import React, { ReactNode } from 'react';

type GeneralProviderProps = {
  children: ReactNode;
};

export const GeneralContext = React.createContext<null>(null);

const GeneralProvider: React.FC<GeneralProviderProps> = (props) => {
  const { children } = props;

  return (
    <GeneralContext.Provider value={null}>{children}</GeneralContext.Provider>
  );
};

export default GeneralProvider;
