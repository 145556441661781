import React, { useCallback, useMemo, useRef } from 'react';
import classNames from 'classnames';
import Bold from 'icons/Bold.icon';
import Italic from 'icons/Italic.icon';
import Underline from 'icons/Underline.icon';
import Strikethrough from 'icons/Strikethrough.icon';
import TextColor from 'icons/TextColor.icon';
import BackgroundColor from 'icons/BackgroundColor.icon';
import OrderedList from 'icons/OrderedList.icon';
import UnorderedList from 'icons/UnorderedList.icon';
import Outdent from 'icons/Outdent.icon';
import Indent from 'icons/Indent.icon';
import InsertLink from 'icons/InsertLink.icon';
import InsertLinkForm from '../InsertLinkForm';
import EditorActions from 'components/RichTextEditorField/utils/EditorActions';
import { InsertLinkFormValues } from '../InsertLinkForm/InsertLinkForm.component';
import { useSlate } from 'slate-react';
import { EditorToolbarActions } from 'components/RichTextEditorField/models/RichTextEditor';
import {
  ELEMENT_TYPES_ARRAY,
  TEXT_INDENT_ARRAY,
  TEXT_STYLES_ARRAY,
} from 'components/RichTextEditorField/constants';
import {
  ElementTypes,
  TextIndent,
} from 'components/RichTextEditorField/models/EditorElements';
import EditorActionButton from '../EditorActionButton';
import { TextStyle } from 'components/RichTextEditorField/models/EditorText';
import { Dropdown } from 'ncoded-component-library';
import { DropdownRef } from 'ncoded-component-library/build/components/molecules/Dropdown/Dropdown.component';
import { FormApi } from 'final-form';

import './RichTextToolbar.styles.scss';
import ColorsForm from '../ColorsForm';
import { ColorsFormValues } from '../ColorsForm/ColorsForm.component';

type RichTextToolbarProps = {
  className?: string;
  readOnly: boolean;
};

const RichTextToolbar: React.FC<RichTextToolbarProps> = (props) => {
  const { className, readOnly } = props;

  const editor = useSlate();
  const linkDropdownRef = useRef<DropdownRef>();
  const textColorDropdownRef = useRef<DropdownRef>();
  const textBackgroundDropdownRef = useRef<DropdownRef>();

  const classes = classNames('anys-rich-text-toolbar', className);

  const selectedText = EditorActions.getStringInSelection(editor);

  const link = EditorActions.getSelectedLink(editor);

  const initialValuesForLink: Partial<InsertLinkFormValues> = useMemo(
    () => ({
      link: link?.link,
      displayText: link?.displayText || selectedText,
    }),
    [link, selectedText],
  );

  const initialValuesForTextColor: Partial<ColorsFormValues> = {
    colorType: 'color',
    color: EditorActions.getActiveColor(editor),
  };

  const initialValuesForTextBackground: Partial<ColorsFormValues> = {
    colorType: 'backgroundColor',
    color: EditorActions.getActiveColor(editor, true),
  };

  const toggleLink = useCallback(
    (values: InsertLinkFormValues, formApi: FormApi<InsertLinkFormValues>) => {
      EditorActions.insertLink(editor, values.link, values.displayText);

      formApi.reset();
      linkDropdownRef.current.setIsOpen(false);
    },
    [editor],
  );

  const toggleColor = useCallback(
    (values: ColorsFormValues, formApi: FormApi<ColorsFormValues>) => {
      const isBackground = values.colorType === 'backgroundColor';

      EditorActions.toggleColor(editor, values.color, isBackground);

      formApi.reset();

      if (isBackground) {
        textBackgroundDropdownRef.current.setIsOpen(false);
      } else {
        textColorDropdownRef.current.setIsOpen(false);
      }
    },
    [editor],
  );

  const checkIfActionActive = useCallback(
    (actionName: EditorToolbarActions) => {
      if (TEXT_STYLES_ARRAY.includes(actionName as TextStyle)) {
        return EditorActions.isMarkActive(editor, actionName as TextStyle);
      }

      if (TEXT_INDENT_ARRAY.includes(actionName as TextIndent)) return false;

      if (ELEMENT_TYPES_ARRAY.includes(actionName as ElementTypes)) {
        return EditorActions.isBlockActive(editor, actionName as ElementTypes);
      }
    },
    [editor],
  );

  const onActionClick = useCallback(
    (actionName: EditorToolbarActions) => {
      if (TEXT_STYLES_ARRAY.includes(actionName as TextStyle)) {
        EditorActions.toggleMark(editor, actionName as TextStyle);
      }

      if (TEXT_INDENT_ARRAY.includes(actionName as TextIndent)) {
        EditorActions.indentText(editor, actionName as TextIndent);

        return;
      }

      if (ELEMENT_TYPES_ARRAY.includes(actionName as ElementTypes)) {
        EditorActions.toggleBlock(editor, actionName as ElementTypes);
      }
    },
    [editor],
  );

  const actionButtonProps = useMemo(
    () => ({
      checkIfActionActive,
      onActionClick,
      disabled: readOnly,
    }),
    [checkIfActionActive, onActionClick, readOnly],
  );

  return (
    <div className={classes}>
      <div>
        <EditorActionButton actionName="bold" {...actionButtonProps}>
          <Bold />
        </EditorActionButton>
        <EditorActionButton actionName="italic" {...actionButtonProps}>
          <Italic />
        </EditorActionButton>
        <EditorActionButton actionName="underline" {...actionButtonProps}>
          <Underline />
        </EditorActionButton>
        <EditorActionButton actionName="line-through" {...actionButtonProps}>
          <Strikethrough />
        </EditorActionButton>
      </div>

      <div>
        <Dropdown
          ref={textColorDropdownRef}
          renderAsPortal
          trigger={
            <EditorActionButton actionName="text-color" {...actionButtonProps}>
              <TextColor />
            </EditorActionButton>
          }
        >
          <ColorsForm
            initialValues={initialValuesForTextColor}
            onSubmit={toggleColor}
          />
        </Dropdown>

        <Dropdown
          ref={textBackgroundDropdownRef}
          renderAsPortal
          trigger={
            <EditorActionButton
              actionName="background-color"
              {...actionButtonProps}
            >
              <BackgroundColor />
            </EditorActionButton>
          }
        >
          <ColorsForm
            initialValues={initialValuesForTextBackground}
            onSubmit={toggleColor}
          />
        </Dropdown>
      </div>

      <div>
        <EditorActionButton actionName="ordered-list" {...actionButtonProps}>
          <OrderedList />
        </EditorActionButton>
        <EditorActionButton actionName="unordered-list" {...actionButtonProps}>
          <UnorderedList />
        </EditorActionButton>
        <EditorActionButton actionName="outdent" {...actionButtonProps}>
          <Outdent />
        </EditorActionButton>
        <EditorActionButton actionName="indent" {...actionButtonProps}>
          <Indent />
        </EditorActionButton>
      </div>

      <Dropdown
        ref={linkDropdownRef}
        renderAsPortal
        trigger={
          <EditorActionButton actionName="link" {...actionButtonProps}>
            <InsertLink />
          </EditorActionButton>
        }
      >
        <InsertLinkForm
          onSubmit={toggleLink}
          initialValues={initialValuesForLink}
        />
      </Dropdown>
    </div>
  );
};

export default RichTextToolbar;
