import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CustomLink from 'components/CustomLink';
import api from 'api';
import useInboxLink from 'router/subrouters/Inbox/hooks/useInboxLink';
import OverlaySpinner from 'components/OverlaySpinner';

import './JobNotFound.styles.scss';

type JobNotFoundProps = {
  className?: string;
};

const JobNotFound: React.FC<JobNotFoundProps> = (props) => {
  const { className } = props;

  const location = useLocation();
  const navigate = useNavigate();
  const { id: jobCommonId } = useParams<{ id: string }>();
  const { t } = useTranslation();

  const { createEntityLink } = useInboxLink();

  const isInbox = location.pathname?.startsWith('/inbox');

  const classes = classNames('anys-job-not-found', 'card', className);

  useEffect(() => {
    if (!+jobCommonId) return;

    // Try to find contract for job
    const getContractMaybe = async () => {
      OverlaySpinner.show('.anys-job-not-found');

      try {
        await api.contract.getContract(+jobCommonId);

        // If we find it, redirect to it
        const contractLink = isInbox
          ? createEntityLink('view', 'contract', +jobCommonId)
          : `/contract/${jobCommonId}`;

        navigate(contractLink, { replace: true });
      } catch (error) {
        console.error(error);
      } finally {
        OverlaySpinner.hide('.anys-job-not-found');
      }
    };

    getContractMaybe();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobCommonId, navigate]);

  return (
    <div className={classes}>
      <h2>{t('General.offerPreOfferNotFound')}</h2>
      <h3>{t('General.offerPreOfferNotFoundDesc')}</h3>
      <CustomLink to="/provide" variant="solid">
        {t('General.backToAnyService')}
      </CustomLink>
    </div>
  );
};

export default JobNotFound;
