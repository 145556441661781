import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { ReactComponent as HorizontalDots } from 'icons/horizontal-dots.svg';
import { DropdownOption } from 'components/DropdownOptions/DropdownOptions.component';
import { useTranslation } from 'react-i18next';
import ChevronIcon from 'icons/ChervonIcon.icon';
import FilterIcon from 'icons/Filter.icon';
import { ModalRef } from 'components/Modal';
import JobFilters from 'components/JobFilters';
import api from 'api';
import SkillsModal from 'router/subrouters/Search/pages/Search/components/SkillsModal';
import { FormApi } from 'final-form';
import { FormValue } from 'components/JobFilters/JobFilters.component';
import ResponsiveDropdown from 'components/ResponsiveDropdown';
import useFiltersForm from 'hooks/useFiltersForm';
import { FilterReqValue } from 'utils/filtersForm';
import useFilterTagDisplays from 'router/subrouters/Search/pages/Search/hooks/useFilterTagDisplays';
import { FilterContext } from 'router/subrouters/Search/pages/Search/providers/Filters/Filters.provider';
import FilterTag from 'router/subrouters/Search/pages/Search/components/FilterTag';
import {
  INBOX_FILTER_DB_NAMES,
  INBOX_ITEMS_GENERAL_FILTER,
} from '../../utils/filters';

import './InboxItemsFilters.styles.scss';
import './InboxItemsFilters.styles.responsive.scss';
import useGetSkillGroups from '../../../../../hooks/useGetSkillGroups';

const defaultValues: {
  inboxItemType: keyof typeof INBOX_ITEMS_GENERAL_FILTER;
} = {
  inboxItemType: 'see-all',
};

const jobFiltersDbNames: Record<string, FilterReqValue> = {};

type InboxItemsFiltersProps = {
  className?: string;
  isManagingItems: boolean;
  loading: boolean;
  onManageItems: () => void;
};

const InboxItemsFilters: React.FC<InboxItemsFiltersProps> = (props) => {
  const { className, isManagingItems, loading, onManageItems } = props;

  const { t } = useTranslation();

  const { filters } = useContext(FilterContext);

  const manageItemsModalRef = useRef<ModalRef>();
  const inboxItemTypeModalRef = useRef<ModalRef>();

  const [selectedSkillIds, setSelectedSkillIds] = useState<number[]>();

  const [priceRange, setPriceRange] = useState({
    minTotalPrice: 0,
    maxTotalPrice: 0,
  });

  const { allSkills } = useGetSkillGroups();

  const filtersModalRef = useRef<ModalRef>();
  const skillsModalRef = useRef<ModalRef>();

  const filtersFormApiRef = useRef<FormApi<FormValue>>();

  const fitlerTagsDisplays = useFilterTagDisplays({ skills: allSkills });

  const onInboxItemTypeChanged = useCallback(() => {
    inboxItemTypeModalRef.current?.close();
  }, []);

  const {
    initialValues: { inboxItemType },
    onSubmit,
  } = useFiltersForm({
    dbNames: INBOX_FILTER_DB_NAMES,
    defaultValues,
    onApply: onInboxItemTypeChanged,
  });

  const classes = classNames('anys-inbox-items-filters', className);

  const messages = useMemo(
    () => ({
      manageItems: t('General.manageItems'),
      'see-all': t('General.seeAll'),
      preOffers: t('General.preOfferCount_other'),
      offers: t('General.offers'),
      activeContracts: t('General.activeContracts'),
      doneContracts: t('General.doneContracts'),
      drafts: t('General.draftCount_other'),
      favourites: t('General.favourites'),
      all: t('General.all'),
      cancel: t('General.cancel'),
    }),
    [t],
  );

  const menuOptions: DropdownOption[] = useMemo(
    () => [
      {
        key: 'remove',
        title: messages.manageItems,
        disabled: isManagingItems,
        className: 'option-as-button',
        onClick: () => {
          onManageItems();
          manageItemsModalRef.current?.close();
        },
      },
    ],
    [isManagingItems, messages, onManageItems],
  );

  const inboxItemTypeOptions = useMemo(
    () =>
      Object.keys(INBOX_ITEMS_GENERAL_FILTER).map((name) => {
        const filterName = name as keyof typeof INBOX_ITEMS_GENERAL_FILTER;

        return {
          key: filterName,
          title: messages[filterName],
          disabled: inboxItemType === filterName || isManagingItems || loading,
          className: inboxItemType === filterName ? 'selected' : undefined,
          onClick: () => {
            onSubmit({
              inboxItemType: filterName === 'see-all' ? null : filterName,
            });
          },
        };
      }),
    [messages, inboxItemType, isManagingItems, loading, onSubmit],
  );

  const onSkillsApply = useCallback((skillIds: number[]) => {
    filtersFormApiRef.current?.change('skills', skillIds);

    skillsModalRef.current?.close();
  }, []);

  useEffect(() => {
    const getPriceRange = async () => {
      try {
        const {
          data: { minTotalPrice, maxTotalPrice },
        } = await api.jobPost.getPriceRange();

        setPriceRange({
          minTotalPrice,
          maxTotalPrice,
        });
      } catch (e) {
        console.error(e);
      }
    };

    getPriceRange();
  }, []);

  return (
    <div className={classes}>
      <div className="flex jb">
        <span className="anys-inbox-items-filters__selected-type">
          {inboxItemType === 'see-all' ? messages.all : messages[inboxItemType]}
        </span>

        <div className="flex gap-8">
          {inboxItemType !== 'activeContracts' && (
            <ResponsiveDropdown
              triggerContent={<HorizontalDots />}
              options={menuOptions}
              modalName="manage-items"
              listClassName="anys-inbox-items-filters__dropdown-list"
              triggerOptions={{
                className: 'anys-inbox-items-filters__dropdown-trigger',
              }}
              confirmActionContent={<span>{messages.cancel}</span>}
              ref={manageItemsModalRef}
            />
          )}

          <ResponsiveDropdown
            triggerContent={<ChevronIcon color="currentColor" />}
            options={inboxItemTypeOptions}
            modalName="inbox-item-type"
            listClassName="anys-inbox-items-filters__dropdown-list"
            triggerOptions={{
              className: 'anys-inbox-items-filters__dropdown-trigger',
            }}
            confirmActionContent={<span>{messages.cancel}</span>}
            ref={inboxItemTypeModalRef}
          />
        </div>
      </div>
      <div className="anys-inbox-items-filters__more-filters">
        <button
          type="button"
          disabled={isManagingItems}
          onClick={() => filtersModalRef.current.open()}
          className={classNames(
            'anys-inbox-items-filters__more-filters-trigger',
            { disabled: isManagingItems },
          )}
        >
          <FilterIcon />
        </button>
        <div className="anys-inbox-items-filters__selected-filters">
          {Object.keys(filters).map((fName) =>
            fName === 'inboxItemType' ? null : (
              <FilterTag
                key={fName}
                name={fName}
                isClickDisabled={isManagingItems}
                display={fitlerTagsDisplays[fName]}
                className={classNames(
                  'anys-inbox-items-filters__selected-filters__filter',
                  { disabled: isManagingItems },
                )}
                hideFilterLabel
              />
            ),
          )}
        </div>
      </div>

      <JobFilters
        modalName="inbox-items-filters"
        ref={filtersModalRef}
        skills={allSkills}
        priceRange={priceRange}
        onBrowse={() => skillsModalRef.current.open()}
        onApply={() => filtersModalRef.current.close()}
        closeModal={() => filtersModalRef.current.close()}
        formApi={filtersFormApiRef}
        dbNames={jobFiltersDbNames}
        keepOpenOnRefresh={!isManagingItems}
      />

      <SkillsModal
        initialValues={selectedSkillIds}
        loading={false}
        ref={skillsModalRef}
        onSubmit={onSkillsApply}
        onOpen={() => {
          setSelectedSkillIds(
            filtersFormApiRef.current?.getState()?.values?.skills,
          );
        }}
      />
    </div>
  );
};

export default InboxItemsFilters;
