import React from 'react';
import _ from 'lodash';

type IconProps = React.SVGProps<SVGSVGElement>;

export default (props: IconProps) => {
  const gradientId = _.uniqueId();

  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.58464 2.33317C2.89428 2.33317 2.33464 2.89281 2.33464 3.58317C2.33464 4.27353 2.89428 4.83317 3.58464 4.83317C4.27499 4.83317 4.83464 4.27353 4.83464 3.58317C4.83464 2.89281 4.27499 2.33317 3.58464 2.33317ZM0.667969 3.58317C0.667969 1.97234 1.9738 0.666504 3.58464 0.666504C5.19547 0.666504 6.5013 1.97234 6.5013 3.58317C6.5013 5.194 5.19547 6.49984 3.58464 6.49984C1.9738 6.49984 0.667969 5.194 0.667969 3.58317ZM17.0906 0.910582C17.416 1.23602 17.416 1.76366 17.0906 2.08909L2.09056 17.0891C1.76512 17.4145 1.23748 17.4145 0.912046 17.0891C0.586609 16.7637 0.586609 16.236 0.912046 15.9106L15.912 0.910582C16.2375 0.585145 16.7651 0.585145 17.0906 0.910582ZM14.418 13.1665C13.7276 13.1665 13.168 13.7261 13.168 14.4165C13.168 15.1069 13.7276 15.6665 14.418 15.6665C15.1083 15.6665 15.668 15.1069 15.668 14.4165C15.668 13.7261 15.1083 13.1665 14.418 13.1665ZM11.5013 14.4165C11.5013 12.8057 12.8071 11.4998 14.418 11.4998C16.0288 11.4998 17.3346 12.8057 17.3346 14.4165C17.3346 16.0273 16.0288 17.3332 14.418 17.3332C12.8071 17.3332 11.5013 16.0273 11.5013 14.4165Z"
        fill={`url(#${gradientId})`}
      />
      <defs>
        <linearGradient
          id={gradientId}
          x1="0.667969"
          y1="8.99984"
          x2="17.3346"
          y2="8.99984"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF7BAC" />
          <stop offset="1" stopColor="#D142F4" />
        </linearGradient>
      </defs>
    </svg>
  );
};
