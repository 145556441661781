import {
  Currency,
  CurrencyConfig,
  CURRENCY_CODES,
  Money,
  SUB_UNIT_DIVISORS,
} from 'constants/currency';
import Decimal from 'decimal.js';
import { TFunction } from 'i18next';

// https://developer.mozilla.org/en/docs/Web/JavaScript/Reference/Global_Objects/Number/MAX_SAFE_INTEGER
// https://developer.mozilla.org/en/docs/Web/JavaScript/Reference/Global_Objects/Number/MIN_SAFE_INTEGER
// https://stackoverflow.com/questions/26380364/why-is-number-max-safe-integer-9-007-199-254-740-991-and-not-9-007-199-254-740-9
const MIN_SAFE_INTEGER = Number.MIN_SAFE_INTEGER || -1 * (2 ** 53 - 1);
const MAX_SAFE_INTEGER = Number.MAX_SAFE_INTEGER || 2 ** 53 - 1;

// See: https://github.com/yahoo/react-intl/wiki/API#formatnumber
const getNumberFormatOptions = (options: CurrencyConfig): CurrencyConfig => ({
  style: 'currency',
  currencyDisplay: 'symbol',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  useGrouping: true,
  ...options,
});

// Convert unformatted value (e.g. 10,00) to Money (or null)
const getPrice = (unformattedValue: string, currencyConfig: CurrencyConfig) => {
  const isEmptyString = unformattedValue === '';
  try {
    return isEmptyString
      ? null
      : convertToMoney(
          convertUnitToSubUnit(
            unformattedValue,
            unitDivisor(currencyConfig.currency),
          ),
          currencyConfig.currency,
        );
  } catch (e) {
    return null;
  }
};

const isSafeNumber = (decimalValue: Decimal) => {
  return (
    decimalValue.gte(MIN_SAFE_INTEGER) && decimalValue.lte(MAX_SAFE_INTEGER)
  );
};

const isMoney = (obj: any): obj is Money =>
  typeof (obj as Money)?.amount === 'number' &&
  !!CURRENCY_CODES[(obj as Money)?.currency];

// Get the minor unit divisor for the given currency
const unitDivisor = (currency: Currency) => {
  return SUB_UNIT_DIVISORS[currency];
};

////////// Currency manipulation in string format //////////

/**
 * Ensures that the given string uses only dots or commas
 * e.g. ensureSeparator('9999999,99', false) // => '9999999.99'
 */
const ensureSeparator = (str: string, useComma = false) => {
  return useComma ? str.replace(/\./g, ',') : str.replace(/,/g, '.');
};

/**
 * Ensures that the given string uses only dots
 * (e.g. JavaScript floats use dots)
 */
const ensureDotSeparator = (str: string) => {
  return ensureSeparator(str, false);
};

/**
 * Convert string to Decimal object (from Decimal.js math library)
 * Handles both dots and commas as decimal separators
 */
const convertToDecimal = (str: string) => {
  const dotFormattedStr = ensureDotSeparator(str);
  return new Decimal(dotFormattedStr);
};

/**
 * Converts Decimal value to a string (from Decimal.js math library)
 * @param useComma - Specify if return value should use comma as separator
 */
const convertDecimalToString = (
  decimalValue: Decimal | number | string,
  useComma = false,
) => {
  const d = new Decimal(decimalValue);
  return ensureSeparator(d.toString(), useComma);
};

const convertDivisorToDecimal = (divisor: Decimal | number | string) => {
  try {
    const divisorAsDecimal = new Decimal(divisor);
    if (divisorAsDecimal.isNegative()) {
      throw new Error(`Parameter (${divisor}) must be a positive number.`);
    }
    return divisorAsDecimal;
  } catch (e) {
    throw new Error(`Parameter (${divisor}) must present a number. ${e}`);
  }
};

/**
 * Limits value to sub-unit precision: "1.4567" -> "1.45"
 * Useful in input fields so this doesn't use rounding.
 *
 * @param inputString - positive number presentation.
 *
 * @param subUnitDivisor - should be something that can be converted to
 * Decimal. (This is a ratio between currency's main unit and sub units.)
 *
 * @param useComma - Specify if return value should use comma as separator
 */
const truncateToSubUnitPrecision = (
  inputString: string,
  subUnitDivisor: Decimal | number | string,
  useComma = false,
) => {
  const subUnitDivisorAsDecimal = convertDivisorToDecimal(subUnitDivisor);

  let trimmed = inputString;
  const inputLength = trimmed.length;
  const lastChar = trimmed[inputLength - 1];

  // '10,' should be passed through, but that format is not supported as valid number
  if (useComma && lastChar === ',') {
    trimmed = trimmed.slice(0, -1);
  } else if (!useComma && lastChar === '.') {
    trimmed = trimmed.slice(0, -1);
  }

  // create another instance and check if value is convertable
  const value = convertToDecimal(trimmed);

  if (value.isNegative()) {
    throw new Error(`Parameter (${inputString}) must be a positive number.`);
  }

  // Amount is always counted in subunits
  // E.g. $10 => 1000¢
  const amount = value.times(subUnitDivisorAsDecimal);

  if (!isSafeNumber(amount)) {
    throw new Error(
      `Cannot represent money minor unit value ${amount.toString()} safely as a number`,
    );
  }

  // Amount must be integer
  // We don't deal with subunit fragments like 1000.345¢
  if (amount.isInteger()) {
    // accepted strings: '9', '9,' '9.' '9,99'
    const decimalCount2 = value.toFixed(2);
    const decimalPrecisionMax2 =
      decimalCount2.length >= inputString.length
        ? inputString
        : value.toFixed(2);
    return ensureSeparator(decimalPrecisionMax2, useComma);
  } else {
    // truncate strings ('9.999' => '9.99')
    const truncated = amount.truncated().dividedBy(subUnitDivisorAsDecimal);
    return convertDecimalToString(truncated, useComma);
  }
};

////////// Currency - Money helpers //////////

/**
 * Converts given value to sub unit value and returns it as a number
 *
 * @param subUnitDivisor - should be something that can be converted to
 * Decimal. (This is a ratio between currency's main unit and sub units.)
 */
const convertUnitToSubUnit = (
  value: number | string,
  subUnitDivisor: Decimal | number | string,
) => {
  const subUnitDivisorAsDecimal = convertDivisorToDecimal(subUnitDivisor);

  if (!(typeof value === 'string' || typeof value === 'number')) {
    throw new TypeError('Value must be either number or string');
  }

  const val =
    typeof value === 'string' ? convertToDecimal(value) : new Decimal(value);
  const amount = val.times(subUnitDivisorAsDecimal);

  if (!isSafeNumber(amount)) {
    throw new Error(
      `Cannot represent money minor unit value ${amount.toString()} safely as a number`,
    );
  } else if (amount.isInteger()) {
    return amount.toNumber();
  } else {
    throw new Error(`value must divisible by ${subUnitDivisor}`);
  }
};

const convertSubUnitToUnit = (
  value: number | string,
  subUnitDivisor: Decimal | number | string,
) => {
  const subUnitDivisorAsDecimal = convertDivisorToDecimal(subUnitDivisor);

  if (!(typeof value === 'string' || typeof value === 'number')) {
    throw new TypeError('Value must be either number or string');
  }

  const val =
    typeof value === 'string' ? convertToDecimal(value) : new Decimal(value);
  const amount = val.dividedBy(subUnitDivisorAsDecimal);

  if (!isSafeNumber(amount)) {
    throw new Error(
      `Cannot represent money unit value ${amount.toString()} safely as a number`,
    );
  } else if (amount.isInteger()) {
    return amount.toNumber();
  } else {
    throw new Error(`value must divisible by ${subUnitDivisor}`);
  }
};

const isNumber = (value: number) => {
  return typeof value === 'number' && !isNaN(value);
};

const convertMoneyToNumber = (value: Money) => {
  const subUnitDivisorAsDecimal = convertDivisorToDecimal(
    unitDivisor(value.currency),
  );

  const amount = new Decimal(value.amount);

  if (!isSafeNumber(amount)) {
    throw new Error(
      `Cannot represent money minor unit value ${amount.toString()} safely as a number`,
    );
  }

  return amount.dividedBy(subUnitDivisorAsDecimal).toNumber();
};

/**
 * Format the given money to a string
 */
const formatMoney = (t: TFunction, value: Money, options?: CurrencyConfig) => {
  const formatOptions = getNumberFormatOptions({
    currency: value.currency,
    ...options,
  });

  const valueAsNumber = convertMoneyToNumber(value);

  return t('intlNumber', { val: valueAsNumber, ...formatOptions });
};

/**
 * Format the given major-unit string value as currency. E.g. "10" -> "$10".
 *
 * NOTE: This function should not be used with listing prices or other Money type.
 * This can be used with price filters and other components that doesn't send Money types to API.
 */
const formatCurrencyMajorUnit = (
  t: TFunction,
  currency: Currency,
  valueWithoutSubunits: number,
  options?: CurrencyConfig,
) => {
  const valueAsNumber = new Decimal(valueWithoutSubunits).toNumber();

  const numberFormatOptions = getNumberFormatOptions({
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    ...options,
  });
  return t('intlNumber', { val: valueAsNumber, ...numberFormatOptions });
};

const getCurrencyFromIntl = (t: TFunction, currency: Currency) =>
  t('intlNumber', {
    val: 0,
    ...getNumberFormatOptions({
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }),
  })
    .replace(/\d/g, '')
    .trim();

// Currency formatting options.
// See: https://github.com/yahoo/react-intl/wiki/API#formatnumber
const getCurrencyConfiguration = (currency: Currency) => {
  return SUB_UNIT_DIVISORS[currency] === 1
    ? // If the currency is not using subunits (like JPY), remove fractions.
      getNumberFormatOptions({
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        currency,
      })
    : getNumberFormatOptions({ currency });
};

const convertToMoney = (amount: number, currency: Currency = 'USD'): Money => ({
  amount,
  currency,
});

const formatNumber = (
  t: TFunction,
  val: string | number,
  options: CurrencyConfig,
) => t('intlNumber', { val, ...options });

export {
  isMoney,
  getPrice,
  isSafeNumber,
  unitDivisor,
  ensureSeparator,
  ensureDotSeparator,
  convertToDecimal,
  convertDecimalToString,
  convertDivisorToDecimal,
  truncateToSubUnitPrecision,
  convertUnitToSubUnit,
  convertSubUnitToUnit,
  isNumber,
  convertMoneyToNumber,
  formatMoney,
  formatCurrencyMajorUnit,
  getCurrencyFromIntl,
  getCurrencyConfiguration,
  convertToMoney,
  formatNumber,
  getNumberFormatOptions,
};
