import React, { useCallback, useContext, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Button } from 'ncoded-component-library';
import Save from 'icons/Save.icon';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import MiniAvatar from 'components/MiniAvatar';
import SlatePreview from 'components/SlatePreview';
import LocationDisplay from 'components/LocationDisplay';
import { JobPost } from 'models/Job';
import MainBoost from './components/MainBoost';
import ShareButton from 'components/ShareButton/ShareButton';
import SelectedSkills from 'components/SelectedSkills';
import JobState from 'components/JobState';
import { getEntityInboxState } from 'router/subrouters/Inbox/utils';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import { Link } from 'react-router-dom';
import api from 'api';
import showToast from 'modules/showToast';
import { useTranslation } from 'react-i18next';
import { formatUserFirstLastName } from 'utils/user';

import './NeedProvideCard.styles.responsive.scss';
import './NeedProvideCard.styles.scss';

dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'a few seconds',
    m: 'a minute',
    mm: '%d minutes',
    h: 'an hour',
    hh: '%d hours',
    d: 'a day',
    dd: '%d days',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years',
  },
});

export type NeedProvide = 'Provide' | 'Need';

export type Media = 'Photo' | 'Video';

type NeedProvideCardProps = {
  className?: string;
  jobPost: JobPost<'response'>;
  disableAllActions?: boolean;
  boostCta?: React.ReactNode;
  isFavourited?: boolean;
  setFavouritedPosts?: React.Dispatch<
    React.SetStateAction<Record<string, boolean>>
  >;
};

const NeedProvideCard: React.FC<NeedProvideCardProps> = (props) => {
  const {
    className,
    jobPost,
    disableAllActions,
    boostCta,
    isFavourited,
    setFavouritedPosts,
  } = props;

  const {
    id,
    title,
    typeOfService,
    description,
    skills,
    user,
    timeAndPricing,
    boosts,
    mainSkill,
    trackingData,
    createdAt,
    type,
  } = jobPost || {};

  const { currentUser } = useContext(CurrentUserContext);

  const [isFavouriting, setIsFavouriting] = useState(false);

  const { t } = useTranslation();

  const classes = classNames('anys-need-provide-card', className);
  const mainBoost = useMemo(() => (!boosts ? null : boosts[0]), [boosts]);

  const shareData = useMemo(
    () =>
      ({
        title,
        url: `${window.location.origin}/job-post/${id}`,
      }) as ShareData,
    [title, id],
  );

  const userDisplayName = formatUserFirstLastName(user, t('General.noName'));

  const skillsWithoutMainSkill = useMemo(
    () => skills?.filter(({ id }) => mainSkill?.id !== id),
    [mainSkill?.id, skills],
  );

  const isOwnCard = currentUser?.id === user?.id;

  const trackClick = useCallback(() => {
    try {
      if (!isOwnCard && trackingData)
        api.positionBoostMetrics.trackClick(trackingData);
    } catch (error) {
      console.error(error);
    }
  }, [isOwnCard, trackingData]);

  const toggleFavourite = useCallback(async () => {
    if (!currentUser?.id || !setFavouritedPosts || isFavouriting) return;

    try {
      setIsFavouriting(true);

      const favFn = isFavourited
        ? api.jobPost.unfavouriteJobPost
        : api.jobPost.favouriteJobPost;

      await favFn(id);

      setFavouritedPosts((old) => ({ ...old, [id]: !isFavourited }));
    } catch (error) {
      console.error(error);
      showToast('error', t('General.error'));
    } finally {
      setIsFavouriting(false);
    }
  }, [currentUser?.id, id, isFavourited, isFavouriting, setFavouritedPosts, t]);

  return (
    <section className={classes}>
      {!disableAllActions && (
        <Link
          tabIndex={0}
          to={`/job-post/${id}`}
          onClick={trackClick}
          className="anys-need-provide-card__nav-layer"
        />
      )}

      <header className="anys-need-provide-card__header">
        <span className="anys-need-provide-card__header__title">{title}</span>
        <JobState state={getEntityInboxState(jobPost, 'job-post')} />
      </header>

      <LocationDisplay {...typeOfService} />

      <main>
        <SlatePreview
          className={classNames('anys-need-provide-card__description', {
            'anys-need-provide-card__description--default':
              !mainBoost?.type ||
              mainBoost?.type === 'Photo' ||
              mainBoost?.type === 'Video',
            'job-post-text-height--50': mainBoost?.type === 'Size-50',
            'job-post-text-height--100': mainBoost?.type === 'Size-100',
          })}
          disableShowMore
        >
          {description}
        </SlatePreview>
        {mainBoost && (
          <div className="anys-need-provide-card__boost-wrapper">
            <MainBoost boost={mainBoost} />
            {boostCta}
          </div>
        )}
      </main>

      <div className="anys-need-provide-card__skills-price">
        <SelectedSkills
          mainSkill={mainSkill}
          mainSkillStyle="fill"
          skills={skillsWithoutMainSkill?.slice(0, 3)}
        />

        <label className="anys-need-provide-card__skills-price__price">
          {`$${(timeAndPricing?.price / 100)?.toFixed(2)}`}
          <span>{timeAndPricing?.type === 'Hourly' && '/hour'}</span>
        </label>
      </div>

      <hr />

      <footer className="anys-need-provide-card__footer">
        <MiniAvatar
          className="anys-need-provide-card__footer__avatar"
          id={!disableAllActions && user?.id}
          profileImage={user?.profileImage}
          userName={userDisplayName}
          isVerified={user?.hasVerifiedIdentity || user?.hasVerifiedBusiness}
          rating={
            type === 'Provide'
              ? user?.overallSkillScore?.averageRating
              : user?.overallClientScore?.averageRating
          }
          votes={
            type === 'Provide'
              ? user?.overallSkillScore?.numberOfReviews
              : user?.overallClientScore?.numberOfReviews
          }
          //TODO add isOnline
          // isOnline={false}
          info={dayjs(createdAt).fromNow()}
          isSkillReview={type === 'Provide'}
        />

        <div className="anys-need-provide-card__footer__action-buttons">
          <ShareButton data={shareData} disabled={disableAllActions} />
          <Button
            type="button"
            styleType="secondary"
            variant="icon"
            icon={<Save />}
            disabled={disableAllActions}
            onClick={toggleFavourite}
            className={classNames({
              'anys-need-provide-card__footer__action-buttons__button--favourite':
                isFavourited,
            })}
          />
        </div>
      </footer>
    </section>
  );
};

export default NeedProvideCard;
