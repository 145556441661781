import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NavBar from 'components/NavBar';
import useHeaderStyles from 'hooks/useHeaderStyles';
import api from 'api';
import OverlaySpinner from 'components/OverlaySpinner';
import { InboxUrlParams } from 'models/Inbox';
import MainTemplate from 'components/templates/MainTemplate';
import JobPostPage from 'router/subrouters/JobPost/pages/JobPost';
import Job from 'router/subrouters/Job/pages/Job';
import Contract from 'router/subrouters/Contract/pages/Contract';
import useInboxLink from '../../hooks/useInboxLink';
import { INBOX_ITEM_RELATIONS, getEntityIdForItem } from '../../utils';
import classNames from 'classnames';
import InboxItems from '../../components/InboxItems';
import SideChat from '../../components/SideChat';
import useHeaderContent from 'hooks/useHeaderContent';
import CustomLink from 'components/CustomLink';
import FiltersProvider from 'router/subrouters/Search/pages/Search/providers/Filters/Filters.provider';
import ChatNotificationsContext from 'providers/ChatNotifications/ChatNotifications.context';
import NotificationBadge from 'components/NotificationBadge';
import { AxiosError } from 'axios';
import showToast from 'modules/showToast';

import './Inbox.styles.scss';
import './Inbox.styles.responsive.scss';

const InboxComponent = () => {
  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();

  const { inboxItemId, chatUserId, id } = useParams<InboxUrlParams>();
  const { totalUnreadNotifications } = useContext(ChatNotificationsContext);

  const { createEntityLink } = useInboxLink();

  const isJobPost = location.pathname?.includes('/job-post');
  const isJob = !isJobPost && location.pathname?.includes('/job');
  const isContract = location.pathname?.includes('/contract');
  const isBoostView = isJobPost && +id && location.pathname?.includes('/boost');

  const containerProps = useMemo(
    () => ({
      asideLeft: (
        <FiltersProvider>
          <InboxItems />
        </FiltersProvider>
      ),
      asideRight: <SideChat />,
    }),
    [],
  );

  const isNothingOpen = !+inboxItemId;
  const isEntityOpen = +inboxItemId && +id;

  const classes = classNames('anys-inbox', {
    'anys-inbox--items-view': isNothingOpen,
    'anys-inbox--entity-view': isEntityOpen,
    'anys-inbox--boost-view': isBoostView,
  });

  useHeaderStyles(
    {
      showBackButton: true,
      className: 'anys-inbox__mobile-header',
    },
    [location.pathname],
  );

  useHeaderContent(
    <div className="mobile-header-content">
      {t('General.inbox')}

      <CustomLink
        to="/chat"
        variant="solid"
        className="mobile-header-content__cta"
      >
        {t('General.chat')}
        {totalUnreadNotifications ? (
          <NotificationBadge count={totalUnreadNotifications} />
        ) : null}
      </CustomLink>
    </div>,
    [isNothingOpen, totalUnreadNotifications],
    undefined,
    isNothingOpen,
  );

  const getInboxItem = useCallback(
    async (inboxItemId: number) => {
      OverlaySpinner.show('.anys-inbox');

      try {
        const { data } = await api.inboxItems.getInboxItem(
          inboxItemId,
          INBOX_ITEM_RELATIONS,
        );

        navigate(
          createEntityLink(
            'view',
            data.type,
            getEntityIdForItem(data),
            inboxItemId,
          ),
        );
      } catch (error) {
        console.error(error);

        if ((error as AxiosError)?.response?.status !== 404) {
          const err = error as AxiosError;

          showToast(
            'error',
            t('General.error'),
            err?.response?.data?.error?.message,
          );
        }
      } finally {
        OverlaySpinner.hide('.anys-inbox');
      }
    },
    [createEntityLink, navigate, t],
  );

  useEffect(() => {
    // If we have an inbox item selected, but
    // we don't have an entityId, we need to fetch
    // the inbox item so we can add the id in the url
    if (+inboxItemId && !+id) {
      getInboxItem(+inboxItemId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, inboxItemId]);

  return (
    <>
      <NavBar className="anys-inbox__navbar" />
      <MainTemplate {...containerProps} className={classes}>
        {isJobPost ? (
          <JobPostPage key={id} {...containerProps} />
        ) : isJob ? (
          <Job key={id} {...containerProps} chatUserId={+chatUserId} />
        ) : isContract ? (
          <Contract key={id} {...containerProps} chatUserId={+chatUserId} />
        ) : (
          <Outlet />
        )}
      </MainTemplate>
    </>
  );
};

export default InboxComponent;
