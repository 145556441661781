import {
  FormValue,
  jobFiltersDbNames,
  Sign,
} from 'components/JobFilters/JobFilters.component';
import { JobPricingType } from 'models/Job/TimeAndPricing';
import { FormJobTypeOfService } from 'models/Job/TypeOfService';
import { LocationType, PlaceFormat, PlaceSearchParam } from 'models/User';
import {
  convertSubUnitToUnit,
  convertUnitToSubUnit,
  unitDivisor,
} from 'utils/currency';
import { FilterReqValue } from 'utils/filtersForm';

export type NeedProvideFiltersFormValues = {
  typeOfService: LocationType;
  price: { from: number; to: number };
  favouriteFilter: string;
  rating: string;
} & Pick<
  FormValue,
  'sign' | 'userType' | 'skills' | 'numberOfJobs' | 'languages'
>;

export const NEED_PROVIDE_FILTERS: Record<
  keyof NeedProvideFiltersFormValues,
  keyof NeedProvideFiltersFormValues
> = {
  favouriteFilter: 'favouriteFilter',
  skills: 'skills',
  price: 'price',
  typeOfService: 'typeOfService',
  sign: 'sign',
  userType: 'userType',
  rating: 'rating',
  numberOfJobs: 'numberOfJobs',
  languages: 'languages',
} as const;

export const locationToTypeOfService = (location: PlaceSearchParam) => {
  const typeOfService: FormJobTypeOfService = {
    type: 'Offline',
    locationType: 'One spot',
    startLocation: location,
  };

  return JSON.stringify(typeOfService);
};

const getPriceQuery = (
  from: string,
  to: string,
  pricingType: JobPricingType,
) => {
  const isHourly = pricingType === 'Hourly';

  if (isHourly && (from || to))
    return {
      'timeAndPricing.price': {
        MORETHANOREQUAL: from ? Number.parseInt(from) : undefined,
        LESSTHANOREQUAL: to ? Number.parseInt(to) : undefined,
      },
    };

  const fromQuery = from
    ? {
        'timeAndPricing.minTotalPrice': {
          MORETHANOREQUAL: Number.parseInt(from),
        },
      }
    : {};

  const toQuery = to
    ? {
        'timeAndPricing.maxTotalPrice': {
          LESSTHANOREQUAL: Number.parseInt(to),
        },
      }
    : {};

  return {
    ...fromQuery,
    ...toQuery,
  };
};

export const typeOfServiceInputParses = (value: string) => {
  const { startLocation, type } = JSON.parse(
    value,
  ) as FormJobTypeOfService<PlaceFormat>;

  if (type === 'Online') return;

  return startLocation as PlaceSearchParam;
};

export const defaultParses: Partial<
  Record<
    keyof NeedProvideFiltersFormValues | 'skills[]',
    (value: string) => any
  >
> = {
  'skills[]': Number,
  price: (value: string) => {
    const [min, max] = value.split('-');

    return {
      from: min ? `${convertSubUnitToUnit(min, unitDivisor('USD'))}` : null,
      to: max ? `${convertSubUnitToUnit(max, unitDivisor('USD'))}` : null,
    };
  },
  typeOfService: (value: string) => {
    return typeOfServiceInputParses(value)?.placeId;
  },
};

export const defaultTransformations: Partial<
  Record<keyof NeedProvideFiltersFormValues, (value: any) => string>
> = {
  price: (price: { from: number; to: number }) => {
    const from = price?.from
      ? convertUnitToSubUnit(price.from, unitDivisor('USD'))
      : '';
    const to = price?.to
      ? convertUnitToSubUnit(price.to, unitDivisor('USD'))
      : '';

    return `${from}-${to}-`;
  },
  typeOfService: (location: PlaceSearchParam) => {
    return locationToTypeOfService(location);
  },
  rating: (value: string) => (value === 'All' ? null : value),
};

export const defaultDbNames: Record<string, FilterReqValue> = {
  'skills[]': {
    dbName: 'skills.id',
    type: 'array',
  },
  price: {
    mapperFn: (price: string) => {
      const [from, to, type] = price.split('-');

      const pricingType = type as JobPricingType;

      return getPriceQuery(from, to, pricingType);
    },
  },
  typeOfService: jobFiltersDbNames.typeOfService,
  sign: {
    mapperFn: (sign) => {
      switch (sign as Sign) {
        case 'pre-offer':
          return {
            signedAt: { ISNULL: '' },
          };
        case 'offer':
          return {
            signedAt: { '!ISNULL': '' },
          };
        default:
          return {};
      }
    },
  },
  userType: {
    mapperFn: (value) => {
      if (value === 'see-all') return {};

      return {
        'user.role': {
          EQUAL: value,
        },
      };
    },
  },
  rating: {
    mapperFn: (value) => {
      if (value === 'All') return;

      const isNeedPage =
        typeof window !== 'undefined' &&
        window.location.pathname.startsWith('/need');

      return {
        [`user.${
          isNeedPage ? 'overallClientScore' : 'overallSkillScore'
        }.averageRating`]: {
          MORETHANOREQUAL: Number.parseInt(value),
        },
      };
    },
  },
  numberOfJobs: {
    mapperFn: (value) => {
      return {
        'user.jobSuccess.numberOfJobs': {
          MORETHANOREQUAL: Number.parseInt(value),
        },
      };
    },
  },
  'languages[]': {
    dbName: 'user.languages.language',
    type: 'array',
    queryType: 'or',
  },
};
