import React from 'react';

type BackgroundColorProps = React.SVGProps<SVGSVGElement>;

const BackgroundColor: React.FC<BackgroundColorProps> = (props) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.43811 9.18057L13.249 5.13795L14.1336 6.10988L9.56311 11.5155L7.43811 9.18057ZM5.69053 9.48896L9.09203 13.2264C9.16245 13.2962 9.24686 13.3502 9.33971 13.3849C9.43255 13.4196 9.5317 13.4342 9.63061 13.4278C9.72952 13.4213 9.82593 13.394 9.91349 13.3475C10.001 13.3011 10.0777 13.2365 10.1385 13.1582L15.727 6.54851C15.8384 6.42617 15.9014 6.26751 15.9043 6.10212C15.9073 5.93673 15.8499 5.77593 15.743 5.64971L13.8583 3.57884C13.7427 3.46055 13.588 3.38836 13.423 3.37576C13.2581 3.36316 13.0942 3.411 12.962 3.51035L5.85672 8.45347C5.77305 8.50662 5.70161 8.57692 5.64712 8.65972C5.59263 8.74253 5.55634 8.83595 5.54063 8.93382C5.52492 9.03169 5.53016 9.13178 5.556 9.22748C5.58184 9.32317 5.62769 9.4123 5.69053 9.48896ZM2.0954 13.8957L6.30842 14.625L7.41777 13.6153L5.1461 11.1193L2.0954 13.8957Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default BackgroundColor;
