import { Currency } from './../../constants/currency';
import { PaginationParams } from 'models/Pagination';
import { ListAnyCoin } from 'models/Wallet';
import httpClient from '../httpClient';

const addDeposit = (amount: number, stripePaymentMethodId: string) =>
  httpClient.post(`wallet/deposit`, {
    amount,
    currency: 'USD',
    stripePaymentMethodId,
  });

const withdrawFunds = (amount: number, currency: Currency) =>
  httpClient.post(`wallet/withdraw`, { 
    amount,
    currency 
  });

const getStripeConnect = () => httpClient.get(`wallet/connect`);

const connectStripe = () => httpClient.post(`wallet/connect`);

const updateStripeConnect = () => httpClient.post(`wallet/connect/update`);

const getWallet = () => httpClient.get(`wallet/current`);

const getAnyCoinTrades = () => httpClient.get(`anycoin/trades`);

const getAnyCoinTradeLimit = () => httpClient.get(`anycoin/trades/limit`);

const sellAnyCoins = (params: ListAnyCoin) =>
  httpClient.post(`anycoin/trades/sell`, { ...params });

const unsellAnyCoins = (tradeId: number) =>
  httpClient.delete(`anycoin/trades/sell/${tradeId}`);

const getPaymentHistory = (fundId: number, params: PaginationParams) =>
  httpClient.get(`wallet/funds/${fundId}/payment-history`, { params });

const attachPaymentMethod = () => httpClient.post(`wallet/payment-methods`);

const detachPaymentMethod = (stripePaymentMethodId: string) =>
  httpClient.delete(`wallet/payment-methods/${stripePaymentMethodId}`);

const setDefaultPaymentMethod = (stripePaymentMethodId: string) =>
  httpClient.post(
    `wallet/payment-methods/${stripePaymentMethodId}/change-default`,
  );

const getPaymentMethods = () => httpClient.get(`wallet/payment-methods`);

const buyAnyCoins = (numberOfCoins: number) =>
  httpClient.post(`anycoin/trades/buy`, { numberOfCoins });

export default {
  addDeposit,
  withdrawFunds,
  getStripeConnect,
  connectStripe,
  updateStripeConnect,
  getWallet,
  getPaymentMethods,
  getAnyCoinTrades,
  getAnyCoinTradeLimit,
  sellAnyCoins,
  unsellAnyCoins,
  getPaymentHistory,
  attachPaymentMethod,
  detachPaymentMethod,
  setDefaultPaymentMethod,
  buyAnyCoins,
};
