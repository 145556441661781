import { useMemo, useState } from 'react';
import { Descendant } from 'slate';

const useDescription = (children: string, maxLength = 250) => {
  const [showMore, setShowMore] = useState(false);

  const parsedDescription = useMemo(() => {
    if (!children) return null;

    const pd = JSON.parse(children);

    if (Array.isArray(pd)) {
      return (pd as Descendant[]).filter((d) => 'type' in d);
    }

    return null;
  }, [children]);

  const cutDescription = useMemo(() => {
    if (!parsedDescription) return null;

    let accLength = 0;

    const cutChildren = (elements: Descendant[]): Descendant[] => {
      return elements.reduce((acc, el, ind) => {
        if (accLength >= maxLength) {
          setShowMore(true);
          return acc;
        }

        if ('text' in el) {
          accLength += el.text.length;
          if (accLength < maxLength) {
            return [...acc, el];
          } else {
            return [
              ...acc,
              {
                text: el.text.slice(
                  0,
                  Math.max(0, maxLength - (accLength - el.text.length)),
                ),
              },
            ];
          }
        }

        if (ind !== 0 && el.type !== 'indent') {
          accLength += 20;
        } else {
          accLength += 4;
        }

        if (accLength >= maxLength) return acc;

        const newEl = {
          ...el,
          children: cutChildren(el.children),
        } as Descendant;
        return [...acc, newEl];
      }, [] as Descendant[]);
    };

    return cutChildren(parsedDescription);
  }, [maxLength, parsedDescription]);

  return [cutDescription, parsedDescription, showMore];
};

export default useDescription;
