import React from 'react';
import Rating from 'components/Rating';
import { SkillRating } from 'models/User';

import './Skill.styles.scss';
import classNames from 'classnames';

type SkillProps = {
  skillRating?: SkillRating;
  onClick: () => void;
  isActive?: boolean;
};

const Skill: React.FC<SkillProps> = ({ skillRating, onClick, isActive }) => {
  const { numberOfReviews, averageRating, skill } = skillRating || {};

  const { name = '-' } = skill || {};

  const classes = classNames('skills-modal__skill', {
    'skills-modal__skill--active': isActive,
  });

  return (
    <li className={classes} onClick={onClick}>
      <p>{name}</p>
      <Rating
        withValue
        value={averageRating}
        reviews={numberOfReviews}
        valueFormat={(val) => val}
        reviewFormat={(revs) => `(${revs})`}
        maxReviewScore={10}
      />
    </li>
  );
};

export default Skill;
