import React, { useContext } from 'react';
import { DropdownOption } from 'components/DropdownOptions/DropdownOptions.component';
import ArbitrationIcon from 'icons/Arbitration.icon';
import BellIcon from 'icons/Bell.icon';
import MessageSquareIcon from 'icons/MessageSquare.icon';
import { NavMenuItem } from 'models/Navigation';
import { useMemo } from 'react';
import authService from 'services/authService';
import { useTranslation } from 'react-i18next';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import ResponsiveDropdown from 'components/ResponsiveDropdown';
import MoreHeaderIcon from 'icons/MoreHeaderIcon.icon';
import NotificationBadge from 'components/NotificationBadge';
import Avatar from 'components/Avatar';
import NotificationsContext from 'providers/Notifications/Notifications.context';
import BurgerIcon from 'icons/Burger.icon';
import classNames from 'classnames';
import useMatchMedia from 'hooks/useMatchMedia';
import BackButton from 'components/BackButton';
import TimesIcon from 'icons/Times.icon';
import GoRightIcon from 'icons/GoRight.icon';
import ChatNotificationsContext from 'providers/ChatNotifications/ChatNotifications.context';
import SettingsIcon from 'icons/Settings.icon';
import FAQIcon from 'icons/FAQ.icon';
import WalletIcon from 'icons/Wallet.icon';

import './MenuDropdown.styles.scss';
import './MenuDropdown.styles.responsive.scss';

type MenuDropdownProps = {
  className?: string;
};

const MenuDropdown: React.FC<MenuDropdownProps> = (props) => {
  const { className } = props;

  const isPhablet = useMatchMedia('isPhablet');

  const { t } = useTranslation();
  const { currentUser } = useContext(CurrentUserContext);
  const { notificationsByGroup, notificationsCount } =
    useContext(NotificationsContext);
  const { totalUnreadNotifications: unreadChatNotifications } = useContext(
    ChatNotificationsContext,
  );

  const loggedIn = !!currentUser?.id;

  const classes = classNames('anys-menu-dropdown', className);

  const menuOptions = useMemo(() => {
    let options: (DropdownOption & { key: NavMenuItem })[];
    if (loggedIn) {
      options = [
        {
          key: 'inbox',
          title: (
            <>
              <MessageSquareIcon className="anys-menu-dropdown__mobile-content" />
              <span>{t('General.inbox')}</span>
              {notificationsByGroup['inbox'] || unreadChatNotifications ? (
                <NotificationBadge
                  count={
                    (notificationsByGroup['inbox'] || 0) +
                    unreadChatNotifications
                  }
                  onlyDotOnMobile={false}
                />
              ) : null}
              <GoRightIcon className="anys-menu-dropdown__mobile-content" />
            </>
          ),
          href: '/inbox',
        },
        {
          key: 'wallet',
          title: (
            <>
              <WalletIcon className="anys-menu-dropdown__mobile-content" />
              <span>{t('Wallet.wallet')}</span>
              <GoRightIcon className="anys-menu-dropdown__mobile-content" />
            </>
          ),
          href: '/wallet',
        },
        {
          key: 'account-settings',
          title: (
            <>
              <SettingsIcon className="anys-menu-dropdown__mobile-content" />
              <span>{t('AccountSettings.accountSettings')}</span>
              <GoRightIcon className="anys-menu-dropdown__mobile-content" />
            </>
          ),
          href: '/account-settings',
        },
        {
          key: 'notification-center',
          title: (
            <>
              <BellIcon className="anys-menu-dropdown__mobile-content" />
              <span>{t('General.notificationCenter')}</span>
              <GoRightIcon className="anys-menu-dropdown__mobile-content" />
            </>
          ),
          href: '/notification-center',
        },
        {
          key: 'arbitration',
          title: (
            <>
              <ArbitrationIcon className="anys-menu-dropdown__mobile-content" />
              <span>{t('General.arbitration')}</span>
              {notificationsByGroup['arbitration'] ? (
                <NotificationBadge
                  count={notificationsByGroup['arbitration']}
                  onlyDotOnMobile={false}
                />
              ) : null}
              <GoRightIcon className="anys-menu-dropdown__mobile-content" />
            </>
          ),
          href: '/arbitration',
        },

        {
          key: 'static-pages',
          title: (
            <>
              <FAQIcon className="anys-menu-dropdown__mobile-content" />
              <span>{t('FrequentQuestions.faqAndTerms')}</span>
              <GoRightIcon className="anys-menu-dropdown__mobile-content" />
            </>
          ),
          href: '/static-pages',
        },
        {
          key: 'logout',
          title: <span>{t('General.logout')}</span>,
          className: 'anys-menu-dropdown__logout',
          onClick: authService.logout,
        },
      ];
    } else {
      options = [
        {
          key: 'login',
          href: '/auth',
          title: <span>{t('General.login')}</span>,
        },
      ];
    }

    return options;
  }, [loggedIn, notificationsByGroup, t, unreadChatNotifications]);

  return (
    <ResponsiveDropdown
      modalName="profile-dropdown-menu"
      className={classes}
      options={menuOptions}
      renderDropdownAsPortal={false}
      type="no-action"
      modalClassName="anys-menu-dropdown__dropdown-modal"
      listClassName="anys-menu-dropdown__item-list"
      triggerOptions={{
        className: 'anys-menu-dropdown__trigger',
        variant: isPhablet ? 'icon' : undefined,
      }}
      title={
        <BackButton
          icon={<TimesIcon gradient gradientColor="purple" />}
          withBorder
        />
      }
      triggerContent={
        <>
          {notificationsCount || unreadChatNotifications ? (
            <NotificationBadge
              count={notificationsCount + unreadChatNotifications}
              className="anys-menu-dropdown__trigger__notif-badge"
            />
          ) : null}
          <BurgerIcon className="anys-menu-dropdown__desktop-content" />
          <Avatar
            img={currentUser?.profileImage?.resizedUrls?.[40]}
            className="anys-menu-dropdown__desktop-content"
          />

          <MoreHeaderIcon className="anys-menu-dropdown__mobile-content" />
        </>
      }
    />
  );
};

export default MenuDropdown;
