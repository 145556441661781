import React from 'react';
import { CertificateType } from 'models/User';

type CertificatesPreviewProps = {
  certificate: CertificateType;
};

const CertificatesPreview: React.FC<CertificatesPreviewProps> = (props) => {
  const {
    certificate: { name, issuedBy, issueDate },
  } = props;

  return (
    <div className="anys-profile-preview__body__preview">
      <span className="anys-profile-preview__body__preview__name">{name}</span>
      <span className="anys-profile-preview__body__preview__value">
        {issuedBy}
        {`, ${issueDate?.substring(0, 4)}`}
      </span>
    </div>
  );
};

export default CertificatesPreview;
