import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { FormRenderProps } from 'react-final-form';
import { Button } from 'ncoded-component-library';
import { FieldArray } from 'react-final-form-arrays';
import TimeInputField from 'components/TimeInputField';
import TrashIcon from 'icons/Trash.icon';
import PlusGradient from 'icons/PlusGradient.icon';
import CalendarDropdownField from 'components/CalendarDropdownField';
import formValidators from 'utils/formValidators';
import utils from '../../utils';
import dayjs from 'dayjs';

import './CustomAvailabilityForm.styles.scss';

type CustomAvailabilityFormProps = {
  className?: string;
  formRenderProps: FormRenderProps<any, Partial<any>>;
};

const CustomAvailabilityForm: React.FC<CustomAvailabilityFormProps> = (
  props,
) => {
  const { className, formRenderProps } = props;

  const { t } = useTranslation();

  const classes = classNames('anys-date-overrides-form', className);

  const {
    handleSubmit,
    values,
    form: {
      mutators: { push },
      change,
    },
  } = formRenderProps;

  const { composeValidators, requiredTime } = formValidators;

  return (
    <form onSubmit={handleSubmit} className={classes}>
      <p className="anys-date-overrides-form__explanation">
        {t("Availability.whenYourAreAvailable")}
      </p>
      <FieldArray name="specificEntries">
        {({ fields: fieldsDate }) =>
          fieldsDate.map((nameDate, indexDate) => (
            <React.Fragment key={nameDate}>
              <div className="anys-date-overrides-form__date">
                <CalendarDropdownField
                  name={`${nameDate}.date`}
                  placeholder={t('Availability.selectDateRange')}
                  className="anys-date-overrides-form__date__calendar"
                  allowRange
                  rightLimitDate={dayjs().add(2, 'year')}
                  validate={composeValidators(
                    utils.hasOverlapDate(
                      t("Availability.dateOverlapping"),
                      'specificEntries',
                      indexDate,
                    ),
                    formValidators.requiredArray(t('General.required')),
                  )}
                />
                <div>
                  <FieldArray name={`${nameDate}.dateHourRange`}>
                    {({ fields: fieldsHour }) =>
                      fieldsHour.map((nameHour, indexHour) => (
                        <div
                          className="anys-date-overrides-form__date__fields"
                          key={nameHour}
                        >
                          <TimeInputField
                            name={`${nameHour}.startTime`}
                            validateFields={[]}
                            validate={composeValidators(
                              formValidators.requiredTime(t('General.required')),
                              utils.hasOverlapTime(
                                t('Availability.timeOverlapping'),
                                `specificEntries.[${indexDate}].dateHourRange`,
                                indexHour,
                              ),
                            )}
                          />
                          <span>-</span>
                          <TimeInputField
                            name={`${nameHour}.endTime`}
                            validate={composeValidators(
                              requiredTime(t('General.required')),
                              utils.timeIsAfterValidation(
                                t('Availability.endDateValidation'),
                                `specificEntries[${indexDate}].dateHourRange[${indexHour}].startTime`,
                              ),
                            )}
                          />
                          <Button
                            variant="icon"
                            icon={<TrashIcon />}
                            className="anys-weekly-hours-form__delete-btn-date"
                            onClick={() => {
                              if (fieldsHour.length === 1) {
                                fieldsDate.remove(indexDate);
                                if (fieldsDate.length === 1) {
                                  change('dataOverride', []);
                                }
                              } else {
                                fieldsHour.remove(indexHour);
                              }
                            }}
                          />
                        </div>
                      ))
                    }
                  </FieldArray>
                </div>

                <Button
                  className="anys-date-overrides-form__date__plus-icon"
                  type="button"
                  variant="icon"
                  icon={<PlusGradient />}
                  onClick={() => {
                    push(`${nameDate}.dateHourRange`);
                  }}
                />
              </div>
              {values.specificEntries.length > 1 && <hr></hr>}
            </React.Fragment>
          ))
        }
      </FieldArray>

      <Button
        onClick={() => {
          push('specificEntries', {
            dateHourRange: [
              {
                startTime: '',
                endTime: '',
              },
            ],
          });
        }}
        variant="outline"
      >
        {t('Availability.addCustomDate')}
      </Button>
    </form>
  );
};

export default CustomAvailabilityForm;
