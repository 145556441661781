import React, { forwardRef, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { Button, Loader } from 'ncoded-component-library';
import { Trans, useTranslation } from 'react-i18next';
import Modal, { ModalRef } from 'components/Modal';
import { CLIENT_FEE_PERCENT } from 'utils/pricing';
import VerifiedIcon from 'icons/VerifiedProfile.icon';
import useKycVerification from 'hooks/useKycVerification';

import './KycVerifyModal.styles.scss';

type KycVerifyModalProps = {
  className?: string;
  closeModal: () => void;
};

const KycVerifyModal: React.ForwardRefRenderFunction<
  ModalRef,
  KycVerifyModalProps
> = (props, ref) => {
  const { className, closeModal } = props;

  const { t } = useTranslation();

  const classes = classNames('anys-kyc-verify-modal', className);

  const {
    hasVeriffStarted,
    isVerifyInitInProgress,
    veriffStatus,
    initiateVeriff,
  } = useKycVerification();

  const veriffStatusMsg = useMemo(() => {
    if (!veriffStatus || hasVeriffStarted) return null;

    switch (veriffStatus) {
      case 'started':
        break;

      case 'submitted':
        return t('KycVerifyModal.verificationSubmitted');

      case 'approved':
        return t('KycVerifyModal.verificationApproved');

      case 'declined':
        return t('KycVerifyModal.verificationDeclined');

      case 'resubmission_requested':
        return t('KycVerifyModal.verificationResubmitRequested');

      case 'expired':
      case 'abandoned':
        return t('KycVerifyModal.verificationExpired');

      case 'review':
        return t('KycVerifyModal.verificationUnderReview');

      default:
        break;
    }
  }, [hasVeriffStarted, t, veriffStatus]);

  const canShowVerifyButton =
    !hasVeriffStarted &&
    veriffStatus !== 'submitted' &&
    veriffStatus !== 'approved' &&
    veriffStatus !== 'review' &&
    veriffStatus !== 'declined';

  const onVerifyClick = useCallback(() => {
    initiateVeriff();
  }, [initiateVeriff]);

  return (
    <Modal
      ref={ref}
      type="no-action"
      modalName="kyc-verify-modal"
      title={t('General.verification')}
      className={classes}
      keepOpenOnRefresh={false}
      footer={
        canShowVerifyButton ? (
          <div className="flex column ac gap-8">
            <Button onClick={onVerifyClick} disabled={isVerifyInitInProgress}>
              {t('General.verify')}
            </Button>
            <span>{t('General.shortMinutesVal', { val: 2 })}</span>
          </div>
        ) : !hasVeriffStarted ? (
          <Button onClick={closeModal}>{t('General.close')}</Button>
        ) : null
      }
    >
      {isVerifyInitInProgress ? (
        <Loader inline overlay={false} size="medium" />
      ) : (
        <>
          {veriffStatusMsg}

          {hasVeriffStarted && (
            <div className="mb-24">{t('KycVerifyModal.veriffExplanation')}</div>
          )}

          <div id="veriff-root"></div>

          {!veriffStatusMsg && !hasVeriffStarted && (
            <div>
              <Trans
                i18nKey="KycVerifyModal.providerInfo"
                components={{ b: <b /> }}
              />
              <p>
                <Trans
                  i18nKey="KycVerifyModal.clientInfo"
                  values={{
                    unverifiedClientFee: CLIENT_FEE_PERCENT.UNVERIFIED,
                    verifiedClientFee: CLIENT_FEE_PERCENT.VERIFIED,
                  }}
                  components={{ b: <b /> }}
                />
              </p>
              <p>
                <Trans
                  i18nKey="KycVerifyModal.badgeInfo"
                  components={{
                    badge: (
                      <VerifiedIcon className="anys-kyc-verify-modal__verif-badge" />
                    ),
                  }}
                />
              </p>
            </div>
          )}
        </>
      )}
    </Modal>
  );
};

export default forwardRef(KycVerifyModal);
