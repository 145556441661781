import React, { useContext } from 'react';
import ThemeContext from 'providers/Theme/Theme.context';
import ContentLoader from 'react-content-loader';

type OffersLoadingProps = { length?: number };

const OffersLoading: React.FC<OffersLoadingProps> = ({ length = 6 }) => {
  const { theme } = useContext(ThemeContext);

  const backgroundColor = theme === 'dark' ? '#909194' : '#e7f1fc';
  const foregroundColor = theme === 'dark' ? '#333' : '#ffffff';

  return (
    <div className="anys-offers-page__loading-items">
      {Array.from({ length }).map((_, ind) => (
        <ContentLoader
          key={ind}
          width="100%"
          backgroundColor={backgroundColor}
          foregroundColor={foregroundColor}
          radius="14px"
        >
          <rect x="0" y="0" rx="14" ry="14" width="100%" height="100%" />
        </ContentLoader>
      ))}
    </div>
  );
};

export default OffersLoading;
