import { Contract } from 'models/Contract';
import { InboxItem, InboxItemType } from 'models/Inbox';
import { Job, JobPost } from 'models/Job';
import { User } from 'models/User';

// 1024 = above-tablet-landscape, 1280 = laptop
export const INBOX_CHAT_BREAKPOINT: [number, number] = [1023, 1279];

// Used only for draft job-posts so we can avoid showing
// 'draft save' modal when changing chats
export const INBOX_CHAT_USER_ITEM_LINK_ID = 'chat-user-link';

export const INBOX_ITEM_RELATIONS =
  'otherUser.profileImage,mainSkill,timeAndPricing,jobPost,jobPost.user.profileImage,job,contract,notifications';

export const getOtherPartyForEntity = (
  currentUser: Partial<User>,
  inboxItem: InboxItem,
) => {
  const { type, otherUser, isFavourite, jobPost } = inboxItem;

  if (type === 'job-post') {
    if (isFavourite) return jobPost.user;

    return currentUser;
  }

  return otherUser;
};

export const getEntityForItem = (item: InboxItem) => {
  const { type, jobPost, job, contract } = item;

  switch (type) {
    case 'job-post':
      return jobPost;

    case 'job':
      return job;

    case 'contract':
      return contract;

    default:
      break;
  }

  return null;
};

export const getEntityIdForItem = (item: Partial<InboxItem>) => {
  const { type, jobPostId, job, contract, commonId } = item;

  switch (type) {
    case 'job-post':
      return jobPostId;

    case 'job':
      return commonId || job?.commonId;

    case 'contract':
      return commonId || contract?.commonId;

    default:
      break;
  }

  return null;
};

export const getEntityInboxState = (
  entity: JobPost | Job | Contract,
  type: InboxItemType,
  deepContractState = true,
) => {
  if (!entity) return null;

  switch (type) {
    case 'job-post':
      // 'state' will be Draft or Ad (Pre offer)
      return (entity as JobPost).isSigned ? 'Offer' : (entity as JobPost).state;

    case 'job': {
      const { isSignedByClient, isSignedByProvider } = entity as Job;

      if (isSignedByClient || isSignedByProvider) return 'Offer';

      return 'Ad';
    }

    case 'contract':
      return deepContractState ? (entity as Contract).state : 'Contract';

    default:
      break;
  }

  return null;
};
