import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import NeedProvideCard from 'router/subrouters/NeedProvide/pages/NeedProvidePage/components/NeedProvideCard';
import api from 'api';
import showToast from 'modules/showToast';
import { useTranslation } from 'react-i18next';
import OverlaySpinner from 'components/OverlaySpinner';
import { Field, Form, FormSpy } from 'react-final-form';
import RadioButtonField from 'components/RadioButtonField';
import {
  Boost,
  BoostFormValues,
  BoostType,
  JOB_POST_BOOST_TYPE,
  NO_DESIGN_BOOST,
  PositionBoost,
  PositionBoostReq,
} from 'models/Boosts';
import AnyCoinConversion from 'components/AnyCoinConversion';
import PositionBoosting from './components/PositionBoosting';
import { BOOST_POST_DESIGN_COIN_COST, getUnitCostForBoost } from 'utils/boosts';
import BoostActions from './components/BoostActions';
import { FormApi, FormState } from 'final-form';
import { coinToMoneyInUnits } from 'utils/boosts';
import useInboxLink from 'router/subrouters/Inbox/hooks/useInboxLink';
import { ReactComponent as UploadIcon } from 'icons/upload.svg';
import ImagesPreviewModal from 'components/ImagesPreviewModal';
import { ModalRef } from 'components/Modal';
import ImageInput from 'components/ImageInput';
import DropzoneTrigger from 'components/Dropzone/DropzoneTrigger';
import Dropzone from 'components/Dropzone';
import { Button } from 'ncoded-component-library';
import { NonImageFile, Picture } from 'models/User';
import TrashIcon from 'icons/Trash.icon';
import { useJobPost } from 'router/subrouters/JobPost/pages/JobPost/JobPost.page';
import formValidators from 'utils/formValidators';
import camelCase from 'lodash/camelCase';
import { AxiosError } from 'axios';
import ValidationError from 'components/ValidationError';
import { removeInvalidProps } from 'utils/job';
import { convertUnitToSubUnit, unitDivisor } from 'utils/currency';

import './EditBoost.styles.scss';
import './EditBoost.styles.responsive.scss';

const MAX_FILE_SIZE_MB = 30;

type PhotoVideo =
  | typeof JOB_POST_BOOST_TYPE.PHOTO
  | typeof JOB_POST_BOOST_TYPE.VIDEO;

const VIDEO_PLACEHOLDER_KEY = 'video-placeholder';

const POST_DESIGN_PREVIEW_BOOSTS: Record<
  BoostType | typeof NO_DESIGN_BOOST,
  Boost | null
> = {
  '': null,
  'Size-50': {
    type: JOB_POST_BOOST_TYPE.SIZE_50,
    createdAt: '',
    id: 0,
    price: 0,
  },
  'Size-100': {
    type: JOB_POST_BOOST_TYPE.SIZE_100,
    createdAt: '',
    id: 0,
    price: 0,
  },
  Photo: {
    type: JOB_POST_BOOST_TYPE.PHOTO,
    createdAt: '',
    id: 0,
    price: 0,
    file: {
      id: 0,
      name: 'Boost placeholder',
      type: 'image',
      key: 'boost-placeholder',
      createdAt: '',
      updatedAt: '',
      url: '/assets/images/image-boost-placeholder.png',
      size: '',
      resizedUrls: { '320': '/assets/images/image-boost-placeholder.png' },
    },
  },
  Video: {
    // Use photo because it's easier to display placeholder
    type: JOB_POST_BOOST_TYPE.PHOTO,
    createdAt: '',
    id: 0,
    price: 0,
    file: {
      id: 0,
      name: 'Boost placeholder',
      type: 'video',
      key: VIDEO_PLACEHOLDER_KEY,
      createdAt: '',
      updatedAt: '',
      url: '/assets/images/video-boost-placeholder.png',
      resizedUrls: { '320': '/assets/images/video-boost-placeholder.png' },
      size: '',
    },
  },
};

const checkIfPositionBoostSelected = (values: BoostFormValues) => {
  const { skillIds, locations, startDate, endDate } = values;

  const hasSkills = skillIds?.length > 0;
  const hasLocations = locations?.length > 0;
  const hasStartEndTime = startDate && endDate;

  return hasSkills || hasLocations || hasStartEndTime;
};

type EditBoostProps = {
  className?: string;
};

const EditBoost: React.FC<EditBoostProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [totalAnyCoins, setTotalAnyCoins] = useState(0);
  const [uploadOpenFor, setUploadOpenFor] = useState<PhotoVideo>();
  const [showBoostDropzone, setShowBoostDropzone] = useState(false);
  const [selectedFileIndex, setSelectedFileIndex] = useState<number>(-1);
  const [fileToUpload, setFileToUpload] = useState<{
    file: File;
    fileFor: PhotoVideo;
  }>(null);
  const [filePreviewUrl, setFilePreviewUrl] = useState<string>(null);
  const [isSendInProgress, setIsSendInProgress] = useState(false);

  const formApi = useRef<FormApi<BoostFormValues>>();
  const uploadModalRef = useRef<ModalRef>();
  const contentRef = useRef<HTMLDivElement>();

  const { id: jobPostId } = useParams<{ id: string }>();

  const { createEntityLink } = useInboxLink();

  const { jobPost, sendJobPost } = useJobPost();

  const classes = classNames('anys-edit-boost', className);

  const {
    timeAndPricing,
    attachments,
    boosts: initialBoosts,
    positionBoost: initialPositionBoost,
    skills: initialJobSkills,
    state,
    sendToTimeline,
    sendToUsers,
  } = jobPost || {};

  const { currency } = timeAndPricing || {};

  const hasSendToData = sendToTimeline || sendToUsers?.length > 0;

  const isBoostCanceled = initialPositionBoost?.state === 'canceled';

  const hasInitialDesignBoost = initialBoosts?.length > 0;

  const isInbox = location.pathname?.startsWith('/inbox');

  const budgetSpent =
    initialPositionBoost?.initialBudget - initialPositionBoost?.currentBudget;

  const initialValues: Partial<BoostFormValues> = useMemo(
    () => ({
      type: initialBoosts?.[0]?.type,
      budget: initialPositionBoost?.currentBudget
        ? `${initialPositionBoost.currentBudget / 100}`
        : undefined,
      startDate: initialPositionBoost?.startDate
        ? new Date(initialPositionBoost.startDate)
        : undefined,
      endDate: initialPositionBoost?.endDate
        ? new Date(initialPositionBoost.endDate)
        : undefined,
      defineTime: initialPositionBoost?.defineTime,
      locations: initialPositionBoost?.locations?.length
        ? initialPositionBoost.locations
        : undefined,
      skillIds: initialPositionBoost?.skills?.map(({ id }) => id),
    }),
    [initialBoosts, initialPositionBoost],
  );

  const postDesignTitles: Record<BoostType | typeof NO_DESIGN_BOOST, string> =
    useMemo(
      () => ({
        '': t('General.defaultSize'),
        'Size-50': t('General.increasedPostSizeVal', { val: '50%' }),
        'Size-100': t('General.increasedPostSizeVal', { val: '100%' }),
        Photo: t('General.postWithImage'),
        Video: t('General.postWithVideo'),
      }),
      [t],
    );

  const postDesigns = useMemo(
    () =>
      [NO_DESIGN_BOOST, ...Object.values(JOB_POST_BOOST_TYPE)].map((type) => {
        return {
          radioName: type,
          postTitle: postDesignTitles[type],
          radioLabel: (
            <span className="anys-edit-boost__radio-label-wrapper">
              <span className="anys-edit-boost__radio-label-wrapper__mobile-label">
                {postDesignTitles[type]}
              </span>
              <AnyCoinConversion
                anyCoinAmount={BOOST_POST_DESIGN_COIN_COST[type]}
                anyCoinCostWithoutSubunits={getUnitCostForBoost(type)}
              />
            </span>
          ),
          boost: POST_DESIGN_PREVIEW_BOOSTS[type],
        };
      }),
    [postDesignTitles],
  );

  const draftPostLink = isInbox
    ? createEntityLink('edit', 'job-post', +jobPostId)
    : `/job-post/edit/${jobPostId}`;

  const nonDraftPostLink = isInbox
    ? createEntityLink('view', 'job-post', +jobPostId)
    : `/job-post/${jobPostId}`;

  const cancelLink = state === 'Draft' ? draftPostLink : nonDraftPostLink;

  const existingBoostFiles = useMemo(() => {
    if (!uploadOpenFor) return attachments || [];

    const attachmentFiles = attachments?.filter((file) =>
      uploadOpenFor === 'Photo'
        ? 'resizedUrls' in file
        : file.type?.includes('video'),
    );

    return attachmentFiles || [];
  }, [attachments, uploadOpenFor]);

  const allBoostFiles = useMemo(() => {
    if (!fileToUpload) return existingBoostFiles;

    const { file } = fileToUpload;

    const isImage = file.type?.includes('image/');

    const parsedFile = {
      id: Date.now(),
      name: file.name,
      url: filePreviewUrl,
      ...(isImage && { resizedUrls: { '320': filePreviewUrl } }),
    } as Partial<Picture>;

    const filesToReturn = [parsedFile, ...existingBoostFiles];

    if (uploadOpenFor === 'Photo') return filesToReturn as Picture[];

    return filesToReturn as NonImageFile[];
  }, [existingBoostFiles, filePreviewUrl, fileToUpload, uploadOpenFor]);

  const addFileToBoostPreview = useCallback(
    (boost: Boost | null): Boost | null => {
      if (!boost || boost.type === 'Size-50' || boost.type === 'Size-100')
        return boost;

      const { file, fileId } = formApi.current?.getState()?.values || {};

      if (file) {
        const isImageFile = file.type?.includes('image/');

        if (
          isImageFile &&
          'file' in boost &&
          boost.file.key !== VIDEO_PLACEHOLDER_KEY
        )
          return {
            ...boost,
            type: 'Photo' as const,
            file: {
              ...boost.file,
              id: file.lastModified,
              url: filePreviewUrl,
              name: file.name,
              type: file.type,
              resizedUrls: { '320': filePreviewUrl },
            },
          };

        if (
          !isImageFile &&
          'file' in boost &&
          boost.file.key === VIDEO_PLACEHOLDER_KEY
        ) {
          const boostWithVideo = {
            ...boost,
            type: 'Video' as const,
            file: {
              ...boost.file,
              id: file.lastModified,
              url: filePreviewUrl,
              name: file.name,
              type: file.type,
            },
          };

          if ('resizedUrls' in boostWithVideo.file)
            delete boostWithVideo.file.resizedUrls;

          return boostWithVideo;
        }
      }

      if (fileId) {
        const existingFile =
          existingBoostFiles.find(({ id }) => id === fileId) || {};

        const isPhotoFile = 'resizedUrls' in existingFile;

        // If the selected file is a photo, and we are currently on the photo boost
        if (
          isPhotoFile &&
          'file' in boost &&
          boost.file.key !== VIDEO_PLACEHOLDER_KEY
        )
          return {
            ...boost,
            file: existingFile,
          } as Boost;

        // If the selected file is not a photo, and we are currently on the video boost
        if (
          !isPhotoFile &&
          'file' in boost &&
          boost.file.key === VIDEO_PLACEHOLDER_KEY
        )
          return {
            ...boost,
            type: 'Video',
            file: existingFile,
          } as Boost;
      }

      return boost;
    },
    [existingBoostFiles, filePreviewUrl],
  );

  const resetStates = useCallback(() => {
    setUploadOpenFor(null);
    setSelectedFileIndex(-1);
    setFileToUpload(null);
  }, []);

  const onSubmit = useCallback(
    async (values: BoostFormValues) => {
      const {
        locationInput,
        type,
        file,
        fileId,
        budget,
        skillIds,
        locations,
        startDate,
        endDate,
        ...rest
      } = values;

      setIsSendInProgress(true);
      OverlaySpinner.show('.anys-edit-boost');

      if (!totalAnyCoins) {
        if (hasSendToData) {
          await sendJobPost(jobPost, jobPost.isSigned);
        } else {
          navigate(nonDraftPostLink, { replace: true });
        }

        setIsSendInProgress(false);
        OverlaySpinner.hide('.anys-edit-boost');

        return;
      }

      try {
        if (type && !hasInitialDesignBoost) {
          const fileMetaMaybe: [File, number] =
            type === 'Photo' || type === 'Video'
              ? [file, fileId]
              : [undefined, undefined];

          const { data } = await api.jobPost.boostJobPost(
            +jobPostId,
            type,
            ...fileMetaMaybe,
          );

          jobPost.boosts = data.jobPost.boosts;
        }

        const hasPositionBoost = checkIfPositionBoostSelected(values);

        let jobPositionBoostRes: Partial<PositionBoost>;

        if (hasPositionBoost) {
          const boostBody: PositionBoostReq = {
            ...rest,
            startDate: startDate?.toISOString(),
            endDate: endDate?.toISOString(),
            locations: locations?.map(removeInvalidProps),
            skillIds,
            jobPostId: jobPost.id,
            budget: budget
              ? convertUnitToSubUnit(+budget, unitDivisor('USD'))
              : undefined,
          };

          if (initialPositionBoost?.id) {
            const { data } = await api.positionBoosts.updateBoost(
              initialPositionBoost.id,
              boostBody,
            );
            jobPositionBoostRes = data;
          } else {
            const { data } = await api.positionBoosts.initBoost(boostBody);

            jobPositionBoostRes = data;
          }
        }

        if (jobPositionBoostRes) {
          jobPost.positionBoost = {
            ...jobPost.positionBoost,
            ...jobPositionBoostRes,
          };
        }

        if (hasSendToData) {
          await sendJobPost(jobPost, jobPost.isSigned);
        } else {
          navigate(nonDraftPostLink, { replace: true });
        }
      } catch (error) {
        const err = error as AxiosError;

        showToast(
          'error',
          t('General.error'),
          err?.response?.data?.error?.message,
        );
        console.error(error);
      } finally {
        setIsSendInProgress(false);
        OverlaySpinner.hide('.anys-edit-boost');
      }
    },
    [
      hasInitialDesignBoost,
      hasSendToData,
      initialPositionBoost?.id,
      jobPost,
      jobPostId,
      navigate,
      nonDraftPostLink,
      sendJobPost,
      t,
      totalAnyCoins,
    ],
  );

  const onFormValuesChange = useCallback(
    (formState: FormState<BoostFormValues>) => {
      if (!formState.touched) return;

      const { type, budget } = formState.values;

      const postDesignCost =
        (!hasInitialDesignBoost && getUnitCostForBoost(type)) || 0;

      setTotalAnyCoins((+budget || 0) + postDesignCost);
    },
    [hasInitialDesignBoost],
  );

  const onOpenBoostUpload = useCallback((boostType: PhotoVideo) => {
    setUploadOpenFor(boostType);
    setSelectedFileIndex(-1);
    setFileToUpload(null);

    uploadModalRef.current.open();
  }, []);

  const handleFileIndexChange = useCallback((currentIndex: number) => {
    setShowBoostDropzone(!(currentIndex >= 0));

    setSelectedFileIndex(currentIndex);
  }, []);

  // Called when modal closes
  const handleCloseBoostModal = useCallback(() => {
    resetStates();
  }, [resetStates]);

  const onBoostModalSave = useCallback(() => {
    formApi.current.batch(() => {
      if (fileToUpload?.file) {
        formApi.current.change('file', fileToUpload?.file);
        formApi.current.change('fileId', undefined);

        return;
      }

      const fileId = existingBoostFiles?.[selectedFileIndex]?.id;

      if (fileId) {
        formApi.current.change('file', undefined);
        formApi.current.change('fileId', fileId);
      }
    });

    // Closing the modal, fileToUpload state is reset
    uploadModalRef.current.close();
  }, [existingBoostFiles, fileToUpload, selectedFileIndex]);

  const onBoostModalCancel = useCallback(() => {
    uploadModalRef.current.close();
  }, []);

  const handleFileSelect = useCallback((boostFor: PhotoVideo, file: File) => {
    setSelectedFileIndex(0);
    setShowBoostDropzone(false);
    setFileToUpload({
      file,
      fileFor: boostFor,
    });

    setFilePreviewUrl(URL.createObjectURL(file));
  }, []);

  const removeBoostMedia = useCallback(() => {
    formApi.current.batch(() => {
      formApi.current.change('file', undefined);
      formApi.current.change('fileId', undefined);
    });
  }, []);

  const uploadBoostDropzone = useMemo(() => {
    if (!showBoostDropzone) return;

    return uploadOpenFor === 'Photo' ? (
      <ImageInput
        maxSizeInMB={MAX_FILE_SIZE_MB}
        trigger={
          <DropzoneTrigger
            formattedMaxSize={`${MAX_FILE_SIZE_MB} MB`}
            uploadFileTypeMsg={t('General.image').toLowerCase()}
          />
        }
        onUploadImage={(image, file) => {
          handleFileSelect('Photo', file);
        }}
        aspect={16 / 9}
      />
    ) : (
      <Dropzone
        name="files"
        maxSizeInMB={MAX_FILE_SIZE_MB}
        handleFiles={(files) => handleFileSelect('Video', files[0])}
        accept={{ 'video/*': [] }}
        uploadFileTypeMsg={t('General.video').toLowerCase()}
      />
    );
  }, [handleFileSelect, showBoostDropzone, t, uploadOpenFor]);

  const uploadBoostModalFooter = useMemo(
    () => (
      <div className="anys-edit-boost__upload-modal__bottom-actions">
        <Button onClick={onBoostModalSave}>{t('General.save')}</Button>
        <Button variant="link" onClick={onBoostModalCancel}>
          <span>{t('General.cancel')}</span>
        </Button>
      </div>
    ),
    [onBoostModalCancel, onBoostModalSave, t],
  );

  const checkIfPostDesignValid = useCallback((values: BoostFormValues) => {
    const { type, file, fileId } = values;

    if (!type || type === 'Size-100' || type === 'Size-50') return true;

    return file || fileId;
  }, []);

  const validateForm = useCallback(
    (values: BoostFormValues) => {
      const errors: Partial<Record<keyof BoostFormValues, string>> = {};

      const hasPositionBoost = checkIfPositionBoostSelected(values);
      const isValidPostDesign = checkIfPostDesignValid(values);

      const { type, budget, defineTime, startDate, endDate } = values;

      if (hasPositionBoost && !+budget) {
        errors.budget = t('General.valueRequired');
      }

      if (defineTime) {
        if (!startDate || !endDate) {
          errors.startDate = !startDate
            ? t('General.dateTimeRequired')
            : undefined;
          errors.endDate = !endDate ? t('General.dateTimeRequired') : undefined;
        }

        if (startDate && endDate && startDate >= endDate) {
          errors.endDate = t('Profile.endDateValidation');
        }
      }

      if (!hasInitialDesignBoost && !isValidPostDesign) {
        errors[type === 'Photo' ? 'photoError' : 'videoError'] = t(
          'General.fileRequired',
        );
      }

      return errors;
    },
    [checkIfPostDesignValid, hasInitialDesignBoost, t],
  );

  useEffect(() => {
    return () => {
      if (filePreviewUrl) {
        URL.revokeObjectURL(filePreviewUrl);
      }
    };
  }, [filePreviewUrl]);

  if (isBoostCanceled) {
    return <Navigate to="/job-post" replace />;
  }

  return jobPost ? (
    <div className={classes}>
      <div className="anys-edit-boost__title">
        <h1>{t('General.postDesign')}</h1>
        <div>{t('General.postDesignDesc')}</div>
      </div>
      <div className="anys-edit-boost__content" ref={contentRef}>
        <Form
          initialValues={initialValues}
          onSubmit={onSubmit}
          validate={validateForm}
          render={(formRenderProps) => {
            const { handleSubmit, form, values } = formRenderProps;

            formApi.current = form;

            const { defineTime } = values;

            return (
              <form onSubmit={handleSubmit}>
                <FormSpy
                  subscription={{ touched: true, values: true }}
                  onChange={onFormValuesChange}
                />
                {postDesigns.map((design) => {
                  const { postTitle, boost, radioName, radioLabel } = design;

                  const boostFinal = addFileToBoostPreview(boost);

                  const hasSelectedBoostMedia =
                    boostFinal &&
                    'file' in boostFinal &&
                    boostFinal.file.id > 0;

                  const boostCta =
                    !hasInitialDesignBoost &&
                    (radioName === 'Photo' || radioName === 'Video') ? (
                      hasSelectedBoostMedia ? (
                        <button
                          type="button"
                          className={classNames(
                            'anys-edit-boost__content__preview-card__upload-cta',
                            'anys-edit-boost__content__preview-card__upload-cta--remove',
                          )}
                          onClick={removeBoostMedia}
                        >
                          <TrashIcon fill="currentColor" />
                          {t('General.remove')}
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="anys-edit-boost__content__preview-card__upload-cta"
                          onClick={() => onOpenBoostUpload(radioName)}
                        >
                          <UploadIcon />
                          {radioName === 'Photo'
                            ? t('General.uploadImage')
                            : t('General.uploadVideo')}
                        </button>
                      )
                    ) : null;

                  const jobPostFinal =
                    hasInitialDesignBoost && initialBoosts[0].type === radioName
                      ? jobPost
                      : {
                          ...jobPost,
                          boosts: [boostFinal],
                        };

                  return (
                    <div
                      key={radioName}
                      className="anys-edit-boost__content__section"
                    >
                      <div>
                        <h2>{postTitle}</h2>
                        <NeedProvideCard
                          jobPost={jobPostFinal}
                          className="anys-edit-boost__content__preview-card"
                          disableAllActions
                          boostCta={boostCta}
                        />
                        <Field
                          type="hidden"
                          name={`${camelCase(radioName)}Error`}
                          render={(props) => {
                            const { input, meta } = props;

                            const { hasError, error } =
                              formValidators.getErrorFromMeta(meta);

                            return (
                              <>
                                <input {...input} />
                                <ValidationError
                                  className="mt-16"
                                  showError={hasError}
                                  error={error}
                                />
                              </>
                            );
                          }}
                        />
                      </div>

                      <RadioButtonField
                        name="type"
                        value={radioName}
                        label={radioLabel}
                        className="anys-edit-boost__content__radio"
                        disabled={hasInitialDesignBoost}
                      />
                    </div>
                  );
                })}
                <PositionBoosting
                  formApi={form}
                  formValues={values}
                  currency={currency}
                  isDefineTimeSelected={defineTime}
                  jobSelectedSkills={initialJobSkills}
                  budgetSpent={budgetSpent}
                />
              </form>
            );
          }}
        />
      </div>

      <BoostActions
        cancelLink={cancelLink}
        totalAnyCoins={totalAnyCoins}
        totalCost={coinToMoneyInUnits(totalAnyCoins)}
        onSubmit={() => {
          formApi.current?.submit();

          formValidators.scrollToFirstError(contentRef.current);
        }}
        inProgress={isSendInProgress}
        hasSendData={hasSendToData}
      />
      <ImagesPreviewModal
        className={classNames('anys-edit-boost__upload-modal', {
          'anys-edit-boost__upload-modal--with-dropzone': showBoostDropzone,
        })}
        modalRef={uploadModalRef}
        files={allBoostFiles}
        title={t('General.uploadPostEntity', {
          entity:
            uploadOpenFor === 'Photo'
              ? t('General.image').toLowerCase()
              : t('General.video').toLowerCase(),
        })}
        modalName="upload-image-modal"
        onClose={handleCloseBoostModal}
        onIndexChange={handleFileIndexChange}
        activeIndex={selectedFileIndex}
        footerContent={uploadBoostModalFooter}
        description={uploadBoostDropzone}
      />
    </div>
  ) : (
    <div className="anys-edit-boost--loading">
      {t('General.loadingDetails')}
    </div>
  );
};

export default EditBoost;
