import React, { useId } from 'react';

type TimesCircleProps = React.SVGProps<SVGSVGElement> & { gradient?: boolean };

const TimesCircleIcon: React.FC<TimesCircleProps> = ({
  gradient = true,
  ...restOfProps
}) => {
  const lgId = useId();
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      {...restOfProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99965 0.666992C13.583 0.666992 17.333 4.41699 17.333 9.00033C17.333 13.5837 13.583 17.3337 8.99965 17.3337C4.41632 17.3337 0.666321 13.5837 0.666321 9.00033C0.666321 4.41699 4.41632 0.666992 8.99965 0.666992ZM12.8796 5.11094C12.5865 4.81826 12.1116 4.81861 11.8189 5.11172L9.23509 7.69952L9.18234 7.7426C9.05287 7.8279 8.87706 7.81349 8.76327 7.69947L6.18071 5.11172C5.88803 4.81861 5.41316 4.81826 5.12005 5.11094L5.05287 5.18769C4.82892 5.4815 4.85098 5.90291 5.11927 6.1716L7.70495 8.76202C7.83483 8.89214 7.83483 9.10285 7.70496 9.23298L5.11927 11.8237C4.82659 12.1168 4.82694 12.5917 5.12005 12.8844C5.41316 13.1771 5.88803 13.1767 6.18071 12.8836L8.76322 10.2948C8.89404 10.1643 9.10509 10.1645 9.23514 10.2948L11.8189 12.8836C12.1116 13.1767 12.5865 13.1771 12.8796 12.8844L12.9468 12.8076C13.1707 12.5138 13.1486 12.0924 12.8804 11.8237L10.2942 9.233C10.1643 9.10287 10.1643 8.89212 10.2943 8.762L12.8804 6.1716C13.173 5.87849 13.1727 5.40361 12.8796 5.11094Z"
        fill={gradient ? `url(#${lgId})` : 'currentColor'}
      />
      <defs>
        <linearGradient
          id={lgId}
          x1="0.666321"
          y1="9.00033"
          x2="17.333"
          y2="9.00033"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF7BAC" />
          <stop offset="1" stopColor="#D142F4" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default TimesCircleIcon;
