import React, { useContext } from 'react';
import classNames from 'classnames';
import MobileHeaderContext from './providers/MobileHeader/MobileHeader.context';
import BackButton from 'components/BackButton';

import './MobileHeader.styles.scss';
import './MobileHeader.styles.responsive.scss';

const MobileHeader = () => {
  const { prevPath, content, styleOptions, onBackClick } =
    useContext(MobileHeaderContext);

  const {
    className,
    hasBorder = true,
    isHeaderShown = true,
    isBackButtonTransparent = false,
    absolutePosition = false,
    showBackButton,
  } = styleOptions;

  const classes = classNames(
    'anys-mobile-header',
    { 'anys-mobile-header--with-border': hasBorder },
    { 'anys-mobile-header--absolute': absolutePosition },
    className,
  );

  return isHeaderShown ? (
    <header className={classes}>
      {showBackButton && (
        <BackButton
          className="anys-mobile-header__back"
          onBackClick={onBackClick}
          withBorder={!isBackButtonTransparent}
          prevPath={prevPath}
        />
      )}

      {content}
    </header>
  ) : null;
};

export default MobileHeader;
