import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import MainTemplate from 'components/templates/MainTemplate';
import Card from 'components/Card';
import './TermAndConditions.styles.scss';

type TermAndConditionsProps = {
  className?: string;
};
const TermAndConditions = (props: TermAndConditionsProps) => {
  const { className } = props;
  const { t } = useTranslation();

  const classes = classNames('anys-terms-and-conditions', className);

  // eslint-disable-next-line jsx-a11y/anchor-has-content
  const a = useMemo(() => <a href="https://www.anyservice.io"></a>, []);

  return (
    <MainTemplate className={classes}>
      <Card>
        <h1>{t('TermsAndConditions.title')}</h1>
        <span className="anys-terms-and-conditions__subtitle">
          {t('TermsAndConditions.lastUpdate')}
        </span>

        <p className="anys-terms-and-conditions__imp-paragraph">
          <Trans
            i18nKey="TermsAndConditions.importantReadCarefully"
            components={{
              underline: <u />,
            }}
          />
        </p>
        <p className="anys-terms-and-conditions__paragraph">
          {t('TermsAndConditions.anyserviceWillProvideServices')}
        </p>

        {/* TERMS OF USE */}
        <h2>{t('TermsAndConditions.termsOfUse')}</h2>

        <p className="anys-terms-and-conditions__paragraph">
          <Trans
            i18nKey="TermsAndConditions.termsOfUseSystemRequirements"
            components={{
              b: <b />,
            }}
          />
        </p>

        <p className="anys-terms-and-conditions__paragraph">
          <Trans
            i18nKey="TermsAndConditions.termsOfUseSystemRequirementsAdditional"
            t={t}
            values={{ link: 'https://www.anyservice.io' }}
            components={{ a, ol: <ol />, li: <li />, b: <b /> }}
          />
        </p>

        <h2>{t('TermsAndConditions.exhibit')}</h2>
        <span className="anys-terms-and-conditions__sub-header">{t('TermsAndConditions.bindingArbitration')}</span>
        <p className="anys-terms-and-conditions__paragraph">{t('TermsAndConditions.exhibitSubtitle')}</p>

        <p className="anys-terms-and-conditions__paragraph anys-terms-and-conditions__paragraph--exhibit">
          <Trans
            i18nKey="TermsAndConditions.exhibitAdditional"
            t={t}
            values={{ link: 'https://www.anyservice.com' }}
            components={{ a, ol: <ol />, li: <li />, b: <b /> }}
          />
        </p>
      </Card>
    </MainTemplate>
  );
};

export default TermAndConditions;
