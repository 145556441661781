import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import AvailabilityModal from '../../components/AvailabilityModal/AvailabilityModal.component';
import ProfileContext from './Profile.context';

const ProfileProvider: React.FC<React.PropsWithChildren> = (props) => {
  const { children } = props;

  const { currentUser } = useContext(CurrentUserContext);
  const { id } = useParams<{ id: string }>();

  const isMine = !id || +id === currentUser?.id;

  const [showAvailabilityModal, setShowAvailabilityModal] = useState(false);

  return (
    <ProfileContext.Provider
      value={{ isMine, showAvailabilityModal, setShowAvailabilityModal }}
    >
      {children}
      <AvailabilityModal
        open={showAvailabilityModal}
        onClose={() => setShowAvailabilityModal(false)}
      />
    </ProfileContext.Provider>
  );
};

export default ProfileProvider;
