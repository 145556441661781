import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Link, LinkProps } from 'react-router-dom';
import GradientText from 'components/GradientText';

import './CustomLink.styles.scss';

type CustomLinkProps = {
  className?: string;
  styleType?: 'purple' | 'pink';
  variant?: 'solid' | 'outline';
} & LinkProps;

const CustomLink: React.FC<CustomLinkProps> = (props) => {
  const {
    children,
    className,
    to,
    styleType = 'purple',
    variant,
    ...rest
  } = props;

  const classes = useMemo(
    () =>
      classNames(
        'anys-custom-link',
        { [`anys-custom-link--${variant}`]: variant },
        className,
      ),
    [className, variant],
  );

  return (
    <Link to={to} className={classes} {...rest}>
      <GradientText gradientType={styleType}>{children}</GradientText>
    </Link>
  );
};

export default CustomLink;
