import React, { useContext, useMemo } from 'react';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import JobPostForm from 'router/subrouters/JobPost/pages/CreateEditJobPost/components/JobPostForm';
import { useTranslation } from 'react-i18next';
import { useContract } from '../Contract/Contract.page';
import { parseContractToJobPostFormValues } from '../../utils';
import UserScheduleContext from 'components/JobPostPreview/components/UserScheduleModal/UserScheduleProvider/UserSchedule.context';

const EditContract: React.FC = () => {
  const { t } = useTranslation();

  const { currentUser } = useContext(CurrentUserContext);
  const { selectedSchedule } = useContext(UserScheduleContext);

  const {
    contract: baseContract,
    savingContract,
    fetchingContract,
    updateContract,
  } = useContract();

  const contract = useMemo(
    () =>
      baseContract && {
        ...baseContract,
        prevVersion:
          baseContract?.prevAcceptedVersion || baseContract?.prevVersion,
      },
    [baseContract],
  );

  const isOwnJob =
    contract?.type === 'Provide'
      ? currentUser?.id === contract?.provider?.id
      : currentUser?.id === contract?.client?.id;

  const initialValues = useMemo(() => {
    const parsedContract = parseContractToJobPostFormValues(contract);

    if (selectedSchedule) {
      parsedContract.timeAndPricing = {
        ...parsedContract.timeAndPricing,
        ...selectedSchedule,
        importAvailability: false,
      };
    }

    return parsedContract;
  }, [contract, selectedSchedule]);

  return contract ? (
    <JobPostForm
      initialValues={initialValues}
      onSubmit={updateContract}
      inProgress={savingContract}
      isOwnJob={isOwnJob}
      provider={contract.provider}
      client={contract.client}
      isSignedByClient
      isSignedByProvider
      jobVersion={contract.version}
      jobCommonId={contract.commonId}
      prevVersion={contract.prevVersion}
      isDraft={false}
      versionState={contract.versionState}
    />
  ) : fetchingContract ? (
    <div className="card">{t('General.loadingDetails')}</div>
  ) : null;
};

export default EditContract;
