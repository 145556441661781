import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';

import './Map.styles.scss';

interface MapProps extends google.maps.MapOptions {
  className?: string;
}

const Map: React.FC<MapProps> = ({
  // TODO: if you want to add more functionality follow https://developers.google.com/maps/documentation/javascript/react-map
  center,
  zoom,
  className,
}) => {
  const ref = useRef();

  const classes = classNames('anys-map', className);

  useEffect(() => {
    new window.google.maps.Map(ref.current, {
      center,
      zoom,
    });
  }, [center, zoom]);

  return <div className={classes} ref={ref} />;
};

export default Map;
