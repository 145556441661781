import React from 'react';
import { DEGREE_TYPE, EducationType } from 'models/User';
import { useTranslation } from 'react-i18next';

export type EducationsPreviewProps = {
  education?: EducationType;
};

const EducationsPreview: React.FC<EducationsPreviewProps> = (props) => {
  const {
    education: { schoolName, degree, fieldOfStudy, startDate, endDate },
  } = props;

  const { t } = useTranslation();

  return (
    <div className="anys-profile-preview__body__preview">
      <span className="anys-profile-preview__body__preview__name">
        {schoolName}
      </span>
      <span className="anys-profile-preview__body__preview__value">
        {degree && DEGREE_TYPE[degree]
          ? t(`General.${degree.toLowerCase()}`)
          : degree}
         {fieldOfStudy}
        {`, ${startDate?.substring(0, 4)} -
                ${endDate?.substring(0, 4)}`}
      </span>
    </div>
  );
};

export default EducationsPreview;
