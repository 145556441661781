import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CurrencyConfig, Money } from 'constants/currency';
import { useTranslation } from 'react-i18next';
import {
  convertMoneyToNumber,
  ensureDotSeparator,
  ensureSeparator,
  formatNumber,
  getNumberFormatOptions,
  isMoney,
  isSafeNumber,
  truncateToSubUnitPrecision,
  unitDivisor,
} from 'utils/currency';
import { FieldInputProps, FieldMetaState } from 'react-final-form';
import Decimal from 'decimal.js';
import Input, { InputProps } from 'components/Input';
import DollarIcon from 'icons/Dollar.icon';
import { TFunction } from 'i18next';
import useFormatPrice from 'hooks/useFormatPrice';
import { CustomOmit } from 'types';

const getInitialStateValues = (
  t: TFunction,
  currencyConfig: CurrencyConfig,
  value: Pick<FieldInputProps<string | Money, HTMLElement>, 'value'>['value'],
  defaultValue: string | Money,
) => {
  const initialValue = isMoney(value)
    ? convertMoneyToNumber(value)
    : defaultValue;

  const hasInitialValue =
    typeof initialValue === 'number' && !isNaN(initialValue);

  // We need to handle number format - some locales use dots and some commas as decimal separator
  // TODO Figure out if this could be digged from React-Intl directly somehow
  const testSubUnitFormat = formatNumber(t, 1.1, currencyConfig);
  const usesComma = testSubUnitFormat.indexOf(',') >= 0;

  try {
    // whatever is passed as a default value, will be converted to currency string
    // Unformatted value is digits + localized sub unit separator ("9,99")
    const unformattedValue = hasInitialValue
      ? truncateToSubUnitPrecision(
          ensureSeparator(initialValue.toString(), usesComma),
          unitDivisor(currencyConfig.currency),
          usesComma,
        )
      : '';
    // Formatted value fully localized currency string ("$1,000.99")
    const formattedValue = hasInitialValue
      ? formatNumber(t, ensureDotSeparator(unformattedValue), currencyConfig)
      : '';

    return {
      formattedValue,
      unformattedValue,
      value: formattedValue,
      usesComma,
    };
  } catch (e) {
    console.error(e, 'currency-input-init-failed', {
      currencyConfig,
      defaultValue,
      initialValue,
    });

    return {
      formattedValue: '',
      unformattedValue: '',
      value: '',
      usesComma: false,
    };
  }
};

export type CurrencyInputProps = {
  className?: string;
  currencyConfig?: CurrencyConfig;
  name: string;
  defaultValue?: string;
  input: CustomOmit<
    FieldInputProps<string | Money>,
    'onBlur' | 'onFocus' | 'onChange'
  > & {
    onBlur?: (price: Money) => void;
    onFocus?: (price: Money) => void;
    onChange?: (price: Money) => void;
  };
  meta: FieldMetaState<string | Money>;
} & Omit<InputProps, 'defaultValue' | 'onBlur' | 'onFocus' | 'onChange'>;

const CurrencyInput: React.FC<CurrencyInputProps> = (props) => {
  const {
    className,
    currencyConfig = getNumberFormatOptions({
      style: 'decimal',
      currency: 'USD',
    }),
    defaultValue,
    input,
    placeholder,
    ...restOfProps
  } = props;
  const { t } = useTranslation();

  const [values, setValues] = useState(
    getInitialStateValues(t, currencyConfig, input.value, defaultValue),
  );

  const formatPrice = useFormatPrice();

  const placeholderText =
    placeholder || formatNumber(t, defaultValue || 0, currencyConfig);

  const onInputBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement, Element>) => {
      event.preventDefault();
      event.stopPropagation();

      const { onBlur: inputOnBlur } = input;

      setValues((prevState) => {
        if (inputOnBlur) {
          // If parent component has provided onBlur function, call it with current price.
          const price = formatPrice(prevState.unformattedValue);
          inputOnBlur?.(price);
        }

        return { ...prevState, value: prevState.formattedValue };
      });
    },
    [formatPrice, input],
  );

  const onInputFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement, Element>) => {
      event.preventDefault();
      event.stopPropagation();

      const { onFocus } = input;

      setValues((prevState) => {
        if (onFocus) {
          // If parent component has provided onFocus function, call it with current price.
          const price = formatPrice(prevState.unformattedValue);

          onFocus(price);
        }
        return { ...prevState, value: prevState.unformattedValue };
      });
    },
    [formatPrice, input],
  );

  const updateValues = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      try {
        const targetValue = event.target.value.trim();
        const isEmptyString = targetValue === '';
        const valueOrZero = isEmptyString ? '0' : targetValue;

        const targetDecimalValue = isEmptyString
          ? null
          : new Decimal(ensureDotSeparator(targetValue));

        const isSafeValue =
          isEmptyString ||
          (targetDecimalValue.isPositive() && isSafeNumber(targetDecimalValue));

        if (!isSafeValue) {
          throw new Error(`Unsafe money value: ${targetValue}`);
        }

        // truncate decimals to subunit precision: 10000.999 => 10000.99
        const truncatedValueString = truncateToSubUnitPrecision(
          valueOrZero,
          unitDivisor(currencyConfig.currency),
          values.usesComma,
        );
        const unformattedValue = !isEmptyString ? truncatedValueString : '';
        const formattedValue = !isEmptyString
          ? formatNumber(t, ensureDotSeparator(truncatedValueString), {
              style: 'decimal',
            })
          : '';

        setValues((oldValues) => ({
          ...oldValues,
          formattedValue,
          value: unformattedValue,
          unformattedValue,
        }));

        return { formattedValue, value: unformattedValue, unformattedValue };
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);

        // If an error occurs while filling input field, use previous values
        // This ensures that string like '12.3r' doesn't end up to a state.
        const { formattedValue, unformattedValue, value } = values;
        return { formattedValue, unformattedValue, value };
      }
    },
    [currencyConfig, t, values],
  );

  const onInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      event.stopPropagation();

      // Update value strings on state
      const { unformattedValue } = updateValues(event);
      // Notify parent component about current price change
      const price = formatPrice(unformattedValue);
      input.onChange(price);
    },
    [formatPrice, input, updateValues],
  );

  const value = useMemo(
    () =>
      typeof input.value === 'string' ? formatPrice(input.value) : input.value,
    [formatPrice, input.value],
  );

  useEffect(() => {
    if (isMoney(value)) {
      const inputValueAsNumber = convertMoneyToNumber(value);

      const { unformattedValue } = values;

      if (inputValueAsNumber !== +unformattedValue) {
        const formattedValue = formatNumber(
          t,
          ensureDotSeparator(`${inputValueAsNumber}`),
          currencyConfig,
        );

        setValues((oldValues) => ({
          ...oldValues,
          formattedValue,
          unformattedValue: `${inputValueAsNumber}`,
          value: formattedValue,
        }));
      }
    } else if (!value) {
      setValues((old) => ({
        ...old,
        value: '',
        formattedValue: '',
        unformattedValue: '',
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Input
      className={className}
      value={values.value}
      onChange={onInputChange}
      onBlur={onInputBlur}
      onFocus={onInputFocus}
      placeholder={placeholderText}
      prefixNode={<DollarIcon />}
      {...restOfProps}
      type="text"
    />
  );
};

export default CurrencyInput;
