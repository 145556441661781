import { AuthResBody } from '../models/Auth';
import storageService, { STORAGE_KEYS } from './storageService';

export default {
  get token() {
    return storageService.getItem(STORAGE_KEYS.TOKEN);
  },

  set token(value: string) {
    storageService.setItem(STORAGE_KEYS.TOKEN, value);
  },

  get refreshToken(): string {
    return storageService.getItem(STORAGE_KEYS.REFRESH_TOKEN);
  },

  set refreshToken(value: string) {
    storageService.setItem(STORAGE_KEYS.REFRESH_TOKEN, value);
  },

  saveAuthBody({ token, refreshToken }: Omit<AuthResBody, 'user'>) {
    this.token = token;
    this.refreshToken = refreshToken;
  },

  removeAuthBody() {
    storageService.removeItem(STORAGE_KEYS.TOKEN);
    storageService.removeItem(STORAGE_KEYS.REFRESH_TOKEN);
  },
};
