import type { OptionValue } from 'ncoded-component-library/build/components/molecules/Select/Select.component';
import { useMemo } from 'react';

export default function useFilteredOptions<T>(
  options: OptionValue<T>[],
  inputVal: string,
  async = false,
) {
  return useMemo(
    () =>
      async
        ? options
        : options.filter((o) =>
            RegExp(
              `${inputVal.replace(/[^a-z]/gi, '')} `
                .split('')
                .join('.*')
                .trim(),
              'gi',
            ).test(typeof o.label === 'string' ? o.label : o.label.text),
          ),
    [inputVal, options, async],
  );
}
