import React, { useId } from 'react';

import type {
  SelectProps,
  OptionValue,
} from 'ncoded-component-library/build/components/molecules/Select/Select.component';

import './SelectOptions.styles.scss';
import './SelectOptions.styles.responsive.scss';
import { RadioButton, SelectUtils } from 'ncoded-component-library';
import SelectLabel from 'components/SelectLabel';

type SelectOptionsProps<T> = Pick<
  SelectProps<T>,
  'options' | 'value' | 'multiple' | 'className'
> & {
  name?: string;
  type?: 'standard' | 'radio';
  onOptionCheck: (value: boolean, option: OptionValue<T>) => void;
};

function SelectOptions<T>(props: SelectOptionsProps<T>) {
  let { name } = props;

  const {
    className,
    type = 'radio',
    onOptionCheck,
    options,
    value,
    multiple,
  } = props;

  const nameId = useId();
  name = name || nameId;

  if (multiple) return null;

  return (
    <ul className={className}>
      {options.map((o, ind) => {
        const liValue = o.value;

        let checked: boolean;

        if (!value) {
          checked = false;
        } else {
          if (Array.isArray(value)) {
            checked = value.some(
              (val) => SelectUtils.isOptionValue(val) && val.value === liValue,
            );
          } else {
            checked =
              SelectUtils.getOptionValue(value).toString() === String(liValue);
          }
        }

        return (
          <li key={ind}>
            {type === 'radio' ? (
              <RadioButton
                name={name}
                label={<SelectLabel label={o.label} />}
                disabled={o.disabled}
                checked={checked}
                value={liValue.toString()}
                onChange={() => {
                  onOptionCheck(true, o);
                }}
              />
            ) : (
              ':TODO implement standard select option'
            )}
          </li>
        );
      })}
    </ul>
  );
}

export default SelectOptions;
