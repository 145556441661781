import { DependencyList, useContext, useEffect } from 'react';
import MobileHeaderContext from 'components/MobileHeader/providers/MobileHeader/MobileHeader.context';

export default function useHeaderBack(
  onBackClick: (prevPath: string | -1) => void,
  dependencies: DependencyList = [],
  condition = true,
) {
  const { setOnBackClick } = useContext(MobileHeaderContext);

  useEffect(() => {
    if (!condition) return;

    setOnBackClick(onBackClick);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setOnBackClick, condition, ...dependencies]);
}
