import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { Button, Input } from 'ncoded-component-library';
import { Form } from 'react-final-form';
import InputField from 'components/InputField';
import SelectField from 'components/SelectField';
import useHeaderStyles from 'hooks/useHeaderStyles';
import Modal, { ModalRef } from 'components/Modal';
import { useNavigate } from 'react-router-dom';
import useHeaderBack from 'hooks/useHeaderBack';
import api from 'api';
import { Account } from 'models/Account';
import PasswordField from 'components/PasswordField';
import { useTranslation } from 'react-i18next';
import credentialsService from 'services/credentialsService';
import formValidators from 'utils/formValidators';
import showToast from 'modules/showToast';
import { AxiosError } from 'axios';
import { InputAutocompleteLocationField } from 'components/InputAutocompleteLocation';
import { PlaceFormat } from 'models/User';
import getCountryCodes from 'constants/country';
import { FormApi } from 'final-form';

import './AccountSettings.styles.scss';
import './AccountSettings.styles.responsive.scss';
import googleMaps from 'utils/googleMaps';
import useMatchMedia from 'hooks/useMatchMedia';

const ACCOUNT_MAX_PASSWORD_LENGTH = 50;

const ACCOUNT_MAX_EMAIL_LENGTH = 50;

const ACCOUNT_MAX_NAME_LENGTH = 50;

const ACCOUNT_MAX_ADDRESS_LENGTH = 100;
const ACCOUNT_MAX_SUITE_LENGTH = 10;
const ACCOUNT_MAX_CITY_LENGTH = 100;
const ACCOUNT_MAX_STATE_LENGTH = 50;
const ACCOUNT_MAX_ZIP_LENGTH = 20;

const ACCOUNT_MAX_PHONE_NUMBER_LENGTH = 15;

const COUNTRY_OPTIONS = getCountryCodes().map((country) => ({
  label: country.name,
  value: country.name,
}));

type AccountSection = {
  title: string;
  action: boolean;
  subtitle?: string;
  fieldNames: (keyof FormValues)[];
  fields: React.ReactNode;
  className: string;
  formatDisplayValues: (values: FormValues) => string;
};

type FormValues = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  address: PlaceFormat;
  city: string;
  state: string;
  country: string;
  suite: string;
  zip: string;
  email: string;
  gender: string;
  currentPassword?: string;
};

type AccountSettingsProps = {
  className?: string;
};

const AccountSettings: React.FC<AccountSettingsProps> = (props) => {
  const { className } = props;

  const classes = classNames('anys-account-settings', className);

  const [accInfo, setAccInfo] = useState<Account>();
  const [openVerifyEmail, setOpenVerifyEmail] = useState(false);
  const [verifCode, setVerifCode] = useState<string>();
  const [alreadySentVerifCode, setAlreadySentVerifCode] = useState(false);
  const [verifError, setVerifError] = useState();
  const [activeForm, setActiveForm] = useState(0);

  const modalRef = useRef<ModalRef>();
  const verifyEmailModalRef = useRef<ModalRef>();

  const isPhablet = useMatchMedia('isPhablet');

  const rawPlaceRef = useRef<google.maps.places.PlaceResult>();

  const formApi = useRef<FormApi<FormValues>>();

  const { t } = useTranslation();

  const accountSettingsSections = useMemo((): AccountSection[] => {
    return [
      {
        title: t('AccountSettings.nameAndSurname'),
        action: true,
        subtitle: t('AccountSettings.nameAndSurnameSubtitle'),
        fieldNames: ['firstName', 'lastName'],
        formatDisplayValues: (values) =>
          (values.firstName || '') + ' ' + (values.lastName || ''),
        fields: (
          <>
            <InputField
              name="firstName"
              label={t('General.name')}
              validate={formValidators.required(t('General.requiredField'))}
              maxLength={ACCOUNT_MAX_NAME_LENGTH}
            />
            <InputField
              name="lastName"
              label={t('AccountSettings.surname')}
              validate={formValidators.required(t('General.requiredField'))}
              maxLength={ACCOUNT_MAX_NAME_LENGTH}
            />
          </>
        ),
        className: 'anys-account-settings__section__names',
      },
      {
        title: t('AccountSettings.address'),
        action: true,
        subtitle: t('AccountSettings.addressSubtitle'),
        fieldNames: ['address', 'city', 'state', 'country', 'zip', 'suite'],
        formatDisplayValues: (values) => {
          const { address, city, country, state, zip, suite } = values;

          const suiteMaybe = suite ? `${suite}, ` : '';
          const cityMaybe = city ? `${city}, ` : '';

          const topPart = `${suiteMaybe}${address?.street || ''}`;
          const middlePart = `${cityMaybe} ${state || ''} ${zip || ''}`;

          return `${topPart} \n ${middlePart} \n ${country || ''}`;
        },
        fields: (
          <>
            <SelectField
              name="country"
              label={t('AccountSettings.country')}
              validate={formValidators.required(t('General.requiredField'))}
              options={COUNTRY_OPTIONS}
            />
            <InputAutocompleteLocationField
              name="address"
              label={t('AccountSettings.streetAddress')}
              placeholder={t('AccountSettings.streetAddressPlaceholder')}
              validate={formValidators.required(t('General.requiredField'))}
              maxLength={ACCOUNT_MAX_ADDRESS_LENGTH}
              className="anys-account-settings__section__address__full-span"
              rawPlaceRef={rawPlaceRef}
              withFullAddress
              onChange={(place) => {
                if (!place) return;

                const { city, street, country, fullAddress } =
                  place as PlaceFormat;

                const addressState = googleMaps.getAddressComponent(
                  rawPlaceRef.current,
                  [
                    'administrative_area_level_1',
                    'administrative_area_level_2',
                    'administrative_area_level_3',
                    'administrative_area_level_4',
                    'administrative_area_level_5',
                  ],
                )?.long_name;

                const postalCode = googleMaps.getAddressComponent(
                  rawPlaceRef.current,
                  ['postal_code'],
                )?.long_name;

                const addressSuite = googleMaps.getAddressComponent(
                  rawPlaceRef.current,
                  ['street_number'],
                )?.long_name;

                const countryOption = COUNTRY_OPTIONS.find(
                  (option) => option.label === country,
                );

                formApi.current?.batch(() => {
                  formApi.current.change('city', city);
                  formApi.current.change('address', {
                    city,
                    country,
                    lat: null,
                    lng: null,
                    street,
                    fullAddress,
                  });

                  if (addressState)
                    formApi.current.change('state', addressState);
                  if (postalCode) formApi.current.change('zip', postalCode);
                  if (addressSuite)
                    formApi.current.change('suite', addressSuite);

                  if (countryOption)
                    formApi.current.change('country', countryOption.label);
                });
              }}
            />
            <InputField
              name="suite"
              label={t('AccountSettings.suite')}
              placeholder={t('AccountSettings.suitePlaceholder')}
              maxLength={ACCOUNT_MAX_SUITE_LENGTH}
              className="anys-account-settings__section__address__full-span"
            />
            <InputField
              name="city"
              label={t('AccountSettings.city')}
              validate={formValidators.required(t('General.requiredField'))}
              maxLength={ACCOUNT_MAX_CITY_LENGTH}
              placeholder={t('AccountSettings.cityPlaceholder')}
            />
            <InputField
              name="state"
              label={t('AccountSettings.state')}
              maxLength={ACCOUNT_MAX_STATE_LENGTH}
              placeholder={t('AccountSettings.statePlaceholder')}
            />
            <InputField
              name="zip"
              label={t('General.zipCode')}
              maxLength={ACCOUNT_MAX_ZIP_LENGTH}
              placeholder={t('General.zipCodePlaceholder')}
            />
          </>
        ),
        className: 'anys-account-settings__section__address',
      },
      {
        title: t('General.email'),
        action: accInfo?.pendingEmail ? false : true,
        fieldNames: ['email'],
        formatDisplayValues: (values) => values.email,
        fields: (
          <>
            {accInfo?.pendingEmail ? (
              <>
                <div className="anys-account-settings__section__email__requested">
                  <span className="anys-account-settings__section__email__requested__title">
                    {t('AccountSettings.alreadyRequestedToChangeEmail')}
                  </span>
                  <span className="anys-account-settings__section__email__requested__value">
                    {` ${accInfo?.pendingEmail} `}
                  </span>
                  <span className="anys-account-settings__section__email__requested__title">
                    {t('AccountSettings.pleaseVerify')}
                  </span>
                </div>

                <Button
                  onClick={() => {
                    verifyEmailModalRef.current.open();
                  }}
                >
                  <span>{t('General.verify')}</span>
                </Button>
              </>
            ) : (
              <>
                <section className="anys-account-settings__section__email__subtitle">
                  {t('AccountSettings.yourEmailAddress')}
                </section>
                <div className="anys-account-settings__section__email__inputs">
                  <InputField
                    name="email"
                    label={t('General.email')}
                    validate={formValidators.getEmailValidators(t)}
                    maxLength={ACCOUNT_MAX_EMAIL_LENGTH}
                  />
                  <PasswordField
                    name="currentPassword"
                    label={t('General.password')}
                    hidePasswordStrength
                    validate={formValidators.getPasswordValidators(t)}
                    maxLength={ACCOUNT_MAX_PASSWORD_LENGTH}
                  />
                </div>
              </>
            )}
          </>
        ),
        className: 'anys-account-settings__section__email',
      },
      {
        title: t('AccountSettings.gender'),
        action: true,
        subtitle: t('AccountSettings.genderSubtitle'),
        fieldNames: ['gender'],
        formatDisplayValues: (values) => {
          if (!values.gender) return '';

          return t(`AccountSettings.${values.gender}`);
        },
        fields: (
          <>
            <SelectField
              name="gender"
              options={[
                { value: 'male', label: t('AccountSettings.male') },
                { value: 'female', label: t('AccountSettings.female') },
                { value: 'other', label: t('AccountSettings.other') },
              ]}
              label={t('AccountSettings.gender')}
              placeholder={t('AccountSettings.gender')}
            />
          </>
        ),
        className: 'anys-account-settings__section__gender',
      },
      {
        title: t('AccountSettings.phone'),
        action: true,
        subtitle: t('AccountSettings.phoneSubtitle'),
        fieldNames: ['phoneNumber'],
        formatDisplayValues: (values) => values.phoneNumber,
        fields: (
          <>
            <InputField
              name="phoneNumber"
              label={t('AccountSettings.phoneNumber')}
              maxLength={ACCOUNT_MAX_PHONE_NUMBER_LENGTH}
            />
          </>
        ),
        className: 'anys-account-settings__section__phone',
      },
    ];
  }, [accInfo, t]);

  const getAccountInformation = useCallback(async () => {
    try {
      const { data } = await api.account.getAccountInformation();
      if (data) {
        setAccInfo(data);
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    getAccountInformation();
  }, [getAccountInformation]);

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        //3 is email form
        if (activeForm === 3) {
          const { data } = await api.account.changeEmail({
            email: formValues.email,
            currentPassword: formValues.currentPassword,
          });
          if (data) {
            setAccInfo((old) => ({ ...old, pendingEmail: formValues.email }));
          }
        } else {
          const { email, currentPassword, ...rest } = formValues;

          const valuesToUpdate: Partial<
            Omit<FormValues, 'address'> & {
              address: string;
              fullAddress: string;
            }
          > = {};

          accountSettingsSections[activeForm - 1].fieldNames.forEach(
            (fieldName) => {
              // The condition above will catch the
              // part with email and currentPassword
              const fieldFinal = fieldName as Exclude<
                typeof fieldName,
                'email' | 'currentPassword'
              >;

              const isAddressField = fieldFinal === 'address';

              const value = isAddressField
                ? rest[fieldFinal]?.street
                : rest[fieldFinal];

              if (isAddressField) {
                valuesToUpdate.fullAddress =
                  rest[fieldFinal]?.fullAddress || null;
              }

              valuesToUpdate[fieldName] = value || null;
            },
          );

          const { data } = await api.account.updateAccountInformation(
            valuesToUpdate,
          );

          if (data) {
            setActiveForm(0);
            setAccInfo((old) => ({ ...old, ...valuesToUpdate }));
          }
        }
      } catch (e) {
        const err = e as AxiosError;

        console.error(e);
        showToast(
          'error',
          err?.response?.data?.error?.message || t('General.updateError'),
        );
      }
    },
    [accountSettingsSections, activeForm, t],
  );

  const handleVerifyCode = useCallback(async () => {
    try {
      const { data } = await api.auth.verifyEmail({
        verificationKey: verifCode,
        email: accInfo?.pendingEmail,
      });
      if (data) {
        const { account, token, refreshToken } = data as any;

        setAccInfo(account);
        credentialsService.saveAuthBody({ token, refreshToken });
        verifyEmailModalRef.current.close();
        setActiveForm(0);

        setVerifCode('');
      }
    } catch (e: any) {
      if (e.response.data.error.message === 'WRONG_VERIFICATION_KEY') {
        setVerifError(e.response.data.error.message);
      }
    }
  }, [accInfo?.pendingEmail, verifCode]);

  const handleResendEmail = useCallback(async () => {
    try {
      const { data } = await api.account.resendVerifyCode(
        accInfo?.pendingEmail,
      );

      if (data) {
        setAlreadySentVerifCode(true);
      }
    } catch (e) {
      console.error(e);
    }
  }, [accInfo?.pendingEmail]);

  const navigate = useNavigate();

  useHeaderStyles({ showBackButton: true });

  const onBackClick = useCallback(() => {
    navigate('/profile', { replace: true });
  }, [navigate]);

  useHeaderBack(onBackClick);

  const initialValues: FormValues = useMemo(() => {
    return {
      firstName: accInfo?.firstName,
      lastName: accInfo?.lastName,
      phoneNumber: accInfo?.phoneNumber,
      address: accInfo?.address
        ? {
            city: accInfo?.city,
            country: accInfo?.country,
            street: accInfo?.address,
            fullAddress: accInfo?.fullAddress,
            lng: null,
            lat: null,
          }
        : null,
      zip: accInfo?.zip,
      suite: accInfo?.suite,
      city: accInfo?.city,
      state: accInfo?.state,
      country: accInfo?.country,
      email: accInfo?.email,
      gender: accInfo?.gender,
    };
  }, [accInfo]);

  return (
    <section className={classes}>
      <h1>{t('AccountSettings.accountSettings')}</h1>
      {accountSettingsSections.map((accountSection, index) => {
        const valuesToDisplay =
          accountSection.formatDisplayValues(initialValues);

        return (
          <section
            key={index}
            className={classNames('anys-account-settings__section', {
              'anys-account-settings__section--active':
                index + 1 === activeForm,
            })}
          >
            <section className="anys-account-settings__section__title">
              <div className="anys-account-settings__section__title__info-preview">
                <label>{accountSection.title}</label>
                {activeForm !== index + 1 &&
                valuesToDisplay?.trim()?.length > 0 ? (
                  <div className="anys-account-settings__section__title__info-preview__info">
                    {valuesToDisplay}
                  </div>
                ) : null}
              </div>
              <Button
                variant="link"
                className="anys-account-settings__section__edit-btn"
                onClick={() => {
                  if (index + 1 === activeForm) {
                    setActiveForm(0);
                  } else {
                    setActiveForm(index + 1);

                    if (isPhablet) modalRef.current.open();
                  }
                }}
              >
                <span>
                  {index + 1 === activeForm
                    ? t('General.cancel')
                    : t('General.edit')}
                </span>
              </Button>
            </section>

            <Form
              onSubmit={onSubmit}
              initialValues={initialValues}
              render={({ handleSubmit, values, invalid, form }) => {
                formApi.current = form;

                return index + 1 === activeForm ? (
                  <>
                    {accountSection.subtitle && (
                      <section className="anys-account-settings__section__subtitle">
                        {accountSection.subtitle}
                      </section>
                    )}

                    <form onSubmit={handleSubmit}>
                      <section className={accountSection.className}>
                        {accountSection.fields}
                      </section>
                      {accountSection.action && (
                        <Button
                          className="anys-account-settings__section__save-btn"
                          type="submit"
                          disabled={invalid}
                        >
                          <span>{t('General.save')}</span>
                        </Button>
                      )}
                    </form>
                  </>
                ) : (
                  <></>
                );
              }}
            />
          </section>
        );
      })}
      <Modal
        type="no-action"
        modalName="verify-email"
        isDrawer
        open={openVerifyEmail}
        ref={verifyEmailModalRef}
        onClose={() => {
          setOpenVerifyEmail(false);
        }}
        title={t('VerifyEmail.emailVerification')}
      >
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={(formRenderProps) => {
            const { handleSubmit, values } = formRenderProps;

            return (
              <form onSubmit={handleSubmit}>
                <p className="anys-account-settings__verify-email-form__subtitle">
                  {t('VerifyEmail.pharagraph')}
                </p>

                <Input
                  type="number"
                  name="verificationKey"
                  placeholder={t('VerifyEmail.codePlaceholder')}
                  disabled={!values.email}
                  value={verifCode}
                  onChange={(ev) => setVerifCode(ev.target.value)}
                  hasError={verifError}
                />

                {verifError && (
                  <div className="anys-account-settings__verify-email-form__wrong-verif-code">
                    {alreadySentVerifCode ? (
                      <span className="anys-account-settings__verify-email-form__wrong-verif-code__sent">
                        {t('AccountSettings.resendEmailSuccessfully')}
                      </span>
                    ) : (
                      <span className="anys-account-settings__verify-email-form__wrong-verif-code__error">
                        {t('AccountSettings.wrongVerifCode')}
                      </span>
                    )}
                    <Button
                      onClick={() => {
                        handleResendEmail();
                      }}
                    >
                      <span>{t('AccountSettings.resendEmail')}</span>
                    </Button>
                  </div>
                )}

                <Button
                  className="anys-account-settings__verify-email-form__verify-btn"
                  type="button"
                  disabled={!verifCode?.trim()}
                  onClick={() => {
                    handleVerifyCode();
                  }}
                >
                  <span>{t('General.verify')}</span>
                </Button>
              </form>
            );
          }}
        />
      </Modal>

      <Modal
        type="no-action"
        modalName="acc-settings-form"
        keepOpenOnRefresh={false}
        ref={modalRef}
        isDrawer
        onClose={() => {
          setActiveForm(0);
        }}
        title={accountSettingsSections[activeForm - 1]?.title}
        className={classNames('anys-account-settings__mobile-modal')}
      >
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({ handleSubmit, invalid }) => {
            return (
              <>
                <section className="anys-account-settings__section__subtitle-modal">
                  {accountSettingsSections[activeForm - 1]?.subtitle}
                </section>

                <form onSubmit={handleSubmit}>
                  <section
                    className={
                      accountSettingsSections[activeForm - 1]?.className
                    }
                  >
                    {accountSettingsSections[activeForm - 1]?.fields}
                  </section>
                  {accountSettingsSections[activeForm - 1]?.action && (
                    <Button
                      className="anys-account-settings__section__save-btn"
                      type="submit"
                      disabled={invalid}
                    >
                      <span>{t('General.save')}</span>
                    </Button>
                  )}
                </form>
              </>
            );
          }}
        />
      </Modal>
    </section>
  );
};

export default AccountSettings;
