import {
  FormJobTimeAndPricing,
  JobTimeAndPricing,
} from 'models/Job/TimeAndPricing';
import {
  FormJobTypeOfService,
  JobTypeOfService,
} from 'models/Job/TypeOfService';
import { LocationType } from 'models/User';
import { getJobEndDate, removeInvalidProps } from './job';
import { convertToMoney } from 'utils/currency';
import { FormJobDeadline, JobDeadline } from 'models/Job/Deadline';
import dates from './dates';
import { FormJobArbitration, JobArbitration } from 'models/Job/Arbitration';

export const parseFormTypeOfService = (
  formTypeOfService: FormJobTypeOfService,
  preserveLocationsCount = false,
): JobTypeOfService => {
  const { id, startLocation, endLocation, area, locationType, type, ...rest } =
    formTypeOfService || {};

  const isArea = locationType === 'Area';

  let locations: LocationType[];

  if (preserveLocationsCount || startLocation) {
    const { id, ...restOfStart } =
      startLocation || ({} as typeof startLocation);

    locations = [{ ...restOfStart, area: isArea ? area : null }];
  }

  if (preserveLocationsCount || endLocation) {
    const { id, ...restOfEnd } = endLocation || ({} as typeof endLocation);
    locations = [...(locations || []), restOfEnd];
  }

  return { ...rest, locationType, locations, type };
};

export const parseTypeOfService = (
  typeOfService: JobTypeOfService,
): FormJobTypeOfService => {
  const { locations, type, coordinates, ...rest } = typeOfService || {};

  let startLocation: LocationType;
  let endLocation: LocationType;
  let area: number;

  if (locations?.length) {
    startLocation = locations[0];
    area = locations[0].area;
    endLocation = locations[1];
  }

  return {
    ...rest,
    type,
    startLocation,
    endLocation,
    area,
  };
};

export const parseFormTimeAndPricing = (
  formTimeAndPricing: FormJobTimeAndPricing,
): JobTimeAndPricing | null => {
  if (!formTimeAndPricing) return null;

  const parsedTimeAndPricing = removeInvalidProps(formTimeAndPricing);

  const { price, type } = formTimeAndPricing;

  const priceAmount = price?.amount;

  if (priceAmount >= 0) {
    if (type === 'By project') {
      parsedTimeAndPricing.minTotalPrice = priceAmount;
      parsedTimeAndPricing.maxTotalPrice = priceAmount;
    } else {
      let minTotalPrice;
      let maxTotalPrice;

      if (formTimeAndPricing.pricingDurationType === 'Flexible') {
        minTotalPrice = priceAmount * formTimeAndPricing.minHours;
        maxTotalPrice = priceAmount * formTimeAndPricing.maxHours;
      } else {
        minTotalPrice = priceAmount * formTimeAndPricing.totalHours;
        maxTotalPrice = minTotalPrice;
      }

      parsedTimeAndPricing.minTotalPrice = minTotalPrice;
      parsedTimeAndPricing.maxTotalPrice = maxTotalPrice;
    }
  } else if (parsedTimeAndPricing) {
    Object.entries(parsedTimeAndPricing).forEach((entry) => {
      const [key, value] = entry;

      if (value === null)
        delete parsedTimeAndPricing[key as keyof typeof parsedTimeAndPricing];
    });
  }

  return {
    ...parsedTimeAndPricing,
    price: priceAmount,
    startDate: parsedTimeAndPricing?.startDate?.toISOString(),
    endDate:
      parsedTimeAndPricing?.endDate?.toISOString() ||
      getJobEndDate(parsedTimeAndPricing)?.toISOString(),
  };
};

export const parseTimeAndPricing = (
  timeAndPricing: JobTimeAndPricing,
): FormJobTimeAndPricing | null => {
  if (!timeAndPricing) return null;

  const {
    id: pricingId,
    type,
    startDate,
    endDate,
    price,
    currency,
    createdAt,
    updatedAt,
    ...restOfTimeAndPricing
  } = timeAndPricing;

  return {
    ...restOfTimeAndPricing,
    currency,
    price: price >= 0 && currency ? convertToMoney(price, currency) : null,
    type,
    startDate: startDate ? new Date(startDate) : null,
    endDate: endDate ? new Date(endDate) : null,
  };
};

export const parseFormDeadlines = (
  formDeadlines: FormJobDeadline[],
): JobDeadline[] => {
  return formDeadlines?.map((d) => ({
    ...removeInvalidProps(d),
    fromDate: d.fromDate ? dates.dateToISOString(d.fromDate) : null,
    toDate: d.toDate ? dates.dateToISOString(d.toDate) : undefined,
  }));
};

export const parseDeadlines = (deadlines: JobDeadline[]): FormJobDeadline[] => {
  return deadlines?.map((d) => ({
    ...d,
    fromDate: d.fromDate ? new Date(d.fromDate) : undefined,
    toDate: d.toDate ? new Date(d.toDate) : undefined,
  }));
};

export const parseFormArbitrationOptions = (
  formArbitrations: FormJobArbitration[],
): JobArbitration[] => {
  return formArbitrations?.map(({ arbitrators, ...restOfArb }) => ({
    ...removeInvalidProps(restOfArb),
    userIds: arbitrators?.length ? arbitrators.map(({ id }) => id) : null,
  }));
};
