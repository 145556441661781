import React from 'react';
import classNames from 'classnames';

import './Chip.styles.scss';

type ChipProps = {
  className?: string;
  gradient?: 'purple' | 'pink';
};

const Chip: React.FC<React.PropsWithChildren<ChipProps>> = (props) => {
  const { className, gradient = 'pink', children } = props;

  const classes = classNames(
    'anys-chip',
    { [`anys-chip--${gradient}`]: gradient },
    className,
  );

  return <span className={classes}>{children}</span>;
};

export default Chip;
