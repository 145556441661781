import { ObjectValues } from 'types';

const LANGUAGE = {
  ENGLISH: 'English',
  GERMAN: 'German',
  FRENCH: 'French',
  SPANISH: 'Spanish',
  DANISH: 'Danish',
  DUTCH: 'Dutch',
  FINNISH: 'Finnish',
  ITALIAN: 'Italian',
  SERBIAN: 'Serbian',
  SWEDISH: 'Swedish',
  RUSSIAN: 'Russian',
  JAPANESE: 'Japanese',
  PORTUGUESE: 'Portuguese',
  TURKISH: 'Turkish',
  PERSIAN: 'Persian',
  POLISH: 'Polish',
  CHINESE: 'Chinese',
  VIETNAMESE: 'Vietnamese',
  INDONESIAN: 'Indonesian',
  CZECH: 'Czech',
  KOREAN: 'Korean',
  ARABIC: 'Arabic',
  UKRAINIAN: 'Ukrainian',
  GREEK: 'Greek',
  HEBREW: 'Hebrew',
  ROMANIAN: 'Romanian',
  HUNGARIAN: 'Hungarian',
  THAI: 'Thai',
  SLOVAK: 'Slovak',
  BULGARIAN: 'Bulgarian',
  NORWEGIAN: 'Norwegian',
  CROATIAN: 'Croatian',
  LITHUANIAN: 'Lithuanian',
  SLOVENIAN: 'Slovenian',
  CATALAN: 'Catalan',
  ESTONIAN: 'Estonian',
  LATVIAN: 'Latvian',
  HINDI: 'Hindi',
} as const;

export type Language = ObjectValues<typeof LANGUAGE>;

const LANGUAGE_IN_NATIVE: Record<Language, string> = {
  English: 'English',
  German: 'Deutsch',
  French: 'Français',
  Spanish: 'Español',
  Danish: 'Dansk',
  Dutch: 'Nederlands',
  Finnish: 'Suomalainen',
  Italian: 'Italiano',
  Serbian: 'Srpski',
  Swedish: 'Svenska',
  Arabic: 'عربي',
  Bulgarian: 'Български',
  Catalan: 'Català',
  Chinese: '中国人',
  Croatian: 'Hrvatski',
  Czech: 'Čeština',
  Estonian: 'Eesti keel',
  Greek: 'Ελληνικά',
  Hebrew: 'עִברִית',
  Hindi: 'हिंदी',
  Hungarian: 'Magyar',
  Indonesian: 'Bahasa Indonesia',
  Japanese: '日本語',
  Korean: '한국인',
  Latvian: 'Latviski',
  Lithuanian: 'Lietuvių',
  Norwegian: 'Norsk',
  Persian: 'فارسی',
  Polish: 'Polski',
  Portuguese: 'Português',
  Romanian: 'Română',
  Russian: 'Русский',
  Slovak: 'Slovenský',
  Slovenian: 'Slovenščina',
  Thai: 'แบบไทย',
  Turkish: 'Türkçe',
  Ukrainian: 'Українська',
  Vietnamese: 'Tiếng Việt',
} as const;

const LANGUAGE_OPTIONS = Object.entries(LANGUAGE_IN_NATIVE).map(
  ([lang, langInNative]) => ({ value: lang as Language, label: langInNative }),
);

const LANGUAGE_PROFICIENCY = {
  BEGINNER: 'Beginner',
  INTERMEDIATE: 'Intermediate',
  ADVANCED: 'Advanced',
  NATIVE: 'Native',
} as const;

export type LanguageProficiency = ObjectValues<typeof LANGUAGE_PROFICIENCY>;

export { LANGUAGE, LANGUAGE_IN_NATIVE, LANGUAGE_PROFICIENCY, LANGUAGE_OPTIONS };
