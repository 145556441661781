import dayjs from 'dayjs';
import { FilterValue } from 'router/subrouters/Search/pages/Search/providers/Filters/Filters.provider';

export type FilterReqValue = Partial<FilterValue>;

const isDate = function (date: any) {
  return (
    typeof date !== 'boolean' &&
    new Date(date).toString() !== 'Invalid Date' &&
    !isNaN(+new Date(date))
  );
};

const idempotent = (value: any) => value;

const transformFilterValues = (
  values: Record<string, any>,
  withoutValue = true,
  transformations: Record<string, (val: any) => string> = {},
) =>
  Object.keys(values).reduce((acc, key) => {
    const value = (values as any)[key];
    let finalKey = key;
    if (!value) {
      return {
        ...acc,
        [finalKey]: null,
      };
    }

    const transform = transformations[finalKey] || idempotent;

    if (Array.isArray(value)) {
      if (!value.length && withoutValue) return acc;
      finalKey = `${key}[]`;
      return {
        ...acc,
        [finalKey]: value.length ? transform(value) : null,
      };
    }

    const isNumber = /^[0-9]+(\.[0-9]+)?$/.test(value);

    if (!isNumber && isDate(value)) {
      return {
        ...acc,
        [finalKey]: dayjs(transform(value)).format('YYYY-MM-DD HH:mm'),
      };
    }

    return {
      ...acc,
      [finalKey]: transform(value),
    };
  }, {});

const transformParamValue = (
  name: string,
  queryValue: string,
  parses: Record<string, (value: string) => any>,
) => {
  const isArray = name.includes('[]');
  const parse = parses[name] || idempotent;
  if (!queryValue) return isArray ? [] : queryValue;
  return isArray ? queryValue.split(',').map(parse) : parse(queryValue);
};

function prepareFiltersForRegistration(
  defaultValues: Record<string, any>,
  dbNames: Record<string, FilterReqValue>,
) {
  return Object.keys(transformFilterValues(defaultValues, false)).reduce(
    (acc, key) => ({
      ...acc,
      [key]: {
        ...dbNames[key],
        value: null,
      },
    }),
    {},
  );
}

export default {
  transformParamValue,
  transformFilterValues,
  prepareFiltersForRegistration,
};
