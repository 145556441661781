import React, { useCallback } from 'react';
import classNames from 'classnames';
import { EditorToolbarActions } from 'components/RichTextEditorField/models/RichTextEditor';

type EditorActionButtonProps = {
  actionName: EditorToolbarActions;
  disabled: boolean;
  checkIfActionActive: (actionName: EditorToolbarActions) => boolean;
  onActionClick: (actionName: EditorToolbarActions) => void;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onMouseDown?: React.MouseEventHandler<HTMLButtonElement>;
};

const EditorActionButton: React.FC<
  React.PropsWithChildren<EditorActionButtonProps>
> = (props) => {
  const {
    children,
    actionName,
    disabled,
    onActionClick,
    checkIfActionActive,
    // The dropdown passes these props
    onClick,
    onMouseDown,
  } = props;

  const isActive = checkIfActionActive(actionName);

  const classes = classNames({
    'editor-action-selected': isActive,
  });

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      onActionClick(actionName);

      onClick?.(event);
    },
    [actionName, onActionClick, onClick],
  );

  return (
    <button
      type="button"
      className={classes}
      onClick={handleClick}
      onMouseDown={onMouseDown}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default EditorActionButton;
