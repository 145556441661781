import React from 'react';

type BoldProps = React.SVGProps<SVGSVGElement>;

const Bold: React.FC<BoldProps> = (props) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.4375 8.85C11.8047 8.62638 12.1146 8.32006 12.3425 7.95547C12.5704 7.59087 12.7099 7.17808 12.75 6.75C12.7569 6.36291 12.6876 5.97825 12.5458 5.61797C12.4041 5.2577 12.1928 4.92888 11.9239 4.65029C11.6551 4.3717 11.334 4.14881 10.979 3.99433C10.624 3.83986 10.2421 3.75683 9.85499 3.75H4.98749V14.25H10.2375C10.6059 14.2461 10.9698 14.1696 11.3087 14.025C11.6475 13.8804 11.9545 13.6705 12.2122 13.4073C12.4699 13.144 12.6732 12.8326 12.8105 12.4907C12.9478 12.1489 13.0164 11.7834 13.0125 11.415V11.325C13.0127 10.8053 12.8647 10.2964 12.5857 9.85797C12.3067 9.41954 11.9084 9.06986 11.4375 8.85ZM6.48749 5.25H9.63749C9.95712 5.24011 10.2721 5.32821 10.5402 5.50249C10.8084 5.67677 11.0168 5.92888 11.1375 6.225C11.2596 6.62084 11.2201 7.04894 11.0276 7.41574C10.8351 7.78254 10.5052 8.05821 10.11 8.1825C9.95652 8.22747 9.79741 8.25021 9.63749 8.25H6.48749V5.25ZM9.93749 12.75H6.48749V9.75H9.93749C10.2571 9.74011 10.5721 9.82821 10.8402 10.0025C11.1084 10.1768 11.3168 10.4289 11.4375 10.725C11.5596 11.1208 11.5201 11.5489 11.3276 11.9157C11.1351 12.2825 10.8052 12.5582 10.41 12.6825C10.2565 12.7275 10.0974 12.7502 9.93749 12.75Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Bold;
