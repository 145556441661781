import React from 'react';

const NoArbitrationSelectedIcon = () => {
  return (
    <svg
      width="74"
      height="65"
      viewBox="0 0 74 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.2624 47.312H43.2478L51.6289 53.2838C51.8023 53.4073 52.0062 53.4806 52.2183 53.4955C52.4303 53.5103 52.6424 53.4663 52.8312 53.3681C53.02 53.27 53.1783 53.1215 53.2888 52.939C53.3993 52.7564 53.4578 52.5468 53.4577 52.3331V47.312H55.7376C56.8681 47.312 57.9522 46.8605 58.7516 46.057C59.5509 45.2534 60 44.1636 60 43.0272V26.7848C60 25.6484 59.5509 24.5585 58.7516 23.755C57.9522 22.9514 56.8681 22.5 55.7376 22.5H30.2624C29.1319 22.5 28.0478 22.9514 27.2484 23.755C26.4491 24.5585 26 25.6484 26 26.7848V43.0272C26 44.1636 26.4491 45.2534 27.2484 46.057C28.0478 46.8605 29.1319 47.312 30.2624 47.312Z"
        fill="#95989B"
      />
      <path
        d="M30.2624 47.312H43.2478L51.6289 53.2838C51.8023 53.4073 52.0062 53.4806 52.2183 53.4955C52.4303 53.5103 52.6424 53.4663 52.8312 53.3681C53.02 53.27 53.1783 53.1215 53.2888 52.939C53.3993 52.7564 53.4578 52.5468 53.4577 52.3331V47.312H55.7376C56.8681 47.312 57.9522 46.8605 58.7516 46.057C59.5509 45.2534 60 44.1636 60 43.0272V26.7848C60 25.6484 59.5509 24.5585 58.7516 23.755C57.9522 22.9514 56.8681 22.5 55.7376 22.5H30.2624C29.1319 22.5 28.0478 22.9514 27.2484 23.755C26.4491 24.5585 26 25.6484 26 26.7848V43.0272C26 44.1636 26.4491 45.2534 27.2484 46.057C28.0478 46.8605 29.1319 47.312 30.2624 47.312Z"
        fill="white"
        fillOpacity="0.5"
      />
      <mask
        id="mask0_10617_150827"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="26"
        y="22"
        width="34"
        height="32"
      >
        <path
          d="M30.2624 47.312H43.2478L51.6289 53.2838C51.8023 53.4073 52.0062 53.4806 52.2183 53.4955C52.4303 53.5103 52.6424 53.4663 52.8312 53.3681C53.02 53.27 53.1783 53.1215 53.2888 52.939C53.3993 52.7564 53.4578 52.5468 53.4577 52.3331V47.312H55.7376C56.8681 47.312 57.9522 46.8605 58.7516 46.057C59.5509 45.2534 60 44.1636 60 43.0272V26.7848C60 25.6484 59.5509 24.5585 58.7516 23.755C57.9522 22.9514 56.8681 22.5 55.7376 22.5H30.2624C29.1319 22.5 28.0478 22.9514 27.2484 23.755C26.4491 24.5585 26 25.6484 26 26.7848V43.0272C26 44.1636 26.4491 45.2534 27.2484 46.057C28.0478 46.8605 29.1319 47.312 30.2624 47.312Z"
          fill="#DEDEDE"
        />
      </mask>
      <g mask="url(#mask0_10617_150827)">
        <path
          d="M27.2624 47.312L34.5 48.5C46.6979 58.3085 55.7516 56 52.7376 47.312C53.8681 47.312 54.9522 46.8605 55.7516 46.057C56.5509 45.2534 57 44.1636 57 43.0272V26.7848C57 25.6484 56.5509 24.5585 55.7516 23.755C54.9522 22.9514 53.8681 22.5 52.7376 22.5H27.2624C26.1319 22.5 25.0478 22.9514 24.2484 23.755C23.4491 24.5585 23 25.6484 23 26.7848V43.0272C23 44.1636 23.4491 45.2534 24.2484 46.057C25.0478 46.8605 26.1319 47.312 27.2624 47.312Z"
          fill="#DEDEDE"
        />
        <line
          x1="29"
          y1="28.5"
          x2="46"
          y2="28.5"
          stroke="#95989B"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <line
          x1="29"
          y1="33.5"
          x2="51"
          y2="33.5"
          stroke="#95989B"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        d="M30.2624 47.312H43.2478L51.6289 53.2838C51.8023 53.4073 52.0062 53.4806 52.2183 53.4955C52.4303 53.5103 52.6424 53.4663 52.8312 53.3681C53.02 53.27 53.1783 53.1215 53.2888 52.939C53.3993 52.7564 53.4578 52.5468 53.4577 52.3331V47.312H55.7376C56.8681 47.312 57.9522 46.8605 58.7516 46.057C59.5509 45.2534 60 44.1636 60 43.0272V26.7848C60 25.6484 59.5509 24.5585 58.7516 23.755C57.9522 22.9514 56.8681 22.5 55.7376 22.5H30.2624C29.1319 22.5 28.0478 22.9514 27.2484 23.755C26.4491 24.5585 26 25.6484 26 26.7848V43.0272C26 44.1636 26.4491 45.2534 27.2484 46.057C28.0478 46.8605 29.1319 47.312 30.2624 47.312V47.312Z"
        stroke="#95989B"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M33.7376 37.312H20.7522L12.3711 43.2838C12.1977 43.4073 11.9938 43.4806 11.7817 43.4955C11.5697 43.5103 11.3576 43.4663 11.1688 43.3681C10.98 43.27 10.8217 43.1215 10.7112 42.939C10.6007 42.7564 10.5422 42.5468 10.5423 42.3331V37.312H8.26239C7.13193 37.312 6.04778 36.8605 5.24843 36.057C4.44907 35.2534 4 34.1636 4 33.0272V16.7848C4 15.6484 4.44907 14.5585 5.24843 13.755C6.04778 12.9514 7.13193 12.5 8.26239 12.5H33.7376C34.8681 12.5 35.9522 12.9514 36.7516 13.755C37.5509 14.5585 38 15.6484 38 16.7848V33.0272C38 34.1636 37.5509 35.2534 36.7516 36.057C35.9522 36.8605 34.8681 37.312 33.7376 37.312Z"
        fill="#DEDEDE"
      />
      <mask
        id="mask1_10617_150827"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="12"
        width="34"
        height="32"
      >
        <path
          d="M33.7376 37.312H20.7522L12.3711 43.2838C12.1977 43.4073 11.9938 43.4806 11.7817 43.4955C11.5697 43.5103 11.3576 43.4663 11.1688 43.3681C10.98 43.27 10.8217 43.1215 10.7112 42.939C10.6007 42.7564 10.5422 42.5468 10.5423 42.3331V37.312H8.26239C7.13193 37.312 6.04778 36.8605 5.24843 36.057C4.44907 35.2534 4 34.1636 4 33.0272V16.7848C4 15.6484 4.44907 14.5585 5.24843 13.755C6.04778 12.9514 7.13193 12.5 8.26239 12.5H33.7376C34.8681 12.5 35.9522 12.9514 36.7516 13.755C37.5509 14.5585 38 15.6484 38 16.7848V33.0272C38 34.1636 37.5509 35.2534 36.7516 36.057C35.9522 36.8605 34.8681 37.312 33.7376 37.312Z"
          fill="#DEDEDE"
        />
      </mask>
      <g mask="url(#mask1_10617_150827)">
        <path
          d="M30.7376 37.312L23.5 38.5C11.3021 48.3085 16.6204 54.8658 5.26239 37.312C4.13193 37.312 3.04778 36.8605 2.24843 36.057C1.44907 35.2534 1 34.1636 1 33.0272V16.7848C1 15.6484 1.44907 14.5585 2.24843 13.755C3.04778 12.9514 4.13193 12.5 5.26239 12.5H30.7376C31.8681 12.5 32.9522 12.9514 33.7516 13.755C34.5509 14.5585 35 15.6484 35 16.7848V33.0272C35 34.1636 34.5509 35.2534 33.7516 36.057C32.9522 36.8605 31.8681 37.312 30.7376 37.312Z"
          fill="white"
        />
      </g>
      <path
        d="M33.7376 37.312H20.7522L12.3711 43.2838C12.1977 43.4073 11.9938 43.4806 11.7817 43.4955C11.5697 43.5103 11.3576 43.4663 11.1688 43.3681C10.98 43.27 10.8217 43.1215 10.7112 42.939C10.6007 42.7564 10.5422 42.5468 10.5423 42.3331V37.312H8.26239C7.13193 37.312 6.04778 36.8605 5.24843 36.057C4.44907 35.2534 4 34.1636 4 33.0272V16.7848C4 15.6484 4.44907 14.5585 5.24843 13.755C6.04778 12.9514 7.13193 12.5 8.26239 12.5H33.7376C34.8681 12.5 35.9522 12.9514 36.7516 13.755C37.5509 14.5585 38 15.6484 38 16.7848V33.0272C38 34.1636 37.5509 35.2534 36.7516 36.057C35.9522 36.8605 34.8681 37.312 33.7376 37.312V37.312Z"
        stroke="#95989B"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <line
        x1="10"
        y1="18.5"
        x2="27"
        y2="18.5"
        stroke="#95989B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="10"
        y1="23.5"
        x2="31"
        y2="23.5"
        stroke="#95989B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="10"
        y1="28.5"
        x2="19"
        y2="28.5"
        stroke="#95989B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="47"
        y="9.5"
        width="26"
        height="26"
        rx="13"
        fill="url(#paint0_linear_10617_150827)"
      />
      <path
        d="M57.3334 20.7084C57.3334 19.2357 58.5273 18.0417 60 18.0417C61.4728 18.0417 62.6667 19.2357 62.6667 20.7084C62.6667 21.951 61.8168 22.995 60.6667 23.2911V24.0417C60.6667 24.4099 60.3682 24.7084 60 24.7084C59.6318 24.7084 59.3334 24.4099 59.3334 24.0417V22.7084C59.3334 22.3402 59.6318 22.0417 60 22.0417C60.7364 22.0417 61.3334 21.4448 61.3334 20.7084C61.3334 19.972 60.7364 19.3751 60 19.3751C59.2636 19.3751 58.6667 19.972 58.6667 20.7084C58.6667 21.0766 58.3682 21.3751 58 21.3751C57.6318 21.3751 57.3334 21.0766 57.3334 20.7084Z"
        fill="white"
      />
      <path
        d="M60.8334 26.2084C60.8334 26.6686 60.4603 27.0417 60 27.0417C59.5398 27.0417 59.1667 26.6686 59.1667 26.2084C59.1667 25.7482 59.5398 25.3751 60 25.3751C60.4603 25.3751 60.8334 25.7482 60.8334 26.2084Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.8146 17.3146C56.1409 15.9883 57.9752 15.1667 60 15.1667C62.0249 15.1667 63.8591 15.9883 65.1855 17.3146C66.5118 18.641 67.3334 20.4752 67.3334 22.5001C67.3334 24.5249 66.5118 26.3592 65.1855 27.6855C63.8591 29.0119 62.0249 29.8334 60 29.8334C57.9752 29.8334 56.1409 29.0119 54.8146 27.6855C53.4882 26.3592 52.6667 24.5249 52.6667 22.5001C52.6667 20.4752 53.4882 18.641 54.8146 17.3146ZM60 16.5001C58.343 16.5001 56.8439 17.1709 55.7574 18.2574C54.6709 19.344 54 20.8431 54 22.5001C54 24.1571 54.6709 25.6562 55.7574 26.7427C56.8439 27.8292 58.343 28.5001 60 28.5001C61.657 28.5001 63.1561 27.8292 64.2426 26.7427C65.3292 25.6562 66 24.1571 66 22.5001C66 20.8431 65.3292 19.344 64.2427 18.2574C63.1561 17.1709 61.657 16.5001 60 16.5001Z"
        fill="white"
      />
      <rect
        x="47"
        y="9.5"
        width="26"
        height="26"
        rx="13"
        stroke="white"
        strokeWidth="2"
      />
      <defs>
        <linearGradient
          id="paint0_linear_10617_150827"
          x1="48"
          y1="34.5"
          x2="72.4185"
          y2="34.0663"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A761F5" />
          <stop offset="1" stopColor="#50A8FA" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default NoArbitrationSelectedIcon;
