import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button } from 'ncoded-component-library';
import { week } from 'constants/week';
import Calendar from 'components/Calendar';
import dayjs from 'dayjs';
import dates from 'utils/dates';
import isBetween from 'dayjs/plugin/isBetween';
import {
  formatHours24To12,
  TimePeriod,
} from 'components/TimeInput/TimeInput.component';
import Notice from 'components/Notice';
import { UserAvailability } from 'models/Availability';

import './Availability.styles.scss';
import './Availability.styles.responsive.scss';

dayjs.extend(isBetween);

export const convertMilitaryTimeToStandard = (militaryTime: string) => {
  const [hours, minutes] = militaryTime.split(':');

  let period: TimePeriod = 'am';

  if (+hours >= 12) {
    period = 'pm';
  }
  return `${formatHours24To12(hours, true)}:${minutes} ${period}`;
};

type AvailabilityProps = {
  className?: string;
  renderInModal?: boolean;
  availability: UserAvailability;
  isMine: boolean;
  handleSelectedDateChange?: (selectedDate: dayjs.Dayjs) => void;
  setShowAvailabilityModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const Availability: React.FC<AvailabilityProps> = (props) => {
  const {
    className,
    renderInModal = false,
    availability,
    isMine,
    handleSelectedDateChange,
    setShowAvailabilityModal,
  } = props;

  const classes = classNames(
    'anys-availability',
    { 'anys-availability--render-in-modal': renderInModal },
    className,
  );

  const { t } = useTranslation();

  const [selectedDate, setSelectedDate] = useState(dayjs());

  const renderAvailabilityStatus = useCallback(() => {
    let isAvailable: { startTime: string; endTime: string }[] = [];

    availability?.specificEntries.forEach((entrie) => {
      const tmp = dayjs(selectedDate).isBetween(
        dayjs(entrie.fromDate),
        dayjs(entrie.toDate),
        'day',
        '[]',
      );
      if (tmp) {
        isAvailable = [
          ...isAvailable,
          {
            startTime: convertMilitaryTimeToStandard(entrie.startTime),
            endTime: convertMilitaryTimeToStandard(entrie.endTime),
          },
        ];
      }
    });

    return (
      <section className="anys-availability__status">
        <span
          className={classNames('anys-availability__status__chip', {
            'anys-availability__status__chip--unavailable':
              isAvailable.length === 0,
          })}
        >
          {isAvailable.length !== 0
            ? t('General.available')
            : t('General.unavailable')}
        </span>
        <span
          className={classNames('anys-availability__status__date', {
            'anys-availability__status__date--unavailable':
              isAvailable.length === 0,
          })}
        >
          {t(week[new Date(selectedDate?.toDate()).getDay()]?.label)}
          {dayjs(selectedDate).format(', DD MMMM YYYY')}
        </span>

        {isAvailable.length !== 0 &&
          isAvailable.map(({ startTime, endTime }, index) => (
            <span key={index}>
              {startTime} - {endTime}
            </span>
          ))}
      </section>
    );
  }, [availability?.specificEntries, selectedDate, t]);

  useEffect(() => {
    handleSelectedDateChange?.(selectedDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  return (
    <div className={classes}>
      {!renderInModal && (
        <h2 className="anys-availability__title">
          {t('General.availability')}
        </h2>
      )}

      {availability?.recurringEntries?.length &&
      availability?.recurringEntries?.length !== 0 ? (
        <section className="anys-availability__recurring">
          <h3 className="anys-availability__recurring__title">
            {t('General.week')}
          </h3>
          {week.map(({ day, abbreviation, label }, index) => {
            return (
              <div
                key={index}
                className={classNames('anys-availability__recurring__entrie', {
                  'anys-availability__recurring__entrie--unavailable':
                    availability?.recurringEntries.filter(
                      (entrie) => entrie.dayOfWeek === abbreviation,
                    ).length === 0,
                })}
              >
                <span className="anys-availability__recurring__entrie__day">
                  {t(label)}
                </span>
                <span className="anys-availability__recurring__entrie__time">
                  {availability?.recurringEntries.filter(
                    (entrie) => entrie.dayOfWeek === abbreviation,
                  ).length === 0 && <span>{t('General.unavailable')}</span>}

                  {availability?.recurringEntries
                    .filter((entrie) => entrie.dayOfWeek === abbreviation)
                    .map((entrie, index) => {
                      return (
                        <div key={index}>
                          <span>
                            {`${convertMilitaryTimeToStandard(
                              entrie.startTime,
                            ).toUpperCase()} - `}
                          </span>

                          <span>
                            {convertMilitaryTimeToStandard(
                              entrie.endTime,
                            ).toUpperCase()}
                          </span>
                        </div>
                      );
                    })}
                </span>
              </div>
            );
          })}
          <div>
            {availability.timeoffs.length !== 0 &&
              availability.timeoffs.some(
                (time) => dayjs(time.toDate) > dayjs(),
              ) && (
                <div>
                  <h3 className="anys-availability__recurring__title">
                    {t('Availability.exceptions')}
                  </h3>
                  <span
                    className={classNames(
                      'anys-availability__status__chip',
                      'anys-availability__status__chip--unavailable',
                    )}
                  >
                    {t('General.unavailable')}
                  </span>
                  {availability.timeoffs.map(
                    (
                      { fromDate, toDate, startTime, endTime, isFullDay },
                      index,
                    ) => {
                      return (
                        dayjs(toDate) > dayjs() && (
                          <div
                            className="anys-availability__status anys-availability__status--exceptions"
                            key={index}
                          >
                            <span
                              className={classNames(
                                'anys-availability__status__date',
                                'anys-availability__status__date--unavailable',
                              )}
                            >
                              {`${dayjs(fromDate).format('DD.MM.YYYY')} -
                      ${dayjs(toDate).format('DD.MM.YYYY')}`}
                            </span>

                            {isFullDay ? (
                              <span>full day unavailable</span>
                            ) : (
                              <span key={index}>
                                {`${convertMilitaryTimeToStandard(startTime)} - 
                      ${convertMilitaryTimeToStandard(endTime)}`}
                              </span>
                            )}
                          </div>
                        )
                      );
                    },
                  )}
                </div>
              )}
          </div>
        </section>
      ) : availability?.specificEntries?.length &&
        availability?.specificEntries?.length !== 0 ? (
        <div className="anys-availability__calendar">
          <Calendar
            leftLimitDate={dayjs()}
            rightLimitDate={dates.MAX_DATEPICKER_LIMIT}
            hints={availability?.timeslots?.map((timeslot) =>
              dayjs(timeslot.from),
            )}
            onChange={(date: any) => {
              setSelectedDate(date);
            }}
            hasSubmitButton={false}
          />

          {renderAvailabilityStatus()}
        </div>
      ) : (
        <div>
          <Notice type="warn">{t('Availability.notSetPlaceholder')}</Notice>
        </div>
      )}

      {isMine && (
        <Button
          className="anys-availability__action"
          onClick={() => setShowAvailabilityModal(true)}
          variant="outline"
        >
          {availability?.recurringEntries?.length ||
          availability?.specificEntries?.length
            ? t('Availability.modalTitle')
            : t('Availability.set')}
        </Button>
      )}
    </div>
  );
};

export default Availability;
