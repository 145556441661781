import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import classNames from 'classnames';
import InboxItemList from '../InboxItemList';
import InboxItemsFilters from '../InboxItemsFilters';
import useInboxItemSearch from '../../hooks/useInboxItemSearch';
import useInboxItemDelete from '../../hooks/useInboxItemDelete';
import StickyBottomContent from 'components/StickyBottomContent';
import { Button } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import eventSocketService from 'services/socket/eventSocketService';
import { InboxItem, InboxUrlParams } from 'models/Inbox';
import { useNavigate, useParams } from 'react-router-dom';
import useInboxLink from '../../hooks/useInboxLink';
import {
  INBOX_ITEMS_GENERAL_FILTER,
  INBOX_ITEM_IN_FILTER_CHECKER,
} from '../../utils/filters';
import NotificationsContext from 'providers/Notifications/Notifications.context';
import { JobState } from 'models/Job';

import './InboxItems.styles.scss';
import './InboxItems.styles.responsive.scss';

type InboxItemsProps = {
  className?: string;
};

const InboxItems: React.FC<InboxItemsProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();

  const [selectedInboxItems, setSelectedInboxItems] = useState<number[]>([]);
  const [isManagingItems, setIsManagingItems] = useState(false);

  const { inboxItemId } = useParams<InboxUrlParams>();
  const navigate = useNavigate();
  const { readManyNotifications } = useContext(NotificationsContext);

  const { createEntityLink, linkWihoutInboxItem } = useInboxLink();

  const classes = classNames('anys-inbox-items', className);

  const messages = useMemo(
    () => ({
      remove: t('General.remove'),
      cancel: t('General.cancel'),
      selectAll: t('General.selectAll'),
      deselect: t('General.deselect'),
    }),
    [t],
  );

  const {
    setItems,
    onContainerScrolled,
    loading,
    items,
    filters: { inboxItemType },
  } = useInboxItemSearch();

  const { isDeleting, handleDelete } = useInboxItemDelete(setItems);

  const hasItems = items?.length > 0;

  // If the filter is applied and our item isn't part of filter results
  const shouldForbidItemAdd = useCallback(
    (newItem: InboxItem) =>
      inboxItemType?.value &&
      !INBOX_ITEM_IN_FILTER_CHECKER[
        inboxItemType.value as keyof typeof INBOX_ITEMS_GENERAL_FILTER
      ](newItem),

    [inboxItemType?.value],
  );

  const onManageItems = useCallback(() => {
    setSelectedInboxItems([]);
    setIsManagingItems(true);
  }, []);

  const handleDeleteMultiItems = useCallback(async () => {
    if (!selectedInboxItems?.length) return;

    let entityState: JobState | 'items' = 'items';

    switch (inboxItemType?.value as keyof typeof INBOX_ITEMS_GENERAL_FILTER) {
      case 'drafts':
        entityState = 'Draft';
        break;
      case 'preOffers':
        entityState = 'Ad';
        break;

      case 'offers':
        entityState = 'Offer';
        break;

      default:
        break;
    }

    const hasDeleted = await handleDelete(selectedInboxItems, entityState);

    // If we have a deleted item open, close it
    if (hasDeleted && selectedInboxItems.includes(+inboxItemId)) {
      navigate(linkWihoutInboxItem, { replace: true });
    }

    setSelectedInboxItems([]);
  }, [
    handleDelete,
    inboxItemId,
    inboxItemType,
    linkWihoutInboxItem,
    navigate,
    selectedInboxItems,
  ]);

  useEffect(() => {
    if (!+inboxItemId || !items?.length) return;

    const inboxItem = items.find(({ id }) => id === +inboxItemId);

    const { notifications } = inboxItem || {};

    const readNotifs = async () => {
      const readSuccess = await readManyNotifications(
        'inbox',
        +inboxItemId,
        notifications?.map(({ id }) => id),
      );

      if (!readSuccess) return;

      setItems((old) =>
        old.map((item) =>
          item.id === +inboxItemId ? { ...item, notifications: [] } : item,
        ),
      );
    };

    readNotifs();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inboxItemId, hasItems, setItems]);

  useEffect(() => {
    const handleItemCreated = (createdItem: InboxItem) => {
      if (shouldForbidItemAdd(createdItem)) return;

      setItems((oldItems) => {
        if (oldItems.some(({ id }) => id === createdItem.id)) return oldItems;

        return [createdItem, ...oldItems];
      });
    };

    eventSocketService.addListener('inbox-item-created', handleItemCreated);

    return () => {
      eventSocketService.removeListener(
        'inbox-item-created',
        handleItemCreated,
      );
    };
  }, [setItems, shouldForbidItemAdd]);

  useEffect(() => {
    const handleItemSwapped = ({
      target,
      updated,
    }: {
      target: InboxItem;
      updated: InboxItem;
    }) => {
      // If updated item isn't part of our filter,
      // we should remove it from visible items
      if (shouldForbidItemAdd(updated)) {
        setItems((oldItems) => oldItems.filter(({ id }) => id !== updated?.id));
      } else {
        setItems((oldItems) =>
          oldItems.map((oldItem) =>
            oldItem.id === target.id ? { ...oldItem, ...updated } : oldItem,
          ),
        );
      }

      if (+inboxItemId === updated.commonId) {
        navigate(
          createEntityLink('view', updated.type, updated.commonId, updated.id),
          { replace: true },
        );
      }
    };

    eventSocketService.addListener('inbox-item-swapped', handleItemSwapped);

    return () => {
      eventSocketService.removeListener(
        'inbox-item-swapped',
        handleItemSwapped,
      );
    };
  }, [createEntityLink, inboxItemId, navigate, setItems, shouldForbidItemAdd]);

  return (
    <div className={classes}>
      <InboxItemsFilters
        onManageItems={onManageItems}
        isManagingItems={isManagingItems}
        loading={loading}
      />
      <InboxItemList
        items={items}
        loading={loading}
        isDeleting={isDeleting}
        handleDelete={handleDelete}
        onContainerScrolled={onContainerScrolled}
        isManagingItems={isManagingItems}
        selectedInboxItems={selectedInboxItems}
        setSelectedInboxItems={setSelectedInboxItems}
      />

      {isManagingItems && (
        <StickyBottomContent className="anys-inbox-items__manage-items">
          <div className="anys-inbox-items__manage-items__top">
            <span>
              {t('General.nSelected', { n: selectedInboxItems.length })}
            </span>
            <Button
              type="button"
              variant="link"
              onClick={() => {
                setSelectedInboxItems((oldItems) => {
                  if (oldItems.length) return [];

                  return items.map(({ id }) => id);
                });
              }}
            >
              <span>
                {selectedInboxItems.length
                  ? messages.deselect
                  : messages.selectAll}
              </span>
            </Button>
          </div>
          <div className="anys-inbox-items__manage-items__bottom">
            <Button
              type="button"
              disabled={isDeleting}
              onClick={handleDeleteMultiItems}
            >
              {messages.remove}
            </Button>

            <Button
              type="button"
              variant="link"
              onClick={() => {
                setIsManagingItems(false);
                setSelectedInboxItems([]);
              }}
              disabled={isDeleting}
            >
              <span>{messages.cancel}</span>
            </Button>
          </div>
        </StickyBottomContent>
      )}
    </div>
  );
};

export default InboxItems;
