import React, { ReactNode, useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import StickyBottomContent from 'components/StickyBottomContent';
import { Button, Loader } from 'ncoded-component-library';

import './FormButtons.styles.scss';
import './FormButtons.styles.responsive.scss';

type ButtonProps = React.ComponentProps<typeof Button>;
type FormButtonsProps = {
  className?: string;
  loading?: boolean;
  disabled?: boolean;
  submitTxt?: ReactNode;
  cancelTxt?: ReactNode;
  saveText?: React.ReactNode;
  submitBtnProps?: ButtonProps;
  cancelBtnProps?: ButtonProps;
  saveBtnProps?: ButtonProps;
  onSave?: () => void;
  onClear?: () => void;
};

const FormButtons: React.FC<FormButtonsProps> = (props) => {
  const { t } = useTranslation();

  const messages = useMemo(
    () => ({
      apply: t('General.apply'),
      clearFilters: t('Filters.clear'),
      save: t('General.save'),
    }),
    [t],
  );

  const {
    loading,
    disabled = loading,
    submitBtnProps,
    cancelBtnProps,
    saveBtnProps,
    submitTxt = loading ? <Loader inline size="small" /> : messages.apply,
    cancelTxt = messages.clearFilters,
    saveText = messages.save,
    className,
    onClear,
    onSave,
  } = props;

  const classes = classNames('anys-form-buttons', className);

  return (
    <StickyBottomContent className={classes}>
      <Button {...submitBtnProps} disabled={disabled} type="submit">
        {submitTxt}
      </Button>
      {!!onSave && (
        <Button
          disabled={disabled}
          variant="outline"
          {...saveBtnProps}
          onClick={onSave}
        >
          {saveText}
        </Button>
      )}
      <Button variant="link" {...cancelBtnProps} onClick={onClear}>
        <span>{cancelTxt}</span>
      </Button>
    </StickyBottomContent>
  );
};

export default FormButtons;
