import React, { useCallback, useState } from 'react';
import Modal, { ModalRef } from 'components/Modal';
import classNames from 'classnames';
import { Button } from 'ncoded-component-library';
import Rating from 'components/Rating';
import api from 'api';
import showToast from 'modules/showToast';
import { useTranslation } from 'react-i18next';
import TextArea from 'components/TextArea';

import './ChangeReviewModal.styles.scss';

type ChangeReviewModalProps = {
  className?: string;
  changeReviewModalRef: (element: ModalRef) => void;
  onClose: () => void;
  updateContent: (newContent: any) => void;
  review: any;
};

// TODO: ALLOW CHANGING REVIEW ONLY ONCE

const ChangeReviewModal: React.FC<ChangeReviewModalProps> = (props) => {
  const { className, changeReviewModalRef, onClose, updateContent, review } =
    props;

  const { rating, content, id } = review || {};

  const classes = classNames('anys-change-review-modal', className);

  const [updatedContent, setUpdatedContent] = useState(content);

  const { t } = useTranslation();

  const handleSubmit = useCallback(async () => {
    try {
      const { data: newReview } = await api.contract.updateReview({
        commonId: id,
        rating,
        content: updatedContent,
      });
      updateContent(newReview.content);
      onClose();
    } catch (error) {
      showToast('error', t('General.error'));
    }
  }, [id, onClose, rating, t, updateContent, updatedContent]);

  const handleContentChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setUpdatedContent(e.target.value);
    },
    [],
  );

  return (
    <Modal
      className={classes}
      type="no-action"
      modalName={`change-review-modal-${id}`}
      ref={changeReviewModalRef}
      footer={
        <div className="anys-change-review-modal__footer">
          <Button type="button" onClick={handleSubmit}>
            Confirm
          </Button>
          <Button type="button" onClick={onClose} variant="link">
            <span>Close</span>
          </Button>
        </div>
      }
    >
      <div className="change-review-modal">
        <Rating
          withValue
          changable
          fullStar
          value={rating}
          reviewFormat={() => ''}
          count={10}
          maxReviewScore={10}
        />
        <TextArea
          name="content"
          defaultValue={content}
          resize={false}
          onChange={handleContentChange}
        />
      </div>
    </Modal>
  );
};

export default ChangeReviewModal;
