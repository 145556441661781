import { PriceRangeType } from 'api/favouriteFilters';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  convertMoneyToNumber,
  convertToMoney,
  formatMoney,
} from 'utils/currency';
import formValidators from 'utils/formValidators';

export default function useValidations(priceRange: PriceRangeType) {
  const { t } = useTranslation();

  const minTotalPrice = useMemo(
    () => convertToMoney(priceRange.minTotalPrice || 0),
    [priceRange.minTotalPrice],
  );
  const maxTotalPrice = useMemo(
    () => convertToMoney(priceRange.maxTotalPrice || 1000),
    [priceRange.maxTotalPrice],
  );

  return useMemo(
    () => ({
      minPrice:
        minTotalPrice.amount >= 0
          ? formValidators.minValue<string>(
              t('General.atLeastValidation', {
                min: formatMoney(t, minTotalPrice),
              }),
              convertMoneyToNumber(minTotalPrice),
              true,
            )
          : undefined,
      maxPrice:
        maxTotalPrice?.amount >= 0
          ? formValidators.maxValue<string>(
              t('General.atMostValidation', {
                max: formatMoney(t, maxTotalPrice),
              }),
              convertMoneyToNumber(maxTotalPrice),
              true,
            )
          : undefined,
    }),
    [maxTotalPrice, minTotalPrice, t],
  );
}
