import React, { useId } from 'react';

type FilterIconProps = React.SVGProps<SVGSVGElement> & {
  showGradient?: boolean;
  showCircle?: boolean;
};

const FilterIcon: React.FC<FilterIconProps> = ({
  showGradient,
  showCircle,
  ...props
}) => {
  const lgId = useId();

  const fill = showGradient ? `url(#${lgId})` : 'currentColor';

  return (
    <svg {...props} width="25" height="25" viewBox="0 0 25 25" fill="none">
      <path
        transform="translate(2.5 -2)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4583 6.66667C11.9186 6.66667 12.2917 7.03976 12.2917 7.5V10.8333C12.2917 11.2936 11.9186 11.6667 11.4583 11.6667C10.9981 11.6667 10.625 11.2936 10.625 10.8333V10H2.29167C1.83143 10 1.45833 9.6269 1.45833 9.16667C1.45833 8.70643 1.83143 8.33333 2.29167 8.33333H10.625V7.5C10.625 7.03976 10.9981 6.66667 11.4583 6.66667ZM13.9583 9.16667C13.9583 8.70643 14.3314 8.33333 14.7917 8.33333H17.2917C17.7519 8.33333 18.125 8.70643 18.125 9.16667C18.125 9.6269 17.7519 10 17.2917 10H14.7917C14.3314 10 13.9583 9.6269 13.9583 9.16667ZM8.95833 12.5C9.41857 12.5 9.79167 12.8731 9.79167 13.3333V14.1667H18.125C18.5852 14.1667 18.9583 14.5398 18.9583 15C18.9583 15.4602 18.5852 15.8333 18.125 15.8333H9.79167V16.6667C9.79167 17.1269 9.41857 17.5 8.95833 17.5C8.4981 17.5 8.125 17.1269 8.125 16.6667V13.3333C8.125 12.8731 8.4981 12.5 8.95833 12.5ZM1.45833 15C1.45833 14.5398 1.83143 14.1667 2.29167 14.1667H5.625C6.08524 14.1667 6.45833 14.5398 6.45833 15C6.45833 15.4602 6.08524 15.8333 5.625 15.8333H2.29167C1.83143 15.8333 1.45833 15.4602 1.45833 15ZM11.4583 18.3333C11.9186 18.3333 12.2917 18.7064 12.2917 19.1667V22.5C12.2917 22.9602 11.9186 23.3333 11.4583 23.3333C10.9981 23.3333 10.625 22.9602 10.625 22.5V21.6667H2.29167C1.83143 21.6667 1.45833 21.2936 1.45833 20.8333C1.45833 20.3731 1.83143 20 2.29167 20H10.625V19.1667C10.625 18.7064 10.9981 18.3333 11.4583 18.3333ZM13.9583 20.8333C13.9583 20.3731 14.3314 20 14.7917 20H17.2917C17.7519 20 18.125 20.3731 18.125 20.8333C18.125 21.2936 17.7519 21.6667 17.2917 21.6667H14.7917C14.3314 21.6667 13.9583 21.2936 13.9583 20.8333Z"
        fill={fill}
      />
      {showCircle && (
        <>
          <rect x="15.5" y="0.5" width="9" height="9" rx="4.5" fill={fill} />
          <rect x="15.5" y="0.5" width="9" height="9" rx="4.5" stroke="white" />
        </>
      )}
      <defs>
        <linearGradient
          id={lgId}
          x1="1.45833"
          y1="15"
          x2="18.9583"
          y2="15"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF7BAC" />
          <stop offset="1" stopColor="#D142F4" />
        </linearGradient>
        <linearGradient
          id={lgId}
          x1="16"
          y1="5"
          x2="24"
          y2="5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF7BAC" />
          <stop offset="1" stopColor="#D142F4" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default FilterIcon;
