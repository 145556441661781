import React, { useContext } from 'react';
import ThemeContext from 'providers/Theme/Theme.context';
import ContentLoader from 'react-content-loader';

type ChatMainLoadingProps = {
  length?: number;
  verticalSpacing?: number;
  itemHeight?: number;
  viewBoxWidth: number;
};

const ChatMainLoading: React.FC<ChatMainLoadingProps> = ({
  length = 15,
  verticalSpacing = 24,
  itemHeight = 74,
  viewBoxWidth,
}) => {
  const { theme } = useContext(ThemeContext);

  const backgroundColor = theme === 'dark' ? '#909194' : '#e7f1fc';
  const foregroundColor = theme === 'dark' ? '#333' : '#ffffff';

  const loaderHeight = (itemHeight + verticalSpacing) * length;

  return viewBoxWidth ? (
    <div>
      <ContentLoader
        viewBox={`0 0 ${viewBoxWidth} ${loaderHeight}`}
        backgroundColor={backgroundColor}
        foregroundColor={foregroundColor}
      >
        {Array.from({ length }).map((_, ind) => {
          const isOdd = ind % 2 === 1;

          const transformMaybe = isOdd
            ? { style: { transform: 'translateX(100%) translateX(-125px)' } }
            : {};

          // ind * itemHeight moves rects by the height of the loader,
          // ind * verticalSpacing moves rects by the spacing between odd/even rects
          const verticalMoveBy = ind * itemHeight + ind * verticalSpacing;

          return (
            <React.Fragment key={ind}>
              <rect
                x="0"
                y={verticalMoveBy}
                rx="16"
                ry="16"
                width="125"
                height="50"
                {...transformMaybe}
              />
              <rect
                x="0"
                y={verticalMoveBy + 58}
                rx="4"
                ry="4"
                width="60"
                height="16"
                {...transformMaybe}
              />
              <rect
                x="65px"
                y={verticalMoveBy + 58}
                rx="4"
                ry="4"
                width="60"
                height="16"
                {...transformMaybe}
              />
            </React.Fragment>
          );
        })}
      </ContentLoader>
    </div>
  ) : null;
};

export default ChatMainLoading;
