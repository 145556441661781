import React, { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { InboxUrlParams } from 'models/Inbox';
import classNames from 'classnames';
import { ReactComponent as InboxChatBoxes } from 'icons/inbox-chat-boxes.svg';
import Chat from 'components/Chat';

import './ChatView.styles.scss';

const ChatView = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { chatUserId } = useParams<Pick<InboxUrlParams, 'chatUserId'>>();

  const classes = classNames('anys-chat-view');

  const handleCloseChat = useCallback(() => {
    navigate('/chat', { replace: true });
  }, [navigate]);

  return (
    <div className={classes}>
      {+chatUserId ? (
        <Chat
          key={chatUserId}
          chatUserId={+chatUserId}
          handleCloseChat={handleCloseChat}
        />
      ) : (
        <div className="anys-chat-view__empty">
          <InboxChatBoxes />

          <span className="anys-chat-view__empty__title">
            {t('General.noItemsSelected')}
          </span>
          <span className="anys-chat-view__empty__desc">
            {t('General.noItemsSelectedDesc')}
          </span>
        </div>
      )}
    </div>
  );
};

export default ChatView;
