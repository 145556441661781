import React from 'react';
import classNames from 'classnames';
import { ReactComponent as BlankFileIcon } from 'icons/blank-file.svg';
import TimesCircleIcon from 'icons/TimesCircle.icon';

import './ChatFilePreview.styles.scss';

type ChatFilePreviewProps = {
  className?: string;
  files: File[];
  onRemoveFile: (file: File) => void;
};

const ChatFilePreview: React.FC<ChatFilePreviewProps> = (props) => {
  const { className, files, onRemoveFile } = props;

  const classes = classNames('anys-chat-file-preview', className);

  return (
    files?.length > 0 && (
      <ul className={classes}>
        {files.map((file, i) => (
          <li key={i} className="anys-chat-file-preview__file">
            <div className="anys-chat-file-preview__file__link">
              <BlankFileIcon />
              <span>{file.name}</span>
            </div>

            <button
              className="anys-chat-file-preview__file__remove"
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                onRemoveFile(file);
              }}
            >
              <TimesCircleIcon gradient={false} />
            </button>
          </li>
        ))}
      </ul>
    )
  );
};

export default ChatFilePreview;
