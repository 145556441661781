import React from 'react';
import { Field, FieldRenderProps, UseFieldConfig } from 'react-final-form';
import CalendarDropdown, {
  CalendarDropdownProps,
} from 'components/CalendarDropdown';
import classNames from 'classnames';
import ValidationError from 'components/ValidationError';
import formValidators from 'utils/formValidators';
import { Dayjs } from 'dayjs';

import './CalendarDropdownField.styles.scss';

type CommonProps = Omit<CalendarDropdownProps, 'value' | 'onChange'> & {
  name: string;
  onChange?: any;
};

type CalendarDropdownFieldProps = CommonProps &
  UseFieldConfig<Dayjs | Dayjs[] | string | Date, Dayjs>;

type CalendarDropdownFieldComponentProps = Partial<CommonProps> &
  FieldRenderProps<Dayjs | Dayjs[] | string | Date, HTMLElement>;

const CalendarDropdownFieldComponent = (
  props: CalendarDropdownFieldComponentProps,
) => {
  const { className, input, meta, onChange, ...restOfProps } = props;

  const classes = classNames('anys-calendar-dropdown-field', className);

  const { error, hasError } = formValidators.getErrorFromMeta(meta);

  return (
    <div className={classes}>
      <CalendarDropdown
        {...restOfProps}
        {...input}
        hasError={hasError}
        onChange={(value: any) => {
          onChange?.(value);
          input.onChange(value);
        }}
      />
      <ValidationError error={error} showError={hasError} />
    </div>
  );
};

const CalendarDropdownField: React.FC<CalendarDropdownFieldProps> = (props) => (
  <Field component={CalendarDropdownFieldComponent} {...props} />
);

export default CalendarDropdownField;
