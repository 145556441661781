import httpClient from '../../httpClient';

const notificationSubscriptionPath = (routePath: TemplateStringsArray) =>
  `notification-subscriptions/${routePath}`;

function subscribe(token: string) {
  return httpClient.post<string>(notificationSubscriptionPath`subscribe`, {
    token,
  });
}

function unsubscribe(token: string) {
  return httpClient.post<string>(notificationSubscriptionPath`unsubscribe`, {
    token,
  });
}

export default {
  subscribe,
  unsubscribe,
};
