import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

export default (props: IconProps) => {
  const { color = '#E73631', ...restOfProps } = props;

  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      {...restOfProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0C8.23789 0 8.45776 0.126765 8.57695 0.332643L15.9103 12.9993C16.0297 13.2056 16.0299 13.4599 15.9109 13.6663C15.7918 13.8728 15.5717 14 15.3333 14H0.666667C0.428347 14 0.208153 13.8728 0.0891171 13.6663C-0.0299192 13.4599 -0.029691 13.2056 0.0897157 12.9993L7.42305 0.332643C7.54224 0.126765 7.76211 0 8 0ZM7.99945 4.66667C8.36764 4.66636 8.66636 4.96459 8.66667 5.33278L8.66943 8.66595C8.66974 9.03414 8.37151 9.33286 8.00332 9.33317C7.63513 9.33347 7.33641 9.03524 7.3361 8.66705L7.33333 5.33389C7.33303 4.9657 7.63126 4.66697 7.99945 4.66667ZM8 10C8.36819 10 8.66667 10.2985 8.66667 10.6667V11C8.66667 11.3682 8.36819 11.6667 8 11.6667C7.63181 11.6667 7.33333 11.3682 7.33333 11V10.6667C7.33333 10.2985 7.63181 10 8 10Z"
        fill={color}
      />
    </svg>
  );
};
