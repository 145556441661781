import httpClient from '../../httpClient';

const participantsPath = (routePath: TemplateStringsArray) =>
  `chat/participants/${routePath}`;

function getUnreadMsgCount() {
  return httpClient.get<{ count: number }>(participantsPath`unread-count`);
}

export default {
  getUnreadMsgCount,
};
