import React from 'react';
import { Job, JobPost } from 'models/Job';
import DataPiecePreview from '../DataPiecePreview';
import { Trans, useTranslation } from 'react-i18next';
import ToolTip from 'components/ToolTip';
import WarningIcon from 'icons/Warning.icon';
import JobChanges from 'components/JobChanges';
import { isPendingChanges } from 'utils/job-changes';

import './TotalNegotiabilityPreview.scss';
import ReactPlayer from 'react-player';

type TotalNegotiabilityPreviewProps = {
  className?: string;
} & Pick<JobPost, 'isNegotiable'> &
  Pick<Job, 'prevVersion'> &
  Required<Pick<Job, 'versionState'>>;

const TotalNegotiabilityPreview: React.FC<TotalNegotiabilityPreviewProps> = (
  props,
) => {
  const { className, isNegotiable, prevVersion, versionState } = props;

  const { t } = useTranslation();

  const totalOfferNegotiabilityMsg = t('JobForm.totalOfferNegotiability');

  const yesMsg = t('General.yes');
  const noMsg = t('General.no');

  const isNegotiabilityChanged =
    prevVersion &&
    prevVersion.isNegotiable !== isNegotiable &&
    isPendingChanges(versionState);

  const isNegotiableMsg = isNegotiable ? yesMsg : noMsg;

  return (
    <section className={className}>
      <div className="anys-total-negotiability-preview__title">
        <h2 className="flex ac">
          {totalOfferNegotiabilityMsg}
          {isNegotiabilityChanged ? (
            <ToolTip
              t={t}
              tooltipName="total-negotiability-changes"
              icon={WarningIcon}
            >
              <JobChanges
                changedFrom={[
                  `${totalOfferNegotiabilityMsg}: ${
                    prevVersion.isNegotiable ? yesMsg : noMsg
                  }`,
                ]}
                changedTo={[
                  `${totalOfferNegotiabilityMsg}: ${
                    isNegotiable ? yesMsg : noMsg
                  }`,
                ]}
              />
            </ToolTip>
          ) : null}
        </h2>
        <ToolTip t={t} tooltipName="total-negotiability-tooltip">
          <ReactPlayer width="100%" url="https://youtu.be/eB1JJ4nR0uM" />
          <p style={{ whiteSpace: 'pre-line' }}>
            <Trans
              i18nKey="Preview.totalNegotiability"
              components={{ b: <b /> }}
            />
          </p>
        </ToolTip>
      </div>
      <div className="anys-total-negotiability-preview__values">
        <DataPiecePreview
          label={totalOfferNegotiabilityMsg}
          value={isNegotiableMsg}
        />
      </div>
    </section>
  );
};

export default TotalNegotiabilityPreview;
