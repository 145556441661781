import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { Editable, Slate, withReact } from 'slate-react';
import { createEditor, Descendant, Range } from 'slate';
import {
  EditableProps,
  RenderElementProps,
  RenderLeafProps,
} from 'slate-react/dist/components/editable';
import { CustomSlateEditor } from './models/RichTextEditor';
import { CustomElement } from './models/EditorElements';
import { CustomText } from './models/EditorText';
import RichTextToolbar from './components/RichTextToolbar';
import EditorElement from './components/EditorElement';
import EditorLeaf from './components/EditorLeaf';
import { Field, FieldRenderProps, UseFieldConfig } from 'react-final-form';
import ValidationError from 'components/ValidationError';
import formValidators from 'utils/formValidators';
import utils from 'utils';
import EditorActions from './utils/EditorActions';

import './RichTextEditorField.styles.scss';

// Slate breaks if we don't have an initial value
const initialValue: Descendant[] = [{ type: 'div', children: [{ text: '' }] }];

declare module 'slate' {
  interface CustomTypes {
    Editor: CustomSlateEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

type RichTextEditorDefaultProps = {
  className?: string;
  name: string;
  label?: string;
  asideLabelContent?: React.ReactNode;
} & EditableProps;

type RichTextEditorFieldProps = RichTextEditorDefaultProps &
  UseFieldConfig<string, string>;

type RichTextEditorProps = RichTextEditorDefaultProps &
  FieldRenderProps<string, HTMLElement>;

export const RichTextEditor: React.FC<RichTextEditorProps> = (props) => {
  const {
    className,
    input,
    meta,
    label,
    name,
    readOnly,
    asideLabelContent,
    ...restOfProps
  } = props;

  const classes = classNames('anys-rich-text-editor', className);

  const hasSetInitialValue = useRef(false);

  const { value: inputValue, onChange, ...restOfInput } = input;

  const { hasError, error } = useMemo(
    () => formValidators.getErrorFromMeta(meta),
    [meta],
  );

  // Create a Slate editor object that won't change across renders.
  const editor = useMemo(() => withReact(createEditor()), []);

  const { isInline } = editor;

  editor.isInline = useCallback(
    (element: CustomElement) => {
      if (
        element.type === 'link' ||
        element.type === 'indent' ||
        element.type === 'outdent'
      )
        return true;

      return isInline(element);
    },
    [isInline],
  );

  // Define a rendering function based on the element passed to `props`.
  const renderElement = useCallback(
    (props: RenderElementProps) => <EditorElement {...props} />,
    [],
  );

  const renderLeaf = useCallback(
    (props: RenderLeafProps) => <EditorLeaf {...props} />,
    [],
  );

  const handleOnChange = useCallback(
    (value: Descendant[]) => {
      const valueAsString = JSON.stringify(value);

      onChange?.(valueAsString);
    },
    [onChange],
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      const { selection } = editor;

      const isEmptySelection =
        selection &&
        selection.focus.offset === 0 &&
        selection.anchor.offset === 0 &&
        Range.isCollapsed(selection);

      if (
        EditorActions.isBlockActive(editor, 'list-item') &&
        isEmptySelection
      ) {
        if (utils.isBackspacePressed(event)) {
          const node = editor.children[selection.anchor.path[0]] as
            | CustomElement
            | undefined;

          const isListNode =
            node?.type === 'list-item' ||
            node?.type === 'ordered-list' ||
            node?.type === 'unordered-list';

          if (isListNode && node?.children?.length === 1) {
            EditorActions.toggleBlock(editor, 'list-item');
          }
        }

        if (utils.isEnterPressed(event)) {
          EditorActions.toggleBlock(editor, 'list-item');
        }
      }
    },
    [editor],
  );

  useEffect(() => {
    if (inputValue && !hasSetInitialValue.current) {
      try {
        const parsedValue = JSON.parse(inputValue);

        if (parsedValue) {
          editor.children = parsedValue;
          hasSetInitialValue.current = true;
        }
        // eslint-disable-next-line no-empty
      } catch (error) {}
    }
  }, [editor, inputValue]);

  return (
    <div className={classes}>
      {label && (
        <div className="anys-rich-text-editor__label-wrapper">
          <label htmlFor={name}>{label}</label>
          {asideLabelContent}
        </div>
      )}
      <div
        className={classNames('anys-rich-text-editor__editor', {
          'anys-rich-text-editor__editor--error': hasError,
          'anys-rich-text-editor__editor--read-only': readOnly,
        })}
      >
        <Slate editor={editor} value={initialValue} onChange={handleOnChange}>
          <RichTextToolbar readOnly={readOnly} />
          <Editable
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            className={classNames('anys-rich-text-editor__textarea', {
              'anys-rich-text-editor__textarea--read-only': readOnly,
            })}
            name={name}
            onKeyDown={handleKeyDown}
            readOnly={readOnly}
            {...restOfInput}
            {...restOfProps}
          />
        </Slate>
      </div>
      <ValidationError showError={hasError} error={error} />
    </div>
  );
};

const RichTextEditorField: React.FC<RichTextEditorFieldProps> = (props) => (
  <Field component={RichTextEditor} {...props} />
);

export default RichTextEditorField;
