import React, {
  ChangeEvent,
  JSXElementConstructor,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import useQueryParams from 'hooks/useQueryParams';
import {
  FilterContext,
  FilterType,
} from 'router/subrouters/Search/pages/Search/providers/Filters/Filters.provider';
import useEffectSkipFirst from 'hooks/useEffectSkipFirst';

import './Filter.styles.scss';
import './Filter.styles.responsive.scss';

const idempotent = (value: any) => value;

type RequiredProps = {
  value: SimpleSelectValue;
  onChange: (val: SimpleSelectValue) => void;
};

export type SimpleSelectValue = string | string[];

type FilterProps<Props, MustProps extends RequiredProps> = {
  name: string;
  debounceOnChange?: number;
  defaultValue?: string;
  type?: FilterType;
  dbName?: string;
  onDestroy?: () => void;
  valueMap?: (val: any) => string | Promise<string>;
  valueParse?: (val: string) => any;
} & (Props extends {
  value?: any;
  onChange?: (val: any) => void;
}
  ? {
      component: JSXElementConstructor<Props>;
    } & Omit<Props, 'name' | 'component' | 'value' | 'onChange'>
  : {
      component: JSXElementConstructor<MustProps>;
    });

function Filter<Props, MustProps extends RequiredProps>(
  props: FilterProps<Props, MustProps>,
) {
  const isArray = props.name.includes('[]');
  // const isMountedRef = useRef(true);

  const {
    name,
    type = isArray ? 'array' : 'single',
    dbName,
    defaultValue,
    valueMap = String,
    valueParse = idempotent,
    debounceOnChange,
    component,
    onDestroy,
    ...compProps
  } = props;

  const { registerFilter, unregisterFilter } = useContext(FilterContext);

  const {
    params: { [name]: queryValue = '' },
    setQueryParam,
    removeQueryParam,
  } = useQueryParams();

  const value = useMemo(() => {
    if (!queryValue) return isArray ? [] : queryValue;
    return isArray ? valueParse(queryValue).split(',') : valueParse(queryValue);
  }, [isArray, queryValue, valueParse]);

  const onChange = useCallback(
    async <T extends { toString(): string }>(
      value: T | T[] | ChangeEvent<HTMLInputElement>,
    ) => {
      if (!value) {
        if (queryValue) {
          removeQueryParam(name);
        }
        return;
      }

      if (typeof value === 'number') {
        setQueryParam(name, value);
        return;
      }

      if ('target' in value) {
        const inputVal = value.target.value;

        if (inputVal) setQueryParam(name, inputVal);
        else removeQueryParam(name);
        return;
      }

      if (Array.isArray(value)) {
        if (!value.length) removeQueryParam(name);
        else {
          const mappedValues = await Promise.all(value.map(valueMap));

          setQueryParam(name, mappedValues.join(','));
        }
      } else {
        const mappedValue = await valueMap(value);

        setQueryParam(name, mappedValue);
      }
    },
    [name, queryValue, setQueryParam, valueMap, removeQueryParam],
  );

  useEffect(() => {
    if (!defaultValue || queryValue) return;

    setQueryParam(name, defaultValue, true);
  }, [defaultValue, name, queryValue, setQueryParam]);

  useEffect(() => {
    if (!dbName || !type) return;

    registerFilter(name, dbName, type);

    return () => unregisterFilter(name);
  }, [dbName, name, registerFilter, type, unregisterFilter]);

  useEffectSkipFirst(() => {
    return () => {
      onDestroy?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Component = component;

  return (
    <Component
      value={value}
      onChange={onChange}
      {...(compProps as unknown as any)}
    />
  );
}

export default Filter;
