import React from 'react';
import classNames from 'classnames';
import { SelectProps } from 'ncoded-component-library/build/components/molecules/Select/Select.component';

import './MultipleSelectTrigger.styles.scss';
import './MultipleSelectTrigger.styles.responsive.scss';

function MultipleSelectTrigger<T>(
  props: Partial<SelectProps<T>> & {
    onClick?: () => void;
  } & { label?: string },
) {
  const { className, placeholder, icon, value, multiple, onClick } = props;

  const classes = classNames(
    'ncoded-select',
    'anys-multiple-select-trigger',
    { active: multiple ? !!value.length : !!value },
    className,
  );

  const label = props.label || placeholder;

  return (
    <div className={classes} onClick={onClick}>
      <div className="ncoded-select__selected">
        {label && <span className="ncoded-select__content">{label}</span>}
        {multiple && !!value?.length && (
          <span className="ncoded-select__multiple-selected">
            {value.length}
          </span>
        )}
        {icon && (
          <span className="dropdown-icon">{icon as React.ReactNode}</span>
        )}
      </div>
    </div>
  );
}

export default MultipleSelectTrigger;
