import React, { useMemo } from 'react';
import classNames from 'classnames';
import { t } from 'i18next';
import ProfileCard from 'components/Card/ProfileCardComponent';
import { useProfile } from 'router/subrouters/Profile/pages/Profile/Profile.page';
import useCallbackRef from 'hooks/useCallbackRef';
import { ModalRef } from 'components/Modal';
import ReviewCardModal from './components/ReviewCardModal';
import EmojiReviewRating from 'components/EmojiReviewRating';

import './ReviewCard.styles.scss';

type ReviewCardProps = {
  className?: string;
  cardTitle?: string;
};

const ReviewCard: React.FC<ReviewCardProps> = (props) => {
  const { className } = props;

  const {
    profileUser: { overallClientScore },
  } = useProfile();

  const classes = classNames('anys-review-card', className);

  const messages = useMemo(
    () => ({
      title: t('ClientReview.reviewedAsAClient'),
      seeMore: t('General.seeMore'),
      noReviewsPlaceholder: t('General.noReviewsPlaceholder'),
      basedOnReviews: t('ClientReview.basedOnReviews', {
        reviews: overallClientScore?.numberOfReviews,
      }),
    }),
    [overallClientScore?.numberOfReviews],
  );

  const [reviewedSkillsModal, reviewedSkillsModalRef] =
    useCallbackRef<ModalRef>(null);

  return (
    <ProfileCard
      cardTitle={messages.title}
      className={classes}
      buttonLabel={t('General.seeMore')}
      onClick={() => {
        reviewedSkillsModal.open();
      }}
    >
      {!overallClientScore?.averageRating ? (
        <p className="anys-review-card__no-reviews">
          {messages.noReviewsPlaceholder}
        </p>
      ) : (
        <section className="anys-review-card__content">
          <EmojiReviewRating grade={+overallClientScore?.averageRating} />

          <section className="anys-review-card__content__info">
            <label className="anys-review-card__content__info__grade">
              {(+overallClientScore?.averageRating).toFixed(1)}
              <small>/5</small>
            </label>
            <p className="anys-review-card__content__info__reviews">
              {messages.basedOnReviews}
            </p>
          </section>
        </section>
      )}
      <ReviewCardModal skillsModalRef={reviewedSkillsModalRef} />
    </ProfileCard>
  );
};

export default ReviewCard;
