import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import Tabs from 'router/subrouters/Profile/pages/ProfilePreview/components/AvailabilityModal/components/Tabs';
import CountdownTimer from 'components/CountdownTimer';
import { Button } from 'ncoded-component-library';
import Modal from 'components/Modal';
import useCallbackRef from 'hooks/useCallbackRef';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import api from 'api';
import dayjs from 'dayjs';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import InputField from 'components/InputField';
import { FieldArray } from 'react-final-form-arrays';
import AddSmallIcon from 'icons/AddSmall.icon';
import { AnyObject } from 'final-form';
import ProviderClientProofs from '../ProviderClientProofs/ProviderClientProofs.components';
import RadioGroupField from 'components/RadioGroupField';
import RadioButtonChoice from 'components/RadioButtonChoice';
import Notice from 'components/Notice';
import { Trans, useTranslation } from 'react-i18next';
import AmountSlider from 'router/subrouters/Contract/pages/ContractPreview/components/AmountSlider';
import { useArbitration } from 'providers/Arbitration/Arbitration.provider';
import useMatchMedia from 'hooks/useMatchMedia';
import confirm from 'modules/confirm';

import './ArbitrationDecisioning.styles.scss';
import './ArbitrationDecisioning.styles.responsive.scss';
import OverlaySpinner from 'components/OverlaySpinner';
import {
  convertSubUnitToUnit,
  convertUnitToSubUnit,
  formatNumber,
  unitDivisor,
} from 'utils/currency';
import showToast from '../../../../../../../modules/showToast';

const availabilityTabs: { name: string; id: number }[] = [
  {
    name: 'Provider proof',
    id: 1,
  },
  {
    name: 'Client proof',
    id: 2,
  },
];

type ArbitrationDecisioningProps = {
  className?: string;
};

const ArbitrationDecisioning = (props: ArbitrationDecisioningProps) => {
  const { className } = props;
  const classes = classNames('anys-arbitration-decisioning', className);

  const {
    selectedArbitration: { proofs, contract, id, actionTimers },
    setSelectedArbitration,
  } = useArbitration();

  const { t } = useTranslation();

  const [active, setActive] = useState(1);

  const [askProviderModal, askProviderModalRef] =
    useCallbackRef<ModalRef>(null);

  const [decideArbitrationModal, decideArbitrationModalRef] =
    useCallbackRef<ModalRef>(null);

  const providerProofs = useMemo(
    () => proofs?.find((proof) => proof.submittedBy === 'provider'),
    [proofs],
  );

  const clientProofs = useMemo(
    () => proofs?.find((proof) => proof.submittedBy === 'client'),
    [proofs],
  );

  const decideTimer = useMemo(() => {
    const date1 = dayjs(new Date());
    const date2 = dayjs(
      actionTimers.find(
        (actionTimer) => actionTimer.type === 'pending_decision',
      )?.endsAt,
    );

    return date2.diff(date1, 'milliseconds');
  }, [actionTimers]);

  const isPhablet = useMatchMedia('isPhablet');

  const providerPriceInUnit = contract.paymentProposal?.providerPrice
    ? convertSubUnitToUnit(
        contract.paymentProposal.providerPrice,
        unitDivisor('USD'),
      )
    : 0;

  const clientPriceInUnit = contract.paymentProposal?.clientPrice
    ? convertSubUnitToUnit(
        contract.paymentProposal.clientPrice,
        unitDivisor('USD'),
      )
    : 0;

  //TODO: divide two timmers for client and provider
  // const questionTimer = useMemo(() => {
  //   const date1 = dayjs(new Date());
  //   const date2 = dayjs(
  //     actionTimers.find(
  //       (actionTimer) => actionTimer.type === 'pending_proof_inquiry_ask',
  //     )?.endsAt,
  //   );

  //   return date2.diff(date1, 'milliseconds');
  // }, [actionTimers]);

  const handleAskQuestions = useCallback(
    async ({ questions }: any) => {
      try {
        OverlaySpinner.show('.anys-arbitration-page__main');

        const { data } = await api.arbitration.askQuestions(
          id,
          questions,
          active === 1 ? 'provider' : 'client',
        );

        const submitted = active === 1 ? 'provider' : 'client';

        if (data) {
          setSelectedArbitration((old) => ({
            ...old,
            proofs: old.proofs.map((proof) => {
              if (proof.submittedBy === submitted) {
                return { ...proof, inquiries: data };
              } else return proof;
            }),
            actionTimers: old.actionTimers.filter(
              (actionTimer) =>
                actionTimer.type !==
                (submitted === 'provider'
                  ? 'pending_proof_inquiry_ask_provider'
                  : 'pending_proof_inquiry_ask_client'),
            ),
          }));
          askProviderModal.close();
        }
      } catch (e) {
        console.error(e);
      } finally {
        OverlaySpinner.hide('.anys-arbitration-page__main');
      }
    },
    [active, askProviderModal, id, setSelectedArbitration],
  );

  const handleRejectArbitration = useCallback(async () => {
    try {
      OverlaySpinner.show('.anys-arbitration-page__main');

      const { data } = await api.arbitration.rejectArbitration(id);

      if (data) {
        setSelectedArbitration((old) => ({ ...old, status: data.status }));
      }
    } catch (e) {
      console.error(e);
    } finally {
      OverlaySpinner.hide('.anys-arbitration-page__main');
    }
  }, [id, setSelectedArbitration]);

  const handleDecideArbitration = useCallback(
    async (data: {
      choice: 'provider' | 'client' | 'beetwen';
      cancelationFee?: string;
      priceRange: {
        max: number;
        min: number;
        range: number;
      };
    }) => {
      const { choice, cancelationFee } = data;

      try {
        if (
          choice === 'beetwen' &&
          (clientPriceInUnit + providerPriceInUnit) / 2 ===
            parseInt(cancelationFee, 10)
        ) {
          showToast('warn', t('Arbitration.middleValueWarn'));
          return;
        }
        OverlaySpinner.show('.anys-arbitration-page__main');

        if (choice !== 'beetwen') {
          await api.arbitration.voteInArbitration(id, choice);
          decideArbitrationModal.close();
          setSelectedArbitration((old) => ({ ...old, state: 'decision_made' }));
        } else {
          const { data } = await api.arbitration.decideArbitationChangePayment(
            id,
            convertUnitToSubUnit(cancelationFee, unitDivisor('USD')),
          );
          if (data) {
            setSelectedArbitration((old) => ({
              ...old,
              state: 'decision_made',
            }));

            decideArbitrationModal.close();
          }
        }
      } catch (e) {
        console.error(e);
      } finally {
        OverlaySpinner.hide('.anys-arbitration-page__main');
      }
    },
    [
      clientPriceInUnit,
      decideArbitrationModal,
      id,
      providerPriceInUnit,
      setSelectedArbitration,
      t,
    ],
  );

  //because form submit button is outside of form
  let submitQuestions: (
    event?: Partial<
      Pick<
        React.SyntheticEvent<Element, Event>,
        'preventDefault' | 'stopPropagation'
      >
    >,
  ) => Promise<AnyObject>;

  //because form submit button is outside of form
  let decideArbitration: (
    event?: Partial<
      Pick<
        React.SyntheticEvent<Element, Event>,
        'preventDefault' | 'stopPropagation'
      >
    >,
  ) => Promise<AnyObject>;

  return (
    <section className={classes}>
      <h1>{t('Arbitration.arbitrationForContractId', { id: contract?.id })}</h1>
      <Tabs
        labels={availabilityTabs}
        active={active}
        setActive={setActive}
        className="anys-arbitration-decisioning__tabs"
      />

      {isPhablet && (
        <CountdownTimer
          startTimeInMS={decideTimer}
          className="anys-arbitration-decisioning__timmer anys-arbitration-decisioning__timmer--mobile"
          iconGradient
        />
      )}

      {active === 1 ? (
        <ProviderClientProofs
          previewType="provider"
          proofs={providerProofs}
          questionModal={askProviderModal}
          actionTimers={actionTimers}
        />
      ) : (
        <ProviderClientProofs
          previewType="client"
          proofs={clientProofs}
          questionModal={askProviderModal}
          actionTimers={actionTimers}
        />
      )}

      <section className="anys-arbitration-decisioning__actions">
        <section className="anys-arbitration-decisioning__actions--btns">
          <Button type="submit" onClick={() => decideArbitrationModal.open()}>
            {t('General.decide')}
          </Button>
          <Button
            variant="outline"
            onClick={async () => {
              if (
                !(await confirm({
                  title: t('Arbitration.rejectArbitration'),
                  onConfirm: handleRejectArbitration,
                  confirmContent: t('General.reject'),
                  description: t('Arbitration.areYouSureToRejectArbitrations', {
                    contractId: contract.id,
                  }),
                }))
              ) {
                return;
              }
            }}
          >
            {t('Arbitration.rejectArbitration')}
          </Button>
        </section>

        <CountdownTimer
          startTimeInMS={decideTimer}
          className="anys-arbitration-decisioning__timmer"
          iconGradient
        />
      </section>

      {/* ASK PROVIDER/CLIENT MODAL */}
      <Modal
        type="no-action"
        modalName="ask-provider"
        ref={askProviderModalRef}
        title={t('Arbitration.askOtherSide', {
          previewType: active === 1 ? 'Provider' : 'Client',
        })}
        className="anys-arbitration-decisioning__question-modal"
        isDrawer
        footer={
          <div className="anys-arbitration-decisioning__question-modal__footer">
            <Button
              onClick={async () => {
                if (
                  !(await confirm({
                    title: t('Arbitration.questions'),
                    description: t(
                      'Arbitration.areYouSureYouWantToSendQuestions',
                      { contractId: contract.id },
                    ),
                    onConfirm: submitQuestions,
                    confirmContent: 'Confirm',
                  }))
                ) {
                  return;
                }
              }}
            >
              {t('General.ask')}
            </Button>
            <Button
              onClick={() => askProviderModal.close()}
              type="button"
              variant="link"
            >
              <span>{t('General.cancel')}</span>
            </Button>
          </div>
        }
      >
        <Form
          onSubmit={handleAskQuestions}
          mutators={{
            ...arrayMutators,
          }}
          initialValues={{ questions: [undefined] }}
          render={({
            handleSubmit,
            form: {
              mutators: { push, pop },
            },
          }) => {
            submitQuestions = handleSubmit;
            return (
              <form onSubmit={handleSubmit}>
                <p className="anys-arbitration-decisioning__question-modal__title">
                  {active === 1
                    ? t('Arbitration.pleaseAddQuestionsForProvider')
                    : t('Arbitration.pleaseAddQuestionsForClient')}
                </p>

                <FieldArray name="questions">
                  {({ fields }) =>
                    fields.map((name, index) => (
                      <InputField
                        key={index}
                        name={`${name}`}
                        label={t('Arbitration.questionId', { id: index + 1 })}
                        placeholder={t('Arbitration.enterYourQuestion')}
                      />
                    ))
                  }
                </FieldArray>
                <Button
                  type="button"
                  variant="link"
                  className="anys-arbitration-decisioning__question-modal__add-field-btn"
                  onClick={() => {
                    push('questions', undefined);
                  }}
                >
                  <AddSmallIcon />
                  <span>{t('Arbitration.addQuestion')}</span>
                </Button>
              </form>
            );
          }}
        />
      </Modal>

      {/* DECIDE ARBITRATION MODAL */}
      <Modal
        type="no-action"
        modalName="decide-arbitration"
        title={t('General.whoIsRight')}
        ref={decideArbitrationModalRef}
        className="anys-arbitration-decisioning__decide-modal"
        isDrawer
        footer={
          <div className="anys-arbitration-decisioning__decide-modal__footer">
            <Button onClick={(e) => decideArbitration(e)}>
              {t('General.confirm')}
            </Button>
            <Button
              onClick={() => decideArbitrationModal.close()}
              type="button"
              variant="link"
              className="anys-arbitration-decisioning__decide-modal__footer__cancel-btn"
            >
              <span>{t('General.cancel')}</span>
            </Button>
          </div>
        }
      >
        {/* <span>provider {contract.paymentProposal?.providerPrice}</span>
        <span>client {contract.paymentProposal?.clientPrice}</span>
        <span>Something in between</span> */}

        <Form
          onSubmit={handleDecideArbitration}
          render={({ handleSubmit, values: { choice }, form }) => {
            decideArbitration = handleSubmit;

            return (
              <>
                <span>
                  {t('Arbitration.pleaseSelectWhoIsWinner', {
                    contractId: contract.id,
                  })}
                </span>
                <RadioGroupField
                  name="choice"
                  className="anys-arbitration-decisioning__decide-modal__radio-group"
                  options={[
                    {
                      value: 'provider',
                      label: (
                        <div className="anys-arbitration-decisioning__decide-modal__radio-btn">
                          <span>{t('General.provider')}</span>
                          <span>
                            {t('Arbitration.providerWillGetAmount', {
                              amount: providerPriceInUnit
                                ? formatNumber(t, providerPriceInUnit, {})
                                : 0,
                            })}
                          </span>
                        </div>
                      ),
                    },
                    {
                      value: 'client',
                      label: (
                        <div className="anys-arbitration-decisioning__decide-modal__radio-btn">
                          <span>{t('General.client')}</span>
                          <span>
                            {t('Arbitration.providerWillGetAmount', {
                              amount: clientPriceInUnit
                                ? formatNumber(t, clientPriceInUnit, {})
                                : 0,
                            })}
                          </span>
                        </div>
                      ),
                    },
                    {
                      value: 'beetwen',
                      label: (
                        <div className="anys-arbitration-decisioning__decide-modal__radio-btn">
                          {t('Arbitration.somethingInBetween')}
                        </div>
                      ),
                    },
                  ]}
                  customRadioComponent={RadioButtonChoice}
                  direction="row"
                />
                {choice ? (
                  choice !== 'beetwen' ? (
                    <Notice type="warn">
                      <Trans>
                        <span>
                          {choice === 'provider'
                            ? t('Arbitration.decideArbitrationProviderWon', {
                                val: providerPriceInUnit,
                              })
                            : t('Arbitration.decideArbitrationClientWon', {
                                val: clientPriceInUnit,
                              })}
                        </span>
                      </Trans>
                    </Notice>
                  ) : (
                    <AmountSlider
                      form={form}
                      minAmountFee={clientPriceInUnit}
                      maxAmountFee={providerPriceInUnit}
                      label={t('Arbitration.providerWillGet')}
                    />
                  )
                ) : (
                  <></>
                )}
              </>
            );
          }}
        />
      </Modal>
    </section>
  );
};

export default ArbitrationDecisioning;
