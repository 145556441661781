import React, { useContext, useMemo } from 'react';
import NotificationBadge from 'components/NotificationBadge';
import NotificationsContext from 'providers/Notifications/Notifications.context';
import { Arbitration } from 'models/Arbitrations';
import classNames from 'classnames';
import useQueryParams from 'hooks/useQueryParams';
import { useTranslation } from 'react-i18next';

import './ArbitrationListChip.styles.scss';

type ArbitrationListChipProps = {
  className?: string;
  arbitration: Arbitration;
};
const ArbitrationListChip = (props: ArbitrationListChipProps) => {
  const { className, arbitration } = props;

  const { getArbitrationNotifCount, getLastNotifForArbitration } =
    useContext(NotificationsContext);

  const { id, notifications } = arbitration;
  const { t } = useTranslation();

  const lastSocketNotifId = getLastNotifForArbitration(id)?.id;

  const ignoreSocketNotifs =
    lastSocketNotifId === notifications?.at(-1)?.id;

  const notifCount = notifications?.length;

  const notificationsCount = ignoreSocketNotifs
    ? notifCount
    : (notifCount || 0) + getArbitrationNotifCount(id);

  const {
    params: { arbitrationId },
    setQueryParam,
    removeQueryParam,
  } = useQueryParams();

  const classes = useMemo(
    () =>
      classNames(
        'anys-arbitration-list-chip',
        {
          'anys-arbitration-list-chip--selected':
            +arbitrationId === arbitration.id,
          'anys-arbitration-list-chip--with-notif': notificationsCount,
        },
        className,
      ),
    [arbitration.id, arbitrationId, className, notificationsCount],
  );

  return (
    <section
      className={classes}
      onClick={() => {
        removeQueryParam('my_terms');
        setQueryParam('arbitrationId', arbitration?.id);
      }}
    >
      <span className="anys-arbitration-list-chip__title">
        {t('Arbitration.arbitrationForContractId', {
          id: arbitration.contract?.id,
        })}
      </span>
      <span className="anys-arbitration-list-chip__description">
        {arbitration.contract.title}
      </span>
      {notificationsCount ? (
        <NotificationBadge
          count={notificationsCount}
          className="anys-arbitration-list-chip__notif-badge"
        />
      ) : null}
    </section>
  );
};

export default ArbitrationListChip;
