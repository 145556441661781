import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Field, FieldRenderProps, UseFieldConfig } from 'react-final-form';
import formValidators from 'utils/formValidators';
import ValidationError from 'components/ValidationError';
import TimeInput, { TimeInputProps } from 'components/TimeInput';

import './TimeInputField.styles.scss';

type TimeInputFieldProps = Partial<TimeInputProps> & {
  name: string;
} & UseFieldConfig<string, string>;

type TimeInputFieldComponentProps = TimeInputProps &
  FieldRenderProps<string, HTMLElement>;

const TimeInputFieldComponent: React.FC<TimeInputFieldComponentProps> = (
  props,
) => {
  const { className, input, meta, ...restOfProps } = props;

  const classes = classNames('anys-time-input-field', className);

  const { error, hasError } = useMemo(
    () => formValidators.getErrorFromMeta(meta),
    [meta],
  );

  return (
    <div className={classes}>
      <TimeInput hasError={hasError} {...restOfProps} {...input} />
      <ValidationError error={error} showError={hasError} />
    </div>
  );
};

const TimeInputField: React.FC<TimeInputFieldProps> = (props) => (
  <Field component={TimeInputFieldComponent} {...props} />
);

export default TimeInputField;
