import { Money } from './../constants/currency';
import regex from 'constants/regex';
import { Dayjs } from 'dayjs';
import { FieldValidator } from 'final-form';
import { TFunction } from 'i18next';
import { FieldMetaState } from 'react-final-form';

const getErrorFromMeta = <T>(
  meta: FieldMetaState<T>,
): { hasError: boolean; error: string } => {
  const { invalid, touched, error } = meta;

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = !!(touched && invalid && error);

  return { hasError, error };
};

const scrollToFirstError = (backupScrollElement?: Element) => {
  // setTimeout is used because when we submit the form,
  // it takes some time in order to validate the form
  // and to show the validation error
  setTimeout(() => {
    const validationError = document.getElementsByClassName(
      'anys-validation-error',
    );

    if (validationError?.length) {
      const errorEl = validationError[0] as HTMLDivElement;

      const { top } = errorEl.getBoundingClientRect();

      const canScrollDocument =
        document.scrollingElement.scrollHeight !==
        document.scrollingElement.clientHeight;

      if (canScrollDocument)
        // -200 so we can see the input as well
        window.scroll({ behavior: 'smooth', top: top + window.scrollY - 200 });
      else if (backupScrollElement) {
        // This is mainly made for use inside inbox page,
        // where instead of the whole page scrolling, only
        // the inner section scrolls
        backupScrollElement.scroll({
          behavior: 'smooth',
          top: errorEl.offsetTop - 300,
        });
      }
    }
  }, 0);
};

// Final Form expects and undefined value for a successful validation
const VALID = undefined as undefined;

const PASSWORD_MIN_LENGTH = 8;
const JOB_TITLE_MIN_LENGTH = 4;
const JOB_TITLE_MAX_LENGTH = 100;

const JOB_DESC_MIN_LENGTH = 4;
const JOB_DESC_MAX_LENGTH = 5000;
const JOB_DESC_DEFAULT_LENGTH_TO_SHOW = 1300;

const isNonEmptyString = (val: any) => {
  return typeof val === 'string' && val.trim().length > 0;
};

export const isEmptyValue = (val: any) =>
  typeof val === 'undefined' || val === null || val === '';

const TEXT_EDITOR_TEXT_KEY = '"text"';

/**
 * @param val should be stringified JSON from RichTextEditor
 */
const getRichTextCharLength = (val: string) => {
  if (!val) return 0;

  let charLength = 0;
  let startIndex = 0;
  let index;

  while ((index = val.indexOf(TEXT_EDITOR_TEXT_KEY, startIndex)) > -1) {
    const startIndexOfText =
      val.indexOf('"', index + TEXT_EDITOR_TEXT_KEY.length) + 1;

    const endIndexOfText = val.indexOf('"', startIndexOfText);

    const text = val.substring(startIndexOfText, endIndexOfText);

    charLength += text.trim().length;

    startIndex = index + 1;
  }

  return charLength;
};

/**
 * @param val should be stringified JSON from RichTextEditor
 */
const isEmptyTextEditor = (val: string) => {
  return !(getRichTextCharLength(val) > 0);
};

const requiredRichText =
  (message: string): FieldValidator<string> =>
  (value: string) => {
    if (!value) return message;

    const stringLength = value.length;

    if (stringLength === 0) return message;

    if (typeof value === 'object' && !Object.values(value).length)
      return message;

    return isEmptyTextEditor(value) ? message : VALID;
  };

const required =
  <T>(message: string): FieldValidator<T> =>
  (value: T) => {
    if (isEmptyValue(value)) {
      // undefined or null values are invalid
      return message;
    }

    if (typeof value === 'string') {
      // string must be nonempty when trimmed
      return isNonEmptyString(value) ? VALID : message;
    }

    return VALID;
  };

const requiredArray =
  <T>(message: string): FieldValidator<T[]> =>
  (value) => {
    if (!value) return message;

    if (value.length > 0) return VALID;

    return message;
  };

const emailFormatValid =
  (message: string): FieldValidator<string> =>
  (value: string) => {
    return value && regex.EMAIL.test(value) ? VALID : message;
  };

const minLength =
  <T>(message: string, minLength: number): FieldValidator<T> =>
  (value: any) => {
    const hasLength = value && typeof value.length === 'number';

    return hasLength && value.length >= minLength ? VALID : message;
  };

const minRichTextLength =
  (message: string, minLength: number): FieldValidator<string> =>
  (value) => {
    if (!value) return VALID;

    return value.length >= minLength ? VALID : message;
  };

const maxLength =
  <T>(message: string, maxLength: number): FieldValidator<T> =>
  (value: any) => {
    const hasLength = value && typeof value.length === 'number';

    return hasLength && value.length <= maxLength ? VALID : message;
  };

const maxRichTextLength =
  (message: string, maxLength: number): FieldValidator<string> =>
  (value) => {
    if (!value) return VALID;

    return value.length <= maxLength ? VALID : message;
  };

const requiredDate =
  (message: string): FieldValidator<Date> =>
  (value) => {
    const dateIsValid = value instanceof Date;

    return dateIsValid ? VALID : message;
  };

const requiredTime =
  (message: string): FieldValidator<string> =>
  (value) => {
    // const dateIsValid = value instanceof Date;

    return /([0-9]{2}:[0-9]{2})/g.test(value) ? VALID : message;

    // return dateIsValid ? VALID : message;
  };

const requiredFile =
  (message: string): FieldValidator<File[]> =>
  (value) => {
    return value && value?.length !== 0 ? undefined : message;
  };

const dateIsAfter =
  (
    message: string,
    dateToCompare: Date | number,
    isSame = false,
  ): FieldValidator<Date> =>
  (value) => {
    if (!value) return VALID;

    const isAfter = isSame ? value >= dateToCompare : value > dateToCompare;

    return isAfter ? VALID : message;
  };

const dateFromCalendarIsAfter =
  (
    message: string,
    dateToCompare: Date | number,
    isSame = false,
  ): FieldValidator<string | Dayjs | Dayjs[] | Date> =>
  (value) =>
    isSame
      ? new Date(value as string) >= dateToCompare
      : new Date(value as string) > dateToCompare
        ? VALID
        : message;

const dateIsBefore =
  (
    message: string,
    dateToCompare: Date | number,
    isSame = false,
  ): FieldValidator<Date> =>
  (value) => {
    const isBefore = isSame ? value <= dateToCompare : value < dateToCompare;

    return isBefore ? VALID : message;
  };

const minValue =
  <T = number | string>(
    message: string,
    min: number,
    isEqual?: boolean,
  ): FieldValidator<T> =>
  (value) => {
    if (isEmptyValue(value)) return VALID;

    const isGreater = isEqual ? +value >= min : +value > min;

    return isGreater ? VALID : message;
  };

const maxValue =
  <T = number | string>(
    message: string,
    max: number,
    isEqual?: boolean,
  ): FieldValidator<T> =>
  (value) => {
    if (isEmptyValue(value)) return VALID;

    const isLess = isEqual ? +value <= max : +value < max;

    return isLess ? VALID : message;
  };

const minMoneyValue =
  <T = Money>(
    message: string,
    min: number,
    isEqual?: boolean,
  ): FieldValidator<T> =>
  (value) => {
    const val = (value as Money)?.amount ? (value as Money).amount / 100 : '';

    if (!value || isEmptyValue(val)) return VALID;

    const isGreater = isEqual ? +val >= min : +val > min;

    return isGreater ? VALID : message;
  };

const maxMoneyValue =
  <T = Money>(
    message: string,
    max: number,
    isEqual?: boolean,
  ): FieldValidator<T> =>
  (value) => {
    const val = (value as Money)?.amount ? (value as Money).amount / 100 : '';

    if (isEmptyValue(val)) return VALID;

    const isLess = isEqual ? +val <= max : +val < max;

    return isLess ? VALID : message;
  };

const composeValidators =
  <T>(...validators: FieldValidator<T>[]) =>
  (value: T, allValues: any) =>
    validators.reduce(
      (error, validator) => error || validator(value, allValues),
      VALID,
    );

const getPasswordValidators = (
  t: TFunction,
  requiredMsg = 'General.passwordRequired',
  minLengthMsg = 'General.passwordMinLength',
) =>
  composeValidators<string>(
    required(t(requiredMsg)),
    minLength(
      t(minLengthMsg, { minLength: PASSWORD_MIN_LENGTH }),
      PASSWORD_MIN_LENGTH,
    ),
  );

const getJobTitleValidators = (
  t: TFunction,
  requiredMsg = 'JobForm.jobTitleRequired',
  minLengthMsg = 'JobForm.jobTitleShort',
  maxLengthMsg = 'General.maxCharLengthError',
) =>
  composeValidators<string>(
    required(t(requiredMsg)),
    minLength(
      t(minLengthMsg, { minLength: JOB_TITLE_MIN_LENGTH }),
      JOB_TITLE_MIN_LENGTH,
    ),
    maxLength(
      t(maxLengthMsg, { max: JOB_TITLE_MAX_LENGTH }),
      JOB_TITLE_MAX_LENGTH,
    ),
  );

const getEmailValidators = (
  t: TFunction,
  isRequired = true,
  requiredMsg = 'General.emailRequired',
  invalidFormatMsg = 'General.emailInvalid',
): FieldValidator<string> | ((value: string) => FieldValidator<string>) => {
  const validFormat = emailFormatValid(t(invalidFormatMsg));

  if (isRequired) {
    return composeValidators<string>(required(t(requiredMsg)), validFormat);
  }

  return validFormat;
};

const contractExplanation = () =>
  composeValidators<string>(
    required('Required'),
    minLength('Please enter at least 20 characters', 20),
  );

export default {
  PASSWORD_MIN_LENGTH,
  JOB_TITLE_MIN_LENGTH,
  JOB_TITLE_MAX_LENGTH,
  JOB_DESC_MIN_LENGTH,
  JOB_DESC_MAX_LENGTH,
  JOB_DESC_DEFAULT_LENGTH_TO_SHOW,
  getErrorFromMeta,
  getPasswordValidators,
  getEmailValidators,
  scrollToFirstError,
  maxValue,
  getJobTitleValidators,
  required,
  requiredRichText,
  requiredArray,
  requiredDate,
  requiredTime,
  requiredFile,
  dateIsAfter,
  dateIsBefore,
  emailFormatValid,
  minLength,
  minRichTextLength,
  maxLength,
  maxRichTextLength,
  minValue,
  minMoneyValue,
  maxMoneyValue,
  composeValidators,
  dateFromCalendarIsAfter,
  contractExplanation,
};
