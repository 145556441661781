import { GOOGLE_MAPS_PLACES_SCRIPT_ID } from 'App';
import OverlaySpinner from 'components/OverlaySpinner';
import { Bounds, CurrentLocationState, PlaceFormat } from 'models/User';
import { useCallback, useEffect, useState } from 'react';
import useEffectOnce from './useEffectOnce';
import googleMaps from 'utils/googleMaps';
import showToast from 'modules/showToast';

const LOADER_EL_NAME = '#root';
// 864000000 = 10 days in milliseconds
const MAX_AGE_MS = 864000000;

type CurrentUserRet = {
  getCurrentLocation: (
    shouldReturnFullLocation?: boolean,
  ) => Promise<Partial<PlaceFormat>>;
} & Readonly<
  | {
      state: 'not-allowed' | 'loading';
      location: undefined;
    }
  | {
      state: 'partial';
      location: Pick<PlaceFormat, 'lat' | 'lng'>;
    }
  | {
      state: 'full';
      location: Required<PlaceFormat>;
    }
>;

const GEOCODED_COORDS: Record<
  string,
  { city: string; country: string; street: string; bounds: Bounds }
> = {};

export const getLatLngKey = (lat: number, lng: number) => `${lat}:${lng}`;

type CurrentLocationProps = Partial<{
  callInitially: boolean;
  onFinished: (position?: PlaceFormat) => void;
}>;

const useCurrentLocation = (props?: CurrentLocationProps): CurrentUserRet => {
  const { callInitially = true, onFinished } = props || {};

  const [state, setState] = useState<CurrentLocationState>('loading');

  const [location, setLocation] = useState<Partial<PlaceFormat>>();

  const [placesScript, setPlacesScript] = useState<any & HTMLScriptElement>();

  const onLocationDisabledInternal = useCallback(() => {
    setState('not-allowed');
    OverlaySpinner.hide(LOADER_EL_NAME);
  }, []);

  const geocodeLocation = useCallback(
    async (lat: number, lng: number) => {
      if (placesScript && lat && lng) {
        const cachedCoords = GEOCODED_COORDS[getLatLngKey(lat, lng)];
        if (cachedCoords) {
          setLocation((old) => ({ ...old, ...cachedCoords }));
          setState('full');

          return { lat, lng, ...cachedCoords };
        }

        OverlaySpinner.show(LOADER_EL_NAME);

        const geocoder = new google.maps.Geocoder();
        const latlng = new google.maps.LatLng(lat, lng);

        try {
          const { results } = await geocoder.geocode({ location: latlng });

          const street = googleMaps.getAddressComponent(results[0], [
            'route',
            'street_address',
          ])?.long_name;

          const city = googleMaps.getAddressComponent(results[0], [
            'locality',
            'city',
          ])?.long_name;

          const country = googleMaps.getAddressComponent(results[0], [
            'country',
          ])?.long_name;

          const bounds = googleMaps.placeBounds(results[0]);

          setLocation((old) => ({
            ...old,
            street,
            city,
            country,
            bounds,
          }));
          setState('full');

          GEOCODED_COORDS[getLatLngKey(lat, lng)] = {
            street,
            city,
            country,
            bounds,
          };

          return { lat, lng, street, city, country, bounds };
        } catch (error) {
          console.error(error);
          showToast('error', 'Failed fetching locations.');
        } finally {
          OverlaySpinner.hide(LOADER_EL_NAME);
        }
      }
    },
    [placesScript],
  );

  const onLocationAllowedInternal: PositionCallback = useCallback(
    ({ coords: { latitude, longitude } }) => {
      setLocation({ lat: latitude, lng: longitude });
      setState('partial');
      OverlaySpinner.hide(LOADER_EL_NAME);
    },
    [],
  );

  const getCurrentLocation = useCallback(
    (shouldReturnFullLocation = false) => {
      return new Promise<Partial<PlaceFormat>>((resolve, reject) => {
        if (location && state === 'full') {
          onFinished(location as PlaceFormat);

          resolve(location);

          OverlaySpinner.hide(LOADER_EL_NAME);

          return;
        }

        OverlaySpinner.show(LOADER_EL_NAME);

        window.navigator.geolocation.getCurrentPosition(
          async (position) => {
            onLocationAllowedInternal(position);

            let geocodedLocation: Partial<PlaceFormat> = {};

            if (shouldReturnFullLocation) {
              geocodedLocation = await geocodeLocation(
                position.coords.latitude,
                position.coords.longitude,
              );
            }

            resolve({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
              ...geocodedLocation,
            });
          },
          () => {
            onLocationDisabledInternal();
            reject();
          },
          {
            maximumAge: MAX_AGE_MS,
          },
        );
      });
    },
    [
      geocodeLocation,
      location,
      onFinished,
      onLocationAllowedInternal,
      onLocationDisabledInternal,
      state,
    ],
  );

  useEffect(() => {
    if (!placesScript) {
      const script = document.getElementById(GOOGLE_MAPS_PLACES_SCRIPT_ID);
      if (script) {
        setPlacesScript(script);
      }
    }
  }, [placesScript]);

  useEffectOnce(() => {
    if (!callInitially) return;

    getCurrentLocation();
  });

  useEffect(() => {
    if (state !== 'partial' || !placesScript) return;

    geocodeLocation(location.lat, location.lng);
  }, [geocodeLocation, location, placesScript, state]);

  useEffect(() => {
    if (state === 'full') {
      onFinished?.(location as PlaceFormat);

      OverlaySpinner.hide(LOADER_EL_NAME);
    } else if (state === 'not-allowed') {
      onFinished?.(null);
      OverlaySpinner.hide(LOADER_EL_NAME);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, state]);

  return { location, state, getCurrentLocation } as const as CurrentUserRet;
};

export default useCurrentLocation;
