import React from 'react';
import dayjs from 'dayjs';
import dates from 'utils/dates';
import { ExperienceType } from 'models/User';

type ExperiencesProps = {
  experience: ExperienceType;
};

const ExperiencePreview: React.FC<ExperiencesProps> = (props) => {
  const {
    experience: {
      companyName,
      position,
      employmentType,
      startDate,
      endDate,
      location,
    },
  } = props;

  return (
    <div className="anys-profile-preview__body__preview">
      <span className="anys-profile-preview__body__preview__name">
        {companyName}
      </span>
      <span className="anys-profile-preview__body__preview__value">
        {position}
        <span className="spacing-dot" />
        {employmentType || '-'}
      </span>
      <span className="anys-profile-preview__body__preview__value">
        {dayjs(startDate).format('MMM YYYY')} -{' '}
        {endDate ? dayjs(endDate).format('MMM YYYY') : 'Present'}
        <span className="spacing-dot" />
        {dates.dateDifferces(startDate, endDate || dayjs())}
      </span>
      <span className="anys-profile-preview__body__preview__value">
        {location}
      </span>
    </div>
  );
};

export default ExperiencePreview;
