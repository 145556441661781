import httpClient from '../httpClient';

const positionBoostMetricsPath = (routePath: TemplateStringsArray) =>
  `position-boost-metrics/${routePath}`;

const trackView = (trackingData: string) => {
  return httpClient.post<string>(positionBoostMetricsPath`view`, {
    trackingData,
  });
};

const trackClick = (trackingData: string) => {
  return httpClient.post<string>(positionBoostMetricsPath`click`, {
    trackingData,
  });
};

const trackBuy = (trackingData: string) => {
  return httpClient.post<string>(
    positionBoostMetricsPath`buy` + { trackingData },
  );
};

export default {
  trackView,
  trackClick,
  trackBuy,
};
