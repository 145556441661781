import React, { useCallback, useId, useMemo } from 'react';
import {
  Editable,
  RenderElementProps,
  RenderLeafProps,
  Slate,
  withReact,
} from 'slate-react';
import { createEditor, Descendant } from 'slate';
import EditorElement from 'components/RichTextEditorField/components/EditorElement';
import EditorLeaf from 'components/RichTextEditorField/components/EditorLeaf';
import useDescription from './hooks/useDescription';
import { Button } from 'ncoded-component-library';
import Modal from 'components/Modal';
import useCallbackRef from 'hooks/useCallbackRef';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import { t } from 'i18next';
import formValidators from 'utils/formValidators';

type SlatePreviewProps = {
  className?: string;
  // JSON stringified slate editor value
  children?: string;
  maxLength?: number;
  disableShowMore?: boolean;
};

const SlatePreview: React.FC<SlatePreviewProps> = (props) => {
  const {
    className,
    children,
    maxLength = formValidators.JOB_DESC_DEFAULT_LENGTH_TO_SHOW,
    disableShowMore = false,
  } = props;

  const id = useId();

  const editor = useMemo(() => withReact(createEditor()), []);
  const editorInModal = useMemo(() => withReact(createEditor()), []);

  const [cutDescription, parsedDescription, showMore] = useDescription(
    children,
    maxLength,
  );

  const [fullDescriptionModal, setFullDescriptionModal] =
    useCallbackRef<ModalRef>(null);

  const renderElement = useCallback(
    (props: RenderElementProps) => <EditorElement {...props} />,
    [],
  );

  const renderLeaf = useCallback(
    (props: RenderLeafProps) => <EditorLeaf {...props} />,
    [],
  );

  if (parsedDescription) {
    return (
      <>
        <Slate editor={editor} value={cutDescription as Descendant[]}>
          <Editable
            className={className}
            readOnly
            renderElement={renderElement}
            renderLeaf={renderLeaf}
          />

          {showMore && !disableShowMore && (
            <Button variant="link" onClick={() => fullDescriptionModal.open()}>
              <span>{t('General.seeMore')}</span>
            </Button>
          )}
        </Slate>

        <Modal
          type="no-action"
          modalName={`description_previe_${id}`}
          ref={setFullDescriptionModal}
        >
          <Slate
            editor={editorInModal}
            value={parsedDescription as Descendant[]}
          >
            <Editable
              className={className}
              readOnly
              renderElement={renderElement}
              renderLeaf={renderLeaf}
            />
          </Slate>
        </Modal>
      </>
    );
  }

  if (typeof children === 'string') {
    return <p>{children}</p>;
  }

  throw Error('Unprocessable description by Slate editor');
};

export default SlatePreview;
