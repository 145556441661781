import { Skill } from 'models/Skills';
import httpClient from '../httpClient';

const skillPath = (routePath: TemplateStringsArray) => `skills/${routePath}`;

function createSkill(name: string, groupId: number) {
  return httpClient.post<{ skill: Skill }>(skillPath``, { name, groupId });
}

function getSkills(name?: string) {
  const url = name ? `${skillPath``}?name=${name}` : `${skillPath``}`;
  return httpClient.get<{ skills: Skill[] }>(url);
}

export default {
  createSkill,
  getSkills,
};
