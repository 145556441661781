import React from 'react';

type InsertLinkProps = React.SVGProps<SVGSVGElement>;

const InsertLink: React.FC<InsertLinkProps> = (props) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.25 12.75H5.25C4.25544 12.75 3.30161 12.3549 2.59835 11.6517C1.89509 10.9484 1.5 9.99456 1.5 9C1.5 8.00544 1.89509 7.05161 2.59835 6.34835C3.30161 5.64509 4.25544 5.25 5.25 5.25H8.25V6.75H5.25C4.65326 6.75 4.08097 6.98705 3.65901 7.40901C3.23705 7.83097 3 8.40326 3 9C3 9.59674 3.23705 10.169 3.65901 10.591C4.08097 11.0129 4.65326 11.25 5.25 11.25H8.25V12.75ZM12.75 5.25H9.75V6.75H12.75C13.3467 6.75 13.919 6.98705 14.341 7.40901C14.7629 7.83097 15 8.40326 15 9C15 9.59674 14.7629 10.169 14.341 10.591C13.919 11.0129 13.3467 11.25 12.75 11.25H9.75V12.75H12.75C13.7446 12.75 14.6984 12.3549 15.4017 11.6517C16.1049 10.9484 16.5 9.99456 16.5 9C16.5 8.00544 16.1049 7.05161 15.4017 6.34835C14.6984 5.64509 13.7446 5.25 12.75 5.25ZM12 8.25H6V9.75H12V8.25Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default InsertLink;
