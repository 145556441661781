import React from 'react';
import classNames from 'classnames';

import './Card.styles.scss';
import './Card.styles.responsive.scss';

type CardProps = {
  className?: string;
  cardTitle?: React.ReactNode;
  footer?: React.ReactNode;
  flat?: boolean;
};

const Card: React.FC<React.PropsWithChildren<CardProps>> = (props) => {
  const { children, flat, className, cardTitle, footer } = props;

  const classes = classNames('anys-card', { flat }, className);

  return (
    <section className={classes}>
      {cardTitle && <h2 className="anys-card__title">{cardTitle}</h2>}
      <div className="anys-card__card">
        <div className="anys-card__card__content">{children}</div>
        {footer && <div className="anys-card__card__footer">{footer}</div>}
      </div>
    </section>
  );
};

export default Card;
