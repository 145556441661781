import React from 'react';
import { t } from 'i18next';
import { useProfile } from '../../../Profile/Profile.page';
import EducationsPreview from './components/EducationsPreview';
import ProfileCardModal from '../ProfileCardModal';

const Educations = () => {
  const {
    profileUser: { educations },
  } = useProfile();

  return educations?.length ? (
    <ProfileCardModal
      cardTitle={t('Profile.education')}
      modalName="educations-modal"
      modalTitle="Education"
      itemList={educations.map((education, index) => (
        <EducationsPreview key={index} education={education} />
      ))}
    />
  ) : null;
};

export default Educations;
