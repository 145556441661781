import api from 'api';
import classNames from 'classnames';
import GradientText from 'components/GradientText';
import Modal, { ModalRef } from 'components/Modal';
import Notice from 'components/Notice';
import TextAreaField from 'components/TextAreaField';
import { FormApi } from 'final-form';
import { t } from 'i18next';
import { Button } from 'ncoded-component-library';
import React, { useRef } from 'react';
import { AnyObject, Form } from 'react-final-form';
import { Trans } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import formValidators from 'utils/formValidators';
import { JobFreeCancelation } from 'models/Job/FreeCancelation';
import { useContract } from '../../../Contract/Contract.page';

import './EndContractModal.styles.scss';

type EndContractModalProps = {
  className?: string;
  modal: React.Ref<ModalRef>;
  isCurrentUserClient: boolean;
  cancelationPolicy: JobFreeCancelation;
  onClose?: () => void;
};

const EndContractModal: React.FC<EndContractModalProps> = (props) => {
  const { className, modal, isCurrentUserClient, cancelationPolicy, onClose } =
    props;

  const classes = classNames('anys-end-contract-modal', className);

  const navigate = useNavigate();
  const { setContract } = useContract();

  const { id } = useParams<{ id: string }>();

  const onSubmit = async (formData: { explanation: string }) => {
    try {
      const { data } = await api.contract.endContract(
        +id,
        formData.explanation,
      );

      if (data) {
        setContract((old) => ({
          ...old,
          endProcess: data.contract.endProcess,
        }));
      }

      //TODO: check where to go after ending contract
      navigate('./');
    } catch (e) {
      console.error(e);
    }
  };

  const formApi = useRef<FormApi<{ explanation: string }>>();

  //because form submit button is outside of form
  let submit: (
    event?: Partial<
      Pick<
        React.SyntheticEvent<Element, Event>,
        'preventDefault' | 'stopPropagation'
      >
    >,
  ) => Promise<AnyObject>;

  return (
    <Modal
      ref={modal}
      type="no-action"
      modalName="endContract"
      title={t('Contract.endContract')}
      className={classes}
      footer={
        <div>
          <Button
            type="button"
            variant={'solid'}
            onClick={(event) => submit(event)}
          >
            {t('General.confirm')}
          </Button>
          <Button variant="link" onClick={onClose}>
            <GradientText>{t('General.cancel')}</GradientText>
          </Button>
        </div>
      }
    >
      <div>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, form }) => {
            submit = handleSubmit;
            formApi.current = form;
            return (
              <form onSubmit={handleSubmit}>
                <Notice type="warn">
                  <p>
                    <Trans>
                      {isCurrentUserClient
                        ? cancelationPolicy?.id
                          ? cancelationPolicy?.isStrict
                            ? t('Contract.clientEndContractStrictPolicy')
                            : t('Contract.clientEndContractCancelationPolicy', {
                                percent: `${cancelationPolicy.cancelationFee}%`,
                              })
                          : t('Contract.clientEndContractNotice')
                        : t('Contract.ProviderEndContractNotice')}
                    </Trans>
                  </p>
                </Notice>

                <TextAreaField
                  label={t('Arbitration.explanation')}
                  name="explanation"
                  placeholder={t(
                    'Arbitration.writeAnExplanationForEndingContract',
                  )}
                  className="anys-end-contract-modal__textarea"
                  maxLength={2000}
                  validate={formValidators.contractExplanation()}
                />
              </form>
            );
          }}
        />
      </div>
    </Modal>
  );
};

export default EndContractModal;
