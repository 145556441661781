import { useEffect, useRef } from 'react';
import debounce from 'lodash/debounce';
import { DebouncedFunc } from 'lodash';

const useViewportHeightSetter = (cb?: () => void) => {
  const calculateVhFn = useRef<DebouncedFunc<() => void>>();

  useEffect(() => {
    const calculateVh = debounce(() => {
      const height = window.visualViewport
        ? window.visualViewport.height
        : window.innerHeight;

      const vh = height * 0.01;
      document.documentElement.style.setProperty('--vh', vh + 'px');

      cb?.();
    }, 100);

    calculateVhFn.current = calculateVh;

    calculateVh();

    // Re-calculate on resize
    window.addEventListener('resize', calculateVh);

    // Re-calculate on device orientation change
    window.addEventListener('orientationchange', calculateVh);

    // Safari iOS doesn't trigger normal resize event
    // when keyboard is opened
    if (window.visualViewport) {
      window.visualViewport.addEventListener('resize', calculateVh);
    }

    return () => {
      window.removeEventListener('resize', calculateVh);
      window.removeEventListener('orientationchange', calculateVh);

      if (window.visualViewport) {
        window.visualViewport.removeEventListener('resize', calculateVh);
      }
    };
  }, [cb]);

  return calculateVhFn.current;
};

export default useViewportHeightSetter;
