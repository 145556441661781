import React from 'react';
import classNames from 'classnames';
import { ExtractPropTypes } from 'types';
import { RangeSlider as RangeSliderNcLib } from 'ncoded-component-library';
import { Field, FieldRenderProps, UseFieldConfig } from 'react-final-form';
import { RangeValueType } from 'ncoded-component-library/build/components/molecules/RangeSlider/RangeSlider.component';

import './RangeSliderField.styles.scss';

export type DefaultRangeSliderProps = ExtractPropTypes<typeof RangeSliderNcLib>;

type RangeSliderFieldProps = {
  name: string;
} & DefaultRangeSliderProps &
  UseFieldConfig<RangeValueType, RangeValueType>;

type RangeSliderFieldComponentProps = DefaultRangeSliderProps &
  FieldRenderProps<RangeValueType, HTMLElement>;

const RangeSliderFieldComponent: React.FC<RangeSliderFieldComponentProps> = (
  props,
) => {
  const { className, meta, input, ...restOfProps } = props;

  const classes = classNames('anys-range-slider-field', className);

  return (
    <div className={classes}>
      <RangeSliderNcLib
        {...input}
        {...restOfProps}
        onChange={(value) => {
          input?.onChange(value);
          restOfProps?.onChange(value);
        }}
      />
    </div>
  );
};

const RangeSliderField: React.FC<RangeSliderFieldProps> = (props) => {
  return <Field component={RangeSliderFieldComponent} {...props} />;
};

export default RangeSliderField;
