import React from 'react';
import classNames from 'classnames';
import {
  Button,
  Input as InputNcLib,
  RadioButton,
  Select,
  TextArea as TextAreaNcLib,
  Toggle,
} from 'ncoded-component-library';
import TextArea from 'components/TextArea';
import Input from 'components/Input';
import PasswordField from 'components/PasswordField';
import { Form } from 'react-final-form';
import utils from 'utils';
import RichTextEditorField from 'components/RichTextEditorField';
import Search from 'components/Search';

import './Catalog.styles.scss';

type CatalogProps = {
  className?: string;
};

const Catalog: React.FC<CatalogProps> = (props) => {
  const { className } = props;

  const classes = classNames('anys-catalog', className);

  return (
    <div className={classes}>
      <h1>Catalog</h1>

      <Form
        onSubmit={utils.noop}
        render={({ form }) => (
          <form className="anys-catalog__container">
            <div>
              <Search name="Search" onClear={form.reset} />
              <RichTextEditorField name="text-editor" />

              <p>Button</p>
              <Button>Click</Button>
              <p>Outline Button</p>
              <Button variant="outline">Click</Button>

              <p>Secondary Button</p>
              <Button styleType="secondary">Click</Button>
              <p>Secondary Outline Button</p>
              <Button styleType="secondary" variant="outline">
                Click
              </Button>

              <p>Button disabled</p>
              <Button disabled>Click</Button>

              <p>Link Button</p>
              <Button variant="link">Link</Button>
              <p>Round Button</p>
              <Button /*icon={Icon}*/ variant="icon" />
              {/* <p>Secondary Link Button </p>
          <Button iconPosition="right" styleType="secondary">
            Secondary Link
          </Button> */}
            </div>
            <div>
              <p>Regular input</p>
              <InputNcLib placeholder="Placeholder" />
              <p>Error Input</p>
              <InputNcLib placeholder="Placeholder" hasError />
              <p>Input with animation</p>
              <Input
                type="text"
                name="catalogInput1"
                label="Placeholder"
                labelDescription="description"
                placeholder="tekst"
              />
              <p>Error Input with animation</p>
              <Input
                type="text"
                name="catalogInput2"
                placeholder="tekst"
                label="Placeholder"
                hasError
              />
              <p>Test password field</p>
              <PasswordField name="catalogPassword" label="pass" />
            </div>
            <div>
              <p>Text Area</p>
              <TextAreaNcLib placeholder="Contents" />
              <p>Error Text Area</p>
              <TextAreaNcLib placeholder="Contents" hasError />
              <p>Text Area with animation</p>
              <TextArea placeholder="Contents" name="catalogTextarea1" />
              <p>Error Text Area</p>
              <TextArea
                placeholder="Contents"
                name="catalogTextarea2"
                label="label"
                hasError
              />
            </div>
            <div>
              <p>Select</p>
              <Select
                name="catalogSelect1"
                options={[
                  { label: 'Option 1', value: '1' },
                  { label: 'Option 2', value: '2' },
                  { label: 'Option 3', value: '3' },
                ]}
              />
              <p>Error Select</p>
              <Select
                hasError
                name="catalogSelect2"
                options={[
                  { label: 'Option 1', value: '1' },
                  { label: 'Option 2', value: '2' },
                  { label: 'Option 3', value: '3' },
                ]}
              />
            </div>
            <div>
              <p>Toggle</p>
              <Toggle name="catalogToggle1" />

              <p>Link (Link Button)</p>
              <a
                href="#link"
                className="ncoded-button ncoded-button--primary ncoded-button--link a-link"
              >
                Link
              </a>
              <p>Link (Secondary Button)</p>
              <a
                href="#link"
                className="ncoded-button ncoded-button--secondary ncoded-button--solid a-link"
              >
                Link
              </a>
              <p>Link (Secondary Outline Button)</p>
              <a
                href="#link"
                className="ncoded-button ncoded-button--secondary ncoded-button--outline a-link"
              >
                Link
              </a>
              <p>Radio Button</p>
              <RadioButton
                name="catalogRadioButton"
                label={<span>One</span>}
                checked
              />
              <RadioButton label={<span>Two</span>} name="catalogRadioButton" />

              <p>RangeSlider Field</p>
            </div>
          </form>
        )}
      />
    </div>
  );
};

export default Catalog;
