import bus, { EventName } from 'modules/bus';
import { useEffect } from 'react';

const useBusListener = <T>(
  eventName: EventName,
  callback: (data: { event: EventName; payload: T }) => void,
) => {
  useEffect(() => {
    const removeListener = bus.addEventListener<T>(eventName, callback);

    return () => {
      removeListener();
    };
  }, [callback, eventName]);
};

export default useBusListener;
