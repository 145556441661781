import React from 'react';
import classNames from 'classnames';
import { ReactComponent as AnyCoinIcon } from 'icons/anycoin.svg';
import { formatCurrencyMajorUnit } from 'utils/currency';
import { useTranslation } from 'react-i18next';
import { Currency } from 'constants/currency';

import './AnyCoinConversion.styles.scss';
import './AnyCoinConversion.styles.responsive.scss';

type AnyCoinConversionProps = {
  className?: string;
  anyCoinAmount: number;
  anyCoinCostWithoutSubunits: number;
  currency?: Currency;
  fractionDigits?: number;
  formatAnyCoins?: () => React.ReactNode;
  formatCost?: () => React.ReactNode;
};

const AnyCoinConversion: React.FC<AnyCoinConversionProps> = (props) => {
  const {
    className,
    anyCoinAmount,
    anyCoinCostWithoutSubunits,
    fractionDigits = 0,
    currency = 'USD',
    formatAnyCoins = () => anyCoinAmount,
    formatCost = () => `= ${formattedCost}`,
  } = props;

  const { t } = useTranslation();

  const classes = classNames('anys-any-coin-conversion', className);

  const formattedCost = formatCurrencyMajorUnit(
    t,
    currency,
    anyCoinCostWithoutSubunits,
    {
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    },
  );

  return (
    <span className={classes}>
      <span>
        {formatAnyCoins()}
        <AnyCoinIcon />
      </span>
      <span>{formatCost()}</span>
    </span>
  );
};

export default AnyCoinConversion;
