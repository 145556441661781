import { Button, Dropdown } from 'ncoded-component-library';
import React, { ReactNode, useMemo } from 'react';
import type { DropdownProps } from 'ncoded-component-library/build/components/molecules/Dropdown/Dropdown.component';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import './DropdownOptions.styles.scss';
import './DropdownOptions.styles.responsive.scss';

export const renderDropdownOptions = (
  options: DropdownOption[],
  className?: string,
  noOptionsContent: React.ReactNode = null,
) =>
  options?.length ? (
    <ul className={className}>
      {options.map((opt) => (
        <li key={opt.key}>
          {'href' in opt ? (
            <Link to={opt.href} className={opt.className}>
              {opt.title}
            </Link>
          ) : (
            <button
              type="button"
              onClick={opt.onClick}
              disabled={opt.disabled}
              className={opt.className}
            >
              {opt.title}
            </button>
          )}
        </li>
      ))}
    </ul>
  ) : (
    <div className="anys-dropdown-options__no-options">{noOptionsContent}</div>
  );

type ButtonProps = React.ComponentProps<typeof Button>;

export type DropdownOption = {
  title: ReactNode;
  key: string;
  className?: string;
} & (
  | {
      disabled?: boolean;
      onClick: () => void;
    }
  | { href: string }
);

type TriggerlessDropdown = Omit<DropdownProps, 'trigger'> & {
  triggerOptions: ButtonProps;
  triggerTitle?: React.ReactNode;
};

type TriggeredDropdown = DropdownProps;

type TriggerDropdownOptions = TriggerlessDropdown | TriggeredDropdown;

export type DropdownOptionsProps = TriggerDropdownOptions & {
  options: DropdownOption[];
  listClassName?: string;
  noOptionsContent?: React.ReactNode;
} & { dropdownRef?: any };

const DropdownOptions: React.FC<DropdownOptionsProps> = (props) => {
  const {
    className,
    options,
    dropdownRef,
    listClassName,
    noOptionsContent,
    children,
    ...otherProps
  } = props;

  const classes = classNames('anys-dropdown-options', className);

  const trigger = useMemo(() => {
    if ('trigger' in props) return props.trigger;

    const { triggerOptions, triggerTitle } = props;

    return <Button {...triggerOptions}>{triggerTitle}</Button>;
  }, [props]);

  let rest: Omit<DropdownProps, 'trigger' | 'ref'>;
  if ('trigger' in otherProps) {
    const { trigger, ...r } = otherProps;
    rest = r;
  } else {
    const { triggerOptions, triggerTitle, ...r } = otherProps;
    rest = r;
  }

  const renderedOptions = useMemo(
    () => renderDropdownOptions(options, listClassName, noOptionsContent),
    [listClassName, noOptionsContent, options],
  );

  return (
    <Dropdown
      closeWithJS
      {...rest}
      trigger={trigger}
      className={classes}
      ref={dropdownRef}
    >
      {children}
      {renderedOptions}
    </Dropdown>
  );
};

export default DropdownOptions;
