import { User } from 'models/User';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import MiniAvatar, { MiniAvatarProps } from './MiniAvatar.component';
import { formatUserDisplayName } from 'utils/user';

type Props = Pick<MiniAvatarProps, 'cta' | 'size' | 'info' | 'className'> & {
  user: User;
};

export const MiniUserAvatar: React.FC<Props> = ({ user, ...otherProps }) => {
  const { t } = useTranslation();

  const skills = useMemo(
    () => user?.skills?.map(({ skill }) => skill),
    [user?.skills],
  );

  return (
    <MiniAvatar
      {...otherProps}
      skills={skills}
      id={user?.id}
      profileImage={user?.profileImage}
      profession={user?.profession}
      rating={user?.overallSkillScore?.averageRating}
      isSkillReview
      userName={formatUserDisplayName(user, t('General.noName'))}
      isVerified={user?.hasVerifiedIdentity || user?.hasVerifiedBusiness}
      location={user.location}
      displayLocation
      votes={user?.overallSkillScore?.numberOfReviews}
    />
  );
};

export default MiniAvatar;
