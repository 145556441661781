import publicHttpClient from 'api/publicHttpClient';
import {
  AuthAccountResBody,
  AuthResBody,
  ConfirmSocialSignupReqBody,
  SignupReqBody,
} from 'models/Auth';
import httpClient from '../httpClient';

const authPath = (routePath: TemplateStringsArray) => `auth/${routePath}`;

function signup(userData: Omit<SignupReqBody, 'termsAndConditions'>) {
  return publicHttpClient.post<AuthAccountResBody>(`account`, userData);
}

function login(userData: { email: string; password: string }) {
  return publicHttpClient.post<AuthResBody>(authPath`login`, userData);
}

const refreshToken = (refreshToken: string) =>
  publicHttpClient.post(authPath`refresh-token`, { refreshToken });

function verifyEmail(emailData: { verificationKey: string; email: string }) {
  return httpClient.post<AuthAccountResBody>(`account/verify/email`, emailData);
}

function resendVerifyCode(email: string) {
  return publicHttpClient.post(`account/verify/email/resend`, { email });
}

function forgotPassword(email: string) {
  return publicHttpClient.post(`account/reset-password/request`, {
    email,
  });
}

function resetPassword(password: string, token: string) {
  return httpClient.post(`account/reset-password/${token}`, {
    password,
  });
}

function checkIfEmailExists(email: string) {
  return publicHttpClient.post<{ existEmail: boolean }>(authPath`check-email`, {
    email,
  });
}

function completeSignupWithSocial(
  values: Omit<ConfirmSocialSignupReqBody, 'termsAndConditions'>,
) {
  return httpClient.post<
    { id: number } & Pick<ConfirmSocialSignupReqBody, 'role'>
  >('account/complete', values);
}

export default {
  signup,
  login,
  refreshToken,
  verifyEmail,
  resendVerifyCode,
  forgotPassword,
  resetPassword,
  checkIfEmailExists,
  completeSignupWithSocial,
};
