import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import MainTemplate from 'components/templates/MainTemplate';
import Filter from 'components/Filter';
import useHeaderStyles from 'hooks/useHeaderStyles';
import ChevronIcon from 'icons/ChervonIcon.icon';
import FilterIcon from 'icons/Filter.icon';
import SearchSelect from './components/SearchSelect';
import useUserSearch from './hooks/useUserSearch';
import { MiniUserAvatar } from 'components/MiniAvatar';
import SearchLoading from './Search.loading';
import { Button } from 'ncoded-component-library';
import FilterSelect from 'components/FilterSelect';
import useEvent from 'hooks/useEvent';
import useInfiniteContainer from 'hooks/useInfiniteContainer';
import { useTranslation } from 'react-i18next';
import useQueryParams from 'hooks/useQueryParams';
import { SearchBy } from './components/SearchSelect/hooks/useSearchOptions';
import { FilterContext } from './providers/Filters/Filters.provider';
import FilterTag from './components/FilterTag';
import useFilterTagDisplays from './hooks/useFilterTagDisplays';
import Pagination from 'components/Pagination';
import FiltersModal, { FormValue } from './components/FiltersModal';
import type { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import useSelectOptions from 'hooks/useSelectOptions';
import SkillsModal from './components/SkillsModal';
import utils from 'utils';
import SuggestedItems from './components/SuggestedItems';
import { User } from 'models/User';
import { FormApi } from 'final-form';
import LocationFilterSelect from './components/LocationFilterSelect';
import { useNavigate } from 'react-router-dom';

import './Search.styles.scss';
import './Search.styles.responsive.scss';
import useGetSkillGroupsByReviews from '../../../../../hooks/useGetSkillGroupsByReviews';

export type SearchProps = {
  className?: string;
};

const Search: React.FC<SearchProps> = (props) => {
  const { className } = props;
  const { t } = useTranslation();

  const isMounted = useRef(true);
  const classes = classNames('anys-search', className);
  const filtersModalRef = useRef<ModalRef>(null);
  const skillsModalRef = useRef<ModalRef>(null);
  const filtersModalFormApi = useRef<FormApi<FormValue>>();

  const [selectedSkillIds, setSelectedSkillIds] = useState<number[]>();

  const { filters, registerFilter, clearFilters } = useContext(FilterContext);
  const { skillGroups, allSkills } = useGetSkillGroupsByReviews();

  const { ratingOptions, languageOptions } = useSelectOptions();

  const filterTagsDisplays = useFilterTagDisplays({ skills: allSkills });

  const {
    setQueryParam,
    removeQueryParam,
    params: { 'search-by': searchBy, 'skills[]': skillsParam = '' },
  } = useQueryParams<{
    'search-by': SearchBy;
    page: string;
    'skills[]': string;
    $suggestionPage: string;
  }>();

  useHeaderStyles({
    isHeaderShown: false,
  });

  const {
    items: users,
    totalPages,
    loading,
    onContainerScrolled,
    isDefaultSearch,
    currentPage,
    searchString: term,
    totalItems,
    suggestedUsersPagination,
    isPhablet,
    showPaginator,
  } = useUserSearch();

  const isOnLastPage = currentPage >= totalPages;

  const { onScroll, loaderEl } = useInfiniteContainer({
    container: window,
    onScroll: onContainerScrolled,
    loader: <SearchLoading length={3} />,
    loading,
  });

  useEvent(
    window,
    'scroll',
    onScroll,
    undefined,
    isPhablet || (!isPhablet && isOnLastPage),
  );

  const [loadingSkills, setLoadingSkills] = useState(false);

  const navigate = useNavigate();

  const getUserItem = useCallback(
    (user: User) => (
      <li key={user.id} className="anys-search__item">
        <MiniUserAvatar
          user={user}
          size="medium"
          cta={
            user.hasOffers && (
              <Button
                className="see-my-offers"
                variant="outline"
                onClick={() => navigate(`/profile/offers/${user?.id}`)}
              >
                {isPhablet
                  ? t('SearchPage.seeOffers')
                  : t('SearchPage.seeMyOffers')}
              </Button>
            )
          }
        />
      </li>
    ),
    [isPhablet, navigate, t],
  );

  const onSkillsApply = useCallback(
    async (values: number[]) => {
      // If we opened skills modal from the filters modal,
      // update the form state, if not, add params.
      if (filtersModalRef.current?.openState) {
        filtersModalFormApi.current?.change('skills', values);
      } else {
        setLoadingSkills(true);
        if (values.length) setQueryParam('skills[]', values.join(','));
        else removeQueryParam('skills[]');

        await utils.wait(250);

        setLoadingSkills(false);
      }

      skillsModalRef.current?.close();
    },
    [removeQueryParam, setQueryParam],
  );

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!skillsParam) return;

    setSelectedSkillIds(skillsParam.split(',').filter(Boolean).map(Number));
  }, [skillsParam]);

  return (
    <MainTemplate className={classes} as="main">
      <div className="anys-search__bar">
        <SearchSelect
          onAddSkill={() => skillsModalRef.current?.open()}
          className="anys-search__input"
          searchBy={searchBy}
        />
        <div className="anys-search__other-filters">
          <button
            className="all-filters"
            onClick={() => filtersModalRef.current?.open()}
          >
            <FilterIcon
              showGradient
              showCircle={!!Object.values(filters).length}
            />
          </button>
          {searchBy !== 'skill' && (
            <Filter
              searchable
              name="skills[]"
              dbName="skills.skill.id"
              label={t('Filters.skills_other')}
              placeholder={t('SearchPage.searchSkill')}
              component={FilterSelect}
              multiple
              selectedOptionsContent={(value) => value.length}
              icon={<ChevronIcon gradient />}
              options={allSkills?.map((o) => ({
                value: o.id,
                label: o.name,
              }))}
              onDestroy={() => {
                if (isMounted.current) removeQueryParam('skills[]', true);
              }}
            />
          )}

          <LocationFilterSelect
            name="location"
            paramToValueParser={JSON.parse}
            dbName="location"
            icon={<ChevronIcon gradient />}
            valueParse={(val) => JSON.parse(val).placeId}
            label={t('Filters.locations_one')}
            placeholder={t('SearchPage.searchLocation')}
            type="bounds-to-latlng"
          />

          <Filter
            searchable
            name="languages[]"
            dbName="languages.language"
            label={t('Filters.languages_one')}
            placeholder={t('Language.searchLanguage')}
            component={FilterSelect}
            multiple
            selectedOptionsContent={(value) => value.length}
            icon={<ChevronIcon gradient />}
            options={languageOptions}
          />

          <Filter
            dbName="overallSkillScore.averageRating"
            name="rating"
            type="morethan"
            label={t('Filters.rating')}
            multiple={false}
            component={FilterSelect}
            options={ratingOptions}
            valueMap={(o) => o.value}
            icon={<ChevronIcon gradient />}
          />

          <Button
            className="anys-search__clear-all"
            variant="link"
            onClick={clearFilters}
          >
            <span>{t('Filters.clear')}</span>
          </Button>
        </div>
        <div className="anys-search__filter-tags">
          {Object.keys(filters).map((fName) =>
            fName === 'term' ? null : (
              <FilterTag
                key={fName}
                name={fName}
                hideFilterLabel={fName === 'location'}
                display={filterTagsDisplays[fName]}
              />
            ),
          )}
        </div>
      </div>
      {loading &&
      (!isPhablet || currentPage === 1) &&
      currentPage <= totalPages &&
      !suggestedUsersPagination ? (
        <SearchLoading />
      ) : (
        <>
          {term && totalItems > 0 && (
            <h2>{t('Filters.showingResults', { term })}</h2>
          )}
          {isDefaultSearch && <h2>{t('SearchPage.topUsers')}</h2>}
          <ul className="anys-search__results">{users.map(getUserItem)}</ul>
        </>
      )}
      {showPaginator && (
        <Filter
          name="page"
          defaultValue="1"
          component={Pagination}
          totalPages={totalPages}
        />
      )}
      {isOnLastPage && suggestedUsersPagination ? (
        <SuggestedItems
          title={
            <>
              {users?.length > 0
                ? t('SearchPage.noMoreUsers')
                : t('SearchPage.noUsers')}
              <br />
              {t('SearchPage.suggestedUsers')}
            </>
          }
          paginatedRes={suggestedUsersPagination}
          loaderEl={loaderEl}
          className={classNames('anys-search__suggested-results', {
            'anys-search__suggested-results--no-margin': !users?.length,
          })}
        >
          {getUserItem}
        </SuggestedItems>
      ) : (
        loaderEl
      )}
      <SkillsModal
        skillGroups={skillGroups}
        initialValues={selectedSkillIds}
        loading={loadingSkills}
        ref={skillsModalRef}
        onSubmit={onSkillsApply}
        onOpen={() => {
          setSelectedSkillIds(
            filtersModalFormApi.current?.getState()?.values?.skills,
          );

          registerFilter('skills[]', 'skills.skill.id', 'array');
        }}
      />
      <FiltersModal
        onBrowse={() => skillsModalRef.current?.open()}
        onApply={() => filtersModalRef.current?.close()}
        formApi={filtersModalFormApi}
        ref={filtersModalRef}
        skills={allSkills}
        closeModal={() => filtersModalRef.current.close()}
      />
    </MainTemplate>
  );
};

export default Search;
