import React, { useCallback, useMemo, useState } from 'react';
import Share from 'icons/Share.icon';
import { Button, Dropdown } from 'ncoded-component-library';
import useEffectOnce from '../../hooks/useEffectOnce';
import { ReactComponent as CopyIcon } from 'icons/copy.svg';
import {
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from 'react-share';
import CopyToClipboard from 'components/CopyToClipboard';

import './ShareButton.styles.scss';

type ShareButtonProps = {
  data: ShareData;
  disabled?: boolean;
};

const ShareButton: React.FC<ShareButtonProps> = (props) => {
  const {
    data,
    data: { url: shareUrl, title },
    disabled,
  } = props;
  const [isHTTPS, setIsHTTPs] = useState(false);
  const [canShare, setCanShare] = useState(false);

  const share = useCallback(() => {
    if (!isHTTPS) {
      alert('Sharing is not available when testing on local machine');
      return;
    }
    navigator.share(data);
  }, [isHTTPS, data]);

  useEffectOnce(() => {
    setIsHTTPs(window.location.protocol === 'https:');
    setCanShare('share' in navigator);
  });

  const shareBtn = useMemo(
    () => (
      <Button
        onClick={share}
        type="button"
        styleType="secondary"
        variant="icon"
        icon={<Share />}
        disabled={disabled}
      />
    ),
    [disabled, share],
  );

  if (canShare) return shareBtn;

  return (
    <Dropdown
      className="anys-share-dropdown"
      portalClassName="anys-share-dropdown__portal"
      trigger={shareBtn}
      renderAsPortal
    >
      <CopyToClipboard
        className="anys-share-dropdown__copy"
        contentToCopy={shareUrl}
      >
        <CopyIcon />
      </CopyToClipboard>
      <FacebookShareButton url={shareUrl} quote={title}>
        <FacebookIcon size={32} round />
      </FacebookShareButton>
      <TwitterShareButton url={shareUrl} title={title}>
        <TwitterIcon size={32} round />
      </TwitterShareButton>
      <LinkedinShareButton url={shareUrl}>
        <LinkedinIcon size={32} round />
      </LinkedinShareButton>
    </Dropdown>
  );
};

export default ShareButton;
