import React from 'react';
import { JobPost } from 'models/Job';
import { Trans } from 'react-i18next';
import { TFunction } from 'i18next';
import dates from './dates';

/**
 * @returns An array of `<span>`-s that have formatted text inside them or `null` if empty
 */
const getFormattedDeadlines = (
  t: TFunction,
  jobEndDate: Date,
  deadlines:
    | Pick<JobPost, 'deadlines'>['deadlines']
    | Pick<JobPost<'form'>, 'deadlines'>['deadlines'],
): JSX.Element[] | null => {
  if (!deadlines || deadlines?.length === 0) return null;

  const hasOnlyOneDeadline = deadlines.length === 1;

  const mappedDeadlines = [
    <span key="before-first-deadline">
      <Trans
        i18nKey="DeadlinePolicy.beforeFirstDeadline"
        components={{
          underline: <u />,
        }}
        values={{
          jobEnd: jobEndDate
            ? dates.formatDeadlineDate(jobEndDate)
            : `"${t('General.endTime').toLowerCase()}"`,
        }}
      />
    </span>,
  ];

  const secondToLastDeadline = deadlines[deadlines.length - 2];

  const secondToLastDeadlineDate = secondToLastDeadline?.toDate
    ? dates.formatDeadlineDate(new Date(secondToLastDeadline.toDate))
    : ` ${t('General.deadline').toLowerCase()} `;

  deadlines.forEach((d, index) => {
    const { fromDate, toDate, percentOfJobValue } = d;

    const isLastDeadline = index === deadlines.length - 1;

    mappedDeadlines.push(
      <span key={index}>
        <Trans
          i18nKey={
            isLastDeadline
              ? 'DeadlinePolicy.afterLastDeadlineFull'
              : 'DeadlinePolicy.betweenDeadlinesFull'
          }
          components={{
            underline: <u />,
            semiBold: (
              <span style={!hasOnlyOneDeadline ? { fontWeight: 600 } : {}} />
            ),
          }}
          values={
            isLastDeadline
              ? {
                  deadline: hasOnlyOneDeadline
                    ? `"${t('General.endTime')}"`.toLowerCase()
                    : secondToLastDeadlineDate,
                  feePercent: percentOfJobValue,
                }
              : {
                  firstDeadline: fromDate
                    ? dates.formatDeadlineDate(new Date(fromDate))
                    : `"${t('General.endTime')}"`.toLowerCase(),
                  secondDeadline: toDate
                    ? dates.formatDeadlineDate(new Date(toDate))
                    : null,
                  feePercent: percentOfJobValue,
                }
          }
        />
      </span>,
    );
  });

  return mappedDeadlines;
};

export { getFormattedDeadlines };
