import useQueryParams from 'hooks/useQueryParams';
import { PlaceSearchParam } from 'models/User';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import utils from 'utils';

const spreadDbNamesToQuery = (
  dbNames: string | string[],
  query: Where[string],
) => {
  if (!Array.isArray(dbNames)) return { [dbNames]: query };

  return dbNames.reduce((queries, dbName) => {
    queries[dbName] = query;

    return queries;
  }, {} as Where);
};

export type Where = Record<string, Record<string, any>>;

export type AnyFilter = {
  dbName: string | string[];
  queryType?: 'and' | 'or';
  type:
    | 'array'
    | 'single'
    | 'search'
    | 'range'
    | 'morethan'
    | 'lessthan'
    | 'bounds-to-latlng'
    | 'location'
    | 'other'
    | 'existential';
};

export type FilterValue = {
  value: string;
} & (
  | AnyFilter
  | {
      mapperFn?: (value: string) => Record<string, any> | Record<string, any>[];
    }
);

export type FilterType = AnyFilter['type'];

type Filters = Record<string, FilterValue>;

type FilterContextValue = {
  where: Where[];
  filters: Filters;
  registerFilter: (name: string, dbName: string, type?: FilterType) => void;
  checkRegisterFilters: (value: Filters) => void;
  unregisterFilter: (name: string) => void;
  clearFilters: () => void;
  getParamsOfInterest: (params: Record<string, string>) => Filters;
  getWhere: (filters: Filters) => Where[];
};

export const FilterContext = React.createContext<FilterContextValue>({
  where: [],
  filters: {},
  registerFilter: utils.noop,
  checkRegisterFilters: utils.noop,
  unregisterFilter: utils.noop,
  clearFilters: utils.noop,
  getParamsOfInterest: utils.noop,
  getWhere: utils.noop,
});

type FiltersProviderProps = {
  children: React.ReactNode;
};

const FiltersProvider: React.FC<FiltersProviderProps> = (props) => {
  const { children } = props;

  const { params, removeSeveralQueryParams } =
    useQueryParams<Record<string, string>>();

  const lastParamsOfInterest = useRef<Filters>();
  const [filters, setFilters] = useState<Filters>({});

  const registerFilter = useCallback(
    (
      name: string,
      dbName: string,
      type: FilterType = 'single',
      value: string = null,
    ) => {
      setFilters((old) =>
        old[name]
          ? old
          : {
              ...old,
              [name]: {
                type,
                dbName,
                value,
              },
            },
      );
    },
    [],
  );

  const unregisterFilter = useCallback((name: string) => {
    setFilters(({ [name]: _, ...old }) => old);
  }, []);

  const checkRegisterFilters = useCallback(
    (newFilters: Filters) => {
      let shouldUpdate = false;
      const updateObj = Object.entries(newFilters).reduce(
        (acc, [key, value]) => {
          if (acc[key]) return acc;

          shouldUpdate = true;
          return { ...acc, [key]: value };
        },
        filters,
      );

      if (shouldUpdate) {
        setFilters((old) => ({ ...old, ...updateObj }));
      }
    },
    [filters],
  );

  const getParamsOfInterest = useCallback(
    (paramsToCheck: Record<string, string>) => {
      return Object.entries(paramsToCheck).reduce(
        (acc, [key, value]) =>
          filters[key]
            ? { ...acc, [key]: { ...filters[key], value: value as any } }
            : acc,
        {} as Filters,
      );
    },
    [filters],
  );

  const paramsOfInterest = useMemo(() => {
    const poi = getParamsOfInterest(params);

    if (JSON.stringify(poi) !== JSON.stringify(lastParamsOfInterest.current)) {
      lastParamsOfInterest.current = poi;
    }
    return lastParamsOfInterest.current;
  }, [getParamsOfInterest, params]);

  const getWhere = useCallback((filters: Filters) => {
    let orConditions: Where[] = [];

    const andConditions = Object.values(filters).reduce((acc, filter) => {
      const { value } = filter;

      if ('mapperFn' in filter) {
        const mappedValue = filter.mapperFn(value);

        if (Array.isArray(mappedValue)) {
          orConditions = [...orConditions, ...mappedValue];
          return acc;
        }

        return { ...acc, ...mappedValue };
      }

      if (!('dbName' in filter)) return acc;
      if (value === 'see-all') return acc;

      const { type, dbName, queryType = 'and' } = filter;

      const isOrQuery = queryType === 'or';

      switch (type) {
        case 'single':
          return {
            ...acc,
            ...spreadDbNamesToQuery(dbName, { EQUAL: value }),
          };

        case 'bounds-to-latlng': {
          const { bounds } = JSON.parse(value) as PlaceSearchParam;

          return {
            ...acc,
            ...spreadDbNamesToQuery(`${dbName}.lat`, {
              BETWEEN: [bounds.sw.lat, bounds.ne.lat],
            }),
            ...spreadDbNamesToQuery(`${dbName}.lng`, {
              BETWEEN: [bounds.sw.lng, bounds.ne.lng],
            }),
          };
        }

        case 'array': {
          const query = { IN: Array.isArray(value) ? value : value.split(',') };

          const mappedDbNames = spreadDbNamesToQuery(dbName, query);

          if (isOrQuery && Array.isArray(dbName)) {
            orConditions = [
              ...orConditions,
              ...dbName.map<Where>((dbName) => ({ [dbName]: query })),
            ];

            return acc;
          }

          return {
            ...acc,
            ...mappedDbNames,
          };
        }
        case 'existential': {
          return acc;
        }
        case 'location': {
          const pairs = value.split(',').map((v) => v.split(';'));

          return {
            ...acc,
            ...spreadDbNamesToQuery(`${dbName}.city`, {
              IN: Array.from(new Set(pairs.map((p) => p[0]))),
            }),
            ...spreadDbNamesToQuery(`${dbName}.country`, {
              IN: Array.from(new Set(pairs.map((p) => p[1]))),
            }),
          };
        }
        case 'morethan':
          return {
            ...acc,
            ...spreadDbNamesToQuery(dbName, { MORETHANOREQUAL: value }),
          };

        case 'lessthan':
          return {
            ...acc,
            ...spreadDbNamesToQuery(dbName, { LESSTHANOREQUAL: value }),
          };

        default:
          return acc;
      }
    }, {} as Where);

    const hasNoAndConditions = JSON.stringify(andConditions) === '{}';
    const hasNoOrConditions = orConditions.length === 0;

    if (hasNoAndConditions && hasNoOrConditions) return null;

    if (hasNoAndConditions) return orConditions;
    if (hasNoOrConditions) return [andConditions];

    return [andConditions, ...orConditions];
  }, []);

  const where = useMemo(
    () => getWhere(paramsOfInterest),
    [getWhere, paramsOfInterest],
  );

  const clearFilters = useCallback(() => {
    removeSeveralQueryParams(Object.keys(filters));
  }, [removeSeveralQueryParams, filters]);

  return (
    <FilterContext.Provider
      value={{
        registerFilter,
        unregisterFilter,
        checkRegisterFilters,
        getParamsOfInterest,
        getWhere,
        filters: paramsOfInterest,
        where,
        clearFilters,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export default FiltersProvider;
