import api from 'api';
import OverlaySpinner from 'components/OverlaySpinner';
import { JOB_TYPE } from 'constants/job';
import useEffectSkipFirst from 'hooks/useEffectSkipFirst';
import useInfinitePagination from 'hooks/useInfinitePagination';
import useMatchMedia from 'hooks/useMatchMedia';
import useQueryParams from 'hooks/useQueryParams';
import unionWith from 'lodash/unionWith';
import { JobPost } from 'models/Job';
import { PaginatedResponse, PaginationParams } from 'models/Pagination';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import {
  FilterContext,
  Where,
} from 'router/subrouters/Search/pages/Search/providers/Filters/Filters.provider';
import utils from 'utils';

const ITEM_TAKE = 15;

export type NeedProvideSearchParams = Partial<{
  page?: string;
  $currentLocation: string;
  typeOfService: string;
  favouriteFilter: string;
  $suggestionPage?: string;
}>;

const useNeedProvideSearch = () => {
  const location = useLocation();

  const { currentUser } = useContext(CurrentUserContext);

  const { params, setQueryParam, removeQueryParam, removeSeveralQueryParams } =
    useQueryParams<NeedProvideSearchParams>();

  const {
    where: filtersWhere,
    filters,
    clearFilters: clearContextFilters,
  } = useContext(FilterContext);

  const [favouritedPosts, setFavouritedPosts] = useState<
    Record<string, boolean>
  >({});

  const [suggestedPostsPagination, setSuggestedPostsPagination] =
    useState<PaginatedResponse<JobPost>>(null);

  const hasReachedLastPage = useRef(false);

  const isPhablet = useMatchMedia('isPhablet');

  const { page, $currentLocation, $suggestionPage, ...restOfParams } = params;

  const clearableParams = useMemo(() => restOfParams, [restOfParams]);

  const isNeedPage = location.pathname?.startsWith('/need');

  const activeFiltersCount = useMemo(() => {
    const paramsActive = clearableParams
      ? Object.values(clearableParams).length
      : 0;

    const filtersActive = filters ? Object.values(filters).length : 0;

    return paramsActive + filtersActive;
  }, [clearableParams, filters]);

  const where = useMemo(() => {
    const defaultWhere: Where = {
      type: { EQUAL: isNeedPage ? JOB_TYPE.NEED : JOB_TYPE.PROVIDE },
    };
    if (!filtersWhere?.length) return [defaultWhere];

    const filtersWithType = [...filtersWhere];

    filtersWithType[0] = { ...filtersWithType[0], ...defaultWhere };

    return filtersWithType;
  }, [filtersWhere, isNeedPage]);

  const getFavouritedPosts = useCallback(
    async (jobPostIds: number[]) => {
      if (!currentUser?.id || !jobPostIds?.length) return;

      try {
        const { data } = await api.jobPost.getFavouritedJobPosts(
          jobPostIds.join(','),
        );

        setFavouritedPosts((old) => ({ ...old, ...data }));
      } catch (error) {
        console.error(error);
      }
    },
    [currentUser?.id],
  );

  const searchJobPosts = useCallback(
    async (currentPage: number, take: number) => {
      OverlaySpinner.show('.anys-need-provide-page');

      const queryParams: PaginationParams = {
        $where: where ? JSON.stringify(where) : null,
        $take: take,
        $skip: (currentPage - 1) * take,
        $order: '-createdAt',
        $relations:
          'mainSkill, skills, boosts.files, user.profileImage, user.overallClientScore, user.overallSkillScore, timeAndPricing, typeOfService, typeOfService.locations',
        $currentLocation,
        $suggestionPage: $suggestionPage || '1',
        $options: {
          loadEagerRelations: false,
        },
      };

      try {
        const {
          data: {
            items,
            currentPage: latestPage,
            totalItems,
            totalPages,
            suggestion,
          },
        } = await api.jobPost.getJobPosts(queryParams);

        getFavouritedPosts(items.map((jobPost) => jobPost.id));

        // If we are on the last page, save suggested pagination
        if (latestPage >= totalPages) {
          setSuggestedPostsPagination((oldPagination) => {
            const { items: suggestedItems, ...restOfSuggestions } = suggestion;

            return {
              ...restOfSuggestions,
              items: unionWith(
                oldPagination?.items || [],
                suggestedItems,
                (item1, item2) => item1?.id === item2?.id,
              ),
            };
          });

          const newItems = hasReachedLastPage.current ? [] : items;

          hasReachedLastPage.current = true;

          return {
            items: newItems,
            totalItems,
            totalPages,
          } as const;
        } else setSuggestedPostsPagination(null);

        return { items, totalItems, totalPages } as const;
      } catch (error) {
        console.error(error);
      } finally {
        OverlaySpinner.hide('.anys-need-provide-page');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [$currentLocation, getFavouritedPosts, where],
  );

  const whereAsString = useMemo(
    () => (where ? JSON.stringify(where) : null),
    [where],
  );

  const pagination = useInfinitePagination<JobPost>({
    take: ITEM_TAKE,
    makeRequest: searchJobPosts,
    resetDeps: [whereAsString, $currentLocation],
    debounceTime: 600,
    page: +page,
    uniqByProp: 'id',
  });

  const clearLocalFilters = useCallback(() => {
    const keys = Object.keys(
      clearableParams,
    ) as (keyof NeedProvideSearchParams)[];

    removeSeveralQueryParams(keys);
  }, [clearableParams, removeSeveralQueryParams]);

  const clearFilters = useCallback(() => {
    clearLocalFilters();
    clearContextFilters();
  }, [clearContextFilters, clearLocalFilters]);

  const {
    loading,
    currentPage,
    totalPages,
    totalItems,
    goToPage,
    onContainerScrolled,
  } = pagination;

  const showPaginator = !!totalItems && !isPhablet;

  const onContainerScrollFinal = useMemo(() => {
    if (loading) return utils.noop;

    if (currentPage >= totalPages) {
      return () => {
        const { currentPage: sCurrentPage, totalPages: sTotalPages } =
          suggestedPostsPagination || {};

        const newSuggestedPage =
          sCurrentPage < sTotalPages ? sCurrentPage + 1 : sCurrentPage;

        setQueryParam('$suggestionPage', newSuggestedPage || '1');
      };
    }

    return onContainerScrolled;
  }, [
    currentPage,
    loading,
    onContainerScrolled,
    setQueryParam,
    suggestedPostsPagination,
    totalPages,
  ]);

  // Reset values
  useEffect(() => {
    if (loading) return;

    hasReachedLastPage.current = false;

    setSuggestedPostsPagination(null);
    setQueryParam('$suggestionPage', '1');

    window.scroll({
      top: 0,
      left: 0,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [whereAsString, page]);

  useEffectSkipFirst(() => {
    if (loading) return;
    if (!showPaginator) {
      goToPage(1);
      removeQueryParam('page', true);
    }
  }, [goToPage, removeQueryParam, showPaginator]);

  // Keep the page url param
  // in sync with currentPage state
  useEffect(() => {
    if (loading) return;
    if (!showPaginator) return;

    setQueryParam('page', currentPage);
  }, [currentPage, loading, setQueryParam, showPaginator]);

  return {
    ...pagination,
    filters,
    activeFiltersCount,
    isPhablet,
    showPaginator,
    clearLocalFilters,
    clearFilters,
    setQueryParam,
    removeQueryParam,
    setFavouritedPosts,
    onContainerScrolled: onContainerScrollFinal,
    suggestedPostsPagination,
    favouritedPosts,
  };
};

export default useNeedProvideSearch;
