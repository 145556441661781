import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import classNames from 'classnames';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import { formatUserDisplayName, formatUserFirstLastName } from 'utils/user';
import UserItem from 'components/UserItem';
import useInfiniteContainer from 'hooks/useInfiniteContainer';
import { useTranslation } from 'react-i18next';
import { DropdownOption } from 'components/DropdownOptions/DropdownOptions.component';
import useChatItemSearch from './hooks/useChatItemSearch';
import useChatItemDelete from './hooks/useChatItemDelete';
import { ChatParticipant, ChatSession } from 'models/Chat';
import TrashIcon from 'icons/Trash.icon';
import UserItemLoading from 'components/LoadPlaceholders/UserItem.loading';
import dates from 'utils/dates';
import dayjs, { Dayjs } from 'dayjs';
import useChatOnlineStatus from 'hooks/useChatOnlineStatus';
import ChatNotificationsContext from 'providers/ChatNotifications/ChatNotifications.context';
import NotificationBadge from 'components/NotificationBadge';
import useEvent from 'hooks/useEvent';
import useMatchMedia from 'hooks/useMatchMedia';
import { INBOX_CHAT_USER_ITEM_LINK_ID } from 'router/subrouters/Inbox/utils';

import './ChatList.styles.scss';

const formatLastOnline = (date: Date | Dayjs | string) => {
  if (dayjs().diff(date, 'hour') >= 24) {
    return dates.formatDate(date, 'hh:mm a, DD.MM.YYYY');
  }

  return dayjs(date).fromNow();
};

type ChatListProps = {
  className?: string;
  activeChatId?: number;
  isChatPage?: boolean;
  generateChatLink?: (chatSession: ChatSession) => string;
};

const ChatList: React.FC<ChatListProps> = (props) => {
  const { className, activeChatId, isChatPage, generateChatLink } = props;

  const { t } = useTranslation();

  const { currentUser } = useContext(CurrentUserContext);

  const { newMessagesBySessions } = useContext(ChatNotificationsContext);

  const chatItemsRef = useRef<HTMLUListElement>();

  const isPhablet = useMatchMedia('isPhablet');

  const { setItems, onContainerScrolled, loading, items } = useChatItemSearch();

  const { isDeleting, handleDelete } = useChatItemDelete(setItems);

  const shouldUseWindowScroll = isPhablet && isChatPage && !activeChatId;

  const { onScroll, loaderEl } = useInfiniteContainer({
    container: shouldUseWindowScroll ? window : chatItemsRef.current,
    onScroll: onContainerScrolled,
    loader: <UserItemLoading length={3} />,
    loading,
  });

  useEvent(window, 'scroll', onScroll, undefined, shouldUseWindowScroll);

  const classes = classNames('anys-chat-list', className);

  const userIds = useMemo(() => items?.map(({ id }) => id), [items]);

  const { onlineStatuses, checkOnlineStatuses } = useChatOnlineStatus();

  const separateParticipants = useCallback(
    (
      participants: ChatParticipant[],
    ): {
      chatParticipant: ChatParticipant;
      meAsParticipant: ChatParticipant;
    } => {
      if (!participants?.length)
        return { chatParticipant: null, meAsParticipant: null };

      return participants.reduce<{
        chatParticipant: ChatParticipant;
        meAsParticipant: ChatParticipant;
      }>(
        (separatedParticipants, participant) => {
          if (participant?.user?.id !== currentUser?.id) {
            separatedParticipants.chatParticipant = participant;
          } else {
            separatedParticipants.meAsParticipant = participant;
          }

          return separatedParticipants;
        },
        { chatParticipant: null, meAsParticipant: null },
      );
    },
    [currentUser?.id],
  );

  useEffect(() => {
    if (userIds?.length) checkOnlineStatuses(userIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIds]);

  return (
    <div className={classes}>
      {items?.length ? (
        <ul ref={chatItemsRef} onScroll={onScroll}>
          {items.map((item) => {
            const {
              id: chatId,
              participants,
              lastMessage,
              lastMessageSentAt,
            } = item;

            const { meAsParticipant, chatParticipant } =
              separateParticipants(participants);

            const { user } = chatParticipant || {};

            const { unreadMessages } = meAsParticipant || {};

            const imgUrl =
              user?.profileImage?.resizedUrls?.[80] || user?.profileImage?.url;

            const { isOnline, lastSeenAt } =
              onlineStatuses?.[user?.id] || user?.status || {};

            const time = isOnline
              ? t('General.online')
              : lastSeenAt
              ? formatLastOnline(lastSeenAt)
              : '-';

            const participantDisplayName = formatUserDisplayName(user);
            const participantFullName = formatUserFirstLastName(user);

            const menuOptions: DropdownOption[] = [
              {
                key: 'remove',
                title: (
                  <span className="anys-chat-list__remove-item">
                    <TrashIcon fill="currentColor" /> {t('General.remove')}
                  </span>
                ),
                disabled: isDeleting,
                onClick: () => {
                  handleDelete(chatId, participantDisplayName);
                },
              },
            ];

            const chatLink = generateChatLink
              ? generateChatLink(item)
              : `/chat/${user.id}`;

            const msgsFromNotif = newMessagesBySessions?.[chatId];

            const lastMsgFromNotif = msgsFromNotif?.[msgsFromNotif?.length - 1];

            const shouldSkipMsgsFromItem =
              lastMessage === lastMsgFromNotif?.payload &&
              lastMessageSentAt === lastMsgFromNotif?.createdAt;

            const totalUnread = shouldSkipMsgsFromItem
              ? unreadMessages || 0
              : (msgsFromNotif?.length || 0) + (unreadMessages || 0);

            return (
              <li key={chatId}>
                <UserItem
                  className={classNames({ 'anys-chat-list__online': isOnline })}
                  linkProps={{ to: chatLink, id: INBOX_CHAT_USER_ITEM_LINK_ID }}
                  imgUrl={imgUrl}
                  imgAlt={participantFullName}
                  topPartContent={participantFullName}
                  bottomPartContent={time}
                  menuOptions={menuOptions}
                  isSelected={activeChatId === user.id}
                >
                  {totalUnread ? (
                    <NotificationBadge
                      count={totalUnread}
                      onlyDotOnMobile={false}
                      className="anys-chat-list__notification-badge"
                    />
                  ) : null}
                </UserItem>
              </li>
            );
          })}
          {loading && <li>{loaderEl}</li>}
        </ul>
      ) : (
        loaderEl
      )}
    </div>
  );
};

export default ChatList;
