import React, { useEffect, useMemo, useRef } from 'react';
import classNames from 'classnames';
import Modal, { ModalRef } from 'components/Modal';
import { ImageSliderRef } from 'ncoded-component-library/build/components/organisms/ImageSlider/ImageSlider.component';
import { Button, ImageSlider } from 'ncoded-component-library';
import ResponsiveImage from 'components/ResponsiveImage';
import { Picture } from 'models/User';
import LongArrow from 'icons/LongArrow';
import useCallbackRef from 'hooks/useCallbackRef';
import ResponsiveVideo from 'components/ResponsiveVideo';
import VideoToImage from 'components/VideoToImage';
import ExternalLink from 'components/ExternalLink';
import { useTranslation } from 'react-i18next';
import { ReactComponent as OpenNewWindowIcon } from 'icons/open-new-window.svg';

import './ImagesPreviewModal.styles.scss';
import './ImagesPreviewModal.styles.responsive.scss';

type ImagesPreviewModalProps = {
  className?: string;
  modalRef: React.Ref<ModalRef>;
  activeIndex?: number;
  title?: React.ReactNode;
  description?: React.ReactNode;
  modalName?: string;
  footerContent?: React.ReactNode;
  onClose?: () => void;
  onIndexChange?: (currentIndex: number) => void;
} & (
  | {
      files: Pick<Picture, 'id' | 'name' | 'url' | 'resizedUrls'>[];
    }
  | {
      files: Pick<Picture, 'id' | 'name' | 'url'>[];
    }
);

const ImagesPreviewModal: React.FC<ImagesPreviewModalProps> = (props) => {
  const {
    className,
    modalRef,
    files,
    activeIndex,
    title,
    description,
    modalName = 'image-slider-modal',
    footerContent,
    onClose,
    onIndexChange,
  } = props;

  const { t } = useTranslation();
  const [slider, sliderRef] = useCallbackRef<ImageSliderRef>();
  const imageTrackRef = useRef<HTMLDivElement>();

  const classes = classNames(
    'anys-images-preview-modal',
    { 'anys-images-preview-modal--with-footer': footerContent },
    className,
  );

  const activeMedia = files?.[slider?.activeImageIndex];

  const sliderImages = useMemo(
    () =>
      files.map((file) => {
        const { name, url } = file;

        const element =
          'resizedUrls' in file ? (
            <ResponsiveImage
              src={url}
              alt={name}
              resizedVariants={file.resizedUrls}
            />
          ) : (
            <ResponsiveVideo
              src={url}
              className="anys-images-preview-modal__video"
            />
          );

        return {
          element,
          imageUrl: null,
        };
      }),
    [files],
  );

  const footer = useMemo(
    () => (
      <>
        {files.length ? (
          <div className="anys-images-preview-modal__bottom-slider">
            <Button
              type="button"
              variant="icon"
              className="anys-images-preview-modal__arrow-button"
              onClick={() => {
                // as any, because we need to change the type in ncoded-library
                slider.setActiveImageIndex(((oldIndex: number) =>
                  oldIndex > 0 ? --oldIndex : files.length - 1) as any);
              }}
            >
              <LongArrow direction="left" />
            </Button>
            <div
              className="anys-images-preview-modal__image-track"
              ref={imageTrackRef}
            >
              {files.map((file, i) => {
                const { id, name, url } = file;

                return (
                  <div
                    key={id}
                    className={classNames(
                      'anys-images-preview-modal__image-track__image',
                      {
                        'anys-images-preview-modal__image-track__image--active':
                          i === slider?.activeImageIndex,
                      },
                    )}
                  >
                    {'resizedUrls' in file ? (
                      <ResponsiveImage
                        resizedVariants={file.resizedUrls}
                        src={file.resizedUrls?.[320] || url}
                        alt={name}
                        onClick={() => slider.setActiveImageIndex(i)}
                        sizes="(max-width: 768px) 100vw,(max-width: 1000px) 750px,900px"
                      />
                    ) : (
                      <VideoToImage
                        videoUrl={url}
                        alt={name}
                        onClick={() => slider.setActiveImageIndex(i)}
                      />
                    )}
                  </div>
                );
              })}
            </div>
            <Button
              type="button"
              variant="icon"
              className="anys-images-preview-modal__arrow-button"
              onClick={() =>
                // as any, because we need to change the type in ncoded-library
                slider.setActiveImageIndex(((oldIndex: number) =>
                  oldIndex < files.length - 1 ? ++oldIndex : 0) as any)
              }
            >
              <LongArrow direction="right" />
            </Button>
          </div>
        ) : null}
        {footerContent}
      </>
    ),
    [files, footerContent, slider],
  );

  useEffect(() => {
    if (imageTrackRef.current && slider?.activeImageIndex >= 0) {
      // Scroll image track to the selected image;
      // Image width (64px).
      const imageWidth = slider.activeImageIndex * 64;
      imageTrackRef.current.scrollLeft = imageWidth;
    }
  }, [slider?.activeImageIndex]);

  useEffect(() => {
    if (!slider) return;

    slider.setActiveImageIndex(activeIndex);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex]);

  useEffect(() => {
    onIndexChange?.(slider?.activeImageIndex);
  }, [onIndexChange, slider?.activeImageIndex]);

  return (
    <Modal
      type="no-action"
      ref={modalRef}
      modalName={modalName}
      isFullscreen
      className={classes}
      title={title}
      footer={footer}
      keepOpenOnRefresh={sliderImages?.length > 0}
      onClose={onClose}
    >
      <div className="anys-images-preview-modal__desc">
        {description}

        <span className="anys-images-preview-modal__desc__counter">
          {(slider?.activeImageIndex || 0) + 1}/{files.length}
        </span>
      </div>
      <ImageSlider
        className="anys-images-preview-modal__slider"
        showDots={false}
        arrowControls={false}
        ref={sliderRef}
        slides={sliderImages}
        activeIndex={activeIndex}
      />
      {activeMedia ? (
        <ExternalLink
          href={activeMedia.url}
          className={classNames(
            'ncoded-button',
            'ncoded-button--primary',
            'ncoded-button--solid',
            'anys-images-preview-modal__view-original',
          )}
        >
          {t('General.viewOriginalEntity', {
            entity: ('resizedUrls' in activeMedia
              ? t('General.image')
              : t('General.video')
            ).toLowerCase(),
          })}

          <OpenNewWindowIcon />
        </ExternalLink>
      ) : null}
    </Modal>
  );
};

export default ImagesPreviewModal;
