import { Role, User } from 'models/User';
import credentialsService from './credentialsService';
import storageService, { STORAGE_KEYS } from './storageService';
import webpushService from './webpushService';

export default {
  logout() {
    // Remove auth data that we store in storage
    storageService.removeItem(STORAGE_KEYS.EMAIL_TO_VERIFY, true);
    credentialsService.removeAuthBody();
    webpushService.unsubscribe();

    // Do a hard reload
    window.location.href = '/';
  },
  checkRolesForUser(user: Partial<User>, roles: Role[], atLeastOne = true) {
    return roles[atLeastOne ? 'some' : 'every']((role) => role === user.role);
  },
};
