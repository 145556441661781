import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement> & { showGradient?: boolean };

export default (props: IconProps) => (
  <svg
    {...props}
    width="28"
    height="30"
    viewBox="0 0 28 30"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.63896 0.566211C3.67373 -0.0292317 4.94863 -0.0292317 5.9834 0.566211L26.0501 12.1132C27.0848 12.7087 27.7223 13.8091 27.7223 15C27.7223 16.1908 27.0848 17.2913 26.0501 17.8867L5.9834 29.4337C4.94863 30.0291 3.67373 30.0291 2.63896 29.4337C1.60418 28.8383 0.966736 27.7378 0.966736 26.547V3.45296C0.966736 2.26208 1.60418 1.16165 2.63896 0.566211Z"
    />
  </svg>
);
