import React from 'react';
import classNames from 'classnames';
import LocationIcon from 'icons/Location.icon';
import LeftArrowIcon from 'icons/LeftArrow.icon';
import { JobTypeOfService } from 'models/Job/TypeOfService';
import { useTranslation } from 'react-i18next';
import { formatSingleLocation } from 'utils/location';

import './LocationDisplay.styles.scss';
import './LocationDisplay.styles.responsive.scss';

type LocationDisplayProps = {
  className?: string;
} & Pick<JobTypeOfService, 'locationType' | 'locations'>;

const LocationDisplay: React.FC<LocationDisplayProps> = (props) => {
  const { className, locationType, locations } = props;

  const { t } = useTranslation();
  const classes = classNames('anys-location-display', className);

  const NO_LOCATION = <p className={classes}>{t('General.noLocation')}</p>;

  if (!locationType) return null;

  switch (locationType) {
    case 'One spot': {
      if (!locations?.length) return NO_LOCATION;

      return (
        <p className={classes}>
          <LocationIcon />
          <span>{formatSingleLocation(locations[0])}</span>
        </p>
      );
    }

    case 'Direction': {
      if (locations?.length !== 2) return NO_LOCATION;

      return (
        <p className={classes}>
          <LocationIcon />
          <span>{formatSingleLocation(locations[0])}</span>
          <LeftArrowIcon />
          <span>{formatSingleLocation(locations[1])}</span>
        </p>
      );
    }

    case 'Area': {
      if (!locations?.length) return NO_LOCATION;

      return (
        <p className={classes}>
          <LocationIcon />
          <span>{formatSingleLocation(locations[0])}</span>
        </p>
      );
    }

    default:
      throw Error('Please use predefined location type');
  }
};

export default LocationDisplay;
