import React from 'react';
import { overallRatingOptions } from 'constants/FilteringOptions';
import { USER_TYPE } from 'constants/filters';
import LockedPadlockIcon from 'icons/LockedPadlock.icon';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import utils from 'utils';
import UnlockedPadlockIcon from 'icons/UnlockedPadlock.icon';
import { JobPricingType } from 'models/Job/TimeAndPricing';
import { Filter } from 'models/Filters';
import { LANGUAGE_OPTIONS } from 'constants/language';

const ratingOptions = Array.from({ length: 10 })
  .map((_, ind) => ({
    label: ind === 9 ? `${ind + 1}` : `${ind + 1}+`,
    value: `${ind + 1}`,
  }))
  .reverse();

const minNumberOfJobs = [
  {
    label: '5+',
    value: '5',
  },
  {
    label: '10+',
    value: '10',
  },
  {
    label: '20+',
    value: '20',
  },
  {
    label: '50+',
    value: '50',
  },
];

const YES_NO = {
  yes: 'yes',
  no: 'no',
};

const SEE_ALL = 'see-all';

export default function useSelectOptions(favouriteFilters?: Filter[]) {
  const { t } = useTranslation();

  const seeAllOption = useMemo(
    () => ({
      value: SEE_ALL,
      label: t('General.seeAll'),
    }),
    [t],
  );

  const userTypeOptions = useMemo(
    () => [
      ...Object.values(USER_TYPE).map((type) => ({
        label: t(`General.${utils.camelize(type)}`),
        value: type,
      })),
      seeAllOption,
    ],
    [t, seeAllOption],
  );

  const signOptions = useMemo(
    () => [
      {
        value: 'offer',
        label: t('General.offer'),
      },
      {
        value: 'pre-offer',
        label: t('General.preOffer'),
      },
      seeAllOption,
    ],
    [t, seeAllOption],
  );

  const negotiationOptions = useMemo(
    () => [
      {
        value: YES_NO.yes,
        label: (
          <>
            {t('General.negotiable')}

            <UnlockedPadlockIcon showGradient={false} width={16} height={16} />
          </>
        ),
      },
      {
        value: YES_NO.no,
        label: (
          <>
            {t('General.nonNegotiable')}{' '}
            <LockedPadlockIcon
              fillBox
              showGradient={false}
              width={16}
              height={16}
            />
          </>
        ),
      },
      seeAllOption,
    ],
    [t, seeAllOption],
  );

  const freeCancellation = useMemo(
    () => [
      {
        value: YES_NO.yes,
        label: t(`General.yes`),
      },
      {
        value: YES_NO.no,
        label: t(`General.no`),
      },
      seeAllOption,
    ],
    [t, seeAllOption],
  );

  const priceTypes: { value: JobPricingType; label: string }[] = useMemo(
    () => [
      { value: 'By project', label: t('Filters.priceTotal') },
      { value: 'Hourly', label: t('Filters.priceByHour') },
    ],
    [t],
  );

  const favouriteFiltersOptions: {
    label: string;
    value: string;
    disabled?: boolean;
  }[] = useMemo(
    () =>
      favouriteFilters?.length
        ? favouriteFilters.map(({ id, name }) => ({
            label: name,
            value: `${id}`,
          }))
        : [
            {
              label: t('General.noFiltersAdded'),
              disabled: true,
              value: '',
            },
          ],
    [favouriteFilters, t],
  );

  return {
    priceTypes,
    signOptions,
    ratingOptions,
    userTypeOptions,
    languageOptions: LANGUAGE_OPTIONS,
    freeCancellation,
    overallRatingOptions,
    negotiationOptions,
    minNumberOfJobs,
    favouriteFiltersOptions,
  };
}
