import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

const WalletIcon = (props: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3924 1.13402C15.8706 0.857878 16.4822 1.02175 16.7584 1.50004L18.7791 5.00004H21C22.1046 5.00004 23 5.89546 23 7.00004V21C23 22.1046 22.1046 23 21 23H3C1.89544 23 1 22.1046 1 21V7.00004C1 5.89545 1.89544 5.00004 3 5.00004H8.69617L15.3924 1.13402ZM16.4654 4.99261L15.5263 3.36607L12.7161 4.98854L16.4654 4.99261ZM21 17.5H17.625C15.6695 17.5 14 15.9784 14 14C14 12.0216 15.6695 10.5 17.625 10.5H21V7.00004H3V21H21V17.5ZM21 12.5H17.625C16.681 12.5 16 13.217 16 14C16 14.783 16.681 15.5 17.625 15.5H21V12.5Z"
        fill="black"
        fillOpacity="0.85"
      />
    </svg>
  );
};

export default WalletIcon;
