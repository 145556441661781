import { useCallback, useEffect, useRef, useState } from 'react';
import api from 'api';
import { UserOnlineStatus } from 'models/User';

const useChatOnlineStatus = () => {
  const [onlineStatuses, setOnlineStatuses] = useState<
    Record<number, UserOnlineStatus>
  >({});

  const intervalId = useRef<NodeJS.Timer>();

  const getOnlineStatuses = useCallback(async (userIds: number[]) => {
    try {
      const { data } = await api.user.getOnlineStatuses(userIds);

      const onlineStatusByUser = data.reduce<Record<number, UserOnlineStatus>>(
        (reducedStatuses, { id, status }) => {
          reducedStatuses[id] = status;

          return reducedStatuses;
        },
        {},
      );

      setOnlineStatuses((old) => ({ ...old, ...onlineStatusByUser }));
    } catch (error) {
      console.error(error);
    }
  }, []);

  const checkOnlineStatuses = useCallback(
    (userIds: number[]) => {
      if (intervalId.current) clearInterval(intervalId.current);

      getOnlineStatuses(userIds);

      intervalId.current = setInterval(() => {
        getOnlineStatuses(userIds);
      }, 60000);
    },
    [getOnlineStatuses],
  );

  useEffect(() => {
    return () => intervalId.current && clearInterval(intervalId.current);
  }, []);

  return { onlineStatuses, checkOnlineStatuses };
};

export default useChatOnlineStatus;
