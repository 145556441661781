import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

export default (props: IconProps) => (
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 22C0 9.84973 9.84973 0 22 0C34.1503 0 44 9.84973 44 22C44 34.1503 34.1503 44 22 44C9.84973 44 0 34.1503 0 22ZM22 4C12.0589 4 4 12.0589 4 22C4 31.9411 12.0589 40 22 40C31.9411 40 40 31.9411 40 22C40 12.0589 31.9411 4 22 4ZM15 14C16.1046 14 17 14.8954 17 16V17C17 18.1046 16.1046 19 15 19C13.8954 19 13 18.1046 13 17V16C13 14.8954 13.8954 14 15 14ZM29 14C30.1046 14 31 14.8954 31 16V17C31 18.1046 30.1046 19 29 19C27.8954 19 27 18.1046 27 17V16C27 14.8954 27.8954 14 29 14ZM13 29C13 27.8954 13.8954 27 15 27H29C30.1046 27 31 27.8954 31 29C31 30.1046 30.1046 31 29 31H15C13.8954 31 13 30.1046 13 29Z"
      fill="url(#paint0_linear_9548_30170)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_9548_30170"
        x1="-7.32063e-08"
        y1="44"
        x2="44.7673"
        y2="43.2049"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A761F5" />
        <stop offset="1" stopColor="#50A8FA" />
      </linearGradient>
    </defs>
  </svg>
);
