import api from 'api';
import useInfinitePagination from 'hooks/useInfinitePagination';
import useQueryParams from 'hooks/useQueryParams';
import {
  DEFAULT_PAGINATION,
  PaginatedResponse,
  PaginationParams,
} from 'models/Pagination';
import { User } from 'models/User';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import utils from 'utils';
import { SearchBy } from '../components/SearchSelect/hooks/useSearchOptions';
import { FilterContext, Where } from '../providers/Filters/Filters.provider';
import useEffectSkipFirst from 'hooks/useEffectSkipFirst';
import useMatchMedia from 'hooks/useMatchMedia';

export default function useUserSearch() {
  const { currentUser, loading: loadingCurrentUser } =
    useContext(CurrentUserContext);

  const {
    params: { page, $suggestionPage },
    removeQueryParam,
    setQueryParam,
  } = useQueryParams<{ page: string; $suggestionPage: string }>();
  const currentUserId = currentUser?.id || '';

  const {
    params: { term, 'search-by': searchBy },
  } = useQueryParams<{
    'search-by': SearchBy;
    term: string;
    'skills[]': string;
  }>();
  const isPhablet = useMatchMedia('isPhablet');

  const { where: filtersWhere } = useContext(FilterContext);

  const [suggestedUsersPagination, setSuggestedUsersPagination] =
    useState<PaginatedResponse<User>>(null);

  const hasReachedLastPage = useRef(false);

  const baseWhere: [Where] = useMemo(() => {
    if (loadingCurrentUser)
      return [
        {
          displayName: { '!ISNULL': '' },
        },
      ];

    return [
      {
        ...(currentUserId
          ? {
              id: {
                NOT: currentUserId,
              },
            }
          : {}),
        displayName: { '!ISNULL': '' },
      },
    ];
  }, [loadingCurrentUser, currentUserId]);

  const defaultWhere = useMemo(() => {
    if (!baseWhere) return null;

    if (!filtersWhere?.length) return baseWhere;

    return filtersWhere.map((fw) => ({ ...baseWhere[0], ...fw }));
  }, [baseWhere, filtersWhere]);

  const where = useMemo(() => {
    let where: Where[];

    const getLikes = (arr: string[], likeStr = term) =>
      arr.map((i) => ({ [i]: { ILIKE: `%${likeStr}%`, '!ISNULL': '' } }));

    if (term) {
      if (searchBy === 'skill') {
        where = [
          {
            'skills.skill.name': { ILIKE: `%${term}%` },
          },
        ];
      } else if (searchBy === 'name') {
        where = getLikes(['displayName']);
      } else if (searchBy === 'userId') {
        const value = utils.tryParse(term);
        if (typeof value === 'number')
          where = [{ anyServiceId: { EQUAL: value } }];
      }
    }

    if (!where) return defaultWhere;
    return where.map((cond) => ({ ...defaultWhere?.[0], ...cond }));
  }, [defaultWhere, searchBy, term]);

  const searchUsers = useCallback(
    async (currentPage: number, take: number) => {
      if (loadingCurrentUser)
        return Promise.resolve({ items: [], totalItems: 0, totalPages: 0 });
      const queryParams: PaginationParams = {
        $where: where ? JSON.stringify(where) : null,
        $relations:
          'profileImage,overallSkillScore,overallClientScore,location,languages,jobSuccess,skills.skill',
        $take: take,
        $skip: (currentPage - 1) * take,
        $suggestionPage: $suggestionPage || '1',
        $order: '-overallSkillScore.numberOfReviews',
      };

      const {
        data: {
          items,
          currentPage: latestPage,
          totalItems,
          totalPages,
          suggestion,
        },
      } = await api.user.getUsers(queryParams);

      // If we are on the last page, save suggested users pagination
      if (latestPage >= totalPages) {
        setSuggestedUsersPagination((oldPagination) => {
          const { items: suggestedItems, ...restOfSuggestions } = suggestion;

          return {
            ...restOfSuggestions,
            items: [...(oldPagination?.items || []), ...suggestedItems],
          };
        });

        const newItems = hasReachedLastPage.current ? [] : items;

        hasReachedLastPage.current = true;

        return {
          items: newItems,
          totalItems,
          totalPages,
        } as const;
      } else setSuggestedUsersPagination(null);

      return { items, totalItems, totalPages } as const;
    },
    [loadingCurrentUser, where, $suggestionPage],
  );

  const whereAsString = where ? JSON.stringify(where) : null;

  const pagination = useInfinitePagination<User>({
    take: DEFAULT_PAGINATION.take,
    searchString: term,
    makeRequest: searchUsers,
    resetDeps: [whereAsString],
    debounceTime: 500,
    page: +page,
  });

  const isDefaultSearch = where === baseWhere;

  const {
    loading,
    currentPage,
    totalItems,
    totalPages,
    goToPage,
    onContainerScrolled,
  } = pagination;

  const showPaginator = !!totalItems && !isPhablet;

  const onContainerScrollFinal = useMemo(() => {
    if (loading) return utils.noop;

    if (currentPage >= totalPages) {
      return () => {
        const { currentPage: sCurrentPage, totalPages: sTotalPages } =
          suggestedUsersPagination || {};

        const newSuggestedPage =
          sCurrentPage < sTotalPages ? sCurrentPage + 1 : sCurrentPage;

        setQueryParam('$suggestionPage', newSuggestedPage || '1');
      };
    }

    return onContainerScrolled;
  }, [
    currentPage,
    loading,
    onContainerScrolled,
    setQueryParam,
    suggestedUsersPagination,
    totalPages,
  ]);

  // Reset values
  useEffect(() => {
    hasReachedLastPage.current = false;

    setSuggestedUsersPagination(null);
    setQueryParam('$suggestionPage', '1');

    window.scroll({
      top: 0,
      left: 0,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [whereAsString, page]);

  // Keep the page url param
  // in sync with currentPage state
  useEffect(() => {
    if (loading) return;
    if (!showPaginator) return;

    setQueryParam('page', currentPage);
  }, [currentPage, loading, setQueryParam, showPaginator]);

  useEffectSkipFirst(() => {
    if (loading) return;
    if (!showPaginator) {
      goToPage(1);
      removeQueryParam('page', true);
    }
  }, [goToPage, removeQueryParam, showPaginator]);

  return {
    ...pagination,
    showPaginator,
    isPhablet,
    removeQueryParam,
    onContainerScrolled: onContainerScrollFinal,
    isDefaultSearch,
    suggestedUsersPagination,
  };
}
