import React from 'react';
import classNames from 'classnames';
import TimesIcon from 'icons/Times.icon';

import './Badge.styles.scss';

type BadgeProps = {
  className?: string;
  clickDisabled?: boolean;
  onClick?: () => void;
};

const Badge: React.FC<React.PropsWithChildren<BadgeProps>> = (props) => {
  const { children, className, clickDisabled, onClick } = props;

  const classes = classNames('anys-badge', className);

  const HTMLEl = 'span';

  return (
    <HTMLEl className={classes}>
      {children}
      {!!onClick && (
        <button
          type="button"
          onClick={onClick}
          disabled={clickDisabled}
          className="anys-badge__button"
        >
          <TimesIcon color="currentColor" />
        </button>
      )}
    </HTMLEl>
  );
};

export default Badge;
