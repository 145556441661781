import React from 'react';
import classNames from 'classnames';

import './Tabs.styles.scss';

type TabsProps = {
  className?: string;
  labels?: { name: string; id: number; disable?: boolean }[];
  active?: number;
  setActive?: (value: React.SetStateAction<number>) => void;
};

const Tabs: React.FC<TabsProps> = (props) => {
  const { className, labels, active, setActive } = props;

  const classes = classNames('anys-tabs', className);

  return (
    <ul className={classes}>
      {labels.map(({ name, id, disable }) => (
        <li
          key={id}
          className={classNames({ active: active === id }, { disable })}
          onClick={() => !disable && setActive(id)}
        >
          {name}
        </li>
      ))}
    </ul>
  );
};

export default Tabs;
