import { EnhancedRouteProps } from 'router/routes/Enhanced/Enhanced.route';
import ArbitrationPage from './Arbitration.page';

//TODO: remove this, all pages are going throw query params
export default [
  {
    path: '',
    element: ArbitrationPage,
    authorized: true,
  },
] as Array<EnhancedRouteProps>;
