import { useCallback, useEffect, useRef, useState } from 'react';
import { Skill, SkillGroupWithSkills } from 'models/Skills';
import api from 'api';
import { useTranslation } from 'react-i18next';
import showToast from 'modules/showToast';
import { AxiosError } from 'axios';

const useGetSkillGroupsByReviews = () => {
  const { t } = useTranslation();
  const called = useRef<boolean>(false);
  const [skillGroups, setSkillGroups] = useState<SkillGroupWithSkills[]>([]);
  const [allSkills, setAllSkills] = useState<Skill[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getSkillGroupsByReviews = useCallback(async () => {
    called.current = true;
    setIsLoading(true);
    try {
      const res = await api.skillGroup.getSkillGroupsWithReviewSkills();
      const groups = res?.data?.skillGroups || [];
      const allSkills = [].concat(
        ...groups.map((skillGroup) => skillGroup.skills),
      );

      setAllSkills(allSkills);
      setSkillGroups(groups);
    } catch (error) {
      const err = error as AxiosError;

      showToast(
        'error',
        t('General.error'),
        err?.response?.data?.error?.message,
      );
    } finally {
      setIsLoading(false);
    }
  }, [t]);

  useEffect(() => {
    if (called.current) {
      return;
    }

    getSkillGroupsByReviews();
  }, [getSkillGroupsByReviews]);

  return { skillGroups, allSkills, isLoading } as const;
};

export default useGetSkillGroupsByReviews;
