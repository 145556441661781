import httpClient from 'api/httpClient';

const referralPath = (routePath: TemplateStringsArray) =>
  `referral/${routePath}`;

const ensureReferralCode = () => {
  return httpClient.post<{ id: number; code: string }>(referralPath``);
};

const recordReferral = (code: string) => {
  return httpClient.post<{ message: string }>(referralPath`` + code);
};

export default {
  ensureReferralCode,
  recordReferral,
};
