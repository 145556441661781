import React, { useCallback, useContext, useEffect, useState } from 'react';
import api from 'api';
import { Filter } from 'models/Filters';
import NeedProvideContext from './NeedProvide.context';
import { PriceRangeType } from 'api/favouriteFilters';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import showToast from 'modules/showToast';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

type NeedProvideProps = {
  children: React.ReactNode;
};

const NeedProvide: React.FC<NeedProvideProps> = (props) => {
  const { children } = props;

  const { t } = useTranslation();
  const location = useLocation();

  const { currentUser } = useContext(CurrentUserContext);
  const userId = currentUser?.id;

  const [removingFilter, setRemovingFilter] = useState(false);
  const [favouriteFilters, setFavouriteFilters] = useState<Partial<Filter[]>>(
    [],
  );

  const [priceRange, setPriceRange] = useState<PriceRangeType>({
    minTotalPrice: 0,
    maxTotalPrice: 0,
  });

  const isNeed = location.pathname?.startsWith('/need');

  const getFavouriteFilters = useCallback(async () => {
    try {
      const { data } = await api.favouriteFilters.getFavouriteFilters();
      setFavouriteFilters(data);
    } catch (e) {
      showToast('error', t('General.error'));
    }
  }, [t]);

  const createFavouriteFilter = useCallback(
    async (name: string, query: string, formValues: object) => {
      try {
        const { data } = await api.favouriteFilters.createFavouriteFilter(
          name,
          query,
          formValues,
        );
        setFavouriteFilters((oldFilters) => [data, ...oldFilters]);

        showToast('success', t('FavouriteFilters.filterSaved'));
      } catch (e) {
        showToast('error', t('General.error'));
      }
    },
    [t],
  );

  const updateFavouriteFilter = useCallback(
    async (id: number, name: string, query: string, formValues: object) => {
      try {
        const { data } = await api.favouriteFilters.updateFavouriteFilter(
          id,
          name,
          query,
          formValues,
        );
        setFavouriteFilters((oldFilters) =>
          oldFilters.map((filter) => (filter.id === id ? data : filter)),
        );

        showToast('success', t('FavouriteFilters.filterUpdated'));
      } catch (e) {
        showToast('error', t('General.error'));

        throw e;
      }
    },
    [t],
  );

  const removeFavouriteFilter = useCallback(
    async (filterId: number) => {
      setRemovingFilter(true);

      try {
        await api.favouriteFilters.removeFavouriteFilter(filterId);

        setFavouriteFilters((oldFilters) =>
          oldFilters.filter(({ id }) => id !== filterId),
        );

        showToast(
          'success',
          `${t('General.success')}!`,
          t('FavouriteFilters.removeFilterSuccess'),
        );
      } catch (error) {
        showToast('error', t('General.error'));

        throw error;
      } finally {
        setRemovingFilter(false);
      }
    },
    [t],
  );

  const getPriceRange = useCallback(async () => {
    try {
      const {
        data: { minTotalPrice, maxTotalPrice },
      } = await api.jobPost.getPriceRange(isNeed ? 'Need' : 'Provide');

      setPriceRange({
        minTotalPrice,
        maxTotalPrice,
      });
    } catch (e) {
      console.error(e);
    }
  }, [isNeed]);

  useEffect(() => {
    if (!userId) return;

    getFavouriteFilters();
  }, [userId, getFavouriteFilters]);

  useEffect(() => {
    getPriceRange();
  }, [getPriceRange]);

  return (
    <NeedProvideContext.Provider
      value={{
        favouriteFilters,
        setFavouriteFilters,
        priceRange,
        createFavouriteFilter,
        removingFilter,
        removeFavouriteFilter,
        updateFavouriteFilter,
      }}
    >
      {children}
    </NeedProvideContext.Provider>
  );
};

export default NeedProvide;
