import Modal, { ModalRef } from 'components/Modal';
import useCallbackRef from 'hooks/useCallbackRef';
import { Button, RadioButton } from 'ncoded-component-library';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import WeeklyHoursForm from '../AvailabilityModal/components/WeeklyHoursForm';
import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';
import timezone from 'dayjs/plugin/timezone';
import dayjs from 'dayjs';
import api from 'api';
import { useProfile } from '../../../Profile/Profile.page';
import CustomAvailabilityForm from './components/CustomAvailabilityForm';
import dates from 'utils/dates';
import { week } from 'constants/week';
import utils from './utils';
import { FormApi } from 'final-form';

import './AvailabilityModal.styles.scss';

dayjs.extend(timezone);

type ConjugateSpecificEntrieType = {
  fromDate: string;
  toDate: string;
  times: { startTime: string; endTime: string }[];
};

type AvailabilityModalProps = {
  open: boolean;
  onOpen?: () => void;
  onClose: () => void;
};

const AvailabilityModal: React.FC<AvailabilityModalProps> = ({
  open,
  onOpen,
  onClose,
}) => {
  const { t } = useTranslation();
  const { profileUser, availability, setAvailability } = useProfile();

  const [modal, modalRef] = useCallbackRef<ModalRef>(null);
  const formApi = useRef<FormApi<any>>();

  const [active, setActive] = useState(1);

  useEffect(() => {
    if (availability?.recurringEntries?.length !== 0) {
      setActive(1);
    } else if (availability?.specificEntries?.length !== 0) {
      setActive(2);
    }
  }, [availability, modal?.open]);

  useEffect(() => {
    if (!modal) return;

    if (!modal.openState && open) modal.open();
    else if (modal.openState && !open) modal.close();
  }, [open, modal]);

  const onSubmit = async (values: any) => {
    let recurringEntries: any = [];

    Object.keys(values).forEach((day: string) => {
      if (values[day].isChecked) {
        const ret = values[day]?.weeklyHoursAvailability?.map(
          (weeklyHoursAvailability: any) => ({
            dayOfWeek: day.substring(0, 3).toLocaleLowerCase(),
            startTime: weeklyHoursAvailability?.startTime,
            endTime: weeklyHoursAvailability?.endTime,
          }),
        );
        recurringEntries = [...recurringEntries, ...ret];
      }
    });

    const specificEntries = values?.specificEntries?.reduce(
      (acc: any, specificEntrie: any) => {
        const tmp = specificEntrie?.dateHourRange?.map((time: any) => {
          return {
            fromDate: dayjs(specificEntrie?.date[0])?.format('YYYY-MM-DD'),
            toDate: dayjs(
              specificEntrie?.date[specificEntrie?.date?.length - 1],
            )?.format('YYYY-MM-DD'),
            startTime: time?.startTime,
            endTime: time?.endTime,
          };
        });

        return [...acc, ...tmp];
      },
      [],
    );

    const timeoffs = values?.timeoffs?.reduce((acc: any, timeoff: any) => {
      const bla = timeoff?.dateHourRange?.map((time: any) => {
        return {
          fromDate: dayjs(timeoff?.date[0])?.format('YYYY-MM-DD'),
          toDate: dayjs(timeoff?.date[timeoff?.date?.length - 1])?.format(
            'YYYY-MM-DD',
          ),
          startTime: time?.startTime,
          endTime: time?.endTime,
          isFullDay: time?.isFullDay,
        };
      });

      return [...acc, ...bla];
    }, []);

    //TODO: zeljko need to respond on this
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (!availability) {
      // CREATE AVAILABILITY
      try {
        if (active === 1) {
          const { data } = await api.availability.createAvailability(
            profileUser.id,
            {
              timezone,
              recurringEntries,
            },
          );

          let data2: any;

          if (data && timeoffs?.length > 0) {
            const { data: timeoffsData } =
              await api.availability.updateAvailabilityTimeoffs(
                profileUser.id,
                data.id,
                {
                  timeoffs,
                  timezone,
                },
              );

            data2 = timeoffsData;
          }

          if (data) {
            setAvailability((old) => ({
              ...old,
              id: data.id,
              recurringEntries: data.recurringEntries,
              timeoffs: data2 ? data2.timeoffs : [],
            }));
          }
        } else {
          const { data } = await api.availability.createAvailability(
            profileUser.id,
            {
              timezone,
              specificEntries,
            },
          );
          if (data) {
            setAvailability(data);
          }
        }
      } catch (e) {
        console.error(e);
      }
    } else {
      // UPDATE AVAILABILITY
      try {
        if (active === 1) {
          const { data } = await api.availability.updateAvailability(
            profileUser.id,
            availability.id,
            {
              timezone,
              recurringEntries,
            },
          );

          let tmp: any;
          if (timeoffs) {
            const { data } = await api.availability.updateAvailabilityTimeoffs(
              profileUser.id,
              availability.id,
              {
                timeoffs,
                timezone,
              },
            );
            tmp = data;
          }

          if (data) {
            setAvailability((old) => ({
              ...old,
              recurringEntries: data.recurringEntries,
              timeoffs: tmp?.timeoffs || [],
              specificEntries: undefined,
            }));
          }
        } else {
          const { data } = await api.availability.updateAvailability(
            profileUser.id,
            availability.id,
            {
              timezone,
              specificEntries,
              recurringEntries: undefined,
            },
          );

          await api.availability.updateAvailabilityTimeoffs(
            profileUser.id,
            availability.id,
            {
              timeoffs: [],
              timezone,
            },
          );

          if (data) {
            setAvailability(data);
          }
        }
      } catch (e) {
        console.error(e);
      }
    }
    onClose();
  };

  const initialValues = useMemo(() => {
    if (!availability) {
      return;
    }

    if (
      availability?.specificEntries &&
      availability?.specificEntries?.length !== 0
    ) {
      const conjugateSpecificEntries = utils.transformAvailabilityInitialData(
        availability?.specificEntries,
        ['fromDate', 'toDate'],
      );

      return {
        specificEntries: conjugateSpecificEntries?.map(
          (entrie: ConjugateSpecificEntrieType) => ({
            date: dates.getDatesInRange(
              dayjs(entrie.fromDate),
              dayjs(entrie.toDate),
            ),
            dateHourRange: entrie?.times.reverse(),
          }),
        ),
      };
    }

    let obj = {};

    if (availability?.recurringEntries.length !== 0) {
      const conjugateSpecificEntries = utils.transformAvailabilityInitialData(
        availability?.recurringEntries,
        ['dayOfWeek'],
      );

      obj = conjugateSpecificEntries.reduce((o, conjugateSpecificEntrie) => {
        return {
          ...o,
          [`${
            week.find(
              (day) => day.abbreviation === conjugateSpecificEntrie.dayOfWeek,
            ).day
          }`]: {
            isChecked: true,
            weeklyHoursAvailability: conjugateSpecificEntrie.times.reverse(),
          },
        };
      }, {});
    }

    if (availability?.timeoffs?.length !== 0) {
      const timeoffs = utils.transformAvailabilityInitialData(
        availability.timeoffs,
        ['fromDate', 'toDate'],
      );

      obj = {
        ...obj,
        timeoffs: timeoffs?.map((entrie: any) => ({
          date: dates.getDatesInRange(
            dayjs(entrie.fromDate),
            dayjs(entrie.toDate),
          ),
          dateHourRange: entrie?.times.reverse(),
        })),
      };
    }

    return obj;
  }, [availability]);

  return (
    <Modal
      ref={modalRef}
      type="no-action"
      onOpen={onOpen}
      onBackClick={onClose}
      onClose={onClose}
      modalName="setAvailability"
      title={t('Availability.modalTitle')}
      className="anys-availability__modal"
      overlayCloses
      isDrawer
      footer={
        <>
          <Button onClick={() => formApi.current.submit()}>
            {t('General.save')}
          </Button>
          <Button type="button" onClick={() => modal.close()} variant="link">
            <span>{t('General.cancel')}</span>
          </Button>
        </>
      }
    >
      <span className="anys-availability__modal__helper-text">
        {t('Availability.selectAvailabilityType')}
      </span>

      <div className="anys-availability__modal__tabs">
        <RadioButton
          label={t('Availability.weeklyHours')}
          checked={active === 1}
          value={1}
          onChange={() => {
            setActive(1);
          }}
        />

        <RadioButton
          label={t('Availability.customAvailability')}
          checked={active === 2}
          value={2}
          onChange={() => {
            setActive(2);
          }}
        />
      </div>

      <Form
        onSubmit={onSubmit}
        mutators={{
          ...arrayMutators,
        }}
        initialValues={initialValues}
        render={(formRenderProps) => {
          const { form } = formRenderProps;

          formApi.current = form;

          return active === 1 ? (
            <WeeklyHoursForm formRenderProps={formRenderProps} />
          ) : (
            <CustomAvailabilityForm formRenderProps={formRenderProps} />
          );
        }}
      />
    </Modal>
  );
};

export default AvailabilityModal;
