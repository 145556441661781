import chatSessions from 'api/chat/chatSessions';
import useInfinitePagination from 'hooks/useInfinitePagination';
import { ChatSession } from 'models/Chat';
import { PaginationParams } from 'models/Pagination';
import { useCallback } from 'react';

const CHAT_ITEM_TAKE = 15;

const useChatItemSearch = () => {
  const searchInbox = useCallback(async (currentPage: number, take: number) => {
    const queryParams: PaginationParams = {
      $relations: 'participants.user.profileImage,participants.user.status',
      $take: take,
      $skip: (currentPage - 1) * take,
      $order: '-updatedAt',
    };

    const {
      data: { items, totalItems, totalPages },
    } = await chatSessions.getChatSessions(queryParams);

    return { items, totalItems, totalPages } as const;
  }, []);

  const pagination = useInfinitePagination<ChatSession>({
    take: CHAT_ITEM_TAKE,
    makeRequest: searchInbox,
    debounceTime: 500,
  });

  return pagination;
};

export default useChatItemSearch;
