import bus, { AuthEventTypes } from 'modules/bus';
import { useEffect } from 'react';
import authService from 'services/authService';

export default function useLogoutSignal() {
  useEffect(() => {
    const removeListener = bus.addEventListener(
      AuthEventTypes.LOGOUT_SIGNAL,
      authService.logout,
    );

    return () => {
      removeListener();
    };
  }, []);
}
