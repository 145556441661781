import React, { useCallback, useContext, useMemo } from 'react';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import { Button } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import bus from 'modules/bus';

const useKycVerifyPrompt = () => {
  const { t } = useTranslation();
  const { currentUser } = useContext(CurrentUserContext);

  const { hasVerifiedIdentity } = currentUser || {};

  const openVerifyModal = useCallback(() => {
    if (hasVerifiedIdentity) return false;

    bus.broadcastEvent('TOGGLE_KYC_MODAL', true);

    return true;
  }, [hasVerifiedIdentity]);

  const verifyButton = useMemo(
    () =>
      !hasVerifiedIdentity ? (
        <Button onClick={openVerifyModal}> {t('General.verify')} </Button>
      ) : null,
    [hasVerifiedIdentity, openVerifyModal, t],
  );

  return {
    hasVerifiedIdentity,
    verifyButton,
    openVerifyModal,
  };
};

export default useKycVerifyPrompt;
