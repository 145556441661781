import {
  BOOST_VIEW_TYPE,
  BoostType,
  BoostViewType,
  JOB_POST_BOOST_TYPE,
  NO_DESIGN_BOOST,
} from 'models/Boosts';

export const BOOST_VIEW_COIN_COST = {
  [BOOST_VIEW_TYPE.VIEW]: 0.1,
  [BOOST_VIEW_TYPE.HOT_VIEW]: 0.01,
} as const;

export const BOOST_VIEW_UNIT_COST: Record<BoostViewType, number> = {
  VIEW: 0.1,
  HOT_VIEW: 0.01,
} as const;

export const BOOST_POST_DESIGN_COIN_COST = {
  [NO_DESIGN_BOOST]: 0,
  [JOB_POST_BOOST_TYPE.SIZE_50]: 3,
  [JOB_POST_BOOST_TYPE.SIZE_100]: 4,
  [JOB_POST_BOOST_TYPE.PHOTO]: 6,
  [JOB_POST_BOOST_TYPE.VIDEO]: 10,
} as const;

export const BOOST_POST_DESIGN_UNIT_COST: Record<
  BoostType | typeof NO_DESIGN_BOOST,
  number
> = {
  [NO_DESIGN_BOOST]: 0,
  'Size-50': 3,
  'Size-100': 4,
  Photo: 6,
  Video: 10,
} as const;

export const getUnitCostForBoost = (
  boostType: BoostViewType | BoostType | typeof NO_DESIGN_BOOST,
) => {
  return (
    BOOST_VIEW_UNIT_COST[boostType as BoostViewType] ??
    BOOST_POST_DESIGN_UNIT_COST[boostType as BoostType]
  );
};

export const MIN_COIN_BUDGET = 0;
export const MAX_COIN_BUDGET = 100000;

export const coinToMoneyInUnits = (coinAmount: number) => coinAmount;
