import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import Card from 'components/Card';
import RadioGroupField from 'components/RadioGroupField';
import ToolTip from 'components/ToolTip';
import { BooleanString, Job, JobNegotiableSections, JobPost } from 'models/Job';
import { JobFreeCancelation } from 'models/Job/FreeCancelation';
import CheckboxField from 'components/CheckboxField';
import InputField from 'components/InputField';
import { FormApi } from 'final-form';
import GradientText from 'components/GradientText';
import PercentIcon from 'icons/Percent.icon';
import formValidators from 'utils/formValidators';
import { useTranslation } from 'react-i18next';
import { formatMoney } from 'utils/currency';
import { Currency } from 'constants/currency';
import {
  getFreeCancellationChanges,
  isPendingChanges,
} from 'utils/job-changes';
import JobChanges from 'components/JobChanges';
import WarningIcon from 'icons/Warning.icon';

import './CancelationPolicy.styles.scss';
import ReactPlayer from 'react-player';

const parseToNumber = (val: string) =>
  val ? (Number.parseFloat(val) as any) : val;

type CancelationPolicyProps = {
  className?: string;
  valuesFromForm: JobFreeCancelation & {
    hasFreeCancelation: BooleanString;
  };
  initialFreeCancelation: JobFreeCancelation;
  formApi: FormApi<JobPost<'form'>>;
  disabled?: boolean;
  jobPriceAmount: number;
  currency: Currency;
  getLockButton: (sectionName: JobNegotiableSections) => JSX.Element;
} & Pick<Job, 'prevVersion'> &
  Required<Pick<Job, 'versionState' | 'isLockedFreeCancelation'>>;

const CancelationPolicy: React.FC<CancelationPolicyProps> = (props) => {
  const {
    className,
    getLockButton,
    valuesFromForm,
    formApi,
    disabled,
    jobPriceAmount,
    currency,
    prevVersion,
    initialFreeCancelation,
    versionState,
    isLockedFreeCancelation,
  } = props;

  const { t } = useTranslation();

  const {
    hasFreeCancelation: hasCancelation,
    isStrict,
    cancelationFee,
  } = valuesFromForm;

  const hasFreeCancelation = hasCancelation === 'true';

  const classes = classNames('anys-cancelation-policy', className);

  const messages = useMemo(
    () => ({
      title: t('JobForm.freeCancellationPolicy'),
      isStrict: t('General.strict'),
      strictParagraph: t('JobForm.strictCancellationMessage'),
      freeCancelation: t('JobForm.freeCancellation'),
      clientCancel: t('JobForm.clientCancel'),
      beforeJobStarts: t('JobForm.beforeJobStarts'),
      hours: t('General.hours'),
      until: t('General.until'),
      hourRequired: t('General.hourRequired'),
      percentRequired: t('General.percentRequired'),
      cancellationFee: t('General.cancellationFee'),
    }),
    [t],
  );

  const cancelationPolicyOptions: { label: string; value: BooleanString }[] =
    useMemo(
      () => [
        { label: t('General.yes'), value: 'true' },
        { label: t('General.no'), value: 'false' },
      ],
      [t],
    );

  const lockButton = useMemo(
    () => getLockButton('isLockedFreeCancelation'),
    [getLockButton],
  );

  const cancellationAmount =
    jobPriceAmount && cancelationFee
      ? jobPriceAmount * (cancelationFee / 100)
      : null;

  const formattedCancellationAmount = cancellationAmount
    ? formatMoney(t, { amount: cancellationAmount, currency })
    : null;

  const cancellationChanges =
    prevVersion && isPendingChanges(versionState)
      ? getFreeCancellationChanges(
          t,
          {
            hasFreeCancelation: `${prevVersion.hasFreeCancelation}`,
            freeCancelation: prevVersion.freeCancelation,
          },
          {
            freeCancelation: valuesFromForm,
            hasFreeCancelation: valuesFromForm.hasFreeCancelation,
          },
          prevVersion.isLockedFreeCancelation,
          isLockedFreeCancelation,
          false,
        )
      : null;

  const handleCancelationPolicyChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const hasCancelation = event.target.value as BooleanString;

      if (hasCancelation === 'true') {
        formApi.change('freeCancelation', undefined);
      } else {
        formApi.change('freeCancelation', initialFreeCancelation);
      }
    },
    [formApi, initialFreeCancelation],
  );

  const onChangeStrict = useCallback(
    (isStrict: boolean | string | number) => {
      const { hasFreeCancelation, ...restOfValues } = valuesFromForm;

      const strict = isStrict as boolean;

      if (strict) {
        formApi.change('freeCancelation', {
          ...restOfValues,
          cancelationFee: undefined,
          cancelationHours: undefined,
          isStrict: strict,
        });
      } else {
        formApi.change('freeCancelation', {
          ...restOfValues,
          ...(initialFreeCancelation?.isStrict
            ? {
                cancelationFee: undefined,
                cancelationHours: undefined,
              }
            : initialFreeCancelation),
          isStrict: strict,
        });
      }
    },
    [formApi, initialFreeCancelation, valuesFromForm],
  );

  return (
    <Card
      className={classes}
      cardTitle={
        <div className="anys-cancelation-policy__title">
          {messages.title}
          {cancellationChanges ? (
            <ToolTip
              t={t}
              tooltipName="client-cancellation-changes"
              icon={WarningIcon}
              className="anys-cancelation-policy__title__changes"
            >
              <JobChanges
                changedFrom={cancellationChanges.changedFrom}
                changedTo={cancellationChanges.changedTo}
              />
            </ToolTip>
          ) : null}
          <div className="anys-cancelation-policy__row">
            {lockButton}
            <ToolTip t={t} tooltipName="client-cancellation-tooltip">
              <ReactPlayer width="100%" url="https://youtu.be/BLHiia2NC0c" />
              <p style={{ whiteSpace: 'pre-line' }}>
                {t('Preview.clientCancellation')}
              </p>
            </ToolTip>
          </div>
        </div>
      }
    >
      <RadioGroupField
        name="hasFreeCancelation"
        options={cancelationPolicyOptions}
        direction="row"
        onChange={handleCancelationPolicyChange}
        className="anys-cancelation-policy__has-cancelation-radio"
        disabled={disabled}
      />
      {!hasFreeCancelation && (
        <>
          <CheckboxField
            name="freeCancelation.isStrict"
            label={
              <span className="anys-cancelation-policy__is-strict__label">
                <span>{messages.isStrict}</span>
                <span>{messages.strictParagraph}</span>
              </span>
            }
            defaultValue={false as any}
            className="anys-cancelation-policy__is-strict"
            onChange={onChangeStrict}
            disabled={disabled}
          />
          {!isStrict && (
            <>
              <div className="anys-cancelation-policy__input-wrapper">
                <label>{messages.freeCancelation}</label>
                <div className="anys-cancelation-policy__input-wrapper__input">
                  <span>{messages.until}</span>
                  <InputField
                    isPureNumberInput
                    type="number"
                    placeholder={messages.hours}
                    name="freeCancelation.cancelationHours"
                    parse={parseToNumber}
                    className="anys-cancelation-policy__input-wrapper__input__cancelation-hours"
                    min="0"
                    validate={formValidators.required(messages.hourRequired)}
                    disabled={disabled}
                  />
                  <GradientText
                    gradientType="pink"
                    className="anys-cancelation-policy__input-wrapper__input__hour"
                  >
                    h
                  </GradientText>
                  <span>{messages.beforeJobStarts}</span>
                </div>
              </div>
              <div className="anys-cancelation-policy__fee-wrapper">
                <div className="anys-cancelation-policy__input-wrapper">
                  <label>{messages.clientCancel}</label>
                  <div className="flex ac">
                    <InputField
                      isPureNumberInput
                      type="number"
                      name="freeCancelation.cancelationFee"
                      parse={parseToNumber}
                      suffixNode={<PercentIcon />}
                      className="anys-cancelation-policy__input-wrapper__cancelation-fee"
                      validate={formValidators.required(
                        messages.percentRequired,
                      )}
                      min="0"
                      disabled={disabled}
                    />
                    {t('JobForm.fromJobValue')}.
                  </div>
                </div>

                {formattedCancellationAmount && (
                  <div className="anys-cancelation-policy__fee-wrapper__fee-amount">
                    <span>{messages.cancellationFee}</span>

                    <GradientText gradientType="pink">
                      {formattedCancellationAmount}
                    </GradientText>
                  </div>
                )}
              </div>
            </>
          )}
        </>
      )}
    </Card>
  );
};

export default CancelationPolicy;
