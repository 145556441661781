import { TFunction } from 'i18next';
import { ArbitratorFilters } from 'models/Job/Arbitration';

const formatArbitrationFilterValue = (
  t: TFunction,
  fieldName: keyof ArbitratorFilters,
  values: Partial<ArbitratorFilters>,
  skillLabels: string,
) => {
  if (!values) return '-';

  const valueWithoutType = values[fieldName];

  // If we have a field value with
  // the number 0, we want to let it through
  if (valueWithoutType === null || valueWithoutType === undefined) return '-';
  if (Array.isArray(valueWithoutType) && !valueWithoutType.length) return '-';

  switch (fieldName) {
    case 'language': {
      const fieldValue = values[fieldName];

      return fieldValue;
    }

    case 'rating': {
      const fieldValue = values[fieldName];

      return Number.parseInt(fieldValue) < 10
        ? `${fieldValue}+`
        : `=${fieldValue}`;
    }
    case 'numberOfJobs': {
      const fieldValue = values[fieldName];

      return `${fieldValue}+`;
    }

    case 'reviewedAsAClient': {
      const fieldValue = values[fieldName];

      return fieldValue === '5'
        ? `=${fieldValue}`
        : fieldValue === '0'
          ? t('General.all')
          : `${fieldValue}+`;
    }
    case 'skills': {
      const fieldValue = values[fieldName];

      return skillLabels || fieldValue.join(', ');
    }

    case 'userType': {
      const fieldValue = values[fieldName];

      return fieldValue
        .map((userRole) => t(`General.${userRole.toLowerCase()}`))
        .join(', ');
    }

    case 'userLocation': {
      const fieldValue = values[fieldName];

      return fieldValue?.country || '-';
    }

    case 'ages': {
      const fieldValue = values[fieldName];

      return `${fieldValue.fromAge} - ${fieldValue.toAge}`;
    }

    default:
      break;
  }

  return '-';
};

export { formatArbitrationFilterValue };
