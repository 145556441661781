import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'ncoded-component-library';
import CountdownTimer from 'components/CountdownTimer';
import ExclamationIcon from 'icons/Exclamation.icon';
import arbitration from 'api/arbitration';
import classNames from 'classnames';
import dayjs from 'dayjs';
import Notice from 'components/Notice';
import { useArbitration } from 'providers/Arbitration/Arbitration.provider';
import OverlaySpinner from 'components/OverlaySpinner';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import showToast from 'modules/showToast';
import { loadStripe } from '@stripe/stripe-js';
import env from 'env';
import { Wallet } from 'models/Wallet';
import api from 'api';
import { formatNumber } from 'utils/currency';
import useCallbackRef from 'hooks/useCallbackRef';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import Modal from 'components/Modal';
import { Elements } from '@stripe/react-stripe-js';
import AddDepositModal from 'components/AddDepositModal';

import './PendingFunds.styles.scss';
import './PendingFunds.styles.responsive.scss';

const stripePromise = loadStripe(env.STRIPE_KEY);

type PendingFundsProps = {
  className?: string;
};
const PendingFunds = (props: PendingFundsProps) => {
  const { className } = props;

  const classes = classNames('anys-pending-funds', className);

  const { selectedArbitration, setSelectedArbitration } = useArbitration();

  const { t } = useTranslation();

  const [wallet, setWallet] = useState<Wallet>();

  const [uploadFundsModal, setUploadFundsModal] = useState(false);

  const {
    lastTransitionedAt,
    id,
    contract,
    actionTimers,
    arbitrationPrice: arbitrationPriceValue = 88800,
  } = selectedArbitration;

  const timeLeftForVerification = useMemo(() => {
    const date1 = dayjs(new Date());
    const date2 = dayjs(lastTransitionedAt);

    //TODO: replace this hours
    const newLimitDate = date2.add(48, 'minutes');

    return newLimitDate.diff(date1, 'milliseconds');
  }, [lastTransitionedAt]);

  const arbitrationPrice = useMemo(
    () => arbitrationPriceValue / 100,
    [arbitrationPriceValue],
  );

  const getWallet = useCallback(async () => {
    try {
      const { data } = await api.wallet.getWallet();
      setWallet(data);
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    getWallet();
  }, [getWallet]);

  const [balance, setBalance] = useState(0);

  useEffect(() => {
    setBalance(
      wallet?.funds.find((fund) => fund.currency.toUpperCase() === 'USD')
        ?.balance / 100 || 0,
    );
  }, [wallet?.funds]);

  const [createAdOfferModal, createAdOfferModalRef] =
    useCallbackRef<ModalRef>(null);

  const handleUploadFunds = async () => {
    try {
      OverlaySpinner.show('.anys-arbitration-page__main');

      const { data } = await arbitration.addFunds(id);

      if (data) {
        const { data } = await arbitration.getArbitration(id);

        if (data) {
          setSelectedArbitration(data);
        }
      }
    } catch (e) {
      const err = e as AxiosError;

      showToast(
        'error',
        t('Arbitration.couldNotCapturePayment'),
        err?.response?.data?.error?.message,
      );

      console.error(e);
    } finally {
      OverlaySpinner.hide('.anys-arbitration-page__main');
    }
  };

  return (
    <div className={classes}>
      <ExclamationIcon />
      <h1>
        {t('Arbitration.arbitrationForContract')} <br /> {contract?.title}{' '}
        {t('Arbitration.isStarting')}
      </h1>
      {actionTimers?.length !== 0 ? (
        <>
          <span className="anys-pending-funds__subtitle">
            {t('Arbitration.pleaseUploadTheFunds', {
              amount: arbitrationPrice,
            })}
          </span>

          <div className="anys-pending-funds__timer">
            <CountdownTimer startTimeInMS={timeLeftForVerification} />
          </div>

          <Button
            // onClick={handleUploadFunds}
            onClick={() => {
              setUploadFundsModal(true);
            }}
            className="anys-pending-funds__upload-btn"
          >
            {t('General.upload')}
          </Button>

          <span className="anys-pending-funds__subtitle">
            {t('Arbitration.ifYouDontUploadFunds')}
          </span>

          <Modal
            type="no-action"
            modalName="buy-anyCoins"
            open={uploadFundsModal}
            isDrawer
            title="Upload funds"
            addSearchParams={false}
            onClose={() => setUploadFundsModal(false)}
          >
            <div className="anys-pending-funds__total-upload">
              {t('Wallet.uploadFundsToStartArbitration', {
                arbitrationPrice: arbitrationPrice,
              })}
            </div>
            <div>
              {t('Wallet.currentBalance', {
                currentBalance: formatNumber(t, balance, { currency: 'USD' }),
              })}
            </div>
            {balance < arbitrationPrice ? (
              <Button
                onClick={() => {
                  createAdOfferModal.open();
                }}
                className="anys-pending-funds__total-upload__btn"
              >
                {t('Wallet.addDeposit')}
              </Button>
            ) : (
              <Button
                className="anys-pending-funds__total-upload__btn"
                onClick={handleUploadFunds}
              >
                {t('General.upload')}
              </Button>
            )}
            <Elements
              stripe={stripePromise}
              options={{
                mode: 'setup',
                currency: 'usd',
              }}
            >
              <AddDepositModal
                addDepositModalRef={createAdOfferModalRef}
                onDepositMake={(value) => {
                  setBalance((old) => old + value);
                }}
                onClose={() => createAdOfferModal.close()}
                initialValueForDeposit={arbitrationPriceValue - balance * 100}
                returnUrlOnConfirmSetup={`${window.location.origin}/arbitration?arbitrationId=${id}`}
              />
            </Elements>
          </Modal>
        </>
      ) : (
        <Notice type="default">
          {t('Arbitration.youAlreadyUploadedFunds')}
        </Notice>
      )}
    </div>
  );
};

export default PendingFunds;
