import { AxiosError } from 'axios';
import { Money } from 'constants/currency';
import { toast } from 'react-toastify';

function isTouchDevice(): boolean {
  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
  const mq = function (query: string) {
    return window.matchMedia(query).matches;
  };

  if ('ontouchstart' in window || 'DocumentTouch' in window) {
    return true;
  }

  const query = ['(', prefixes.join('touch-enabled),('), 'ncoded', ')'].join(
    '',
  );
  return mq(query);
}

export const parseStringValue = (value: any) =>
  /^\d+$/.test(value)
    ? +value
    : /^(true|false)$/.test(value)
    ? JSON.parse(value)
    : value;

export function convertFormDataToJSONObject(formData: FormData) {
  const obj: any = {};
  formData.forEach((val, key) => {
    const isArray = key.includes('[]') || key.includes('files');

    if (isArray) {
      const newKey = key.split('[]')[0];
      if (!obj[newKey]) obj[newKey] = [];
      obj[newKey].push(parseStringValue(val));
    } else {
      obj[key] = parseStringValue(val);
    }
  });
  return obj;
}

export const convertObjToFormData = (
  obj: Record<string, any>,
  formData = new FormData(),
  path = '',
) => {
  if (obj === undefined) return;

  for (const prop in obj) {
    const newPath = path ? `${path}[${prop}]` : prop;
    if (typeof obj[prop] !== 'object') {
      if (obj[prop] instanceof File) {
        const file: File = obj[prop];
        formData.append(newPath, file, file.name);
      } else {
        formData.append(newPath, obj[prop]);
      }
    } else if (obj[prop] === null) {
      formData.append(newPath, obj[prop]);
    } else {
      convertObjToFormData(obj[prop], formData, newPath);
    }
  }

  return formData;
};

export function debounce(
  func: (...args: any[]) => void,
  wait: number,
  isImmediate = false,
) {
  let timeout: NodeJS.Timeout | null;
  return function (...args: any[]) {
    const later = () => {
      timeout = null;
      if (!isImmediate) {
        func(...args);
      }
    };
    const callNow = isImmediate && !timeout;
    if (timeout) clearTimeout(timeout);
    timeout = global.setTimeout(later, wait);
    if (callNow) {
      func(...args);
    }
  };
}

const replaceCharAt = (
  base: string,
  indexToReplace: number,
  newChar: string | number,
) => {
  if (indexToReplace > base.length - 1) {
    return base;
  }

  return (
    base.substring(0, indexToReplace) +
    newChar +
    base.substring(indexToReplace + 1)
  );
};

const isAxiosError = (e: AxiosError | unknown): e is AxiosError =>
  'message' in (e as AxiosError) || 'response' in (e as AxiosError);

function toastError(e: AxiosError | unknown) {
  if (isAxiosError(e)) {
    toast.error(e.message || e?.response.data?.message);
    return;
  }

  toast.error('Error');
}

const ENTER_KEY_CODE = 13;

function isEnterPressed<T>(event: React.KeyboardEvent<T>) {
  return (
    event.key === 'Enter' ||
    event.keyCode === ENTER_KEY_CODE ||
    event.which === ENTER_KEY_CODE
  );
}

const BACKSPACE_KEY_CODE = 8;

function isBackspacePressed<T>(event: React.KeyboardEvent<T>) {
  return (
    event.key === 'Backspace' ||
    event.keyCode === BACKSPACE_KEY_CODE ||
    event.which === BACKSPACE_KEY_CODE
  );
}

const DELETE_KEY_CODE = 46;

function isDeletePressed<T>(event: React.KeyboardEvent<T>) {
  return (
    event.key === 'Delete' ||
    event.keyCode === DELETE_KEY_CODE ||
    event.which === DELETE_KEY_CODE
  );
}

const ARROW_KEY_CODES = {
  UP: 38,
  DOWN: 40,
  RIGHT: 39,
  LEFT: 37,
} as const;

function isArrowPressed<T>(
  event: React.KeyboardEvent<T>,
  direction: 'up' | 'right' | 'down' | 'left',
) {
  switch (direction) {
    case 'up':
      return (
        event.key === 'ArrowUp' ||
        event.keyCode === ARROW_KEY_CODES.UP ||
        event.which === ARROW_KEY_CODES.UP
      );

    case 'down':
      return (
        event.key === 'ArrowDown' ||
        event.keyCode === ARROW_KEY_CODES.DOWN ||
        event.which === ARROW_KEY_CODES.DOWN
      );

    case 'right':
      return (
        event.key === 'ArrowRight' ||
        event.keyCode === ARROW_KEY_CODES.RIGHT ||
        event.which === ARROW_KEY_CODES.RIGHT
      );

    default:
      break;
  }

  return (
    event.key === 'ArrowLeft' ||
    event.keyCode === ARROW_KEY_CODES.LEFT ||
    event.which === ARROW_KEY_CODES.LEFT
  );
}

function camelize(str: string) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
}

const ASCII_CODE_A = 65;

function convertArrayIndexToAlphabet(arrayIndex: number) {
  return String.fromCharCode(ASCII_CODE_A + arrayIndex);
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
function noop(): any {}

const selectUtils = {
  transformObjValue: (value?: string | number | Record<string, any>) => {
    if (!value) return '';

    if (typeof value !== 'object') return value;

    return JSON.stringify(value);
  },

  transformValueBack: (value: string | number) => {
    try {
      return JSON.parse(`${value}`) as string | number;
    } catch (er) {
      // JSON.parse can't parse plain string
      // thus we just return `value`
      return value;
    }
  },
};

export const createFile = (
  bits: BlobPart[],
  name: string,
  type: string,
): Blob | File => {
  try {
    // If this call fails, we go for Blob
    return new File(bits, name, { type });
  } catch (e) {
    // If we reach this point a new File could not be constructed
    const myBlob = new Blob(bits, { type });
    (myBlob as any).lastModified = new Date();
    (myBlob as any).name = name;

    return myBlob;
  }
};

const prepareFilesForUpload = (files: File[], formDataKey: string) => {
  const formData = new FormData();

  if (!files?.length) return formData;

  files.forEach((file) => {
    let newFile: File | Blob = file;

    // There is a name length validation on BE
    if (file.name.length > 50) {
      const extensionIndex = file.name.lastIndexOf('.');
      const fileExtension = file.name.substring(extensionIndex);
      const nameWithoutExtension = file.name.substring(0, extensionIndex);

      const cutFileName =
        nameWithoutExtension.substring(0, 50 - fileExtension.length) +
        fileExtension;

      newFile = createFile([file], cutFileName, file.type);
    }

    formData.append(formDataKey, newFile);
  });

  return formData;
};

function tryParse(value: string) {
  try {
    return JSON.parse(value);
  } catch (er) {
    return value;
  }
}

export function isJSONString(value: any) {
  try {
    JSON.parse(value);

    return (
      typeof value === 'string' &&
      value.startsWith('[{') &&
      value.endsWith('}]')
    );
  } catch (error) {
    return false;
  }
}

function camelToDashCase(value: string) {
  return value.replace(/([A-Z])/g, '-$1').toLowerCase();
}

function dashToCamelCase(value: string) {
  return value.replace(/(-[a-z])/g, '$1').toUpperCase();
}

const wait = async (time: number) =>
  new Promise((resolve) => setTimeout(resolve, time));

function getIntegerFromDottedNumber(value: string) {
  if (!value) return;
  return parseFloat(value.replace(/,/g, ''));
}

function getDotedNumber(value: string) {
  return value
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    .split('.')
    .map((str, ind) => (ind > 0 ? str.replace(/,/g, '') : str))
    .join('.');
}

function getUnitValue(value: string | number | Money) {
  if (typeof value === 'string')
    return getIntegerFromDottedNumber(value as string);
  else if (typeof value === 'number') return getDotedNumber(value.toString());
  else if ('amount' in value) {
    return getDotedNumber((value.amount / 100).toString());
  }
}

async function getThumbnailForVideo(videoUrl: string, timePercent = 0) {
  const video = document.createElement('video');
  const canvas = document.createElement('canvas');
  video.style.display = 'none';
  canvas.style.display = 'none';

  // Trigger video load
  await new Promise<void>((resolve, reject) => {
    video.addEventListener('loadedmetadata', () => {
      video.width = video.videoWidth;
      video.height = video.videoHeight;
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      // Seek the video to time percent (e.g. 25)
      video.currentTime = video.duration * timePercent;
    });
    video.addEventListener('seeked', () => resolve());
    video.src = videoUrl;
  });

  // Draw the thumbnailz
  canvas
    .getContext('2d')
    .drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

  const imageUrl = canvas.toDataURL('image/png');

  return imageUrl;
}

export const capitalizeFirstLetter = (str: string) => {
  if (!str || typeof str !== 'string') return null;

  return str.charAt(0).toUpperCase() + str.slice(1);
};

const humanizeIsoDate = (isoString?: string) => {
  if (!isoString) return null;

  const rawDate = new Date(isoString);
  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  }).format(rawDate);
};

export default {
  noop,
  toastError,
  tryParse,
  isTouchDevice,
  parseStringValue,
  convertObjToFormData,
  convertFormDataToJSONObject,
  debounce,
  isEnterPressed,
  isBackspacePressed,
  isDeletePressed,
  isArrowPressed,
  camelize,
  replaceCharAt,
  convertArrayIndexToAlphabet,
  camelToDashCase,
  getUnitValue,
  getIntegerFromDottedNumber,
  getDotedNumber,
  dashToCamelCase,
  wait,
  prepareFilesForUpload,
  getThumbnailForVideo,
  selectUtils,
  humanizeIsoDate,
};
