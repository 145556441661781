import React from 'react';
import classNames from 'classnames';
import './MainBoost.styles.scss';
import './MainBoost.styles.responsive.scss';
import { Boost } from 'models/Boosts';
import ResponsiveImage from 'components/ResponsiveImage';
import ResponsiveVideo from 'components/ResponsiveVideo';

type MainBoostProps = {
  className?: string;
  boost: Boost;
};

const MainBoost: React.FC<MainBoostProps> = (props) => {
  const { boost, className } = props;

  const classes = classNames('anys-main-boost', className);

  if ('file' in boost) {
    const { file } = boost;
    if ('resizedUrls' in file) {
      return (
        <ResponsiveImage
          className={classes}
          alt={file.name}
          resizedVariants={file.resizedUrls}
        />
      );
    } else {
      return <ResponsiveVideo className={classes} src={file.url} />;
    }
  }

  return null;
};

export default MainBoost;
