import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import mergeRefs from './utils/mergeRefs';
import { Button } from 'ncoded-component-library';
import ChevronIcon from 'icons/ChervonIcon.icon';
import TimesIcon from 'icons/Times.icon';
import dates from 'utils/dates';
import { useTranslation } from 'react-i18next';
import { Timeslot } from 'models/Availability';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import './Calendar.styles.scss';

dayjs.extend(utc);
dayjs.extend(timezone);

export type CalendarProps = {
  value?: Dayjs | Dayjs[];
  className?: string;
  leftLimitDate?: Dayjs;
  rightLimitDate?: Dayjs;
  allowRange?: boolean;
  onChange?: any;
  withoutDays?: boolean;
  hints?: Dayjs[];
  hasSubmitButton?: boolean;
  hasCloseButton?: boolean;
  timeslots?: Timeslot[];
  timezone?: string;
  onClose?: () => void;
  onDone?: () => void;
};

enum DAY {
  MON,
  TUE,
  WED,
  THU,
  FRI,
  SAT,
  SUN,
}

const mapIndexesToDays: Record<number, DAY> = {
  0: DAY.MON,
  1: DAY.TUE,
  3: DAY.WED,
  4: DAY.THU,
  5: DAY.FRI,
  6: DAY.SAT,
  7: DAY.SUN,
};

enum CalendarViewMode {
  BASIC,
  YEARS,
  MONTHS,
}

const Calendar = React.forwardRef((props: CalendarProps, ref): JSX.Element => {
  const {
    value,
    className,
    leftLimitDate,
    rightLimitDate,
    allowRange,
    onChange,
    withoutDays = false,
    hints,
    hasSubmitButton = true,
    hasCloseButton = false,
    timeslots,
    timezone,
    onClose,
    onDone,
  } = props;

  const calendarRef = useRef<HTMLTableElement>(null);

  const { t } = useTranslation();

  const dayObj = useMemo(() => {
    const dayjsObj = dayjs();
    return {
      month: dayjsObj.month() + 1,
      year: dayjsObj.year(),
      date: dayjsObj.date(),
    };
  }, []);

  const actualDate = useMemo(() => {
    if (value && !Array.isArray(value)) {
      return {
        month: value.month() + 1,
        year: value.year(),
        date: value.date(),
      };
    } else {
      return dayObj;
    }
  }, [dayObj, value]);

  const dateDay = useMemo(() => actualDate.date, [actualDate.date]);

  const [month, setMonth] = useState(actualDate.month);
  const [year, setYear] = useState(actualDate.year);

  const [selectedDate, setSelectedDate] = useState<Dayjs | Dayjs[] | undefined>(
    allowRange ? (Array.isArray(value) ? value : []) : value,
  );
  const [firstYearInMatrix, setFirstYearInMatrix] = useState<number>(1);

  const [viewMode, setViewMode] = useState<CalendarViewMode>(
    withoutDays ? CalendarViewMode.YEARS : CalendarViewMode.BASIC,
  );

  const days = useMemo(
    () => [
      t('General.mo'),
      t('General.tu'),
      t('General.we'),
      t('General.th'),
      t('General.fr'),
      t('General.sa'),
      t('General.su'),
    ],
    [t],
  );

  const monthsShort = useMemo(
    () => [
      t('General.jan'),
      t('General.feb'),
      t('General.mar'),
      t('General.apr'),
      t('General.may'),
      t('General.jun'),
      t('General.jul'),
      t('General.aug'),
      t('General.sep'),
      t('General.oct'),
      t('General.nov'),
      t('General.dec'),
    ],
    [t],
  );

  const months = useMemo(
    () => [
      t('General.January'),
      t('General.February'),
      t('General.March'),
      t('General.April'),
      t('General.May'),
      t('General.June'),
      t('General.July'),
      t('General.August'),
      t('General.September'),
      t('General.October'),
      t('General.November'),
      t('General.December'),
    ],
    [t],
  );

  const firstDayOfMonth = useMemo(() => {
    const firstDay = dayjs(`${year}-${month}-${dateDay}`)
      .startOf('month')
      .day();
    return firstDay === 0 ? 7 : firstDay;
  }, [dateDay, month, year]);

  const daysInMonth = useMemo(
    () => dayjs(`${year}-${month}-${dateDay}`).daysInMonth(),
    [dateDay, month, year],
  );

  const weeksInMonth = useMemo(() => {
    let weeks = Math.ceil(daysInMonth / 7);
    if (daysInMonth === 28) weeks = 5;
    if ([DAY.SAT, DAY.SUN].includes(mapIndexesToDays[firstDayOfMonth])) {
      ++weeks;
    }
    return weeks;
  }, [daysInMonth, firstDayOfMonth]);

  const allowedDateRange = useMemo<number[]>(() => {
    const range = [0, 0];
    if (leftLimitDate) {
      const leftDiff = leftLimitDate.diff(dayjs(`${year}-${month}-01`), 'days');
      range[0] = leftDiff + 1;
    }
    if (rightLimitDate) {
      const rightDiff = rightLimitDate.diff(
        dayjs(`${year}-${month}-01`),
        'days',
      );
      range[1] = rightDiff + 1;
    }
    return range;
  }, [leftLimitDate, month, rightLimitDate, year]);

  const availableDatesFromTimeslots = useMemo(
    () =>
      timeslots?.length
        ? timeslots.reduce((available, slots) => {
            const { from } = slots;

            const date = dayjs(from).tz(timezone).format('YYYY-MM-DD');

            available[date] = true;

            return available;
          }, {} as Record<string, boolean>)
        : null,
    [timeslots, timezone],
  );

  const isDayOutsideOfTimeslot = useCallback(
    (actualDay: number) => {
      if (!availableDatesFromTimeslots) return false;

      const monthString = month > 9 ? month : `0${month}`;
      const dayString = actualDay > 9 ? actualDay : `0${actualDay}`;

      return !availableDatesFromTimeslots[
        `${year}-${monthString}-${dayString}`
      ];
    },
    [availableDatesFromTimeslots, month, year],
  );

  // RENDER DAY
  const renderDay = useCallback(
    (week: number, index: number) => {
      if (week === 0) {
        if (index < firstDayOfMonth) {
          return <td />;
        } else {
          const actualDay = index - firstDayOfMonth + 1;

          const isDisabled = isDayOutsideOfTimeslot(actualDay);

          return (
            <td
              onClick={() => {
                if (isDisabled) return;

                const newDate = dayjs(`${year}-${month}-${actualDay}`);
                if (allowRange) {
                  if ((selectedDate as Dayjs[])?.length === 1) {
                    const dateRange = dates.getDatesInRange(
                      (selectedDate as Dayjs[])?.[0],
                      newDate,
                    );

                    //When select one date and then new one in past, range need to start from that newDate
                    if (dateRange.length === 0) {
                      setSelectedDate([newDate]);
                      onChange?.([newDate]);
                    } else {
                      setSelectedDate(dateRange);
                      onChange?.(dateRange);
                    }
                  } else {
                    setSelectedDate([newDate]);
                    onChange?.([newDate]);
                  }
                } else {
                  setSelectedDate(newDate);
                  onChange?.(newDate);
                }
              }}
              id={`calendar-${actualDay}-${month}-${year}`}
              className={classNames(
                'anys-calendar__table__day',
                {
                  'anys-calendar__table__day--weekend': [6, 7].includes(index),
                },
                {
                  'anys-calendar__table__day--selected': !allowRange
                    ? actualDay === (selectedDate as Dayjs)?.date() &&
                      month === (selectedDate as Dayjs).month() + 1 &&
                      year === (selectedDate as Dayjs).year()
                    : (selectedDate as Dayjs[])?.filter(
                        (selectedDate: Dayjs) =>
                          actualDay === (selectedDate as Dayjs)?.date() &&
                          month === (selectedDate as Dayjs).month() + 1 &&
                          year === (selectedDate as Dayjs).year(),
                      ).length !== 0,
                },
                {
                  'anys-calendar__table__day--disabled':
                    isDisabled ||
                    !_.inRange(
                      actualDay,
                      allowedDateRange[0],
                      allowedDateRange[1] + 1,
                    ),
                },
                {
                  'anys-calendar__table__day--hint': hints?.find(
                    (selDate: Dayjs) =>
                      selDate.isSame(
                        dayjs(`${year}-${month}-${actualDay}`),
                        'day',
                      ),
                  ),
                },
              )}
            >
              {actualDay === dateDay &&
                month === actualDate.month &&
                year === actualDate.year && (
                  <div className="anys-calendar__table__day__current" />
                )}
              {actualDay}
            </td>
          );
        }
      }

      const actualDay = week * 7 + index - firstDayOfMonth + 1;

      const isDisabled = isDayOutsideOfTimeslot(actualDay);

      if (actualDay > daysInMonth) {
        return <td />;
      }
      return (
        <td
          onClick={() => {
            if (isDisabled) return;

            const newDate = dayjs(`${year}-${month}-${actualDay}`);
            if (allowRange) {
              if ((selectedDate as Dayjs[])?.length === 1) {
                const dateRange = dates.getDatesInRange(
                  (selectedDate as Dayjs[])?.[0],
                  newDate,
                );
                //When select one date and then new one in past, range need to start from that newDate
                if (dateRange.length === 0) {
                  setSelectedDate([newDate]);
                  onChange?.([newDate]);
                } else {
                  setSelectedDate(dateRange);
                  onChange?.(dateRange);
                }
              } else {
                setSelectedDate([newDate]);
                onChange?.([newDate]);
              }
            } else {
              setSelectedDate(newDate);
              onChange?.(newDate);
            }
          }}
          id={`calendar-${actualDay}-${month}-${year}`}
          className={classNames(
            'anys-calendar__table__day',
            {
              'anys-calendar__table__day--weekend': [6, 7].includes(index),
            },
            {
              'anys-calendar__table__day--selected': !allowRange
                ? actualDay === (selectedDate as Dayjs)?.date() &&
                  month === (selectedDate as Dayjs).month() + 1 &&
                  year === (selectedDate as Dayjs).year()
                : (selectedDate as Dayjs[])?.filter(
                    (selectedDate: Dayjs) =>
                      actualDay === (selectedDate as Dayjs)?.date() &&
                      month === (selectedDate as Dayjs).month() + 1 &&
                      year === (selectedDate as Dayjs).year(),
                  ).length !== 0,
            },
            {
              'anys-calendar__table__day--disabled':
                isDisabled ||
                !_.inRange(
                  actualDay,
                  allowedDateRange[0],
                  allowedDateRange[1] + 1,
                ),
            },

            {
              'anys-calendar__table__day--hint': hints?.find((selDate: Dayjs) =>
                selDate.isSame(dayjs(`${year}-${month}-${actualDay}`), 'day'),
              ),
            },
            {
              'anys-calendar__table__day__current':
                actualDay === dateDay &&
                month === actualDate.month &&
                year === actualDate.year,
            },
          )}
        >
          {/* {actualDay === dateDay &&
            month === actualDate.month &&
            year === actualDate.year && (
              <div className="anys-calendar__table__day__current" />
            )} */}
          {actualDay}
        </td>
      );
    },
    [
      firstDayOfMonth,
      isDayOutsideOfTimeslot,
      daysInMonth,
      month,
      year,
      allowRange,
      selectedDate,
      allowedDateRange,
      hints,
      dateDay,
      actualDate.month,
      actualDate.year,
      onChange,
    ],
  );

  // RENDER WEEKS
  const renderWeeks = useMemo<JSX.Element[]>(() => {
    return [...Array(weeksInMonth).keys()].map((week) => {
      return (
        <tr key={week}>
          {renderDay(week, 1)}
          {renderDay(week, 2)}
          {renderDay(week, 3)}
          {renderDay(week, 4)}
          {renderDay(week, 5)}
          {renderDay(week, 6)}
          {renderDay(week, 7)}
        </tr>
      );
    });
  }, [renderDay, weeksInMonth]);

  // RENDER YEARS
  const renderYears = useMemo<JSX.Element[]>(() => {
    return [...Array(5).keys()].map((row) => {
      const calculatedYear = row * 4 + firstYearInMatrix;
      return (
        <tr key={row}>
          {[...Array(4).keys()].map((column) => {
            return (
              <td
                key={`${row}${column}`}
                className={classNames(
                  'anys-calendar__table__year',
                  {
                    'anys-calendar__table__year--selected':
                      calculatedYear + column === year,
                  },
                  {
                    'anys-calendar__table__year--disabled': !_.inRange(
                      calculatedYear + column,
                      leftLimitDate
                        ? leftLimitDate.year()
                        : Number.MIN_SAFE_INTEGER,
                      rightLimitDate ? rightLimitDate.year() + 1 : undefined,
                    ),
                  },
                )}
                onClick={() => {
                  setYear(calculatedYear + column);
                  setViewMode(CalendarViewMode.MONTHS);
                }}
              >
                {calculatedYear + column}
              </td>
            );
          })}
        </tr>
      );
    });
  }, [firstYearInMatrix, leftLimitDate, rightLimitDate, year]);

  // RENDER MONTHS
  const renderMonths = useMemo<JSX.Element[]>(() => {
    return [...Array(3).keys()].map((row) => {
      return (
        <tr key={row}>
          {[...Array(4).keys()].map((column) => {
            const calculatedMonth = row * 4 + column;
            return (
              <td
                key={`${row}${column}`}
                className={classNames(
                  'anys-calendar__table__month',
                  {
                    'anys-calendar__table__month--selected': !allowRange
                      ? year === (selectedDate as Dayjs)?.year() &&
                        calculatedMonth === (selectedDate as Dayjs).month()
                      : (selectedDate as Dayjs[])?.filter(
                          (selectedDate: Dayjs) =>
                            selectedDate?.year() &&
                            calculatedMonth === selectedDate.month(),
                        ).length !== 0,
                  },

                  {
                    'anys-calendar__table__month--disabled':
                      !_.inRange(
                        year,
                        leftLimitDate
                          ? leftLimitDate.year()
                          : Number.MIN_SAFE_INTEGER,
                        rightLimitDate ? rightLimitDate.year() + 1 : undefined,
                      ) ||
                      (leftLimitDate?.year() === year
                        ? calculatedMonth < leftLimitDate.month()
                        : rightLimitDate?.year() === year
                        ? calculatedMonth > rightLimitDate.month()
                        : false),
                  },
                )}
                onClick={() => {
                  setMonth(calculatedMonth + 1);
                  if (!withoutDays) {
                    setViewMode(CalendarViewMode.BASIC);
                  } else {
                    setSelectedDate(dayjs(`${year}-${calculatedMonth + 1}`));
                    onChange?.(dayjs(`${year}-${calculatedMonth + 1}`));
                  }
                }}
              >
                {monthsShort[calculatedMonth]}
              </td>
            );
          })}
        </tr>
      );
    });
  }, [
    allowRange,
    year,
    selectedDate,
    leftLimitDate,
    rightLimitDate,
    monthsShort,
    withoutDays,
    onChange,
  ]);

  useEffect(() => {
    let yearIndex = year % 20;
    if (yearIndex === 0) {
      yearIndex = 20;
    }
    setFirstYearInMatrix(year - yearIndex + 1);
  }, [year]);

  const calendar = useMemo(() => {
    return (
      <div className="anys-calendar">
        <div className="anys-calendar__head">
          {hasCloseButton && (
            <Button
              className="anys-calendar__head__close-btn"
              variant="icon"
              onClick={onClose}
              icon={<TimesIcon gradient={true} />}
            />
          )}

          <Button
            className="anys-calendar__table__head__commands__command"
            icon={<ChevronIcon gradient={true} />}
            variant="icon"
            iconPosition="right"
            disabled={
              viewMode === CalendarViewMode.YEARS
                ? firstYearInMatrix === 1960
                : year === 2001
            }
            onClick={(ev) => {
              if (viewMode === CalendarViewMode.BASIC) {
                if (month > 1) {
                  setMonth(month - 1);
                } else {
                  setMonth(12);
                  setYear(year - 1);
                }
              } else if (viewMode === CalendarViewMode.YEARS) {
                setFirstYearInMatrix(firstYearInMatrix - 20);
              } else {
                setYear(year - 1);
              }
            }}
          />
          <span
            className="anys-calendar__head__month"
            onClick={() => {
              if (viewMode === CalendarViewMode.BASIC) {
                setViewMode(CalendarViewMode.YEARS);
              } else if (viewMode === CalendarViewMode.YEARS) {
                setViewMode(
                  withoutDays
                    ? CalendarViewMode.MONTHS
                    : CalendarViewMode.BASIC,
                );
              } else {
                setViewMode(CalendarViewMode.YEARS);
              }
            }}
          >
            {viewMode === CalendarViewMode.BASIC
              ? `${months[month - 1]} ${year}`
              : viewMode === CalendarViewMode.YEARS
              ? `${firstYearInMatrix}-${firstYearInMatrix + 19}`
              : year}
            <ChevronIcon gradient={true} />
          </span>
          <Button
            className="anys-calendar__table__head__commands__command"
            icon={<ChevronIcon gradient={true} />}
            variant="icon"
            disabled={
              viewMode === CalendarViewMode.YEARS
                ? firstYearInMatrix === 2021
                : year === 2040
            }
            onClick={(ev) => {
              if (viewMode === CalendarViewMode.BASIC) {
                if (month < 12) {
                  setMonth(month + 1);
                } else {
                  setMonth(1);
                  setYear(year + 1);
                }
              } else if (viewMode === CalendarViewMode.YEARS) {
                setFirstYearInMatrix(firstYearInMatrix + 20);
              } else {
                setYear(year + 1);
              }
            }}
          />
        </div>
        <div
          className={classNames(
            'anys-calendar__table',
            {
              'anys-calendar__table--years':
                viewMode === CalendarViewMode.YEARS,
            },
            {
              'anys-calendar__table--months':
                viewMode === CalendarViewMode.MONTHS,
            },
            className,
          )}
          ref={mergeRefs(calendarRef, ref)}
        >
          <table>
            <tbody>
              {/* DAY NAMES */}
              {viewMode === CalendarViewMode.BASIC && (
                <Fragment>
                  <tr>
                    {days.map((day, index) => {
                      return <td key={index}>{day}</td>;
                    })}
                  </tr>
                  {renderWeeks}
                </Fragment>
              )}
              {viewMode === CalendarViewMode.YEARS && renderYears}
              {viewMode === CalendarViewMode.MONTHS && (
                <Fragment>{renderMonths}</Fragment>
              )}
            </tbody>
          </table>
          <div className="anys-calendar__table__head__commands">
            <div>
              {/* <Button
                className="anys-calendar__table__head__commands__command"
                icon={<ChevronIcon gradient={true} />}
                variant="icon"
                iconPosition="right"
                disabled={
                  viewMode === CalendarViewMode.YEARS
                    ? firstYearInMatrix === 1960
                    : year === 2001
                }
                onClick={(ev) => {
                  if (viewMode === CalendarViewMode.BASIC) {
                    if (month > 1) {
                      setMonth(month - 1);
                    } else {
                      setMonth(12);
                      setYear(year - 1);
                    }
                  } else if (viewMode === CalendarViewMode.YEARS) {
                    setFirstYearInMatrix(firstYearInMatrix - 20);
                  } else {
                    setYear(year - 1);
                  }
                }}
              /> */}
              {/* <Button
                className="anys-calendar__table__head__commands__command"
                icon={<ChevronIcon gradient={true} />}
                variant="icon"
                disabled={
                  viewMode === CalendarViewMode.YEARS
                    ? firstYearInMatrix === 2021
                    : year === 2040
                }
                onClick={(ev) => {
                  if (viewMode === CalendarViewMode.BASIC) {
                    if (month < 12) {
                      setMonth(month + 1);
                    } else {
                      setMonth(1);
                      setYear(year + 1);
                    }
                  } else if (viewMode === CalendarViewMode.YEARS) {
                    setFirstYearInMatrix(firstYearInMatrix + 20);
                  } else {
                    setYear(year + 1);
                  }
                }}
              /> */}
            </div>
            {hasSubmitButton && (
              <Button
                className="anys-calendar__table__head__commands__done-btn"
                onClick={() => {
                  onDone?.();
                  onClose?.();
                }}
              >
                {t('General.done')}
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }, [
    className,
    days,
    firstYearInMatrix,
    hasCloseButton,
    hasSubmitButton,
    month,
    months,
    onClose,
    onDone,
    ref,
    renderMonths,
    renderWeeks,
    renderYears,
    t,
    viewMode,
    withoutDays,
    year,
  ]);

  return calendar;
});

Calendar.displayName = 'Calendar';

export default Calendar;
