import React, { useMemo } from 'react';
import classNames from 'classnames';
import { ArbitrationActionTimer, ArbitrationProof } from 'models/Arbitrations';
import FilesPreview from 'components/FilesPreview';
import CountdownTimer from 'components/CountdownTimer';
import { Button } from 'ncoded-component-library';
import { OverlayRef } from 'ncoded-component-library/build/components/atoms/Overlay/Overlay.component';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import './ProviderClientProofs.styles.scss';
import './ProviderClientProofs.styles.responsive.scss';

type ProviderClientProofsProps = {
  previewType: 'client' | 'provider';
  proofs: ArbitrationProof;
  questionModal: OverlayRef;
  actionTimers?: ArbitrationActionTimer[];
  className?: string;
};
const ProviderClientProofs = (props: ProviderClientProofsProps) => {
  const { previewType, proofs, questionModal, actionTimers, className } = props;

  const classes = classNames('anys-provider-client-proofs', className);

  const { t } = useTranslation();

  const questionTimer = useMemo(() => {
    const date1 = dayjs(new Date());
    const date2 = dayjs(
      actionTimers.find(
        (actionTimer) =>
          actionTimer.type ===
          (previewType === 'provider'
            ? 'pending_proof_inquiry_ask_provider'
            : 'pending_proof_inquiry_ask_client'),
      )?.endsAt,
    );

    return date2.diff(date1, 'milliseconds');
  }, [actionTimers, previewType]);

  return (
    <section className={classes}>
      <p className="anys-provider-client-proofs__description">{proofs?.text}</p>
      <h2>{t(`Arbitration.${previewType}Proof`)}</h2>

      <FilesPreview
        files={proofs?.files as any}
        className="anys-provider-client-proofs__files"
      />

      <section className="anys-provider-client-proofs__questions-header">
        <section>
          <h2>{t('Arbitration.questions')}</h2>
          <section className="anys-provider-client-proofs__questions-header__timmer">
            {questionTimer ? (
              <>
                <p className="anys-provider-client-proofs__questions-header__description">
                  {t('Arbitration.timeForAskingQuestion', { previewType })}
                </p>

                <CountdownTimer
                  startTimeInMS={questionTimer}
                  className="anys-arbitration-decisioning__timmer"
                  iconGradient
                />
              </>
            ) : proofs?.inquiries.length !== 0 ? (
              <p className="anys-provider-client-proofs__questions-header__description">
                {t('Arbitration.alreadyAsked', { previewType })}
              </p>
            ) : (
              <p className="anys-provider-client-proofs__questions-header__description">
                {t('Arbitration.timeIsUp')}
              </p>
            )}
          </section>
        </section>
        {questionTimer ? (
          <Button variant="outline" onClick={() => questionModal.open()}>
            {t('Arbitration.askOtherSide', {
              previewType: t(`General.${previewType}`),
            })}
          </Button>
        ) : (
          <></>
        )}
      </section>

      {proofs?.inquiries.length !== 0 && (
        <section className="anys-provider-client-proofs__questions">
          {proofs?.inquiries.map((inquirie, index) => (
            <section
              key={inquirie.id}
              className="anys-provider-client-proofs__question"
            >
              <h3>{t('Arbitration.questionId', { id: index + 1 })}</h3>
              <span>{inquirie.question}</span>
              <h3>{`${t(`General.${previewType}`)} ${t(
                'Arbitration.answernId',
                {
                  id: index + 1,
                },
              )}`}</h3>
              <span>{inquirie.answer || t('Arbitration.notYetAnswered')}</span>
            </section>
          ))}
        </section>
      )}
    </section>
  );
};

export default ProviderClientProofs;
