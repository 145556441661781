import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Modal, { ModalRef } from 'components/Modal';
import JobPostPreview from 'components/JobPostPreview';
import { Job } from 'models/Job';
import { convertToMoney, formatMoney } from 'utils/currency';
import { useTranslation } from 'react-i18next';
import { Fund, Wallet } from 'models/Wallet';
import { Button } from 'ncoded-component-library';
import { useJob } from '../../Job/Job.page';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import ToolTip from 'components/ToolTip';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import AddDepositModal from 'components/AddDepositModal';
import useCallbackRef from 'hooks/useCallbackRef';
import api from 'api';
import env from 'env';
import { useParams } from 'react-router-dom';
import { InboxUrlParams } from 'models/Inbox';

import './JobSummaryPayModal.styles.scss';

type SummaryPayModalProps = {
  summaryType: 'client' | 'provider';
  onClose: () => void;
  name?: string;
  isSigning: boolean;
  handleSign: (signed: boolean) => Promise<void>;
  jobSummaryModalRef?: (element: ModalRef) => void;
};

const stripePromise = loadStripe(env.STRIPE_KEY);

const JobSummaryPayModal = (props: SummaryPayModalProps) => {
  const {
    summaryType,
    onClose,
    name = 'job-summary-pay-modal',
    isSigning,
    handleSign,
    jobSummaryModalRef,
  } = props;

  const { job, fetchingJob } = useJob();

  const { t } = useTranslation();

  const [wallet, setWallet] = useState<Wallet>();

  const { currentUser } = useContext(CurrentUserContext);

  const { inboxItemId } = useParams<InboxUrlParams>();

  const { client, provider, isSignedByClient, isSignedByProvider } = useMemo(
    (): Partial<Job> => job || {},
    [job],
  );

  const isCurrentUserClient = currentUser?.id === client?.id;

  const [balance, setBalance] = useState(0);

  const [createAdOfferModal, createAdOfferModalRef] =
    useCallbackRef<ModalRef>(null);

  const price = useMemo(() => {
    if (job?.timeAndPricing.type === 'Hourly') {
      return job?.timeAndPricing.maxTotalPrice / 100;
    } else {
      return job?.timeAndPricing.price / 100;
    }
  }, [
    job?.timeAndPricing.maxTotalPrice,
    job?.timeAndPricing.price,
    job?.timeAndPricing.type,
  ]);

  const getWallet = useCallback(async () => {
    try {
      const { data } = await api.wallet.getWallet();
      setWallet(data);
    } catch (e) {
      console.error(e);
    }
  }, []);

  const onSign = useCallback(async () => {
    // Save old sign status
    const hasAnySign = isSignedByClient || isSignedByProvider;

    await handleSign(false);

    // If the old sign status is signed, it means we will
    // redirect to contract so we dont need to close modal
    if (!hasAnySign) {
      onClose();
    }
  }, [handleSign, isSignedByClient, isSignedByProvider, onClose]);

  useEffect(() => {
    getWallet();
  }, [getWallet]);

  useEffect(() => {
    const USDFunds = wallet?.funds.find(
      (wall: Fund) => wall.currency === 'USD',
    )?.balance;

    if (USDFunds) {
      setBalance(USDFunds / 100);
    }
  }, [wallet]);

  return (
    <Modal
      modalName={name}
      type="no-action"
      title="Summary"
      className="job-summary-pay-modal"
      ref={jobSummaryModalRef}
    >
      <JobPostPreview
        job={job}
        isFetching={fetchingJob}
        hasClientSigned={summaryType === 'client' ? true : isSignedByClient}
        hasProviderSigned={
          summaryType === 'provider' ? true : isSignedByProvider
        }
        client={client}
        provider={provider}
        isCurrentUserClient={isCurrentUserClient}
        actionInProgress={isSigning}
        handleSign={handleSign}
        isOwnJob={false}
        isInSummaryModal
      />

      {summaryType === 'client' ? (
        <div className="job-summary-price">
          <div className="job-summary-price__price-part">
            {`Subtotal: ${price || 0}$`}
          </div>

          <div className="job-summary-price__price-part">
            <span>
              {`Fees & Estimated Tax: ${+((price / 100) * 0.97).toFixed(2)}$`}
            </span>
            <ToolTip t={t} tooltipName="fees">
              Service fee is 0.97% help us operate AnyService
            </ToolTip>
          </div>

          <div className="job-summary-price__price-total">
            Total / Job value:
            <span>
              {formatMoney(
                t,
                convertToMoney((price + (price / 100) * 0.97) * 100 || 0),
              )}
            </span>
          </div>

          <div className="job-summary-price__description">
            This amount will be in escrow until service is done and you are
            satisfied with service.
          </div>

          <div className="job-summary-price__actions">
            {+balance.toFixed(2) >=
            +(price + (price / 100) * 0.97).toFixed(2) ? (
              <div>
                <Button type="button" variant="solid" onClick={onSign}>
                  Sign and pay
                </Button>
                <Button
                  variant="link"
                  onClick={() => {
                    onClose();
                  }}
                >
                  <span>Cancel</span>
                </Button>
              </div>
            ) : (
              <div className="job-summary-price__no-enough-money">
                <span>{t('General.notMoneyInBalance')}</span>
                <Button
                  type="button"
                  variant="solid"
                  onClick={() => {
                    createAdOfferModal.open();
                  }}
                >
                  Add deposit
                </Button>
              </div>
            )}
            <div className="job-summary-price__current-balance">
              Your current balance:
              <span>{formatMoney(t, convertToMoney(balance * 100 || 0))}</span>
            </div>
          </div>
          <Elements
            stripe={stripePromise}
            options={{
              mode: 'setup',
              currency: 'usd',
            }}
          >
            <AddDepositModal
              addDepositModalRef={createAdOfferModalRef}
              onDepositMake={(value) => {
                setBalance((old) => old + value);
              }}
              onClose={() => createAdOfferModal.close()}
              initialValueForDeposit={
                +((price + (price / 100) * 0.97 - balance) * 100).toFixed()
              }
              returnUrlOnConfirmSetup={
                inboxItemId
                  ? `${window.location.origin}/inbox/${inboxItemId}/0/job/${job?.commonId}?job-summary-pay-modal=open&add-deposit=open`
                  : `${window.location.origin}/job/${job?.commonId}?job-summary-pay-modal=open&add-deposit=open`
              }
            />
          </Elements>
        </div>
      ) : (
        <div className="job-summary-price">
          <div className="job-summary-price__price-part">
            {`Subtotal: ${price || 0}$`}
          </div>

          <div className="job-summary-price__price-part">
            {`Fees & Estimated Tax: ${+((price / 100) * 3 < 3
              ? 3
              : +((price / 100) * 3).toFixed(2))}$`}
            <ToolTip t={t} tooltipName="fees">
              Service fee is 3% (minimum 3$) help us operate AnyService
            </ToolTip>
          </div>

          <div className="job-summary-price__price-total">
            Total / Job value:
            <span>
              {formatMoney(
                t,
                convertToMoney(
                  +(
                    (price - ((price / 100) * 3 < 3 ? 3 : (price / 100) * 3)) *
                    100
                  ).toFixed(2) || 0,
                ),
              )}
            </span>
          </div>

          <div className="job-summary-price__actions">
            <div>
              <Button variant="solid" onClick={onSign}>
                Sign and Take job
              </Button>
              <Button
                variant="link"
                onClick={() => {
                  onClose();
                }}
              >
                <span>Cancel</span>
              </Button>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default JobSummaryPayModal;
