import api from 'api';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import CurrentUserContext, { FileType } from './CurrentUser.context';
import { User } from 'models/User';
import OverlaySpinner from 'components/OverlaySpinner';
import credentialsService from 'services/credentialsService';
import useEffectOnce from 'hooks/useEffectOnce';
import utils from 'utils';
import eventSocketService from 'services/socket/eventSocketService';
import webpushService from '../../services/webpushService';
import showToast from 'modules/showToast';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';

const ROLE_WHITELISTED_ROUTES = ['static-pages'];

type CurrentUserProps = {
  children: React.ReactNode;
};

const CurrentUser: React.FC<CurrentUserProps> = (props) => {
  const { children } = props;

  const hasShownAuthPage = useRef(false);

  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState<Partial<User>>(null);
  const isConnectingToSockets = useRef(false);

  const location = useLocation();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const locationFirstPath = location.pathname?.split('/')?.[1];

  // Used for guest users that have just entered the site.
  // We want to show them the auth page.
  const shouldNavigateToAuth = useCallback(() => {
    if (credentialsService.token || hasShownAuthPage.current) return false;

    hasShownAuthPage.current = true;

    return true;
  }, []);

  const getUser = useCallback(async () => {
    try {
      setLoading(true);
      const {
        data: { user },
      } = await api.user.getUserProfile();

      setCurrentUser(user);

      const isWhitelistedRoute =
        ROLE_WHITELISTED_ROUTES.includes(locationFirstPath);

      if (!user?.role && !isWhitelistedRoute) {
        const token = credentialsService.token;
        const refreshToken = credentialsService.refreshToken;

        navigate(
          `/auth/confirm?access-token=${token}&refresh-token=${refreshToken}`,
        );
      }
    } catch (e) {
      utils.toastError(e);
    } finally {
      setLoading(false);
    }
  }, [locationFirstPath, navigate]);

  const removeUserFile = useCallback(
    async (id: number, updateUserState = true) => {
      try {
        const { data } = await api.user.removeUserFile(id);

        if (updateUserState) {
          setCurrentUser(data);
        }

        return data;
      } catch (e) {
        utils.toastError(e);
      }
    },
    [],
  );

  const uploadUserFile = useCallback(
    async (fileName: FileType, files: File[], updateUserState = true) => {
      if (!files || files.length < 1) {
        return null;
      }

      OverlaySpinner.show('div');
      const formData = new FormData();
      files.forEach((file) => formData.append(fileName, file));
      try {
        const { data } = await api.user.uploadUserFile(formData);

        if (updateUserState) {
          setCurrentUser((oldUser) => ({
            ...oldUser,
            profileImage: data.profileImage,
            coverImage: data.coverImage,
            gallery: data.gallery,
          }));
        }

        return data;
      } catch (e) {
        const err = e as AxiosError;

        showToast(
          'error',
          t('General.error'),
          err?.response?.data?.error?.message,
        );
        utils.toastError(e);
      } finally {
        OverlaySpinner.hide('div');
      }
    },
    [t],
  );

  useEffectOnce(() => {
    if (!credentialsService.token) {
      setLoading(false);
      return;
    }

    getUser();
  });

  // Tell our BE that we are online, at a set interval
  useEffect(() => {
    let intervalId: NodeJS.Timer;
    const isConnected = Boolean(eventSocketService?.socket?.connected);

    if (isConnectingToSockets.current || !currentUser || isConnected) {
      return;
    }

    isConnectingToSockets.current = true;
    eventSocketService.connect().then(() => {
      isConnectingToSockets.current = false;
      try {
        eventSocketService.sendEvent('heartbeat');

        intervalId = setInterval(() => {
          eventSocketService.sendEvent('heartbeat');

          // 1 minute
        }, 60000);
      } catch (error) {
        console.error(error);
      }
    });

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
      if (isConnected) {
        eventSocketService.disconnect();
      }
    };
  }, [currentUser]);

  // Ask the user for notification permission
  useEffect(() => {
    if (currentUser) {
      webpushService.requestPermission();
    }
  }, [currentUser]);

  return (
    <CurrentUserContext.Provider
      value={{
        currentUser,
        loading,
        shouldNavigateToAuth,
        setCurrentUser,
        removeUserFile,
        uploadUserFile,
        getUser,
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
};

export default CurrentUser;
