import React, { useCallback, useEffect, useState } from 'react';
import { ArbitrationView } from 'router/subrouters/Arbitration/Arbitration.page';
import { Arbitration } from 'models/Arbitrations';
import api from 'api';
import useQueryParams from 'hooks/useQueryParams';
import { Outlet, useOutletContext } from 'react-router-dom';

type ArbitationProviderProps = { children?: React.ReactNode };

const ArbitationProvider: React.FC<ArbitationProviderProps> = (props) => {
  const {
    params: { arbitrationId, arbitration_view },
  } = useQueryParams();

  const [arbitrationView, setArbitrationView] = useState<ArbitrationView>(
    (arbitration_view as ArbitrationView) || 'my_cases',
  );

  const [arbitrations, setArbitrations] = useState<Arbitration[]>([]);

  const [selectedArbitration, setSelectedArbitration] =
    useState<Arbitration>(null);

  const getArbitration = useCallback(async () => {
    try {
      if (arbitrationId) {
        const { data } = await api.arbitration.getArbitration(+arbitrationId);

        if (data) {
          setSelectedArbitration(data);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }, [arbitrationId]);

  useEffect(() => {
    getArbitration();
  }, [getArbitration]);

  return (
    <Outlet
      context={{
        arbitrations,
        setArbitrations,
        arbitrationView,
        setArbitrationView,
        selectedArbitration,
        setSelectedArbitration,
      }}
    />
  );
};

export default ArbitationProvider;

type OutletContext = {
  arbitrations: Arbitration[];
  setArbitrations: React.Dispatch<React.SetStateAction<Arbitration[]>>;
  arbitrationView: ArbitrationView;
  setArbitrationView: React.Dispatch<React.SetStateAction<ArbitrationView>>;
  selectedArbitration: Arbitration;
  setSelectedArbitration: React.Dispatch<React.SetStateAction<Arbitration>>;
};

export const useArbitration = () => useOutletContext<OutletContext>();
