import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import uniqBy from 'lodash/uniqBy';
import debounce from 'lodash/debounce';
import showToast from 'modules/showToast';
import { AxiosError } from 'axios';

type MakeRequestFn<T> = (
  currentPage: number,
  take: number,
) => Promise<{
  items: T[];
  totalItems: number;
  totalPages: number;
}>;

type InfinitePagination<T> = {
  take: number;
  makeRequest: MakeRequestFn<T>;
  defaultItems?: T[];
  page?: number;
  totalItems?: number;
  customSetItems?: React.Dispatch<React.SetStateAction<T[]>>;
  dependencies?: Array<any>;
  debounceTime?: number;
  resetDeps?: Array<any>;
  skipFirst?: boolean;
  searchString?: string;
  prependItems?: boolean;
  uniqByProp?: string;
  resetItemsOnPageChange?: boolean;
  manipulateResponseItems?: (items: T[]) => T[];
};

export default function <T>(props: InfinitePagination<T>) {
  const {
    take,
    searchString,
    makeRequest,
    defaultItems = [],
    customSetItems,
    dependencies = [],
    debounceTime = null,
    resetDeps = [],
    skipFirst = false,
    page,
    totalItems: defaultTotalItems,
    prependItems,
    uniqByProp,
    resetItemsOnPageChange = true,
    manipulateResponseItems,
  } = props;

  const resetDataFlag = useRef(false);

  const [loading, setLoading] = useState(!skipFirst);
  const [items, setItems] = useState<T[]>(defaultItems);
  const [currentPage, setCurrentPage] = useState(page || 1);
  const [totalItems, setTotalItems] = useState(defaultTotalItems);
  const lastSearchString = useRef(searchString);
  const first = useRef(true);
  const skipFirstRef = useRef(skipFirst);

  const totalPages = Math.ceil(totalItems / take);

  const onContainerScrolled = useCallback(() => {
    if (loading) return;

    setCurrentPage((old) => {
      if (old < totalPages) return old + 1;

      return old;
    });
  }, [loading, totalPages]);

  const goToPage = useCallback((page: number) => {
    resetDataFlag.current = true;
    setCurrentPage(page);
  }, []);

  const setItemsFinal = useMemo(
    () => customSetItems || setItems,
    [customSetItems],
  );

  const getItems = useMemo(
    () =>
      debounce(
        async (
          makeRequest: MakeRequestFn<T>,
          currentPage: number,
          searchString: string,
          prependItems: boolean,
          setItemsFinal: React.Dispatch<React.SetStateAction<T[]>>,
        ) => {
          try {
            setLoading(true);
            const { items: respItems, totalItems } = await makeRequest(
              currentPage,
              take,
            );

            let items: T[];

            if (manipulateResponseItems) {
              items = manipulateResponseItems(respItems);
            } else {
              items = respItems;
            }

            if (resetDataFlag.current) {
              setItemsFinal(items);
              resetDataFlag.current = false;
            } else if (lastSearchString.current !== searchString) {
              setItemsFinal(items);
            } else {
              setItemsFinal((old: T[]) =>
                uniqBy(
                  prependItems ? [...items, ...old] : [...old, ...items],
                  uniqByProp || undefined,
                ),
              );
            }

            lastSearchString.current = searchString;

            setTotalItems(totalItems);
          } catch (e) {
            const err = e as AxiosError;

            showToast('error', err.message || err.response?.data?.message);
          } finally {
            setTimeout(() => setLoading(false), 500);
          }
        },
        debounceTime,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debounceTime],
  );

  useEffect(() => {
    if (skipFirstRef.current && first.current) {
      first.current = false;
      return;
    }

    getItems(
      makeRequest,
      currentPage,
      searchString,
      prependItems,
      setItemsFinal,
    );
  }, [
    currentPage,
    searchString,
    prependItems,
    makeRequest,
    getItems,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ...dependencies,
    setItemsFinal,
  ]);

  useEffect(() => {
    if (loading) return;

    setCurrentPage(1);
    resetDataFlag.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...resetDeps]);

  useEffect(() => {
    if (!page) return;

    if (resetItemsOnPageChange) resetDataFlag.current = true;

    setCurrentPage(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, resetItemsOnPageChange]);

  return {
    items,
    loading,
    searchString,
    currentPage,
    totalPages,
    totalItems,
    goToPage,
    setItems,
    onContainerScrolled,
    setCurrentPage,
  };
}
