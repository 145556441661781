import React from 'react';
import useHeightAnimation from 'hooks/useHeightAnimation';
import classNames from 'classnames';
import ChevronIcon from 'icons/ChervonIcon.icon';

import './Question.styles.scss';

type QuestionProps = {
  question: string;
  answer: string;
};

const Question = (props: QuestionProps) => {
  const { question, answer } = props;

  const { isExpanded, toggleIsExpanded, contentRef, contentHeight } =
    useHeightAnimation(false);

  const classes = classNames(['anys-section-question']);

  return (
    <div className={classes}>
      <div className="anys-section-question__header" onClick={toggleIsExpanded}>
        <h3>{question}</h3>
        <ChevronIcon
          className={classNames([
            'anys-section-question__header__icon',
            `anys-section-question__header__icon--${
              isExpanded ? 'open' : 'close'
            }`,
          ])}
        />
      </div>
      <div
        style={{
          height: isExpanded ? contentHeight : 0,
          overflow: 'hidden',
          transition: 'height 0.5s ease',
        }}
        ref={contentRef}
      >
        <p className="anys-section-question__answer">{answer}</p>
      </div>
    </div>
  );
};

export default Question;
