import { CONTRACT_STATE } from 'constants/contract';
import { JOB_STATE } from 'constants/job';
import { InboxItem, INBOX_ITEM_TYPE } from 'models/Inbox';
import { FilterReqValue } from 'utils/filtersForm';

const INBOX_ITEMS_GENERAL_FILTER = {
  'see-all': {},
  preOffers: {
    state: { '!EQUAL': JOB_STATE.DRAFT },
    type: { '!EQUAL': INBOX_ITEM_TYPE.CONTRACT },
    numberOfSigns: { EQUAL: 0 },
  },

  offers: {
    state: { '!EQUAL': JOB_STATE.DRAFT },
    type: { '!EQUAL': INBOX_ITEM_TYPE.CONTRACT },
    numberOfSigns: { EQUAL: 1 },
  },
  activeContracts: { state: { EQUAL: CONTRACT_STATE.ACTIVE } },
  doneContracts: { state: { EQUAL: CONTRACT_STATE.DONE } },
  drafts: { state: { EQUAL: JOB_STATE.DRAFT } },
  favourites: { isFavourite: { EQUAL: true } },
} as const;

const INBOX_ITEM_IN_FILTER_CHECKER: Record<
  keyof typeof INBOX_ITEMS_GENERAL_FILTER,
  (inboxItem: InboxItem) => boolean
> = {
  'see-all': () => true,
  preOffers: (item) => item.type !== 'contract' && item.numberOfSigns === 0,
  offers: (item) => item.numberOfSigns === 1,
  activeContracts: (item) =>
    item.state === 'Active' || item.contract?.state === 'Active',
  doneContracts: (item) =>
    item.state === 'Done' || item.contract?.state === 'Done',
  drafts: (item) => item.state === 'Draft',
  favourites: (item) => item.isFavourite,
};

const INBOX_FILTER_DB_NAMES: Record<string, FilterReqValue> = {
  inboxItemType: {
    mapperFn: (value) =>
      INBOX_ITEMS_GENERAL_FILTER[
        value as keyof typeof INBOX_ITEMS_GENERAL_FILTER
      ],
  },
};

export {
  INBOX_ITEMS_GENERAL_FILTER,
  INBOX_ITEM_IN_FILTER_CHECKER,
  INBOX_FILTER_DB_NAMES,
};
