import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import JobPostPreview from 'components/JobPostPreview';
import { useJobPost } from '../JobPost/JobPost.page';
import { Job, JobPost } from 'models/Job';
import useJobPostSign from '../../hooks/useJobPostSign';
import { convertToMoney, formatMoney } from 'utils/currency';
import { useTranslation } from 'react-i18next';
import { Fund, Wallet } from 'models/Wallet';
import { Button } from 'ncoded-component-library';
import Modal, { ModalRef } from 'components/Modal';
import ToolTip from 'components/ToolTip';
import AddDepositModal from 'components/AddDepositModal';
import useCallbackRef from 'hooks/useCallbackRef';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import api from 'api';
import env from 'env';
import { useParams } from 'react-router-dom';
import { InboxUrlParams } from 'models/Inbox';
import './JobPostSummaryPayModal.styles.scss';
import CurrentUserContext from '../../../../../providers/CurrentUser/CurrentUser.context';

type SummaryPayModalProps = {
  summaryType: 'client' | 'provider';
  onClose: () => void;
  name?: string;
  isInForm?: boolean;
  jobPostSummaryModalRef?: (element: ModalRef) => void;
  // in case signing job post form
  handleSign: (hasSigned: boolean) => Promise<void>;
  jobPostForm?: any;
};

const stripePromise = loadStripe(env.STRIPE_KEY);

const JobPostSummaryPayModal = (props: SummaryPayModalProps) => {
  const {
    summaryType,
    onClose,
    name = 'job-post-summary-pay-modal',
    isInForm,
    jobPostForm,
    jobPostSummaryModalRef,
    handleSign,
  } = props;
  const { currentUser } = useContext(CurrentUserContext);

  const { jobPost, fetchingJobPost, setJobPost } = useJobPost();

  const [jobPostState, setJobPostState] = useState(jobPostForm || jobPost);

  const [balance, setBalance] = useState(0);

  const { t } = useTranslation();

  const [wallet, setWallet] = useState<Wallet>();

  const { inboxItemId } = useParams<InboxUrlParams>();

  const { id, type, isSigned, state, user, trackingData } = useMemo(
    () => jobPostState || ({} as JobPost),
    [jobPostState],
  );

  const isProvideJob = type === 'Provide';

  const hasProviderSigned = isProvideJob && isSigned;

  const price = useMemo(() => {
    if (jobPostState?.timeAndPricing.type === 'Hourly') {
      return jobPostState?.timeAndPricing.maxTotalPrice / 100;
    } else {
      return jobPostState?.timeAndPricing.price / 100;
    }
  }, [
    jobPostState?.timeAndPricing.maxTotalPrice,
    jobPostState?.timeAndPricing.price,
    jobPostState?.timeAndPricing.type,
  ]);

  const [createAdOfferModal, createAdOfferModalRef] =
    useCallbackRef<ModalRef>(null);

  const { client, isCurrentUserClient, provider, signing } = useJobPostSign({
    jobId: id,
    jobType: type,
    jobState: state,
    jobCreator: user,
    trackingData,
    updateJob: (newJob: Job | JobPost) => {
      setJobPost((oldJob) => ({
        ...oldJob,
        isSigned: 'isSigned' in newJob ? newJob.isSigned : false,
      }));
    },
  });

  useEffect(() => {
    if (!currentUser?.id) {
      return;
    }
    const USDFunds = wallet?.funds.find(
      (wall: Fund) => wall.currency === 'USD',
    )?.balance;

    if (USDFunds) {
      setBalance(USDFunds / 100);
    }
  }, [currentUser?.id, wallet]);

  const getWallet = useCallback(async () => {
    try {
      const { data } = await api.wallet.getWallet();
      setWallet(data);
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    if (!currentUser?.id) {
      return;
    }
    getWallet();
  }, [currentUser?.id, getWallet]);

  useEffect(() => {
    if (isInForm) {
      jobPostForm &&
        setJobPostState({
          ...jobPostForm,
          mainSkill: { id: 'main-skill', name: jobPostForm?.mainSkill },
          skills: jobPostForm?.skills.map((skill: any, index: number) => ({
            id: index,
            name: skill,
          })),
        });
    } else {
      setJobPostState(jobPost);
    }
  }, [isInForm, jobPost, jobPostForm]);

  return (
    <Modal
      modalName={name}
      type="no-action"
      title="Summary"
      className="job-summary-pay-modal"
      ref={jobPostSummaryModalRef}
    >
      <JobPostPreview
        jobPost={jobPostState}
        isFetching={fetchingJobPost}
        hasClientSigned={true}
        hasProviderSigned={hasProviderSigned}
        client={client}
        provider={provider}
        isCurrentUserClient={isCurrentUserClient}
        actionInProgress={signing}
        handleSign={handleSign}
        isOwnJob={false}
        isInSummaryModal
      />

      <div className="job-post-preview__price">
        {summaryType === 'client' ? (
          <div className="job-summary-price">
            <div className="job-summary-price__price-part">
              {`Subtotal: ${price || 0}$`}
            </div>

            <div className="job-summary-price__price-part">
              <span>
                {`Fees & Estimated Tax: ${+((price / 100) * 0.97).toFixed(2)}$`}
              </span>
              <ToolTip t={t} tooltipName="fees">
                Service fee is 0.97% help us operate AnyService
              </ToolTip>
            </div>

            <div className="job-summary-price__price-total">
              Total / Job value:
              <span>
                {formatMoney(
                  t,
                  convertToMoney((price + (price / 100) * 0.97) * 100 || 0),
                )}
              </span>
            </div>

            <div className="job-summary-price__description">
              This amount will be in escrow until service is done and you are
              satisfied with service.
            </div>

            <div className="job-summary-price__actions">
              {+balance.toFixed(2) >=
              +(price + (price / 100) * 0.97).toFixed(2) ? (
                <div>
                  <Button
                    type="button"
                    variant="solid"
                    onClick={async () => {
                      handleSign(false);
                      onClose();
                    }}
                  >
                    Sign and pay
                  </Button>
                  <Button
                    variant="link"
                    onClick={() => {
                      onClose();
                    }}
                  >
                    <span>Cancel</span>
                  </Button>
                </div>
              ) : (
                <div className="job-summary-price__no-enough-money">
                  <span>{t('General.notMoneyInBalance')}</span>
                  <Button
                    type="button"
                    variant="solid"
                    onClick={() => {
                      createAdOfferModal.open();
                    }}
                  >
                    Add deposit
                  </Button>
                </div>
              )}
              <div className="job-summary-price__current-balance">
                Your current balance:
                <span>
                  {formatMoney(t, convertToMoney(balance * 100 || 0))}
                </span>
              </div>
            </div>
            <Elements
              stripe={stripePromise}
              options={{
                mode: 'setup',
                currency: 'usd',
              }}
            >
              <AddDepositModal
                addDepositModalRef={createAdOfferModalRef}
                onDepositMake={(value) => {
                  setBalance((old) => old + value);
                }}
                onClose={() => createAdOfferModal.close()}
                initialValueForDeposit={
                  +((price + (price / 100) * 0.97 - balance) * 100).toFixed()
                }
                returnUrlOnConfirmSetup={
                  inboxItemId
                    ? `${window.location.origin}/inbox/${inboxItemId}/0/job-post/${jobPost?.id}?job-post-summary-pay-modal=open&add-deposit=open`
                    : `${window.location.origin}/job-post/${jobPost?.id}?job-post-summary-pay-modal=open&add-deposit=open`
                }
              />
            </Elements>
          </div>
        ) : (
          <div className="job-summary-price">
            <div className="job-summary-price__price-part">
              {`Subtotal: ${price || 0}$`}
            </div>

            <div className="job-summary-price__price-part">
              {`Fees & Estimated Tax: ${+((price / 100) * 3 < 3
                ? 3
                : +((price / 100) * 3).toFixed(2))}$`}
              <ToolTip t={t} tooltipName="fees">
                Service fee is 3% (minimum 3$) help us operate AnyService
              </ToolTip>
            </div>

            <div className="job-summary-price__price-total">
              Total / Job value:
              <span>
                {formatMoney(
                  t,
                  convertToMoney(
                    +(
                      (price -
                        ((price / 100) * 3 < 3 ? 3 : (price / 100) * 3)) *
                      100
                    ).toFixed(2) || 0,
                  ),
                )}
              </span>
            </div>
            <div className="job-summary-price__actions">
              <div>
                <Button
                  variant="solid"
                  onClick={async () => {
                    handleSign(false);
                    onClose();
                  }}
                >
                  Sign and Take job
                </Button>
                <Button
                  variant="link"
                  onClick={() => {
                    onClose();
                  }}
                >
                  <span>Cancel</span>
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default JobPostSummaryPayModal;
