import React, { useMemo } from 'react';
import classNames from 'classnames';
import Card from 'components/Card';
import InputField from 'components/InputField';
import RichTextEditorField from 'components/RichTextEditorField';
import formValidators from 'utils/formValidators';
import { Job } from 'models/Job';
import { useTranslation } from 'react-i18next';
import { getTextChanges, isPendingChanges } from 'utils/job-changes';
import ToolTip from 'components/ToolTip';
import WarningIcon from 'icons/Warning.icon';
import JobChanges from 'components/JobChanges';
import SlatePreview from 'components/SlatePreview';

import './ContractInfo.styles.scss';

type ContractInfoProps = {
  className?: string;
  disabled?: boolean;
  valuesFromForm: Pick<Job, 'title' | 'description'>;
} & Pick<Job, 'prevVersion' | 'versionState'>;

const ContractInfo: React.FC<ContractInfoProps> = (props) => {
  const { className, disabled, valuesFromForm, prevVersion, versionState } =
    props;

  const { t } = useTranslation();

  const classes = classNames('anys-contract-info', className);

  const messages = useMemo(
    () => ({
      jobTitle: t('General.jobTitle'),
      jobTitlePlaceholder: t('JobForm.jobTitlePlaceholder'),
      jobDescription: t('General.jobDescription'),
      jobDescriptionPlaceholder: t('JobForm.jobDescriptionPlaceholder'),
      jobDescriptionRequired: t('JobForm.jobDescriptionRequired'),
    }),
    [t],
  );

  const titleChanges =
    prevVersion && isPendingChanges(versionState)
      ? getTextChanges(prevVersion.title, valuesFromForm.title)
      : null;

  const descChanges =
    prevVersion && isPendingChanges(versionState)
      ? getTextChanges(prevVersion.description, valuesFromForm.description)
      : null;

  return (
    <Card className={classes}>
      <InputField
        type="text"
        name="title"
        label={messages.jobTitle}
        asideLabelContent={
          titleChanges ? (
            <ToolTip
              t={t}
              tooltipName="title-changes"
              icon={WarningIcon}
              className="anys-contract-info__tooltip"
            >
              <JobChanges
                changedFrom={[titleChanges.from]}
                changedTo={[titleChanges.to]}
              />
            </ToolTip>
          ) : null
        }
        placeholder={messages.jobTitlePlaceholder}
        validate={formValidators.getJobTitleValidators(t)}
        disabled={disabled}
      />
      <RichTextEditorField
        name="description"
        label={messages.jobDescription}
        asideLabelContent={
          descChanges ? (
            <ToolTip
              t={t}
              tooltipName="description-changes"
              icon={WarningIcon}
              className="anys-contract-info__tooltip"
            >
              <JobChanges
                changedFrom={[descChanges.from]}
                changedTo={[descChanges.to]}
                valueParser={(value) => {
                  if (typeof value !== 'string') return '';

                  return <SlatePreview>{value}</SlatePreview>;
                }}
              />
            </ToolTip>
          ) : null
        }
        placeholder={messages.jobDescriptionPlaceholder}
        validate={formValidators.composeValidators(
          formValidators.requiredRichText(messages.jobDescriptionRequired),
          formValidators.minRichTextLength(
            t('JobForm.jobTitleShort', {
              minLength: formValidators.JOB_DESC_MIN_LENGTH,
            }),
            formValidators.JOB_DESC_MIN_LENGTH,
          ),
          formValidators.maxRichTextLength(
            t('General.maxCharLengthError', {
              max: formValidators.JOB_DESC_MAX_LENGTH,
            }),
            formValidators.JOB_DESC_MAX_LENGTH,
          ),
        )}
        className="anys-contract-info__description"
        readOnly={disabled}
      />
    </Card>
  );
};

export default ContractInfo;
