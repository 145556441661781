import React from 'react';
import { TFunction } from 'i18next';
import _ from 'lodash';
import { JobPost } from 'models/Job';
import { JobTypeOfService } from 'models/Job/TypeOfService';
import { User } from 'models/User';
import { formatArbitrationFilterValue } from './arbitration';
import { formatMoney } from './currency';
import dates from './dates';
import { getFormattedDeadlines } from './deadlines';
import { getSkillsAsLabels } from './job';
import { formatLocation } from './location';
import { formatUserDisplayName } from './user';
import ExternalLink from 'components/ExternalLink';
import { ReactComponent as OpenNewWindowIcon } from 'icons/open-new-window.svg';
import { OptionValue } from 'ncoded-component-library/build/components/molecules/Select/Select.component';

export type ChangesReturn<T = string> = {
  changedFrom: T[];
  changedTo: T[];
} | null;

const getLockedChange = (
  t: TFunction,
  prevIsLocked: boolean,
  newIsLocked: boolean,
) => {
  if (prevIsLocked === newIsLocked) return null;

  return {
    from: prevIsLocked ? t('General.nonNegotiable') : t('General.negotiable'),
    to: newIsLocked ? t('General.nonNegotiable') : t('General.negotiable'),
  };
};

const getTextChanges = (prevText: string, newText: string) => {
  if (prevText !== newText) {
    return {
      from: prevText,
      to: newText,
    };
  }

  return null;
};

type ContractInfo = Pick<JobPost<'form'>, 'title' | 'description'>;

const getContractInfoChanges = (
  t: TFunction,
  prevContarctInfo: ContractInfo,
  newContractInfo: ContractInfo,
): ChangesReturn => {
  const { title: prevTitle, description: prevDesc } = prevContarctInfo;
  const { title, description } = newContractInfo;

  const changedFrom: string[] = [];
  const changedTo: string[] = [];

  const titleChanges = getTextChanges(prevTitle, title);

  if (titleChanges) {
    changedFrom.push(titleChanges.from);
    changedTo.push(titleChanges.to);
  }

  const descChanges = getTextChanges(prevDesc, description);

  if (descChanges) {
    changedFrom.push(descChanges.from);
    changedTo.push(descChanges.to);
  }

  if (!changedFrom.length && !changedTo.length) return null;

  return { changedFrom, changedTo };
};

type Skills = Pick<JobPost<'form'>, 'mainSkill' | 'skills'>;

const getSkillsChanges = (
  t: TFunction,
  { mainSkill: prevMainSkill, skills: prevSkills }: Skills,
  { mainSkill, skills }: Skills,
): ChangesReturn => {
  const changedFrom: string[] = [];
  const changedTo: string[] = [];

  if (prevMainSkill !== mainSkill) {
    changedFrom.push(`${t('General.mainSkill')}: ${prevMainSkill}`);
    changedTo.push(`${t('General.mainSkill')}: ${mainSkill}`);
  }

  const prevSkillNames = prevSkills || [];
  const skillNames = skills || [];

  if (!_.isEqual(prevSkills, skills)) {
    changedFrom.push(
      `${t('General.skills')}: ${prevSkillNames?.join(', ') || '-'}`,
    );
    changedTo.push(`${t('General.skills')}: ${skillNames?.join(', ') || '-'}`);
  }

  if (!changedFrom.length && !changedTo.length) return null;

  return { changedFrom, changedTo };
};

type TimeAndPricing = Pick<JobPost<'form'>, 'timeAndPricing'>['timeAndPricing'];

const getTimeChanges = (
  t: TFunction,
  prevTimeAndPricing: Pick<
    TimeAndPricing,
    'importAvailability' | 'startDate' | 'endDate'
  >,
  newTimeAndPricing: Pick<
    TimeAndPricing,
    'importAvailability' | 'startDate' | 'endDate'
  >,
  prevIsLocked: boolean,
  newIsLocked: boolean,
): ChangesReturn => {
  const {
    importAvailability: prevImportAvailability,
    startDate: prevStartDate,
    endDate: prevEndDate,
  } = prevTimeAndPricing || {};
  const { importAvailability, startDate, endDate } = newTimeAndPricing || {};

  const changedFrom: string[] = [];
  const changedTo: string[] = [];

  const formattedPrevDates = dates.formatDates(prevStartDate, prevEndDate);
  const formattedDates = dates.formatDates(startDate, endDate);

  const availabilityMsg = t('General.importFromAvailability');

  const lockedChanges = getLockedChange(t, prevIsLocked, newIsLocked);

  if (lockedChanges) {
    changedFrom.push(lockedChanges.from);
    changedTo.push(lockedChanges.to);
  }

  if (prevImportAvailability !== importAvailability) {
    changedFrom.push(
      `${t('General.time')}: ${
        prevImportAvailability ? availabilityMsg : formattedPrevDates
      }`,
    );
    changedTo.push(
      `${t('General.time')}: ${
        importAvailability ? availabilityMsg : formattedDates
      }`,
    );
  } else if (+prevStartDate !== +startDate || +prevEndDate !== +endDate) {
    changedFrom.push(`${t('General.time')}: ${formattedPrevDates}`);
    changedTo.push(`${t('General.time')}: ${formattedDates}`);
  }

  if (!changedFrom.length && !changedTo.length) return null;

  return { changedFrom, changedTo };
};

const getPriceChanges = (
  t: TFunction,
  prevTimeAndPricing: Pick<
    TimeAndPricing,
    'type' | 'price' | 'minHours' | 'maxHours' | 'totalHours'
  >,
  newTimeAndPricing: Pick<
    TimeAndPricing,
    'type' | 'price' | 'minHours' | 'maxHours' | 'totalHours'
  >,
  prevIsLocked: boolean,
  newIsLocked: boolean,
): ChangesReturn => {
  const {
    type: prevType,
    price: prevPrice,
    minHours: prevMinHours,
    maxHours: prevMaxHours,
    totalHours: prevTotalHours,
  } = prevTimeAndPricing || {};
  const { type, price, minHours, maxHours, totalHours } =
    newTimeAndPricing || {};

  const changedFrom: string[] = [];
  const changedTo: string[] = [];

  const isPrevByProject = prevType === 'By project';
  const isNewByProject = type === 'By project';

  const formattedPrevPrice =
    typeof prevPrice?.amount === 'number' ? formatMoney(t, prevPrice) : '-';
  const formattedNewPrice =
    typeof price?.amount === 'number' ? formatMoney(t, price) : '-';

  const lockedChanges = getLockedChange(t, prevIsLocked, newIsLocked);

  if (lockedChanges) {
    changedFrom.push(lockedChanges.from);
    changedTo.push(lockedChanges.to);
  }

  if (prevPrice?.amount !== price?.amount) {
    changedFrom.push(
      isPrevByProject
        ? `${t('General.price')}: ${formattedPrevPrice}`
        : `${t('General.pricePerHour')}: ${formattedPrevPrice}`,
    );
    changedTo.push(
      isNewByProject
        ? `${t('General.price')}: ${formattedNewPrice}`
        : `${t('General.pricePerHour')}: ${formattedNewPrice}`,
    );
  }

  const minHoursChanged = prevMinHours !== minHours;
  const maxHoursChanged = prevMaxHours !== maxHours;
  const totalHoursChanged = prevTotalHours !== totalHours;

  if (minHoursChanged) {
    changedFrom.push(
      `${t('General.minHours')}: ${
        Number.parseFloat(`${prevMinHours}`) >= 0 ? prevMinHours : '-'
      }`,
    );
    changedTo.push(
      `${t('General.minHours')}: ${
        Number.parseFloat(`${minHours}`) >= 0 ? minHours : '-'
      }`,
    );
  }

  if (maxHoursChanged) {
    changedFrom.push(
      `${t('General.maxHours')}: ${
        Number.parseFloat(`${prevMaxHours}`) >= 0 ? prevMaxHours : '-'
      }`,
    );
    changedTo.push(
      `${t('General.maxHours')}: ${
        Number.parseFloat(`${maxHours}`) >= 0 ? maxHours : '-'
      }`,
    );
  }

  if (totalHoursChanged) {
    changedFrom.push(
      `${t('General.totalHours')}: ${
        Number.parseFloat(`${prevTotalHours}`) >= 0 ? prevTotalHours : '-'
      }`,
    );
    changedTo.push(
      `${t('General.totalHours')}: ${
        Number.parseFloat(`${totalHours}`) >= 0 ? totalHours : '-'
      }`,
    );
  }

  if (!changedFrom.length && !changedTo.length) return null;

  return { changedFrom, changedTo };
};

const getTimeAndPricingChanges = (
  t: TFunction,
  prevTimeAndPricing: TimeAndPricing,
  newTimeAndPricing: TimeAndPricing,
  prevIsLocked: boolean,
  newIsLocked: boolean,
): ChangesReturn => {
  const {
    type: prevType,
    additionalExplanation: prevAdditionalExplanation,
    pricingDurationType: prevPricingDurationType,
  } = prevTimeAndPricing || {};
  const { type, additionalExplanation, pricingDurationType } =
    newTimeAndPricing || {};

  const changedFrom: string[] = [];
  const changedTo: string[] = [];

  const lockedChanges = getLockedChange(t, prevIsLocked, newIsLocked);

  if (lockedChanges) {
    changedFrom.push(lockedChanges.from);
    changedTo.push(lockedChanges.to);
  }

  if (prevType !== type) {
    changedFrom.push(t(`General.${_.camelCase(prevType)}`));
    changedTo.push(t(`General.${_.camelCase(type)}`));
  }

  // Send dummy value for 'locked'
  const timeChanges = getTimeChanges(
    t,
    prevTimeAndPricing,
    newTimeAndPricing,
    false,
    false,
  );

  if (timeChanges) {
    changedFrom.push(...timeChanges.changedFrom);
    changedTo.push(...timeChanges.changedTo);
  }

  if (prevPricingDurationType !== pricingDurationType) {
    // If we didn't have this in prev., it means that is was
    // By project, so we shouldn't add it in changes
    if (prevPricingDurationType) {
      changedFrom.push(
        `${t('General.duration')}: ${t(
          `General.${prevPricingDurationType.toLowerCase()}`,
        )}`,
      );
    }

    changedTo.push(
      `${t('General.duration')}: ${
        pricingDurationType
          ? t(`General.${pricingDurationType.toLowerCase()}`)
          : '-'
      }`,
    );
  }

  // Send dummy value for 'locked'
  const priceChanges = getPriceChanges(
    t,
    prevTimeAndPricing,
    newTimeAndPricing,
    false,
    false,
  );

  if (priceChanges) {
    changedFrom.push(...priceChanges.changedFrom);
    changedTo.push(...priceChanges.changedTo);
  }

  if (prevAdditionalExplanation !== additionalExplanation) {
    changedFrom.push(
      `${t('JobForm.additionalExplanation')}: ${
        prevAdditionalExplanation || '-'
      }`,
    );
    changedTo.push(
      `${t('JobForm.additionalExplanation')}: ${additionalExplanation || '-'}`,
    );
  }

  if (!changedFrom.length && !changedTo.length) return null;

  return { changedFrom, changedTo };
};

const getTypeOfServiceChanges = (
  t: TFunction,
  prevTypeOfService: JobTypeOfService,
  newTypeOfService: JobTypeOfService,
  prevIsLocked: boolean,
  newIsLocked: boolean,
): ChangesReturn => {
  const {
    type: prevType,
    locationType: prevLocationType,
    locations: prevLocations,
  } = prevTypeOfService || {};
  const { type, locationType, locations } = newTypeOfService || {};

  const changedFrom: string[] = [];
  const changedTo: string[] = [];

  const lockedChanges = getLockedChange(t, prevIsLocked, newIsLocked);

  if (lockedChanges) {
    changedFrom.push(lockedChanges.from);
    changedTo.push(lockedChanges.to);
  }

  if (prevType !== type) {
    changedFrom.push(
      `${t('General.serviceType')}: ${t(`General.${prevType.toLowerCase()}`)}`,
    );
    changedTo.push(
      `${t('General.serviceType')}: ${t(`General.${type.toLowerCase()}`)}`,
    );
  }

  if (prevLocationType !== locationType) {
    changedFrom.push(
      `${t('General.locationType')}: ${
        prevLocationType ? t(`General.${_.camelCase(prevLocationType)}`) : '-'
      }`,
    );
    changedTo.push(
      `${t('General.locationType')}: ${
        locationType ? t(`General.${_.camelCase(locationType)}`) : '-'
      }`,
    );
  }

  if (prevLocations?.length !== locations?.length) {
    // Format location based on locationType
    const formattedPrevLocation = formatLocation({
      locationType: prevLocationType,
      locations: prevLocations,
    });

    const formattedNewLocation = formatLocation({
      locationType,
      locations,
    });

    changedFrom.push(`${t('General.location')}: ${formattedPrevLocation}`);
    changedTo.push(`${t('General.location')}: ${formattedNewLocation}`);
  }

  if (!changedFrom.length && !changedTo.length) return null;

  return { changedFrom, changedTo };
};

type FreeCancellation = Pick<
  JobPost<'form'>,
  'freeCancelation' | 'hasFreeCancelation'
>;

const getFreeCancellationChanges = (
  t: TFunction,
  prevFreeCancellation: FreeCancellation,
  newFreeCancellation: FreeCancellation,
  prevIsLocked: boolean,
  newIsLocked: boolean,
  isOffer: boolean,
): ChangesReturn => {
  const {
    hasFreeCancelation: prevHasCancellation,
    freeCancelation: prevCancellation,
  } = prevFreeCancellation || {};

  const { hasFreeCancelation, freeCancelation } = newFreeCancellation || {};

  const {
    cancelationFee: prevCancellationFee,
    cancelationHours: prevCancellationHours,
    isStrict: prevIsStrict,
  } = prevCancellation || {};
  const { cancelationFee, cancelationHours, isStrict } = freeCancelation || {};

  const changedFrom: string[] = [];
  const changedTo: string[] = [];

  const strictCancellationMsg = t('JobForm.strictCancellationMessage');
  const prevCancellationMsg = isOffer
    ? t('General.cancellationValue', {
        hours: prevCancellationHours,
        percent: prevCancellationFee,
      })
    : t('JobForm.nonStrictCancellationMessage', {
        untilHours: prevCancellationHours,
        feePercent: prevCancellationFee,
      });
  const newCancellationMsg = isOffer
    ? t('General.cancellationValue', {
        hours: cancelationHours,
        percent: cancelationFee,
      })
    : t('JobForm.nonStrictCancellationMessage', {
        untilHours: cancelationHours,
        feePercent: cancelationFee,
      });

  const lockedChanges = getLockedChange(t, prevIsLocked, newIsLocked);

  if (lockedChanges) {
    changedFrom.push(lockedChanges.from);
    changedTo.push(lockedChanges.to);
  }

  if (prevHasCancellation !== hasFreeCancelation) {
    // If the new version has free cancellation
    if (hasFreeCancelation === 'true') {
      // If the previous version is strict
      changedFrom.push(
        prevIsStrict ? strictCancellationMsg : prevCancellationMsg,
      );
      changedTo.push(t('JobForm.freeCancellation'));

      // If the new version doesn't have free cancellation
    } else {
      changedFrom.push(t('JobForm.freeCancellation'));
      // If the new version is strict
      changedTo.push(isStrict ? strictCancellationMsg : newCancellationMsg);
    }

    return { changedFrom, changedTo };
  }

  if (prevIsStrict !== isStrict) {
    changedFrom.push(
      prevIsStrict ? strictCancellationMsg : prevCancellationMsg,
    );
    changedTo.push(isStrict ? strictCancellationMsg : newCancellationMsg);

    return {
      changedFrom,
      changedTo,
    };
  }

  if (
    prevCancellationFee !== cancelationFee ||
    prevCancellationHours !== cancelationHours
  ) {
    changedFrom.push(prevCancellationMsg);
    changedTo.push(newCancellationMsg);

    return {
      changedFrom,
      changedTo,
    };
  }

  if (!changedFrom.length && !changedTo.length) return null;

  return { changedFrom, changedTo };
};

type DeadlinePolicy = Pick<JobPost<'form'>, 'hasDeadlinePolicy' | 'deadlines'>;

const getDeadlinePolicyChanges = (
  t: TFunction,
  jobEndDate: Date,
  prevDeadlinePolicy: DeadlinePolicy,
  newDeadlinePolicy: DeadlinePolicy,
  prevIsLocked: boolean,
  newIsLocked: boolean,
): ChangesReturn<JSX.Element[] | string> => {
  const { hasDeadlinePolicy: prevHasPolicy, deadlines: prevDeadlines } =
    prevDeadlinePolicy;
  const { hasDeadlinePolicy, deadlines } = newDeadlinePolicy;

  const changedFrom: (JSX.Element[] | string)[] = [];
  const changedTo: (JSX.Element[] | string)[] = [];

  const formattedPrevDeadlines = getFormattedDeadlines(
    t,
    jobEndDate,
    prevDeadlines,
  );
  const formattedDeadlines = getFormattedDeadlines(t, jobEndDate, deadlines);

  const lockedChanges = getLockedChange(t, prevIsLocked, newIsLocked);

  if (lockedChanges) {
    changedFrom.push(lockedChanges.from);
    changedTo.push(lockedChanges.to);
  }

  if (prevHasPolicy !== hasDeadlinePolicy) {
    // If we have a deadline policy in new version
    if (hasDeadlinePolicy === 'true') {
      changedFrom.push(t('General.noDeadlinePolicy'));
      changedTo.push(formattedDeadlines);

      // If we don't have a deadline policy in new version
    } else {
      changedFrom.push(formattedPrevDeadlines);
      changedTo.push(t('General.noDeadlinePolicy'));
    }
  }

  const areDeadlinesSame =
    prevDeadlines?.length === deadlines?.length &&
    prevDeadlines?.reduce((areSame, d1, i) => {
      const d2 = deadlines?.[i];

      return (
        +d1?.fromDate === +d2?.fromDate &&
        +d1?.toDate === +d2?.toDate &&
        d1?.percentOfJobValue === d2?.percentOfJobValue
      );
    }, false);

  if (
    prevHasPolicy === 'true' &&
    prevHasPolicy === hasDeadlinePolicy &&
    !areDeadlinesSame
  ) {
    changedFrom.push(formattedPrevDeadlines);
    changedTo.push(formattedDeadlines);
  }

  if (!changedFrom.length && !changedTo.length) return null;

  return { changedFrom, changedTo };
};

type Arbitration = Pick<
  JobPost<'form'> | JobPost,
  'hasArbitration' | 'arbitrationOptions'
>;

const getChangedArbitrations = (
  t: TFunction,
  skills: OptionValue[],
  arbitrations: Arbitration['arbitrationOptions'],
  changedFields?: {
    numberChanged: boolean;
    typeChanged: boolean;
    feeChanged: boolean;
    arbitratorsChanged: boolean;
    filtersChanged: boolean;
  },
  isOffer?: boolean,
) => {
  const changes: (string | string[])[] = [];

  const {
    numberChanged = true,
    typeChanged = true,
    feeChanged = true,
    arbitratorsChanged = true,
    filtersChanged = true,
  } = changedFields || {};

  const numberTypeOrFeeChanged = numberChanged || typeChanged || feeChanged;

  (arbitrations || []).forEach((arb) => {
    const {
      arbitratorsNumber,
      arbitratorsType,
      arbitratorsFee,
      arbitrators,
      filterQueryFormValues,
    } = arb;

    if (numberTypeOrFeeChanged) {
      if (isOffer) {
        changes.push(t('Arbitration.earnInfo'));
      } else {
        if (typeChanged)
          changes.push(
            `${t('ArbitrationSetUp.arbitratorsType')}: ${t(
              `ArbitrationSetUp.${_.camelCase(arbitratorsType)}`,
            )}`,
          );
        if (numberChanged)
          changes.push(
            `${t(
              'ArbitrationSetUp.numberOfArbitrators',
            )}: ${arbitratorsNumber}`,
          );
        if (feeChanged)
          changes.push(
            `${t('ArbitrationSetUp.arbitratorsFee')}: ${arbitratorsFee}%`,
          );

        if (feeChanged || numberChanged) {
          const totalFee = `${arbitratorsFee}% x ${arbitratorsNumber} = ${
            arbitratorsFee * arbitratorsNumber
          }%`;

          changes.push(
            `${t('ArbitrationSetUp.arbitratorsTotalFee')}: ${totalFee}`,
          );
        }
      }
    }

    if (arbitratorsChanged) {
      const arbitratorsJoined = arbitrators
        ?.map((user) => formatUserDisplayName(user))
        ?.join(', ');

      changes.push(`${t('General.arbitrators')}: ${arbitratorsJoined || '-'}`);
    }

    if (filtersChanged && filterQueryFormValues) {
      const filters = Object.keys(filterQueryFormValues).map((filterName) => {
        const filterLabel = t(`General.${filterName}`);

        const filterValue = formatArbitrationFilterValue(
          t,
          filterName as keyof typeof filterQueryFormValues,
          filterQueryFormValues,
          getSkillsAsLabels(skills, filterQueryFormValues.skills),
        );

        return `${filterLabel}: ${filterValue}`;
      });

      changes.push(filters);
    }
  });

  if (!changes.length) return null;

  return changes;
};

const getArbitrationChanges = (
  t: TFunction,
  skills: OptionValue[],
  prevArbitration: Arbitration,
  newArbitration: Arbitration,
  prevIsLocked: boolean,
  newIsLocked: boolean,
  isOffer: boolean,
): ChangesReturn<string | string[]> => {
  const { arbitrationOptions: prevArbitrationOptions } = prevArbitration;
  const { arbitrationOptions } = newArbitration;

  const prevHasArbitration = `${prevArbitration.hasArbitration}`;
  const hasArbitration = `${newArbitration.hasArbitration}`;

  const changedFrom: (string | string[])[] = [];
  const changedTo: (string | string[])[] = [];

  const lockedChanges = getLockedChange(t, prevIsLocked, newIsLocked);

  if (lockedChanges) {
    changedFrom.push(lockedChanges.from);
    changedTo.push(lockedChanges.to);
  }

  let typeChanged: boolean;
  let numberChanged: boolean;
  let feeChanged: boolean;
  let arbitratorsChanged: boolean;
  let filtersChanged: boolean;
  let arbitrationsSame =
    prevArbitrationOptions?.length === arbitrationOptions?.length;

  for (
    let index = 0;
    index <
    Math.max(
      prevArbitrationOptions?.length || 0,
      arbitrationOptions?.length || 0,
    );
    index++
  ) {
    const {
      arbitrators: prevArbitrators,
      arbitratorsFee: prevFee,
      arbitratorsNumber: prevNumber,
      arbitratorsType: prevType,
      arbitratorsFilterQuery: prevFilterQuery,
    } = prevArbitrationOptions?.[index] || {};
    const {
      arbitrators,
      arbitratorsFee,
      arbitratorsNumber,
      arbitratorsType,
      arbitratorsFilterQuery,
    } = arbitrationOptions?.[index] || {};

    feeChanged = prevFee !== arbitratorsFee;
    numberChanged = prevNumber !== arbitratorsNumber;
    typeChanged = prevType !== arbitratorsType;

    arbitratorsChanged = !_.isEqualWith(
      prevArbitrators || [],
      arbitrators || [],
      (arb1: User, arb2: User) => arb1?.id === arb2?.id,
    );

    const numberTypeOrFeeChanged = feeChanged || numberChanged || typeChanged;

    filtersChanged = prevFilterQuery !== arbitratorsFilterQuery;

    arbitrationsSame =
      !numberTypeOrFeeChanged && !filtersChanged && !arbitratorsChanged;
  }

  if (prevHasArbitration !== hasArbitration) {
    // If new version has arbitration
    if (hasArbitration === 'true') {
      changedFrom.push(t('General.noArbitration'));
      changedTo.push(
        ...getChangedArbitrations(
          t,
          skills,
          arbitrationOptions,
          undefined,
          isOffer,
        ),
      );
      // If new version doesn't have arbitration
    } else {
      changedFrom.push(
        ...getChangedArbitrations(
          t,
          skills,
          prevArbitrationOptions,
          undefined,
          isOffer,
        ),
      );
      changedTo.push(t('General.noArbitration'));
    }
  } else if (!arbitrationsSame) {
    const changeStates = {
      arbitratorsChanged,
      filtersChanged,
      feeChanged,
      numberChanged,
      typeChanged,
    };

    changedFrom.push(
      ...getChangedArbitrations(
        t,
        skills,
        prevArbitrationOptions,
        changeStates,
        isOffer,
      ),
    );
    changedTo.push(
      ...getChangedArbitrations(
        t,
        skills,
        arbitrationOptions,
        changeStates,
        isOffer,
      ),
    );
  }

  if (!changedFrom.length && !changedTo.length) return null;

  return { changedFrom, changedTo };
};

const getAttachmentChanges = (
  prevAttachments: { name: string; url: string; originalFileId?: number }[],
  attachments: { name: string; url: string; originalFileId?: number }[],
): ChangesReturn<JSX.Element> => {
  const changedFrom: JSX.Element[] = [];
  const changedTo: JSX.Element[] = [];

  let areAttachmentsEqual = true;

  for (let index = 0; index < prevAttachments?.length || 0; index++) {
    const prevAttch = prevAttachments?.[index];
    const attch = attachments?.[index];

    if (prevAttch?.originalFileId && attch?.originalFileId)
      areAttachmentsEqual = prevAttch.originalFileId === attch.originalFileId;
  }

  if (prevAttachments?.length !== attachments?.length || !areAttachmentsEqual) {
    prevAttachments?.forEach((att) => {
      changedFrom.push(
        att.url ? (
          <ExternalLink href={att.url}>
            {att.name}
            <OpenNewWindowIcon
              width={12}
              style={{ verticalAlign: 'middle', marginLeft: '4px' }}
            />
          </ExternalLink>
        ) : (
          <span>-</span>
        ),
      );
    });

    if (!changedFrom.length && attachments?.length)
      changedFrom.push(<span>-</span>);

    attachments?.forEach((att) => {
      changedTo.push(
        att.url ? (
          <ExternalLink href={att.url}>
            {att.name}
            <OpenNewWindowIcon
              width={12}
              style={{ verticalAlign: 'middle', marginLeft: '4px' }}
            />
          </ExternalLink>
        ) : (
          <span>-</span>
        ),
      );
    });

    if (prevAttachments?.length && !changedTo.length)
      changedTo.push(<span>-</span>);
  }

  if (!changedFrom.length && !changedTo.length) return null;

  return { changedFrom, changedTo };
};

const isPendingChanges = (
  versionState: 'Pending' | 'Accepted' | 'Awaiting payment',
) => {
  return versionState === 'Pending' || versionState === 'Awaiting payment';
};

export {
  getTextChanges,
  getContractInfoChanges,
  getSkillsChanges,
  getTimeChanges,
  getPriceChanges,
  getTimeAndPricingChanges,
  getTypeOfServiceChanges,
  getFreeCancellationChanges,
  getDeadlinePolicyChanges,
  getArbitrationChanges,
  getAttachmentChanges,
  isPendingChanges,
};
