import React from 'react';
import { Button, Modal } from 'ncoded-component-library';
import { createRoot } from 'react-dom/client';
import classNames from 'classnames';

import './Confirm.styles.scss';

export type ConfirmModalProps = {
  description?: React.ReactNode;
  confirmContent?: React.ReactNode;
  cancelContent?: React.ReactNode;
  title?: React.ReactNode;
  onClose?: () => void;
  onConfirm?: () => void;
};

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  title,
  description,
  confirmContent = 'OK',
  cancelContent = 'Cancel',
  onClose,
  onConfirm,
}) => {
  return (
    <Modal
      open
      className={classNames('anys-confirm', {
        'anys-confirm--with-title': !!title,
        'anys-confirm--no-desc': !description,
      })}
      title={title}
      footer={
        <div className="anys-confirm__buttons">
          {confirmContent && (
            <Button type="button" onClick={onConfirm}>
              {confirmContent}
            </Button>
          )}

          {cancelContent && (
            <Button type="button" variant="link" onClick={onClose}>
              <span>{cancelContent}</span>
            </Button>
          )}
        </div>
      }
    >
      {description ? (
        <div className="anys-confirm__content">{description}</div>
      ) : null}
    </Modal>
  );
};

const confirm = async (config: ConfirmModalProps) => {
  const { onConfirm, onClose, ...rest } = config;

  const rootEl = createRoot(document.createElement('div'));

  const cleanup = () => {
    rootEl.unmount();
  };

  return new Promise<boolean>((resolve) => {
    rootEl.render(
      <ConfirmModal
        onClose={() => {
          onClose?.();
          cleanup();
          resolve(false);
        }}
        onConfirm={() => {
          onConfirm?.();
          cleanup();
          resolve(true);
        }}
        {...rest}
      />,
    );
  });
};

export default confirm;
