import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { FieldRenderProps } from 'react-final-form';
import { Select } from 'ncoded-component-library';
import useMatchMedia from 'hooks/useMatchMedia';
import PhabletSearchableSelect from 'components/PhabletSearchableSelect';
import Badge from 'components/Badge';
import SelectLabel from 'components/SelectLabel';
import type { OptionValue } from 'ncoded-component-library/build/components/molecules/Select/Select.component';

import './MultipleSelectField.styles.scss';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import formValidators from '../../utils/formValidators';
import ValidationError from '../ValidationError';
import SelectUtils from 'ncoded-component-library/build/components/molecules/Select/Select.utils';

type MultipleSelectFieldProps = FieldRenderProps<
  (string | number | OptionValue<string | number>)[]
> & {
  asyncFunc?: (search?: string) => Promise<OptionValue<string | number>[]>;
  ignoreAsync?: boolean;
  options: OptionValue<string | number>[];
  hideTags?: boolean;
  className?: string;
  onChange?: (
    value: (string | number | OptionValue<string | number>)[],
  ) => void;
  mapFunction?: (value: any) => any;
  singleSelect?: boolean;
};

const MultipleSelectField: React.FC<MultipleSelectFieldProps> = (props) => {
  const {
    className,
    ignoreAsync, // once this is set to true, it will always pull only data that is passed in here, it will ignore the additional server data fetching
    options: propsOptions,
    label,
    input: { onChange: inputOnChange, value, name },
    onChange: propOnChange,
    icon,
    hideTags = false,
    singleSelect,
    mapFunction,
    meta,
    asyncFunc,
    ...rest
  } = props;

  const { t } = useTranslation();
  const initialLoad = useRef<boolean>(false);
  const isPhablet = useMatchMedia('isPhablet');
  const [options, setOptions] = useState<OptionValue<string | number>[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [debouncedSearch, setDebouncedSearch] = useState(null);

  const onChange = useCallback(
    (values: (string | number | OptionValue<string | number>)[]) => {
      inputOnChange(values);
      propOnChange?.(values);
    },
    [inputOnChange, propOnChange],
  );

  const tags = useMemo(() => {
    if (!value || hideTags) {
      return null;
    }

    return (
      <div className="anys-multiple-select-field__tags">
        {options
          .filter((o) => value.map(String).includes(o.value.toString()))
          .map((el) => (
            <Badge
              key={el.value.toString()}
              onClick={() =>
                onChange(
                  value.filter((v) => v.toString() !== el.value.toString()),
                )
              }
            >
              <SelectLabel label={el.label} />
            </Badge>
          ))}
      </div>
    );
  }, [onChange, hideTags, options, value]);

  const classes = classNames('anys-multiple-select-field', className);

  const asyncFetch = useCallback(
    async (search?: string) => {
      const data = await asyncFunc(search);
      const map = mapFunction
        ? mapFunction
        : (option: any) => ({
            value: option.name,
            label: option.name,
          });
      setOptions(data.map(map));
      setIsLoading(false);
    },
    [asyncFunc, mapFunction],
  );

  const { error, hasError } = useMemo(
    () => formValidators.getErrorFromMeta(meta),
    [meta],
  );

  useEffect(() => {
    if (ignoreAsync) {
      setOptions(
        mapFunction ? propsOptions.map(mapFunction) : propsOptions || [],
      );
      return;
    }

    if (initialLoad.current) {
      return;
    }
    initialLoad.current = true;

    asyncFetch();
  }, [asyncFetch, asyncFunc, ignoreAsync, mapFunction, propsOptions]);

  useEffect(() => {
    if (!asyncFunc) {
      return;
    }

    setDebouncedSearch(() =>
      debounce(async (search) => {
        await asyncFetch(search);
      }, 300),
    );
  }, [asyncFetch, asyncFunc]);

  return (
    <div className={classes}>
      {label && <label htmlFor={name}>{label}</label>}

      {isPhablet ? (
        <>
          <PhabletSearchableSelect
            options={options}
            multiple={!singleSelect ? true : null}
            {...rest}
            value={value}
            inputValue={
              SelectUtils.isOptionValue(value?.[0]) && String(value?.[0].label)
            }
            icon={icon}
            handleAdd={(o) => {
              if (singleSelect) {
                return onChange([o]);
              }
              onChange([...value, o.value]);
            }}
            handleRemove={(o) => {
              onChange(value.filter((v) => v !== o.value));
            }}
            hasError={hasError}
            noOptionsFoundContent={
              <div>
                {isLoading ? t('Skills.loading') : t('Skills.nothingFound')}
              </div>
            }
            onSearchChange={(search) => {
              if (ignoreAsync) {
                if (!search?.target?.value) {
                  setOptions(
                    mapFunction
                      ? propsOptions.map(mapFunction)
                      : propsOptions || [],
                  );
                  return;
                }

                const filtered = options.filter((v) => {
                  return v.label
                    .toString()
                    .toLowerCase()
                    .includes(search?.target?.value?.toLowerCase());
                });
                setOptions(filtered);
                return;
              }

              setIsLoading(true);
              debouncedSearch(search.target.value);
            }}
          />
          <ValidationError error={error} showError={hasError} />
        </>
      ) : (
        <>
          <Select
            searchable
            multiple={!singleSelect ? true : null}
            options={options}
            {...rest}
            value={value}
            selectedOptionsContent={() => null}
            hasError={hasError}
            noOptionsFoundContent={
              <div>{isLoading ? 'Loading...' : 'Nothing found.'}</div>
            }
            onSearchChange={
              !ignoreAsync
                ? (search) => {
                    setIsLoading(true);
                    debouncedSearch(search.target.value);
                  }
                : null
            }
            onChange={onChange}
            icon={icon}
          />
          <ValidationError error={error} showError={hasError} />
        </>
      )}

      {tags}
    </div>
  );
};

export default MultipleSelectField;
