import React from 'react';
import EmojiCryIcon from 'icons/EmojiCry.icon';
import EmojiLaughIcon from 'icons/EmojiLaugh.icon';
import EmojiPokerFaceIcon from 'icons/EmojiPokerFace.icon';
import EmojiSadIcon from 'icons/EmojiSad.icon';
import EmojiSmileIcon from 'icons/EmojiSmile.icon';

type EmojiReviewRatingProps = {
  className?: string;
  grade: number;
};

const EmojiReviewRating: React.FC<EmojiReviewRatingProps> = (props) => {
  const { grade, ...rest } = props;

  if (!grade) return null;

  if (grade < 1) return <EmojiCryIcon {...rest} />;
  if (grade < 2) return <EmojiSadIcon {...rest} />;
  if (grade < 3) return <EmojiPokerFaceIcon {...rest} />;
  if (grade < 4) return <EmojiSmileIcon {...rest} />;
  if (grade <= 5) return <EmojiLaughIcon {...rest} />;

  return null;
};

export default EmojiReviewRating;
