import React, { useCallback, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Badge from 'components/Badge';
import AddIcon from 'icons/Add.icon';
import GradientText from 'components/GradientText';
import Modal, { ModalRef } from 'components/Modal';
import SkillsModal from 'router/subrouters/Search/pages/Search/components/SkillsModal';
import { FormApi } from 'final-form';
import { BoostFormValues } from 'models/Boosts';
import { formatLocation } from 'utils/location';
import { InputAutocompleteLocationField } from 'components/InputAutocompleteLocation';
import formValidators from 'utils/formValidators';
import AnyCoinConversion from 'components/AnyCoinConversion';
import {
  BOOST_VIEW_COIN_COST,
  MAX_COIN_BUDGET,
  MIN_COIN_BUDGET,
  coinToMoneyInUnits,
  getUnitCostForBoost,
} from 'utils/boosts';
import TimesCircleIcon from 'icons/TimesCircle.icon';
import { LocationType } from 'models/User';
import InputField from 'components/InputField';
import { ReactComponent as AnyCoinIcon } from 'icons/anycoin.svg';
import { convertUnitToSubUnit, formatMoney, unitDivisor } from 'utils/currency';
import { Currency } from 'constants/currency';
import CheckboxField from 'components/CheckboxField';
import DateTimeField from 'components/DateTimeField';
import dates from 'utils/dates';
import { Skill } from 'models/Skills';
import ToolTip from 'components/ToolTip';

import './PositionBoosting.styles.scss';
import './PositionBoosting.styles.responsive.scss';
import useGetSkillGroups from '../../../../hooks/useGetSkillGroups';

type PositionBoostingProps = {
  className?: string;
  formApi: FormApi<BoostFormValues>;
  formValues: BoostFormValues;
  currency: Currency;
  isDefineTimeSelected: boolean;
  jobSelectedSkills: Skill[];
  budgetSpent: number;
};

const PositionBoosting: React.FC<PositionBoostingProps> = (props) => {
  const {
    className,
    formApi,
    formValues,
    currency,
    isDefineTimeSelected,
    jobSelectedSkills,
    budgetSpent,
  } = props;

  const { t } = useTranslation();
  const skillsModalRef = useRef<ModalRef>();
  const locationModalRef = useRef<ModalRef>();

  const { allSkills, skillGroups } = useGetSkillGroups();

  const { skillIds, locations, locationInput, budget } = formValues;

  const classes = classNames('anys-position-boosting', className);

  const skills = useMemo(
    () => allSkills?.filter((skill) => skillIds?.includes(skill.id)),
    [allSkills, skillIds],
  );

  const viewCostField = useMemo(
    () => (
      <div className="anys-position-boosting__view-cost">
        <div>
          <span className="fw-600">{t('General.view')}</span>
          <AnyCoinConversion
            anyCoinAmount={BOOST_VIEW_COIN_COST.VIEW}
            anyCoinCostWithoutSubunits={getUnitCostForBoost('VIEW')}
            fractionDigits={1}
            className="anys-position-boosting__view-cost__coin"
          />
        </div>
        <div>
          <span className="fw-600">{t('General.hotView')}</span>
          <AnyCoinConversion
            anyCoinAmount={BOOST_VIEW_COIN_COST.HOT_VIEW}
            anyCoinCostWithoutSubunits={getUnitCostForBoost('HOT_VIEW')}
            fractionDigits={2}
            className="anys-position-boosting__view-cost__coin"
          />
        </div>
      </div>
    ),
    [t],
  );

  const budgetSpentRow = (
    <div className="anys-position-boosting__budget-spent">
      {/* Using className from budget field */}
      <span className="anys-position-boosting__budget__label">
        {t('General.moneySpent')}
      </span>
      <span className="anys-position-boosting__budget-spent__cost">
        {`${formatMoney(t, {
          currency,
          amount: coinToMoneyInUnits(Math.abs(+budgetSpent || 0)),
        })}`}
      </span>
    </div>
  );

  const budgetField = useMemo(
    () => (
      <div className="anys-position-boosting__budget">
        <InputField
          type="number"
          isPureNumberInput
          name="budget"
          placeholder={'0'}
          label={t('General.remainingBudget')}
          validate={formValidators.composeValidators(
            formValidators.minValue(
              t('General.atLeastValidation', { min: MIN_COIN_BUDGET }),
              MIN_COIN_BUDGET,
              true,
            ),
            formValidators.maxValue(
              t('General.atMostValidation', { max: MAX_COIN_BUDGET }),
              MAX_COIN_BUDGET,
              true,
            ),
          )}
          suffixNode={<AnyCoinIcon />}
          labelClassName="anys-position-boosting__budget__label"
          min={1}
        />
        <span className="anys-position-boosting__budget__cost">
          {`= ${formatMoney(t, {
            currency,
            amount: convertUnitToSubUnit(
              coinToMoneyInUnits(+budget || 0),
              unitDivisor(currency),
            ),
          })}`}
        </span>
      </div>
    ),
    [budget, currency, t],
  );

  const onAddSkills = useCallback(
    (selectedSkillIds: number[]) => {
      formApi.change('skillIds', selectedSkillIds);

      skillsModalRef.current.close();
    },
    [formApi],
  );

  const onAddLocation = useCallback(() => {
    formApi.batch(() => {
      formApi.change('locations', [...(locations || []), locationInput]);
      formApi.change('locationInput', null);
    });

    locationModalRef.current.close();
  }, [formApi, locationInput, locations]);

  const onRemoveLocation = useCallback(
    (location: LocationType) => {
      const newLocations = locations?.filter(
        (loc, i) => loc.lat !== location.lat && loc.lng !== location.lng,
      );

      formApi.change('locations', newLocations);
    },
    [formApi, locations],
  );

  const onCloseLocationModal = useCallback(() => {
    formApi.change('locationInput', null);

    locationModalRef.current.close();
  }, [formApi]);

  const filteredSkillGroups = useMemo(() => {
    const jobSelectedSkillsIds = jobSelectedSkills.map((skill) => skill.id);
    return skillGroups.map((skillGroup) => ({
      ...skillGroup,
      skills: skillGroup.skills.filter((skill) =>
        jobSelectedSkillsIds.includes(skill.id),
      ),
    }));
  }, [jobSelectedSkills, skillGroups]);

  return (
    <section className={classes}>
      <div className="anys-position-boosting__title">
        <h2>
          {t('General.boosting')}
          <ToolTip t={t} tooltipName="position-boosting-tooltip">
            {t('General.positionBoostTooltip')}
          </ToolTip>
        </h2>
        <div>{t('General.boostingDesc')}</div>
      </div>
      <div className="anys-position-boosting__content">
        <div className="anys-position-boosting__content__skills-and-loc">
          <div>
            <h3 className="anys-position-boosting__subtitle">
              {t('General.skills')}
            </h3>
            <div className="anys-position-boosting__skills">
              {skills?.length
                ? skills.map((skill) => {
                    const { id, name } = skill;

                    return (
                      <Badge
                        key={id}
                        onClick={() =>
                          formApi.change(
                            'skillIds',
                            skillIds.filter((skillId) => skillId !== id),
                          )
                        }
                        className="anys-position-boosting__skills__skill"
                      >
                        {name}
                      </Badge>
                    );
                  })
                : t('Skills.noAddedSkills')}
            </div>
            <button
              type="button"
              className="anys-position-boosting__add-boost"
              onClick={() => skillsModalRef.current.open()}
            >
              <AddIcon />
              <GradientText>{t('General.addSkill')}</GradientText>
            </button>
          </div>
          <div>
            <h3 className="anys-position-boosting__subtitle">
              {t('General.location')}
            </h3>
            <ul className="anys-position-boosting__locations">
              {locations?.length
                ? locations.map((location, i) => (
                    <li key={location.lat + i}>
                      <div>
                        <div>
                          {formatLocation({
                            locationType: 'One spot',
                            locations: [location],
                          })}
                        </div>
                        <button
                          type="button"
                          onClick={() => onRemoveLocation(location)}
                          className="anys-position-boosting__locations__remove"
                        >
                          <TimesCircleIcon />
                        </button>
                      </div>
                    </li>
                  ))
                : t('General.noLocation')}
            </ul>
            <button
              type="button"
              className="anys-position-boosting__add-boost"
              onClick={() => locationModalRef.current.open()}
            >
              <AddIcon />
              <GradientText>{t('General.addLocation')}</GradientText>
            </button>
          </div>
          <div className="hide-on-mobile">{viewCostField}</div>
        </div>
        <div className="anys-position-boosting__time-boosting">
          <div className="anys-position-boosting__time-boosting__title-wrapper">
            <div className="anys-position-boosting__time-boosting__title">
              <h3 className="anys-position-boosting__subtitle">
                {t('General.timeBoosting')}
              </h3>
              <div className="anys-position-boosting__time-boosting__desc">
                {t('General.timeBoostingDesc')}
              </div>
            </div>
            <div className="hide-on-mobile">
              {budgetSpentRow}
              {budgetField}
            </div>
          </div>
          <div className="anys-position-boosting__time-boosting__time">
            <CheckboxField
              name="defineTime"
              label={t('General.defineTime')}
              onChange={(isChecked) => {
                if (isChecked) return;

                formApi.batch(() => {
                  formApi.change('startDate', undefined);
                  formApi.change('endDate', undefined);
                });
              }}
            />
            {isDefineTimeSelected && (
              <>
                <DateTimeField
                  name="startDate"
                  label={t('General.startTime')}
                  isSingleInput
                  renderAsPortal
                  validate={formValidators.dateIsAfter(
                    t('General.dateAfterError', {
                      limit: dates.formatDeadlineDate(new Date()),
                    }),
                    new Date(),
                  )}
                />
                <DateTimeField
                  name="endDate"
                  label={t('General.endTime')}
                  isSingleInput
                  renderAsPortal
                />
              </>
            )}
          </div>
          <div className="hide-on-desktop">
            <div className="mb-24">{viewCostField}</div>
            {budgetSpentRow}
            {budgetField}
          </div>
        </div>
      </div>

      <SkillsModal
        ref={skillsModalRef}
        initialValues={skillIds}
        loading={false}
        onSubmit={onAddSkills}
        keepOpenOnRefresh={false}
        skillGroups={filteredSkillGroups}
      />
      <Modal
        ref={locationModalRef}
        type="action"
        modalName="location-boost-modal"
        keepOpenOnRefresh={false}
        primaryActionContent={t('General.add')}
        secondaryActionContent={t('General.cancel')}
        title={t('General.addLocation')}
        isFullscreen
        onPrimaryAction={onAddLocation}
        onSecondaryAction={onCloseLocationModal}
        isDrawer
        className="anys-position-boosting__location-modal"
      >
        <InputAutocompleteLocationField
          name="locationInput"
          validate={formValidators.required(t('JobForm.locationRequired'))}
        />
      </Modal>
    </section>
  );
};

export default PositionBoosting;
