import React, { useContext, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { Button, Loader } from 'ncoded-component-library';
import { useNavigate } from 'react-router-dom';
import FileInput from 'components/FileInput';
import Camera from 'icons/Camera.icon';
import ProfileInfo from 'components/ProfileInfo';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import { useProfile } from '../../../Profile/Profile.page';
import { t } from 'i18next';
import ProfileContext from '../../providers/Profile/Profile.context';
import Availability from '../Availability';
import Modal, { ModalRef } from 'components/Modal';
import useCallbackRef from 'hooks/useCallbackRef';
import useHeaderContent from 'hooks/useHeaderContent';
import useHeaderStyles from 'hooks/useHeaderStyles';
import NotificationsContext from 'providers/Notifications/Notifications.context';
import MenuDropdown from 'components/NavBar/MenuDropdown';
import useKycVerifyPrompt from 'hooks/useKycVerifyPrompt';
import SendUserMessage from 'components/SendUserMessage';
import VerifyBusinessModal from './VerifyBusinessModal/VerifyBusinessModal.component';
import OverlaySpinner from 'components/OverlaySpinner';

import './ProfileMain.styles.scss';
import './ProfileMain.styles.responsive.scss';

type ProfileMainProps = {
  className?: string;
  canShowAvailability: boolean;
};

const ProfileMain: React.FC<ProfileMainProps> = (props) => {
  const { className, canShowAvailability } = props;

  const { isMine, setShowAvailabilityModal } = useContext(ProfileContext);
  const { currentUser, uploadUserFile } = useContext(CurrentUserContext);
  const { notificationsCount } = useContext(NotificationsContext);
  const { profileUser, availability, setProfileUser } = useProfile();
  const navigate = useNavigate();

  const classes = classNames('anys-profile-main', className);

  const [saveFilterModal, saveFilterModalRef] = useCallbackRef<ModalRef>(null);

  const verifyBusinessModal = useRef<ModalRef>();

  const { hasVerifiedIdentity, verifyButton: veriffButton } =
    useKycVerifyPrompt();

  const isBusinessProfile = profileUser?.role === 'Business';

  const isUserVerified = isBusinessProfile
    ? profileUser?.hasVerifiedBusiness
    : hasVerifiedIdentity;

  const verifyBusinessButton = useMemo(
    () => (
      <Button
        onClick={() => {
          verifyBusinessModal.current?.open();
        }}
      >
        {t('General.verify')}
      </Button>
    ),
    [],
  );

  const verifyButton = useMemo(
    () => (isBusinessProfile ? verifyBusinessButton : veriffButton),
    [isBusinessProfile, veriffButton, verifyBusinessButton],
  );

  useHeaderStyles({
    hasBorder: false,
    absolutePosition: true,
    className: 'anys-profile-main__mobile-header',
    showBackButton: true,
  });

  useHeaderContent(
    <div className="anys-profile-main__header">
      {!isUserVerified && isMine && (
        <div className="anys-profile-main__verify-button--mobile">
          {verifyButton}
        </div>
      )}
      {isMine && (
        <Button
          className="anys-profile-main__header__edit-button"
          onClick={() => {
            navigate('edit');
          }}
        >
          {t('Profile.editProfile')}
        </Button>
      )}
      <MenuDropdown />
    </div>,
    [navigate, t, isMine, notificationsCount, isUserVerified, !!currentUser],
  );

  return (
    <div className={classes}>
      {profileUser.id ? (
        <img
          className="anys-profile-main__image"
          src={profileUser?.coverImage?.url || '/android-chrome-512x512.png'}
          alt="cover-img"
        />
      ) : (
        <div className="anys-profile-main__image">
          <Loader />
        </div>
      )}

      {!isUserVerified && isMine && (
        <div className="anys-profile-main__verify-button--desktop">
          {verifyButton}
        </div>
      )}

      {isMine && (
        <Button
          className="anys-profile-main__edit-button"
          onClick={() => {
            navigate('edit');
          }}
        >
          {t('Profile.editProfile')}
        </Button>
      )}

      {isMine && (
        <FileInput
          className="anys-profile-main__change-cover"
          trigger={
            <span className="anys-profile-main__change-cover__btn">
              <Camera />
            </span>
          }
          onChange={(files) => {
            OverlaySpinner.show('.anys-profile-main');

            uploadUserFile('coverImage', files)
              .then((value) => {
                setProfileUser(value);
              })
              .finally(() => {
                OverlaySpinner.hide('.anys-profile-main');
              });
          }}
          accept="image/*"
        />
      )}

      {profileUser.id && (
        <ProfileInfo
          className="anys-profile-main__profile-info"
          user={profileUser}
          edit={isMine}
        />
      )}
      {!isMine && (
        <div className="anys-profile-main__actions">
          <SendUserMessage className="message-me" userId={profileUser?.id}>
            {t('Profile.message')}
          </SendUserMessage>

          {canShowAvailability && (
            <Button
              variant="outline"
              className="availability-btn"
              onClick={() => saveFilterModal.open()}
            >
              <span>{t('General.availability')}</span>
            </Button>
          )}
          {/* <p className="not-available">
            <NotAllowedIcon />
            <span>{t('Profile.notAvailable')}</span>
            <small>{t('Profile.availableFrom')}</small>
          </p> */}
        </div>
      )}

      <Modal
        type="no-action"
        modalName="preview-availability"
        // open={true}
        ref={saveFilterModalRef}
        title={t('General.availability')}
        // onClose={() => setAvailabilityPreviewModal(false)}
        // isFullscreen
        isDrawer
      >
        <Availability
          renderInModal
          availability={availability}
          isMine={isMine}
          setShowAvailabilityModal={setShowAvailabilityModal}
        />
      </Modal>

      <VerifyBusinessModal
        modalRef={verifyBusinessModal}
        closeModal={() => verifyBusinessModal.current?.close()}
      />
    </div>
  );
};

export default ProfileMain;
