import React from 'react';
import { t } from 'i18next';
import { useProfile } from '../../../Profile/Profile.page';
import LanguagesPreview from './components/LanguagesPreview';
import ProfileCardModal from '../ProfileCardModal';

const Languages = () => {
  const {
    profileUser: { languages },
  } = useProfile();

  return languages?.length ? (
    <ProfileCardModal
      className="anys-profile-preview__body__with-out-padding"
      cardTitle={t('General.language')}
      modalName="languages-modal"
      modalTitle="Languages"
      itemList={languages.map((language, index) => (
        <LanguagesPreview key={index} language={language} />
      ))}
    />
  ) : null;
};

export default Languages;
