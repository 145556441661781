import { Filter } from 'models/Filters';
import httpClient from '../httpClient';

type FiltersResponse = Filter[];

export type PriceRangeType = { minTotalPrice: number; maxTotalPrice: number };

const favouriteFiltersPath = (routePath: TemplateStringsArray) =>
  `favourite-filters/${routePath}`;

const getFavouriteFilters = () =>
  httpClient.get<FiltersResponse>(favouriteFiltersPath``);

const createFavouriteFilter = (
  name: string,
  query: string,
  formValues: object,
) =>
  httpClient.post<Filter>(favouriteFiltersPath``, {
    name,
    query,
    filterQueryFormValues: formValues,
  });

const updateFavouriteFilter = (
  id: number,
  name: string,
  query: string,
  formValues: object,
) =>
  httpClient.put<Filter>(favouriteFiltersPath`` + id, {
    name,
    query,
    filterQueryFormValues: formValues,
  });

const removeFavouriteFilter = (id: number) =>
  httpClient.delete<{ message: string }>(favouriteFiltersPath`` + id);

export default {
  getFavouriteFilters,
  createFavouriteFilter,
  updateFavouriteFilter,
  removeFavouriteFilter,
};
