import ProfileCard from 'components/Card/ProfileCardComponent';
import Map from 'components/Map';
import { t } from 'i18next';
import { PlaceFormat } from 'models/User';
import React from 'react';

import './LocationPreview.styles.scss';

const LocationPreview: React.FC<Omit<PlaceFormat, 'bounds'>> = (props) => {
  const { lat, lng, city, street, country, fullAddress } = props;

  return (
    <ProfileCard
      cardTitle={t('Filters.locations_one')}
      className="location-preview"
    >
      {lat && lng ? (
        <>
          {fullAddress ? (
            <span className="location-preview__city-name">{fullAddress}</span>
          ) : (
            <>
              {street ? (
                <span className="location-preview__city-name">{street}</span>
              ) : null}
              <span className="location-preview__city-name">{city}</span>
            </>
          )}

          <span className="location-preview__country-name">{country}</span>

          <Map center={{ lat, lng }} zoom={10} />
        </>
      ) : (
        <p>{t('General.noLocationPlaceholder')}</p>
      )}
    </ProfileCard>
  );
};

export default LocationPreview;
