import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import VeriffObject from '@veriff/js-sdk';
import env from 'env';
import { createVeriffFrame } from '@veriff/incontext-sdk';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import { VeriffSessionResponse, VerifyResponse } from 'models/Verification';
import bus, { EventName } from 'modules/bus';
import api from 'api';
import showToast from 'modules/showToast';
import { useTranslation } from 'react-i18next';
import { VerificationStatus } from 'models/Verification';
import useBusListener from './useBusListener';

// Import type is wrong, so we fix it here
const Veriff = (VeriffObject as any).Veriff as typeof VeriffObject;

const useKycVerification = () => {
  const { t } = useTranslation();

  const [isVerifyInitInProgress, setIsVerifyInitInProgress] = useState(false);
  const [hasVeriffStarted, setHasVeriffStarted] = useState(false);

  const veriff = useRef<ReturnType<typeof Veriff>>();
  const veriffFrame = useRef<ReturnType<typeof createVeriffFrame>>();

  // 'verifyEntity' is from our BE
  const [verifyEntity, setVerifyEntity] = useState<VerifyResponse>(null);
  const [veriffUrl, setVeriffUrl] = useState<string>();

  const [veriffStatus, setVeriffStatus] = useState<VerificationStatus>();

  const { currentUser, setCurrentUser } = useContext(CurrentUserContext);

  const { firstName, lastName } = currentUser || {};

  const { sessionUrl } = verifyEntity || {};

  const closeVeriffModal = useCallback(() => {
    veriffFrame.current?.close();

    bus.broadcastEvent('TOGGLE_KYC_MODAL', false);

    setVerifyEntity(null);
    setVeriffUrl(null);
    setHasVeriffStarted(false);
  }, []);

  // Create/mount veriff iframe
  const createVeriffFrameMaybe = useCallback(() => {
    if (!veriffUrl && !sessionUrl) return;

    veriffFrame.current = createVeriffFrame({
      url: sessionUrl || veriffUrl,
      onEvent: (msg) => {
        switch (msg) {
          case 'FINISHED':
            closeVeriffModal();
            break;

          case 'CANCELED':
            closeVeriffModal();
            break;

          default:
            break;
        }
      },
    });
  }, [closeVeriffModal, sessionUrl, veriffUrl]);

  // Set Veriff form params and mount it.
  // Used when we are first verifying user.
  const mountVeriffFormMaybe = useCallback(() => {
    if (!verifyEntity?.verificationToken || sessionUrl || !veriff.current)
      return;

    veriff.current.setParams({
      vendorData: verifyEntity.verificationToken,
      person: {
        givenName: firstName,
        lastName,
      },
    });

    veriff.current.mount();
  }, [firstName, lastName, sessionUrl, verifyEntity]);

  const initiateVeriff = useCallback(() => {
    setHasVeriffStarted(true);

    mountVeriffFormMaybe();

    createVeriffFrameMaybe();
  }, [createVeriffFrameMaybe, mountVeriffFormMaybe]);

  // Create verification on our BE
  const initVefirication = useCallback(async () => {
    try {
      setIsVerifyInitInProgress(true);

      const { data } = await api.user.initVerification();

      const { status } = data;

      setVeriffStatus(status);

      let verifyData = { ...data };

      switch (status) {
        case 'approved':
          setCurrentUser((old) => ({ ...old, hasVerifiedIdentity: true }));
          break;

        case 'expired':
        case 'abandoned':
        case 'declined':
          verifyData = { ...data, sessionUrl: null };

          break;

        default:
          break;
      }

      setVerifyEntity(verifyData);
    } catch (error) {
      showToast('error', t('General.verificationError'));
    } finally {
      setIsVerifyInitInProgress(false);
    }
  }, [setCurrentUser, t]);

  const toggleVerifyModal = useCallback(
    ({ payload: isOpen }: { event: EventName; payload: boolean }) => {
      if (!isOpen) return;

      initVefirication();
    },
    [initVefirication],
  );

  useBusListener('TOGGLE_KYC_MODAL', toggleVerifyModal);

  // Create Veriff form only if we don't
  // have a session already created.
  useEffect(() => {
    if (sessionUrl) return;

    veriff.current = Veriff({
      host: env.VERIFF_API_URL,
      apiKey: env.VERIFF_API_TOKEN,
      parentId: 'veriff-root',
      onSession: (err, response: VeriffSessionResponse) => {
        const { verification } = response || {};

        if (!verification?.url) return;

        setVeriffUrl(verification.url);
      },
    });
  }, [sessionUrl]);

  // Used when we don't have a session created
  useEffect(() => {
    if (!veriffUrl) return;

    createVeriffFrameMaybe();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [veriffUrl]);

  // Save veriff session url to our BE,
  // so we don't have to recreate sessions until they expire
  useEffect(() => {
    if (
      !verifyEntity?.id ||
      sessionUrl ||
      !veriffUrl ||
      veriffUrl === sessionUrl
    )
      return;

    try {
      api.user.saveVerification(verifyEntity.id, veriffUrl);
    } catch (error) {
      console.error(error);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [veriffUrl, verifyEntity]);

  return {
    closeVeriffModal,
    initiateVeriff,
    isVerifyInitInProgress,
    veriffStatus,
    hasVeriffStarted,
  };
};

export default useKycVerification;
