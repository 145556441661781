import classNames from 'classnames';
import EditorActions from 'components/RichTextEditorField/utils/EditorActions';
import React from 'react';
import {
  RenderElementProps,
  useFocused,
  useSelected,
  useSlateStatic,
} from 'slate-react';

type EditorElementProps = RenderElementProps;

const EditorElement: React.FC<EditorElementProps> = (props) => {
  const { children, attributes, element } = props;

  const editor = useSlateStatic();
  const selected = useSelected();
  const focused = useFocused();

  switch (element.type) {
    case 'link':
      return (
        <div
          {...attributes}
          className={classNames({
            'anys-rich-text-editor__link-element': element.type === 'link',
          })}
        >
          <a href={element.href}>{children}</a>
          {selected && focused && (
            <div
              className="anys-rich-text-editor__link-element__popup"
              contentEditable={false}
            >
              <a href={element.href} rel="noreferrer" target="_blank">
                {element.href}
              </a>
              <button
                type="button"
                onClick={() => EditorActions.removeLink(editor)}
              >
                X
              </button>
            </div>
          )}
        </div>
      );

    case 'ordered-list':
      return <ol {...attributes}>{children}</ol>;

    case 'unordered-list':
      return <ul {...attributes}>{children}</ul>;

    case 'list-item':
      return <li {...attributes}>{children}</li>;

    case 'paragraph':
      return <p {...attributes}>{children}</p>;

    case 'indent':
      return <span {...attributes}>&emsp;{children}</span>;

    default:
      return <div {...attributes}>{children}</div>;
  }
};

export default EditorElement;
