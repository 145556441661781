import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

export default (props: IconProps) => {
  const { ...restOfProps } = props;

  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      {...restOfProps}
    >
      <path
        d="M19.05 15.7069L11.0611 0.870937C10.4948 -0.180938 8.98637 -0.180938 8.41965 0.870937L0.431217 15.7069C0.30827 15.9352 0.24663 16.1916 0.252313 16.4508C0.257997 16.7101 0.33081 16.9635 0.463646 17.1863C0.596482 17.409 0.784801 17.5935 1.01022 17.7217C1.23564 17.85 1.49046 17.9176 1.74981 17.918H17.729C17.9886 17.918 18.2437 17.8507 18.4694 17.7226C18.6952 17.5945 18.8838 17.4101 19.0169 17.1873C19.15 16.9644 19.2231 16.7109 19.2289 16.4514C19.2346 16.1919 19.173 15.9354 19.05 15.7069ZM9.74059 15.6211C9.55517 15.6211 9.37392 15.5661 9.21974 15.4631C9.06557 15.3601 8.94541 15.2137 8.87445 15.0424C8.8035 14.8711 8.78493 14.6826 8.82111 14.5007C8.85728 14.3188 8.94657 14.1518 9.07768 14.0207C9.20879 13.8896 9.37584 13.8003 9.55769 13.7641C9.73955 13.7279 9.92805 13.7465 10.0994 13.8175C10.2707 13.8884 10.4171 14.0086 10.5201 14.1627C10.6231 14.3169 10.6781 14.4982 10.6781 14.6836C10.6781 14.8067 10.6538 14.9286 10.6067 15.0424C10.5596 15.1561 10.4906 15.2595 10.4035 15.3465C10.3164 15.4336 10.2131 15.5026 10.0994 15.5497C9.98561 15.5968 9.86371 15.6211 9.74059 15.6211ZM10.7587 6.19219L10.4897 11.9109C10.4897 12.1098 10.4106 12.3006 10.27 12.4413C10.1293 12.5819 9.93857 12.6609 9.73965 12.6609C9.54074 12.6609 9.34998 12.5819 9.20932 12.4413C9.06867 12.3006 8.98965 12.1098 8.98965 11.9109L8.72059 6.19453C8.71455 6.05793 8.73606 5.92151 8.78386 5.79341C8.83166 5.6653 8.90476 5.54813 8.99881 5.44888C9.09285 5.34963 9.20592 5.27033 9.33127 5.2157C9.45662 5.16108 9.59168 5.13225 9.7284 5.13094H9.73825C9.87591 5.13087 10.0122 5.15869 10.1388 5.21272C10.2654 5.26675 10.3797 5.34587 10.4749 5.44531C10.5701 5.54475 10.6442 5.66245 10.6926 5.7913C10.7411 5.92015 10.7629 6.05748 10.7568 6.195L10.7587 6.19219Z"
        fill="#FF8B1F"
      />
    </svg>
  );
};
