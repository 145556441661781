import React from 'react';
import classNames from 'classnames';
import { Toggle } from 'ncoded-component-library';
import { Field, FieldRenderProps, UseFieldConfig } from 'react-final-form';

import './ToggleField.styles.scss';

type ToggleProps = {
  name: string;
  className?: string;
  label?: string;
};

type ToggleFieldProps = ToggleProps & UseFieldConfig<string, string>;

type ToggleComponentFieldProps = ToggleProps & FieldRenderProps<string, any>;

const ToggleComponentField: React.FC<ToggleComponentFieldProps> = (props) => {
  const { children, label, input, meta, className, ...rest } = props;

  const classes = classNames('anys-toggle-field', className);

  return (
    <div className={classes}>
      <Toggle
        onChange={(ev) => {
          input.onChange(!input.value);
        }}
        checked={Boolean(input.value)}
        {...rest}
      />
      <span>{label}</span>
    </div>
  );
};

const ToggleField: React.FC<ToggleFieldProps> = (props) => (
  <Field component={ToggleComponentField} {...props} />
);

export default ToggleField;
