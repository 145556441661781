import React from 'react';
import classNames from 'classnames';
import { User } from 'models/User';
import { useTranslation } from 'react-i18next';
import { Loader } from 'ncoded-component-library';
import UserListItem from './UserListItem';

import './UserList.styles.scss';

type UserListProps = {
  className?: string;
  title?: React.ReactNode;
  users: User[];
  isFetching?: boolean;
  selectedUserIds?: number[];
  maxSelectableUsers?: number;
  isArbitratorList: boolean;
  scrollContainerRef: React.Ref<HTMLUListElement>;
  onSelectUser?: (user: User, selected: boolean) => void;
  onScroll?: (event: React.UIEvent<HTMLUListElement, UIEvent>) => void;
};

const UserList: React.FC<UserListProps> = (props) => {
  const {
    className,
    title,
    users = [],
    isFetching,
    maxSelectableUsers,
    selectedUserIds = [],
    isArbitratorList,
    scrollContainerRef,
    onSelectUser,
    onScroll,
  } = props;

  const { t } = useTranslation();

  const classes = classNames('anys-user-list', className);

  const hasUsers = users?.length > 0;

  const noUsersFound = t('General.noUsersFound');

  const isSelectDisabled =
    typeof maxSelectableUsers === 'number'
      ? selectedUserIds.length >= maxSelectableUsers
      : false;

  return (
    <div className={classes}>
      {title && <label className="anys-user-list__title">{title}</label>}
      {hasUsers ? (
        <ul onScroll={onScroll} ref={scrollContainerRef}>
          {users.map((user) => {
            const selected = selectedUserIds.includes(user.id);
            const disabled = !selected && isSelectDisabled;

            return (
              <UserListItem
                key={user.id}
                user={user}
                onSelectUser={onSelectUser}
                selected={selected}
                selectDisabled={disabled}
                isArbitrator={isArbitratorList}
              />
            );
          })}
        </ul>
      ) : !isFetching ? (
        <p className="anys-user-list__no-users"> {noUsersFound} </p>
      ) : null}

      {isFetching && (
        <div className="anys-user-list__loader">
          <Loader overlay={false} inline />
        </div>
      )}
    </div>
  );
};

export default UserList;
