import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Overlay } from 'ncoded-component-library';
import useEffectSkipFirst from 'hooks/useEffectSkipFirst';
import useMatchMedia from 'hooks/useMatchMedia';
import { useDrag } from '@use-gesture/react';
import { OverlayRef } from 'ncoded-component-library/build/components/atoms/Overlay/Overlay.component';

import './Aside.styles.scss';

const disableElScroll = (el: HTMLElement) => {
  if (!el) return;
  el.style.overflow = 'hidden';
};

const enableElScroll = (el: HTMLElement) => {
  if (!el) return;
  el.style.overflow = '';
};

type AsideProps = {
  overlayClassName?: string;
  className?: string;
  open: boolean;
  openDirection?: 'to-top' | 'to-bottom' | 'to-right' | 'to-left';
  breakpointCondition?: Parameters<typeof useMatchMedia>[0];
  transparentOverlay?: boolean;
  handleOpenStateChange?: (newOpenState: AsideOpenState) => void;
  onClose?: () => void;
};

export type AsideOpenState =
  | 'open'
  | 'start-open'
  | 'opening'
  | 'start-close'
  | 'closed'
  | 'closing';

const Aside: React.FC<React.PropsWithChildren<AsideProps>> = (props) => {
  const {
    children,
    overlayClassName,
    className,
    open,
    openDirection = 'to-top',
    breakpointCondition = 'isPhablet',
    transparentOverlay,
    handleOpenStateChange,
    onClose,
  } = props;

  const scrollDisabledRef = useRef(false);
  const isMediaMatched = useMatchMedia(breakpointCondition);
  const scrollerEl = useRef<HTMLDivElement>(null);
  const overlayRef = useRef<OverlayRef>();
  const [openStatus, setOpenStatus] = useState<AsideOpenState>('closed');

  const overlayClasses = classNames(
    'anys-aside-overlay--plain',
    { transparent: transparentOverlay },
    openStatus,
    overlayClassName,
  );
  const classes = classNames(openStatus, openDirection);

  useEffectSkipFirst(() => {
    if (open) {
      setOpenStatus('start-open');
    } else {
      setOpenStatus('start-close');
    }
  }, [open]);

  useEffectSkipFirst(() => {
    if (openStatus === 'start-close') {
      setOpenStatus('closing');
    }

    if (openStatus === 'start-open') {
      requestAnimationFrame(() => setOpenStatus('opening'));
    }
  }, [openStatus]);

  useEffect(() => {
    // If the component doesn't behave
    // as an overlay (usually on desktop)
    if (!isMediaMatched) {
      overlayRef.current.container?.classList.remove(
        'anys-aside-overlay--as-overlay',
      );
      overlayRef.current.container?.classList.add('anys-aside-overlay--plain');
      scrollerEl.current?.classList.remove('anys-aside');

      if (scrollDisabledRef.current) {
        enableElScroll(document.body);
        scrollDisabledRef.current = true;
      }
    } else {
      overlayRef.current.container?.classList.remove(
        'anys-aside-overlay--plain',
      );
      overlayRef.current.container?.classList.add(
        'anys-aside-overlay--as-overlay',
      );
      scrollerEl.current?.classList.add('anys-aside');

      if (openStatus === 'opening') {
        disableElScroll(document.body);
        scrollDisabledRef.current = true;
      } else if (openStatus === 'closed') {
        enableElScroll(document.body);
        scrollDisabledRef.current = false;
      }
    }
  }, [isMediaMatched, openStatus]);

  useEffect(() => {
    handleOpenStateChange?.(openStatus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openStatus]);

  // close modal on swipe down
  const bind = useDrag(
    ({ movement: [, my], velocity: [, vy] }) => {
      const { scrollTop } = scrollerEl.current;
      if (vy > 2 && my > 0 && scrollTop <= 0) {
        onClose?.();
      }
    },
    { axis: 'y' },
  );

  return (
    <Overlay
      renderAsPortal={false}
      lockScroll={false}
      background="black"
      overlayCloses
      controlledByParent={false}
      onOverlayClose={onClose}
      className={overlayClasses}
      ref={overlayRef}
      open
    >
      <div
        ref={scrollerEl}
        key="filters"
        className={classes}
        onTransitionEnd={() => {
          if (openStatus === 'closing') {
            setOpenStatus('closed');
            // overlayRef.current.close();
          } else {
            setOpenStatus('open');
          }
        }}
        {...bind()}
      >
        <div
          onClick={(ev) => ev.stopPropagation()}
          className={classNames(className, 'anys-aside__content')}
        >
          {children}
        </div>
      </div>
    </Overlay>
  );
};

export default Aside;
