import React from 'react';

type ShareProps = React.SVGProps<SVGSVGElement>;

const Share: React.FC<ShareProps> = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6667 4.58341C11.6667 2.97258 12.9725 1.66675 14.5833 1.66675C16.1942 1.66675 17.5 2.97258 17.5 4.58341C17.5 6.19424 16.1942 7.50008 14.5833 7.50008C13.769 7.50008 13.0327 7.16639 12.5036 6.62828L8.22756 9.21896C8.29649 9.46757 8.33333 9.72952 8.33333 10.0001C8.33333 10.2431 8.3036 10.4792 8.24758 10.705L12.6009 13.2774C13.1212 12.795 13.8178 12.5001 14.5833 12.5001C16.1942 12.5001 17.5 13.8059 17.5 15.4167C17.5 17.0276 16.1942 18.3334 14.5833 18.3334C12.9725 18.3334 11.6667 17.0276 11.6667 15.4167C11.6667 15.1737 11.6964 14.9376 11.7524 14.7119L7.39908 12.1395C6.87875 12.6219 6.18214 12.9167 5.41667 12.9167C3.80585 12.9167 2.5 11.6109 2.5 10.0001C2.5 8.38926 3.80585 7.08341 5.41667 7.08341C6.15373 7.08341 6.82695 7.35682 7.34042 7.80775L11.7222 5.15296C11.6858 4.96876 11.6667 4.77832 11.6667 4.58341ZM14.5833 3.33341C13.893 3.33341 13.3333 3.89306 13.3333 4.58341C13.3333 5.27376 13.893 5.83341 14.5833 5.83341C15.2737 5.83341 15.8333 5.27376 15.8333 4.58341C15.8333 3.89306 15.2737 3.33341 14.5833 3.33341ZM5.41667 8.75008C4.72632 8.75008 4.16667 9.30974 4.16667 10.0001C4.16667 10.6904 4.72632 11.2501 5.41667 11.2501C6.10701 11.2501 6.66667 10.6904 6.66667 10.0001C6.66667 9.30974 6.10701 8.75008 5.41667 8.75008ZM14.5833 14.1667C13.893 14.1667 13.3333 14.7264 13.3333 15.4167C13.3333 16.1071 13.893 16.6667 14.5833 16.6667C15.2737 16.6667 15.8333 16.1071 15.8333 15.4167C15.8333 14.7264 15.2737 14.1667 14.5833 14.1667Z"
      />
    </svg>
  );
};

export default Share;
