import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import './ResponsiveVideo.styles.scss';
import './ResponsiveVideo.styles.responsive.scss';
import PlayIcon from 'icons/Play.icon';

type ResponsiveVideoProps = {
  className?: string;
  src: string;
};

const ResponsiveVideo: React.FC<ResponsiveVideoProps> = (props) => {
  const { src, className } = props;

  const [showControls, setShowControls] = useState<true>();
  const videoRef = useRef<HTMLVideoElement>();

  const classes = classNames('anys-responsive-video', className, {
    'show-play': !showControls,
  });

  return (
    <div className={classes}>
      {!showControls && (
        <button
          className="play-btn"
          onClick={() => {
            videoRef.current.play();
            setShowControls(true);
          }}
        >
          <PlayIcon />
        </button>
      )}

      <video ref={videoRef} controls={showControls} src={src} />
    </div>
  );
};

export default ResponsiveVideo;
