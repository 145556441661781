import React, { useContext, useMemo } from 'react';
import classNames from 'classnames';
import { Job } from 'models/Job';
import { useTranslation } from 'react-i18next';
import DataPiecePreview from 'components/JobPostPreview/components/DataPiecePreview';
import PlainLocationDisplay from 'components/PlainLocationDisplay';
import utils from 'utils';
import ArbitrationSelectedFilters from 'router/subrouters/JobPost/pages/CreateEditJobPost/components/ArbitrationSetUp/components/ArbitrationSelectedFilters';
import dates from 'utils/dates';
import ToolTip from 'components/ToolTip';
import {
  getArbitrationChanges,
  getFreeCancellationChanges,
  getTimeChanges,
  getTypeOfServiceChanges,
  isPendingChanges,
} from 'utils/job-changes';
import WarningIcon from 'icons/Warning.icon';
import JobChanges from 'components/JobChanges';

import './SummarizedPreview.styles.scss';
import { parseFormTypeOfService, parseTypeOfService } from 'utils/job-parses';
import { convertToMoney, formatMoney } from 'utils/currency';
import { calcJobPrice } from 'utils/job';
import GradientText from 'components/GradientText';
import { Button } from 'ncoded-component-library';
import UserScheduleContext from '../UserScheduleModal/UserScheduleProvider/UserSchedule.context';
import { formatUserFirstLastName } from 'utils/user';
import useGetSkillGroups from '../../../../hooks/useGetSkillGroups';
import { useNavigate } from 'react-router-dom';
import CurrentUserContext from '../../../../providers/CurrentUser/CurrentUser.context';

type SummarizedPreviewProps = {
  className?: string;
  isOwnJob: boolean;
  isUserScheduleDisabled: boolean;
  isInSummaryModal?: boolean;
  onOpenSchedule: () => void;
} & Pick<
  Job,
  | 'timeAndPricing'
  | 'typeOfService'
  | 'arbitrationOptions'
  | 'freeCancelation'
  | 'prevVersion'
> &
  Required<
    Pick<
      Job,
      | 'versionState'
      | 'isLockedArbitration'
      | 'isLockedFreeCancelation'
      | 'isLockedTypeOfService'
      | 'isLockedTimeAndPricing'
    >
  >;

const SummarizedPreview: React.FC<SummarizedPreviewProps> = (props) => {
  const {
    className,
    timeAndPricing,
    typeOfService,
    arbitrationOptions,
    freeCancelation,
    prevVersion,
    versionState,
    isLockedArbitration,
    isLockedFreeCancelation,
    isLockedTimeAndPricing,
    isLockedTypeOfService,
    isOwnJob,
    isUserScheduleDisabled,
    isInSummaryModal,
    onOpenSchedule,
  } = props;

  const navigate = useNavigate();
  const { currentUser } = useContext(CurrentUserContext);
  const { t } = useTranslation();
  const { selectedSchedule } = useContext(UserScheduleContext);

  const { skillOptions } = useGetSkillGroups();

  const classes = classNames('anys-summarized-preview', className);

  const messages = useMemo(
    () => ({
      time: t('General.time'),
      location: t('General.location'),
      arbitration: t('General.arbitration'),
      arbitrators: t('General.arbitrators'),
      arbitrator: t('General.arbitrator'),
      noArbitration: t('General.noArbitration'),
      cancellationPolicy: t('General.cancellationPolicy'),
      online: t('General.online'),
      option: t('General.option'),
      free: t('General.free'),
      arbitratorsFilters: t('ArbitrationSetUp.arbitratorsFilter'),
      timeFromAvailability: t('General.timeFromAvailability'),
      additionalExplanation: t('JobForm.additionalExplanation'),
      strict: t('General.strict'),
      schedule: t('General.schedule'),
    }),
    [t],
  );

  const { timeAndPricing: prevTimeAndPricing } = useMemo(
    () => prevVersion || {},
    [prevVersion],
  );

  const { startDate, endDate, importAvailability, additionalExplanation } =
    timeAndPricing || {};
  const { isStrict, cancelationFee, cancelationHours } = freeCancelation || {};

  const hasMoreArbOptions = arbitrationOptions?.length > 1;

  const formattedDates =
    !startDate && !endDate ? '-' : dates.formatDates(startDate, endDate);

  const scheduleInfo = useMemo(
    () => (
      <span>
        {selectedSchedule
          ? dates.formatDates(
              selectedSchedule.startDate,
              selectedSchedule.endDate,
              'DD/MM/YYYY • hh:mm a',
            )
          : messages.schedule}
      </span>
    ),
    [messages.schedule, selectedSchedule],
  );

  const scheduleCTA = useMemo(
    () => (
      <Button
        variant="link"
        onClick={() => {
          if (!currentUser?.id) {
            return navigate('/auth');
          }
          onOpenSchedule();
        }}
        className="anys-summarized-preview__schedule-cta"
        disabled={isUserScheduleDisabled}
      >
        {scheduleInfo}
      </Button>
    ),
    [
      currentUser?.id,
      isUserScheduleDisabled,
      navigate,
      onOpenSchedule,
      scheduleInfo,
    ],
  );

  const displayedTime = importAvailability
    ? isOwnJob
      ? messages.timeFromAvailability
      : isInSummaryModal
        ? scheduleInfo
        : scheduleCTA
    : formattedDates;

  const isPendingVersion = isPendingChanges(versionState);

  const timeChanges =
    prevVersion && isPendingVersion
      ? getTimeChanges(
          t,
          {
            importAvailability: prevTimeAndPricing.importAvailability,
            startDate: prevTimeAndPricing.startDate
              ? new Date(prevTimeAndPricing.startDate)
              : null,
            endDate: prevTimeAndPricing.endDate
              ? new Date(prevTimeAndPricing.endDate)
              : null,
          },
          {
            importAvailability,
            startDate: startDate ? new Date(startDate) : null,
            endDate: endDate ? new Date(endDate) : null,
          },
          prevVersion.isLockedTimeAndPricing,
          isLockedTimeAndPricing,
        )
      : null;

  const typeOfServiceChanges =
    prevVersion && isPendingVersion
      ? getTypeOfServiceChanges(
          t,
          // Do a double parse to remove invalid props
          parseFormTypeOfService(parseTypeOfService(prevVersion.typeOfService)),
          parseFormTypeOfService(parseTypeOfService(typeOfService)),
          prevVersion.isLockedTypeOfService,
          isLockedTypeOfService,
        )
      : null;

  const cancellationChanges =
    prevVersion && isPendingVersion
      ? getFreeCancellationChanges(
          t,
          {
            hasFreeCancelation: `${prevVersion.hasFreeCancelation}`,
            freeCancelation: prevVersion.freeCancelation,
          },
          {
            hasFreeCancelation: `${!freeCancelation}`,
            freeCancelation,
          },
          prevVersion.isLockedFreeCancelation,
          isLockedFreeCancelation,
          true,
        )
      : null;

  const arbitrationChanges =
    prevVersion && isPendingVersion
      ? getArbitrationChanges(
          t,
          skillOptions,
          {
            hasArbitration: prevVersion.hasArbitration,
            arbitrationOptions: prevVersion.arbitrationOptions,
          },
          {
            hasArbitration: arbitrationOptions?.length > 0,
            arbitrationOptions,
          },
          prevVersion.isLockedArbitration,
          isLockedArbitration,
          true,
        )
      : null;

  const jobPrice = timeAndPricing
    ? calcJobPrice({
        ...timeAndPricing,
        startDate: timeAndPricing?.startDate
          ? new Date(timeAndPricing.startDate)
          : null,
        endDate: timeAndPricing?.endDate
          ? new Date(timeAndPricing.endDate)
          : null,
        price: convertToMoney(timeAndPricing.price, timeAndPricing.currency),
      })
    : 0;

  const cancellationAmount =
    jobPrice && cancelationFee ? jobPrice * (cancelationFee / 100) : null;

  const formattedCancellationAmount =
    cancellationAmount && timeAndPricing?.currency
      ? formatMoney(t, {
          amount: cancellationAmount,
          currency: timeAndPricing.currency,
        })
      : null;

  return (
    <section className={classes}>
      <div className="anys-summarized-preview__values">
        <DataPiecePreview
          label={
            <>
              {messages.time}
              {timeChanges ? (
                <ToolTip t={t} tooltipName="time-changes" icon={WarningIcon}>
                  <JobChanges
                    changedFrom={timeChanges.changedFrom}
                    changedTo={timeChanges.changedTo}
                  />
                </ToolTip>
              ) : null}
            </>
          }
          value={displayedTime}
        />

        {additionalExplanation ? (
          <DataPiecePreview
            label={messages.additionalExplanation}
            value={additionalExplanation}
            className="anys-summarized-preview__values__explanation"
          />
        ) : null}

        <DataPiecePreview
          label={
            <>
              {messages.location}
              {typeOfServiceChanges ? (
                <ToolTip
                  t={t}
                  tooltipName="type-of-service-changes"
                  icon={WarningIcon}
                >
                  <JobChanges
                    changedFrom={typeOfServiceChanges.changedFrom}
                    changedTo={typeOfServiceChanges.changedTo}
                  />
                </ToolTip>
              ) : null}
            </>
          }
          value={
            typeOfService?.type === 'Online' ? (
              messages.online
            ) : (
              <PlainLocationDisplay {...typeOfService} />
            )
          }
        />

        <DataPiecePreview
          label={
            <>
              {messages.cancellationPolicy}
              {cancellationChanges ? (
                <ToolTip
                  t={t}
                  tooltipName="cancellation-changes"
                  icon={WarningIcon}
                >
                  <JobChanges
                    changedFrom={cancellationChanges.changedFrom}
                    changedTo={cancellationChanges.changedTo}
                  />
                </ToolTip>
              ) : null}
            </>
          }
          value={
            <>
              {!freeCancelation
                ? messages.free
                : isStrict
                  ? messages.strict
                  : t('General.cancellationValue', {
                      hours: cancelationHours,
                      percent: cancelationFee,
                    })}
              {formattedCancellationAmount && (
                <GradientText
                  gradientType="pink"
                  className="anys-summarized-preview__margin-left"
                >
                  {`(${formattedCancellationAmount})`}
                </GradientText>
              )}
            </>
          }
        />

        {arbitrationOptions?.length ? (
          arbitrationOptions.map((arb, i) => {
            const {
              arbitratorsType,
              arbitratorsFee,
              arbitrators,
              filterQueryFormValues,
            } = arb;

            const isRandomWithFilters =
              arbitratorsType === 'Random with filters';

            const optionName = utils.convertArrayIndexToAlphabet(i);

            const totalFee = convertToMoney(
              arbitratorsFee ? (jobPrice * arbitratorsFee) / 100 : 0,
              timeAndPricing.currency,
            );

            return (
              <React.Fragment key={i}>
                {hasMoreArbOptions && (
                  <div
                    className={classNames(
                      'anys-summarized-preview__values__full-span',
                      'anys-summarized-preview__values__arb-option-title',
                    )}
                  >
                    {messages.option} {optionName}
                  </div>
                )}

                <DataPiecePreview
                  label={
                    <>
                      {messages.arbitration}
                      {arbitrationChanges ? (
                        <ToolTip
                          t={t}
                          tooltipName="arbitration-changes"
                          icon={WarningIcon}
                        >
                          <JobChanges
                            changedFrom={arbitrationChanges.changedFrom}
                            changedTo={arbitrationChanges.changedTo}
                          />
                        </ToolTip>
                      ) : null}
                    </>
                  }
                  value={
                    <>
                      {t('Arbitration.earnInfo')}
                      <GradientText
                        gradientType="pink"
                        className="anys-summarized-preview__margin-left"
                      >
                        {`(${formatMoney(t, totalFee)})`}
                      </GradientText>
                    </>
                  }
                />

                {arbitrators?.length > 0 && (
                  <DataPiecePreview
                    label={messages.arbitrators}
                    className="anys-summarized-preview__values__full-span"
                    value={
                      arbitrators?.length ? (
                        <ul className="anys-summarized-preview__values__arbitrators">
                          {arbitrators.map((arb) => (
                            <li key={arb.id}>{formatUserFirstLastName(arb)}</li>
                          ))}
                        </ul>
                      ) : (
                        '-'
                      )
                    }
                    valueAs="div"
                  />
                )}

                {isRandomWithFilters && (
                  <ArbitrationSelectedFilters
                    selectedFilters={filterQueryFormValues}
                    title={messages.arbitratorsFilters}
                    className={classNames(
                      'anys-summarized-preview__values__full-span',
                      'anys-summarized-preview__values__filters',
                    )}
                  />
                )}
              </React.Fragment>
            );
          })
        ) : (
          <DataPiecePreview
            label={
              <>
                {messages.arbitration}
                {arbitrationChanges ? (
                  <ToolTip
                    t={t}
                    tooltipName="arbitration-changes"
                    icon={WarningIcon}
                  >
                    <JobChanges
                      changedFrom={arbitrationChanges.changedFrom}
                      changedTo={arbitrationChanges.changedTo}
                    />
                  </ToolTip>
                ) : null}
              </>
            }
            value={messages.noArbitration}
          />
        )}
      </div>
    </section>
  );
};

export default SummarizedPreview;
