import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Modal, { ModalRef } from 'components/Modal';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { Button } from 'ncoded-component-library';
import api from 'api';
import { PaymentMethod } from 'models/Wallet';
import { Form } from 'react-final-form';
import SelectField from 'components/SelectField';
import formValidators from 'utils/formValidators';
import ThemeContext from 'providers/Theme/Theme.context';
import CurrencyInputField from 'components/CurrencyInputField';
import { useTranslation } from 'react-i18next';
import ToolTip from 'components/ToolTip';
import OverlaySpinner from 'components/OverlaySpinner';
import showToast from 'modules/showToast';
import classNames from 'classnames';

type AddDepositModalProps = {
  className?: string;
  addDepositModalRef?: (element: ModalRef) => void;
  onDepositMake?: (value: number) => void;
  onClose?: () => void;
  initialValueForDeposit?: number;
  returnUrlOnConfirmSetup?: string;
};

const AddDepositModal = (props: AddDepositModalProps) => {
  const {
    addDepositModalRef,
    onDepositMake,
    onClose,
    initialValueForDeposit,
    returnUrlOnConfirmSetup,
    className,
  } = props;

  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);

  const { required } = formValidators;

  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();

  const getPaymentMethods = useCallback(async () => {
    try {
      const { data } = await api.wallet.getPaymentMethods();

      setPaymentMethods(data);
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    getPaymentMethods();
  }, [getPaymentMethods]);

  const elements = useElements();
  const stripe = useStripe();

  const handleSubmit = useCallback(
    async (e: any) => {
      e.preventDefault();

      try {
        OverlaySpinner.show('.anys-wallet-page__add-card-form');

        const { error: submitError } = await elements.submit();
        if (submitError) {
          console.error(submitError);
          return;
        }

        const { data } = await api.wallet.attachPaymentMethod();

        stripe
          .confirmSetup({
            elements,
            clientSecret: data.clientSecret,
            confirmParams: {
              return_url: returnUrlOnConfirmSetup,
            },
            //TODO: add this after zeljko add on be side
            // redirect: 'if_required',
          })
          .then(function (result) {
            if (result.error) {
              showToast('error', result.error.message);
            }
          });
      } catch (e) {
        console.error(e);
      } finally {
        getPaymentMethods();
        OverlaySpinner.hide('.anys-wallet-page__add-card-form');
      }
    },
    [elements, getPaymentMethods, returnUrlOnConfirmSetup, stripe],
  );

  const handleMakeDeposit = useCallback(
    async (values: {
      cardPaymentId: string;
      deposit: { amount: number; currency: string };
    }) => {
      try {
        OverlaySpinner.show('.anys-wallet-page__add-card-form');
        const {
          data: { clientSecret },
        } = await api.wallet.addDeposit(
          values.deposit?.amount,
          values.cardPaymentId,
        );

        if (clientSecret) {
          const { error } = await stripe.confirmCardPayment(clientSecret);

          if (error) {
            console.error(error);
            showToast('error', error.message);
          } else {
            onDepositMake(values.deposit?.amount / 100);
            onClose();
          }
        }
      } catch (e) {
        console.error(e);
      } finally {
        OverlaySpinner.hide('.anys-wallet-page__add-card-form');
      }
    },
    [onClose, onDepositMake, stripe],
  );

  const defaultPaymentCard = useMemo(() => {
    if (paymentMethods.length !== 0) {
      return paymentMethods.find((paymentMethod) => paymentMethod.isDefault);
    }
  }, [paymentMethods]);

  const paymentMethodsOptions = useMemo(() => {
    return paymentMethods.map((paymentMethod) => ({
      label: {
        text: `**** ${paymentMethod.last4Digits}`,
        icon: (
          <img
            alt="payment method brand"
            src={`/assets/images/payment-methods/${theme}-theme/${paymentMethod.brand}.png`}
          />
        ),
      },
      value: paymentMethod.stripePaymentMethodId,
      searchValue: paymentMethod.last4Digits,
    }));
  }, [paymentMethods, theme]);

  return (
    <Modal
      type="no-action"
      modalName="add-deposit"
      title="Add deposit"
      ref={addDepositModalRef}
      className={classNames('anys-add-deposit-modal', className)}
    >
      {paymentMethods.length === 0 ? (
        <form
          id="payment-form"
          onSubmit={handleSubmit}
          className="anys-wallet-page__add-card-form"
        >
          <PaymentElement
            options={{
              layout: {
                type: 'accordion',
                defaultCollapsed: false,
                radios: true,
                spacedAccordionItems: false,
              },
            }}
          />
          <Button type="submit">Add</Button>
        </form>
      ) : (
        <Form
          onSubmit={handleMakeDeposit}
          initialValues={{
            cardPaymentId: defaultPaymentCard?.stripePaymentMethodId,
            deposit: {
              amount: initialValueForDeposit,
              currency: 'USD',
            },
          }}
          render={({ handleSubmit, values, form }) => {
            return (
              <form
                onSubmit={(event) => {
                  handleSubmit(event).then(() => {
                    form.restart();
                  });
                }}
                className="anys-wallet-page__add-card-form"
              >
                <div className="anys-anycoin-list__fields">
                  <SelectField
                    name="cardPaymentId"
                    label="Credit card"
                    options={paymentMethodsOptions as any}
                    className="anys-wallet-page__default-cc"
                    validate={required(t('General.requiredField'))}
                  />

                  <CurrencyInputField
                    name="deposit"
                    label="Amount"
                    placeholder="Amount"
                    validate={required<string>(t('General.required'))}
                  />
                </div>
                {values.deposit && (
                  <div className="anys-wallet-page__total">
                    {/* SUBTOTAL */}
                    <span className="anys-wallet-page__sub-total">
                      Subtotal: {(values.deposit.amount / 100).toFixed(2)}$
                    </span>

                    {/* FEES */}
                    <div className="anys-wallet-page__sub-total">
                      <span>{`Fees & Estimated Tax: ${
                        (values.deposit.amount / 10000) * 2.97 < 3
                          ? 3
                          : +((values.deposit.amount / 10000) * 2.97).toFixed(2)
                      }$`}</span>
                      <ToolTip t={t} tooltipName="fees">
                        Service fee is 2.97% (minimum 3$) help us operate
                        AnyService
                      </ToolTip>
                    </div>

                    {/* TOTAL */}
                    <span className="anys-wallet-page__total-lbl">
                      {`Total:
                        ${+(
                          values.deposit.amount / 100 +
                          ((values.deposit.amount / 10000) * 2.97 < 3
                            ? 3
                            : +((values.deposit.amount / 10000) * 2.97).toFixed(
                                2,
                              ))
                        ).toFixed(2)}$`}
                    </span>
                  </div>
                )}

                <Button
                  type="submit"
                  className="anys-wallet-page__total__buy-btn"
                >
                  Add
                </Button>
              </form>
            );
          }}
        />
      )}
    </Modal>
  );
};

export default AddDepositModal;
