import React from 'react';
import classNames from 'classnames';
import { FormRenderProps } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import CheckboxField from 'components/CheckboxField';
import { week } from 'constants/week';
import TimeInputField from 'components/TimeInputField';
import { Button } from 'ncoded-component-library';
import TrashIcon from 'icons/Trash.icon';
import PlusGradient from 'icons/PlusGradient.icon';
import { useTranslation } from 'react-i18next';
import { CheckboxValue } from 'components/CheckboxField/CheckboxField.component';
import formValidators from 'utils/formValidators';
import CalendarDropdownField from 'components/CalendarDropdownField';
import utils from '../../utils';
import dayjs from 'dayjs';

import './WeeklyHoursForm.styles.scss';

type WeeklyHoursFormProps = {
  className?: string;
  formRenderProps: FormRenderProps<any, Partial<any>>;
};

const WeeklyHoursForm: React.FC<WeeklyHoursFormProps> = (props) => {
  const { className, formRenderProps } = props;

  const { t } = useTranslation();

  const classes = classNames('anys-weekly-hours-form', className);

  const {
    handleSubmit,
    values,
    form: {
      mutators: { push },
      change,
    },
  } = formRenderProps;

  const { composeValidators, requiredTime } = formValidators;

  return (
    <form onSubmit={handleSubmit} className={classes}>
      {week.map(({ day, label }) => (
        <div className="anys-weekly-hours-form__day" key={day}>
          <CheckboxField
            name={`${day}.isChecked`}
            label={t(label)}
            onChange={(value: CheckboxValue) => {
              let lastTimeInput = undefined;

              if (values) {
                const { timeoffs, ...days } = values;
                const lastTimes = (
                  Object.values(days)[
                    Object.keys(days)?.length === 1
                      ? 0
                      : Object.keys(days)?.length - 1
                  ] as any
                )?.weeklyHoursAvailability;

                lastTimeInput = lastTimes?.[lastTimes?.length - 1];
              }

              if (value) {
                push(`${day}.weeklyHoursAvailability`, lastTimeInput);
              } else {
                change(`${day}`, undefined);
              }
            }}
            parse={(value) => value || undefined}
          />
          {values[day]?.['isChecked'] ? (
            <>
              <div
                className="anys-weekly-hours-form__day__hours__actions"
                key={day}
              >
                <FieldArray name={`${day}.weeklyHoursAvailability`}>
                  {({ fields }) =>
                    fields.map((name, index) => (
                      <div
                        className="anys-weekly-hours-form__day__hours__actions__fields"
                        key={name}
                      >
                        <TimeInputField
                          name={`${name}.startTime`}
                          validateFields={[`${name}.endTime`]}
                          validate={composeValidators(
                            formValidators.requiredTime(t("General.required")),
                            utils.hasOverlapTime(
                              t('Availability.timeOverlapping'),
                              `${day}.weeklyHoursAvailability`,
                              index,
                            ),
                          )}
                        />
                        <span className="anys-weekly-hours-form__separator">
                          -
                        </span>
                        <TimeInputField
                          name={`${name}.endTime`}
                          validate={composeValidators(
                            requiredTime(t("General.required")),
                            utils.timeIsAfterValidation(
                              t('Availability.endDateValidation'),
                              `${day}.weeklyHoursAvailability.[${index}].startTime`,
                            ),
                          )}
                          parse={(value) => {
                            return value === '24:00' ? '00:00' : value;
                          }}
                        />
                        <Button
                          variant="icon"
                          icon={<TrashIcon />}
                          className="anys-weekly-hours-form__delete-btn"
                          onClick={() => {
                            if (fields.length === 1) {
                              change(`${day}.isChecked`, false);
                              change(`${day}.weeklyHoursAvailability`, [
                                { startTime: '', endTime: '' },
                              ]);
                              fields.remove(index);
                            } else {
                              fields.remove(index);
                            }
                          }}
                        />
                      </div>
                    ))
                  }
                </FieldArray>
              </div>
              <Button
                className="plus-icon"
                type="button"
                variant="icon"
                icon={<PlusGradient />}
                onClick={() => {
                  push(`${day}.weeklyHoursAvailability`, undefined);
                }}
              />
            </>
          ) : (
            <p className="anys-weekly-hours-form__day__unavailable">
              {t('General.unavailable')}
            </p>
          )}
        </div>
      ))}
      <section className="anys-weekly-hours-form__exceptions">
        <h2 className="anys-weekly-hours-form__exceptions__title">
          {t('Availability.exceptions')}
        </h2>
        <p className="anys-weekly-hours-form__exceptions__helper-text">
          {t('Availability.availabilityDiffersFromWeeklyHours')}
        </p>
        <div>
          <FieldArray name="timeoffs">
            {({ fields: fieldsDate }) =>
              fieldsDate.map((nameDate, indexDate) => {
                //TODO: consider this for validation
                // const validateNames = fieldsDate.map((nameDate, index) => {
                //   if (index !== indexDate) {
                //     return nameDate;
                //   }
                // }).filter((el) => el).map(el => `${el}.date`);
                return (
                  <div
                    key={indexDate}
                    className={classNames(
                      'anys-weekly-hours-form__exceptions__form',
                      {
                        'anys-weekly-hours-form__exceptions__form--unavailable':
                          values?.timeoffs?.[indexDate]?.dateHourRange?.[0]
                            .isFullDay,
                      },
                    )}
                  >
                    <CalendarDropdownField
                      name={`${nameDate}.date`}
                      placeholder={t('Availability.selectDateRange')}
                      className="anys-weekly-hours-form__exceptions__form__calendar"
                      allowRange
                      markDates={t('Availability.markFullDayUnavailable')}
                      hasCloseButton={false}
                      rightLimitDate={dayjs().add(2, 'year')}
                      validateFields={[]}
                      validate={composeValidators(
                        utils.hasOverlapDate(
                          t('Availability.dateOverlapping'),
                          'timeoffs',
                          indexDate,
                        ),
                        formValidators.requiredArray(t('General.required')),
                      )}
                      onMarkDates={(value: boolean) => {
                        if (value) {
                          change(`${nameDate}.dateHourRange[0]`, {
                            startTime: '00:00',
                            endTime: '23:59',
                            isFullDay: true,
                          });
                        } else {
                          change(`${nameDate}.dateHourRange[0]`, {
                            startTime: '',
                            endTime: '',
                            isFullDay: undefined,
                          });
                        }
                      }}
                    />
                    {!values?.timeoffs?.[indexDate]?.dateHourRange?.[0]
                      .isFullDay ? (
                      <div>
                        <FieldArray
                          name={`${nameDate}.dateHourRange`}
                          parse={(value: any) => {
                            if ((value as any).length === 0) {
                              return [{}];
                            }
                          }}
                        >
                          {({ fields: fieldsHour }) =>
                            fieldsHour.map((nameHour, indexHour) => {
                              return (
                                <div
                                  className="anys-weekly-hours-form__exceptions__form__fields"
                                  key={nameHour}
                                >
                                  <TimeInputField
                                    name={`${nameHour}.startTime`}
                                    validateFields={[]}
                                    validate={composeValidators(
                                      formValidators.requiredTime(
                                        t('General.required'),
                                      ),
                                      utils.hasOverlapTime(
                                        t('General.timeOverlapping'),
                                        `timeoffs.[${indexDate}].dateHourRange`,
                                        indexHour,
                                      ),
                                    )}
                                  />
                                  <span>-</span>
                                  <TimeInputField
                                    name={`${nameHour}.endTime`}
                                    validate={composeValidators(
                                      requiredTime(t('General.required')),
                                      utils.timeIsAfterValidation(
                                        t('Availability.endDateValidation'),
                                        `timeoffs[${indexDate}].dateHourRange[${indexHour}].startTime`,
                                        //TODO: ne moze ovako index zbog validacije
                                      ),
                                    )}
                                  />

                                  <Button
                                    variant="icon"
                                    icon={<TrashIcon />}
                                    className="anys-weekly-hours-form__delete-btn-date"
                                    onClick={() => {
                                      if (fieldsHour.length === 1) {
                                        fieldsDate.remove(indexDate);
                                        if (fieldsDate.length === 1) {
                                          change('timeoffs', []);
                                        }
                                      } else {
                                        fieldsHour.remove(indexHour);
                                      }
                                    }}
                                  />
                                </div>
                              );
                            })
                          }
                        </FieldArray>
                      </div>
                    ) : (
                      <div className="anys-weekly-hours-form__exceptions__form__unavailable">
                        <span className="anys-weekly-hours-form__exceptions__form__unavailable-status">
                          {t('General.unavailable')}
                        </span>
                        <Button
                          variant="icon"
                          icon={<TrashIcon />}
                          onClick={() => {
                            fieldsDate.remove(indexDate);
                          }}
                        />
                      </div>
                    )}
                    {values?.timeoffs?.[indexDate]?.dateHourRange.length !==
                      0 &&
                    !values?.timeoffs?.[indexDate]?.dateHourRange?.[0]
                      .isFullDay ? (
                      <Button
                        className="anys-weekly-hours-form__exceptions__form__plus-icon"
                        type="button"
                        variant="icon"
                        icon={<PlusGradient />}
                        onClick={() => {
                          push(`${nameDate}.dateHourRange`, {
                            startTime: '',
                            endTime: '',
                          });
                        }}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                );
              })
            }
          </FieldArray>
        </div>
        <Button
          onClick={() => {
            push('timeoffs', {
              dateHourRange: [
                {
                  startTime: '',
                  endTime: '',
                },
              ],
            });
          }}
          variant="outline"
          className="anys-weekly-hours-form__exceptions__add-button"
        >
          {t('Availability.addUnavailableDate')}
        </Button>
      </section>
    </form>
  );
};

export default WeeklyHoursForm;
