import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement> & {
  showGradient?: boolean;
};

export default (props: IconProps) => {
  const { showGradient, ...restOfProps } = props;
  return (
    <svg
      viewBox="0 0 37 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restOfProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5317 10C19.0839 10.0007 19.5311 10.449 19.5303 11.0013L19.5225 17H25.5C26.0523 17 26.5 17.4477 26.5 18C26.5 18.5523 26.0523 19 25.5 19H19.5199L19.512 25.0013C19.5113 25.5536 19.063 26.0007 18.5107 26C17.9584 25.9993 17.5113 25.551 17.512 24.9987L17.5199 19H11.5C10.9477 19 10.5 18.5523 10.5 18C10.5 17.4477 10.9477 17 11.5 17H17.5225L17.5304 10.9987C17.5311 10.4464 17.9794 9.99928 18.5317 10Z"
        fill="currentColor"
      />
      <rect
        x="1.5"
        y="1"
        width="34"
        height="34"
        rx="17"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
};
