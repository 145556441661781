import React from 'react';
import classNames from 'classnames';
import Filter from 'components/Filter';
import { Button, Input, Select } from 'ncoded-component-library';
import useTranslations from '../../hooks/useTranslations';
import useSearchOptions, {
  SearchBy,
  SEARCH_BY,
} from './hooks/useSearchOptions';
import DownIcon from 'icons/Down.icon';
import { OptionValue } from 'ncoded-component-library/build/components/molecules/Select/Select.component';
import useMatchMedia from 'hooks/useMatchMedia';
import PlusGradient from 'icons/PlusGradient.icon';

import './SearchSelect.styles.scss';
import './SearchSelect.styles.responsive.scss';

type SearchSelectProps = {
  className?: string;
  searchBy: SearchBy;
  onAddSkill?: () => void;
};

const SearchSelect: React.FC<SearchSelectProps> = (props) => {
  const { className, searchBy, onAddSkill } = props;

  const classes = classNames('anys-search-select', className);
  const isPhablet = useMatchMedia('isPhablet');

  const copies = useTranslations();
  const searchOptions = useSearchOptions();

  const isSearchBySkill = searchBy === 'skill';

  return (
    <Filter
      name="term"
      className={classes}
      component={Input}
      dbName="search"
      type="search"
      prefixNode={
        <Filter
          name="search-by"
          component={Select}
          multiple={false}
          icon={<DownIcon showGradient />}
          defaultValue={SEARCH_BY.SKILL}
          valueMap={(o: OptionValue<SearchBy>) => o.value}
          options={searchOptions}
        />
      }
      suffixNode={
        isSearchBySkill && (
          <Button
            variant="link"
            onClick={onAddSkill}
            className="anys-search-select__add-skill"
          >
            {isPhablet ? (
              <PlusGradient gradientType="purple" />
            ) : (
              <span>{copies.addSkill}</span>
            )}
          </Button>
        )
      }
      placeholder={copies.searchPlaceholder}
    />
  );
};

export default SearchSelect;
