import React, { useContext } from 'react';
import classNames from 'classnames';
import Avatar from 'components/Avatar';
import VerifiedIcon from 'icons/VerifiedProfile.icon';
import { ReactComponent as ScheduleIcon } from 'icons/Schedule.svg';
import Rating from 'components/Rating';
import { t } from 'i18next';
import { User } from 'models/User';
import { Button } from 'ncoded-component-library';
import ProfileContext from 'router/subrouters/Profile/pages/ProfilePreview/providers/Profile/Profile.context';
import CopyToClipboard from 'components/CopyToClipboard';
import SendUserMessage from 'components/SendUserMessage';
import { formatUserDisplayName } from 'utils/user';

import './ProfileInfo.styles.scss';
import './ProfileInfo.styles.responsive.scss';

type ProfileInfoProps = {
  className?: string;
  user: Partial<User>;
  // TODO: refactor this when conect with offers
  offers?: string[];
  edit: boolean;
};

const ProfileInfo: React.FC<ProfileInfoProps> = (props) => {
  const { className, user, edit } = props;

  const { setShowAvailabilityModal, isMine } = useContext(ProfileContext);
  const classes = classNames('anys-profile-info', className);

  const {
    profileImage,
    profession,
    id,
    anyServiceId,
    overallSkillScore,
    hasVerifiedIdentity,
    hasVerifiedBusiness,
    businessName,
  } = user || {};

  return (
    <div className={classes}>
      <Avatar
        className="anys-profile-info__img"
        img={profileImage?.url}
        alt={user?.firstName || businessName}
        edit={edit}
      />
      <div className="anys-profile-info__data grow">
        <div className="w-100">
          <div className="anys-profile-info__users">
            <label className="anys-profile-info__data__name">
              {formatUserDisplayName(user, t('General.name'))}
              {(hasVerifiedIdentity || hasVerifiedBusiness) && <VerifiedIcon />}
            </label>
          </div>
          <div className="flex jb">
            <div className="flex column gap-2">
              {profession && (
                <label className="anys-profile-info__data__profession">
                  {profession}
                </label>
              )}
              {overallSkillScore?.numberOfReviews || !isMine ? (
                <Rating
                  value={+(+overallSkillScore?.averageRating).toFixed(1)}
                  reviews={overallSkillScore?.numberOfReviews || 0}
                  withValue
                  maxReviewScore={10}
                />
              ) : (
                <p className="anys-profile-info__no-reviews">
                  {t('Profile.noReviewsYetMine')}
                </p>
              )}
            </div>
            {!isMine && (
              <SendUserMessage className="message-me" userId={id}>
                {t('Profile.message')}
              </SendUserMessage>
            )}
          </div>
        </div>
        <CopyToClipboard
          className="anys-profile-info__data__id"
          contentToCopy={`${anyServiceId}`}
          disabled={!anyServiceId}
        >
          {'ID'}
          <span className="anys-profile-info__data__id--value">{`${
            anyServiceId || t('General.number')
          }`}</span>
        </CopyToClipboard>

        {isMine && (
          <div className="anys-profile-info__schedule">
            <Button
              onClick={() => setShowAvailabilityModal(true)}
              icon={<ScheduleIcon />}
            >
              {t('Profile.mySchedule')}
            </Button>
          </div>
        )}
      </div>
      {/* {offers && (
        <Button
          type="button"
          variant="link"
          className="anys-profile-info__see-offers-button"
        >
          {t('General.seeOffers')}
        </Button>
      )} */}
    </div>
  );
};

export default ProfileInfo;
