import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement> & { showGradient?: boolean };

export default (props: IconProps) => {
  const { showGradient = true, ...restOfProps } = props;

  return (
    <svg
      fill="none"
      viewBox="0 0 36 36"
      width="36"
      height="36"
      {...restOfProps}
    >
      <path
        d="M2 18C2 9.1634 9.1634 2 18 2s16 7.1634 16 16-7.1634 16-16 16S2 26.8366 2 18Z"
        fill="url(#add-icon-gradient)"
      />
      <path
        d="M25 18.0002H11M18.0002 25V11"
        stroke="#fff"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      {showGradient && (
        <defs>
          <linearGradient
            id="add-icon-gradient"
            x1="2"
            y1="34"
            x2="34.5581"
            y2="33.4218"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#A761F5" />
            <stop offset="1" stopColor="#50A8FA" />
          </linearGradient>
        </defs>
      )}
    </svg>
  );
};
