import api from 'api';
import { PaginationParams } from 'models/Pagination';
import React, { useEffect, useState } from 'react';
import Rating from 'components/Rating';
import { useProfile } from 'router/subrouters/Profile/pages/Profile/Profile.page';
import Skill from './components/Skill';
import Review from './components/Review';
import Modal, { ModalRef } from 'components/Modal';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import './SkillsModal.styles.scss';
import './SkillsModal.styles.responsive.scss';
import { formatUserDisplayName } from 'utils/user';

type SkillsModalProps = {
  className?: string;
  skillsModalRef?: (element: ModalRef) => void;
};

const SkillsModal: React.FC<SkillsModalProps> = (props) => {
  const { className, skillsModalRef } = props;

  const [reviews, setReviews] = useState([]);
  const [skillFilter, setSkillFilter] = useState<number | null>(null);

  const { t } = useTranslation();

  const { profileUser } = useProfile();

  const { skills, id, overallSkillScore } = profileUser || {};

  const { averageRating, numberOfReviews } = overallSkillScore || {};

  const classes = classNames('anys-skills-modal', className);

  useEffect(() => {
    const getReviews = async () => {
      const queryParams: PaginationParams = {
        $relations: 'reviewee.profileImage',
        $take: 100,
        $skip: 0,
        $where: JSON.stringify({
          'userSkill.userId': { EQUAL: id },
          ...(skillFilter && { 'userSkill.skillId': { EQUAL: skillFilter } }),
        }),
      };

      const {
        data: { items },
      } = await api.contract.getReviews(queryParams);

      setReviews(items);
    };

    getReviews();
  }, [id, skillFilter]);

  return (
    <Modal
      type="no-action"
      modalName="skills-modal"
      ref={skillsModalRef}
      // onBackClick={onClose}
      isDrawer
      overlayCloses
      title={
        <div className="anys-skills-modal__title">
          <span>
            {`${formatUserDisplayName(profileUser)} ${t('General.reviews')}`}
          </span>
          <Rating
            withValue
            value={averageRating}
            valueFormat={(val) => val}
            reviewFormat={(revs) => `(${revs} reviews)`}
            reviews={numberOfReviews}
            maxReviewScore={10}
          />
        </div>
      }
      className={classes}
    >
      <div className="anys-skills-modal__content">
        <ul className="anys-skills-modal__content__skills">
          <li
            className={classNames('skills-modal__skill', {
              'skills-modal__skill--active': !skillFilter,
            })}
            onClick={() => setSkillFilter(null)}
          >
            <p>{t('Filters.all_skills')}</p>
            <Rating
              withValue
              value={averageRating}
              reviews={numberOfReviews}
              valueFormat={(val) => val}
              reviewFormat={(revs) => `(${revs})`}
              maxReviewScore={10}
            />
          </li>
          {skills?.map((skill, index) => (
            <Skill
              key={index}
              skillRating={skill}
              onClick={() => setSkillFilter(skill?.skillId)}
              isActive={skill?.skillId === skillFilter}
            />
          ))}
        </ul>
        <ul className="anys-skills-modal__content__reviews">
          {reviews?.map((review, index) => (
            <Review key={index} review={review} isSkillReview />
          ))}
        </ul>
      </div>
    </Modal>
  );
};

export default SkillsModal;
