const JOB_TYPE = {
  PROVIDE: 'Provide',
  NEED: 'Need',
} as const;

const JOB_STATE = {
  DRAFT: 'Draft',
  AD: 'Ad',
  OFFER: 'Offer',
  CONTRACT: 'Contract',
} as const;

const JOB_PRICING_TYPE = {
  BY_PROJECT: 'By project',
  HOURLY: 'Hourly',
} as const;

const JOB_PRICING_DURATION_TYPE = {
  STRICT: 'Strict',
  FLEXIBLE: 'Flexible',
} as const;

const JOB_SERVICE_TYPES = {
  OFFLINE: 'Offline',
  ONLINE: 'Online',
} as const;

const JOB_TYPE_OF_SERVICE_LOCATION_TYPE = {
  ONE_SPOT: 'One spot',
  DIRECTION: 'Direction',
  AREA: 'Area',
} as const;

const JOB_ARBITRATORS_TYPE = {
  RANDOM: 'Random',
  RANDOM_WITH_FILTERS: 'Random with filters',
  SPECIFIC: 'Specific',
} as const;

const JOB_ARBITRATORS_NUMBER = {
  THREE: 3,
  FIVE: 5,
} as const;

const JOB_NEGOTIABILITY = {
  NEGOTIABLE: 'Negotiable',
  NON_NEGOTIABLE: 'Non negotiable',
} as const;

const BOOLEAN_STRING = {
  TRUE: 'true',
  FALSE: 'false',
} as const;

const JOB_NEGOTIABLE_PARTS = {
  TIME_AND_PRICING: 'isLockedTimeAndPricing',
  TYPE_OF_SERVICE: 'isLockedTypeOfService',
  FREE_CANCELATION: 'isLockedFreeCancelation',
  DEADLINE_POLICY: 'isLockedDeadlinePolicy',
  ARBITRATION: 'isLockedArbitration',
} as const;
const JOB_NEGOTIABLE_PARTS_ARRAY = Object.values(JOB_NEGOTIABLE_PARTS);

export {
  JOB_TYPE,
  JOB_STATE,
  JOB_PRICING_TYPE,
  JOB_PRICING_DURATION_TYPE,
  JOB_SERVICE_TYPES,
  JOB_TYPE_OF_SERVICE_LOCATION_TYPE,
  JOB_ARBITRATORS_TYPE,
  JOB_ARBITRATORS_NUMBER,
  JOB_NEGOTIABILITY,
  BOOLEAN_STRING,
  JOB_NEGOTIABLE_PARTS,
  JOB_NEGOTIABLE_PARTS_ARRAY,
};
