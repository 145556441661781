import React, { useContext, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import FilterIcon from 'icons/Filter.icon';
import Filter from 'components/Filter';
import FilterSelect from 'components/FilterSelect';
import ChevronIcon from 'icons/ChervonIcon.icon';
import useSelectOptions from 'hooks/useSelectOptions';
import LocationFilterSelect from 'router/subrouters/Search/pages/Search/components/LocationFilterSelect';
import {
  defaultParses,
  defaultTransformations,
  NEED_PROVIDE_FILTERS,
} from '../../../../utils/filters';
import NeedProvideContext from 'providers/NeedProvide/NeedProvide.context';
import ResponsiveDropdown from 'components/ResponsiveDropdown';
import { DropdownOption } from 'components/DropdownOptions/DropdownOptions.component';
import MultipleSelectTrigger from 'components/MultipleSelectTrigger';
import useQueryParams from 'hooks/useQueryParams';
import { NeedProvideSearchParams } from 'providers/NeedProvide/hooks/useNeedProvideSearch';
import { ModalRef } from 'components/Modal';
import { useLocation } from 'react-router-dom';

import './MobileFilters.styles.scss';
import './MobileFilters.styles.responsive.scss';
import { Skill } from '../../../../../../../../../models/Skills';

type MobileFiltersProps = {
  className?: string;
  allSkills: Skill[];
  activeFiltersCount: number;
  openFavouritesModal: () => void;
  setAdvancedFiltersOpen: () => void;
};

const MobileFilters: React.FC<MobileFiltersProps> = (props) => {
  const {
    className,
    allSkills,
    activeFiltersCount,
    setAdvancedFiltersOpen,
    openFavouritesModal,
  } = props;

  const { t } = useTranslation();

  const location = useLocation();

  const favouritesOptionsModalRef = useRef<ModalRef>();

  const {
    params: { favouriteFilter },
    setQueryParam,
  } = useQueryParams<NeedProvideSearchParams>();

  const { favouriteFilters } = useContext(NeedProvideContext);

  const classes = classNames('anys-mobile-filters', className);

  const {
    ratingOptions,
    languageOptions,
    favouriteFiltersOptions,
    userTypeOptions,
  } = useSelectOptions(favouriteFilters);

  const isProvidePage = location.pathname.startsWith('/provide');

  const favouritesAsDropdownOptions = useMemo(
    () =>
      favouriteFiltersOptions.map(
        ({ label, value, disabled }) =>
          ({
            key: value,
            title: label,
            disabled,
            onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              e.preventDefault();
              favouritesOptionsModalRef.current.close();

              setTimeout(() => {
                setQueryParam('favouriteFilter', value);
              }, 100);
            },
          }) as DropdownOption,
      ),
    [favouriteFiltersOptions, setQueryParam],
  );

  const selectedFavFilterLabel = useMemo(
    () =>
      favouriteFiltersOptions?.find(({ value }) => value === favouriteFilter)
        ?.label,
    [favouriteFilter, favouriteFiltersOptions],
  );

  return (
    <div className={classes}>
      <button
        type="button"
        className="anys-mobile-filters__more-filters-trigger"
        onClick={() => {
          setAdvancedFiltersOpen();
        }}
      >
        <FilterIcon showGradient showCircle={!!activeFiltersCount} />
      </button>

      <div className="anys-mobile-filters__filters">
        <ResponsiveDropdown
          triggerContent={
            <MultipleSelectTrigger
              label={selectedFavFilterLabel || t('General.favourites')}
              value={favouriteFilter}
              icon={<ChevronIcon gradient />}
            />
          }
          options={favouritesAsDropdownOptions}
          modalName="inbox-item-type"
          modalClassName="anys-mobile-filters__filters__favourites-modal"
          listClassName="anys-mobile-filters__filters__favourites-list"
          triggerOptions={{
            className: 'anys-mobile-filters__filters__favourites',
          }}
          title={t('NeedProvideFilters.modalTitle')}
          type="action"
          cancelActionContent={t('General.cancel')}
          secondaryActionContent={t('General.edit')}
          onSecondaryAction={openFavouritesModal}
          ref={favouritesOptionsModalRef}
        />

        <Filter
          searchable
          name="skills[]"
          dbName="skills.id"
          label={t('Filters.skills_other')}
          placeholder={t('SearchPage.searchSkill')}
          component={FilterSelect}
          multiple
          selectedOptionsContent={(value) => value.length}
          icon={<ChevronIcon gradient />}
          options={allSkills?.map((o) => ({
            value: o.id,
            label: o.name,
          }))}
        />

        <Filter
          searchable
          name="languages[]"
          dbName="user.languages.language"
          label={t('Filters.languages_one')}
          placeholder={t('Language.searchLanguage')}
          component={FilterSelect}
          multiple
          selectedOptionsContent={(value) => value.length}
          icon={<ChevronIcon gradient />}
          options={languageOptions}
        />

        <LocationFilterSelect
          name={NEED_PROVIDE_FILTERS.typeOfService}
          icon={<ChevronIcon gradient />}
          valueMap={defaultTransformations.typeOfService}
          valueParse={defaultParses.typeOfService}
          label={t('Filters.locations_one')}
          placeholder={t('SearchPage.searchLocation')}
          paramToValueParser={(val) => JSON.parse(val).startLocation}
        />

        <Filter
          dbName={`user.${
            isProvidePage ? 'overallSkillScore' : 'overallClientScore'
          }.averageRating`}
          name={NEED_PROVIDE_FILTERS.rating}
          type="morethan"
          label={t('Filters.rating')}
          multiple={false}
          component={FilterSelect}
          options={ratingOptions}
          valueMap={(o) => o.value}
          icon={<ChevronIcon gradient />}
        />

        <Filter
          name={NEED_PROVIDE_FILTERS.userType}
          type="single"
          label={t('General.userType')}
          multiple={false}
          component={FilterSelect}
          options={userTypeOptions}
          valueMap={(o) => o.value}
          icon={<ChevronIcon gradient />}
          defaultValue="see-all"
        />
      </div>
    </div>
  );
};

export default MobileFilters;
