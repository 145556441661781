import React from 'react';
import classNames from 'classnames';
import Aside, { AsideOpenState } from 'components/Aside';
import { INBOX_CHAT_BREAKPOINT } from '../../utils';
import { Button } from 'ncoded-component-library';

import './SideWrapper.styles.scss';
import './SideWrapper.styles.responsive.scss';

type SideWrapperProps = {
  className?: string;
  openDirection: 'to-left' | 'to-right';
  open?: boolean;
  closeIcon: React.ReactNode;
  openIcon: React.ReactNode;
  toggleOpen?: (isOpen: boolean) => void;
  handleOpenStateChange?: (newState: AsideOpenState) => void;
};

const SideWrapper: React.FC<React.PropsWithChildren<SideWrapperProps>> = (
  props,
) => {
  const {
    className,
    openDirection,
    children,
    open,
    closeIcon,
    openIcon,
    toggleOpen,
    handleOpenStateChange,
  } = props;

  const classes = classNames('anys-side-wrapper', className);

  return (
    <div className={classes}>
      <Button
        onClick={() => toggleOpen(true)}
        className="anys-side-wrapper__toggle"
        variant="icon"
      >
        {openIcon}
      </Button>

      <Aside
        open={open}
        onClose={() => toggleOpen(false)}
        handleOpenStateChange={handleOpenStateChange}
        openDirection={openDirection}
        breakpointCondition={INBOX_CHAT_BREAKPOINT}
        overlayClassName="anys-side-wrapper__aside"
        transparentOverlay
      >
        <Button
          onClick={() => toggleOpen(false)}
          className={classNames(
            'anys-side-wrapper__toggle',
            'anys-side-wrapper__toggle--close',
          )}
          variant="icon"
        >
          {closeIcon}
        </Button>

        {children}
      </Aside>
    </div>
  );
};

export default SideWrapper;
