import React, { useCallback } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import classNames from 'classnames';

import './NavigationLink.styles.scss';

type NavigationLinkProps = {
  activeClassName?: string;
} & NavLinkProps;

const NavigationLink: React.FC<NavigationLinkProps> = (props) => {
  const { children, className, activeClassName, ...restOfProps } = props;

  const classes = useCallback(
    ({ isActive }: { isActive: boolean }) =>
      classNames('anys-navlink', className, {
        [activeClassName || 'anys-navlink--active']: isActive,
      }),
    [activeClassName, className],
  );

  return (
    <NavLink className={classes} {...restOfProps}>
      {children}
    </NavLink>
  );
};
export default NavigationLink;
