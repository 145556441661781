import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import ResponsiveImage from 'components/ResponsiveImage';
import utils from 'utils';

type VideoToImageProps = {
  className?: string;
  videoUrl: string;
} & Omit<
  React.ComponentProps<typeof ResponsiveImage>,
  'resizedVariants' | 'src'
>;

const VideoToImage: React.FC<VideoToImageProps> = (props) => {
  const { className, videoUrl, ...rest } = props;

  const [imageUrl, setImageUrl] = useState<string>();

  const classes = classNames('anys-video-to-image', className);

  useEffect(() => {
    if (!videoUrl) return;

    const getImageFromVideo = async () => {
      const url = await utils.getThumbnailForVideo(videoUrl);

      setImageUrl(url);
    };

    getImageFromVideo();
  }, [videoUrl]);

  return <ResponsiveImage className={classes} {...rest} src={imageUrl} />;
};

export default VideoToImage;
