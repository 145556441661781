import React, { useCallback, useEffect, useState } from 'react';
import MainTemplate from 'components/templates/MainTemplate';
import useHeaderStyles from 'hooks/useHeaderStyles';
import { useTranslation } from 'react-i18next';
import Question from './components/Question';
import classNames from 'classnames';
import api from 'api';
import Card from 'components/Card';

import './FrequentQuestions.styles.scss';

type SectionValue = { question: string; answer: string };

const FrequentQuestions = () => {
  const classes = classNames(['anys-faq']);
  const [sections, setSections] = useState<Map<string, SectionValue[]>>(
    new Map<string, SectionValue[]>(),
  );
  const { t } = useTranslation();

  const getFrequentQuestions = useCallback(async () => {
    try {
      const questionList = await api.frequentQuestions.getFrequentQuestions(
        'faq',
      );
      const sectionsData: Map<string, SectionValue[]> = new Map<
        string,
        SectionValue[]
      >();

      questionList.data.forEach((item: any) => {
        const content = JSON.parse(item.content);

        if (sectionsData.get(content.section) === undefined) {
          sectionsData.set(content.section, []);
        }

        sectionsData.get(content.section).push({
          question: content.heading,
          answer: content.text,
        });
      });
      setSections(sectionsData);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useHeaderStyles({ showBackButton: true });

  useEffect(() => {
    getFrequentQuestions();
  }, [getFrequentQuestions]);

  return (
    <MainTemplate className={classes}>
        <Card>
        <h1>{t('FrequentQuestions.faq')}</h1>
        <p className="anys-faq__content__subtitle">
          {t('FrequentQuestions.faqSubtitle')}
        </p>
        {Array.from(sections.keys()).map((section) => (
          <div key={section} className="anys-faq__content__section">
            <h2>{section}</h2>

            {sections.get(section).map((item) => (
              <Question
                key={section}
                question={item.question}
                answer={item.answer}
              />
            ))}
          </div>
        ))}
        </Card>

    </MainTemplate>
  );
};

export default FrequentQuestions;
