import React, { useCallback, useContext, useMemo } from 'react';
import Availability from 'router/subrouters/Profile/pages/ProfilePreview/components/Availability';
import { useProfile } from 'router/subrouters/Profile/pages/Profile/Profile.page';
import useInfiniteContainer from 'hooks/useInfiniteContainer';
import ResponsiveImage from 'components/ResponsiveImage';
import LocationDisplay from 'components/LocationDisplay';
import useHeaderStyles from 'hooks/useHeaderStyles';
import Modal, { ModalRef } from 'components/Modal';
import useCallbackRef from 'hooks/useCallbackRef';
import { Button } from 'ncoded-component-library';
import useGetOffers from './hooks/useGetOffers';
import OfferCard from './components/OfferCard';
import Pagination from 'components/Pagination';
import OffersLoading from './Offers.loading';
import Rating from 'components/Rating';
import Filter from 'components/Filter';
import useEvent from 'hooks/useEvent';
import classNames from 'classnames';
import { t } from 'i18next';
import { formatUserFirstLastName } from 'utils/user';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import api from 'api';
import showToast from 'modules/showToast';
import { AxiosError } from 'axios';
import confirm from 'modules/confirm';
import ProfileContext from '../ProfilePreview/providers/Profile/Profile.context';
import { Type } from 'models/User';

import './Offers.styles.scss';
import './Offers.styles.responsive.scss';

const Offers = () => {
  const { profileUser, availability, setProfileOffers } = useProfile();
  const { currentUser } = useContext(CurrentUserContext);

  const { setShowAvailabilityModal, isMine } = useContext(ProfileContext);

  const classes = classNames(['anys-offers-page']);

  const [availabilityModal, availabilityModalRef] =
    useCallbackRef<ModalRef>(null);

  useHeaderStyles({
    hasBorder: true,
    absolutePosition: true,
    showBackButton: true,
  });

  const {
    items: offers,
    totalPages,
    loading,
    onContainerScrolled,
    currentPage,
    showPaginator,
    isPhablet,
    setItems,
  } = useGetOffers(profileUser?.id);

  const userLocationInArray = useMemo(
    () => (profileUser?.location ? [profileUser?.location] : undefined),
    [profileUser?.location],
  );

  const onLastPage = currentPage >= totalPages;

  const canShowAvailability =
    offers.length !== 0 &&
    profileUser?.type === Type.CLIENT_AND_PROVIDER &&
    (isMine || availability?.timeslots?.length > 0);

  const { onScroll } = useInfiniteContainer({
    container: window,
    onScroll: onContainerScrolled,
    loading,
  });

  useEvent(window, 'scroll', onScroll, undefined, isPhablet || onLastPage);

  const onDeleteOffer = useCallback(
    async (offerId: number) => {
      const shouldDelete = await confirm({
        title: t('General.removeEntityQuestion', {
          entity: t('General.offer') + '/' + t('General.preOffer'),
        }),
        confirmContent: t('General.yes'),
      });

      if (!shouldDelete) return;

      try {
        await api.users.removeUserOffer(currentUser?.id, offerId);

        setItems((old) => old.filter((oldItem) => oldItem.id !== offerId));
        setProfileOffers((old) =>
          old.filter((oldItem) => oldItem.id !== offerId),
        );
      } catch (error) {
        const err = error as AxiosError;

        showToast(
          'error',
          t('General.error'),
          err?.response?.data?.error?.message,
        );
      }
    },
    [currentUser?.id, setItems, setProfileOffers],
  );

  return (
    <div className={classes}>
      <div className="anys-offers-page__user">
        <div className="anys-offers-page__user__header">
          <div
            className={classNames(
              'anys-offers-page__user__header__picture',
              'anys-offers-page__user__header__picture--big',
            )}
          >
            <ResponsiveImage
              alt={formatUserFirstLastName(profileUser, '')}
              src={profileUser?.profileImage?.resizedUrls?.[40]}
              resizedVariants={profileUser?.profileImage?.resizedUrls}
            />
          </div>

          <div className="anys-offers-page__user__header__info">
            <h2 className="anys-offers-page__user__header__info__name">
              {formatUserFirstLastName(profileUser)}
            </h2>
            <div className="anys-offers-page__user__header__info__rating-location">
              <Rating
                fullStar
                reviewFormat={(revs) => `(${revs ? revs : 0})`}
                valueFormat={(val) =>
                  val
                    ? `${Number.parseFloat(`${val}`).toFixed(2)} / 10`
                    : '- / 10'
                }
                value={profileUser?.overallSkillScore?.averageRating || 0}
                reviews={profileUser?.overallSkillScore?.numberOfReviews}
                changable={false}
                withValue
                maxReviewScore={10}
              />
              <LocationDisplay
                locations={userLocationInArray}
                locationType="One spot"
              />
            </div>
          </div>
          {isPhablet && canShowAvailability && (
            <Button
              variant="outline"
              className="availability-btn"
              onClick={() => availabilityModal.open()}
            >
              <span>{t('General.availability')}</span>
            </Button>
          )}
        </div>
        {!isPhablet && canShowAvailability && (
          <div className="anys-offers-page__user__availability">
            <Availability
              availability={availability}
              isMine={isMine}
              setShowAvailabilityModal={setShowAvailabilityModal}
            />
          </div>
        )}
      </div>

      {loading && !isPhablet ? (
        <OffersLoading length={6} />
      ) : offers?.length ? (
        <div className="anys-offers-page__offers">
          {offers.map((offer) => {
            const { id, jobPost } = offer;

            return (
              <OfferCard
                key={id}
                jobPost={jobPost}
                offerId={id}
                isOwnJob={currentUser?.id === profileUser?.id}
                onDeleteOffer={onDeleteOffer}
              />
            );
          })}
        </div>
      ) : (
        <div className="anys-offers-page__offers__empty-state">
          {t('MyOffers.myOffersEmptyState')}
        </div>
      )}
      {showPaginator && totalPages > 1 && (
        <Filter
          name="page"
          className="anys-offers-page__pagination"
          component={Pagination}
          defaultValue="1"
          totalPages={totalPages}
        />
      )}
      <Modal
        type="no-action"
        modalName="preview-availability"
        ref={availabilityModalRef}
        title={t('General.availability')}
        isDrawer
      >
        <Availability
          renderInModal
          availability={availability}
          isMine={isMine}
          setShowAvailabilityModal={setShowAvailabilityModal}
        />
      </Modal>
    </div>
  );
};

export default Offers;
