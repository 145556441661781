import React, { useContext } from 'react';
import ThemeContext from 'providers/Theme/Theme.context';
import ContentLoader from 'react-content-loader';
import { LoadingProps } from './LoadingProps';

const UserItemLoading: React.FC<LoadingProps> = ({
  className,
  contentLoaderProps,
  length = 15,
}) => {
  const { theme } = useContext(ThemeContext);

  const backgroundColor = theme === 'dark' ? '#909194' : '#e7f1fc';
  const foregroundColor = theme === 'dark' ? '#333' : '#ffffff';

  return (
    <div className={className}>
      {Array.from({ length }).map((_, ind) => (
        <ContentLoader
          key={ind}
          viewBox="0 0 650 96"
          backgroundColor={backgroundColor}
          foregroundColor={foregroundColor}
          {...contentLoaderProps}
        >
          <rect x="16" y="16" rx="14" ry="14" width="64" height="64" />
          <rect x="88" y="24" rx="4" ry="4" width="240" height="20" />
          <rect x="88" y="56" rx="4" ry="4" width="120" height="20" />
          <rect
            x={650 - 16 - 130}
            y="16"
            rx="10"
            ry="10"
            width="130"
            height="48"
          />
        </ContentLoader>
      ))}
    </div>
  );
};

export default UserItemLoading;
