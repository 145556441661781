import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Form } from 'react-final-form';
import TextAreaField from 'components/TextAreaField';
import { useTranslation } from 'react-i18next';
import { Button, Loader } from 'ncoded-component-library';
import showToast from 'modules/showToast';
import chatSessions from 'api/chat/chatSessions';
import utils from 'utils';
import { FormApi } from 'final-form';
import { ReactComponent as TwoChain } from 'icons/two-chain.svg';
import FileInputField from 'components/FileInputField';
import ChatFilePreview from '../ChatFilePreview';
import ChatPhotoPreview from '../ChatPhotoPreview';
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
import { isMobileSafari } from 'react-device-detect';
import useChatScrollContainer from 'components/Chat/hooks/useChatScrollContainer';
import useBusListener from 'hooks/useBusListener';
import bus from 'modules/bus';
import { ReactComponent as SmallDownArrow } from 'icons/small-down-arrow.svg';
import { ReactComponent as SendIcon } from 'icons/send.svg';

import './ChatFooter.styles.scss';
import './ChatFooter.styles.responsive.scss';

const separateFiles = (
  files: File[],
): { photos: File[]; nonPhotos: File[] } => {
  if (!files?.length) return { photos: [], nonPhotos: [] };

  return files.reduce(
    (filesToSend, file) => {
      if (file.type.startsWith('image/')) {
        filesToSend.photos.push(file);
      } else {
        filesToSend.nonPhotos.push(file);
      }

      return filesToSend;
    },
    {
      photos: [],
      nonPhotos: [],
    },
  );
};

type ChatFooterValues = {
  chatMessage: string;
  files?: File[];
};

type ChatFooterProps = {
  className?: string;
  chatSessionId: number;
  onInputFocus: () => void;
  onInputBlur: () => void;
};

const ChatFooter: React.FC<ChatFooterProps> = (props) => {
  const { className, chatSessionId, onInputFocus, onInputBlur } = props;

  const { t } = useTranslation();
  const [sendingMessage, setSendingMessage] = useState(false);
  const [isScrollDownShown, setIsScrollDownShown] = useState(false);

  const messages = useMemo(
    () => ({
      messagePlaceholder: t('General.messagePlaceholder'),
      send: t('General.send'),
    }),
    [t],
  );

  const classes = classNames('anys-chat-footer', className);

  const { scrollContainer } = useChatScrollContainer();

  const onFocusBlur = useMemo(
    () =>
      isMobileSafari
        ? {
            onFocus: () => {
              onInputFocus();

              disableBodyScroll(scrollContainer);
            },
            onBlur: () => {
              onInputBlur();

              enableBodyScroll(scrollContainer);
            },
          }
        : {
            onFocus: onInputFocus,
            onBlur: onInputBlur,
          },
    [onInputBlur, onInputFocus, scrollContainer],
  );

  const toggleShowScrollDown = useCallback(
    ({ payload }: { payload: boolean }) => {
      setIsScrollDownShown(payload);
    },
    [],
  );

  useBusListener('TOGGLE_SHOW_SCROLL_TO_BOTTOM', toggleShowScrollDown);

  const handleKeyDown = useCallback(
    (
      e: React.KeyboardEvent<HTMLTextAreaElement>,
      formApi: FormApi<ChatFooterValues>,
    ) => {
      const enterPressed = utils.isEnterPressed(e);

      if (enterPressed && !e.shiftKey) {
        e.preventDefault();
        formApi.submit();
      }
    },
    [],
  );

  const onSubmit = useCallback(
    async (values: ChatFooterValues, formApi: FormApi<ChatFooterValues>) => {
      const { chatMessage, files } = values;

      if (sendingMessage) return;

      setSendingMessage(true);

      formApi.reset();

      try {
        await chatSessions.sendMessage(chatSessionId, {
          payload: chatMessage,
          files,
        });
      } catch (error) {
        showToast('error', t('General.error'));
      } finally {
        setSendingMessage(false);
      }
    },
    [chatSessionId, sendingMessage, t],
  );

  return (
    <Form
      onSubmit={onSubmit}
      render={(formRenderProps) => {
        const {
          handleSubmit,
          values: { chatMessage, files },
          form,
        } = formRenderProps;

        const chatDisabled = !chatSessionId || sendingMessage;

        const hasMessage = chatMessage?.trim();
        const hasFiles = files?.length;

        const submitDisabled = chatDisabled || (!hasMessage && !hasFiles);

        const { photos, nonPhotos } = separateFiles(files);

        return (
          <form className={classes} onSubmit={handleSubmit}>
            {isScrollDownShown && (
              <div className="anys-chat-footer__scroll-down">
                <button
                  type="button"
                  onClick={() => bus.broadcastEvent('SCROLL_TO_BOTTOM')}
                >
                  <SmallDownArrow />
                </button>
              </div>
            )}
            <div className="anys-chat-footer__input-wrapper">
              <ChatPhotoPreview
                photos={photos}
                onRemoveFile={(fileToRemove) =>
                  form.change(
                    'files',
                    files?.filter(({ name }) => name !== fileToRemove.name),
                  )
                }
              />

              <TextAreaField
                className="anys-chat-footer__msg-input"
                name="chatMessage"
                placeholder={messages.messagePlaceholder}
                resize={false}
                onKeyDown={(e) => !submitDisabled && handleKeyDown(e, form)}
                id="anys-chat-input"
                {...onFocusBlur}
              />

              <ChatFilePreview
                files={nonPhotos}
                className="anys-chat-footer__files"
                onRemoveFile={(fileToRemove) => {
                  form.change(
                    'files',
                    files?.filter(({ name }) => name !== fileToRemove.name),
                  );
                }}
              />
            </div>
            <div className="anys-chat-footer__actions">
              <FileInputField
                className="anys-chat-footer__actions__file-input"
                name="files"
                disabled={chatDisabled}
                trigger={<TwoChain />}
                maxFiles={10}
                multiple
              />

              <Button
                type="submit"
                disabled={submitDisabled}
                className="anys-chat-footer__actions__submit"
              >
                {sendingMessage ? (
                  <Loader inline overlay={false} />
                ) : (
                  <>
                    <SendIcon className="anys-chat-footer__actions__submit__icon" />
                    {messages.send}
                  </>
                )}
              </Button>
            </div>
          </form>
        );
      }}
    />
  );
};

export default ChatFooter;
