import React, { useRef } from 'react';
import classNames from 'classnames';
import './ToolTip.styles.scss';
import ToolTipIcon from 'icons/ToolTip.icon';
import { Button } from 'ncoded-component-library';
import Modal, { ModalRef } from 'components/Modal';
import { TFunction } from 'i18next';

type ToolTipProps = {
  className?: string;
  children?: React.ReactNode;
  tooltipName: string;
  icon?: React.ReactNode | React.FC;
  t: TFunction;
};

const ToolTip: React.FC<ToolTipProps> = (props) => {
  const { className, children, tooltipName, icon = <ToolTipIcon />, t } = props;

  const classes = classNames('anys-tool-tip', className);
  const toolTipModalRef = useRef<ModalRef>(null);

  return (
    <div className={classes}>
      <Button
        type="button"
        variant="link"
        icon={icon}
        onClick={() => toolTipModalRef.current.open()}
      />
      <Modal
        type="confirm"
        ref={toolTipModalRef}
        isDark
        hideHeader
        modalName={tooltipName}
        footer={
          <Button
            type="button"
            styleType="primary"
            onClick={() => toolTipModalRef.current.close()}
          >
            {t('General.close')}
          </Button>
        }
      >
        {children}
      </Modal>
    </div>
  );
};

export default ToolTip;
