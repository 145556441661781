import { createContext } from 'react';
import utils from 'utils';

type ProfileContextProps = {
  isMine?: boolean;
  showAvailabilityModal?: boolean;
  setShowAvailabilityModal?: React.Dispatch<React.SetStateAction<boolean>>;
};

export default createContext<Partial<ProfileContextProps>>({
  isMine: true,
  showAvailabilityModal: false,
  setShowAvailabilityModal: utils.noop,
});
