import React, { useMemo } from 'react';
import classNames from 'classnames';
import Rating from 'components/Rating';
import LocationDisplay from 'components/LocationDisplay';
import { LocationType, Picture } from 'models/User';
import ResponsiveImage from 'components/ResponsiveImage';
import { AvatarImageVariants } from 'constants/image';
import { Link, LinkProps } from 'react-router-dom';
import { Skill } from 'models/Skills';
import SelectedSkills from 'components/SelectedSkills';
import AnyServiceLogoIcon from 'icons/AnyServiceLogo.icon';
import VerifiedIcon from 'icons/VerifiedProfile.icon';

import './MiniAvatar.styles.scss';

export type MiniAvatarProps = {
  className?: string;
  profileImage?: Picture<AvatarImageVariants>;
  userName?: string;
  isOnline?: boolean;
  info?: string;
  rating?: number | string;
  votes?: number;
  location?: LocationType;
  size?: 'small' | 'medium';
  displayLocation?: boolean;
  id?: number;
  skills?: Skill[];
  profession?: React.ReactNode;
  cta?: JSX.Element; // Call to action
  profileLinkProps?: Omit<LinkProps, 'to'>;
  isSkillReview: boolean;
  isVerified?: boolean;
};

const MiniAvatar: React.FC<MiniAvatarProps> = (props) => {
  const {
    id,
    className,
    profileImage,
    userName,
    isOnline,
    info,
    rating,
    votes,
    location,
    cta,
    displayLocation = false,
    size = 'small',
    skills,
    profession,
    profileLinkProps,
    isSkillReview,
    isVerified,
  } = props;

  const classes = classNames('anys-mini-avatar', className, {
    'with-skills': skills?.length,
    'with-cta': !!cta,
  });

  const clonedCta = useMemo(() => {
    if (!cta) return null;

    return React.cloneElement(cta, {
      className: classNames(cta.props.className, 'cta'),
    });
  }, [cta]);

  const verifiedIcon = isVerified && (
    <VerifiedIcon className="anys-mini-avatar__verified" />
  );

  const userLocationInArray = useMemo(
    () => (location ? [location] : undefined),
    [location],
  );

  const maxRating = isSkillReview ? 10 : 5;

  return (
    <div className={classes}>
      {!!id && (
        <Link
          tabIndex={0}
          to={`/profile/${id}`}
          className="anys-mini-avatar__nav-layer"
          {...profileLinkProps}
        />
      )}

      <div className={classNames('anys-mini-avatar__picture', size)}>
        {profileImage ? (
          <ResponsiveImage
            alt={userName || ''}
            resizedVariants={profileImage?.resizedUrls}
            src={profileImage?.resizedUrls?.[40]}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = profileImage?.url;
            }}
          />
        ) : (
          <div className="anys-mini-avatar__picture__placeholder">
            <AnyServiceLogoIcon />
          </div>
        )}
      </div>
      {info ? (
        <p className="anys-mini-avatar__info">
          <label className="anys-mini-avatar__username">
            {userName}
            {verifiedIcon}
          </label>
          {info && (
            <span className="anys-mini-avatar__user-activity">
              <span
                className={
                  isOnline ? 'spacing-dot spacing-dot-online' : 'spacing-dot'
                }
              />
              <span className="anys-mini-avatar__info__ago">{info}</span>
            </span>
          )}
        </p>
      ) : (
        <label className="anys-mini-avatar__username">
          {userName}
          {verifiedIcon}

          {profession ? (
            <span className="anys-mini-avatar__profession">
              <span className="spacing-dot" />
              <span>{profession}</span>
            </span>
          ) : null}
        </label>
      )}

      <Rating
        reviewFormat={(revs) => `(${revs ? revs : 0})`}
        // There are some ratings that are string and some are number
        valueFormat={(val) =>
          val
            ? `${Number.parseFloat(`${val}`).toFixed(1)} / ${maxRating}`
            : `- / ${maxRating}`
        }
        value={rating ? Number.parseFloat(`${rating}`) : 0}
        reviews={votes}
        changable={false}
        withValue
        maxReviewScore={maxRating}
        useSmily={!isSkillReview}
      />

      {displayLocation && userLocationInArray && (
        <LocationDisplay
          locations={userLocationInArray}
          locationType="One spot"
        />
      )}
      {skills?.length ? (
        <SelectedSkills
          skills={skills}
          shownSkills={3}
          className="anys-mini-avatar__skills"
        />
      ) : null}
      {clonedCta}
    </div>
  );
};

export default MiniAvatar;
