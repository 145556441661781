import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

export default (props: IconProps) => (
  <svg
    width="45"
    height="44"
    viewBox="0 0 45 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.5 22C0.5 9.84973 10.3497 0 22.5 0C34.6503 0 44.5 9.84973 44.5 22C44.5 34.1503 34.6503 44 22.5 44C10.3497 44 0.5 34.1503 0.5 22ZM22.5 4C12.5589 4 4.5 12.0589 4.5 22C4.5 31.9411 12.5589 40 22.5 40C32.4411 40 40.5 31.9411 40.5 22C40.5 12.0589 32.4411 4 22.5 4ZM15.5 14C16.6046 14 17.5 14.8954 17.5 16V17C17.5 18.1046 16.6046 19 15.5 19C14.3954 19 13.5 18.1046 13.5 17V16C13.5 14.8954 14.3954 14 15.5 14ZM29.5 14C30.6046 14 31.5 14.8954 31.5 16V17C31.5 18.1046 30.6046 19 29.5 19C28.3954 19 27.5 18.1046 27.5 17V16C27.5 14.8954 28.3954 14 29.5 14ZM31.4799 27.7172C31.6361 28.8106 30.8763 29.8237 29.7828 29.9799L15.7828 31.9799C14.6894 32.1361 13.6763 31.3763 13.5201 30.2828C13.3639 29.1894 14.1237 28.1763 15.2172 28.0201L29.2172 26.0201C30.3106 25.8639 31.3237 26.6237 31.4799 27.7172Z"
      fill="url(#paint0_linear_9548_30169)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_9548_30169"
        x1="0.5"
        y1="44"
        x2="45.2673"
        y2="43.2049"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A761F5" />
        <stop offset="1" stopColor="#50A8FA" />
      </linearGradient>
    </defs>
  </svg>
);
