import { useEffect, useState } from 'react';

const MEDIA_MATCHES = {
  isPhablet: `(max-width: 767px)`,
  isTabletLandscape: '(max-width: 1023px)',
  isLaptop: `(min-width: 769px) and (max-width: 1439px)`,
  isPc: `(min-width: 1440px) and (max-width: 1919px)`,
  isQHD: `(min-width: 1920px) and (max-width: 2440px)`,
  is4k: `(min-width: 2441px) `,
  touchDevice: '(hover: none), (pointer: coarse)',
  prefersDark: '(prefers-color-scheme: dark)',
  maxTablet:
    '(orientation: landscape) and (max-width: 1200px), (orientation: portrait) and (max-width: 968px)',
} as const;

type QUERY = keyof typeof MEDIA_MATCHES;

export default function useMatchMedia(
  query: QUERY | QUERY[] | number | [number, number],
  every = false,
) {
  const [matches, setMatches] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      if (Array.isArray(query)) {
        if (typeof query[0] === 'number') {
          setMatches(
            window.matchMedia(
              `(min-width: ${query[0]}px) and (max-width: ${query[1]}px)`,
            ).matches,
          );
        } else {
          setMatches(
            (query as QUERY[])[every ? 'every' : 'some'](
              (q) => window.matchMedia(MEDIA_MATCHES[q]).matches,
            ),
          );
        }
      } else {
        if (typeof query === 'number') {
          setMatches(window.matchMedia(`(max-width: ${query}px)`).matches);
        } else {
          setMatches(window.matchMedia(MEDIA_MATCHES[query]).matches);
        }
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [every, query]);

  return matches;
}
