import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import NotificationsContext from './Notifications.context';
import api from 'api';
import { NotificationCount, NotificationEntity } from 'models/Notification';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import eventSocketService from 'services/socket/eventSocketService';
import { NavMenuItem } from 'models/Navigation';

const Notifications: React.FC<React.PropsWithChildren> = (props) => {
  const { children } = props;

  const { currentUser } = useContext(CurrentUserContext);

  const [generalNotifications, setGeneralNotifications] = useState<
    Partial<Record<NavMenuItem, number>>
  >({});
  const [inboxItemNotifications, setInboxItemNotifications] = useState<
    Partial<Record<number, NotificationEntity[]>>
  >({});
  const [arbitrationNotifications, setArbitrationNotifications] = useState<
    Partial<Record<number, NotificationEntity[]>>
  >({});

  const notificationsByGroup = useMemo(() => {
    const base = { ...generalNotifications };

    base.inbox =
      (base?.inbox || 0) + Object.values(inboxItemNotifications).length;
    base.arbitration =
      (base?.arbitration || 0) + Object.values(arbitrationNotifications).length;

    return base;
  }, [arbitrationNotifications, generalNotifications, inboxItemNotifications]);

  const notificationsCount = useMemo(
    () =>
      Object.values(notificationsByGroup).reduce(
        (sumCount, count) => sumCount + count,
        0,
      ),
    [notificationsByGroup],
  );

  const readManyNotifications = useCallback(
    async (type: NavMenuItem, entityId: number, notifIdsToRead: number[]) => {
      let notifIds = notifIdsToRead;

      if (!notifIds?.length) {
        switch (type) {
          case 'inbox':
            if (inboxItemNotifications[entityId]?.length)
              notifIds = inboxItemNotifications[entityId].map(({ id }) => id);

            break;

          case 'arbitration':
            if (arbitrationNotifications[entityId]?.length)
              notifIds = arbitrationNotifications[entityId].map(({ id }) => id);

            break;

          default:
            break;
        }
      }

      if (!notifIds?.length) return false;

      try {
        await api.notification.notifications.readManyNotifications(notifIds);

        switch (type) {
          case 'inbox':
            setInboxItemNotifications((oldNotif) => {
              const filteredNotifications = oldNotif[entityId]?.filter(
                ({ id }) => !notifIds.includes(id),
              );

              if (!filteredNotifications?.length) {
                delete oldNotif[entityId];

                return { ...oldNotif };
              }

              return {
                ...oldNotif,
                [entityId]: filteredNotifications,
              };
            });
            break;

          case 'arbitration':
            setArbitrationNotifications((oldNotif) => {
              const filteredNotifications = oldNotif[entityId]?.filter(
                ({ id }) => id !== entityId,
              );

              if (!filteredNotifications?.length) {
                delete oldNotif[entityId];

                return { ...oldNotif };
              }

              return {
                ...oldNotif,
                [entityId]: filteredNotifications,
              };
            });
            break;

          default:
            break;
        }

        setGeneralNotifications((oldNotif) => ({
          ...oldNotif,
          [type]: Math.max(0, (oldNotif[type] || 0) - notifIds.length),
        }));

        return true;
      } catch (error) {
        console.error(error);
      }
    },
    [arbitrationNotifications, inboxItemNotifications],
  );

  const getArbitrationNotifCount = useCallback(
    (arbitrationId: number) => {
      return arbitrationNotifications[arbitrationId]?.length || 0;
    },
    [arbitrationNotifications],
  );

  const getLastNotifForArbitration = useCallback(
    (arbitrationId: number): NotificationEntity | null => {
      if (!arbitrationNotifications[arbitrationId]?.length) return null;

      const notifLength = getArbitrationNotifCount(arbitrationId);

      return arbitrationNotifications[arbitrationId][notifLength - 1];
    },
    [getArbitrationNotifCount, arbitrationNotifications],
  );

  const getInboxItemNotifCount = useCallback(
    (inboxItemId: number) => {
      return inboxItemNotifications[inboxItemId]?.length || 0;
    },
    [inboxItemNotifications],
  );

  const getLastNotifForInboxItem = useCallback(
    (inboxItemId: number): NotificationEntity | null => {
      if (!inboxItemNotifications[inboxItemId]?.length) return null;

      const notifLength = getInboxItemNotifCount(inboxItemId);

      return inboxItemNotifications[inboxItemId][notifLength - 1];
    },
    [getInboxItemNotifCount, inboxItemNotifications],
  );

  useEffect(() => {
    if (!currentUser?.id) return;

    const onNotificationCreated = (notification: NotificationEntity) => {
      const { inboxItem, arbitration } = notification;

      const type: NavMenuItem = arbitration?.id ? 'arbitration' : 'inbox';

      if (arbitration?.id) {
        setArbitrationNotifications((old) => ({
          ...old,
          [arbitration.id]: [...(old[arbitration.id] || []), notification],
        }));
      } else if (inboxItem?.id) {
        setInboxItemNotifications((old) => ({
          ...old,
          [inboxItem.id]: [...(old[inboxItem.id] || []), notification],
        }));
      } else {
        setGeneralNotifications((oldNotif) => ({
          ...oldNotif,
          [type]: (oldNotif[type] || 0) + 1,
        }));
      }
    };

    eventSocketService.addListener(
      'notification-created',
      onNotificationCreated,
    );

    return () =>
      eventSocketService.removeListener(
        'notification-created',
        onNotificationCreated,
      );
  }, [currentUser?.id]);

  useEffect(() => {
    if (!currentUser?.id) return;

    const getNotificationCount = async () => {
      try {
        const { data } =
          await api.notification.notifications.getNotificationsCount();

        const { arbitration, inbox } = data.reduce<
          Partial<Record<NavMenuItem, NotificationCount[]>>
        >(
          (grouped, notif) => {
            const { arbitrationId } = notif;

            const type: NavMenuItem = arbitrationId ? 'arbitration' : 'inbox';

            grouped[type] = [...grouped[type], notif];

            return grouped;
          },
          {
            arbitration: [],
            inbox: [],
          },
        );

        if (arbitration.length) {
          setArbitrationNotifications((old) => {
            arbitration.forEach((notifCount) => {
              const { arbitrationId, type } = notifCount;

              // Note: Keep synced with ARBITRATION_TYPE_TO_PANEL_VIEW;
              // Value used in Arbitration page to know where to redirect user
              // based on notification type
              const notifType =
                type === 'asArbiter'
                  ? 'arbitration-arbiter-invite'
                  : 'arbitration-defendant-epilogue';

              old[arbitrationId] = [
                ...(old[arbitrationId] || []),
                {
                  id: arbitrationId,
                  type: notifType,
                  createdAt: '',
                  updatedAt: '',
                  isRead: false,
                  relativeUrl: '/',
                  user: null,
                },
              ];
            });

            return {
              ...old,
            };
          });
        }

        if (inbox.length) {
          setInboxItemNotifications((old) => {
            inbox.forEach((notifCount) => {
              const { inboxItemId } = notifCount;

              old[inboxItemId] = [
                ...(old[inboxItemId] || []),
                {
                  id: inboxItemId,
                  type: 'contract-made',
                  createdAt: '',
                  updatedAt: '',
                  isRead: false,
                  relativeUrl: '/',
                  user: null,
                },
              ];
            });

            return {
              ...old,
            };
          });
        }
      } catch (error) {
        console.error(error);
      }
    };

    getNotificationCount();
  }, [currentUser?.id]);

  return (
    <NotificationsContext.Provider
      value={{
        notificationsByGroup,
        notificationsCount,
        arbitrationNotifications,
        inboxItemNotifications,
        getLastNotifForArbitration,
        getArbitrationNotifCount,
        getLastNotifForInboxItem,
        getInboxItemNotifCount,
        readManyNotifications,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export default Notifications;
