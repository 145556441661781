import React from 'react';
import classNames from 'classnames';
import { JobTypeOfService } from 'models/Job/TypeOfService';
import { formatLocation } from 'utils/location';

import './PlainLocationDisplay.styles.scss';

type PlainLocationDisplayProps = {
  className?: string;
} & Pick<JobTypeOfService, 'locationType' | 'locations'>;

const PlainLocationDisplay: React.FC<PlainLocationDisplayProps> = (props) => {
  const { className, locationType, locations } = props;

  const classes = classNames('anys-plain-location-display', className);

  const displayedLocation = formatLocation({
    locationType,
    locations,
  });

  return <span className={classes}>{displayedLocation}</span>;
};

export default PlainLocationDisplay;
