import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Job, JobPost, JobState } from 'models/Job';
import { useTranslation } from 'react-i18next';
import ImagesPreview from 'components/ImagesPreview';
import DataPiecePreview from '../DataPiecePreview';
import FilesPreview from 'components/FilesPreview';
import { Picture, NonImageFile } from 'models/User';
import { getAttachmentChanges, isPendingChanges } from 'utils/job-changes';
import ToolTip from 'components/ToolTip';
import WarningIcon from 'icons/Warning.icon';
import JobChanges from 'components/JobChanges';

import './AttachmentsPreview.styles.scss';

type AttachmentsPreviewProps = {
  className?: string;
  jobTitle: string;
  jobState: JobState;
} & Pick<JobPost, 'attachments' | 'boosts'> &
  Pick<Job, 'prevVersion' | 'versionState'>;

const AttachmentsPreview: React.FC<AttachmentsPreviewProps> = (props) => {
  const {
    className,
    attachments,
    boosts,
    jobTitle,
    jobState,
    versionState,
    prevVersion,
  } = props;

  const { t } = useTranslation();

  const classes = classNames('anys-attachments-preview', className);

  const messages = useMemo(
    () => ({
      attachments: t('General.attachments'),
      images: t('General.images'),
      files: t('General.files'),
    }),
    [t],
  );

  const hasAttachments = !!attachments?.length;
  const hasBoosts = !!boosts?.length;

  const attachmentChanges =
    prevVersion && isPendingChanges(versionState)
      ? getAttachmentChanges(
          prevVersion.attachments?.map((attch) => ({
            name: attch.name,
            url: attch.url,
            originalFileId: attch.originalFileId,
          })),
          attachments?.map((attch) => ({
            name: attch.name,
            url: attch.url,
            originalFileId: attch.originalFileId,
          })),
        )
      : null;

  const { attachmentImages, attachmentFiles } = useMemo(
    () =>
      hasAttachments
        ? attachments.reduce<{
            attachmentImages: Picture[];
            attachmentFiles: NonImageFile[];
          }>(
            (separatedFiles, file) => {
              if (file.type?.startsWith('image/')) {
                separatedFiles.attachmentImages.push(file as Picture);
              } else {
                separatedFiles.attachmentFiles.push(file);
              }

              return separatedFiles;
            },
            { attachmentImages: [], attachmentFiles: [] },
          )
        : { attachmentImages: [], attachmentFiles: [] },
    [attachments, hasAttachments],
  );
  const boostImages = useMemo(
    () =>
      hasBoosts
        ? boosts.reduce<Picture[]>((images, boost) => {
            if (boost.type === 'Photo') {
              images.push(boost.file as Picture);
            }

            return images;
          }, [])
        : [],
    [boosts, hasBoosts],
  );
  const images = useMemo(
    () => attachmentImages.concat(boostImages),
    [attachmentImages, boostImages],
  );

  const hasImages = images.length > 0;
  const hasFiles = attachmentFiles.length > 0;

  if (!hasImages && !hasFiles) return null;

  return (
    <section className={classes}>
      <h2>
        {messages.attachments}
        {attachmentChanges ? (
          <ToolTip
            t={t}
            tooltipName="attachment-changes"
            icon={WarningIcon}
            className="anys-attachments-preview__changes"
          >
            <JobChanges
              changedFrom={attachmentChanges.changedFrom}
              changedTo={attachmentChanges.changedTo}
            />
          </ToolTip>
        ) : null}
      </h2>

      <div className="anys-attachments-preview__values">
        {hasImages && (
          <DataPiecePreview
            label={messages.images}
            value={
              <ImagesPreview
                images={images}
                jobTitle={jobTitle}
                jobState={jobState}
              />
            }
            valueAs="div"
            className="anys-attachments-preview__values__images"
          />
        )}

        {hasFiles && (
          <DataPiecePreview
            label={messages.files}
            value={<FilesPreview files={attachmentFiles} />}
            valueAs="div"
            className="anys-attachments-preview__values__files"
          />
        )}
      </div>
    </section>
  );
};

export default AttachmentsPreview;
