import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import NavBar from 'components/NavBar';
import MainTemplate from 'components/templates/MainTemplate';
import { InboxUrlParams } from 'models/Inbox';
import classNames from 'classnames';
import ChatList from 'components/ChatList';
import useHeaderStyles from 'hooks/useHeaderStyles';
import useHeaderContent from 'hooks/useHeaderContent';
import { useTranslation } from 'react-i18next';

import './ChatPage.styles.responsive.scss';

const ChatPage = () => {
  const { chatUserId } = useParams<Pick<InboxUrlParams, 'chatUserId'>>();

  const { t } = useTranslation();

  const classes = classNames('anys-chat-page', {
    'anys-chat-page--chat-list': !+chatUserId,
  });

  useHeaderStyles({ isHeaderShown: !+chatUserId, showBackButton: true });

  useHeaderContent(
    <div className="mobile-header-content">{t('General.chat')}</div>,
  );

  return (
    <>
      <NavBar className="anys-chat-page__navbar" />
      <MainTemplate
        className={classes}
        asideLeft={<ChatList activeChatId={+chatUserId} isChatPage />}
      >
        <Outlet />
      </MainTemplate>
    </>
  );
};

export default ChatPage;
