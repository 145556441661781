import React, { ReactNode, useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import ImageInput from 'components/ImageInput';
import AnyServiceLogoIcon from 'icons/AnyServiceLogo.icon';

import './Avatar.styles.scss';

type AvatarProps = {
  className?: string;
  img?: string;
  alt?: string;
  edit?: boolean;
  fallbackNode?: ReactNode;
};

const Avatar: React.FC<AvatarProps> = (props) => {
  const {
    className,
    img,
    fallbackNode = <AnyServiceLogoIcon />,
    alt = 'avatar',
    edit = false,
  } = props;

  const { uploadUserFile } = useContext(CurrentUserContext);

  const classes = classNames('anys-avatar', className);

  const [imgState, setImgState] = useState(img);

  useEffect(() => {
    setImgState(img);
  }, [img]);

  return (
    <div className={classes}>
      <div className="anys-avatar__aspect-wrapper">
        {imgState ? (
          <img src={imgState} alt={alt} />
        ) : (
          <div className="anys-avatar__aspect-wrapper__placeholder">
            {fallbackNode}
          </div>
        )}
      </div>

      {edit && (
        <ImageInput
          onUploadImage={(image, file) => {
            setImgState(image);
            uploadUserFile('profileImage', [file]);
          }}
        />
      )}
    </div>
  );
};

export default Avatar;
