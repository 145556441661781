import React from 'react';
import _ from 'lodash';

type IconProps = React.SVGProps<SVGSVGElement> & { showGradient?: boolean };

export default (props: IconProps) => {
  const { showGradient = true, ...restOfProps } = props;

  const gradientId = _.uniqueId();

  return (
    <svg
      width="26"
      height="25"
      viewBox="0 0 26 25"
      fill="none"
      stroke={showGradient ? `url(#${gradientId})` : 'currentColor'}
      {...restOfProps}
    >
      <path
        d="M17.0538 16.3518C16.5517 16.8534 16.5516 17.6668 17.0537 18.1685L22.513 23.6236C23.0152 24.1254 23.8295 24.1255 24.3318 23.6237C24.834 23.1221 24.834 22.3087 24.3319 21.807L18.8727 16.3519C18.3705 15.8501 17.5561 15.85 17.0538 16.3518ZM17.0538 16.3518L15.647 14.9471"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.98712 7.05067C2.74273 4.23358 4.94539 2.03318 7.76536 1.27834C10.5853 0.523506 13.5942 1.32891 15.6586 3.39116C17.7229 5.45342 18.5292 8.45922 17.7736 11.2763C17.018 14.0934 14.8153 16.2938 11.9953 17.0486C9.17535 17.8035 6.16647 16.9981 4.10211 14.9358C2.03774 12.8736 1.23151 9.86776 1.98712 7.05067Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      {showGradient && (
        <defs>
          <linearGradient
            id={gradientId}
            x1="0.832031"
            y1="9.52199"
            x2="18.21"
            y2="9.52199"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FF7BAC" />
            <stop offset="1" stopColor="#D142F4" />
          </linearGradient>
        </defs>
      )}
    </svg>
  );
};
