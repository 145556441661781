import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useJobPost } from '../JobPost/JobPost.page';
import JobPostPreview from '../../../../../components/JobPostPreview';
import JobPostPreviewActions from './JobPostPreviewActions';
import { Job, JobPost } from 'models/Job';
import useJobPostSign from '../../hooks/useJobPostSign';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import SendTo from '../CreateEditJobPost/components/SendTo';
import { Form } from 'react-final-form';
import { User } from 'models/User';
import { FORM_ERROR, FormApi } from 'final-form';
import { parseJobPostToFormValues } from '../JobPost/utils';
import { useTranslation } from 'react-i18next';
import UserScheduleContext from 'components/JobPostPreview/components/UserScheduleModal/UserScheduleProvider/UserSchedule.context';
import JobPostSummaryPayModal from '../JobPostSummaryPayModal';

import './ViewJobPost.styles.scss';
import useCallbackRef from 'hooks/useCallbackRef';
import { ModalRef } from 'components/Modal';
import confirm from 'modules/confirm';

type SendToFormValues = Pick<JobPost<'form'>, 'sendToTimeline' | 'sendToUsers'>;

const ViewJobPost: React.FC = () => {
  const { t } = useTranslation();

  const {
    jobPost,
    fetchingJobPost,
    updateJobPostAsOtherUser,
    setJobPost,
    onSubmitForm,
  } = useJobPost();

  const { currentUser } = useContext(CurrentUserContext);
  const { selectedSchedule, setSelectedSchedule } =
    useContext(UserScheduleContext);

  const {
    id,
    type,
    isSigned,
    state,
    user,
    trackingData,
    sendToTimeline,
    sendToUsers,
    timeAndPricing,
    freeCancelation,
  } = useMemo(() => jobPost || ({} as JobPost), [jobPost]);

  const sendToFormApi = useRef<FormApi<SendToFormValues>>();

  const [jobPostSummaryModalRef, jobPostSummaryModalSetRef] =
    useCallbackRef<ModalRef>(null);

  const { importAvailability } = timeAndPricing || {};

  const isProvideJob = type === 'Provide';

  const hasClientSigned = !isProvideJob && isSigned;
  const hasProviderSigned = isProvideJob && isSigned;

  const isOwnJob = user?.id === currentUser?.id;

  const isHourlyPricing = timeAndPricing?.type === 'Hourly';

  const hasNoJobDates = importAvailability
    ? !selectedSchedule
    : (isHourlyPricing || !!freeCancelation) && !timeAndPricing?.startDate;

  const hasNoJobPricing = !(timeAndPricing?.price > 0);

  const isSignDisabled = (!isOwnJob && hasNoJobDates) || hasNoJobPricing;

  const { client, toggleSign, isCurrentUserClient, provider, signing } =
    useJobPostSign({
      jobId: id,
      jobType: type,
      jobState: state,
      jobCreator: user,
      trackingData,
      updateJob: (newJob: Job | JobPost) => {
        setJobPost((oldJob) => ({
          ...oldJob,
          isSigned: 'isSigned' in newJob ? newJob.isSigned : false,
        }));
      },
    });

  const sendToInitialValues: Partial<SendToFormValues> = useMemo(
    () => ({
      sendToTimeline,
      sendToUsers,
    }),
    [sendToTimeline, sendToUsers],
  );

  const onSubmitSendTo = useCallback(
    (values: SendToFormValues) => {
      onSubmitForm({
        ...parseJobPostToFormValues(jobPost),
        isSigned: jobPost.isSigned,
        ...values,
      });
    },
    [jobPost, onSubmitForm],
  );

  const toggleSignWrapper = useCallback(
    async (hasSigned: boolean) => {
      // If we don't have a selected schedule, we should
      // go with the regular flow
      if (isOwnJob || !selectedSchedule) {
        await toggleSign(hasSigned);
      } else {
        const jobPostAsFormValues = parseJobPostToFormValues(jobPost);

        jobPostAsFormValues.timeAndPricing = {
          ...jobPostAsFormValues.timeAndPricing,
          startDate: selectedSchedule.startDate,
          endDate: selectedSchedule.endDate,
          importAvailability: false,
        };

        await updateJobPostAsOtherUser({
          ...jobPostAsFormValues,
          isSigned: true,
        });
      }
    },
    [isOwnJob, jobPost, selectedSchedule, toggleSign, updateJobPostAsOtherUser],
  );

  const handleSignPrompt = useCallback(async () => {
    if (isSignDisabled) {
      await confirm({
        title: t('General.selectScheduleModalTitle'),
        description: (
          <>
            {hasNoJobDates && t('General.selectScheduleModalInfo')}

            {hasNoJobDates && hasNoJobPricing && <br />}

            {hasNoJobPricing && t('General.selectScheduleModalPrice')}
          </>
        ),
        cancelContent: null,
      });

      return;
    }
    jobPostSummaryModalRef.open();
  }, [
    hasNoJobDates,
    hasNoJobPricing,
    isSignDisabled,
    jobPostSummaryModalRef,
    t,
  ]);

  useEffect(() => {
    setSelectedSchedule(null);
  }, [setSelectedSchedule, id]);

  return (
    <JobPostPreview
      className="anys-view-job-post"
      jobPost={jobPost}
      isFetching={fetchingJobPost}
      hasClientSigned={hasClientSigned}
      hasProviderSigned={hasProviderSigned}
      client={client}
      provider={provider}
      isCurrentUserClient={isCurrentUserClient}
      actionInProgress={signing}
      handleSign={handleSignPrompt}
      isOwnJob={isOwnJob}
      sendToForm={
        isOwnJob &&
        isSigned && (
          <Form
            initialValues={sendToInitialValues}
            onSubmit={onSubmitSendTo}
            validate={(values) => {
              const { sendToTimeline, sendToUsers } = values;

              if (!sendToTimeline && !sendToUsers?.length) {
                return { [FORM_ERROR]: t('General.selectRequired') };
              }
            }}
            render={(formRenderProps) => {
              const { handleSubmit, form, values, error, touched } =
                formRenderProps;

              const { sendToUsers } = values;

              sendToFormApi.current = form;

              const sendToValues = {
                sendToUsers,
              };

              const hasTouchedFields =
                touched &&
                Object.values(touched)?.filter((touchedState) => touchedState)
                  ?.length > 0;

              const onChangeSendToUsers = (users: User[]) =>
                form.change('sendToUsers', users);

              return (
                <form onSubmit={handleSubmit}>
                  <SendTo
                    className="anys-view-job-post__send-to"
                    onChangeSendToUsers={onChangeSendToUsers}
                    valuesFromForm={sendToValues}
                    isProvideJob={isProvideJob}
                  />
                  {error && hasTouchedFields && (
                    <p className="api-error">{error}</p>
                  )}
                </form>
              );
            }}
          />
        )
      }
    >
      <JobPostPreviewActions
        provider={provider}
        jobPost={jobPost}
        hasClientSigned={hasClientSigned}
        hasProviderSigned={hasProviderSigned}
        isCurrentUserClient={isCurrentUserClient}
        inProgress={signing}
        isOwnJob={isOwnJob}
        onSign={handleSignPrompt}
        handleSend={() => sendToFormApi.current.submit()}
      />

      <JobPostSummaryPayModal
        jobPostSummaryModalRef={jobPostSummaryModalSetRef}
        summaryType={isCurrentUserClient ? 'client' : 'provider'}
        onClose={() => jobPostSummaryModalRef.close()}
        handleSign={toggleSignWrapper}
      />
    </JobPostPreview>
  );
};

export default ViewJobPost;
