import MobileHeaderContext, {
  MobileHeaderStyleOptions,
} from 'components/MobileHeader/providers/MobileHeader/MobileHeader.context';
import pickBy from 'lodash/pickBy';
import { useContext, useEffect, useRef } from 'react';

export default function useHeaderStyles(
  {
    absolutePosition,
    className,
    hasBorder,
    isBackButtonTransparent,
    isHeaderShown,
    showBackButton,
  }: MobileHeaderStyleOptions,
  dependencies: (string | number | boolean)[] = [],
  keepOldState?: boolean,
) {
  const { styleOptions, setStyleOptions } = useContext(MobileHeaderContext);

  const oldStyleOptions = useRef(styleOptions);

  useEffect(() => {
    setStyleOptions((oldStyle) => {
      const newStyles = {
        absolutePosition,
        className,
        hasBorder,
        isBackButtonTransparent,
        isHeaderShown,
        showBackButton,
      };

      return {
        ...(keepOldState && oldStyle),
        // Prevent unpassed styles
        // from deleting existing ones
        ...pickBy(newStyles, (v) => v !== undefined),
      };
    });
  }, [
    setStyleOptions,
    absolutePosition,
    className,
    hasBorder,
    isBackButtonTransparent,
    isHeaderShown,
    showBackButton,
    keepOldState,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ...dependencies,
  ]);

  useEffect(() => {
    const oldStyles = oldStyleOptions.current;
    return () => {
      setStyleOptions(oldStyles);
    };
  }, [setStyleOptions]);
}
