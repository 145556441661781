import React, { useEffect, useRef } from 'react';

const isElInViewport = (el: HTMLDivElement) => {
  if (typeof window === 'undefined' || typeof document === 'undefined')
    return false;

  const rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

type InViewportObserverProps = {
  dependencies?: React.DependencyList;
  className?: string;
  callback: (isVisible: boolean) => void;
};

const InViewportObserver: React.FC<
  React.PropsWithChildren<InViewportObserverProps>
> = (props) => {
  const { dependencies = [], callback, children, className } = props;

  const prevIsVisible = useRef(false);

  const observedElRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (!observedElRef.current) return;

    if (!prevIsVisible.current && isElInViewport(observedElRef.current)) {
      prevIsVisible.current = true;
      callback?.(true);
    }

    if (prevIsVisible.current && !isElInViewport(observedElRef.current)) {
      prevIsVisible.current = false;
      callback?.(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies]);

  return (
    <div ref={observedElRef} className={className}>
      {children}
    </div>
  );
};

export default InViewportObserver;
