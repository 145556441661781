import React from 'react';
import classNames from 'classnames';
import TimesCircleIcon from 'icons/TimesCircle.icon';
import InputField from 'components/InputField';
import { InputFieldProps } from 'components/InputField/InputField.component';
import SearchIcon from 'icons/Search.icon';

import './Search.styles.scss';

type SearchProps = InputFieldProps & {
  showIconGradient?: boolean;
  className?: string;
  onClear?: () => void;
};

const Search: React.FC<SearchProps> = (props) => {
  const { className, onClear, showIconGradient = true, ...rest } = props;

  const classes = classNames('anys-search', className);

  return (
    <InputField
      className={classes}
      prefixNode={
        <SearchIcon
          className="anys-search__search-icon"
          showGradient={showIconGradient}
        />
      }
      suffixNode={
        <button type="button" className="anys-search__clear">
          <TimesCircleIcon
            className="anys-search__times-circle-icon"
            onClick={() => onClear()}
          />
        </button>
      }
      autoComplete="off"
      type="text"
      {...rest}
    />
  );
};

export default Search;
