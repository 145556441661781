import React, { useCallback, useState } from 'react';
import AmountSlider from '../AmountSlider';
import { Button } from 'ncoded-component-library';
import { Contract } from 'models/Contract';
import { Form } from 'react-final-form';
import GradientText from 'components/GradientText';
import Notice from 'components/Notice';
import api from 'api';
import classNames from 'classnames';
import { useContract } from '../../../Contract/Contract.page';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import TimesIcon from 'icons/Times.icon';
import useInboxLink from 'router/subrouters/Inbox/hooks/useInboxLink';
import { useTranslation } from 'react-i18next';

import './PaymentNegotiation.styles.scss';
import './PaymentNegotiation.styles.responsive.scss';

type PaymentNegotiationType = {
  commonId: number;
  className?: string;
  initialValue?: number;
  maxAmountFee?: number;
  minAmountFee?: number;
  title?: string;
  message?: string;
  notice?: string;
  from?: 'client' | 'provider';
  goToArbitration?: boolean;
  arbitrationIsAllowed?: boolean;
  numberOfProposals?: number;
};

const PaymentNegotiation = (props: PaymentNegotiationType) => {
  const {
    commonId,
    className,
    initialValue,
    maxAmountFee,
    minAmountFee,
    title,
    message,
    notice,
    from,
    goToArbitration,
    arbitrationIsAllowed,
    numberOfProposals,
  } = props;

  const { setContract } = useContract();

  const navigate = useNavigate();

  const location = useLocation();
  const isInbox = location.pathname?.startsWith('/inbox');

  const { t } = useTranslation();

  const { linkWihoutInboxItem } = useInboxLink();

  const classes = classNames('anys-payment-negotiation', className);

  const [initial, setInitial] = useState(initialValue);
  const [showNotice, setShowNotice] = useState(true);

  const [disableSlides, setDisableSlides] = useState<boolean>(true);

  // TODO: what is with this
  // const [showNotice, setShowNotice] = useState(
  //   from === 'client' ? true : false,
  // );

  const onConfirm = useCallback(
    async (values: any) => {
      try {
        if (+values.cancelationFee === initialValue) {
          const paymentValue = +values.cancelationFee * 100;

          const { data } = await api.contract.acceptPayment(
            commonId,
            paymentValue,
          );
          if (data) {
            setContract((old: Contract) => ({
              ...old,
              paymentProposal: {
                ...old.paymentProposal,
                finalPrice: paymentValue,
                state:
                  from === 'client'
                    ? t('Contract.clientAccepted')
                    : t('Contract.providerAccepted'),
              },
            }));
          }
        } else {
          const { data } = await api.contract.counterPayment(
            commonId,
            +values.cancelationFee * 100,
          );
          if (data) {
            setContract((old: Contract) => ({
              ...old,
              paymentProposal: {
                ...old.paymentProposal,
                state:
                  from === 'client'
                    ? t('Contract.clientProposed')
                    : t('Contract.providerProposed'),
              },
            }));
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    [commonId, from, initialValue, setContract, t],
  );

  return (
    <div className={classes}>
      <Form
        onSubmit={onConfirm}
        initialValues={
          initial && {
            cancelationFee: initial,
            priceRange: {
              min: initial,
              max: maxAmountFee,
              range: maxAmountFee - initial,
            },
          }
        }
        render={({ handleSubmit, form, values }) => {
          return (
            <form
              onSubmit={handleSubmit}
              className="anys-payment-negotiation__form"
            >
              <div className="anys-payment-negotiation__form__title">
                <h1>{title}</h1>
                <Link
                  to={isInbox ? linkWihoutInboxItem : '/provide'}
                  className="anys-payment-negotiation__form__title__close"
                >
                  <TimesIcon />
                </Link>
              </div>
              {message && <span>{message}</span>}

              {notice &&
                showNotice &&
                goToArbitration &&
                arbitrationIsAllowed && <Notice type="warn">{notice}</Notice>}

              <AmountSlider
                form={form}
                maxAmountFee={maxAmountFee}
                minAmountFee={minAmountFee}
                label={t('Contract.paymentRequest')}
                initialValue={initial}
                disabled={disableSlides}
              />

              <div className="anys-end-contract-modal__actions">
                <Button
                  type="button"
                  variant={'solid'}
                  onClick={(event) => handleSubmit(event)}
                  //   disabled={
                  //     initial ? false : hasReview ? disableButton(values) : false
                  //   }
                >
                  {disableSlides
                    ? t('General.accept')
                    : t('General.sendNewPaymentRequest')}
                </Button>

                {(from === 'client' &&
                  goToArbitration &&
                  arbitrationIsAllowed) ||
                numberOfProposals <= 2 ? (
                  <Button
                    variant="link"
                    onClick={async () => {
                      if (goToArbitration && from === 'client') {
                        const { data } =
                          await api.contract.declinePayment(commonId);

                        if (data) {
                          navigate({
                            pathname: '/arbitration',
                            search: `?arbitrationId=${data.contract.arbitration.id}`,
                          });
                        }
                      } else {
                        setDisableSlides((old) => !old);
                        if (initial) {
                          from === 'provider' && setShowNotice(true);
                        } else {
                          setInitial(initialValue);
                          from === 'provider' && setShowNotice(false);
                        }
                      }
                    }}
                  >
                    <GradientText>
                      {disableSlides
                        ? from === 'client' &&
                          goToArbitration &&
                          arbitrationIsAllowed
                          ? t('Arbitration.arbitrationProcess')
                          : numberOfProposals <= 2 && t('General.change')
                        : t('General.cancel')}
                    </GradientText>
                  </Button>
                ) : (
                  <></>
                )}
              </div>
            </form>
          );
        }}
      />
    </div>
  );
};

export default PaymentNegotiation;
