import { Bounds, LatLng, PlaceFormat } from 'models/User';

const getAddressComponent = (
  place: google.maps.places.PlaceResult,
  types: string[],
) =>
  place?.address_components?.filter((address) =>
    types.some((type) => address.types.includes(type)),
  )?.[0];

const placeOrigin = (place: google.maps.places.PlaceResult): LatLng => {
  if (!place?.geometry?.location) return null;

  return {
    lat: place.geometry.location.lat(),
    lng: place.geometry.location.lng(),
  };
};

const placeBounds = (place: google.maps.places.PlaceResult): Bounds => {
  if (!place?.geometry?.viewport) return null;

  const ne = place.geometry.viewport.getNorthEast();
  const sw = place.geometry.viewport.getSouthWest();

  return {
    ne: { lat: ne.lat(), lng: ne.lng() },
    sw: { lat: sw.lat(), lng: sw.lng() },
  };
};

const getPlaceDetails = (
  { placeId, placeDesc }: { placeId: string; placeDesc: string },
  sessionToken: google.maps.places.AutocompleteSessionToken,
  options: {
    withFullAddress: boolean;
    rawPlaceRef: React.MutableRefObject<google.maps.places.PlaceResult>;
  },
) =>
  new Promise<PlaceFormat>((resolve, reject) => {
    const serviceStatus = window.google.maps.places.PlacesServiceStatus;
    const el = document.createElement('div');
    const service = new window.google.maps.places.PlacesService(el);
    const fields = [
      'address_component',
      'formatted_address',
      'geometry',
      'place_id',
    ];
    const sessionTokenMaybe = sessionToken ? { sessionToken } : {};

    service.getDetails(
      { placeId, fields, ...sessionTokenMaybe },
      (place, status) => {
        if (status !== serviceStatus.OK) {
          reject(
            new Error(
              `Could not get details for place id "${placeId}", error status was "${status}"`,
            ),
          );
        } else {
          const origin = placeOrigin(place);

          const { withFullAddress, rawPlaceRef } = options;

          if (rawPlaceRef) rawPlaceRef.current = place;

          const fullAddressMaybe = withFullAddress
            ? {
                fullAddress: placeDesc || place.formatted_address,
              }
            : {};

          resolve({
            ...origin,
            ...fullAddressMaybe,
            city: getAddressComponent(place, [
              'locality',
              'sublocality_level_1',
              'political',
              'administrative_area_level_1',
            ])?.long_name,
            country: getAddressComponent(place, ['country'])?.long_name,
            street: getAddressComponent(place, ['route', 'street_address'])
              ?.long_name,
            bounds: placeBounds(place),
          });
        }
      },
    );
  });

const predictionSuccessful = (
  status: google.maps.places.PlacesServiceStatus,
) => {
  const { OK, ZERO_RESULTS } = window.google.maps.places.PlacesServiceStatus;
  return status === OK || status === ZERO_RESULTS;
};

const getPlacePredictions = (
  search: string,
  sessionToken: google.maps.places.AutocompleteSessionToken,
  searchRequest?: google.maps.places.AutocompletionRequest,
) =>
  new Promise<{
    search: string;
    predictions: google.maps.places.AutocompletePrediction[];
  }>((resolve, reject) => {
    const service = new window.google.maps.places.AutocompleteService();
    const sessionTokenMaybe = sessionToken ? { sessionToken } : {};

    service.getPlacePredictions(
      {
        input: search,
        //for now i will remove this until Nesa consider what we need to return in predicetion
        // types: ['neighborhood', 'route', 'country', 'locality'],
        ...sessionTokenMaybe,
        ...searchRequest,
      },
      (predictions, status) => {
        if (!predictionSuccessful(status)) {
          reject(new Error(`Prediction service status not OK: ${status}`));
        } else {
          const results = {
            search,
            predictions: predictions || [],
          };
          resolve(results);
        }
      },
    );
  });

const locationBounds = (latlng: LatLng, distance: number): Bounds => {
  const bounds = new window.google.maps.Circle({
    center: new window.google.maps.LatLng(latlng.lat, latlng.lng),
    radius: distance,
  }).getBounds();

  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();

  return {
    ne: { lat: ne.lat(), lng: ne.lng() },
    sw: { lat: sw.lat(), lng: sw.lng() },
  };
};

export default {
  locationBounds,
  getPlacePredictions,
  getPlaceDetails,
  placeBounds,
  placeOrigin,
  getAddressComponent,
};
