import { Contract, ContractRequest } from 'models/Contract';
import { JobPost } from 'models/Job';
import {
  getLockedData,
  removeId,
  removeIdFromArray,
  removeInvalidProps,
} from 'utils/job';
import {
  parseDeadlines,
  parseFormArbitrationOptions,
  parseFormDeadlines,
  parseFormTimeAndPricing,
  parseFormTypeOfService,
  parseTimeAndPricing,
  parseTypeOfService,
} from 'utils/job-parses';

const parseJobPostFormValuesToContractRequest = (
  values: Omit<JobPost<'form'>, 'attachments'> &
    Pick<Contract, 'prevAcceptedVersion'>,
  isOwnContract = true,
): ContractRequest => {
  const {
    id,
    type: jobType,
    isNegotiable,
    hasFreeCancelation,
    hasDeadlinePolicy,
    timeAndPricing,
    isLockedTimeAndPricing,
    deadlines = [],
    isLockedDeadlinePolicy,
    hasArbitration,
    sendToUsers,
    sendToTimeline,
    arbitrationOptions = [],
    isLockedArbitration,
    typeOfService,
    isLockedTypeOfService,
    freeCancelation,
    isLockedFreeCancelation,
    isSigned,
    attachmentIdsToDelete,
    boosts,
    prevAcceptedVersion,
    ...restOfValues
  } = values;

  const editableCoreInfo = isOwnContract
    ? {
        isLockedTimeAndPricing,
        isLockedDeadlinePolicy,
        isLockedTypeOfService,
        isLockedFreeCancelation,
        isLockedArbitration,
      }
    : {};

  const timeAndPricingMaybe = getLockedData(
    parseFormTimeAndPricing(timeAndPricing),
    isLockedTimeAndPricing,
    isOwnContract,
  );

  const typeOfServiceMaybe = getLockedData(
    removeInvalidProps(parseFormTypeOfService(typeOfService)),
    isLockedTypeOfService,
    isOwnContract,
  );

  const freeCancellationMaybe = getLockedData(
    freeCancelation ? removeInvalidProps(freeCancelation) : null,
    isLockedFreeCancelation,
    isOwnContract,
  );

  const hasFreeCancellationMaybe =
    isOwnContract || !isLockedFreeCancelation
      ? {
          hasFreeCancelation: hasFreeCancelation === 'true',
        }
      : {};

  const deadlineMaybe = getLockedData(
    parseFormDeadlines(deadlines),
    isLockedDeadlinePolicy,
    isOwnContract,
  );

  const hasDeadlinePolicyMaybe =
    isOwnContract || !isLockedDeadlinePolicy
      ? {
          hasDeadlinePolicy: hasDeadlinePolicy === 'true',
        }
      : {};

  const arbitrationMaybe = getLockedData(
    parseFormArbitrationOptions(arbitrationOptions),
    isLockedArbitration,
    isOwnContract,
  );

  const hasArbitrationMaybe =
    isOwnContract || !isLockedArbitration
      ? {
          hasArbitration: hasArbitration === 'true',
        }
      : {};

  const isNegotiableMaybe = isOwnContract
    ? {
        isNegotiable: isNegotiable === 'true',
      }
    : {};

  return {
    ...restOfValues,
    ...editableCoreInfo,
    timeAndPricing: isOwnContract
      ? timeAndPricingMaybe
      : removeId(timeAndPricingMaybe),
    typeOfService: isOwnContract
      ? typeOfServiceMaybe
      : removeId(typeOfServiceMaybe),
    freeCancelation: isOwnContract
      ? freeCancellationMaybe
      : removeId(freeCancellationMaybe),
    deadlines: isOwnContract ? deadlineMaybe : removeIdFromArray(deadlineMaybe),
    arbitrationOptions: isOwnContract
      ? arbitrationMaybe
      : removeIdFromArray(arbitrationMaybe),
    ...isNegotiableMaybe,
    ...hasFreeCancellationMaybe,
    ...hasDeadlinePolicyMaybe,
    ...hasArbitrationMaybe,
  };
};

const parseContractToJobPostFormValues = (
  contract: Contract,
): JobPost<'form'> => {
  if (!contract) return null;

  const {
    isNegotiable,
    hasFreeCancelation,
    hasDeadlinePolicy,
    timeAndPricing,
    deadlines = [],
    hasArbitration,
    mainSkill,
    skills,
    typeOfService,
    commonId,
    createdAt,
    payment,
    updatedAt,
    version,
    versionSubmitedBy,
    versionState,
    prevVersion,
    prevVersionId,
    provider,
    client,
    deletedAt,
    state,
    arbitration,
    endProcess,
    paymentProposal,
    reviews,
    ...restOfPost
  } = contract;

  return {
    ...restOfPost,
    typeOfService: parseTypeOfService(typeOfService),
    mainSkill: mainSkill?.name,
    skills: skills?.map(({ name }) => name),
    sendToTimeline: null,
    isSigned: false,
    deadlines: parseDeadlines(deadlines),
    timeAndPricing: parseTimeAndPricing(timeAndPricing),
    isNegotiable: `${isNegotiable}`,
    hasFreeCancelation: `${hasFreeCancelation}`,
    hasDeadlinePolicy: `${hasDeadlinePolicy}`,
    hasArbitration: `${hasArbitration}`,
  };
};

export {
  parseJobPostFormValuesToContractRequest,
  parseContractToJobPostFormValues,
};
