import React from 'react';
import LockedPadlockNewIcon from 'icons/LockedPadlockNew.icon';
import UnlockedPadlockNewIcon from 'icons/UnlockedPadlockNew.icon';

export const overallRatingOptions = Array.from({ length: 7 })

  .map((_, index) => ({
    label: index === 0 ? 'All' : index < 6 ? `${index + 4}+` : `${index + 4}`,
    value: index === 0 ? 'All' : index < 6 ? `${index + 4}+` : `${index + 4}`,
  }))
  .reverse();

export const negotiableOptions: {
  label: React.ReactNode;
  value: string;
}[] = [
  {
    label: (
      <>
        <label>Negotiable</label>
        <UnlockedPadlockNewIcon />
      </>
    ),
    value: 'Negotiable',
  },
  {
    label: (
      <>
        <label>Non negotiable</label>
        <LockedPadlockNewIcon />
      </>
    ),
    value: 'Non negotiable',
  },
];

export const reviewedAsClientOptions = Array.from({ length: 5 })

  .map((_, index) => ({
    label: index === 0 ? 'All' : index < 4 ? `${index + 1}+` : `${index + 1}`,
    value: index === 0 ? 'All' : index < 4 ? `${index + 1}+` : `${index + 1}`,
  }))
  .reverse();

export const raitingFilterOptions = Array.from({
  length: 10,
})
  .map((_, index) => ({
    label: `${index + 1} ${index === 9 ? '' : '+'}`,
    value: `${index + 1}`,
  }))
  .reverse();
export const numberOfJobsFilterOptions = [
  { label: '5+', value: '5' },
  { label: '10+', value: '10' },
  { label: '20+', value: '20' },
  { label: '50+', value: '50' },
].map(({ label, value }) => ({
  label: label,
  value: value,
}));
