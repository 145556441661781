import React from 'react';

type MoreHeaderIconProps = React.SVGProps<SVGSVGElement> & {
  fill?: string;
};

const MoreHeaderIcon: React.FC<MoreHeaderIconProps> = ({ fill, ...rest }) => {
  return (
    <svg
      width="5"
      height="18"
      viewBox="0 0 5 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 4.5C3.4875 4.5 4.5 3.4875 4.5 2.25C4.5 1.0125 3.4875 0 2.25 0C1.0125 0 0 1.0125 0 2.25C0 3.4875 1.0125 4.5 2.25 4.5ZM2.25 6.75C1.0125 6.75 0 7.7625 0 9C0 10.2375 1.0125 11.25 2.25 11.25C3.4875 11.25 4.5 10.2375 4.5 9C4.5 7.7625 3.4875 6.75 2.25 6.75ZM0 15.75C0 14.5125 1.0125 13.5 2.25 13.5C3.4875 13.5 4.5 14.5125 4.5 15.75C4.5 16.9875 3.4875 18 2.25 18C1.0125 18 0 16.9875 0 15.75Z"
        fill={fill || 'url(#paint0_linear_156_3592)'}
      />
      <defs>
        <linearGradient
          id="paint0_linear_156_3592"
          x1="-7.487e-09"
          y1="18"
          x2="4.57983"
          y2="17.9797"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A761F5" />
          <stop offset="1" stopColor="#50A8FA" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default MoreHeaderIcon;
