import { TFunction } from 'i18next';
import { DEGREE_TYPE, User } from 'models/User';

const formatUserDisplayName = (user: Partial<User>, noNameText = '-') => {
  const { firstName, lastName, businessName, role } = user || {};

  const isBusiness = role === 'Business';

  if (isBusiness) return businessName || noNameText;

  if (firstName && lastName)
    return firstName + ' ' + lastName[0].toUpperCase() + '.';

  return noNameText;
};

const formatUserFirstLastName = (user: Partial<User>, noNameText = '-') => {
  const { firstName, lastName, businessName, role } = user || {};

  const isBusiness = role === 'Business';

  if (isBusiness) return businessName || noNameText;

  if (firstName && lastName) return firstName + ' ' + lastName;

  return noNameText;
};

const getDegreeTypeOptions = (t: TFunction) =>
  Object.values(DEGREE_TYPE).map((degree) => ({
    value: degree,
    label: t(`General.${degree.toLowerCase()}`),
  }));

export { formatUserDisplayName, formatUserFirstLastName, getDegreeTypeOptions };
