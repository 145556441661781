import React from 'react';
import classNames from 'classnames';

import './StickyBottomContent.styles.scss';
import './StickyBottomContent.styles.responsive.scss';

type StickyBottomContentProps = {
  className?: string;
};

const StickyBottomContent: React.FC<
  React.PropsWithChildren<StickyBottomContentProps>
> = (props) => {
  const { className, children } = props;

  const classes = classNames('anys-sticky-bottom-content', className);

  return (
    <div className={classes}>
      {children}

      {/* Do not remove. 
      This element fixes position sticky problem
      on mobile phones */}
      <span className="anys-sticky-bottom-content__mobile-fix" />
    </div>
  );
};

export default StickyBottomContent;
