import React, { useMemo } from 'react';
import classNames from 'classnames';
import { FormApi, SubmissionErrors } from 'final-form';
import { Button } from 'ncoded-component-library';
import { Form, FormProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import InputField from 'components/InputField';

import './InsertLinkForm.styles.scss';

export type InsertLinkFormValues = {
  link: string;
  displayText: string;
};

type InsertLinkFormProps = {
  className?: string;
  onSubmit: (
    values: InsertLinkFormValues,
    form: FormApi<InsertLinkFormValues>,
  ) => SubmissionErrors | Promise<SubmissionErrors> | void;
  initialValues: Partial<InsertLinkFormValues>;
} & FormProps<InsertLinkFormValues>;

const InsertLinkForm: React.FC<InsertLinkFormProps> = (props) => {
  const { className, initialValues, onSubmit } = props;

  const { t } = useTranslation();

  const classes = classNames('anys-insert-link-form', className);

  const messages = useMemo(
    () => ({
      linkPlaceholder: t('RichTextEditor.linkPlaceholder'),
      linkDisplayText: t('RichTextEditor.linkDisplayText'),
      save: t('General.save'),
    }),
    [t],
  );

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={(formRenderProps) => {
        const { handleSubmit, invalid } = formRenderProps;

        const submitDisabled = invalid;

        return (
          <form className={classes} onSubmit={handleSubmit}>
            <InputField name="link" placeholder={messages.linkPlaceholder} />
            <InputField
              name="displayText"
              placeholder={messages.linkDisplayText}
            />

            <Button type="submit" disabled={submitDisabled} variant="outline">
              {messages.save}
            </Button>
          </form>
        );
      }}
    />
  );
};

export default InsertLinkForm;
