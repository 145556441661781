import { createContext } from 'react';

export type ThemeType = 'dark' | 'light';

type ThemeContextProps = {
  theme: ThemeType;
  setTheme: (theme: ThemeType) => void;
};

export default createContext<Partial<ThemeContextProps>>({});
