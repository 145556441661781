import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import classNames from 'classnames';
import VerifyEmailForm from './components/VerifyEmailForm';
import { useAuth } from '../Auth/Auth.page';
import storageService, { STORAGE_KEYS } from 'services/storageService';
import { EmailToVerify } from 'models/Auth';
import MobileHeaderContext from 'components/MobileHeader/providers/MobileHeader/MobileHeader.context';
import { useTranslation } from 'react-i18next';
import AnyServiceLogoIcon from 'icons/AnyServiceLogo.icon';

import './VerifyEmail.styles.scss';
import './VerifyEmail.styles.responsive.scss';

// 5 minutes in ms
const CODE_EXPIRE_TIME = 300000;

const getEmailToVerify = () => {
  const stored = storageService.getItem<EmailToVerify>(
    STORAGE_KEYS.EMAIL_TO_VERIFY,
    true,
  );

  if (stored) {
    return { storedEmail: stored.email, storedAt: stored.storedAt };
  }

  return {};
};

type VerifyEmailProps = {
  className?: string;
};

const VerifyEmail: React.FC<VerifyEmailProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();

  const {
    verifyEmail,
    isVerifyingEmail,
    resendVerifyCode,
    isSendingVerificationCode,
  } = useAuth();

  const [emailStoredAt, setEmailStoredAt] = useState<Date>(null);

  const classes = classNames('anys-verify-email', className);

  const timeLeftForVerification = useMemo(() => {
    if (!emailStoredAt) return 0;

    const startedAt = +emailStoredAt;
    const expiresAt = startedAt + CODE_EXPIRE_TIME;

    const now = +new Date();

    if (expiresAt <= now) return 0;

    const timeElapsed = expiresAt - now;

    return timeElapsed;
  }, [emailStoredAt]);

  const initialValues = useMemo(
    () => ({
      email: getEmailToVerify().storedEmail,
    }),
    [],
  );

  const clearStoredAt = useCallback(() => setEmailStoredAt(null), []);

  const { setStyleOptions, setContent } = useContext(MobileHeaderContext);

  useEffect(() => {
    setStyleOptions(() => ({
      hasBorder: false,
      isBackButtonTransparent: true,
      showBackButton: true,
    }));
    setContent(
      <h5 className="anys-signup__title">
        {t('VerifyEmail.codePlaceholder')}
      </h5>,
    );
  }, [setContent, setStyleOptions, t]);

  // This is used so we can reset the timer
  // when we resend the verification code.
  useEffect(() => {
    if (!isSendingVerificationCode) {
      const { storedAt } = getEmailToVerify();

      setEmailStoredAt(new Date(storedAt));
    }
  }, [isSendingVerificationCode]);

  return (
    <div className={classes}>
      <AnyServiceLogoIcon className="anys-verify-email__big-logo" />

      <VerifyEmailForm
        initialValues={initialValues}
        onSubmit={verifyEmail}
        inProgress={isVerifyingEmail}
        timeLeftForVerification={timeLeftForVerification}
        onResendVerificationCode={resendVerifyCode}
        isSendingVerificationCode={isSendingVerificationCode}
        clearStoredAt={clearStoredAt}
      />
    </div>
  );
};

export default VerifyEmail;
