import React from 'react';
import classNames from 'classnames';
import TimelineLinePart from './components/TimelineLinePart';

import './Timeline.styles.scss';

export type TimelineItem = {
  title?: string;
  content?: React.ReactNode;
  className?: string;
  isCheckpoint?: boolean;
};

type TimelineProps = {
  className?: string;
  items: TimelineItem[];
};

const Timeline: React.FC<TimelineProps> = (props) => {
  const { className, items } = props;

  const classes = classNames('anys-timeline', className);

  return (
    <div className={classes}>
      {items.map((item, i) => {
        const { title, content, className, isCheckpoint } = item;

        const isFirstItem = i === 0;
        const isLastItem = i === items.length - 1;

        const key = title ? title + i : i;

        return (
          <div
            key={key}
            className={classNames('anys-timeline__step', className)}
          >
            {content && (
              <div className="anys-timeline__step__content"> {content} </div>
            )}
            <TimelineLinePart
              isFirstDotLarge={isCheckpoint}
              firstDotContent={title}
              hasLastDot={isLastItem}
              className={classNames({
                'anys-timeline__step__first-line': isFirstItem,
              })}
            />
          </div>
        );
      })}
    </div>
  );
};

export default Timeline;
