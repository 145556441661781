import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

export default (props: IconProps) => (
  <svg width="14" height="16" viewBox="0 0 14 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.00016 4.6665V6.6665H1.66683C0.93045 6.6665 0.333496 7.26346 0.333496 7.99984V13.9998C0.333496 14.7362 0.93045 15.3332 1.66683 15.3332H12.3335C13.0699 15.3332 13.6668 14.7362 13.6668 13.9998V7.99984C13.6668 7.26346 13.0699 6.6665 12.3335 6.6665H11.0002V4.6665C11.0002 2.45736 9.20928 0.666504 7.00016 0.666504C4.79104 0.666504 3.00016 2.45736 3.00016 4.6665ZM7.00016 1.99984C5.52742 1.99984 4.3335 3.19375 4.3335 4.6665V6.6665H9.66683V4.6665C9.66683 3.19375 8.47291 1.99984 7.00016 1.99984Z"
      fill="#1A0042"
    />
  </svg>
);
