import React, { useCallback } from 'react';
import classNames from 'classnames';
import StickyBottomContent from 'components/StickyBottomContent';
import { Button } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import CustomLink from 'components/CustomLink';
import AnyCoinConversion from 'components/AnyCoinConversion';
import GradientText from 'components/GradientText';
import { convertUnitToSubUnit, formatMoney, unitDivisor } from 'utils/currency';
import { useLocation } from 'react-router-dom';

import './BoostActions.styles.scss';
import './BoostActions.styles.responsive.scss';

type BoostActionsProps = {
  className?: string;
  cancelLink: string;
  totalAnyCoins: number;
  totalCost: number;
  inProgress: boolean;
  hasSendData: boolean;
  onSubmit?: () => void;
};

const BoostActions: React.FC<BoostActionsProps> = (props) => {
  const {
    className,
    cancelLink,
    totalAnyCoins,
    totalCost,
    inProgress,
    hasSendData,
    onSubmit,
  } = props;

  const { t } = useTranslation();
  const location = useLocation();

  const isInbox = location.pathname?.startsWith('/inbox');

  const classes = classNames(
    'anys-boost-actions',
    { 'anys-boost-actions--inbox': isInbox },
    className,
  );

  const formatAnyCoins = useCallback(
    () => `${t('General.total')} ${totalAnyCoins}`,
    [t, totalAnyCoins],
  );

  const formatCost = useCallback(
    () => (
      <>
        <span>=</span>
        <GradientText
          gradientType="pink"
          className="anys-boost-actions__total__cost"
        >
          {formatMoney(t, {
            currency: 'USD',
            amount: convertUnitToSubUnit(totalCost || 0, unitDivisor('USD')),
          })}
        </GradientText>
      </>
    ),
    [t, totalCost],
  );

  return (
    <StickyBottomContent className={classes}>
      <div className="anys-boost-actions__actions">
        <Button type="submit" disabled={inProgress} onClick={onSubmit}>
          {hasSendData ? t('General.send') : t('General.save')}
        </Button>
        <CustomLink to={cancelLink} className="hide-on-mobile">
          {t('General.cancel')}
        </CustomLink>
      </div>
      <AnyCoinConversion
        className="anys-boost-actions__total"
        anyCoinAmount={totalAnyCoins}
        anyCoinCostWithoutSubunits={totalCost}
        formatAnyCoins={formatAnyCoins}
        formatCost={formatCost}
      />
    </StickyBottomContent>
  );
};

export default BoostActions;
