import React, { useState } from 'react';
import UserScheduleContext, {
  UserSchedule as UserScheduleModel,
} from './UserSchedule.context';

const UserSchedule: React.FC<React.PropsWithChildren> = (props) => {
  const { children } = props;

  const [selectedSchedule, setSelectedSchedule] =
    useState<UserScheduleModel>(null);

  return (
    <UserScheduleContext.Provider
      value={{ selectedSchedule, setSelectedSchedule }}
    >
      {children}
    </UserScheduleContext.Provider>
  );
};

export default UserSchedule;
