import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { Job, JobPost } from 'models/Job';
import { Navigate } from 'react-router-dom';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import { useJob } from '../Job/Job.page';
import useJobActions from '../../hooks/useJobActions';
import useJobVersion from '../../hooks/useJobVersion';
import JobPostPreview from 'components/JobPostPreview';
import JobPreviewActions from './JobPreviewActions';
import JobSummaryPayModal from './JobSummaryPayModal';
import UserScheduleContext from 'components/JobPostPreview/components/UserScheduleModal/UserScheduleProvider/UserSchedule.context';
import { parseJobToJobPostFormValues } from '../Job/utils';
import confirm from 'modules/confirm';
import { useTranslation } from 'react-i18next';
import useCallbackRef from 'hooks/useCallbackRef';
import { ModalRef } from 'components/Modal';
import { isPendingChanges } from 'utils/job-changes';

const ViewJob: React.FC = () => {
  const { currentUser } = useContext(CurrentUserContext);
  const { selectedSchedule, setSelectedSchedule } =
    useContext(UserScheduleContext);

  const { job, fetchingJob, updateJob, setJob } = useJob();

  const { t } = useTranslation();

  const [jobSummaryModalRef, jobSummaryModalSetRef] =
    useCallbackRef<ModalRef>(null);

  const {
    commonId,
    client,
    provider,
    isSignedByClient,
    isSignedByProvider,
    version,
    prevVersion,
    versionSubmitedBy,
    versionState,
    type,
    timeAndPricing,
    freeCancelation,
  } = useMemo((): Partial<Job> => job || {}, [job]);

  const { importAvailability } = timeAndPricing || {};

  const isUserOfferParticipant =
    client?.id === currentUser?.id || provider?.id === currentUser?.id;

  const isCurrentUserClient = currentUser?.id === client?.id;

  const isProviderChangePending =
    versionSubmitedBy === 'Provider' && isPendingChanges(versionState);
  const isClientChangePending =
    versionSubmitedBy === 'Client' && isPendingChanges(versionState);

  const canAcceptVersion = isCurrentUserClient
    ? isProviderChangePending && !isSignedByClient
    : isClientChangePending && !isSignedByProvider;

  const canRejectVersion = isCurrentUserClient
    ? isProviderChangePending && isSignedByClient
    : isClientChangePending && isSignedByProvider;

  const isOwnJob =
    type === 'Provide'
      ? currentUser?.id === provider?.id
      : currentUser?.id === client?.id;

  const isHourlyPricing = timeAndPricing?.type === 'Hourly';

  const hasNoJobDates = importAvailability
    ? !selectedSchedule
    : (isHourlyPricing || !!freeCancelation) && !timeAndPricing?.startDate;

  const hasNoJobPricing = !(timeAndPricing?.price > 0);

  const isSignDisabled = (!isOwnJob && hasNoJobDates) || hasNoJobPricing;

  const { toggleSign, signing } = useJobActions({
    jobCommonId: commonId,
    jobVersion: version,
    updateJob: (newJob: Job | JobPost) => {
      setJob((oldJob) => ({
        ...oldJob,
        isSignedByClient: (newJob as Job).isSignedByClient,
        isSignedByProvider: (newJob as Job).isSignedByProvider,
      }));
    },
  });

  const { acceptVersion, rejectVersion, acceptingOrRejecting } = useJobVersion({
    updateJob: (job) => setJob(job),
  });

  const onSign = useCallback(
    async (signed: boolean) => {
      if (prevVersion) {
        if (canAcceptVersion) await acceptVersion(commonId, version);
        else if (canRejectVersion) await rejectVersion(commonId, version);
      }

      if (isOwnJob || !selectedSchedule) {
        await toggleSign(signed);
      } else {
        const jobPostAsFormValues = parseJobToJobPostFormValues(job);

        jobPostAsFormValues.timeAndPricing = {
          ...jobPostAsFormValues.timeAndPricing,
          startDate: selectedSchedule.startDate,
          endDate: selectedSchedule.endDate,
          importAvailability: false,
        };

        await updateJob({
          ...jobPostAsFormValues,
          isSigned: true,
        });
      }
    },
    [
      prevVersion,
      isOwnJob,
      selectedSchedule,
      canAcceptVersion,
      acceptVersion,
      commonId,
      version,
      canRejectVersion,
      rejectVersion,
      toggleSign,
      job,
      updateJob,
    ],
  );

  const signProps = useMemo(
    () => ({
      handleSign: onSign,

      hasClientSigned: isSignedByClient,
      hasProviderSigned: isSignedByProvider,
      jobCommonId: commonId,
      isCurrentUserClient,
      inProgress: signing || acceptingOrRejecting,
    }),
    [
      acceptingOrRejecting,
      commonId,
      isCurrentUserClient,
      isSignedByClient,
      isSignedByProvider,
      signing,
      onSign,
    ],
  );

  const onRejectChanges = useCallback(async () => {
    if (prevVersion) await rejectVersion(commonId, version);
  }, [commonId, prevVersion, rejectVersion, version]);

  useEffect(() => {
    setSelectedSchedule(null);
  }, [setSelectedSchedule, commonId]);

  const handleSignPrompt = useCallback(async () => {
    if (isSignDisabled) {
      await confirm({
        title: t('General.selectScheduleModalTitle'),
        description: (
          <>
            {hasNoJobDates && t('General.selectScheduleModalInfo')}

            {hasNoJobDates && hasNoJobPricing && <br />}

            {hasNoJobPricing && t('General.selectScheduleModalPrice')}
          </>
        ),
        cancelContent: null,
      });

      return;
    }

    if (
      !(await confirm({
        title: t('General.actionConfirmQuestion', {
          action: 'Sign',
        }),
        confirmContent: t('General.yes'),
      }))
    ) {
      return;
    }

    jobSummaryModalRef.open();
  }, [hasNoJobDates, hasNoJobPricing, isSignDisabled, jobSummaryModalRef, t]);

  if (job && currentUser && !isUserOfferParticipant) {
    return <Navigate to="/" replace />;
  }

  return (
    <JobPostPreview
      job={job}
      isFetching={fetchingJob}
      client={client}
      provider={provider}
      actionInProgress={signing}
      isOwnJob={isOwnJob}
      {...signProps}
      handleSign={handleSignPrompt}
    >
      <JobPreviewActions
        {...signProps}
        job={job}
        rejectChanges={onRejectChanges}
        isProvideJob={type === 'Provide'}
        isOwnJob={isOwnJob}
        onSign={handleSignPrompt}
      />

      <JobSummaryPayModal
        summaryType={isCurrentUserClient ? 'client' : 'provider'}
        onClose={() => jobSummaryModalRef.close()}
        handleSign={signProps.handleSign}
        isSigning={signProps.inProgress}
        jobSummaryModalRef={jobSummaryModalSetRef}
      />
    </JobPostPreview>
  );
};

export default ViewJob;
