import React, { useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { Picture } from 'models/User';
import { useTranslation } from 'react-i18next';
import ResponsiveImage from 'components/ResponsiveImage';
import { ModalRef } from 'components/Modal';
import ImagesPreviewModal from 'components/ImagesPreviewModal';
import JobState from 'components/JobState';
import { JobState as JobStateModel } from 'models/Job';

import './ImagesPreview.styles.scss';

type ImagesPreviewProps = {
  className?: string;
  images: Picture[];
  shownImages?: number;
  jobState: JobStateModel;
  jobTitle: string;
};

const ImagesPreview: React.FC<ImagesPreviewProps> = (props) => {
  const {
    className,
    images: allImages,
    shownImages = 3,
    jobState,
    jobTitle,
  } = props;

  const { t } = useTranslation();

  const modalRef = useRef<ModalRef>();

  const [activeImageIndex, setActiveImageIndex] = useState<number>();

  const classes = classNames('anys-images-preview', className);

  const messages = useMemo(
    () => ({
      imageAttachments: t('General.imageAttachments'),
      noImages: t('General.noImages'),
    }),
    [t],
  );

  const images = useMemo(() => {
    if (!shownImages) return allImages;

    return allImages.slice(0, Math.abs(shownImages));
  }, [allImages, shownImages]);

  const moreImagesNumber =
    shownImages > 0 && allImages?.length
      ? allImages.length - shownImages
      : null;

  const modalTitle = useMemo(
    () => (
      <div className="anys-images-preview__modal-title">
        {jobTitle}
        <JobState state={jobState} />
      </div>
    ),
    [jobState, jobTitle],
  );

  return (
    <div className={classes}>
      {images.length ? (
        <ul>
          {images.map((img, i) => {
            const { id, name, resizedUrls, url } = img;

            const isLastImage = i === images.length - 1;

            return (
              <li key={id}>
                <button
                  type="button"
                  onClick={() => {
                    modalRef.current.open();

                    setActiveImageIndex(i);
                  }}
                >
                  <ResponsiveImage src={resizedUrls?.[320] || url} alt={name} />
                  {isLastImage && moreImagesNumber > 0 && (
                    <div className="anys-images-preview__overlay">{`+${moreImagesNumber}`}</div>
                  )}
                </button>
              </li>
            );
          })}
        </ul>
      ) : (
        messages.noImages
      )}

      <ImagesPreviewModal
        modalRef={modalRef}
        files={allImages}
        activeIndex={activeImageIndex}
        title={modalTitle}
        description={
          <span className="anys-images-preview__modal-desc">
            {messages.imageAttachments}
          </span>
        }
      />
    </div>
  );
};

export default ImagesPreview;
