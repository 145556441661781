import React from 'react';
import classNames from 'classnames';
import { Outlet } from 'react-router-dom';
import NavBar from 'components/NavBar';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './StaticPages.styles.scss';
import './StaticPages.styles.responsive.scss';

type StaticPagesProps = {
  className?: string;
};
const StaticPages = (props: StaticPagesProps) => {
  const { className } = props;
  const classes = classNames('anys-static-pages', className);

  const {t} = useTranslation();
  return (
    <div className={classes}>
      <NavBar />
      <Outlet />
      <footer className="anys-static-pages__footer">
        <span>{t("TermsAndConditions.anyServiceInc")}</span>
        <Link to={'faq'}>{t("FrequentQuestions.faq")}</Link>
        <Link to={'terms-and-conditions'}>{t("TermsAndConditions.termsAndConditions")}</Link>
      </footer>
    </div>
  );
};

export default StaticPages;
