import { useCallback, useEffect, useRef } from 'react';
import useInfinitePagination from 'hooks/useInfinitePagination';
import useQueryParams from 'hooks/useQueryParams';
import useMatchMedia from 'hooks/useMatchMedia';
import { PaginationParams } from 'models/Pagination';
import api from 'api';
import { useTranslation } from 'react-i18next';
import OverlaySpinner from 'components/OverlaySpinner';
import { ProfileOffer } from 'models/Job';
import showToast from 'modules/showToast';
import { AxiosError } from 'axios';

export default function useGetOffers(id: number) {
  const isPhablet = useMatchMedia('isPhablet');

  const { t } = useTranslation();
  const {
    params: { page },
    setQueryParam,
  } = useQueryParams<{ page: string }>();

  const hasReachedLastPage = useRef(false);

  const getOffers = useCallback(
    async (currentPage: number) => {
      try {
        if (isPhablet) OverlaySpinner.show('.anys-offers-page__offers');
        if (id) {
          const queryParams: PaginationParams = {
            $page: currentPage,
            $relations: 'jobPost.timeAndPricing',
            $take: 6,
            $order: '-id',
          };
          const {
            data: { items, totalItems, totalPages },
          } = await api.user.getDisplayedOffers(id, queryParams);

          return { items, totalItems, totalPages } as const;
        }

        return { items: [], totalItems: 0, totalPages: 0 };
      } catch (e) {
        console.error(e);
        const err = e as AxiosError;

        showToast(
          'error',
          t('General.error'),
          err?.response?.data?.error?.message,
        );
      } finally {
        if (!isPhablet) window.scroll(0, 0);
        else OverlaySpinner.hide('.anys-offers-page__offers');
      }
    },
    [id, isPhablet, t],
  );

  const pagination = useInfinitePagination<ProfileOffer>({
    take: 6,
    makeRequest: getOffers,
    debounceTime: 500,
    page: +page,
  });

  const { totalItems, onContainerScrolled } = pagination;

  const showPaginator = !!totalItems && !isPhablet;

  useEffect(() => {
    hasReachedLastPage.current = false;

    if (!isPhablet)
      window.scroll({
        top: 0,
        left: 0,
      });
  }, [isPhablet, setQueryParam]);

  return {
    ...pagination,
    showPaginator,
    isPhablet,
    onContainerScrolled,
  };
}
