import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import ForgotPasswordForm from './components/ForgotPasswordForm';
import { useAuth } from '../Auth/Auth.page';
import Modal, { ModalRef } from 'components/Modal';
import { ForgotPasswordReqBody } from 'models/Auth';
import { Trans, useTranslation } from 'react-i18next';
import Mail from 'icons/Mail.icon';
import MobileHeaderContext from 'components/MobileHeader/providers/MobileHeader/MobileHeader.context';
import AnyServiceLogoIcon from 'icons/AnyServiceLogo.icon';
import { useNavigate } from 'react-router-dom';
import { Button } from 'ncoded-component-library';

import './ForgotPassword.styles.scss';
import './ForgotPassword.styles.responsive.scss';

type ForgotPasswordProps = {
  className?: string;
};

const ForgotPassword: React.FC<ForgotPasswordProps> = (props) => {
  const { className } = props;

  const { forgotPassword, isForgotPasswordInProgress } = useAuth();

  const { t } = useTranslation();
  const { setStyleOptions, setContent } = useContext(MobileHeaderContext);
  const emailSentModalRef = useRef<ModalRef>();

  const [forgotPasswordEmail, setForgotPasswordEmail] = useState<string>(null);

  const classes = classNames('anys-forgot-password', className);

  const navigate = useNavigate();

  const messages = useMemo(
    () => ({
      title: t('General.forgotPassword'),
      subtitle: t('ForgotPassword.subtitle'),
      emailSentTitle: t('ForgotPassword.emailSentTitle'),
      backToLogin: t('General.backToLogin'),
    }),
    [t],
  );

  const handleEmailSentClose = useCallback(() => {
    setForgotPasswordEmail(null);
  }, []);

  const handleForgotPassword = useCallback(
    async (values: ForgotPasswordReqBody) => {
      try {
        await forgotPassword(values);

        setForgotPasswordEmail(values.email);
        emailSentModalRef.current.open();
        // eslint-disable-next-line no-empty
      } catch (error) {
        // Error handled in parent
      }
    },
    [forgotPassword],
  );
  const handleBack = useCallback(() => {
    navigate('/auth/login', { replace: true });
  }, [navigate]);

  useEffect(() => {
    setStyleOptions({
      isBackButtonTransparent: true,
      hasBorder: false,
      showBackButton: true,
    });
    setContent(<AnyServiceLogoIcon className="anys-forgot-password__logo" />);
  }, [setContent, setStyleOptions]);

  return (
    <div className={classes}>
      <AnyServiceLogoIcon className="anys-forgot-password__big-logo" />
      <div className="anys-forgot-password__title">
        <h3>{messages.title}</h3>
        <span>{messages.subtitle}</span>
      </div>
      <ForgotPasswordForm
        onSubmit={handleForgotPassword}
        inProgress={isForgotPasswordInProgress}
      />
      <Button
        type="button"
        className="anys-forgot-password__back-button"
        variant="link"
        onClick={handleBack}
      >
        <span>{messages.backToLogin}</span>
      </Button>

      <Modal
        modalName="email-sent"
        type="confirm"
        onClose={handleEmailSentClose}
        isDark
        hideHeader
        ref={emailSentModalRef}
      >
        <h3 className="anys-forgot-password__modal-title">
          <Mail />
          {messages.emailSentTitle}
        </h3>
        <div>
          <Trans
            i18nKey="ForgotPassword.emailSentContent"
            t={t}
            values={{ email: forgotPasswordEmail }}
            components={{
              accented: <span className="anys-forgot-password__modal-email" />,
              newLine: <br />,
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default ForgotPassword;
