import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import InputField from 'components/InputField';
import { SubmissionErrors } from 'final-form';
import { VerifyEmailReqBody } from 'models/Auth';
import { Button } from 'ncoded-component-library';
import { Form, FormProps } from 'react-final-form';
import formValidators from 'utils/formValidators';
import { useTranslation } from 'react-i18next';
import CountdownTimer from 'components/CountdownTimer';
import { useNavigate } from 'react-router-dom';

import './VerifyEmailForm.styles.scss';
import './VerifyEmailForm.styles.responsive.scss';

const { required } = formValidators;

type VerifyEmailFormProps = {
  className?: string;
  onSubmit: (
    values: VerifyEmailReqBody,
  ) => SubmissionErrors | Promise<SubmissionErrors> | void;
  onResendVerificationCode: () => void;
  clearStoredAt: () => void;
  initialValues: Partial<VerifyEmailReqBody>;
  inProgress: boolean;
  timeLeftForVerification: number;
  isSendingVerificationCode: boolean;
} & FormProps<VerifyEmailReqBody>;

const VerifyEmailForm: React.FC<VerifyEmailFormProps> = (props) => {
  const {
    className,
    initialValues,
    onSubmit,
    onResendVerificationCode,
    inProgress,
    timeLeftForVerification,
    isSendingVerificationCode,
    clearStoredAt,
  } = props;

  const { t } = useTranslation();

  const [isCodeExpired, setIsCodeExpired] = useState(false);

  const classes = classNames('anys-verify-email-form', className);
  const navigate = useNavigate();

  const messages = useMemo(
    () => ({
      title: t('VerifyEmail.emailVerification'),
      subtitle: t('VerifyEmail.pharagraph'),
      codeLabel: t('VerifyEmail.codeLabel'),
      codePlaceholder: t('VerifyEmail.codePlaceholder'),
      codeDescription: t('VerifyEmail.codeDescription'),
      codeRequired: t('VerifyEmail.codeRequired'),

      resendCode: t('VerifyEmail.resendCode'),
      resendCodeError: t('VerifyEmail.resendCodeError'),
      createAccount: t('General.createAccount'),
      error: t('VerifyEmail.error'),
      backToLogin: t('General.backToLogin'),
    }),
    [t],
  );

  const isResendCodeDisabled = useMemo(
    () =>
      !isCodeExpired ||
      timeLeftForVerification > 0 ||
      !initialValues.email ||
      isSendingVerificationCode,
    [
      initialValues.email,
      isCodeExpired,
      isSendingVerificationCode,
      timeLeftForVerification,
    ],
  );

  const handleTimerFinished = useCallback(() => {
    setIsCodeExpired(true);
    clearStoredAt();
  }, [clearStoredAt]);

  const inputDescription = useMemo(() => {
    return (
      <span className="anys-verify-email-form__below-input">
        <CountdownTimer
          startTimeInMS={timeLeftForVerification}
          onTimerFinished={handleTimerFinished}
        />

        <Button
          type="button"
          onClick={onResendVerificationCode}
          variant="link"
          disabled={isResendCodeDisabled}
        >
          <span> {messages.resendCode}</span>
        </Button>
      </span>
    );
  }, [
    handleTimerFinished,
    messages.resendCode,
    onResendVerificationCode,
    isResendCodeDisabled,
    timeLeftForVerification,
  ]);

  const handleBack = useCallback(() => {
    navigate('/auth/login', { replace: true });
  }, [navigate]);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={(formRenderProps) => {
        const { handleSubmit, invalid, values } = formRenderProps;

        const submitDisabled = invalid || inProgress || !values.email;

        return (
          <form className={classes} onSubmit={handleSubmit}>
            <h3 className="anys-verify-email-form__title">{messages.title}</h3>
            <p className="anys-verify-email-form__subtitle">
              {messages.subtitle}
            </p>
            <label className="anys-verify-email-form__input-label">
              {messages.codePlaceholder}
            </label>
            <InputField
              type="number"
              name="verificationKey"
              isLargeLabel
              label={messages.codeLabel}
              labelDescription={messages.codeDescription}
              placeholder={messages.codePlaceholder}
              validate={required(messages.codeRequired)}
              inputDescription={inputDescription}
              isPureNumberInput
              disabled={!values.email}
            />

            <Button type="submit" disabled={submitDisabled}>
              {messages.createAccount}
            </Button>
            <Button
              type="button"
              className="anys-reset-password-form__back-button"
              variant="link"
              onClick={handleBack}
            >
              <span>{messages.backToLogin}</span>
            </Button>
          </form>
        );
      }}
    />
  );
};

export default VerifyEmailForm;
