import React, { Ref, useCallback, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import Modal, { ModalRef } from 'components/Modal';
import { useTranslation } from 'react-i18next';
import { UseFiltersFormReturn } from 'hooks/useFiltersForm';
import { Loader } from 'ncoded-component-library';
import { Form, FormRenderProps } from 'react-final-form';
import FormButtons from 'components/FormButtons';
import { FormApi, ValidationErrors } from 'final-form';
import useMatchMedia from 'hooks/useMatchMedia';
import GradientText from 'components/GradientText';

import './FiltersForm.styles.scss';
import './FiltersForm.styles.responsive.scss';

type FiltersFormProps<FormValue extends Record<string, any>> = {
  className?: string;
  modalRef: Ref<ModalRef>;
  render?: (
    props: FormRenderProps<FormValue, Partial<FormValue>>,
  ) => React.ReactNode;
  modalName?: string;
  defaultValues?: FormValue;
  formApi?: React.MutableRefObject<FormApi<FormValue>>;
  keepOpenOnRefresh?: boolean;
  isModal?: boolean;
  closeModal: () => void;
  validate?: (
    values: FormValue,
  ) => ValidationErrors | Promise<ValidationErrors>;
} & UseFiltersFormReturn<FormValue> &
  Pick<
    React.ComponentPropsWithoutRef<typeof FormButtons>,
    'onSave' | 'saveBtnProps'
  >;

function FiltersForm<FormValue extends Record<string, any>>(
  props: FiltersFormProps<FormValue>,
) {
  const {
    render,
    loading,
    defaultValues,
    initialValues,
    onSubmit,
    className,
    modalName = 'user-filters',
    formApi: propFormApi,
    keepOpenOnRefresh,
    saveBtnProps,
    isModal = true,
    modalRef,
    onSave,
    closeModal,
    validate,
  } = props;
  const [isReset, setIsReset] = useState(false);
  const formApi = useRef<FormApi<FormValue>>();

  const isPhablet = useMatchMedia('isPhablet');

  const { t } = useTranslation();
  const classes = classNames('anys-filters-form', className);

  const onClear = useCallback(() => {
    if (!formApi.current) return;

    const { initialValues } = formApi.current.getState();

    if (initialValues !== defaultValues) setIsReset(true);
    formApi.current.reset(defaultValues);
  }, [defaultValues]);

  const content = useMemo(
    () =>
      loading ? (
        <Loader />
      ) : (
        <Form<FormValue>
          onSubmit={onSubmit}
          initialValues={initialValues}
          validate={validate}
          render={(formRenderProps) => {
            const { handleSubmit, submitting, dirty, form } = formRenderProps;

            formApi.current = form;

            if (propFormApi) propFormApi.current = form;

            return (
              <form onSubmit={handleSubmit}>
                <div className="anys-filters-form__content">
                  {render(formRenderProps)}
                </div>
                {isModal && (
                  <FormButtons
                    loading={loading}
                    disabled={submitting || (!dirty && !isReset)}
                    onClear={!isPhablet ? closeModal : onClear}
                    cancelTxt={!isPhablet ? t('General.cancel') : undefined}
                    onSave={onSave}
                    saveBtnProps={saveBtnProps}
                  />
                )}
              </form>
            );
          }}
        />
      ),
    [
      closeModal,
      initialValues,
      isModal,
      isPhablet,
      isReset,
      loading,
      onClear,
      onSave,
      onSubmit,
      propFormApi,
      render,
      saveBtnProps,
      t,
      validate,
    ],
  );

  return isModal ? (
    <Modal
      ref={modalRef}
      isDrawer
      onOpen={() => setIsReset(false)}
      type="no-action"
      modalName={modalName}
      title={
        <div className="anys-filters-form__title">
          {t('Filters.advancedFilters')}

          <button type="button" onClick={onClear}>
            <GradientText>{t('Filters.clear')}</GradientText>
          </button>
        </div>
      }
      className={classes}
      overlayCloses={false}
      keepOpenOnRefresh={keepOpenOnRefresh}
      lockScroll={false}
    >
      {content}
    </Modal>
  ) : (
    <div className={classes}>{content}</div>
  );
}

export default FiltersForm;
