import React, { useMemo, useState } from 'react';
import classNames from 'classnames';

import './ResponsiveImage.styles.scss';

type ResponsiveImageProps = {
  className?: string;
  alt: string;
  // response resizedUrls
  resizedVariants?: Record<string, string>;
} & React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>;

const ResponsiveImage: React.FC<ResponsiveImageProps> = (props) => {
  const { className, alt, resizedVariants, ...rest } = props;

  const [imgOrientation, setImgOrientation] = useState<
    'horizontal' | 'vertical'
  >();

  const classes = classNames(
    'anys-responsive-image',
    { [imgOrientation]: !!imgOrientation },
    className,
  );

  const srcSet = useMemo(
    () =>
      resizedVariants
        ? Object.entries(resizedVariants)
            .map(([imgWidth, imgUrl]) => `${imgUrl} ${imgWidth}w`)
            .join(', ')
        : '',
    [resizedVariants],
  );

  return (
    <img
      className={classes}
      alt={alt}
      srcSet={srcSet}
      onLoad={({ target }) => {
        const { naturalWidth, naturalHeight } = target as HTMLImageElement;
        setImgOrientation(
          naturalWidth > naturalHeight ? 'horizontal' : 'vertical',
        );
      }}
      {...rest}
    />
  );
};

export default ResponsiveImage;
