import React, { useCallback, useContext, useMemo } from 'react';
import classNames from 'classnames';
import ExclamationIcon from 'icons/Exclamation.icon';
import CountdownTimer from 'components/CountdownTimer';
import { Button } from 'ncoded-component-library';
import api from 'api';
import dayjs from 'dayjs';
import { useArbitration } from 'providers/Arbitration/Arbitration.provider';
import OverlaySpinner from 'components/OverlaySpinner';
import { useTranslation } from 'react-i18next';

import './ArbitrationInvite.styles.scss';
import './ArbitrationInvite.styles.responsive.scss';
import CurrentUserContext from '../../../../../../../providers/CurrentUser/CurrentUser.context';

type ArbitrationInviteProps = {
  className?: string;
};
const ArbitrationInvite = (props: ArbitrationInviteProps) => {
  const { className } = props;
  const classes = classNames('anys-arbitration-invite', className);
  const {
    selectedArbitration: { contract, lastTransitionedAt, id, invites },
    setSelectedArbitration,
  } = useArbitration();
  const { currentUser } = useContext(CurrentUserContext);

  const isCurrentUserAcceptedInvitation = invites.some(
    (invite) =>
      invite.state === 'accepted' && invite?.invitee?.id === currentUser.id,
  );

  const { t } = useTranslation();

  const timeLeftForVerification = useMemo(() => {
    const date1 = dayjs(new Date());
    const date2 = dayjs(lastTransitionedAt);

    //TODO: limit based on type - 24h specific, 1h random
    //TODO: replace this hours
    const newLimitDate = date2.add(24, 'minutes');

    return newLimitDate.diff(date1, 'milliseconds');
  }, [lastTransitionedAt]);

  const handleAcceptArbitration = useCallback(async () => {
    try {
      OverlaySpinner.show('.anys-arbitration-page__main');

      const { data } = await api.arbitration.acceptArbitration(id);

      if (data.state === 'accepted') {
        const { data: arbitration } = await api.arbitration.getArbitration(id);
        if (arbitration) {
          setSelectedArbitration(arbitration);
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      OverlaySpinner.hide('.anys-arbitration-page__main');
    }
  }, [id, setSelectedArbitration]);

  const handleDeclineArbitration = useCallback(async () => {
    try {
      OverlaySpinner.show('.anys-arbitration-page__main');

      await api.arbitration.declineArbitration(id);
    } catch (e) {
      console.error(e);
    } finally {
      OverlaySpinner.hide('.anys-arbitration-page__main');
    }
  }, [id]);

  return (
    <div className={classes}>
      <div className="anys-arbitration-invite__info">
        <ExclamationIcon />
        <h1>
          {t('Arbitration.arbitrationForContract')} <br /> #{contract.id}
        </h1>

        <span className="anys-pending-funds__subtitle">
          {!isCurrentUserAcceptedInvitation
            ? t('Arbitration.arbitratorInvitation')
            : t('Arbitration.accepted')}
        </span>

        {!isCurrentUserAcceptedInvitation ? (
          <div className="anys-pending-funds__timer">
            <CountdownTimer startTimeInMS={timeLeftForVerification} />
          </div>
        ) : null}
      </div>
      {!isCurrentUserAcceptedInvitation ? (
        <div className="anys-arbitration-invite__actions">
          <Button
            onClick={handleDeclineArbitration}
            className="anys-pending-funds__upload-btn"
            variant="outline"
          >
            {t('General.decline')}
          </Button>
          <Button
            onClick={handleAcceptArbitration}
            className="anys-pending-funds__upload-btn"
          >
            {t('General.accept')}
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default ArbitrationInvite;
