import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { Skill } from 'models/Skills';
import useFiltersForm from 'hooks/useFiltersForm';
import type { ModalRef } from 'components/Modal';
import { useTranslation } from 'react-i18next';
import PriceRangeField from './components/PriceRangeField';
import { PriceRangeType } from 'api/favouriteFilters';

import RadioGroupField from 'components/RadioGroupField';
import useSelectOptions from 'hooks/useSelectOptions';
import FiltersFormComponent from 'components/FiltersForm/FiltersForm.component';
import CurrencyInputField from 'components/CurrencyInputField';
import { Money } from 'constants/currency';

import DateTimeField from 'components/DateTimeField';
import useTranslationMessages from './hooks/useTranslationMessages';
import { LocationFields } from 'router/subrouters/JobPost/pages/CreateEditJobPost/components/TypeOfService/TypeOfService.component';
import { SkillsField } from 'router/subrouters/Search/pages/Search/components/FiltersModal/FiltersModal.component';

import { Field } from 'react-final-form';
import MultipleSelectField from 'components/MultipleSelectField';
import InputField from 'components/InputField';
import Plus from 'icons/Plus.icon';
import useValidations from './hooks/useValidations';
import { FormJobTypeOfService } from 'models/Job/TypeOfService';
import { FilterReqValue } from 'utils/filtersForm';
import { FormApi } from 'final-form';
import { JobPricingType } from 'models/Job/TimeAndPricing';
import { NumberRange } from 'types';
import {
  convertSubUnitToUnit,
  convertUnitToSubUnit,
  unitDivisor,
} from 'utils/currency';
import { JOB_SERVICE_TYPES, JOB_STATE } from 'constants/job';
import { UserType } from 'constants/filters';
import { INBOX_ITEM_TYPE } from 'models/Inbox';
import dates from 'utils/dates';
import dayjs from 'dayjs';
import { removeSeeAll } from 'utils/filters';
import { PlaceFormat } from 'models/User';
import googleMaps from 'utils/googleMaps';

import './JobFilters.styles.scss';
import './JobFilters.styles.responsive.scss';

const getPriceQuery = (
  from: string,
  to: string,
  pricingType: JobPricingType,
) => {
  const isHourly = pricingType === 'Hourly';

  if (isHourly)
    return {
      'timeAndPricing.price': {
        MORETHANOREQUAL: from ? Number.parseInt(from) : undefined,
        LESSTHANOREQUAL: to ? Number.parseInt(to) : undefined,
      },
    };

  const fromQuery = from
    ? {
        'timeAndPricing.minTotalPrice': {
          MORETHANOREQUAL: Number.parseInt(from),
        },
      }
    : {};

  const toQuery = to
    ? {
        'timeAndPricing.maxTotalPrice': {
          LESSTHANOREQUAL: Number.parseInt(to),
        },
      }
    : {};

  const typeQuery = pricingType
    ? {
        [`timeAndPricing.type`]: { EQUAL: pricingType },
      }
    : {};

  return {
    ...fromQuery,
    ...toQuery,
    ...typeQuery,
  };
};

export const jobFiltersParses: Record<string, (value: string) => any> = {
  'skills[]': Number,
  price: (value: string) => {
    const [min, max, type] = value.split('-');

    return {
      range: {
        from: min ? `${convertSubUnitToUnit(min, unitDivisor('USD'))}` : null,
        to: max ? `${convertSubUnitToUnit(max, unitDivisor('USD'))}` : null,
      },
      type,
    };
  },
  typeOfService: JSON.parse,
  dates: (value: string) => {
    const [start, end] = value.split(',');

    const startDate = start ? new Date(start) : null;
    const endDate = end ? new Date(end) : null;

    return { startDate, endDate };
  },
};

export const jobFiltersTransformations: Partial<
  Record<keyof FormValue, (value: any) => string>
> = {
  skills: (value: number[]) => value.join(','),
  price: (price: PriceNumberRange) => {
    const from = price.range?.from
      ? convertUnitToSubUnit(price.range.from, unitDivisor('USD'))
      : '';
    const to = price.range?.to
      ? convertUnitToSubUnit(price.range.to, unitDivisor('USD'))
      : '';

    return `${from}-${to}-${price.type || ''}`;
  },
  dates: (value: { startDate: string; endDate: string }) => {
    if (!value) return '';

    const { startDate, endDate } = value;

    const start = startDate
      ? dates.formatDate(startDate, 'YYYY-MM-DD HH:mm')
      : '';

    const end = endDate ? dates.formatDate(endDate, 'YYYY-MM-DD HH:mm') : '';

    return `${start},${end}`;
  },
  typeOfService: JSON.stringify,
  negotiable: removeSeeAll,
  sign: removeSeeAll,
  freeCancelation: removeSeeAll,
  userType: removeSeeAll,
};

export type PriceNumberRange = { range: NumberRange; type?: JobPricingType };

export type Sign = 'offer' | 'pre-offer' | 'see-all';

export type YesNo = 'yes' | 'no' | 'see-all';

export type FormValue = {
  skills: number[];
  languages: string[];
  locations: string[];
  freeCancelation: YesNo;
  sign: Sign;
  negotiable: YesNo;
  rating: string;
  dates: { startDate: string; endDate: string };
  numberOfJobs: number;
  userType: UserType | 'see-all';
  price: PriceNumberRange;
  typeOfService: FormJobTypeOfService;
};

const defaultValues: FormValue = {
  skills: [],
  languages: [],
  locations: null,
  freeCancelation: 'see-all',
  sign: 'see-all',
  negotiable: 'see-all',
  numberOfJobs: 0,
  dates: null,
  rating: null,
  userType: 'see-all',
  price: null,
  typeOfService: null,
};

export const jobFiltersDbNames: Record<string, FilterReqValue> = {
  'skills[]': {
    dbName: 'skills.id',
    type: 'array',
  },
  'languages[]': {
    dbName: 'otherUser.languages.language',
    type: 'array',
    queryType: 'or',
  },
  price: {
    mapperFn: (price: string) => {
      const [from, to, type] = price.split('-');

      const pricingType = type as JobPricingType;

      return getPriceQuery(from, to, pricingType);
    },
  },
  dates: {
    mapperFn: (value) => {
      const [start, end] = value.split(',');

      const startQuery = start
        ? {
            'timeAndPricing.startDate': { MORETHANOREQUAL: start },
          }
        : {};

      const endQuery = end
        ? {
            'timeAndPricing.endDate': { LESSTHANOREQUAL: end },
          }
        : {};

      return { ...startQuery, ...endQuery };
    },
  },

  typeOfService: {
    mapperFn: (value: string) => {
      const typeOfService = JSON.parse(
        value,
      ) as FormJobTypeOfService<PlaceFormat>;

      const { type, locationType, startLocation, endLocation, area } =
        typeOfService;

      if (type === JOB_SERVICE_TYPES.ONLINE) {
        return {
          'typeOfService.type': { EQUAL: JOB_SERVICE_TYPES.ONLINE },
        };
      }

      let query: Partial<Record<string, any>> = {};

      switch (locationType) {
        case 'One spot': {
          if (!startLocation) break;

          const { bounds } = startLocation;

          if (!bounds) break;

          query = {
            'typeOfService.coordinates': {
              BOUNDS: [
                bounds.ne.lat,
                bounds.ne.lng,
                bounds.sw.lat,
                bounds.sw.lng,
              ],
            },
          };

          break;
        }

        case 'Area': {
          if (!startLocation || !area) break;

          const bounds = googleMaps.locationBounds(
            { lat: startLocation.lat, lng: startLocation.lng },
            area,
          );

          if (!bounds) break;

          query = {
            'typeOfService.coordinates': {
              BOUNDS: [
                bounds.ne.lat,
                bounds.ne.lng,
                bounds.sw.lat,
                bounds.sw.lng,
              ],
            },
          };

          break;
        }

        case 'Direction': {
          if (!startLocation && !endLocation) break;

          const { bounds: startBounds } = startLocation || {};
          const { bounds: endBounds } = endLocation || {};

          if (!startBounds && !endBounds) break;

          const startCoords = startBounds
            ? [
                startBounds.ne.lat,
                startBounds.ne.lng,
                startBounds.sw.lat,
                startBounds.sw.lng,
              ]
            : [];
          const endCoords = endBounds
            ? [
                endBounds.ne.lat,
                endBounds.ne.lng,
                endBounds.sw.lat,
                endBounds.sw.lng,
              ]
            : [];

          // If we have startBounds then our direction is left-to-right (lr);
          const direction = startBounds ? 'lr' : 'rl';

          query = {
            'typeOfService.coordinates': {
              BOUNDS: [direction, ...startCoords, ...endCoords],
            },
          };

          break;
        }
        default:
          break;
      }

      return {
        'typeOfService.locationType': {
          EQUAL: locationType,
        },
        ...query,
      };
    },
  },
  freeCancelation: {
    mapperFn: (freeCancelation) => {
      switch (freeCancelation as YesNo) {
        case 'yes':
          return {
            hasFreeCancelation: { EQUAL: true },
          };

        case 'no':
          return {
            hasFreeCancelation: { EQUAL: false },
          };

        default:
          return {};
      }
    },
  },
  sign: {
    mapperFn: (sign) => {
      switch (sign as Sign) {
        case 'pre-offer':
          return {
            state: { '!EQUAL': JOB_STATE.DRAFT },
            type: { '!EQUAL': INBOX_ITEM_TYPE.CONTRACT },
            numberOfSigns: { EQUAL: 0 },
          };
        case 'offer':
          return {
            type: { '!EQUAL': INBOX_ITEM_TYPE.CONTRACT },
            numberOfSigns: { EQUAL: 1 },
          };
        default:
          return {};
      }
    },
  },
  negotiable: {
    mapperFn: (value) => {
      const negotiation = value as YesNo;

      switch (negotiation) {
        case 'yes':
          return { isNegotiable: { EQUAL: true } };
        case 'no':
          return { isNegotiable: { EQUAL: false } };

        default:
          return {};
      }
    },
  },
  userType: {
    mapperFn: (value) => {
      if (value === 'see-all') return {};

      return {
        'otherUser.role': {
          EQUAL: value,
        },
      };
    },
  },
  rating: {
    mapperFn: (value) => {
      const isNeedPage =
        typeof window !== 'undefined' &&
        window.location.pathname.startsWith('/need');

      return {
        [`otherUser.${
          isNeedPage ? 'overallClientScore' : 'overallSkillScore'
        }.averageRating`]: {
          MORETHANOREQUAL: Number.parseInt(value),
        },
      };
    },
  },
  numberOfJobs: {
    dbName: 'otherUser.jobSuccess.numberOfJobs',
    type: 'morethan',
  },
};

type JobFiltersProps = {
  className?: string;
  skills: Skill[];
  priceRange: PriceRangeType;
  modalName: string;
  keepOpenOnRefresh?: boolean;
  formApi?: React.MutableRefObject<FormApi<FormValue>>;
  dbNames?: Record<string, FilterReqValue>;
  parses?: Record<string, (value: string) => any>;
  transformations?: Record<string, (value: any) => string>;
  isModal?: boolean;
  loading?: boolean;
  closeModal: () => void;
  onApply?: () => void;
  onBrowse?: () => void;
} & Pick<
  React.ComponentPropsWithoutRef<typeof FiltersFormComponent>,
  'onSave' | 'saveBtnProps'
>;

const valueMap = (money: Money, type: 'change' | 'focus' | 'blur') => {
  if (type === 'blur') return money;

  return money ? (money.amount / 100).toString() : '';
};

const JobFilters: React.ForwardRefRenderFunction<ModalRef, JobFiltersProps> = (
  props,
  ref,
) => {
  const {
    className,
    priceRange,
    skills,
    modalName,
    formApi,
    keepOpenOnRefresh,
    parses: propParses,
    dbNames: propDbNames,
    transformations: propTransformations,
    saveBtnProps,
    isModal = true,
    loading: propLoading,
    closeModal,
    onBrowse,
    onApply,
    onSave,
  } = props;

  const { t } = useTranslation();
  const classes = classNames('anys-job-filters', className);

  const dbNames = useMemo(
    () => ({ ...jobFiltersDbNames, ...propDbNames }),
    [propDbNames],
  );

  const parses = useMemo(
    () => ({ ...jobFiltersParses, ...propParses }),
    [propParses],
  );

  const transformations = useMemo(
    () => ({ ...jobFiltersTransformations, ...propTransformations }),
    [propTransformations],
  );

  const {
    loading: formLoading,
    initialValues,
    onSubmit,
  } = useFiltersForm({
    dbNames,
    defaultValues,
    parses,
    transformations,
    onApply,
  });

  const loading =
    typeof propLoading !== 'undefined'
      ? propLoading
      : !priceRange || formLoading;

  const {
    priceTypes,
    signOptions,
    userTypeOptions,
    freeCancellation,
    ratingOptions,
    languageOptions,
    negotiationOptions,
    minNumberOfJobs,
  } = useSelectOptions();

  const messages = useTranslationMessages();

  const validations = useValidations(priceRange);

  const validateForm = useCallback(
    (values: Record<string, any>) => {
      const { price, dates: formDates } = values as FormValue;

      const errors: Partial<Record<keyof FormValue, any>> = {
        dates: null,
        price: null,
      };

      if (formDates) {
        const { startDate, endDate } = formDates;

        if (startDate && startDate > endDate)
          errors.dates = {
            endDate: t('General.dateAfterError', {
              limit: dates.formatDate(startDate, 'DD/MM/YYYY, hh:mm a'),
            }),
          };
      }

      if (price) {
        const { range, type } = price;

        if (type && !range?.from && !range?.to) {
          errors.price = {
            range: {
              from: t('General.valueRequired'),
              to: t('General.valueRequired'),
            },
          };
        }
      }

      return errors;
    },
    [t],
  );

  return (
    <FiltersFormComponent
      className={classes}
      modalRef={ref}
      loading={loading}
      defaultValues={defaultValues}
      initialValues={initialValues}
      onSubmit={onSubmit}
      modalName={modalName}
      formApi={formApi}
      keepOpenOnRefresh={keepOpenOnRefresh}
      validate={validateForm}
      closeModal={closeModal}
      onSave={onSave}
      saveBtnProps={saveBtnProps}
      isModal={isModal}
      render={(formRenderProps) => {
        const { form, values, initialValues } = formRenderProps;
        const { typeOfService, skills: skillsValue, dates } = values;
        return (
          <>
            <section>
              <SkillsField
                skills={skills}
                skillsValue={skillsValue}
                messages={messages}
                onBrowse={onBrowse}
              />
            </section>
            <section>
              <RadioGroupField
                className="price-type-radio"
                label={t('Filters.price')}
                name="price.type"
                options={priceTypes}
              />
              <PriceRangeField
                functionType="logarithmic"
                name="price.range"
                min={
                  priceRange?.minTotalPrice
                    ? convertSubUnitToUnit(
                        priceRange.minTotalPrice,
                        unitDivisor('USD'),
                      )
                    : undefined
                }
                max={
                  priceRange?.maxTotalPrice
                    ? convertSubUnitToUnit(
                        priceRange.maxTotalPrice,
                        unitDivisor('USD'),
                      )
                    : undefined
                }
              />

              <div className="fields-row range even price-row">
                <CurrencyInputField
                  name="price.range.from"
                  label={t('Filters.min')}
                  placeholder={t('Filters.minPrice')}
                  valueMap={valueMap}
                  validate={validations.minPrice}
                />
                <CurrencyInputField
                  name="price.range.to"
                  label={t('Filters.max')}
                  placeholder={t('Filters.maxPrice')}
                  valueMap={valueMap}
                  validate={validations.maxPrice}
                />
              </div>
            </section>
            <section>
              <p className="section-title">{messages.time}</p>

              <div className="anys-job-filters__dates fields-row range even">
                <DateTimeField
                  isSingleInput
                  name="dates.startDate"
                  label={messages.start}
                  rightLimit={dates?.endDate && dayjs(dates.endDate)}
                  renderAsPortal
                />
                <DateTimeField
                  isSingleInput
                  name="dates.endDate"
                  label={messages.end}
                  leftLimit={dates?.startDate && dayjs(dates.startDate)}
                  renderAsPortal
                />
              </div>
            </section>
            <section>
              <p className="section-title">{messages.location}</p>
              <LocationFields
                valuesFromForm={typeOfService}
                initialTypeOfService={initialValues.typeOfService}
                formApi={form}
                withValidation={false}
                withBounds
                withPlaceId
              />
            </section>
            <section>
              <Field
                component={MultipleSelectField}
                className="anys-filters-form__section"
                name="languages"
                ignoreAsync
                label={messages.language}
                placeholder={messages.selectLanguage}
                options={languageOptions}
                icon={<></>}
                searchable
              />
            </section>
            <section>
              <RadioGroupField
                name="freeCancelation"
                direction="row"
                label={messages.freeCancellation}
                options={freeCancellation}
              />
            </section>
            <section>
              <RadioGroupField
                name="sign"
                direction="row"
                label={messages.sign}
                options={signOptions}
              />
            </section>
            <section>
              <RadioGroupField
                name="negotiable"
                direction="row"
                label={messages.negotiable}
                options={negotiationOptions}
              />
            </section>
            <section>
              <RadioGroupField
                name="userType"
                direction="row"
                label={messages.userType}
                options={userTypeOptions}
              />
            </section>
            <section>
              <RadioGroupField
                className="radio-grid"
                label={messages.overallRating}
                name="rating"
                options={ratingOptions}
              />
            </section>
            <section>
              <div className="fields-row">
                <InputField
                  className="number-of-jobs"
                  label={messages.numberOfJobs}
                  name="numberOfJobs"
                  type="number"
                  suffixNode={<Plus showGradient />}
                />
                <span className="or">{messages.or}</span>
                <RadioGroupField
                  direction="row"
                  className="radio-full-row"
                  name="numberOfJobs"
                  options={minNumberOfJobs}
                />
              </div>
            </section>
          </>
        );
      }}
    />
  );
};

export default React.forwardRef(JobFilters);
