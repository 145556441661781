import api from 'api';
import useInfinitePagination from 'hooks/useInfinitePagination';
import useQueryParams from 'hooks/useQueryParams';
import { InboxItem, InboxUrlParams } from 'models/Inbox';
import { PaginationParams } from 'models/Pagination';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FilterContext } from 'router/subrouters/Search/pages/Search/providers/Filters/Filters.provider';
import {
  INBOX_ITEM_RELATIONS,
  getEntityIdForItem,
  getOtherPartyForEntity,
} from '../utils';
import useInboxLink from './useInboxLink';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import useMatchMedia from 'hooks/useMatchMedia';

const INBOX_ITEM_TAKE = 15;

const useInboxItemSearch = () => {
  const { setQueryParam, removeQueryParam } = useQueryParams();
  const { inboxItemId } = useParams<InboxUrlParams>();
  const navigate = useNavigate();
  const { createEntityLink } = useInboxLink();

  const isTabletLandscape = useMatchMedia('isTabletLandscape');

  const { where: filtersWhere, filters } = useContext(FilterContext);
  const { currentUser } = useContext(CurrentUserContext);

  const where = useMemo(() => {
    if (!filtersWhere?.length) return null;

    return filtersWhere;
  }, [filtersWhere]);

  const searchInbox = useCallback(
    async (currentPage: number, take: number) => {
      const queryParams: PaginationParams = {
        $where: where ? JSON.stringify(where) : null,
        $take: take,
        $skip: (currentPage - 1) * take,
        $relations: INBOX_ITEM_RELATIONS,
        $order: '-updatedAt',
      };

      const {
        data: { items, totalItems, totalPages },
      } = await api.inboxItems.getInboxItems(queryParams);

      return { items, totalItems, totalPages } as const;
    },
    [where],
  );

  const pagination = useInfinitePagination<InboxItem>({
    take: INBOX_ITEM_TAKE,
    makeRequest: searchInbox,
    resetDeps: [where],
    debounceTime: 500,
  });

  const whereAsString = where ? JSON.stringify(where) : null;

  const { items } = pagination;

  useEffect(() => {
    if (!items?.length || isTabletLandscape) return;

    const hasItemFromURL = items.some((item) => item.id === +inboxItemId);

    // If the selected item from the url isn't in our fetched items,
    // we should navigate to the first item from the fetched list
    if (hasItemFromURL) return;

    const { id, type } = items[0];

    const entityId = getEntityIdForItem(items[0]);

    const otherUser = getOtherPartyForEntity(currentUser, items[0]);

    navigate(
      createEntityLink(
        'view',
        type,
        entityId,
        id,
        otherUser?.id === currentUser?.id ? null : otherUser?.id,
      ),
      { replace: true },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items?.length, whereAsString, isTabletLandscape]);

  return {
    ...pagination,
    filters,
    setQueryParam,
    removeQueryParam,
  };
};

export default useInboxItemSearch;
