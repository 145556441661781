import React, { useId } from 'react';

type IconProps = React.SVGProps<SVGSVGElement> & { showGradient?: boolean };

export default ({ showGradient, ...props }: IconProps) => {
  const lgId = useId();

  return (
    <svg width="12" height="8" viewBox="0 0 12 8" fill="none" {...props}>
      <path
        d="M0.999862 0.666626C0.66281 0.666626 0.358946 0.869661 0.229962 1.18106C0.100978 1.49245 0.172274 1.85088 0.410606 2.08921L5.41061 7.08921C5.73604 7.41465 6.26368 7.41465 6.58912 7.08921L11.5891 2.08921C11.8274 1.85088 11.8987 1.49245 11.7698 1.18106C11.6408 0.869661 11.3369 0.666626 10.9999 0.666626H0.999862Z"
        fill={showGradient ? `url(#${lgId})` : 'currentColor'}
      />
      <defs>
        <linearGradient
          id={lgId}
          x1="0.166504"
          y1="3.99996"
          x2="11.8332"
          y2="3.99996"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF7BAC" />
          <stop offset="1" stopColor="#D142F4" />
        </linearGradient>
      </defs>
    </svg>
  );
};
