import * as ReactDOM from 'react-dom/client';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import App from './App';
import React from 'react';

import 'react-toastify/dist/ReactToastify.css';
import './index.scss';
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
