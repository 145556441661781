import React, { useState } from 'react';
import classNames from 'classnames';
import EmojiCryIcon from 'icons/EmojiCry.icon';
import EmojiSadIcon from 'icons/EmojiSad.icon';
import EmojiPokerFaceIcon from 'icons/EmojiPokerFace.icon';
import EmojiSmileIcon from 'icons/EmojiSmile.icon';
import EmojiLaughIcon from 'icons/EmojiLaugh.icon';

import './ReviewEmojisGrade.styles.scss';

const EMOJIS = [
  { svg: <EmojiCryIcon />, value: 1 },
  { svg: <EmojiSadIcon />, value: 2 },
  { svg: <EmojiPokerFaceIcon />, value: 3 },
  { svg: <EmojiSmileIcon />, value: 4 },
  { svg: <EmojiLaughIcon />, value: 5 },
];

type ReviewEmojisGradeProps = {
  className?: string;
  onChange?: (value: number) => void;
};

const ReviewEmojisGrade: React.FC<ReviewEmojisGradeProps> = (props) => {
  const { className, onChange } = props;

  const classes = classNames('anys-review-emojis-grade', className);

  const [active, setActive] = useState<number>();

  return (
    <div className={classes}>
      {EMOJIS.map((emoji, index) => {
        return (
          <div
            key={index}
            className={classNames('anys-review-emojis-grade__emoji', {
              'anys-review-emojis-grade__emoji--active': index === active,
            })}
            onClick={() => {
              setActive(index);
              onChange && onChange(index);
            }}
          >
            {emoji.svg}
          </div>
        );
      })}
    </div>
  );
};

export default ReviewEmojisGrade;
