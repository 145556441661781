import { createContext } from 'react';

export type UserSchedule = {
  startDate: Date;
  endDate: Date;
};

type UserScheduleContextProps = {
  selectedSchedule: UserSchedule;
  setSelectedSchedule: React.Dispatch<React.SetStateAction<UserSchedule>>;
};

export default createContext<Partial<UserScheduleContextProps>>({});
