import React from 'react';
import { Navigate } from 'react-router-dom';
import { EnhancedRouteProps } from 'router/routes/Enhanced/Enhanced.route';
import EmptyMiddlePreview from './pages/EmptyMiddlePreview';

export default [
  {
    path: ':inboxItemId/:chatUserId',
    element: EmptyMiddlePreview,
  },
  {
    index: true,
    element: EmptyMiddlePreview,
  },
  {
    path: '',
    element: () => <Navigate to="/" replace />,
  },
] as Array<EnhancedRouteProps>;
