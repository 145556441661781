import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { Field, FieldRenderProps, UseFieldConfig } from 'react-final-form';
import { RadioButton, RadioButtonProps } from 'ncoded-component-library';
import formValidators from 'utils/formValidators';
import ValidationError from 'components/ValidationError';

import './RadioButtonField.styles.scss';

type RadioButtonFieldProps = { name: string } & RadioButtonProps &
  UseFieldConfig<string, string>;

type RadioButtonFieldComponentProps = RadioButtonProps &
  FieldRenderProps<string, HTMLElement>;

const RadioButtonFieldComponent: React.FC<RadioButtonFieldComponentProps> = (
  props,
) => {
  const {
    className,
    input,
    meta,
    onChange,
    disabled,
    customRadioComponent: Component = RadioButton,
    ...restOfProps
  } = props;

  const { onChange: inputOnChange, ...restOfInput } = useMemo(
    () => input,
    [input],
  );

  const classes = classNames(
    'anys-radio-button-field',
    { 'anys-radio-button-field--disabled': disabled },
    className,
  );

  const { hasError, error } = useMemo(
    () => formValidators.getErrorFromMeta(meta),
    [meta],
  );

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      inputOnChange(e);

      onChange?.(e);
    },
    [inputOnChange, onChange],
  );

  return (
    <div className={classes}>
      <Component
        {...restOfProps}
        {...restOfInput}
        disabled={disabled}
        onChange={handleOnChange}
      />

      <ValidationError showError={hasError} error={error} />
    </div>
  );
};

const RadioButtonField: React.FC<RadioButtonFieldProps> = (props) => {
  return (
    <Field type="radio" component={RadioButtonFieldComponent} {...props} />
  );
};

export default RadioButtonField;
