import InViewportObserver from 'components/InViewportObserver';
import { useEffect, useState } from 'react';

const useInViewportElObserver = () => {
  const [viewportSize, setViewportSize] = useState(-1);

  useEffect(() => {
    const changeViewportSize = () => {
      const {
        clientHeight = 0,
        clientWidth = 0,
        scrollTop = 0,
      } = document.documentElement;

      // Sum of height and width because we want to make sure
      // that when user changes the window height/width we update
      // the value; divide by 50 so we don't get frequent re-renders
      const newSize = (clientHeight + clientWidth + scrollTop) / 50;

      setViewportSize(newSize);
    };

    if (typeof window === 'undefined') return;

    // Set the initial value when the component mounts
    setViewportSize(window.innerHeight + window.innerWidth + window.scrollX);

    window.addEventListener('scroll', changeViewportSize);
    window.addEventListener('resize', changeViewportSize);
    window.addEventListener('orientationchange', changeViewportSize);

    return () => {
      window.removeEventListener('scroll', changeViewportSize);
      window.removeEventListener('resize', changeViewportSize);
      window.removeEventListener('orientationchange', changeViewportSize);
    };
  }, []);

  return { dependency: viewportSize, InViewportObserver };
};

export default useInViewportElObserver;
