import React, { createContext } from 'react';

export type MobileHeaderStyleOptions = {
  hasBorder?: boolean;
  isHeaderShown?: boolean;
  className?: string;
  isBackButtonTransparent?: boolean;
  absolutePosition?: boolean;
  showBackButton?: boolean;
};

type MobileHeaderContextProps = {
  content: React.ReactNode;
  styleOptions: MobileHeaderStyleOptions;
  showBackButton: true;
  prevPath: string | -1;
  setStyleOptions: React.Dispatch<
    React.SetStateAction<MobileHeaderStyleOptions>
  >;
  setContent: (content: React.ReactNode, priority?: number) => void;
  onBackClick: (prevPath: string | -1) => void;
  setOnBackClick: (backFn: (prevPath: string | -1) => void) => void;
};

export default createContext<Partial<MobileHeaderContextProps>>({});
