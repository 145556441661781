import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import AnyServiceLogo from 'icons/AnyServiceLogo.icon';
import { Button } from 'ncoded-component-library';
import Google from 'icons/Google.icon';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import MobileHeaderContext from 'components/MobileHeader/providers/MobileHeader/MobileHeader.context';
import { useTranslation } from 'react-i18next';

import './AuthWelcome.styles.scss';
import './AuthWelcome.styles.responsive.scss';
import storageService from 'services/storageService';
import env from 'env';

type AuthWelcomeProps = {
  className?: string;
};

const AuthWelcome: React.FC<AuthWelcomeProps> = (props) => {
  const { className } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const { setStyleOptions } = useContext(MobileHeaderContext);

  const { t } = useTranslation();

  const [params] = useSearchParams();

  const classes = classNames('anys-auth-welcome', className);

  const messages = useMemo(
    () => ({
      title: t('AuthWelcome.title'),
      subtitle: t('General.bestPlatform'),
      signUpForFree: t('General.signUpForFree'),
      login: t('General.login'),
      continueWithApple: t('AuthWelcome.continueWithApple'),
      continueWithGoogle: t('AuthWelcome.continueWithGoogle'),
      continueWithFacebook: t('AuthWelcome.continueWithFacebook'),
      asGuest: t('AuthWelcome.asGuest'),
    }),
    [t],
  );

  useEffect(() => {
    setStyleOptions({
      isHeaderShown: false,
    });
  }, [setStyleOptions]);

  const socialButtonClasses = classNames(
    'anys-auth-welcome__action-buttons__button',
    'anys-auth-welcome__action-buttons__button--social',
  );

  // TODO: replace buttons with links
  // when we style them
  const handleLogin = useCallback(() => {
    navigate('login', { state: location.state });
  }, [location.state, navigate]);

  const handleSignup = useCallback(() => {
    navigate('signup');
  }, [navigate]);

  const handleSkip = useCallback(() => {
    navigate('/');
  }, [navigate]);

  const authWithGoogle = useCallback(() => {
    window.location.href = `${env.SERVER_ENDPOINT}/api/auth/google`;
  }, []);

  useEffect(() => {
    const referralCode = params.get('referralCode');

    if (!referralCode) {
      storageService.removeItem('referralCode', true);

      return;
    }

    storageService.setItem('referralCode', referralCode, true);
  }, [params]);

  return (
    <div className={classes}>
      <img
        alt="human network"
        src="/assets/images/human-network.png"
        className="anys-auth-welcome__human-network"
      />
      <AnyServiceLogo className="anys-auth-welcome__logo" />
      <section>
        <h2 className="anys-auth-welcome__title">{messages.title}</h2>
        <div className="anys-auth-welcome__subtitle">{messages.subtitle}</div>

        <div className="anys-auth-welcome__action-buttons">
          <Button
            type="button"
            className="anys-auth-welcome__action-buttons__button"
            onClick={handleSignup}
          >
            {messages.signUpForFree}
          </Button>

          <Button
            type="button"
            variant="outline"
            className="anys-auth-welcome__action-buttons__button"
            onClick={handleLogin}
          >
            {messages.login}
          </Button>

          {/* <Button type="button" className={socialButtonClasses}>
            <Apple />
            {messages.continueWithApple}
          </Button> */}
          <Button
            type="button"
            className={socialButtonClasses}
            onClick={authWithGoogle}
          >
            <Google />
            {messages.continueWithGoogle}
          </Button>
          {/* <Button className={socialButtonClasses}>
            <Facebook />
            {messages.continueWithFacebook}
          </Button> */}

          <Button
            type="button"
            className="anys-auth-welcome__action-buttons__button"
            variant="link"
            onClick={handleSkip}
          >
            <span>{messages.asGuest}</span>
          </Button>
        </div>
      </section>
    </div>
  );
};

export default AuthWelcome;
