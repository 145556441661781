export type Pagination = {
  totalItems: number;
  totalPages: number;
  currentPage: number;
};

export type PaginationParams = {
  $take?: number;
  $skip?: number;
  $order?: string; // e.g. -role
  $where?: string;
  $relations?: string;
  $page?: number;
  $currentLocation?: string;
  $suggestionPage?: string;
  $options?: Partial<{
    loadEagerRelations: boolean;
  }>;
};

export type PaginatedResponse<T> = Pagination & {
  items: T[];
};

export const DEFAULT_PAGINATION = {
  take: 10,
};
