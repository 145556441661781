import api from 'api';
import useInfinitePagination from 'hooks/useInfinitePagination';
import { InboxItem } from 'models/Inbox';
import { PaginationParams } from 'models/Pagination';
import { useCallback } from 'react';
import { INBOX_ITEM_RELATIONS } from 'router/subrouters/Inbox/utils';

const useMyOffersSearch = () => {
  const searchOffers = useCallback(
    async (currentPage: number, take: number) => {
      const queryParams: PaginationParams = {
        $where: JSON.stringify([
          { isOwn: { EQUAL: true }, isProvide: { EQUAL: true } },
        ]),
        $take: take,
        $skip: (currentPage - 1) * take,
        $relations: INBOX_ITEM_RELATIONS,
        $order: '-updatedAt',
      };

      const {
        data: { items, totalItems, totalPages },
      } = await api.inboxItems.getInboxItemsWithoutOffers(queryParams);

      return { items, totalItems, totalPages } as const;
    },
    [],
  );

  const pagination = useInfinitePagination<InboxItem>({
    take: 15,
    makeRequest: searchOffers,
    debounceTime: 500,
  });

  return pagination;
};

export default useMyOffersSearch;
