import React from 'react';
import _ from 'lodash';

type ClockProps = {
  hasGradient?: boolean;
} & React.SVGProps<SVGSVGElement>;

export default (props: ClockProps) => {
  const { hasGradient = true, ...restOfProps } = props;

  const gradientId = _.uniqueId();

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restOfProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.832031 10.0002C0.832031 4.93755 4.93609 0.833496 9.9987 0.833496C15.0613 0.833496 19.1654 4.93755 19.1654 10.0002C19.1654 15.0628 15.0613 19.1668 9.9987 19.1668C4.93609 19.1668 0.832031 15.0628 0.832031 10.0002ZM9.9987 2.50016C5.85656 2.50016 2.4987 5.85802 2.4987 10.0002C2.4987 14.1423 5.85656 17.5002 9.9987 17.5002C14.1408 17.5002 17.4987 14.1423 17.4987 10.0002C17.4987 5.85802 14.1408 2.50016 9.9987 2.50016ZM10.0022 4.16683C10.4625 4.16688 10.8355 4.54001 10.8355 5.00025L10.835 9.65869L14.124 12.9477C14.4494 13.2731 14.4494 13.8007 14.124 14.1262C13.7986 14.4516 13.2709 14.4516 12.9455 14.1262L9.4124 10.5931C9.2561 10.4368 9.1683 10.2248 9.16832 10.0037L9.16882 5.00008C9.16887 4.53984 9.542 4.16678 10.0022 4.16683Z"
        fill={hasGradient ? `url(#${gradientId})` : 'currentColor'}
      />
      {hasGradient && (
        <defs>
          <linearGradient
            id={gradientId}
            x1="0.832031"
            y1="10.0002"
            x2="19.1654"
            y2="10.0002"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FF7BAC" />
            <stop offset="1" stopColor="#D142F4" />
          </linearGradient>
        </defs>
      )}
    </svg>
  );
};
