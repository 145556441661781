import React, { ReactNode } from 'react';
import classNames from 'classnames';
import Star from './Star/Star.component';
import { isEmptyValue } from 'utils/formValidators';
import { useTranslation } from 'react-i18next';
import EmojiReviewRating from 'components/EmojiReviewRating';

import './Rating.styles.scss';

type RatingProps = {
  count?: number;
  value?: number;
  className?: string;
  reviews?: number;
  changable?: boolean;
  withValue?: boolean;
  starSize?: number;
  fullStar?: boolean;
  useSmily?: boolean;
  maxReviewScore: number;
  valueFormat?: (value: number) => ReactNode;
  reviewFormat?: (reviews: number) => ReactNode;
};

const Rating: React.FC<RatingProps> = (props) => {
  const { t } = useTranslation();
  const {
    count,
    value,
    className,
    reviews,
    withValue,
    starSize,
    fullStar,
    useSmily,
    maxReviewScore,
    valueFormat = (val) => `${val} / ${maxReviewScore}`,
    reviewFormat = (revs) =>
      !isEmptyValue(revs) ? `(${revs} ${t('General.reviews')})` : '(0)',
  } = props;

  const classes = classNames('anys-rating', className);

  const hasValue = withValue && !isEmptyValue(value) && !Number.isNaN(value);

  return (
    <div className={classes}>
      {useSmily ? (
        <EmojiReviewRating grade={+value} className="anys-rating__smily" />
      ) : (
        [...Array(count)].map((el, index) => (
          <Star
            key={index}
            active={true}
            value={fullStar ? maxReviewScore : value}
            size={starSize}
          />
        ))
      )}

      <span className="anys-rating__label">
        {hasValue ? valueFormat(value) : `- / ${maxReviewScore}`}
      </span>
      {hasValue && !!reviews && (
        <span
          className={classNames(
            'anys-rating__label anys-rating__label--reviews',
            { 'no-value': !hasValue },
          )}
        >
          {hasValue ? reviewFormat(reviews) : `(${t('General.noReviewsYet')})`}
        </span>
      )}
    </div>
  );
};

export default Rating;
