import React from 'react';

type SearchSingleInsolentProps = React.SVGProps<SVGSVGElement>;

const SearchSingleInsolent: React.FC<SearchSingleInsolentProps> = (props) => {
  return (
    <svg width="29" height="29" viewBox="0 0 29 29" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.833252 12.9999C0.833252 6.00434 6.50434 0.333252 13.4999 0.333252C20.4955 0.333252 26.1666 6.00434 26.1666 12.9999C26.1666 16.0174 25.1114 18.7885 23.3499 20.9643L28.2475 25.8618C28.7682 26.3825 28.7682 27.2268 28.2475 27.7475C27.7268 28.2682 26.8825 28.2682 26.3618 27.7475L21.4643 22.8499C19.2885 24.6114 16.5174 25.6666 13.4999 25.6666C6.50434 25.6666 0.833252 19.9955 0.833252 12.9999ZM13.4999 2.99992C7.9771 2.99992 3.49992 7.4771 3.49992 12.9999C3.49992 18.5227 7.9771 22.9999 13.4999 22.9999C19.0227 22.9999 23.4999 18.5227 23.4999 12.9999C23.4999 7.4771 19.0227 2.99992 13.4999 2.99992Z"
        fill="url(#paint0_linear_5121_17521)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5121_17521"
          x1="0.833252"
          y1="14.2356"
          x2="28.638"
          y2="14.2356"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF7BAC" />
          <stop offset="1" stopColor="#D142F4" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SearchSingleInsolent;
