export const SOCKET_EVENT_NAMES = {
  AUTH: 'auth',
  AUTH_SUCCESS: 'auth:success',
  AUTH_FAIL: 'auth:fail',
  DISCONNECT: 'disconnect',
  KICKED: 'kicked',
  CHAT_SESSION_CREATED: 'chat-session-created',
  CHAT_MESSAGE_CREATED: 'chat-message-created',
  INBOX_ITEM_CREATED: 'inbox-item-created',
  INBOX_ITEM_SWAPPED: 'inbox-item-swapped',
  INBOX_ITEM_DELETED: 'inbox-item-deleted',
  INBOX_ITEM_UPDATED: 'inbox-item-updated',
  HEARTBEAT: 'heartbeat',
  NOTIFICATION_CREATED: 'notification-created',

  // We send subscribe when we enter the job post page
  SUBSCRIBE_TO_JOB_POST: 'subscribe-to-job-post',
  UNSUBSCRIBE_FROM_JOB_POST: 'unsubscribe-from-job-post',
  JOB_POST_UPDATED: 'job-post-updated', // show refresh button for the user
  JOB_POST_SUSPENDED: 'job-post-suspended', // redirect user or show him suspend info

  // Arbitration
  ARBITRATION_CREATED: 'arbitration-created',
  ARBITRATION_UPDATED: 'arbitration-updated',
} as const;

export type SocketEventNames =
  (typeof SOCKET_EVENT_NAMES)[keyof typeof SOCKET_EVENT_NAMES];
