/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import FileInput from 'components/FileInput';
import { Accept, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import ReactCrop, { ReactCropProps } from 'react-image-crop';
import useCallbackRef from 'hooks/useCallbackRef';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import { Button } from 'ncoded-component-library';
import Modal from 'components/Modal';
import AddWithBorderIcon from 'icons/AddWithBorder.icon';
import showToast from 'modules/showToast';
import useModalCloseOveride from 'hooks/useModalCloseOveride';

import 'react-image-crop/lib/ReactCrop.scss';
import './ImageInput.styles.scss';

const RCrop = ReactCrop as any as React.FC<ReactCropProps>;

type ImageInputProps = {
  className?: string;
  trigger?: React.ReactNode;
  accept?: Accept;
  maxSizeInMB?: number;
  aspect?: number;
  onUploadImage: (image: string, file: File) => void;
};

const ImageInput: React.FC<ImageInputProps> = (props) => {
  const {
    className,
    trigger,
    accept = { 'image/png': ['.png', '.jpeg', '.jpg'] },
    maxSizeInMB = 5,
    aspect = 1,
    onUploadImage,
  } = props;

  const classes = classNames('anys-image-input', className);

  const { t } = useTranslation();

  const [files, setFiles] = useState<File[]>([]);

  const [crop, setCrop] = useState<ReactCrop.Crop>({
    aspect,
    width: 40,
    unit: '%',
  });

  const [completedCrop, setCompletedCrop] = useState<ReactCrop.Crop>();

  const [imageModal, imageModalRef] = useCallbackRef<ModalRef>(null);

  const previewCanvasRef = useRef<HTMLCanvasElement>(
    document.createElement('canvas'),
  );

  const [img, imgRef] = useCallbackRef<HTMLImageElement>();
  const [image, setImage] = useState<Blob | null>(null);

  const MIN_IMAGE_WIDTH = 512;
  const MIN_IMAGE_HEIGHT = 512;
  const MAX_IMAGE_WIDTH = 6000;
  const MAX_IMAGE_HEIGHT = 6000;

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    multiple: false,
    noClick: true,
    noKeyboard: true,
    // COMMENT: image limit is 5MB
    maxSize: maxSizeInMB * 2 ** 20, // 2^20 is 1MB
    maxFiles: 1,
    onDropAccepted: (files) => {
      const filteredFiles: File[] = [];
      const promises: Promise<any>[] = [];

      files.forEach(async (file) => {
        const reader = new FileReader();

        reader.readAsDataURL(file);
        promises.push(
          new Promise(
            (resolve, reject) =>
              (reader.onload = async (e) => {
                const image = new Image();

                image.src = e!.target!.result as string;

                image.onload = async () => {
                  if (
                    image.width > MAX_IMAGE_WIDTH ||
                    image.height > MAX_IMAGE_HEIGHT
                  ) {
                    showToast(
                      'error',
                      t(
                        `Maximum image resolution is ${MAX_IMAGE_WIDTH}x${MAX_IMAGE_HEIGHT} pixels. `,
                      ),
                    );
                    reject();
                  } else if (
                    image.width < MIN_IMAGE_WIDTH ||
                    image.height < MIN_IMAGE_HEIGHT
                  ) {
                    showToast(
                      'error',
                      t(
                        `Minimum image resolution is ${MIN_IMAGE_WIDTH}x${MIN_IMAGE_HEIGHT} pixels.`,
                      ),
                    );

                    reject();
                  } else {
                    Object.assign(file, {
                      preview: e.target?.result,
                    });
                    filteredFiles.push(file);
                    resolve(file);

                    imageModal.open();
                  }
                };
              }),
          ),
        );

        await Promise.all(promises);
        setFiles(filteredFiles);
      });
    },
    onDropRejected: (fileRejections) => {
      fileRejections.forEach((file) => {
        switch (file.errors[0].code) {
          case 'file-too-large':
            showToast('error', t('General.fileTooLarge'));

            return;
          case 'file-invalid-type':
            showToast('error', t('General.invalidFileType'));

            return;
        }
      });
    },
  });

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !img) {
      return;
    }

    const canvas = previewCanvasRef.current;

    const scaleX = img.naturalWidth / img.width;
    const scaleY = img.naturalHeight / img.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    if (ctx && completedCrop) {
      const crop = completedCrop;
      canvas.width = completedCrop.width! * pixelRatio;
      canvas.height = completedCrop.height! * pixelRatio;

      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
      ctx.imageSmoothingQuality = 'high';

      ctx.drawImage(
        img,
        crop.x! * scaleX,
        crop.y! * scaleY,
        crop.width! * scaleX,
        crop.height! * scaleY,
        0,
        0,
        crop.width!,
        crop.height!,
      );

      canvas.toBlob(setImage);
    }
  }, [completedCrop, img]);

  useModalCloseOveride(imageModal);

  return (
    <div className={classes} {...getRootProps()}>
      <FileInput
        trigger={trigger ? trigger : <AddWithBorderIcon />}
        inputProps={getInputProps()}
      />

      <Modal
        modalName="image-input"
        type="no-action"
        ref={imageModalRef}
        onOverlayClose={() => undefined}
      >
        {files[0] ? (
          <RCrop
            crop={crop}
            src={(files[0] as any)?.preview}
            onChange={setCrop}
            onComplete={setCompletedCrop}
            onImageLoaded={imgRef}
          />
        ) : (
          <FileInput
            trigger={
              <Button variant="link">
                <span>{t('General.addImage')}</span>
              </Button>
            }
            inputProps={getInputProps()}
          />
        )}
        <Button
          className="anys-image-input__save-btn"
          onClick={() => {
            if (image) {
              const file = new File([image], files[0].name, {
                type: files[0].type,
              });

              onUploadImage(URL.createObjectURL(image), file);
            }
            imageModal.close();
          }}
          styleType="secondary"
        >
          {t('General.save')}
        </Button>
      </Modal>
    </div>
  );
};

export default ImageInput;
