import Auth from './subrouters/Auth/pages/Auth';
import Catalog from 'pages/Catalog';
import ChatPage from './subrouters/Chat/pages/ChatPage';
import { ComplexRoute } from './components/Routes/Routes';
import Contract from './subrouters/Contract/pages/Contract';
import Home from 'pages/Home';
import Inbox from './subrouters/Inbox/pages/Inbox';
import { InboxItemType } from 'models/Inbox';
import Job from './subrouters/Job/pages/Job';
import JobPost from './subrouters/JobPost/pages/JobPost';
import NavBar from 'components/NavBar';
import NeedProvideProvider from 'providers/NeedProvide/NeedProvide.provider';
import Profile from './subrouters/Profile/pages/Profile/Profile.page';
import NeedProvidePage from './subrouters/NeedProvide/pages/NeedProvidePage';
import React from 'react';
import SearchRouter from './subrouters/Search';
import authRoutes from './subrouters/Auth/routes';
import chatPageRoutes from './subrouters/Chat/routes';
import contractRoutes from './subrouters/Contract/routes';
import inboxRoutes from './subrouters/Inbox/routes';
import jobPostRoutes from './subrouters/JobPost/routes';
import jobRoutes from './subrouters/Job/routes';
import profileRoutes from './subrouters/Profile/routes';
import walletRoutes from './subrouters/Wallet/routes';
import searchRoutes from './subrouters/Search/routes';
import arbitrationRoutes from './subrouters/Arbitration/routes';
import FiltersProvider from './subrouters/Search/pages/Search/providers/Filters/Filters.provider';
import NotificationCenter from 'pages/NotificationCenter';
import ArbitrationProvider from 'providers/Arbitration';
import AccountSettings from './subrouters/Profile/pages/AccountSettings';
import StaticPages from './subrouters/StaticPages/pages/StatisPages';
import staticPagesRoutes from './subrouters/StaticPages/routes';
import WalletOutline from './subrouters/Wallet/WalletOutline.page';

const getRoutesForInbox = (inboxItemType: InboxItemType) => {
  const routes =
    inboxItemType === 'job-post'
      ? jobPostRoutes
      : inboxItemType === 'job'
        ? jobRoutes
        : contractRoutes;

  return [
    {
      path: `inbox/:inboxItemId/:chatUserId/${inboxItemType}`,
      element: Inbox,
      routes,
      authorized: true,
    },
  ];
};

export default [
  {
    path: 'auth',
    authorized: false,
    element: Auth,
    routes: authRoutes,
  },
  {
    path: 'catalog',
    element: Catalog,
  },
  {
    path: 'notification-center',
    authorized: true,
    element: NotificationCenter,
  },
  {
    path: 'profile',
    authorized: true,
    onlyPublic: false,
    element: () => (
      <>
        <NavBar />
        <Profile />
      </>
    ),
    routes: profileRoutes,
  },
  {
    path: 'job-post',
    element: JobPost,
    routes: jobPostRoutes,
  },
  {
    path: 'job',
    authorized: true,
    element: Job,
    routes: jobRoutes,
  },
  {
    path: 'inbox',
    element: Inbox,
    routes: inboxRoutes,
    authorized: true,
  },
  {
    path: 'wallet',
    element: () => (
      <>
        <NavBar />
        <WalletOutline />
      </>
    ),
    routes: walletRoutes,
    authorized: true,
  },
  {
    path: 'arbitration',
    element: () => (
      <>
        <NavBar />
        <ArbitrationProvider />
      </>
    ),
    authorized: true,
    routes: arbitrationRoutes,
  },
  {
    path: 'static-pages',
    element: StaticPages,
    routes: staticPagesRoutes,
  },
  {
    path: 'account-settings',
    element: () => (
      <>
        <NavBar />
        <AccountSettings />
      </>
    ),
    authorized: true,
    // routes: arbitrationRoutes,
  },
  ...getRoutesForInbox('job-post'),
  ...getRoutesForInbox('job'),
  ...getRoutesForInbox('contract'),
  {
    path: 'chat',
    element: ChatPage,
    routes: chatPageRoutes,
    authorized: true,
  },
  {
    path: 'search',
    element: SearchRouter,
    routes: searchRoutes,
  },
  {
    path: 'provide',
    element: () => (
      <>
        <NavBar />
        <FiltersProvider>
          <NeedProvideProvider>
            <NeedProvidePage />
          </NeedProvideProvider>
        </FiltersProvider>
      </>
    ),
  },
  {
    path: 'need',
    element: () => (
      <>
        <NavBar />
        <FiltersProvider>
          <NeedProvideProvider>
            <NeedProvidePage />
          </NeedProvideProvider>
        </FiltersProvider>
      </>
    ),
  },
  {
    path: 'contract',
    authorized: true,
    onlyPublic: false,
    routes: contractRoutes,
    element: Contract,
  },
  {
    index: true,
    element: Home,
  },

  {
    path: '*',
    element: () => `not found`,
  },
] as Array<ComplexRoute>;
