import React, { useMemo } from 'react';
import classNames from 'classnames';
import { t } from 'i18next';
import { SkillRaiting } from 'models/Skills';
import ProfileCard from 'components/Card/ProfileCardComponent';
import { useProfile } from '../../../Profile/Profile.page';
import useCallbackRef from 'hooks/useCallbackRef';
import { ModalRef } from 'components/Modal';
import SkillsModal from './components/SkillsModal';
import Star from 'components/Rating/Star';

import './SkillsRating.styles.scss';
import './SkillsRating.styles.responsive.scss';

type SkillsRatingProps = {
  className?: string;
  overAllGrade?: number;
  reviews?: number;
  skillsRaitingList?: SkillRaiting[];
};

const SkillsRating: React.FC<SkillsRatingProps> = (props) => {
  const { className } = props;

  const classes = classNames('anys-skills-rating', className);

  const {
    profileUser: { overallSkillScore, skills },
  } = useProfile();

  const messages = useMemo(
    () => ({
      title: t('General.skills'),
      seeMore: t('General.seeMore'),
      noReviewsPlaceholder: t('General.noReviewsPlaceholder'),
      basedOnReviews: t('ClientReview.basedOnReviews', {
        reviews: overallSkillScore?.numberOfReviews,
      }),
    }),
    [overallSkillScore?.numberOfReviews],
  );

  const [skillsModal, skillsModalRef] = useCallbackRef<ModalRef>(null);

  return skills?.length ? (
    <ProfileCard
      className={classes}
      buttonLabel={
        overallSkillScore?.numberOfReviews &&
        overallSkillScore?.averageRating &&
        messages.seeMore
      }
      cardTitle={messages.title}
      onClick={() => {
        skillsModal.open();
      }}
    >
      <div className="anys-skills-rating__overall-review">
        <label className="anys-skills-rating__overall-review__grade">
          {(+overallSkillScore?.averageRating || 0)?.toFixed(1)}
          <small>/10</small>
        </label>
        <p className="anys-skills-rating__overall-review__reviews">
          {messages.basedOnReviews}
        </p>
      </div>
      <ul>
        {skills.slice(0, 3).map(({ skill, averageRating }) => (
          <li key={skill?.id || '-'}>
            <label>{skill?.name || '-'}</label>
            <div className="anys-skills-rating__reviews">
              <Star active value={averageRating || 0} />
              <label>
                {(+averageRating || 0)?.toFixed(1)}
                <p>/10</p>
              </label>
            </div>
          </li>
        ))}
      </ul>
      <SkillsModal skillsModalRef={skillsModalRef} />
    </ProfileCard>
  ) : null;
};

export default SkillsRating;
