import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { Button } from 'ncoded-component-library';
import SearchSingleInsolent from 'icons/SearchSingleInsolent.icon';
import TrashIcon from 'icons/Trash.icon';
import NeedProvideContext from 'providers/NeedProvide/NeedProvide.context';
import { Skill } from 'models/Skills';
import JobFilters from 'components/JobFilters';
import { PriceRangeType } from 'api/favouriteFilters';
import confirm from 'modules/confirm';
import utils from 'utils';
import SkillsModal from 'router/subrouters/Search/pages/Search/components/SkillsModal';
import Modal, { ModalRef } from 'components/Modal';
import { FormApi } from 'final-form';
import {
  FormValue,
  jobFiltersTransformations,
} from 'components/JobFilters/JobFilters.component';
import filtersForm from 'utils/filtersForm';
import { FilterContext } from 'router/subrouters/Search/pages/Search/providers/Filters/Filters.provider';
import { Filter } from 'models/Filters';
import { useTranslation } from 'react-i18next';
import BackButton from 'components/BackButton';
import StickyBottomContent from 'components/StickyBottomContent';

import './FavouriteFilters.styles.scss';
import './FavouriteFilters.styles.responsive.scss';

type FavouriteFiltersProps = {
  className?: string;
  skills: Skill[];
  priceRange: PriceRangeType;
  closeModal: () => void;
};

const FavouriteFilters: React.ForwardRefRenderFunction<
  ModalRef,
  FavouriteFiltersProps
> = (props, ref) => {
  const { className, skills, priceRange, closeModal } = props;

  const { t } = useTranslation();

  const {
    favouriteFilters,
    removeFavouriteFilter,
    removingFilter,
    updateFavouriteFilter,
  } = useContext(NeedProvideContext);

  const { getParamsOfInterest, getWhere } = useContext(FilterContext);

  const skillsModalRef = useRef<ModalRef>();
  const favouriteFilterFormApi = useRef<FormApi<FormValue>>();

  const [selectedSkillIds, setSelectedSkillIds] = useState<number[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<Filter>(null);

  const classes = classNames(
    'anys-favourite-filters',
    {
      'anys-favourite-filters--selected': selectedFilter,
    },
    className,
  );

  const messages = useMemo(
    () => ({
      modalTitle: t('NeedProvideFilters.modalTitle'),
      title: t('FavouriteFilters.noFilterSelectedTitle'),
      subtitle: t('FavouriteFilters.noFilterSelectedSubtitle'),
      removeFilter: t('NeedProvideFilters.removeFilter'),
      remove: t('General.remove'),
      cancel: t('General.cancel'),
      noFilters: t('FavouriteFilters.noFilterPlaceholder'),
      save: t('General.save'),
      done: t('General.done'),
    }),
    [t],
  );

  const resetStates = useCallback(() => {
    setSelectedFilter(null);
    setSelectedSkillIds([]);
  }, []);

  const onSkillsApply = useCallback((skillIds: number[]) => {
    favouriteFilterFormApi.current?.change('skills', skillIds);

    skillsModalRef.current.close();
  }, []);

  const handleDeleteFilter = useCallback(
    async (id: number, name: string) => {
      if (
        !(await confirm({
          title: messages.removeFilter,
          description: t('FavouriteFilters.removeFilterDesc', {
            filterName: name,
          }),
          confirmContent: messages.remove,
          cancelContent: messages.cancel,
        }))
      ) {
        return;
      }

      try {
        await removeFavouriteFilter(id);

        setSelectedFilter(null);
        // eslint-disable-next-line no-empty
      } catch (error) {}
    },
    [messages, removeFavouriteFilter, t],
  );

  const onSubmit = useCallback(async () => {
    if (!selectedFilter) {
      closeModal();

      return;
    }

    const { values } = favouriteFilterFormApi.current?.getState() || {};

    if (!values) return;

    const transformedFilters = filtersForm.transformFilterValues(
      values,
      false,
      jobFiltersTransformations,
    );

    const search: Record<string, string> = {};

    Object.keys(transformedFilters).forEach((filterName) => {
      const filterValue =
        transformedFilters[filterName as keyof typeof transformedFilters];

      if (filterValue) search[filterName] = filterValue;
    });

    const poi = getParamsOfInterest(search);

    const where = getWhere(poi);

    const query = '$where=' + JSON.stringify(where);

    try {
      await updateFavouriteFilter(
        selectedFilter.id,
        selectedFilter.name,
        query,
        values,
      );
      setSelectedFilter(null);

      // eslint-disable-next-line no-empty
    } catch (error) {}
  }, [
    closeModal,
    getParamsOfInterest,
    getWhere,
    selectedFilter,
    updateFavouriteFilter,
  ]);

  useEffect(() => {
    if (selectedFilter) {
      favouriteFilterFormApi.current?.initialize(
        selectedFilter.filterQueryFormValues,
      );
    }
  }, [selectedFilter]);

  return (
    <Modal
      type="no-action"
      modalName="favouriteFilters"
      isDrawer
      overlayCloses
      keepOpenOnRefresh={false}
      ref={ref}
      className={classNames('anys-favourite-filters__favourites-modal', {
        'anys-favourite-filters__favourites-modal--selected': selectedFilter,
      })}
      onOpen={resetStates}
      lockScroll={false}
      title={
        <div className="anys-favourite-filters__favourites-modal__header">
          {selectedFilter && (
            <BackButton withBorder onBackClick={resetStates} />
          )}
          <span>{messages.modalTitle}</span>
          {selectedFilter && (
            <button
              type="button"
              onClick={() =>
                handleDeleteFilter(selectedFilter.id, selectedFilter.name)
              }
              className="anys-favourite-filters__favourites-modal__header__remove"
            >
              {messages.remove}
            </button>
          )}
        </div>
      }
    >
      <div className={classes}>
        <div className="anys-favourite-filters__top-part">
          <div className="anys-favourite-filters__menu">
            {favouriteFilters?.length ? (
              favouriteFilters.map((filter) => {
                const { id, name } = filter;

                return (
                  <div
                    key={id}
                    className={classNames(
                      'anys-favourite-filters__menu__item',
                      {
                        'anys-favourite-filters__menu__item--active':
                          selectedFilter?.id === id,
                      },
                    )}
                  >
                    <button
                      type="button"
                      onClick={() => {
                        setSelectedFilter(filter);
                      }}
                      className="anys-favourite-filters__menu__item__filter"
                    >
                      {name}
                    </button>
                    <Button
                      type="button"
                      variant="icon"
                      icon={<TrashIcon fill="var(--color-text-light)" />}
                      onClick={() => handleDeleteFilter(id, name)}
                      disabled={removingFilter}
                      className="anys-favourite-filters__menu__item__filter__remove"
                    />
                  </div>
                );
              })
            ) : (
              <div className="anys-favourite-filters__menu__no-filters">
                {messages.noFilters}
              </div>
            )}
          </div>

          {!selectedFilter ? (
            <div className="anys-favourite-filters__none-selected">
              <SearchSingleInsolent />
              <label className="anys-favourite-filters__none-selected__title">
                {messages.title}
              </label>
              <label className="anys-favourite-filters__none-selected__subtitle">
                {messages.subtitle}
              </label>
            </div>
          ) : (
            <JobFilters
              className="anys-favourite-filters__selected-filter-form"
              isModal={false}
              modalName=""
              skills={skills}
              priceRange={priceRange}
              onBrowse={() => skillsModalRef.current.open()}
              formApi={favouriteFilterFormApi}
              closeModal={utils.noop}
              loading={false}
            />
          )}
        </div>

        <StickyBottomContent className="anys-favourite-filters__action-buttons">
          <Button onClick={onSubmit}>
            <span>{selectedFilter ? messages.save : messages.done}</span>
          </Button>

          <Button variant="link" onClick={closeModal}>
            <span>{messages.cancel}</span>
          </Button>
        </StickyBottomContent>

        <SkillsModal
          keepOpenOnRefresh={false}
          initialValues={selectedSkillIds}
          loading={false}
          ref={skillsModalRef}
          onSubmit={onSkillsApply}
          onOpen={() => {
            setSelectedSkillIds(
              favouriteFilterFormApi.current?.getState().values.skills || [],
            );
          }}
        />
      </div>
    </Modal>
  );
};

export default forwardRef(FavouriteFilters);
