import React from 'react';

type TextColorProps = React.SVGProps<SVGSVGElement>;

const TextColor: React.FC<TextColorProps> = (props) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.4 10.1205C11.4 10.1205 8.70001 13.0455 8.70001 14.8455C8.69509 15.205 8.76102 15.5619 8.89404 15.896C9.02706 16.23 9.22457 16.5345 9.47529 16.7922C9.98164 17.3126 10.674 17.6106 11.4 17.6205C12.126 17.6304 12.8263 17.3516 13.3467 16.8452C13.6044 16.5945 13.8102 16.2955 13.9523 15.9652C14.0944 15.635 14.1701 15.28 14.175 14.9205V14.8455C14.175 13.0455 11.4 10.1205 11.4 10.1205ZM10.2975 9.10275L10.7993 8.55975L9.07501 3.75H7.57501L3.82501 14.25H5.32501L6.39751 11.25H8.60476C9.11377 10.4932 9.6786 9.77544 10.2945 9.10275H10.2975ZM8.32501 5.85L9.72001 9.75H6.93301L8.32501 5.85Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default TextColor;
