import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { Field, Form } from 'react-final-form';
import SelectField from 'components/SelectField';
import CheckboxField from 'components/CheckboxField';
import InputField from 'components/InputField';
import { Button, RangeSlider } from 'ncoded-component-library';
import RadioGroupField from 'components/RadioGroupField';
import PlusGradient from 'icons/PlusGradient.icon';
import { useTranslation } from 'react-i18next';
import { LANGUAGE_OPTIONS } from 'constants/language';
import { Role } from 'models/User';
import { RangeValueType } from 'ncoded-component-library/build/components/molecules/RangeSlider/RangeSlider.component';
import { FormApi } from 'final-form';
import dayjs from 'dayjs';
import dates from 'utils/dates';
import {
  convertFormValuesToQuery,
  FormValueToQueryMapper,
  UserQueryParams,
} from 'utils/filters';
import { ArbitratorFilters } from 'models/Job/Arbitration';
import SearchIcon from 'icons/Search.icon';
import { InputAutocompleteLocationField } from 'components/InputAutocompleteLocation';
import MultipleSelectField from 'components/MultipleSelectField';

import './AddArbitratorFilters.styles.scss';
import './AddArbitratorFilters.styles.responsive.scss';
import useGetSkillGroups from '../../../../../../../../../hooks/useGetSkillGroups';

const MIN_AGE = 18;
const MAX_AGE = 80;

const ageToBirthDate = (age: number) =>
  dates.formatDate(dayjs().subtract(age, 'year'), 'DD-MM-YYYY');

const parseToInteger = (val: string) =>
  val ? (Number.parseInt(val) as any) : val;

const keepAgeInBounds = (
  age: string,
  otherAge: string,
  isToAge?: boolean,
): any => {
  const ageAsNumber = parseToInteger(age);
  const otherAgeAsNumber = parseToInteger(otherAge);

  if (isToAge) {
    return Math.min(Math.max(ageAsNumber, otherAgeAsNumber), MAX_AGE);
  }

  return Math.max(Math.min(ageAsNumber, otherAgeAsNumber), MIN_AGE);
};

const checkboxOptions: { label: Role }[] = [
  {
    label: 'Business',
  },
  { label: 'Individual' },
];

const ratingFilterOptions = Array.from({
  length: 10,
})
  .map((_, index) => ({
    label: `${index + 1} ${index === 9 ? '' : '+'}`,
    value: `${index + 1}`,
  }))
  .reverse();

const numberOfJobsFilterOptions = [
  { label: '5+', value: '5' },
  { label: '10+', value: '10' },
  { label: '20+', value: '20' },
  { label: '50+', value: '50' },
];

const reviewedAsClientOptions = Array.from({ length: 5 })
  .map((_, index) => ({
    label: index === 0 ? 'All' : index < 4 ? `${index + 1}+` : `${index + 1}`,
    value: index === 0 ? `${index}` : `${index + 1}`,
  }))
  .reverse();

// The order of keys is used in ArbitrationSelectedFilters
export const VALUES_TO_QUERY_MAPPER: FormValueToQueryMapper<
  ArbitratorFilters,
  keyof typeof UserQueryParams
> = {
  language: {
    name: 'languages.language',
    mapperFn: (value) => (value ? { EQUAL: value } : undefined),
  },
  skills: {
    name: 'skills.skillId',
    mapperFn: (skills) => (skills?.length ? { IN: skills } : undefined),
  },
  userType: {
    name: 'user.role',
    mapperFn: (role) => (role.length ? { IN: role } : undefined),
  },
  userLocation: {
    name: 'location',
    mapperFn: (location) =>
      location.country ? { EQUAL: location.country } : undefined,
  },
  ages: {
    name: 'dateOfBirth',
    mapperFn: (age) => ({
      // The order is switched because the toAge will be older
      BETWEEN: [
        ageToBirthDate(age.toAge || MAX_AGE),
        ageToBirthDate(age.fromAge || MIN_AGE),
      ],
    }),
  },
  rating: {
    name: 'overallSkillScore.averageRating',
    mapperFn: (score) => ({
      MORETHANOREQUAL: Number.parseInt(score),
    }),
  },
  numberOfJobs: {
    name: 'jobSuccess.numberOfJobs',
    mapperFn: (jobs) => ({
      MORETHANOREQUAL: Number.parseInt(jobs || '0'),
    }),
  },
  reviewedAsAClient: {
    name: 'overallClientScore.averageRating',
    mapperFn: (score) => ({
      MORETHANOREQUAL: Number.parseInt(score),
    }),
  },
};

const getValueForRange = (fromAge: number, toAge: number): RangeValueType => {
  const min = Math.max(MIN_AGE, fromAge);
  const max = Math.min(MAX_AGE, toAge);

  return min >= MIN_AGE && max >= min
    ? {
        min,
        max,
        range: max - min,
      }
    : {
        min: MIN_AGE,
        max: MAX_AGE,
        range: MAX_AGE - MIN_AGE,
      };
};

type AddArbitratorFiltersProps = {
  className?: string;
  initialValues: Partial<ArbitratorFilters>;
  onCancel: () => void;
  onSubmit: (formValues: ArbitratorFilters, filtersQueryString: string) => void;
};

const AddArbitratorFilters: React.FC<AddArbitratorFiltersProps> = (props) => {
  const {
    className,
    initialValues: propInitialValues,
    onCancel,
    onSubmit,
  } = props;

  const { t } = useTranslation();

  const { skillOptions } = useGetSkillGroups();

  const classes = classNames('anys-add-arbitrator-filters', className);

  const messages = useMemo(
    () => ({
      language: t('General.language'),
      skills: t('General.skills'),
      selectSkill: t('NeedProvideFilters.selectSkill'),
      userType: t('General.userType'),
      userLocation: t('General.userLocation'),
      ages: t('General.ages'),
      from: t('General.from'),
      to: t('General.to'),
      rating: t('General.rating'),
      numberOfJobs: t('General.numberOfJobs'),
      or: t('General.or'),
      reviewedAsClient: t('General.reviewedAsAClient'),
      apply: t('General.apply'),
      cancel: t('General.cancel'),
    }),
    [t],
  );

  const initialValues: Partial<ArbitratorFilters> = useMemo(() => {
    if (!propInitialValues)
      return {
        userType: ['Individual', 'Business'],
        ages: {
          fromAge: MIN_AGE,
          toAge: 50,
        },
        rating: '10',
        numberOfJobs: '20',
        reviewedAsAClient: '5',
      } as Partial<ArbitratorFilters>;

    return propInitialValues;
  }, [propInitialValues]);

  const onChangeSlider = useCallback(
    (range: RangeValueType, formApi: FormApi<ArbitratorFilters>) => {
      const { min, max } = range;

      formApi.change('ages', {
        fromAge: Number.parseInt(`${min}`),
        toAge: Number.parseInt(`${max}`),
      });
    },
    [],
  );

  const handleSubmit = useCallback(
    (values: ArbitratorFilters) => {
      const valueAsString = convertFormValuesToQuery(
        values,
        VALUES_TO_QUERY_MAPPER,
      );

      onSubmit(values, valueAsString);
    },
    [onSubmit],
  );

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      render={(formRenderProps) => {
        const {
          handleSubmit,
          form,
          values: { ages },
        } = formRenderProps;

        const { fromAge, toAge } = ages || {};

        const rangeValue = getValueForRange(fromAge, toAge);

        const handleRangeSlider = (val: RangeValueType) =>
          onChangeSlider(val, form);

        return (
          <form onSubmit={handleSubmit} className={classes}>
            <SelectField
              label={messages.language}
              name="language"
              options={LANGUAGE_OPTIONS}
              className="anys-add-arbitrator-filters__language"
            />

            <Field
              className="anys-add-arbitrator-filters__skills"
              component={MultipleSelectField}
              ignoreAsync
              name="skills"
              label={messages.skills}
              placeholder={messages.selectSkill}
              options={skillOptions}
              icon={<></>}
              searchable
              onReset={() => form.change('skills', null)}
            />

            <div>
              <label>{messages.userType}</label>
              <div className="anys-add-arbitrator-filters__checkboxes">
                {checkboxOptions.map(({ label }) => (
                  <CheckboxField
                    key={label}
                    name="userType"
                    label={label}
                    value={label}
                  />
                ))}
              </div>
            </div>

            <InputAutocompleteLocationField
              label={messages.userLocation}
              name="userLocation"
              prefixNode={
                <SearchIcon
                  className="anys-add-arbitrator-filters__location-search-icon"
                  showGradient={false}
                />
              }
            />
            <div className="anys-add-arbitrator-filters__age">
              <label>{messages.ages}</label>
              <div className="anys-add-arbitrator-filters__age__range-slider">
                <RangeSlider
                  onChange={handleRangeSlider}
                  value={rangeValue}
                  min={MIN_AGE}
                  max={MAX_AGE}
                />
              </div>
              <div className="anys-add-arbitrator-filters__age__inputs">
                <InputField
                  type="number"
                  name="ages.fromAge"
                  isPureNumberInput
                  label={messages.from}
                  parse={parseToInteger}
                  format={(val) => keepAgeInBounds(val, `${toAge}`)}
                  min={MIN_AGE}
                  max={MAX_AGE}
                  autoComplete="off"
                  formatOnBlur
                />
                <label className="anys-add-arbitrator-filters__age__inputs__to">
                  -
                </label>
                <InputField
                  type="number"
                  isPureNumberInput
                  name="ages.toAge"
                  label={messages.to}
                  parse={parseToInteger}
                  format={(val) => keepAgeInBounds(val, `${fromAge}`, true)}
                  min={MIN_AGE}
                  max={MAX_AGE}
                  autoComplete="off"
                  formatOnBlur
                />
              </div>
            </div>
            <div className="anys-add-arbitrator-filters__rating">
              <label>{messages.rating}</label>
              <RadioGroupField
                direction="row"
                name="rating"
                options={ratingFilterOptions}
              />
            </div>
            <div className="anys-add-arbitrator-filters__number-of-jobs">
              <label>{messages.numberOfJobs}</label>
              <div className="anys-add-arbitrator-filters__number-of-jobs__actions">
                <InputField
                  name="numberOfJobs"
                  type="number"
                  suffixNode={<PlusGradient />}
                  placeholder="0"
                  isPureNumberInput
                />
                <label>{messages.or}</label>
                <RadioGroupField
                  direction="row"
                  name="numberOfJobs"
                  options={numberOfJobsFilterOptions}
                />
              </div>
            </div>
            <div className="anys-add-arbitrator-filters__reviewed-as-client">
              <label>{messages.reviewedAsClient}</label>

              <RadioGroupField
                direction="row"
                name="reviewedAsAClient"
                options={reviewedAsClientOptions}
              />
            </div>

            <div className="anys-add-arbitrator-filters__buttons-wrapper">
              <Button type="submit" variant="solid">
                {messages.apply}
              </Button>
              <Button type="button" variant="link" onClick={onCancel}>
                <span>{messages.cancel}</span>
              </Button>
            </div>
          </form>
        );
      }}
    />
  );
};

export default AddArbitratorFilters;
