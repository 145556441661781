import { NotificationCount, NotificationEntity } from 'models/Notification';
import httpClient from '../../httpClient';
import { PaginationParams, PaginatedResponse } from 'models/Pagination';

const notificationsPath = (routePath: TemplateStringsArray) =>
  `notifications/${routePath}`;

function getNotificationsCount() {
  return httpClient.get<NotificationCount[]>(notificationsPath`unread-count`);
}

function getNotifications(params: PaginationParams) {
  return httpClient.get<PaginatedResponse<NotificationEntity>>(
    notificationsPath``,
    {
      params,
    },
  );
}

function readManyNotifications(notifIds: number[]) {
  return httpClient.post<{ message: string }>(notificationsPath`read-many`, {
    ids: notifIds,
  });
}

function readAllNotifications() {
  return httpClient.post<{ message: string }>(notificationsPath`read-all`);
}

export default {
  getNotificationsCount,
  getNotifications,
  readManyNotifications,
  readAllNotifications,
};
