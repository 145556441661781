import React, { useMemo } from 'react';
import AddProofs from './components/AddProofs/AddProofs.component';
import PendingFunds from './components/PendingFunds/PendingFunds.component';
import classNames from 'classnames';
import Notice from 'components/Notice';
import DecisioningAndQuestions from './components/DecisioningAndQuestions/DecisioningAndQuestions.component';
import { useArbitration } from 'providers/Arbitration/Arbitration.provider';
import { useTranslation } from 'react-i18next';

import './MyCasesActions.styles.scss';

type MyCasesActionsProps = {
  className?: string;
};

const MyCasesActions = (props: MyCasesActionsProps) => {
  const { className } = props;
  const classes = classNames('anys-my-cases-actions', className);

  const { t } = useTranslation();

  const {
    selectedArbitration: { state, actionTimers },
  } = useArbitration();

  const renderArbitrationState = useMemo(() => {
    switch (state) {
      case 'pending_funds_provider_won':
        return <Notice type="default">{t('Arbitration.missingFunds')}</Notice>;

      case 'pending_funds':
        return <PendingFunds />;

      case 'pending_proofs':
        return <AddProofs previewOnly={actionTimers.length === 0} />;

      case 'finding_arbiters':
        return <AddProofs previewOnly />;

      case 'finding_arbiters_failed':
        return <AddProofs previewOnly />;

      case 'decisioning':
        return <DecisioningAndQuestions />;

      case 'decision_made':
        return <DecisioningAndQuestions decisionMade />;

      case 'decisioning_failed':
        return <p>{t('Arbitration.decisionFailed')}</p>;

      case 'pending_funds_client_won':
        return (
          <Notice type="default">
            {t('Arbitration.missingFundsFromClient')}
          </Notice>
        );

      default:
        return <div>{state}</div>;
    }
  }, [actionTimers.length, state, t]);

  return <div className={classes}>{renderArbitrationState}</div>;
};

export default MyCasesActions;
