import React, { ReactNode } from 'react';
import classNames from 'classnames';
import useQueryParams from 'hooks/useQueryParams';
import Badge from 'components/Badge';
import { useTranslation } from 'react-i18next';

import './FilterTag.styles.scss';
import './FilterTag.styles.responsive.scss';

type FilterTagProps = {
  className?: string;
  name: string;
  hideFilterLabel?: boolean;
  isClickDisabled?: boolean;
  display?: (value: string) => ReactNode;
};

const FilterTag: React.FC<FilterTagProps> = (props) => {
  const {
    className,
    name: realName,
    hideFilterLabel,
    isClickDisabled,
    display = String,
  } = props;
  const { t } = useTranslation();

  const classes = classNames('anys-filter-tag', className);

  const {
    params: { [realName]: value },
    setQueryParam,
    removeQueryParam,
  } = useQueryParams();

  const isArray = realName.includes('[]');
  const name = isArray ? realName.split('[')[0] : realName;

  if (!value) return null;

  if (isArray) {
    const values = value.split(',');
    return (
      <>
        {values.map((value) => (
          <Badge
            className={classes}
            clickDisabled={isClickDisabled}
            key={value}
            onClick={() => {
              if (values.length === 1) {
                return removeQueryParam(realName);
              }

              setQueryParam(
                realName,
                values.filter((v) => v !== value).join(','),
              );
            }}
          >
            {hideFilterLabel ? '' : `${t(`Filters.${name}_one`)}: `}
            {display(value)}
          </Badge>
        ))}
      </>
    );
  }

  return (
    <Badge
      className={classes}
      clickDisabled={isClickDisabled}
      onClick={() => {
        removeQueryParam(realName);
      }}
    >
      {hideFilterLabel ? '' : `${t(`Filters.${name}`)}: `}
      {display(value)}
    </Badge>
  );
};

export default FilterTag;
