import React from 'react';
import classNames from 'classnames';
import { ExtractPropTypes } from 'types';
import { TextArea as TextAreaNcLib } from 'ncoded-component-library';

import './TextArea.styles.scss';

type DefaultTextAreaProps = ExtractPropTypes<typeof TextAreaNcLib>;

type TextAreaProps = {
  className?: string;
  textAreaClassName?: string;
  name: string;
  label?: string;
  error?: { hasError: boolean; error: string };
} & DefaultTextAreaProps;

const TextArea: React.FC<TextAreaProps> = (props) => {
  const {
    className,
    textAreaClassName,
    name,
    label,
    resize = true,
    error,
    ...rest
  } = props;

  const classes = classNames(
    'anys-text-area',
    { 'anys-text-area--resize': resize },
    className,
  );

  return (
    <div className={classes}>
      {label && <label htmlFor={name}>{label}</label>}

      <TextAreaNcLib
        {...rest}
        {...error}
        name={name}
        className={textAreaClassName}
        resize={resize}
      />
      {error?.hasError && (
        <label className="anys-text-area__error">{error.error}</label>
      )}
    </div>
  );
};

export default TextArea;
