import React, { useCallback, useState } from 'react';
import { Button } from 'ncoded-component-library';
import chat from 'api/chat';
import { useNavigate } from 'react-router-dom';
import showToast from 'modules/showToast';
import { useTranslation } from 'react-i18next';

type SendUserMessageProps = {
  className?: string;
  userId: number;
} & React.ComponentProps<typeof Button>;

const SendUserMessage: React.FC<
  React.PropsWithChildren<SendUserMessageProps>
> = (props) => {
  const { children, userId, disabled, ...rest } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isSendingMessage, setIsSendingMessage] = useState(false);

  const sendMessage = useCallback(async () => {
    try {
      setIsSendingMessage(true);

      await chat.chatSessions.createChatSession(userId);

      navigate(`/chat/${userId}`);
    } catch (error) {
      showToast('error', t('General.sendingMessageFailed'));
      console.error(error);
    } finally {
      setIsSendingMessage(false);
    }
  }, [navigate, t, userId]);

  return (
    <Button
      {...rest}
      disabled={disabled || isSendingMessage || !userId}
      onClick={sendMessage}
    >
      {children}
    </Button>
  );
};

export default SendUserMessage;
