import React, { useEffect, useState } from 'react';
import { Timeslot } from 'models/Availability';
import dates from 'utils/dates';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import './AvailableSlots.styles.scss';

type AvailableSlotsProps = {
  startDate: Date;
  endDate: Date;
  timeslots: Timeslot[];
  userDisplayName: string;
};

const AvailableSlots: React.FC<AvailableSlotsProps> = (props) => {
  const { startDate, endDate, timeslots, userDisplayName } = props;

  const { t } = useTranslation();

  const [slots, setSlots] = useState<Timeslot[]>(null);

  const startDateTimestamp = startDate ? +startDate : null;
  const endDateTimestamp = endDate ? +endDate : null;

  const startDateAsDayjs = startDate ? dayjs(startDate) : null;
  const endDateAsDayjs = endDate ? dayjs(endDate) : null;

  useEffect(() => {
    if ((!startDate && !endDate) || !timeslots?.length) {
      setSlots(null);
      return;
    }

    setSlots(
      timeslots.filter(({ from, to }) => {
        const isStartDateInSlot = startDateAsDayjs?.isBetween(
          from,
          to,
          'day',
          '[]',
        );

        const isEndDateInSlot = endDateAsDayjs?.isBetween(
          from,
          to,
          'day',
          '[]',
        );

        return isStartDateInSlot || isEndDateInSlot;
      }),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateTimestamp, endDateTimestamp]);

  if (!slots?.length) return;

  return (
    <ul className="anys-available-slots">
      <li>
        {t('Availability.userBookedAvailableSlots', { user: userDisplayName })}
      </li>
      {slots.map((slot, i) => {
        const { from, to, id } = slot;

        return <li key={id}>{dates.formatDates(from, to)}</li>;
      })}
    </ul>
  );
};

export default AvailableSlots;
