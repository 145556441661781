import React from 'react';
import classNames from 'classnames';
import { NonImageFile } from 'models/User';
import { ReactComponent as DownloadIcon } from 'icons/download.svg';
import { ReactComponent as FillFileIcon } from 'icons/fill-file.svg';
import ExternalLink from 'components/ExternalLink';

import './FileContent.styles.scss';

type FileContentProps = {
  className?: string;
  files: NonImageFile[];
};

const FileContent: React.FC<FileContentProps> = (props) => {
  const { className, files } = props;

  const classes = classNames('anys-file-content', className);

  return (
    files?.length > 0 && (
      <div className={classes}>
        <ul>
          {files.map((file, i) => (
            <li key={i} className="anys-file-content__file">
              <ExternalLink
                className="anys-file-content__file__download"
                href={file.url}
              >
                <div className="anys-file-content__file__name">
                  <FillFileIcon />
                  <span>{file.name}</span>
                </div>

                <DownloadIcon />
              </ExternalLink>
            </li>
          ))}
        </ul>
      </div>
    )
  );
};

export default FileContent;
