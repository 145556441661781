import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from 'ncoded-component-library';
import Modal from 'components/Modal';
import GradientText from 'components/GradientText';
import api from 'api';
import showToast from 'modules/showToast';
import CopyToClipboard from 'components/CopyToClipboard';

import './Referral.styles.scss';
import './Referral.styles.responsive.scss';

type VideoGroups = {
  client: { label: string; url: string }[];
  provider: { label: string; url: string }[];
  general: { label: string; url: string }[];
  [key: string]: { label: string; url: string }[];
};
const VIDEOS: VideoGroups = {
  client: [
    {
      label: 'Referral.howToBuyServices',
      url: 'https://youtu.be/BmEdo8KCghk',
    },
  ],
  provider: [
    {
      label: 'Referral.usingAnyServiceProvider',
      url: 'https://youtu.be/W7zJ16Te7JQ',
    },
    {
      label: 'Referral.providerAvailability',
      url: 'https://youtu.be/gjjy0sTGijE',
    },
    {
      label: 'Referral.specificServices',
      url: 'https://youtu.be/5Z5sAsA5W-A',
    },
    {
      label: 'Referral.boosting',
      url: 'https://youtu.be/s_JooSkE5p8',
    },
    {
      label: 'Referral.postDesign',
      url: 'https://youtu.be/YeEJS0C3yFA',
    },
  ],
  general: [
    {
      label: 'Referral.arbitrationProcessExplained',
      url: 'https://youtu.be/i5sjEtiy23g',
    },
    {
      label: 'Referral.contractExplained',
      url: 'https://youtu.be/9tt5nqZS2RM',
    },
    {
      label: 'Referral.paymentRequest',
      url: 'https://youtu.be/9MfwMAu88Rc',
    },
  ],
};

type ReferralProps = {
  className?: string;
};

const Referral: React.FC<ReferralProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();

  const [isModalOpen, setIsOpenModal] = useState(false);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState<string>();
  const [isCreateReferralInProgress, setIsCreateReferralInProgress] =
    useState(false);
  const [referralCode, setReferralCode] = useState<string>();

  const classes = classNames('anys-referral', className);

  const inviteCopyText = useMemo(
    () =>
      `Check this out, this could totally be the next big thing. Loads of folks are hopping on this—it's a killer idea. \n\n Explainer video: ${selectedVideoUrl} \n\n Link: ${window.location.origin}/auth?referralCode=${referralCode}`,
    [referralCode, selectedVideoUrl],
  );

  const openModal = useCallback(() => {
    setIsOpenModal(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsOpenModal(false);
  }, []);

  const createReferral = useCallback(async () => {
    setIsCreateReferralInProgress(true);
    try {
      const {
        data: { code },
      } = await api.referral.ensureReferralCode();

      setReferralCode(code);
    } catch (error) {
      showToast('error', t('General.error'));
    } finally {
      setIsCreateReferralInProgress(false);
    }
  }, [t]);

  return (
    <div className={classes}>
      <div className="anys-referral__title">{t('Referral.title') + '!'}</div>
      <p>
        <Trans
          i18nKey="Referral.description"
          components={{
            b: <b />,
          }}
        />
      </p>

      <Button type="button" onClick={openModal}>
        {t('Referral.cta')}
      </Button>

      <p>
        <Trans
          i18nKey="Referral.ctaNote"
          components={{
            b: <b />,
          }}
        />
      </p>

      <div className="anys-referral__title">{t('General.videos')}</div>
      <ul className="anys-referral__videos">
        {Object.keys(VIDEOS).map((group, i) => (
          <li key={i}>
            <p>{t(`General.${group}`)}</p>
            {VIDEOS[group].map((video, i) => (
              <div key={i}>
                <a
                  href={video.url}
                  className="anys-custom-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  <GradientText gradientType="purple">
                    {t(video.label)}
                  </GradientText>
                </a>
              </div>
            ))}
          </li>
        ))}
      </ul>

      <Modal
        addSearchParams={false}
        modalName="referral-video"
        type="no-action"
        open={isModalOpen}
        onClose={closeModal}
        title={t('Referral.title') + '!'}
      >
        {referralCode ? (
          <p style={{ whiteSpace: 'pre-line' }}>{inviteCopyText}</p>
        ) : (
          <>
            <div>{`${t('General.selectVideo')}:`}</div>
            <ul className="anys-referral__videos">
              {Object.keys(VIDEOS).map((group, i) => (
                <li key={i}>
                  <p style={{ marginTop: 0 }}>{t(`General.${group}`)}</p>
                  {VIDEOS[group].map((video, i) => (
                    <button
                      key={i}
                      style={{ paddingTop: 3, paddingBottom: 3 }}
                      type="button"
                      onClick={() => setSelectedVideoUrl(video.url)}
                      className={classNames({
                        selected: video.url === selectedVideoUrl,
                      })}
                    >
                      <GradientText>{t(video.label)}</GradientText>
                    </button>
                  ))}
                </li>
              ))}
            </ul>
          </>
        )}

        {referralCode ? (
          <CopyToClipboard
            className="anys-referral__copy-link ncoded-button ncoded-button--primary ncoded-button--solid"
            contentToCopy={inviteCopyText}
          >
            {t('General.copyLink')}
          </CopyToClipboard>
        ) : (
          <Button
            type="button"
            onClick={createReferral}
            disabled={isCreateReferralInProgress || !selectedVideoUrl}
            className="anys-referral__create-link"
          >
            {t('General.createLink')}
          </Button>
        )}
      </Modal>
    </div>
  );
};

export default Referral;
