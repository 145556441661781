import React from 'react';
import classNames from 'classnames';

import './GradientText.styles.scss';

type GradientTextProps = {
  className?: string;
  gradientType?: 'purple' | 'pink';
};

const GradientText: React.FC<React.PropsWithChildren<GradientTextProps>> = (
  props,
) => {
  const { children, className, gradientType = 'purple' } = props;

  const classes = classNames(
    'anys-gradient-text',
    { 'anys-gradient-text--purple': gradientType === 'purple' },
    { 'anys-gradient-text--pink': gradientType === 'pink' },
    className,
  );

  return <span className={classes}>{children}</span>;
};

export default GradientText;
