import React from 'react';
import classNames from 'classnames';
import { NonImageFile } from 'models/User';
import { useTranslation } from 'react-i18next';
import FileItem from 'components/FileItem';

import './FilesPreview.styles.scss';

type FilesPreviewProps = {
  className?: string;
  files: NonImageFile[];
};

const FilesPreview: React.FC<FilesPreviewProps> = (props) => {
  const { className, files } = props;

  const { t } = useTranslation();

  const classes = classNames('anys-files-preview', className);

  const hasFiles = !!files?.length;

  const noFilesMsg = t('General.noFiles');

  return (
    <div className={classes}>
      {hasFiles ? (
        <ul>
          {files.map((file) => (
            <li key={file.id}>
              <FileItem file={file} />
            </li>
          ))}
        </ul>
      ) : (
        noFilesMsg
      )}
    </div>
  );
};

export default FilesPreview;
