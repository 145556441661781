import React, { useCallback, useState } from 'react';
import confirm from 'modules/confirm';
import showToast from 'modules/showToast';
import { Trans, useTranslation } from 'react-i18next';
import chatSessions from 'api/chat/chatSessions';
import { ChatSession } from 'models/Chat';

const useChatItemDelete = (
  setItems: React.Dispatch<React.SetStateAction<ChatSession[]>>,
) => {
  const { t } = useTranslation();

  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = useCallback(
    async (idToDelete: number, chatParticipantName: string) => {
      if (
        !(await confirm({
          title: t('General.removeEntity', {
            entity: t('General.chat').toLowerCase(),
          }),
          description: (
            <div>
              <Trans
                i18nKey="Chat.removeChatQuestion"
                values={{ user: chatParticipantName }}
                components={{ span: <span className="fw-600" /> }}
              />

              <div className="mt-16">{t('Chat.removeChatIrreversible')}</div>
            </div>
          ),
          confirmContent: t('General.remove'),
        }))
      )
        return;

      setIsDeleting(true);

      try {
        await chatSessions.removeChatSession(idToDelete);

        showToast(
          'success',
          t('General.entityRemoved', { entity: t('General.chat') }),
        );

        setItems((oldItems) =>
          oldItems.filter((item) => idToDelete !== item.id),
        );
      } catch (error) {
        showToast('error', t('General.error'));
      } finally {
        setIsDeleting(false);
      }
    },
    [setItems, t],
  );

  return { isDeleting, handleDelete };
};

export default useChatItemDelete;
