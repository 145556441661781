import React, { useId } from 'react';

type IconProps = React.SVGProps<SVGSVGElement> & {
  showGradient?: boolean;
};

export default ({ showGradient = true, ...props }: IconProps) => {
  const lgId1 = useId();
  const lgId2 = useId();

  return (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" {...props}>
      <path
        d="M4.599 9V5.44643C4.59787 4.34487 5.01593 3.28219 5.77203 2.46469C6.52814 1.6472 7.56834 1.1332 8.6907 1.0225C9.81306 0.911793 10.9375 1.21227 11.8458 1.8656C12.754 2.51894 13.3745 3.37624 13.599 4.45577"
        stroke={showGradient ? `url(#${lgId1})` : 'currentColor'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2222 9.00256H2.77778C1.79594 9.00256 1 9.81659 1 10.8207V17.1844C1 18.1885 1.79594 19.0026 2.77778 19.0026H15.2222C16.2041 19.0026 17 18.1885 17 17.1844V10.8207C17 9.81659 16.2041 9.00256 15.2222 9.00256Z"
        stroke={showGradient ? `url(#${lgId2})` : 'currentColor'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id={lgId1}
          x1="4.599"
          y1="9"
          x2="13.7552"
          y2="8.81706"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A761F5" />
          <stop offset="1" stopColor="#50A8FA" />
        </linearGradient>
        <linearGradient
          id={lgId2}
          x1="1"
          y1="19.0026"
          x2="17.271"
          y2="18.5402"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A761F5" />
          <stop offset="1" stopColor="#50A8FA" />
        </linearGradient>
      </defs>
    </svg>
  );
};
