import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import api from '../api';
import storageService, { STORAGE_KEYS } from './storageService';

export default {
  async requestPermission() {
    if (!('Notification' in window && 'permission' in Notification)) {
      throw new Error('Your browser does not support Notification API.');
    }

    const vapidKey = process.env.REACT_APP_GOOGLE_FCM_VAPID_KEY;

    if (!vapidKey) {
      throw new Error('Could not request webpush permission.');
    }

    try {
      const firebaseApp = initializeApp({
        apiKey: 'AIzaSyBq8XzLfCLF_FKTHzszJ-KrNazT5H4R6cQ',
        authDomain: 'anyservice-dev.firebaseapp.com',
        projectId: 'anyservice-dev',
        storageBucket: 'anyservice-dev.appspot.com',
        messagingSenderId: '12743048507',
        appId: '1:12743048507:web:d03e22cf4414389ebf0b1e',
      });
      const messaging = getMessaging(firebaseApp);

      const token = await getToken(messaging, { vapidKey });

      await api.notification.notificationSubscription.subscribe(token);

      storageService.setItem(STORAGE_KEYS.WEB_PUSH_TOKEN, token);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('An error occurred while retrieving token. ', e);
    }
  },
  async unsubscribe() {
    const token = storageService.getItem(STORAGE_KEYS.WEB_PUSH_TOKEN);

    if (!token || typeof token !== 'string') {
      return;
    }

    storageService.removeItem(STORAGE_KEYS.WEB_PUSH_TOKEN);

    try {
      await api.notification.notificationSubscription.unsubscribe(token);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('An error occurred while unsubscribing the FCM token. ', e);
    }
  },
};
