import { useCallback, useEffect, useRef, useState } from 'react';
import { Skill, SkillGroupWithSkills } from 'models/Skills';
import api from 'api';
import { useTranslation } from 'react-i18next';
import showToast from 'modules/showToast';
import { AxiosError } from 'axios';
import { OptionValue } from 'ncoded-component-library/build/components/molecules/Select/Select.component';

const useGetSkillGroups = () => {
  const { t } = useTranslation();
  const called = useRef<boolean>(false);
  const [skillGroups, setSkillGroups] = useState<SkillGroupWithSkills[]>([]);
  const [skillOptions, setSkillOptions] = useState<OptionValue[]>([]);
  const [allSkills, setAllSkills] = useState<Skill[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getSkillGroupsByJobSkills = useCallback(async () => {
    called.current = true;
    setIsLoading(true);
    try {
      const res = await api.skillGroup.getSkillGroupsWithJobsPostSkills();
      const groups: SkillGroupWithSkills[] = res?.data?.skillGroups || [];
      const allSkills = [].concat(
        ...groups.map((skillGroup) => skillGroup.skills),
      );

      setAllSkills(allSkills);
      setSkillOptions(
        allSkills.map((skill) => ({
          value: skill.id,
          label: skill.name,
        })),
      );
      setSkillGroups(groups);
    } catch (error) {
      const err = error as AxiosError;

      showToast(
        'error',
        t('General.error'),
        err?.response?.data?.error?.message,
      );
    } finally {
      setIsLoading(false);
    }
  }, [t]);

  useEffect(() => {
    if (called.current) {
      return;
    }

    getSkillGroupsByJobSkills();
  }, [getSkillGroupsByJobSkills]);

  return { skillGroups, allSkills, isLoading, skillOptions } as const;
};

export default useGetSkillGroups;
