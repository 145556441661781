import React, { useMemo } from 'react';
import classNames from 'classnames';
import { BooleanString, Job, JobPost } from 'models/Job';
import { useTranslation } from 'react-i18next';
import DeadlinePolicy from '../../../../router/subrouters/JobPost/pages/CreateEditJobPost/components/DeadlinePolicy';

import './DeadlinePolicyPreview.styles.scss';
import { getDeadlinePolicyChanges, isPendingChanges } from 'utils/job-changes';
import ToolTip from 'components/ToolTip';
import JobChanges from 'components/JobChanges';
import WarningIcon from 'icons/Warning.icon';
import ReactPlayer from 'react-player';

const mockFormApi: any = () => ({});
const mockLockButtonFn = () => <span />;

type DeadlinePolicyPreviewProps = {
  className?: string;
  jobEndDate: Date;
} & Pick<JobPost, 'deadlines'> &
  Pick<Job, 'prevVersion'> &
  Required<Pick<Job, 'versionState' | 'isLockedDeadlinePolicy'>>;

const DeadlinePolicyPreview: React.FC<DeadlinePolicyPreviewProps> = (props) => {
  const {
    className,
    deadlines,
    prevVersion,
    versionState,
    isLockedDeadlinePolicy,
    jobEndDate,
  } = props;

  const { t } = useTranslation();

  const classes = classNames('anys-deadline-policy-preview', className);

  const messages = useMemo(
    () => ({
      deadlinePolicy: t('DeadlinePolicy.title'),
      noDeadlinePolicy: t('General.noDeadlinePolicy'),
    }),
    [t],
  );

  const hasDeadlines = !!deadlines?.length;

  const deadlineData: Pick<JobPost<'form'>, 'deadlines'> & {
    hasDeadlinePolicy: BooleanString;
  } = useMemo(
    () => ({
      deadlines: deadlines?.map((d) => ({
        ...d,
        fromDate: d.fromDate ? new Date(d.fromDate) : null,
        toDate: d.toDate ? new Date(d.toDate) : null,
      })),
      hasDeadlinePolicy: `${hasDeadlines}`,
    }),
    [deadlines, hasDeadlines],
  );

  const deadlineChanges =
    prevVersion && isPendingChanges(versionState)
      ? getDeadlinePolicyChanges(
          t,
          jobEndDate,
          {
            hasDeadlinePolicy: `${prevVersion.hasDeadlinePolicy}`,
            deadlines: prevVersion.deadlines?.map((d) => ({
              ...d,
              fromDate: d.fromDate ? new Date(d.fromDate) : null,
              toDate: d.toDate ? new Date(d.toDate) : null,
            })),
          },
          deadlineData,
          prevVersion.isLockedDeadlinePolicy,
          isLockedDeadlinePolicy,
        )
      : null;

  return hasDeadlines ? (
    <DeadlinePolicy
      className={classes}
      // Pass hardcoded data to render
      // deadline policy with correct values
      // isEdit=false is needed
      isEdit={false}
      hasNoEndDate={false}
      formApi={mockFormApi}
      isPricingByProject={false}
      valuesFromForm={deadlineData}
      jobEndDate={null}
      getLockButton={mockLockButtonFn}
      prevVersion={prevVersion}
      versionState={versionState}
      isLockedDeadlinePolicy={isLockedDeadlinePolicy}
    />
  ) : (
    <section>
      <div className="anys-deadline-policy-preview__title">
        <h2 className="flex ac">
          {messages.deadlinePolicy}
          {deadlineChanges ? (
            <ToolTip t={t} icon={WarningIcon} tooltipName="deadline-changes">
              <JobChanges
                changedFrom={deadlineChanges.changedFrom}
                changedTo={deadlineChanges.changedTo}
              />
            </ToolTip>
          ) : null}
        </h2>
        <ToolTip t={t} tooltipName="deadline-changes-tooltip">
          <ReactPlayer width="100%" url="https://youtu.be/XV_6MnmJ3PA" />
          <p style={{ whiteSpace: 'pre-line' }}>
            {t('Preview.deadlinePolicy')}
          </p>
        </ToolTip>
      </div>
      <div>{messages.noDeadlinePolicy}</div>
    </section>
  );
};

export default DeadlinePolicyPreview;
