import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ToolTip from 'components/ToolTip';
import { FormApi } from 'final-form';
import { Skill } from 'models/Skills';
import Card from 'components/Card';
import { Button, Loader } from 'ncoded-component-library';
import classNames from 'classnames';
import Badge from 'components/Badge';
import { Field } from 'react-final-form';
import formValidators from 'utils/formValidators';
import MultipleSelectField from 'components/MultipleSelectField';
import { Job, JobPost } from 'models/Job';
import SelectField from 'components/SelectField';
import useCallbackRef from 'hooks/useCallbackRef';
import { ModalRef } from 'components/Modal';
import CreateNewSkillModal from 'components/CreateNewSkillModal';
import { getSkillsChanges, isPendingChanges } from 'utils/job-changes';
import WarningIcon from 'icons/Warning.icon';
import JobChanges from 'components/JobChanges';

import './AddSkillsField.styles.scss';
import api from '../../../../../../../api';

type AddSkillsFieldProps = {
  className?: string;
  formApi: FormApi<JobPost<'form'>> | any;
  skills: Skill[];
  selectedSkills?: string[];
  isLoadingSkills?: boolean;
  mainSkill?: string;
  tooltip?: string;
  disabled?: boolean;
  hasMainSkillInput?: boolean;
} & Pick<Job, 'prevVersion' | 'versionState'>;

const AddSkillsField: React.FC<AddSkillsFieldProps> = (props) => {
  const {
    className,
    formApi,
    mainSkill,
    selectedSkills = [],
    skills,
    isLoadingSkills,
    tooltip,
    disabled,
    hasMainSkillInput = true,
    prevVersion,
    versionState,
  } = props;

  const { t } = useTranslation();

  const [createNewSkillModal, createNewSkillModalRef] =
    useCallbackRef<ModalRef>(null);

  const classes = classNames('anys-add-skills-field', className);

  const messages = useMemo(
    () => ({
      title: t('General.skills'),
      addSkill: t('General.addSkill'),
      fetchingSkills: t('General.fetchingSkills'),
      mainSkill: t('General.mainSkill'),
      noAddedSkills: t('Skills.noAddedSkills'),
      mainSkillRequired: t('Skills.mainSkillRequired'),
      selectSkill: t('NeedProvideFilters.selectSkill'),
      selectMainSkill: t('General.selectMainSkillPlaceholder'),
      mainSkillRequirement: t('General.mainSkillRequirement'),
    }),
    [t],
  );

  const hasSelectedSkills = selectedSkills.length > 0;
  const selectedSkillsAsOptions = useMemo(
    () =>
      selectedSkills?.length
        ? selectedSkills.map((skillName) => ({
            label: skillName,
            value: skillName,
          }))
        : [],
    [selectedSkills],
  );

  const skillsWithMainAsFirst = useMemo(() => {
    if (!mainSkill) return selectedSkills;

    return [
      mainSkill,
      ...selectedSkills.filter((skill) => skill !== mainSkill),
    ];
  }, [mainSkill, selectedSkills]);

  const skillsChanges =
    prevVersion && isPendingChanges(versionState)
      ? getSkillsChanges(
          t,
          {
            mainSkill: prevVersion.mainSkill.name,
            skills: prevVersion.skills.map((skill) => skill.name),
          },
          { mainSkill, skills: selectedSkills },
        )
      : null;

  const onRemoveSkill = useCallback(
    (skillName: string, isMainSkill: boolean) => {
      if (isMainSkill) formApi.change('mainSkill', null);

      formApi.change(
        'skills',
        selectedSkills.filter((selectedSkill) => selectedSkill !== skillName),
      );
    },
    [formApi, selectedSkills],
  );

  const handleResetSkills = useCallback(() => {
    formApi.batch(() => {
      formApi.change('skills', []);
      formApi.change('mainSkill', null);
    });
  }, [formApi]);

  const handleNewSkill = useCallback(
    (newSkill: string) => {
      formApi.change('skills', [...selectedSkills, newSkill]);
      skills.push({
        name: newSkill,
        status: 'Active',
        id: -1,
      });
    },
    [formApi, selectedSkills, skills],
  );

  const onChange = useCallback(
    (values: string[]) => {
      if (!selectedSkills?.length && !mainSkill && hasMainSkillInput)
        formApi.change('mainSkill', values[0]);
    },
    [formApi, hasMainSkillInput, mainSkill, selectedSkills?.length],
  );

  return (
    <Card
      cardTitle={
        <div className="anys-add-skills-field__title">
          {messages.title}
          {skillsChanges ? (
            <ToolTip
              t={t}
              tooltipName="skills-changes"
              icon={WarningIcon}
              className="anys-add-skills-field__title__changes"
            >
              <JobChanges
                changedFrom={skillsChanges.changedFrom}
                changedTo={skillsChanges.changedTo}
              />
            </ToolTip>
          ) : null}
          {tooltip && (
            <ToolTip t={t} tooltipName="skills-tooltip">
              <p style={{ whiteSpace: 'pre-line' }}>{tooltip}</p>
            </ToolTip>
          )}
        </div>
      }
      className={classes}
    >
      {isLoadingSkills && (
        <Loader inline overlay={false} text={messages.fetchingSkills} />
      )}
      <div className="anys-add-skills-field__content">
        <Field
          className="anys-add-skills-field__content__skills"
          component={MultipleSelectField}
          asyncFunc={async (search: string) => {
            const { data } = await api.skill.getSkills(search);
            return data.skills;
          }}
          mapFunction={(option: Skill) => ({
            value: option.name,
            label: option.name,
          })}
          name="skills"
          hideTags
          label={messages.addSkill}
          placeholder={messages.selectSkill}
          icon={<></>}
          searchable
          onReset={handleResetSkills}
          onChange={onChange}
          disabled={disabled}
        />
        <div className="anys-add-skills-field__content__new-skill">
          {t('Skills.didntFindSkill')}
          <Button
            className="anys-add-skills-field__content__new-skill__create-button"
            variant="link"
            styleType="secondary"
            type="button"
            onClick={() => createNewSkillModal.open()}
          >
            <span>{t('General.createIt')}</span>
          </Button>
        </div>

        {hasSelectedSkills ? (
          <div className="anys-add-skills-field__content__skills-group">
            {skillsWithMainAsFirst.map((skillName, i) => {
              return (
                <Badge
                  key={`${skillName}-${i}`}
                  onClick={() =>
                    onRemoveSkill(skillName, mainSkill === skillName)
                  }
                  clickDisabled={disabled}
                  className={classNames(
                    'anys-add-skills-field__content__skills-group__skill',
                    {
                      'anys-add-skills-field__content__skills-group__skill--main':
                        mainSkill === skillName,
                    },
                  )}
                >
                  {skillName}
                </Badge>
              );
            })}
          </div>
        ) : (
          <div className="anys-add-skills-field__no-skills">
            {messages.noAddedSkills}
          </div>
        )}

        {hasMainSkillInput && (
          <>
            <SelectField
              className="anys-add-skills-field__content__main-skill-select"
              name="mainSkill"
              options={selectedSkillsAsOptions}
              label={messages.mainSkill}
              placeholder={messages.selectMainSkill}
              disabled={!selectedSkills || !selectedSkills.length || disabled}
              validate={formValidators.required(messages.mainSkillRequired)}
            />
            {!hasSelectedSkills && (
              <span className="anys-add-skills-field__content__main-skill-note">
                {messages.mainSkillRequirement}
              </span>
            )}{' '}
          </>
        )}
      </div>
      <CreateNewSkillModal
        modalRef={createNewSkillModalRef}
        onClose={() => createNewSkillModal.close()}
        handleNewSkill={handleNewSkill}
      />
    </Card>
  );
};

export default AddSkillsField;
