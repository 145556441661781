import React, { useCallback, useMemo, useRef } from 'react';
import classNames from 'classnames';
import Input, { InputProps } from 'components/Input';
import useCallbackRef from 'hooks/useCallbackRef';
import utils from 'utils';
import ClockIcon from 'icons/Clock.icon';

import './TimeInput.styles.scss';

export type TimePeriod = 'am' | 'pm';

const checkIfTimeInputSupported = () => {
  const input = document.createElement('input');
  input.setAttribute('type', 'time');

  // Time input when unsupported defaults to text
  return input.type === 'time';
};

const isSingleDigitNumber = (num: string | number) => +num < 10;

export const formatHours24To12 = (
  hours: string,
  //for example insted of 05:00 if you set true it will be 5:00
  withoutZeroOnStart = false,
) => {
  if (!hours) return '';

  if (hours === '00') return '12';

  const hoursInAMPM = +hours === 24 || +hours === 12 ? 12 : +hours % 12;

  // If the first time that we type in the input is a number
  // that isnt 1, we should consider it as a second part of the hour input.
  // (e.g. User types in '5', convert it to '05')
  if (isSingleDigitNumber(hoursInAMPM))
    return withoutZeroOnStart ? hoursInAMPM : `0${hoursInAMPM}`;

  return `${hoursInAMPM}`;
};

export const formatHours12To24 = (hours: string | number) => {
  const isEmpty = hours === null || hours === undefined;
  // We don't want to catch 0
  if (isEmpty || hours === '') return '';

  const actualHours = +hours > 12 ? 12 : +hours < 0 ? 0 : +hours;

  const hoursIn24 = actualHours && actualHours + 12;

  if (hoursIn24 === 24) return '00';

  return `${hoursIn24}`;
};

export const formatMinutes = (minutes: string | number) => {
  const isEmpty = minutes === null || minutes === undefined;

  if (isEmpty || minutes === '') return '';

  const actualMinutes = +minutes > 59 ? 59 : +minutes < 0 ? '00' : minutes;

  if (isSingleDigitNumber(actualMinutes)) return `0${+actualMinutes}`;

  return `${actualMinutes}`;
};

const TEXT_INPUT_PROPS: React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> = {
  type: 'text',
  placeholder: '--',
  autoComplete: 'off',
  formNoValidate: true,
};

const isTimeInputSupported = checkIfTimeInputSupported();

export type TimeInputProps = Omit<
  InputProps,
  'inputDescription' | 'onChange'
> & {
  isPlainStyle?: boolean;
  hasIcon?: boolean;
  value: string;
  iconClassName?: string;
  customInputRef?:
    | React.MutableRefObject<HTMLDivElement>
    | ((el: HTMLDivElement) => void);
  icon?: React.ReactNode;
  onChange: (time: string) => void;
};

const TimeInput: React.FC<TimeInputProps> = (props) => {
  const {
    className,
    inputClassName,
    iconClassName,
    isPlainStyle,
    hasError,
    value,
    icon = <ClockIcon />,
    hasIcon,
    customInputRef,
    disabled,
    onChange,
    onFocus,
    onBlur,
    ...restOfProps
  } = props;

  const [nativeTimeInputRef, nativeTimeInputRefCallback] =
    useCallbackRef<HTMLInputElement>();
  const hoursInputRef = useRef<HTMLInputElement>();
  const minutesInputRef = useRef<HTMLInputElement>();
  const periodInputRef = useRef<HTMLInputElement>();

  const classes = classNames('anys-time-input', className);

  const inputClasses = classNames(
    'anys-time-input__input--native',
    inputClassName,
  );

  const hasValue = value && typeof value === 'string';

  const { selectedHours, selectedMinutes, selectedPeriod } = useMemo(() => {
    if (!hasValue)
      return { selectedHours: '', selectedMinutes: '', selectedPeriod: '' };

    const [hours, minutes] = value.split(':');

    let period: TimePeriod = 'am';

    if (+hours >= 12) {
      period = 'pm';
    }

    return {
      selectedHours: hours,
      selectedMinutes: minutes,
      selectedPeriod: period,
    };
  }, [hasValue, value]);

  const displayedHours = formatHours24To12(selectedHours);
  const displayedMinutes = formatMinutes(selectedMinutes);

  const onChangeHours = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let hours = Number.parseInt(event.target.value);

      if (Number.isNaN(hours)) {
        onChange(`:${selectedMinutes || '00'}`);
        return;
      }

      const [firstHourDigit, secondHourDigit, thirdHourDigit] =
        event.target.value;

      const is12InsideInput = firstHourDigit + secondHourDigit === '12';

      // If we have smth. like '125' inside the input,
      // it means we entered 0 (0 = 12),
      // we should remove '12' so we have '5'
      const shouldRemove12 = is12InsideInput && !!+thirdHourDigit;

      // Take the last number ('5')
      // from input string (e.g. '125')
      if (shouldRemove12) {
        hours = +`${hours}`[2];
      }

      const isInvalidFirstPartOfHour =
        +firstHourDigit > 1 && isSingleDigitNumber(firstHourDigit);

      // If the user types in '5',
      // we know that that isn't the start of
      // and hour because we are using a 12h clock
      if (isInvalidFirstPartOfHour) {
        const h = formatHours12To24(hours);

        onChange(`${h}:${selectedMinutes || '00'}`);

        minutesInputRef.current.focus();

        return;
      }

      // we want to allow input of zero, and 1 is because 11 and 12
      if (hours > 1) {
        minutesInputRef.current.focus();
      }

      const h = formatHours12To24(hours);

      const timeString = `${h}:${selectedMinutes || '00'}`;

      onChange(timeString);
    },
    [onChange, selectedMinutes],
  );

  const onChangeMinutes = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const minutes = Number.parseInt(event.target.value);

      if (Number.isNaN(minutes)) {
        onChange(`${selectedHours}:`);

        return;
      }

      const [firstMinuteDigit] = event.target.value;

      const isInvalidFirstPartOfMinute = +firstMinuteDigit >= 6;

      if (isInvalidFirstPartOfMinute) {
        onChange(`${selectedHours}:0${firstMinuteDigit}`);

        periodInputRef.current.focus();

        return;
      }

      const m = formatMinutes(minutes);

      const timeString = `${selectedHours}:${m}`;

      onChange(timeString);
    },
    [onChange, selectedHours],
  );

  const onChangePeriod = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = event.target.value;

      let period: TimePeriod = 'am';

      // If we can find the relevant letters, we can assume
      // that the user is trying to type that period
      const isTypingPM =
        typeof inputValue === 'string' &&
        inputValue.toLowerCase().includes('p');

      let actualHours = +selectedHours;

      period = isTypingPM ? 'pm' : 'am';

      const isPM = period === 'pm';

      if (actualHours >= 12 && !isPM) {
        actualHours = +selectedHours % 12;
      } else if (actualHours < 12 && isPM) {
        actualHours = +selectedHours + 12;
      }

      const timeString = `${
        isSingleDigitNumber(actualHours) ? `0${actualHours}` : actualHours
      }:${selectedMinutes}`;

      onChange(timeString);
    },
    [onChange, selectedHours, selectedMinutes],
  );

  const onKeyDownHours = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const shouldRemove =
        utils.isBackspacePressed(event) || utils.isDeletePressed(event);

      if (shouldRemove) {
        onChange(`:${selectedMinutes}`);
      }

      if (utils.isArrowPressed(event, 'right')) {
        minutesInputRef.current.focus();
      } else if (
        utils.isArrowPressed(event, 'left') ||
        utils.isArrowPressed(event, 'down') ||
        utils.isArrowPressed(event, 'up')
      ) {
        event.preventDefault();
      }
    },
    [onChange, selectedMinutes],
  );

  const onKeyDownMinutes = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const shouldRemove =
        utils.isBackspacePressed(event) || utils.isDeletePressed(event);

      if (shouldRemove) {
        onChange(`${selectedHours}:`);
      }

      // Focus left input
      if (utils.isArrowPressed(event, 'left')) {
        hoursInputRef.current.focus();
        // Focus right input
      } else if (utils.isArrowPressed(event, 'right')) {
        periodInputRef.current.focus();
      } else if (
        utils.isArrowPressed(event, 'down') ||
        utils.isArrowPressed(event, 'up')
      ) {
        event.preventDefault();
      }
    },
    [onChange, selectedHours],
  );

  const onKeyDownPeriod = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const shouldRemove =
        utils.isBackspacePressed(event) || utils.isDeletePressed(event);

      if (shouldRemove) {
        // Toggle the hours from am/pm
        const h =
          +selectedHours >= 12 ? +selectedHours % 12 : +selectedHours + 12;

        onChange(`${h}:${selectedMinutes}`);
      }

      if (utils.isArrowPressed(event, 'left')) {
        minutesInputRef.current.focus();
      } else if (
        utils.isArrowPressed(event, 'right') ||
        utils.isArrowPressed(event, 'down') ||
        utils.isArrowPressed(event, 'up')
      ) {
        event.preventDefault();
      }
    },
    [onChange, selectedHours, selectedMinutes],
  );

  const onClickTimeInputs = useCallback(
    (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
      event.stopPropagation();

      if (isTimeInputSupported) {
        nativeTimeInputRef?.click();
      }
    },
    [nativeTimeInputRef],
  );

  const onFocusTimeInputs = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      event.target.select();

      onFocus?.(event);
    },
    [onFocus],
  );

  const onNativeOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value);
    },
    [onChange],
  );

  return (
    <div className={classes}>
      <Input
        {...restOfProps}
        className={inputClasses}
        type="time"
        inputRef={nativeTimeInputRefCallback}
        value={value}
        disabled={disabled}
        onChange={onNativeOnChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />

      <div
        className={classNames('anys-time-input__custom-input', {
          'anys-time-input__custom-input--error': hasError && !isPlainStyle,
          'anys-time-input__custom-input--plain': isPlainStyle,
          'anys-time-input__custom-input--disabled': disabled,
        })}
        onClick={() => {
          if (isTimeInputSupported) {
            nativeTimeInputRef?.click();
          }
          hoursInputRef.current?.focus();
        }}
        ref={customInputRef}
      >
        <input
          {...TEXT_INPUT_PROPS}
          className={classNames(
            'anys-time-input__input',
            'anys-time-input__input--text',
            'anys-time-input__input--text--hours',
          )}
          name="hours"
          onChange={onChangeHours}
          value={displayedHours}
          inputMode="numeric"
          pattern="[0-9]*"
          ref={hoursInputRef}
          onKeyDown={onKeyDownHours}
          onClick={(ev) => {
            hoursInputRef.current.select();
            onClickTimeInputs(ev);
          }}
          onFocus={onFocusTimeInputs}
          onBlur={onBlur}
          disabled={disabled}
        />
        <span
          className={classNames('anys-time-input__colon', {
            'anys-time-input__colon--no-value': !value,
          })}
        >
          :
        </span>
        <input
          {...TEXT_INPUT_PROPS}
          className={classNames(
            'anys-time-input__input',
            'anys-time-input__input--text',
          )}
          name="minutes"
          onChange={onChangeMinutes}
          value={displayedMinutes}
          inputMode="numeric"
          pattern="[0-9]*"
          ref={minutesInputRef}
          onKeyDown={onKeyDownMinutes}
          onClick={(ev) => {
            minutesInputRef.current.select();
            onClickTimeInputs(ev);
          }}
          onFocus={onFocusTimeInputs}
          onBlur={onBlur}
          disabled={disabled}
        />
        <input
          {...TEXT_INPUT_PROPS}
          name="period"
          value={selectedPeriod}
          onChange={onChangePeriod}
          className={classNames(
            'anys-time-input__input',
            'anys-time-input__input--text',
            'anys-time-input__input--text--period',
          )}
          ref={periodInputRef}
          pattern="(am|pm|AM|PM)"
          onKeyDown={onKeyDownPeriod}
          onClick={onClickTimeInputs}
          onFocus={onFocusTimeInputs}
          onBlur={onBlur}
          disabled={disabled}
        />
        {hasIcon && (
          <span
            className={classNames(
              'anys-time-input__custom-input__icon',
              iconClassName,
            )}
          >
            {icon}
          </span>
        )}
      </div>
    </div>
  );
};

export default TimeInput;
