import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import api from 'api';
import Stripe from 'icons/Stripe.icon';
import DropdownOptions from 'components/DropdownOptions';
import { DropdownRef } from 'ncoded-component-library/build/components/molecules/Dropdown/Dropdown.component';
import { Button } from 'ncoded-component-library';
import ElipsisIcon from 'icons/Elepsis.icon';

import './StripeConnectDetails.styles.scss';

type StripeConnectDetailsProps = {
  stripeAccountId: string;
  className?: string;
};

const StripeConnectDetails = (props: StripeConnectDetailsProps) => {
  const { stripeAccountId, className } = props;

  const [stripeAccount, setStripeAccount] = useState(null);

  const classes = classNames('anys-stripe-details', className);

  const dropdownRef = useRef<DropdownRef>();

  const getStripeAccount = useCallback(async () => {
    try {
      const { data } = await api.wallet.getStripeConnect();

      setStripeAccount(data);
    } catch (e) {
      console.error(e);
    }
  }, []);

  const handleUpdateStripe = useCallback(async () => {
    try {
      const {
        data: { onboardingLink },
      } = await api.wallet.updateStripeConnect();

      window.location.href = onboardingLink.url;
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    if (stripeAccountId) {
      getStripeAccount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripeAccountId]);

  return (
    stripeAccount && (
      <div className={classes}>
        <Stripe />

        <div className="anys-stripe-details__info">
          <div className="anys-stripe-details__info__main">
            <span className="anys-stripe-details__brand">Stripe Connect</span>
          </div>
          <div className="anys-stripe-details__expiration">
            {stripeAccountId}
          </div>
        </div>
        {stripeAccount.payouts_enabled ? (
          <div className="anys-stripe-details__connected">Connected</div>
        ) : (
          <div className="anys-stripe-details__default">
            Pending information
          </div>
        )}
        <DropdownOptions
          dropdownRef={dropdownRef}
          lockScroll={false}
          trigger={
            <Button
              className="anys-stripe-details__more-actions"
              variant="link"
              icon={<ElipsisIcon />}
            />
          }
          options={[
            {
              title: 'Update',
              key: 'update',
              onClick: () => {
                ((dropdownRef as any).current as any)?.setIsOpen(false);
                handleUpdateStripe();
              },
            },
          ]}
        />
      </div>
    )
  );
};

export default StripeConnectDetails;
