import React from 'react';
import classNames from 'classnames';
import { FormApi, SubmissionErrors } from 'final-form';
import { Form, FormProps } from 'react-final-form';
import {
  FormattedText,
  TextColor,
} from 'components/RichTextEditorField/models/EditorText';
import { TEXT_COLORS_ARRAY } from 'components/RichTextEditorField/constants';

import './ColorsForm.styles.scss';

export type ColorsFormValues = {
  color: TextColor;
  colorType: keyof Pick<FormattedText, 'backgroundColor' | 'color'>;
};

type ColorsFormProps = {
  className?: string;
  onSubmit: (
    values: ColorsFormValues,
    form: FormApi<ColorsFormValues>,
  ) => SubmissionErrors | Promise<SubmissionErrors> | void;
  initialValues: Partial<ColorsFormValues>;
} & FormProps<ColorsFormValues>;

const ColorsForm: React.FC<ColorsFormProps> = (props) => {
  const { className, initialValues, onSubmit } = props;

  const classes = classNames('anys-colors-form', className);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={(formRenderProps) => {
        const { handleSubmit, form, initialValues } = formRenderProps;

        return (
          <form className={classes} onSubmit={handleSubmit}>
            {TEXT_COLORS_ARRAY.map((color) => (
              <button
                key={color}
                type="submit"
                style={{ backgroundColor: color }}
                onClick={(e) => {
                  form.change('color', color);
                }}
                className={classNames({
                  'anys-colors-form__selected': initialValues.color === color,
                })}
              />
            ))}
          </form>
        );
      }}
    />
  );
};

export default ColorsForm;
