import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import api from 'api';
import { Job } from 'models/Job';
import { useTranslation } from 'react-i18next';
import useInboxLink from 'router/subrouters/Inbox/hooks/useInboxLink';
import showToast from 'modules/showToast';
import { AxiosError } from 'axios';

export type JobActionsProps = {
  jobCommonId: number;
  jobVersion: number;
  updateJob?: (job: Job) => void;
};

const useJobActions = (props?: JobActionsProps) => {
  const { jobCommonId, jobVersion, updateJob } = props || {};

  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const { createEntityLink } = useInboxLink();

  const [signing, setSigning] = useState(false);

  const isInbox = location.pathname?.startsWith('/inbox');

  const signJob = useCallback(
    async (commonId?: number, version?: number) => {
      setSigning(true);

      const id = jobCommonId || commonId;

      try {
        const {
          data: { job, contractCommonId },
        } = await api.job.signJob(id, jobVersion || version);

        if (contractCommonId) {
          navigate(
            isInbox
              ? createEntityLink('view', 'contract', contractCommonId)
              : `/contract/${contractCommonId}`,
            { replace: true },
          );
        } else {
          navigate(
            isInbox ? createEntityLink('view', 'job', id) : `/job/${id}`,
            { replace: true },
          );

          updateJob?.(job);
        }

        return { job, contractCommonId };
      } catch (error) {
        const err = error as AxiosError;

        showToast(
          'error',
          t('General.signError'),
          err?.response?.data?.error?.message,
        );

        console.error(error);
      } finally {
        setSigning(false);
      }
    },
    [
      createEntityLink,
      isInbox,
      jobCommonId,
      jobVersion,
      navigate,
      t,
      updateJob,
    ],
  );

  const unsignJob = useCallback(
    async (commonId?: number, version?: number) => {
      setSigning(true);

      const id = jobCommonId || commonId;

      try {
        const { data } = await api.job.unsignJob(id, jobVersion || version);

        updateJob?.(data.job);
      } catch (error) {
        const err = error as AxiosError;

        showToast(
          'error',
          t('General.unsignError'),
          err?.response?.data?.error?.message,
        );

        console.error(error);
      } finally {
        setSigning(false);
      }
    },
    [jobCommonId, jobVersion, t, updateJob],
  );

  const toggleSign = useCallback(
    async (
      hasSigned: boolean,
      commonId?: number,
      jobVersion?: number,
      callBack?: () => void,
    ) => {
      if (hasSigned) {
        await unsignJob(commonId, jobVersion);
      } else {
        await signJob(commonId, jobVersion);
      }
      callBack?.();
    },
    [signJob, unsignJob],
  );

  return {
    signing,
    signJob,
    unsignJob,
    toggleSign,
  };
};

export default useJobActions;
