import React from 'react';
import classNames from 'classnames';
import { User } from 'models/User';
import MiniAvatar from 'components/MiniAvatar';
import { formatUserDisplayName } from 'utils/user';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'ncoded-component-library';
import { formatMoney } from 'utils/currency';
import VerifiedIcon from 'icons/VerifiedProfile.icon';
import Avatar from 'components/Avatar';
import QuestionMark from 'icons/QuestionMark.icon';

import './UserListItem.styles.scss';

type UserListItemProps = {
  className?: string;
  user: User;
  selectDisabled?: boolean;
  selected?: boolean;
  isArbitrator: boolean;
  onSelectUser?: (user: User, selected: boolean) => void;
};

const UserListItem: React.FC<UserListItemProps> = (props) => {
  const {
    className,
    user,
    selected,
    selectDisabled,
    isArbitrator,
    onSelectUser,
  } = props;

  const { t } = useTranslation();

  const classes = classNames(
    'anys-user-list-item',
    {
      'anys-user-list-item--selected': selected,
    },
    className,
  );

  const viewProfile = t('General.viewProfile');

  const {
    profileImage,
    overallSkillScore,
    profession,
    anyServiceId,
    arbitratorTerms,
  } = user;
  const { averageRating = 0, numberOfReviews = 0 } = overallSkillScore || {};

  const {
    visibilityType,
    priceOption,
    currency,
    fixedPrice,
    percentOfJobValue,
    languages,
    educations,
  } = arbitratorTerms || {};

  const isHiddenArbitrator = visibilityType === 'hidden';

  const userName = formatUserDisplayName(user, isArbitrator ? '' : '-');

  const formattedFixedPrice = fixedPrice
    ? formatMoney(t, { amount: fixedPrice, currency: currency || 'USD' })
    : null;

  return (
    <li className={classes}>
      {isArbitrator ? (
        <div className="anys-user-list-item__arbitration-section">
          <Avatar
            alt={userName || ''}
            img={profileImage?.resizedUrls?.[40]}
            className={classNames('anys-user-list-item__arbitration-avatar', {
              'anys-user-list-item__arbitration-avatar--hidden':
                isHiddenArbitrator,
            })}
            fallbackNode={isHiddenArbitrator ? <QuestionMark /> : undefined}
          />

          <div className="flex grow jb column">
            {userName ? <div>{userName}</div> : null}
            <div className="anys-user-list-item__arbitration-info">
              {languages?.length ? (
                <span className="anys-user-list-item__arbitration-info__plain-text">
                  {languages[0].language}
                  <VerifiedIcon />
                </span>
              ) : null}
              {educations?.length ? (
                <span className="anys-user-list-item__arbitration-info__plain-text">
                  {`${educations[0].schoolName} ${t(
                    `General.${educations[0].degree.toLowerCase()}Short`,
                  )}`}
                  <VerifiedIcon />
                </span>
              ) : null}
              <span className="anys-user-list-item__arbitration-info__big-text">
                {priceOption === 'any' ? (
                  t('General.any')
                ) : priceOption === 'fixed' ? (
                  formattedFixedPrice
                ) : percentOfJobValue >= 0 ? (
                  <>
                    {`${percentOfJobValue}%`}
                    <span className="anys-user-list-item__arbitration-info__plain-text">
                      {t('JobForm.fromJobValue')}
                    </span>
                  </>
                ) : (
                  '-'
                )}
              </span>
            </div>
          </div>
        </div>
      ) : (
        <MiniAvatar
          userName={userName}
          profileImage={profileImage}
          rating={averageRating}
          votes={numberOfReviews}
          info={profession}
          isSkillReview
        />
      )}

      <div className="anys-user-list-item__right">
        <div className="anys-user-list-item__right__actions">
          {!isHiddenArbitrator && (
            <Link
              to={`/profile/${user.id}`}
              className="anys-user-list-item__right__actions__profile-link"
              target="_blank"
            >
              {viewProfile}
            </Link>
          )}
          {!!onSelectUser && (
            <Checkbox
              className="anys-user-list-item__right__actions__checkbox"
              value={user.id}
              checked={selected}
              disabled={selectDisabled}
              onChange={(selected) => onSelectUser(user, selected as boolean)}
            />
          )}
        </div>
        {anyServiceId && !isHiddenArbitrator ? (
          <span className="anys-user-list-item__right__id">
            <span> {t('SearchPage.userId')}</span>
            {anyServiceId}
          </span>
        ) : null}
      </div>
    </li>
  );
};

export default UserListItem;
