import React from 'react';
import { Navigate } from 'react-router-dom';
import { EnhancedRouteProps } from 'router/routes/Enhanced/Enhanced.route';
import FrequentQuestions from './pages/FrequentQuestions';
import TermAndConditions from './pages/TermAndConditions';

export default [
  {
    path: 'faq',
    element: FrequentQuestions,
  },
  {
    path: 'terms-and-conditions',
    element: TermAndConditions,
  },
  {
    index: true,
    element: FrequentQuestions,
  },
  {
    path: '',
    element: () => <Navigate to="/" replace />,
  },
] as Array<EnhancedRouteProps>;
