import { JobPost } from 'models/Job';
import { LocationType, PlaceFormat } from 'models/User';
import googleMaps from './googleMaps';

const formatSingleLocation = (
  location: Pick<PlaceFormat, 'street' | 'city' | 'country' | 'fullAddress'>,
) => {
  if (!location || !location.country) return '';

  if (location.fullAddress) return location.fullAddress;

  const { street, city, country } = location;

  const streetMaybe = street ? `${street}, ` : '';

  return `${streetMaybe}${city}, ${country}`;
};

const formatLocation = (
  typeOfService: Omit<
    Pick<JobPost, 'typeOfService'>['typeOfService'],
    'type' | 'id'
  >,
  emptyContent = '-',
) => {
  const { locationType, locations } = typeOfService || {};

  if (!locationType) return emptyContent;

  switch (locationType) {
    case 'One spot': {
      if (!locations?.length) return emptyContent;

      return formatSingleLocation(locations[0]);
    }

    case 'Direction': {
      if (!locations?.length) return emptyContent;

      return `${formatSingleLocation(locations[0])} - ${formatSingleLocation(
        locations[1],
      )}`;
    }

    case 'Area': {
      if (!locations?.length) return emptyContent;

      return formatSingleLocation(locations[0]);
    }

    default:
      return emptyContent;
  }
};

const getCurrentLocationAsInputOption = (
  currentLocation: PlaceFormat,
  profileLocation: LocationType,
) => {
  if (profileLocation) {
    const { street, city, country, lat, lng } = profileLocation;

    return {
      bounds: googleMaps.locationBounds({ lat, lng }, 500),
      street,
      city,
      country,
      lat,
      lng,
    };
  }

  if (currentLocation) {
    const { bounds, street, city, country, lat, lng } = currentLocation;

    return { bounds, street, city, country, lat, lng };
  }
};

export {
  formatLocation,
  getCurrentLocationAsInputOption,
  formatSingleLocation,
};
