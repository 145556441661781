import React, { useCallback, useContext, useRef } from 'react';
import classNames from 'classnames';
import { InboxItem, InboxUrlParams } from 'models/Inbox';
import { useNavigate, useParams } from 'react-router-dom';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import { formatUserFirstLastName } from 'utils/user';
import {
  getEntityForItem,
  getEntityIdForItem,
  getEntityInboxState,
  getOtherPartyForEntity,
} from '../../utils';
import UserItem from 'components/UserItem';
import useInboxLink from '../../hooks/useInboxLink';
import useInfiniteContainer from 'hooks/useInfiniteContainer';
import { DropdownOption } from 'components/DropdownOptions/DropdownOptions.component';
import { useTranslation } from 'react-i18next';
import TrashIcon from 'icons/Trash.icon';
import UserItemLoading from 'components/LoadPlaceholders/UserItem.loading';
import useMatchMedia from 'hooks/useMatchMedia';
import useEvent from 'hooks/useEvent';
import { JobState as JobStateModel } from 'models/Job';
import { Checkbox } from 'ncoded-component-library';
import NotificationBadge from 'components/NotificationBadge';
import NotificationsContext from 'providers/Notifications/Notifications.context';
import { getTranslationForJobState } from 'utils/job';

import './InboxItemList.styles.scss';
import './InboxItemList.styles.responsive.scss';

const getStateFromSigns = (numberOfSigns: number): JobStateModel => {
  if (!numberOfSigns) return 'Ad';

  if (numberOfSigns === 1) return 'Offer';

  return 'Contract';
};

type InboxItemListProps = {
  className?: string;
  items: InboxItem[];
  loading: boolean;
  isDeleting: boolean;
  isManagingItems: boolean;
  selectedInboxItems: number[];
  setSelectedInboxItems: React.Dispatch<React.SetStateAction<number[]>>;
  handleDelete: (
    idsToDelete: number[],
    state: JobStateModel,
  ) => Promise<boolean>;
  onContainerScrolled: () => void;
};

const InboxItemList: React.FC<InboxItemListProps> = (props) => {
  const {
    className,
    items,
    loading,
    isDeleting,
    isManagingItems,
    selectedInboxItems,
    setSelectedInboxItems,
    handleDelete,
    onContainerScrolled,
  } = props;

  const { t } = useTranslation();

  const isTabletLandscape = useMatchMedia('isTabletLandscape');

  const { currentUser } = useContext(CurrentUserContext);
  const { getLastNotifForInboxItem, getInboxItemNotifCount } =
    useContext(NotificationsContext);

  const inboxItemsRef = useRef<HTMLUListElement>();

  const params = useParams<InboxUrlParams>();
  const navigate = useNavigate();

  const { createEntityLink, linkWihoutInboxItem } = useInboxLink();

  const { onScroll, loaderEl } = useInfiniteContainer({
    container: isTabletLandscape ? window : inboxItemsRef.current,
    onScroll: onContainerScrolled,
    loader: <UserItemLoading length={3} />,
    loading,
  });

  useEvent(window, 'scroll', onScroll, undefined, isTabletLandscape);

  const classes = classNames('anys-inbox-item-list', className);

  const onDeleteItem = useCallback(
    async (inboxItemId: number, stateWithoutContractStatus: JobStateModel) => {
      const hasDeleted = await handleDelete(
        [inboxItemId],
        stateWithoutContractStatus,
      );

      // If we have the deleted item open, close it
      if (hasDeleted && inboxItemId === +params.inboxItemId) {
        navigate(linkWihoutInboxItem, { replace: true });
      }
    },
    [handleDelete, linkWihoutInboxItem, navigate, params.inboxItemId],
  );

  return (
    <div className={classes}>
      {items?.length ? (
        <ul ref={inboxItemsRef} onScroll={onScroll}>
          {items.map((item) => {
            const {
              id: inboxItemId,
              mainSkill,
              type,
              state: itemState,
              numberOfSigns,
              notifications,
            } = item;

            const entity = getEntityForItem(item);
            const entityId = getEntityIdForItem(item);

            const mainSkillName = mainSkill?.name;

            const otherUser = getOtherPartyForEntity(currentUser, item);
            const fullName = formatUserFirstLastName(otherUser);

            const isItemSelected = inboxItemId === +params.inboxItemId;

            const entityState = getEntityInboxState(entity, type);

            const state =
              itemState === 'Ad'
                ? getStateFromSigns(numberOfSigns)
                : itemState || entityState || getStateFromSigns(numberOfSigns);

            const stateWithoutContractStatus = getEntityInboxState(
              entity,
              type,
              false,
            );

            const isDraft = state === 'Draft';
            const isContractDone = state === 'Done';

            const isCurrentUserOtherUser = otherUser?.id === currentUser?.id;

            const link = createEntityLink(
              isDraft ? 'edit' : 'view',
              type,
              entityId,
              inboxItemId,
              isCurrentUserOtherUser ? null : otherUser?.id,
            );

            const imgUrl =
              otherUser?.profileImage?.resizedUrls?.[80] ||
              otherUser?.profileImage?.url;

            // If latest notification is same as fetched item notifications
            const ignoreSocketNotifs =
              notifications?.[notifications?.length - 1]?.id ===
              getLastNotifForInboxItem(inboxItemId)?.id;

            const allNotificationsCount = ignoreSocketNotifs
              ? notifications?.length
              : (notifications?.length || 0) +
                getInboxItemNotifCount(inboxItemId);

            const timelineString =
              entity?.type === 'Provide'
                ? t('General.provideTimeline')
                : t('General.needTimeline');

            const topPartContent = entity?.title;

            const isJobOnTimeline =
              entity && 'sendToTimeline' in entity && entity.sendToTimeline;

            const bottomPartContent =
              isCurrentUserOtherUser && isJobOnTimeline
                ? timelineString
                : mainSkillName;

            // If it's not a contract, or if the contract is done
            const canDeleteEntity = !item.contract || isContractDone;

            // Hide menu when we are managing items
            // to give room for checkbox
            const menuOptions: DropdownOption[] =
              !isManagingItems && canDeleteEntity
                ? [
                    {
                      key: 'remove',
                      title: (
                        <span className="anys-inbox-item-list__remove-item">
                          <TrashIcon fill="currentColor" />
                          {t('General.remove')}
                        </span>
                      ),
                      disabled: isDeleting || isManagingItems,
                      onClick: () => {
                        onDeleteItem(
                          inboxItemId,
                          stateWithoutContractStatus as JobStateModel,
                        );
                      },
                    },
                  ]
                : null;

            const onCheckboxChange = () => {
              setSelectedInboxItems((oldItems) => {
                if (oldItems.includes(inboxItemId))
                  return oldItems.filter((itemId) => itemId !== inboxItemId);

                return [...oldItems, inboxItemId];
              });
            };

            return (
              <li key={inboxItemId}>
                <UserItem
                  linkProps={{ to: link }}
                  imgUrl={!isDraft && imgUrl}
                  imgAlt={fullName}
                  isSelected={isItemSelected}
                  topPartContent={topPartContent}
                  bottomPartContent={bottomPartContent}
                  menuOptions={menuOptions}
                  prefixContent={
                    isManagingItems &&
                    canDeleteEntity && (
                      <Checkbox
                        onChange={onCheckboxChange}
                        checked={selectedInboxItems.includes(inboxItemId)}
                        className="anys-inbox-item-list__checkbox"
                      />
                    )
                  }
                >
                  {state && (
                    <span className="anys-inbox-item-list__state">
                      {getTranslationForJobState(t, state)}
                    </span>
                  )}
                </UserItem>
                {allNotificationsCount ? (
                  <NotificationBadge
                    count={allNotificationsCount}
                    className="anys-inbox-item-list__notif-badge"
                  />
                ) : null}
              </li>
            );
          })}
          {loading && <li>{loaderEl}</li>}
        </ul>
      ) : (
        loaderEl
      )}

      {!items?.length && !loading && t('General.noItems')}
    </div>
  );
};

export default InboxItemList;
