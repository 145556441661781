import { SkillGroup, SkillGroupWithSkills } from 'models/Skills';
import httpClient from '../httpClient';

function getSkillGroups() {
  return httpClient.get<{ skillGroups: SkillGroup[] }>('skill-groups');
}

function getSkillGroupsWithJobsPostSkills() {
  return httpClient.get<{ skillGroups: SkillGroupWithSkills[] }>(
    'skill-groups/jobs',
  );
}

function getSkillGroupsWithReviewSkills() {
  return httpClient.get<{ skillGroups: SkillGroupWithSkills[] }>(
    'skill-groups/reviews',
  );
}

export default {
  getSkillGroups,
  getSkillGroupsWithJobsPostSkills,
  getSkillGroupsWithReviewSkills,
};
