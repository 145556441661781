import React, { useId } from 'react';

type IconProps = React.SVGProps<SVGSVGElement> & { showGradient?: boolean };

const Plus: React.FC<IconProps> = ({ showGradient, ...props }) => {
  const lgId = useId();

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M15 8.0002H1M8.0002 15V1"
        stroke={showGradient ? `url(#${lgId})` : 'currentColor'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <linearGradient
        id={lgId}
        x1="14.9385"
        y1="19.4735"
        x2="24"
        y2="19.4735"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF7BAC" />
        <stop offset="1" stopColor="#D142F4" />
      </linearGradient>
    </svg>
  );
};

export default Plus;
