import React, { useCallback, useContext, useState } from 'react';
import NeedProvideCard from './components/NeedProvideCard';
import MainTemplate from 'components/templates/MainTemplate';
import NeedProvideFilters from './components/NeedProvideFilters';
import classNames from 'classnames';
import useNeedProvideSearch from 'providers/NeedProvide/hooks/useNeedProvideSearch';
import useInfiniteContainer from 'hooks/useInfiniteContainer';
import Filter from 'components/Filter';
import Pagination from 'components/Pagination';
import useEvent from 'hooks/useEvent';
import ImageLoading from 'components/LoadPlaceholders/Image.loading';
import useFilterTagDisplays from 'router/subrouters/Search/pages/Search/hooks/useFilterTagDisplays';
import FilterTag from 'router/subrouters/Search/pages/Search/components/FilterTag';
import { Button } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import useHeaderContent from 'hooks/useHeaderContent';
import AnyServiceLogoIcon from 'icons/AnyServiceLogo.icon';
import { Link } from 'react-router-dom';
import NotificationBadge from 'components/NotificationBadge';
import NotificationsContext from 'providers/Notifications/Notifications.context';
import { ReactComponent as InboxIcon } from 'icons/file-success-one.svg';
import useInViewportElObserver from 'hooks/useInViewportElObserver';
import api from 'api';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import SuggestedItems from 'router/subrouters/Search/pages/Search/components/SuggestedItems';
import { JobPost } from 'models/Job';
import Referral from 'components/Referral';
import useMatchMedia from 'hooks/useMatchMedia';
import Modal from 'components/Modal';

import './NeedProvidePage.styles.scss';
import './NeedProvidePage.styles.responsive.scss';
import useGetSkillGroups from '../../../../../hooks/useGetSkillGroups';

type NeedProvidePageProps = {
  className?: string;
};

const NeedProvidePage: React.FC<NeedProvidePageProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();

  const { notificationsCount } = useContext(NotificationsContext);
  const { currentUser } = useContext(CurrentUserContext);
  const { skillGroups, allSkills } = useGetSkillGroups();

  const [isReferralOpen, setIsReferralOpen] = useState(false);

  const isTabletLandscape = useMatchMedia('isTabletLandscape');

  useHeaderContent(
    <>
      <AnyServiceLogoIcon />
      {currentUser?.id && (
        <Button
          type="button"
          className={classNames(
            'anys-mobile-header__actions',
            'anys-need-provide-page__invite-link',
          )}
          variant="solid"
          onClick={() => setIsReferralOpen(true)}
        >
          {t('Referral.title')}
        </Button>
      )}
      <Link
        to="/inbox"
        className={classNames('anys-need-provide-page__inbox-link')}
      >
        {notificationsCount ? (
          <NotificationBadge
            count={notificationsCount}
            className="anys-need-provide-page__inbox-link__notif"
          />
        ) : null}
        <InboxIcon />
      </Link>
    </>,
    [currentUser?.id],
  );

  const {
    filters,
    items: jobPosts,
    totalPages,
    onContainerScrolled,
    loading,
    activeFiltersCount,
    clearFilters,
    favouritedPosts,
    isPhablet,
    showPaginator,
    currentPage,
    suggestedPostsPagination,
    setFavouritedPosts,
  } = useNeedProvideSearch();

  const isOnLastPage = currentPage >= totalPages;

  const { onScroll, loaderEl } = useInfiniteContainer({
    container: window,
    onScroll: onContainerScrolled,
    loader: <ImageLoading length={3} />,
    loading,
  });

  const { dependency, InViewportObserver } = useInViewportElObserver();

  const filterTagsDisplays = useFilterTagDisplays({
    skills: allSkills,
  });

  useEvent(
    window,
    'scroll',
    onScroll,
    undefined,
    isPhablet || (!isPhablet && isOnLastPage),
  );

  const classes = classNames('anys-need-provide-page', className);

  const handleTrackView = useCallback(async (trackingData: string) => {
    try {
      await api.positionBoostMetrics.trackView(trackingData);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const getJobPostItem = useCallback(
    (jobPost: JobPost, notInList?: boolean) => {
      const { id, trackingData, user } = jobPost;

      const isOwnJob = user?.id === currentUser?.id;

      const Wrapper = notInList ? 'div' : 'li';

      return (
        <Wrapper
          key={jobPost.id}
          className="anys-need-provide-page__posts__post-wrapper"
        >
          <InViewportObserver
            dependencies={[dependency]}
            callback={(isVisible) =>
              isVisible &&
              trackingData &&
              !isOwnJob &&
              handleTrackView(trackingData)
            }
          >
            <NeedProvideCard
              jobPost={jobPost}
              isFavourited={favouritedPosts[id]}
              setFavouritedPosts={setFavouritedPosts}
            />
          </InViewportObserver>
        </Wrapper>
      );
    },
    [
      InViewportObserver,
      currentUser?.id,
      dependency,
      favouritedPosts,
      handleTrackView,
      setFavouritedPosts,
    ],
  );

  return (
    <MainTemplate
      className={classes}
      asideLeft={
        <NeedProvideFilters
          skillGroups={skillGroups}
          allSkills={allSkills}
          activeFiltersCount={activeFiltersCount}
          setIsReferralOpen={setIsReferralOpen}
        />
      }
      asideRight={currentUser?.id && !isTabletLandscape ? <Referral /> : null}
    >
      <div className="anys-need-provide-page__wrapper">
        {activeFiltersCount > 0 && (
          <div className="anys-need-provide-page__selected-filters">
            <div className="anys-need-provide-page__selected-filters__badges">
              {Object.keys(filters).map((fName) =>
                fName === 'inboxItemType' ? null : (
                  <FilterTag
                    key={fName}
                    name={fName}
                    display={filterTagsDisplays[fName]}
                    hideFilterLabel
                  />
                ),
              )}
            </div>
            <Button
              variant="link"
              type="button"
              styleType="secondary"
              className="anys-need-provide-page__selected-filters__clear"
              onClick={clearFilters}
            >
              <span>{t('Filters.clear')}</span>
            </Button>
          </div>
        )}

        <div className="anys-need-provide-page__posts">
          {jobPosts?.length
            ? jobPosts.map((jp) => getJobPostItem(jp, true))
            : loaderEl}

          {showPaginator && (
            <Filter
              name="page"
              defaultValue="1"
              component={(
                props: React.ComponentProps<typeof Filter> &
                  React.ComponentProps<typeof Pagination>,
              ) => (
                <Pagination
                  {...props}
                  onChange={(value) => {
                    props.onChange(value);

                    window.scroll({ behavior: 'smooth', top: 0 });
                  }}
                />
              )}
              totalPages={totalPages}
            />
          )}

          {isOnLastPage && suggestedPostsPagination ? (
            <SuggestedItems
              title={
                <>
                  {jobPosts?.length > 0
                    ? t('General.noMoreFilteredPostsFound')
                    : t('General.noFilteredPostsFound')}
                  <br />
                  {t('General.suggestedOffersPreOffers')}
                </>
              }
              paginatedRes={suggestedPostsPagination}
              loaderEl={loaderEl}
              className={classNames(
                'anys-need-provide-page__suggested-results',
                {
                  'anys-need-provide-page__suggested-results--no-margin':
                    !jobPosts?.length,
                },
              )}
            >
              {getJobPostItem}
            </SuggestedItems>
          ) : (
            loaderEl
          )}
        </div>
      </div>

      <Modal
        type="no-action"
        modalName="referral-mobile"
        isFullscreen
        addSearchParams={false}
        open={isReferralOpen}
        onClose={() => setIsReferralOpen(false)}
        onBackClick={() => setIsReferralOpen(false)}
      >
        <Referral className="anys-need-provide-page__referral" />
      </Modal>
    </MainTemplate>
  );
};

export default NeedProvidePage;
