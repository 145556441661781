import React, { useCallback, useEffect, useState } from 'react';
import api from 'api';
import { Wallet } from 'models/Wallet';
import { Outlet, useOutletContext } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import env from 'env';

const stripePromise = loadStripe(env.STRIPE_KEY);

const WalletOutline = () => {
  const [wallet, setWallet] = useState<Wallet>();

  const getWallet = useCallback(async () => {
    try {
      const { data } = await api.wallet.getWallet();
      setWallet(data);
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    getWallet();
  }, [getWallet]);

  return (
    <Elements
      stripe={stripePromise}
      options={{
        mode: 'setup',
        currency: 'usd',
      }}
    >
      <Outlet
        context={{
          wallet,
          setWallet,
        }}
      />
    </Elements>
  );
};

export default WalletOutline;

type OutletContext = {
  wallet: Wallet;
  setWallet: React.Dispatch<React.SetStateAction<Wallet>>;
  clientSecret: string;
};

export const useWallet = () => useOutletContext<OutletContext>();
