import React, { useId } from 'react';

type IconProps = React.SVGProps<SVGSVGElement> & { showGradient?: boolean };

export default (props: IconProps) => {
  const { showGradient = true, strokeWidth = 1.75, ...restOfProps } = props;

  const gradientId = useId();

  return (
    <svg
      width="23"
      height="25"
      viewBox="0 0 23 25"
      fill="none"
      stroke={showGradient ? `url(#${gradientId})` : 'currentColor'}
      strokeWidth={strokeWidth}
      {...restOfProps}
    >
      <path
        d="M20.0418 24H3.37517C2.45469 24 1.7085 23.2949 1.7085 22.4251C1.7085 19.82 3.41883 17.4883 6.00029 16.574L6.51282 16.3925C9.86283 15.206 13.5542 15.206 16.9042 16.3925L17.4167 16.574C19.9982 17.4883 21.7085 19.82 21.7085 22.4251C21.7085 23.2949 20.9623 24 20.0418 24Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.16743 11.7421C10.7624 12.5163 12.6546 12.5163 14.2496 11.7421C15.734 11.0216 16.7975 9.70922 17.1467 8.1672L17.2398 7.75565C17.5969 6.17876 17.2155 4.53299 16.1937 3.24146L16.044 3.05233C15.0221 1.76062 13.4153 1 11.7085 1C10.0017 1 8.39494 1.76062 7.37295 3.05233L7.22332 3.24146C6.20148 4.53299 5.82007 6.17876 6.17715 7.75565L6.27034 8.1672C6.61952 9.70922 7.68304 11.0216 9.16743 11.7421Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <linearGradient
        id={gradientId}
        x1="14.9385"
        y1="19.4735"
        x2="24"
        y2="19.4735"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF7BAC" />
        <stop offset="1" stopColor="#D142F4" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2680_7580"
        x1="1"
        y1="9.16349"
        x2="17.3437"
        y2="9.16349"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF7BAC" />
        <stop offset="1" stopColor="#D142F4" />
      </linearGradient>
    </svg>
  );
};
