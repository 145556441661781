import React, { useCallback } from 'react';
import classNames from 'classnames';
import { ExtractPropTypes } from 'types';
import { Input as InputNcLib } from 'ncoded-component-library';
import ValidationError from 'components/ValidationError';

import './Input.styles.scss';

const SPECIAL_NUMBER_CHARS = ['e', 'E', '+', '-'];

export type DefaultInputProps = ExtractPropTypes<typeof InputNcLib>;

export type InputProps = {
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
  label?: React.ReactNode;
  labelDescription?: string;
  asideLabelContent?: React.ReactNode;
  isLargeLabel?: boolean;
  name: string;
  inputDescription?: React.ReactNode;
  error?: string;
  hideNumberArrows?: boolean;
  isPureNumberInput?: boolean;
  required?: boolean;
  inputRef?: React.Ref<HTMLInputElement>;
} & DefaultInputProps;

const Input: React.FC<InputProps> = (props) => {
  const {
    className,
    labelClassName,
    inputClassName,
    label,
    labelDescription,
    asideLabelContent,
    isLargeLabel,
    name,
    inputDescription,
    hasError,
    error,
    type,
    hideNumberArrows = true,
    isPureNumberInput = false,
    required = false,
    onKeyDown,
    inputRef,
    ...rest
  } = props;

  const isNumberInput = type === 'number';

  const classes = classNames('anys-input', className);

  const labelClasses = classNames(
    'anys-input__label',
    { 'anys-input__label--large': isLargeLabel },
    labelClassName,
  );
  const labelDescClasses = classNames('anys-input__label__description', {
    'anys-input__label__description--large-spacing': isLargeLabel,
  });

  const inputClasses = classNames(
    'anys-input__input',
    {
      'anys-input__input--no-arrows': isNumberInput && hideNumberArrows,
    },
    inputClassName,
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (isPureNumberInput && SPECIAL_NUMBER_CHARS.includes(event.key)) {
        event.preventDefault();

        return;
      }

      onKeyDown?.(event);
    },
    [isPureNumberInput, onKeyDown],
  );

  return (
    <div className={classes}>
      {label && (
        <div className="anys-input__label-wrapper">
          <label htmlFor={name} className={labelClasses}>
            <span>
              {label}
              {required && (
                <span className="anys-input__label__required">{'*'}</span>
              )}
            </span>

            {labelDescription && (
              <span className={labelDescClasses}>{labelDescription}</span>
            )}
          </label>
          {asideLabelContent}
        </div>
      )}

      <InputNcLib
        {...rest}
        onKeyDown={handleKeyDown}
        type={type}
        hasError={hasError}
        name={name}
        className={inputClasses}
        required={required}
        ref={inputRef}
      />

      <ValidationError showError={hasError} error={error} />

      {!!inputDescription && (
        <span className="anys-input__description">{inputDescription}</span>
      )}
    </div>
  );
};

export default Input;
