import React, { useContext, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { useSearchParams } from 'react-router-dom';
import ResetPasswordForm from './components/ResetPasswordForm';
import { useAuth } from '../Auth/Auth.page';
import MobileHeaderContext from 'components/MobileHeader/providers/MobileHeader/MobileHeader.context';
import AnyServiceLogoIcon from 'icons/AnyServiceLogo.icon';

import './ResetPassword.styles.scss';
import './ResetPassword.styles.responsive.scss';

type ResetPasswordProps = {
  className?: string;
};

const ResetPassword: React.FC<ResetPasswordProps> = (props) => {
  const { className } = props;

  const [searchParams] = useSearchParams();
  const { resetPassword, isResettingPassword } = useAuth();
  const { setStyleOptions, setContent } = useContext(MobileHeaderContext);

  const classes = classNames('anys-reset-password', className);

  const initialValues = useMemo(
    () => ({ token: searchParams.get('token') }),
    [searchParams],
  );

  useEffect(() => {
    setStyleOptions(() => ({
      hasBorder: false,
      isBackButtonTransparent: true,
      showBackButton: true,
    }));
    setContent(<AnyServiceLogoIcon className="anys-reset-password__logo" />);
  }, [setContent, setStyleOptions]);

  return (
    <div className={classes}>
      <AnyServiceLogoIcon className="anys-reset-password__big-logo" />
      <ResetPasswordForm
        initialValues={initialValues}
        onSubmit={resetPassword}
        inProgress={isResettingPassword}
      />
    </div>
  );
};

export default ResetPassword;
