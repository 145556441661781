import React, { useEffect, useMemo, useRef, useState } from 'react';

import ClockIcon from 'icons/Clock.icon';
import classNames from 'classnames';
import './CountdownTimer.styles.scss';

const SECOND_IN_MS = 1000;

const addPrefixZeroToTimeUnit = (timeUnit: number) =>
  timeUnit > 9 ? `${timeUnit}` : `0${timeUnit}`;

const formatMSToTime = (ms: number) => {
  // 1- Convert to seconds:
  let seconds = ms / SECOND_IN_MS;
  // 2- Extract hours:
  const hours = Number.parseInt(`${seconds / 3600}`); // 3,600 seconds in 1 hour
  seconds = seconds % 3600; // seconds remaining after extracting hours
  // 3- Extract minutes:
  const minutes = Number.parseInt(`${seconds / 60}`); // 60 seconds in 1 minute
  // 4- Keep only seconds not extracted to minutes:
  seconds = seconds % 60;

  const hNormalized = addPrefixZeroToTimeUnit(Number.parseInt(`${hours}`));

  const hoursMaybe = hours > 0 ? `${hNormalized}:` : '';

  const mNormalized = addPrefixZeroToTimeUnit(Number.parseInt(`${minutes}`));
  const sNormalized = addPrefixZeroToTimeUnit(Number.parseInt(`${seconds}`));

  return `${hoursMaybe}${mNormalized}:${sNormalized}`;
};

type CountdownTimerProps = {
  startTimeInMS: number;
  className?: string;
  iconGradient?: boolean;
  onTimerFinished?: () => void;
};

const CountdownTimer: React.FC<CountdownTimerProps> = (props) => {
  const {
    startTimeInMS,
    className,
    iconGradient = false,
    onTimerFinished,
  } = props;

  const [timerCount, setTimerCount] = useState<number>();
  const timer = useRef<NodeJS.Timer>(null);

  const classes = classNames(
    'anys-countdown-timer',
    { 'anys-countdown-timer--finished': timerCount <= 0 },
    className,
  );

  const formattedTime = useMemo(
    () => timerCount >= 0 && formatMSToTime(timerCount),
    [timerCount],
  );

  useEffect(() => {
    if (startTimeInMS) {
      setTimerCount(startTimeInMS);
    }
  }, [startTimeInMS]);

  useEffect(() => {
    if (startTimeInMS > 0) {
      timer.current = setInterval(() => {
        setTimerCount((oldTimer) =>
          oldTimer - SECOND_IN_MS > 0 ? oldTimer - SECOND_IN_MS : 0,
        );
      }, 1000);
    }

    return () => {
      timer.current && clearInterval(timer.current);
    };
  }, [startTimeInMS]);

  useEffect(() => {
    if (timerCount <= 0) {
      timer.current && clearInterval(timer.current);

      onTimerFinished?.();
    }
  }, [onTimerFinished, timerCount]);

  return (
    <span className={classes}>
      <ClockIcon hasGradient={iconGradient} />
      {formattedTime}
    </span>
  );
};

export default CountdownTimer;
