import httpClient from 'api/httpClient';
import { FrequentQuestionsResponse } from 'models/FrequentQuestions';

const getFrequentQuestions = (type: string) => {
  return httpClient.get<FrequentQuestionsResponse[]>('/static-contents', {
    params: { type: type },
  });
};

export default { getFrequentQuestions };
