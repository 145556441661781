import React, { useCallback, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { Checkbox, Dropdown } from 'ncoded-component-library';
import {
  DropdownProps,
  DropdownRef,
} from 'ncoded-component-library/build/components/molecules/Dropdown/Dropdown.component';
import Calendar, { CalendarProps } from 'components/Calendar';
import dayjs, { Dayjs } from 'dayjs';
import dates from 'utils/dates';
import CalendarIcon from 'icons/Calendar.icon';
import useEvent from 'hooks/useEvent';
import { Timeslot } from 'models/Availability';

import './CalendarDropdown.styles.scss';

const TODAY = dayjs();

export type CalendarDropdownProps = Partial<
  Omit<DropdownProps, 'value' | 'onChange' | 'onClose' | 'ref' | 'placeholder'>
> &
  Omit<CalendarProps, 'value' | 'onChange' | 'placeholder'> & {
    calendarClassName?: string;
    label?: React.ReactNode;
    hasError?: boolean;
    hasIcon?: boolean;
    isPlainStyle?: boolean;
    value: Date | Dayjs | Dayjs[] | string;
    placeholder?: React.ReactNode;
    allowRange?: boolean;
    withoutDays?: boolean;
    markDates?: string;
    hasCloseButton?: boolean;
    timeslots?: Timeslot[];
    timezone?: string;
    onMarkDates?: (value: boolean) => void;
  } & Required<Pick<CalendarProps, 'onChange'>>;

const CalendarDropdown: React.FC<CalendarDropdownProps> = (props) => {
  const {
    className,
    calendarClassName,
    trigger,
    label,
    placeholder = 'dd/mm/yyyy',
    value: propValue,
    leftLimitDate = TODAY,
    rightLimitDate = dates.MAX_DATEPICKER_LIMIT,
    hasError,
    hasIcon = true,
    isPlainStyle,
    disabled,
    allowRange = false,
    onClose,
    onDone,
    onChange,
    withoutDays,
    markDates,
    hasCloseButton = false,
    onMarkDates,
    renderAsPortal = false,
    timeslots,
    timezone,
    ...dropdownProps
  } = props;

  const dropdownRef = useRef<DropdownRef>();

  const [selectedUnavailableCheckbox, setSelectedUnavailableCheckbox] =
    useState(false);

  const classes = classNames(
    'anys-calendar-dropdown',
    { 'anys-calendar-dropdown__mark-dates-checkbox': markDates },
    className,
  );

  const value = useMemo(() => {
    if (!propValue) return null;

    if (Array.isArray(propValue)) {
      return propValue;
    } else {
      if (dayjs.isDayjs(propValue)) return propValue;

      return dayjs(propValue);
    }
  }, [propValue]);

  const selectedDate = useMemo(() => {
    if (!value) return withoutDays ? 'MM/YYYY' : placeholder;

    return !Array.isArray(value)
      ? value?.format(withoutDays ? 'MM/YYYY' : 'DD/MM/YYYY')
      : `${value[0]?.format('DD/MM/YY')} - ${value[value.length - 1]?.format(
          'DD/MM/YY',
        )}`;
  }, [placeholder, value, withoutDays]);

  const triggerClasses = classNames('anys-calendar-dropdown__trigger', {
    'anys-calendar-dropdown__trigger--placeholder': !value,
    'anys-calendar-dropdown__trigger--error': hasError && !isPlainStyle,
    'anys-calendar-dropdown__trigger--plain': isPlainStyle,
    'anys-calendar-dropdown__trigger--disabled': disabled,
  });

  const finalTrigger = useMemo(() => {
    if (trigger) return trigger;

    return (
      <button type="button" className={triggerClasses} disabled={disabled}>
        <span className="anys-calendar-dropdown__trigger__value">
          {selectedDate}
        </span>
        {hasIcon && (
          <CalendarIcon className="anys-calendar-dropdown__trigger__icon" />
        )}
      </button>
    );
  }, [disabled, hasIcon, selectedDate, trigger, triggerClasses]);

  const onCloseCalendar = useCallback(() => {
    onClose?.();

    dropdownRef.current.setIsOpen(false);
  }, [onClose]);

  const handleScroll = useCallback(() => {
    if (dropdownRef.current?.isOpen) {
      dropdownRef.current.setIsOpen(false);
    }
  }, []);

  useEvent(window, 'scroll', handleScroll, { capture: true }, renderAsPortal);

  return (
    <div className={classes}>
      {label && (
        <label className="anys-calendar-dropdown__label">{label}</label>
      )}
      <Dropdown
        ref={dropdownRef}
        trigger={finalTrigger}
        onClose={onClose}
        renderAsPortal={renderAsPortal}
        {...dropdownProps}
      >
        <Calendar
          leftLimitDate={leftLimitDate}
          rightLimitDate={rightLimitDate}
          onChange={onChange}
          onClose={onCloseCalendar}
          onDone={onDone}
          value={value}
          className={calendarClassName}
          allowRange={allowRange}
          withoutDays={withoutDays}
          hasCloseButton={hasCloseButton}
          timeslots={timeslots}
          timezone={timezone}
        />

        {markDates && (
          <Checkbox
            label={markDates}
            className="anys-calendar-dropdown__checkbox"
            checked={selectedUnavailableCheckbox}
            onChange={() => {
              setSelectedUnavailableCheckbox(!selectedUnavailableCheckbox);
              onMarkDates && onMarkDates(!selectedUnavailableCheckbox);
            }}
          />
        )}
      </Dropdown>
    </div>
  );
};

export default CalendarDropdown;
