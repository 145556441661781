import React from 'react';

type SelectLabelProps = {
  label:
    | string
    | {
        icon: React.ReactNode;
        text: string;
      };
};
const SelectLabel: React.FC<SelectLabelProps> = (props) => {
  const { label } = props;
  if (typeof label === 'string') {
    return <>{label}</>;
  }

  return (
    <>
      {label.icon} {label.text}
    </>
  );
};

export default SelectLabel;
