import httpClient from '../httpClient';
import { AxiosResponse } from 'axios';
import { GetUsersResponse, User } from 'models/User';
import {
  DEFAULT_PAGINATION,
  PaginatedResponse,
  PaginationParams,
} from 'models/Pagination';
import { UserChatOnlineStatus } from 'models/Chat';
import { VerifyResponse } from 'models/Verification';
import { ProfileOffer } from 'models/Job';

const getUserProfile = (
  params?: PaginationParams,
): Promise<AxiosResponse<{ user: any }>> =>
  httpClient.get<{ user: User }>('users/current', { params });

const updateUser = (formData: any) => httpClient.patch<User>('users', formData);

const uploadUserFile = (formData: FormData) =>
  httpClient.post<User>('users/files', formData);

const addUserExperience = (formData: FormData) =>
  httpClient.post('users/experiences', formData);

const removeUserExperince = (id: number) =>
  httpClient.delete(`users/experiences/${id}`);

const removeUserFile = (id: number) =>
  httpClient.delete<User>(`users/files/${id}`);

const getUsers = (params: PaginationParams) =>
  httpClient.get<GetUsersResponse>('users', { ...DEFAULT_PAGINATION, params });

const getUser = (id: string): Promise<AxiosResponse<{ user: User }>> =>
  httpClient.get(`users/${id}`);

const getOnlineStatuses = (userIds: number[]) =>
  httpClient.post<UserChatOnlineStatus[]>('users/online-statuses', {
    userIds,
  });

const initVerification = () => {
  return httpClient.post<VerifyResponse>('users/verify/identity');
};

const saveVerification = (verifyId: number, sessionUrl: string) => {
  return httpClient.put<
    Pick<VerifyResponse, 'id' | 'sessionId' | 'sessionUrl'>
  >(`users/verify/identity/${verifyId}`, {
    sessionUrl,
  });
};

const getSuggestedArbitrators = (params: PaginationParams) => {
  return httpClient.get<PaginatedResponse<User>>(
    'users/suggested-arbitrators',
    { params },
  );
};

const getDisplayedOffers = (userId: number, params?: PaginationParams) => {
  return httpClient.get<PaginatedResponse<ProfileOffer>>(
    `users/${userId}/offers`,
    {
      params,
    },
  );
};

const addDisplayedOffer = (
  userId: number,
  entityId:
    | {
        jobPostId: number;
      }
    | { jobId: number }
    | {
        contractId: number;
      },
) => {
  return httpClient.post<ProfileOffer>(`users/${userId}/offers`, {
    ...entityId,
  });
};

const removeUserOffer = (userId: number, offerId: number) => {
  return httpClient.delete<{ message: string }>(
    `users/${userId}/offers/${offerId}`,
  );
};

export default {
  getUser,
  getUsers,
  getUserProfile,
  updateUser,
  uploadUserFile,
  addUserExperience,
  removeUserExperince,
  removeUserFile,
  getOnlineStatuses,
  initVerification,
  saveVerification,
  getSuggestedArbitrators,
  getDisplayedOffers,
  addDisplayedOffer,
  removeUserOffer,
};
