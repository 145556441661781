import { User } from './User';

export const NOTIFICATION_SETTING_TYPE = {
  OFFER: 'offer',
  CONTRACT: 'contract',
  ANY_MESSAGE: 'any-message',
  OTHER_PARTY: 'other-party',
  REMINDERS: 'reminders',
  ANYSERVICE_UPDATES: 'anyservice-updates',
  PROMOTIONAL_OFFERS: 'promotional-offers',
  LEADS: 'leads',
} as const;

export type NotificationSettingType =
  (typeof NOTIFICATION_SETTING_TYPE)[keyof typeof NOTIFICATION_SETTING_TYPE];

export const NOTIFICATION_TYPE = {
  OFFER_RECEIVED: 'offer-received',
  OFFER_SIGNED: 'offer-signed',
  CONTRACT_MADE: 'contract-made',
  CONTRACT_END_REQUEST: 'contract-end-requested',
  CONTRACT_PAYMENT_REQUESTED: 'contract-payment-requested',
  CONTRACT_CHANGE_REQUESTED: 'contract-change-requested',
  CONTRACT_START_REMINDER_6H: 'contract-start-reminder-6h',
  ARBITRATION_DEFENDANT_UPLOAD_MONEY_48H:
    'arbitration-defendant-upload-money-48h',
  ARBITRATION_DEFENDANT_QUESTIONS_ASKED:
    'arbitration-defendant-questions-asked',
  ARBITRATION_DEFENDANT_EPILOGUE: 'arbitration-defendant-epilogue',
  ARBITRATION_ARBITER_INVITE: 'arbitration-arbiter-invite',
  ARBITRATION_ARBITER_DECISION_REMINDER_6H:
    'arbitration-arbiter-decision-reminder-6h',
  ARBITRATION_ARBITER_QUESTIONS_ANSWERED:
    'arbitration-arbiter-questions-answered',
} as const;

export const ARBITRATION_TYPE_TO_PANEL_VIEW = {
  [NOTIFICATION_TYPE.ARBITRATION_DEFENDANT_UPLOAD_MONEY_48H]: 'non-arbiter',
  [NOTIFICATION_TYPE.ARBITRATION_DEFENDANT_QUESTIONS_ASKED]: 'arbiter',
  [NOTIFICATION_TYPE.ARBITRATION_DEFENDANT_EPILOGUE]: 'non-arbiter',
  [NOTIFICATION_TYPE.ARBITRATION_ARBITER_INVITE]: 'arbiter',
  [NOTIFICATION_TYPE.ARBITRATION_ARBITER_DECISION_REMINDER_6H]: 'arbiter',
  [NOTIFICATION_TYPE.ARBITRATION_ARBITER_QUESTIONS_ANSWERED]: 'arbiter',
} as const;

export type NotificationType =
  (typeof NOTIFICATION_TYPE)[keyof typeof NOTIFICATION_TYPE];

export type NotificationSetting = {
  id: number;
  shouldReceivePush: boolean;
  shouldReceiveEmail: boolean;
};

export type NotificationSettingRes = NotificationSetting & {
  type: NotificationSettingType;
  userId: number;
  createdAt: string;
  updatedAt: string;
};

export type NotificationCount = {
  inboxItemId?: number;
  arbitrationId?: number;
  count: number;
  type?: 'asArbiter' | 'asDefendant';
};

export type NotificationMetadata = {
  userName?: string;
  otherUserName?: string;
  id?: number;
  commonId?: number;
  startDate?: Date;
};

export type NotificationEntity = {
  id: number;
  type: NotificationType;
  metadata?: NotificationMetadata;
  relativeUrl: string;
  isRead: boolean;
  user: User;
  inboxItem?: { id: number };
  arbitration?: { id: number };
  createdAt: string;
  updatedAt: string;
};
