import React, { useCallback, useRef } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import routes from 'router/routes';
import Routes from 'router/components/Routes/Routes';
import ThemeProvider from 'providers/Theme';
import './i18n/config';
import MobileHeaderProvider from 'components/MobileHeader/providers/MobileHeader';
import CurrentUserProvider from 'providers/CurrentUser';
import { Wrapper } from '@googlemaps/react-wrapper';
import useLogoutSignal from 'hooks/useLogoutSignal';
import GeneralProvider from 'providers/General/General.provider';
import ChatNotificationsProvider from 'providers/ChatNotifications';
import NotificationsProvider from 'providers/Notifications';
import KycVerifyModal from 'components/KycVerifyModal';
import { ModalRef } from 'components/Modal';
import useBusListener from 'hooks/useBusListener';
import { EventName } from 'modules/bus';

export const GOOGLE_MAPS_PLACES_SCRIPT_ID = 'google_maps_places_script';

function App() {
  const kycVerifyModalRef = useRef<ModalRef>();

  useLogoutSignal();

  const toggleVerifyModal = useCallback(
    ({ payload: isOpen }: { event: EventName; payload: boolean }) => {
      if (isOpen) kycVerifyModalRef.current?.open();
      else kycVerifyModalRef.current?.close();
    },
    [],
  );

  useBusListener('TOGGLE_KYC_MODAL', toggleVerifyModal);

  return (
    <GeneralProvider>
      <ThemeProvider>
        <BrowserRouter>
          <Wrapper
            apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
            id={GOOGLE_MAPS_PLACES_SCRIPT_ID}
            libraries={['places']}
          >
            <CurrentUserProvider>
              <ChatNotificationsProvider>
                <NotificationsProvider>
                  <MobileHeaderProvider>
                    <Routes routes={routes} />
                    <KycVerifyModal
                      ref={kycVerifyModalRef}
                      closeModal={() => kycVerifyModalRef.current?.close()}
                    />
                  </MobileHeaderProvider>
                </NotificationsProvider>
              </ChatNotificationsProvider>
            </CurrentUserProvider>
          </Wrapper>
        </BrowserRouter>
        <ToastContainer />
      </ThemeProvider>
    </GeneralProvider>
  );
}

export default App;
