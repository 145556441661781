import CheckmarkCircle from 'icons/CheckmarkCircle';
import RedWarningIcon from 'icons/RedWarning.icon';
import WarningIcon from 'icons/Warning.icon';
import React from 'react';
import { toast, ToastOptions } from 'react-toastify';

import './Toast.styles.scss';

const TOAST_OPTIONS: ToastOptions = {
  hideProgressBar: true,
  closeButton: false,
  position: 'top-center',
};

const showToast = (
  type: 'success' | 'error' | 'warn' | 'info',
  title?: React.ReactNode,
  description?: React.ReactNode,
  toastOptions?: ToastOptions,
) => {
  let icon = null;

  switch (type) {
    case 'success':
      icon = <CheckmarkCircle />;
      break;
    case 'error':
      icon = <RedWarningIcon color="currentColor" />;

      break;
    default:
      icon = <WarningIcon />;
      break;
  }

  toast[type](
    <div className="anys-toast-content">
      {title && <div className="anys-toast-content__title">{title}</div>}
      {description && (
        <div className="anys-toast-content__desc">{description}</div>
      )}
    </div>,
    { ...TOAST_OPTIONS, icon, ...toastOptions, theme: 'colored' },
  );
};

export default showToast;
