import { InboxItemType, InboxUrlParams, INBOX_ITEM_TYPE } from 'models/Inbox';
import { useCallback, useMemo } from 'react';
import {
  generatePath,
  matchPath,
  useLocation,
  useParams,
} from 'react-router-dom';
import routes from 'router/routes';

const ENTITY_AND_CHAT_CLOSED = '/0/0/';
const ALL_ITEM_TYPES = Object.values(INBOX_ITEM_TYPE).join('|');

const SUMMARY_PAY_MODAL_NAMES =
  '(job-summary-pay-modal=open)|(job-sign-summary-pay-modal=open)|(job-post-summary-pay-modal=open)|(contract-summary-pay-modal=open)';

const useInboxLink = () => {
  const params = useParams<InboxUrlParams>();

  const location = useLocation();

  const baseInboxRoute = routes.find(({ path }) => path?.includes('inbox'));

  const baseInboxPath = baseInboxRoute
    ? `${baseInboxRoute.path}/${baseInboxRoute.routes[0].path}`
    : '/inbox';

  // Find all 'inbox' paths
  const inboxPaths = useMemo(() => {
    const inboxRoutes = routes.filter(({ path }) => path?.includes('inbox/'));

    return inboxRoutes.reduce<string[]>((allPaths, { path, routes }) => {
      // Go through the subroutes
      // and append the subpaths to
      // the parent path
      if (routes.length) {
        // We don't want 'create' routes because they
        // are irrelevant in the inbox
        const subRoutesWithPath = routes.filter(
          ({ path: suffixPath }) =>
            !!suffixPath && !suffixPath.includes('create'),
        );

        const subRoutePaths = subRoutesWithPath.map(
          ({ path: suffixPath }) => `${path}/${suffixPath}`,
        );

        allPaths.push(...subRoutePaths);
      }

      return allPaths;
    }, []);
  }, []);

  const currentPathPattern = inboxPaths?.find((path) =>
    matchPath({ path }, location.pathname),
  );

  const getSpecificPathPattern = useCallback(
    (pathType: 'edit' | 'view', entityType: InboxItemType) => {
      const isView = pathType === 'view';

      return inboxPaths.find((path) => {
        // Route should include e.g /edit and /job/
        if (!isView)
          return (
            path.includes(`/${pathType}`) && path.includes(`/${entityType}/`)
          );

        // We only want view routes,
        // and they are in this format
        return path.endsWith(`/${entityType}/:id`);
      });
    },
    [inboxPaths],
  );

  const createLink = useCallback(
    (pathPattern: string, params: InboxUrlParams, pathSuffix = '') => {
      if (!pathPattern) return `/inbox${location.search}`;

      const { inboxItemId, chatUserId, id } = params;

      let newPath = generatePath(pathPattern, {
        inboxItemId: inboxItemId || '0',
        chatUserId: chatUserId || '0',
        id: id || '',
      });

      // If the path has /0/0/ that means
      // nothing is open (no chat, no job)
      // so we should remove it.
      if (newPath.includes(ENTITY_AND_CHAT_CLOSED)) {
        newPath = newPath.replace(ENTITY_AND_CHAT_CLOSED, '/');
      }

      // If we dont have ids,
      // we should remove the item type
      // from the path
      if (!inboxItemId || !id) {
        newPath = newPath.replace(new RegExp(`/(${ALL_ITEM_TYPES})/`, 'g'), '');

        // If we are editing item, remove the 'edit'
        if (newPath.includes('edit/')) {
          newPath = newPath.replace(new RegExp('edit', 'g'), '');
        }
      }

      const locationSearchFinal = (location.search || '').replace(
        new RegExp(`(${SUMMARY_PAY_MODAL_NAMES})`, 'g'),
        '',
      );

      return `/${newPath}${pathSuffix}${locationSearchFinal}`;
    },
    [location.search],
  );

  const linkWihoutChat = createLink(currentPathPattern, {
    ...params,
    chatUserId: null,
  });

  const linkWihoutInboxItem = createLink(currentPathPattern, {
    chatUserId: params.chatUserId,
  });

  const createEntityLink = useCallback(
    (
      pathType: 'view' | 'edit',
      itemType: InboxItemType,
      entityId: number,
      inboxItemId?: number,
      chatUserId?: number,
      pathSuffix?: string,
    ) => {
      const editPath = getSpecificPathPattern(pathType, itemType);

      return createLink(
        editPath,
        {
          ...params,
          id: `${entityId}`,
          inboxItemId: inboxItemId ? `${inboxItemId}` : params.inboxItemId,
          chatUserId: chatUserId ? `${chatUserId}` : params.chatUserId,
        },
        pathSuffix,
      );
    },
    [createLink, getSpecificPathPattern, params],
  );

  const createChatLink = useCallback(
    (chatUserId: number) => {
      return createLink(currentPathPattern || baseInboxPath, {
        ...params,
        chatUserId: chatUserId ? `${chatUserId}` : null,
      });
    },
    [baseInboxPath, createLink, currentPathPattern, params],
  );

  return {
    createEntityLink,
    createChatLink,
    linkWihoutChat,
    linkWihoutInboxItem,
  };
};

export default useInboxLink;
