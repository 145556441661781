import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import type { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';

import Modal from 'components/Modal';
import { useTranslation } from 'react-i18next';
import FormButtons from 'components/FormButtons';
import { Button, Checkbox } from 'ncoded-component-library';
import { SkillGroupWithSkills } from 'models/Skills';
import './SkillsModal.styles.scss';
import './SkillsModal.styles.responsive.scss';

type SkillsModalProps = {
  initialValues?: number[];
  loading: boolean;
  className?: string;
  keepOpenOnRefresh?: boolean;
  skillGroups?: SkillGroupWithSkills[];
  onOpen?: () => void;
  onSubmit: (values: number[]) => void;
};

enum SkillGroupType {
  ONLINE = 'Online',
  OFFLINE = 'Offline',
}

const EMPTY_ARRAY: number[] = [];

const SkillsModal: React.ForwardRefRenderFunction<
  ModalRef,
  SkillsModalProps
> = (props, ref) => {
  const { t } = useTranslation();

  const {
    className,
    initialValues = EMPTY_ARRAY,
    loading,
    keepOpenOnRefresh,
    skillGroups,
    onOpen,
    onSubmit,
  } = props;

  const classes = classNames('anys-skills-modal', className);
  const formRef = useRef<HTMLFormElement>();
  const hasSetInitialValues = useRef(false);

  const [values, setValues] = useState<number[]>([]);
  const [selectedGroupType, setSelectedGroupType] = useState<string>(
    SkillGroupType.ONLINE,
  );
  const [selectedGroup, setSelectedGroup] =
    useState<SkillGroupWithSkills | null>(null);
  const skillGroupsFiltered = useMemo(() => {
    return (
      skillGroups?.filter(
        (skillGroup) => skillGroup.type === selectedGroupType,
      ) || []
    );
  }, [selectedGroupType, skillGroups]);

  useEffect(() => {
    if (hasSetInitialValues.current) return;

    if (initialValues && initialValues !== EMPTY_ARRAY) {
      setValues(initialValues);

      hasSetInitialValues.current = true;
    }

    return () => {
      hasSetInitialValues.current = false;
    };
  }, [initialValues]);

  return (
    <Modal
      ref={ref}
      isDrawer
      onOpen={() => {
        onOpen?.();
        setValues(initialValues);
      }}
      type="no-action"
      modalName="choose-skills"
      title={t('Filters.skills_other')}
      className={classes}
      keepOpenOnRefresh={keepOpenOnRefresh}
    >
      <div className="anys-skills-modal__filter">
        <Button
          variant={
            selectedGroupType === SkillGroupType.OFFLINE ? 'outline' : 'solid'
          }
          styleType={
            selectedGroupType === SkillGroupType.ONLINE
              ? 'primary'
              : 'secondary'
          }
          onClick={() => setSelectedGroupType(SkillGroupType.ONLINE)}
        >
          <span>{t('Skills.online')}</span>
        </Button>
        <Button
          variant={
            selectedGroupType === SkillGroupType.ONLINE ? 'outline' : 'solid'
          }
          styleType={
            selectedGroupType === SkillGroupType.OFFLINE
              ? 'primary'
              : 'secondary'
          }
          onClick={() => setSelectedGroupType(SkillGroupType.OFFLINE)}
        >
          <span>{t('Skills.offline')}</span>
        </Button>
      </div>
      <form
        ref={formRef}
        onSubmit={(ev) => {
          ev.preventDefault();
          ev.stopPropagation();
          onSubmit(values);
        }}
      >
        {skillGroupsFiltered.map((skillGroup) => (
          <>
            <div
              key={skillGroup.id}
              className={
                !skillGroup?.skills?.length
                  ? 'anys-skills-modal__group__disabled'
                  : 'anys-skills-modal__group'
              }
              onClick={() => {
                if (!skillGroup?.skills?.length) {
                  return;
                }
                setSelectedGroup(
                  selectedGroup?.id === skillGroup.id ? null : skillGroup,
                );
              }}
            >
              {`${skillGroup.name} (${skillGroup?.skills?.length || 0})`}
            </div>
            {selectedGroup?.id === skillGroup.id ? (
              <ul style={{ paddingLeft: '2rem' }}>
                {selectedGroup.skills.map((skill) => (
                  <li key={skill.id}>
                    <Checkbox
                      key={skill.id}
                      label={skill.name}
                      name="skills"
                      value={skill.id}
                      checked={values.includes(skill.id)}
                      onChange={(ev) => {
                        const checked =
                          typeof ev === 'boolean' ? ev : ev.target.checked;
                        setValues((old) =>
                          checked
                            ? [...old, skill.id]
                            : old.filter((id) => id !== skill.id),
                        );
                      }}
                    />
                  </li>
                ))}
              </ul>
            ) : null}
          </>
        ))}
        <FormButtons
          loading={loading}
          cancelTxt={t('General.reset')}
          cancelBtnProps={{ variant: 'link' }}
          onClear={() => setValues([])}
        />
      </form>
    </Modal>
  );
};

export default React.forwardRef(SkillsModal);
