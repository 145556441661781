import _ from 'lodash';
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { Loader } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';

export type InfiniteContainerProps = {
  id?: string;
  className?: string;
  threshold?: number;
  loader?: ReactNode;
  showLoader?: boolean;
  loading: boolean;
  toTop?: boolean;
  onScroll: () => void;
};

type InfiniteContainerHookProps = InfiniteContainerProps & {
  container: HTMLElement | Window | null;
};

export default function useInfiniteContainer(
  props: InfiniteContainerHookProps,
) {
  const { t } = useTranslation();
  const {
    container,
    threshold = 95,
    showLoader = true,
    loading,
    toTop,
    loader = <Loader text={t('loading')} />,
    onScroll,
  } = props;
  const [inverse, setInverse] = useState(false);
  const preventFiringEvent = useRef(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnScroll = useCallback(_.debounce(onScroll, 100), [onScroll]);

  const onContainerScroll = useCallback(
    (ev: Event | React.UIEvent) => {
      const element =
        container === window
          ? document.documentElement
          : (ev.target as HTMLDivElement);
      if (container !== window && element !== container) return;
      const localInverse = element.scrollTop < 0;

      if (localInverse && !inverse) {
        setInverse(true);
      }

      const scrolledPercent =
        (Math.abs(
          element.scrollTop + (localInverse ? -1 : 1) * element.clientHeight,
        ) *
          100) /
        element.scrollHeight;

      const scrolledPercentFinal = toTop
        ? (100 * element.scrollTop) /
          Math.abs(element.scrollHeight - element.clientHeight)
        : scrolledPercent;

      const scrolledPastThreshold = toTop
        ? scrolledPercentFinal <= threshold
        : scrolledPercentFinal >= threshold;

      if (scrolledPastThreshold && !preventFiringEvent.current) {
        if (!loading) debouncedOnScroll();
      }
    },
    [container, toTop, inverse, threshold, loading, debouncedOnScroll],
  );

  useEffect(() => {
    const preventFiringEventDebounced = _.debounce(() => {
      preventFiringEvent.current = false;
    }, 50);

    const onResize = () => {
      if (!preventFiringEvent.current) {
        preventFiringEvent.current = true;
      }
      preventFiringEventDebounced();
    };

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  const loaderEl = useMemo(
    () =>
      showLoader ? (
        <div
          className={classNames('infinite-scroll__loader', {
            'infinite-scroll__loader--top': inverse,
            'infinite-scroll__loader--bottom': !inverse,
          })}
          {...(!loading && {
            style: { display: 'none' },
          })}
        >
          {loader}
        </div>
      ) : null,
    [showLoader, inverse, loader, loading],
  );

  return {
    onScroll: onContainerScroll,
    loaderEl,
  } as const;
}
