import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Job, JobPost } from 'models/Job';
import { Trans, useTranslation } from 'react-i18next';
import { getArbitrationChanges, isPendingChanges } from 'utils/job-changes';

import './ArbitrationPreview.styles.scss';
import WarningIcon from 'icons/Warning.icon';
import ToolTip from 'components/ToolTip';
import JobChanges from 'components/JobChanges';
import useGetSkillGroups from '../../../../hooks/useGetSkillGroups';
import ReactPlayer from 'react-player';

type ArbitrationPreviewProps = {
  className?: string;
  prevArbitrationOptions: Pick<Job, 'arbitrationOptions'>['arbitrationOptions'];
  prevIsLockedArbitration: Pick<
    Job,
    'isLockedArbitration'
  >['isLockedArbitration'];
} & Required<Pick<JobPost, 'arbitrationOptions' | 'timeAndPricing'>> &
  Required<Pick<Job, 'versionState' | 'isLockedArbitration'>>;

const ArbitrationPreview: React.FC<ArbitrationPreviewProps> = (props) => {
  const {
    className,
    arbitrationOptions,
    prevArbitrationOptions,
    versionState,
    prevIsLockedArbitration,
    isLockedArbitration,
  } = props;

  const { t } = useTranslation();

  const { skillOptions } = useGetSkillGroups();

  const classes = classNames('anys-arbitration-preview', className);

  const hasArbitrations = !!arbitrationOptions?.length;

  const messages = useMemo(
    () => ({
      arbitration: t('General.arbitration'),
      noArbitration: t('General.noArbitration'),
      arbitrators: t('General.arbitrators'),
      numberOfArbitrators: t('ArbitrationSetUp.numberOfArbitrators'),
      arbitratorsFee: t('ArbitrationSetUp.arbitratorsFee'),
      arbitratorsTotalFee: t('ArbitrationSetUp.arbitratorsTotalFee'),
      arbitrationExplanation: t('ArbitrationSetUp.paragraphNo'),
      option: t('General.option'),
      arbitratorsFilters: t('ArbitrationSetUp.arbitratorsFilter'),
      arbitratorsType: t('ArbitrationSetUp.arbitratorsType'),
    }),
    [t],
  );

  const arbitrationChanges =
    prevArbitrationOptions && isPendingChanges(versionState)
      ? getArbitrationChanges(
          t,
          skillOptions,
          {
            hasArbitration: !!prevArbitrationOptions?.length,
            arbitrationOptions: prevArbitrationOptions,
          },
          {
            hasArbitration: hasArbitrations,
            arbitrationOptions: arbitrationOptions,
          },
          prevIsLockedArbitration,
          isLockedArbitration,
          false,
        )
      : null;

  return (
    <section className={classes}>
      <div className="anys-arbitration-preview__title">
        <h2 className="flex ac">
          <div className="anys-arbitration-preview__title-wrapper">
            {messages.arbitration}
            {arbitrationChanges ? (
              <ToolTip
                t={t}
                icon={WarningIcon}
                tooltipName="arbitration-changes"
              >
                <JobChanges
                  changedFrom={arbitrationChanges.changedFrom}
                  changedTo={arbitrationChanges.changedTo}
                />
              </ToolTip>
            ) : null}
          </div>
        </h2>
        <ToolTip t={t} tooltipName="arbitration-preview-tooltip">
          <ReactPlayer width="100%" url="https://youtu.be/i5sjEtiy23g" />
          <p style={{ whiteSpace: 'pre-line' }}>
            <Trans
              i18nKey="Preview.arbitration"
              components={{ b: <b />, ul: <ul />, li: <li /> }}
            />
          </p>
        </ToolTip>
      </div>
      <div className="anys-arbitration-preview__explanation">
        {messages.arbitrationExplanation}
      </div>
    </section>
  );
};

export default ArbitrationPreview;
