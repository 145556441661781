import dayjs from 'dayjs';
import { FieldValidator } from 'final-form';
import get from 'lodash/get';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

const transformAvailabilityInitialData = (array: any[], props: string[]) => {
  const removeDuplicates = array?.filter(
    (value, index, self) =>
      index ===
      self.findIndex((t) =>
        props
          .map((prop) => {
            return t[`${prop}`] === value[`${prop}`] ? true : false;
          })
          .every((el) => el),
      ),
  );

  let conjugateSpecificEntries: any[] = [];

  //then for each unique entrie find duplicates and attach startTime and endTime to it
  removeDuplicates?.forEach((uniqueEntrie) => {
    let times: { startTime: string; endTime: string; isFullDay?: boolean }[] =
      [];

    array.forEach((specificEntrie) => {
      if (
        props
          .map((prop) => {
            return specificEntrie[`${prop}`] === uniqueEntrie[`${prop}`]
              ? true
              : false;
          })
          .every((el) => el)
      ) {
        times = [
          ...times,
          {
            startTime: specificEntrie.startTime.substring(0, 5),
            endTime: specificEntrie.endTime.substring(0, 5),
            isFullDay: specificEntrie.isFullDay,
          },
        ];
      }
    });

    const obj = props.reduce((o, el) => {
      return { ...o, [`${el}`]: uniqueEntrie[`${el}`] };
    }, {});

    conjugateSpecificEntries = [
      ...conjugateSpecificEntries,
      {
        ...obj,
        times,
      },
    ];
  }, []);

  return conjugateSpecificEntries;
};

const timeIsAfterValidation =
  (message: string, props: string): FieldValidator<string> =>
  (value, allValues: any) => {
    const timeToCompare = get(allValues, props);

    if (!timeToCompare) {
      return undefined;
    }

    const [hours, minutes] = value.split(':');
    const [hoursToCompare, minutesToCompare] = timeToCompare.split(':');

    const valueTime = dayjs()
      //when 00 we must change to 24 because of validation
      .hour(hours === '00' ? 24 : +hours)
      .minute(+minutes);

    const valueTimeToCompare = dayjs()
      .hour(+hoursToCompare)
      .minute(+minutesToCompare);

    if (valueTime.diff(valueTimeToCompare, 'minute', true) > 0) {
      return undefined;
    } else {
      return message;
    }
  };

const hasOverlapDate =
  (message: string, props: string, ind: number): FieldValidator<any> =>
  (value, allValues: any) => {
    const otherValues = allValues?.[`${props}`]?.reduce(
      (acc: any, el: any, index: number) => {
        return index !== ind ? acc.concat(el.date) : acc;
      },
      [],
    );

    let overlapping = false;

    value?.forEach((date: Date) => {
      otherValues?.forEach((other: Date) => {
        if (dayjs(other).isSame(dayjs(date), 'day')) {
          overlapping = true;
        }
      });
    });

    return overlapping ? message : undefined;
  };

// time must be in format hh:mm
const createDateFromTime = (time: string) =>
  dayjs().hour(+time?.split(':')[0]).minute(+time?.split(':')[1]);

const hasOverlapTime =
  (message: string, props: string, index: number): FieldValidator<any> =>
  (value, allValues: any) => {
    const formValues = get(allValues, props, []);

    if (formValues?.length > 1) {
      const restTimesForThatDay = formValues?.filter(
        (_: any, ind: number) => ind !== index,
      );

      const curValue = createDateFromTime(value);

      let overlapping = false;

      restTimesForThatDay.forEach((element: any) => {
        if (
          curValue.isBetween(
            createDateFromTime(element?.startTime),
            createDateFromTime(element?.endTime),
            'hour',
          )
        ) {
          overlapping = true;
        }
      });
      return overlapping ? message : undefined;
    } else {
      return undefined;
    }
  };

export default {
  transformAvailabilityInitialData,
  timeIsAfterValidation,
  hasOverlapDate,
  hasOverlapTime,
};
