import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import Modal, { ModalRef } from 'components/Modal';
import { useTranslation } from 'react-i18next';
import { Button, RadioButton } from 'ncoded-component-library';
import { JOB_TYPE } from 'constants/job';
import { JobType } from 'models/Job';
import CustomLink from 'components/CustomLink';

import './CreateAdOfferModal.styles.scss';

type CreateAdOfferModalProps = {
  className?: string;
  createAdOfferModalRef?: (element: ModalRef) => void;
  onClose: () => void;
};

const CreateAdOfferModal: React.FC<CreateAdOfferModalProps> = (props) => {
  const { className, createAdOfferModalRef, onClose } = props;

  const { t } = useTranslation();
  const [jobType, setJobType] = useState<JobType>('Need');

  const classes = classNames('anys-create-ad-offer-modal', className);

  const jobTypeOptions = useMemo(
    () =>
      Object.values(JOB_TYPE)
        .reverse()
        .map((type) => ({
          value: type as JobType,
          label: t(`General.${type.toLowerCase()}`),
          explanation:
            type === 'Need'
              ? t('General.createAdOfferNeedExplanation')
              : t('General.createAdOfferProvideExplanation'),
        })),
    [t],
  );

  const changeJobType = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setJobType(event.target.value as JobType);
    },
    [],
  );

  return (
    <Modal
      type="no-action"
      modalName="create-ad-offer"
      ref={createAdOfferModalRef}
      title={t('General.createAdOffer')}
      className={classes}
      footer={
        <div className="anys-create-ad-offer-modal__footer">
          <CustomLink
            to={`/job-post/create/${jobType?.toLowerCase()}`}
            variant="solid"
            replace
          >
            {t('General.create')}
          </CustomLink>
          <Button type="button" onClick={onClose} variant="link">
            <span>{t('General.cancel')}</span>
          </Button>
        </div>
      }
    >
      <span className="anys-create-ad-offer-modal__title">
        {t('General.createAdOfferTitle')}
      </span>
      {jobTypeOptions.map((option, i) => {
        return (
          <>
            <RadioButton
              key={i}
              name="jobType"
              value={option.value}
              label={option.label}
              checked={jobType === option.value}
              onChange={changeJobType}
            />
            <p className="anys-create-ad-offer-modal__explanation">
              {option.explanation}
            </p>
          </>
        );
      })}

      {/*  <RadioGroup
        name="jobType"
        options={jobTypeOptions}
        value={jobType}
        onChange={changeJobType}
        className="anys-create-ad-offer-modal__job-type"
      />*/}
    </Modal>
  );
};

export default CreateAdOfferModal;
