import MobileHeader from 'components/MobileHeader/MobileHeader.component';
import React, { useEffect, useRef, useState } from 'react';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import MobileHeaderContext, {
  MobileHeaderStyleOptions,
} from './MobileHeader.context';

type MobileHeaderProps = {
  children: React.ReactNode;
};

const MobileHeaderProvider: React.FC<MobileHeaderProps> = (props) => {
  const { children } = props;

  const location = useLocation();

  const [content, sSetContent] = useState<React.ReactNode>();
  const [onBackClick, setBackClick] =
    useState<(prevPath: string | -1) => void>();
  const [styleOptions, setStyleOptions] = useState<MobileHeaderStyleOptions>(
    {},
  );

  const prevLocation = useRef(location.pathname);

  const prevPath =
    prevLocation.current === location.pathname ? -1 : prevLocation.current;

  // Used when the same page sets
  // content in different places/depths.
  const contentPriorityList = useRef<Record<string, number>>({});

  const setContent = useCallback(
    (content: React.ReactNode, priority = 0) => {
      if (contentPriorityList.current[location.pathname] < priority) {
        return;
      }

      contentPriorityList.current[location.pathname] = priority;

      sSetContent(content);
    },
    [location.pathname],
  );

  const setOnBackClick = useCallback(
    (backFn: (prevPath: string | -1) => void) => {
      setBackClick(() => backFn);
    },
    [],
  );

  // Clear the mobile header states when
  // leaving the page to switch to
  // another route, so we don't have incorrect styles
  useEffect(() => {
    // Clear while unmounting, so we don't clear
    // the state before the child component can set it
    return () => {
      if (prevLocation.current !== location.pathname) {
        sSetContent(undefined);
        setBackClick(undefined);
        setStyleOptions({});

        prevLocation.current = location.pathname;
      }
    };
  }, [location.pathname, sSetContent]);

  return (
    <MobileHeaderContext.Provider
      value={{
        content,
        styleOptions,
        prevPath,
        setStyleOptions,
        onBackClick,
        setOnBackClick,
        setContent,
      }}
    >
      <MobileHeader />
      {children}
    </MobileHeaderContext.Provider>
  );
};

export default MobileHeaderProvider;
