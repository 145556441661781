import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import Card from 'components/Card';
import { Trans, useTranslation } from 'react-i18next';
import ToolTip from 'components/ToolTip';
import CheckboxField from 'components/CheckboxField';
import { Button } from 'ncoded-component-library';
import AddIcon from 'icons/Add.icon';
import Modal, { ModalRef } from 'components/Modal';
import SearchUsersForm, {
  SearchUsersFormValues,
} from 'components/SearchUsersForm';
import useCallbackRef from 'hooks/useCallbackRef';
import { User } from 'models/User';
import { JobPost } from 'models/Job';
import { formatUserDisplayName } from 'utils/user';
import Avatar from 'components/Avatar';
import { Field } from 'react-final-form';
import formValidators from 'utils/formValidators';
import ValidationError from 'components/ValidationError';

import './SendTo.styles.scss';
import './SendTo.styles.responsive.scss';

type SendToProps = {
  className?: string;
  valuesFromForm: Pick<JobPost<'form'>, 'sendToUsers'>;
  isProvideJob: boolean;
  onChangeSendToUsers: (newUsers: User[]) => void;
};

const SendTo: React.FC<SendToProps> = (props) => {
  const { className, valuesFromForm, isProvideJob, onChangeSendToUsers } =
    props;

  const [selectClientsModal, selectClientsModalRef] =
    useCallbackRef<ModalRef>(null);

  const { t } = useTranslation();

  const { sendToUsers = [] } = useMemo(() => valuesFromForm, [valuesFromForm]);

  const classes = classNames('anys-send-to', className);

  const messages = useMemo(
    () => ({
      title: t('General.sendTo'),
      timeline: t('General.timeline'),
      addClients: t('General.addClients'),
      addProviders: t('General.addProviders'),
      selectClients: t('General.selectClients'),
      selectProviders: t('General.selectProviders'),
      suggestedClients: t('General.suggestedClients'),
      suggestedProviders: t('General.suggestedProviders'),
      remove: t('General.remove'),
    }),
    [t],
  );

  const initialValues: Partial<SearchUsersFormValues> = useMemo(
    () => ({
      selectedUserIds: sendToUsers.map(({ id }) => id),
      selectedUsers: sendToUsers,
    }),
    [sendToUsers],
  );

  const closeSelectClientsModal = useCallback(() => {
    selectClientsModal.close();
  }, [selectClientsModal]);

  const openSelectClientsModal = useCallback(() => {
    selectClientsModal.open();
  }, [selectClientsModal]);

  const confirmClientSelection = useCallback(
    (selectedUsers: User[]) => {
      onChangeSendToUsers(selectedUsers);

      closeSelectClientsModal();
    },
    [closeSelectClientsModal, onChangeSendToUsers],
  );

  const removeClient = useCallback(
    (userId: number) => {
      onChangeSendToUsers(sendToUsers.filter(({ id }) => id !== userId));
    },
    [onChangeSendToUsers, sendToUsers],
  );

  return (
    <Card
      className={classes}
      cardTitle={
        <>
          {messages.title}
          <ToolTip t={t} tooltipName="send-to-tooltip">
            <p style={{ whiteSpace: 'pre-line' }}>
              <Trans i18nKey="Preview.sendTo" components={{ b: <b /> }} />
            </p>
          </ToolTip>
        </>
      }
      footer={
        <Button
          type="button"
          variant="link"
          className="anys-send-to__add-clients"
          onClick={openSelectClientsModal}
        >
          <AddIcon />
          <span>
            {isProvideJob ? messages.addClients : messages.addProviders}
          </span>
        </Button>
      }
    >
      <CheckboxField name="sendToTimeline" label={messages.timeline} />

      {!!sendToUsers?.length && (
        <ul className="anys-send-to__clients">
          {sendToUsers.map((client) => {
            const { id, profileImage } = client;

            const displayName = formatUserDisplayName(client);

            return (
              <li key={id} className="anys-send-to__clients__client">
                <Avatar
                  img={profileImage?.resizedUrls?.[80] || profileImage?.url}
                />

                <div className="anys-send-to__clients__client__info">
                  <label>{displayName}</label>

                  <button type="button" onClick={() => removeClient(id)}>
                    {messages.remove}
                  </button>
                </div>
              </li>
            );
          })}
        </ul>
      )}

      <Field
        type="hidden"
        name="sendToTimeline"
        render={(props) => {
          const { input, meta } = props;

          const { hasError, error } = formValidators.getErrorFromMeta(meta);

          return (
            <>
              <input {...input} />
              <ValidationError
                className="mt-16"
                showError={hasError}
                error={error}
              />
            </>
          );
        }}
      />

      <Modal
        type="no-action"
        isFullscreen
        modalName="select-clients"
        ref={selectClientsModalRef}
        title={isProvideJob ? messages.selectClients : messages.selectProviders}
        className="anys-send-to__clients-modal"
        footer={null}
      >
        <SearchUsersForm
          onSubmit={confirmClientSelection}
          onCancel={closeSelectClientsModal}
          title={
            isProvideJob
              ? messages.suggestedClients
              : messages.suggestedProviders
          }
          initialValues={initialValues}
        />
      </Modal>
    </Card>
  );
};

export default SendTo;
