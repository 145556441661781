import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Job } from 'models/Job';
import { convertToMoney, formatMoney } from 'utils/currency';
import { useTranslation } from 'react-i18next';
import GradientText from 'components/GradientText';
import {
  ChangesReturn,
  getArbitrationChanges,
  getAttachmentChanges,
  getContractInfoChanges,
  getDeadlinePolicyChanges,
  getFreeCancellationChanges,
  getSkillsChanges,
  getTimeAndPricingChanges,
  getTypeOfServiceChanges,
  isPendingChanges,
} from 'utils/job-changes';
import ToolTip from 'components/ToolTip';
import WarningIcon from 'icons/Warning.icon';
import JobChanges, { Value } from 'components/JobChanges';
import SlatePreview from 'components/SlatePreview';
import { parseFormTypeOfService, parseTypeOfService } from 'utils/job-parses';
import { isJSONString } from 'utils';

import './JobPreviewPrice.styles.scss';
import './JobPreviewPrice.styles.responsive.scss';
import useGetSkillGroups from '../../hooks/useGetSkillGroups';

type JobPreviewPropsPrice = {
  className?: string;
  job: Partial<Job>;
  isOffer: boolean;
};

const JobPreviewPrice: React.FC<JobPreviewPropsPrice> = (props) => {
  const { className, job, isOffer } = props;

  const { skillOptions } = useGetSkillGroups();

  const { timeAndPricing } = job || {};

  const { t } = useTranslation();

  const {
    type,
    pricingDurationType,
    currency,
    price = 0,
    minHours = 0,
    maxHours = 0,
    totalHours = 0,
    minTotalPrice = 0,
    maxTotalPrice = 0,
  } = timeAndPricing || {};

  const classes = classNames('anys-job-preview-price', className);

  const isPricingByProject = type === 'By project';
  const isPricingHourlyStrict =
    !isPricingByProject && pricingDurationType === 'Strict';

  const lowerCaseHours = t('General.hours').toLowerCase();

  const formattedPrice = formatMoney(t, convertToMoney(price || 0, currency));
  const formattedMinPrice = formatMoney(
    t,
    convertToMoney(minTotalPrice || 0, currency),
  );
  const formattedMaxPrice = formatMoney(
    t,
    convertToMoney(maxTotalPrice || 0, currency),
  );

  const allChanges = useMemo(() => {
    const {
      title,
      description,
      prevVersion,
      versionState,
      isLockedTimeAndPricing,
      timeAndPricing,
      isLockedTypeOfService,
      typeOfService,
      isLockedFreeCancelation,
      hasFreeCancelation,
      freeCancelation,
      isLockedDeadlinePolicy,
      hasDeadlinePolicy,
      deadlines,
      isLockedArbitration,
      arbitrationOptions,
      isNegotiable,
      mainSkill,
      skills,
      attachments,
    } = job || {};

    const { currency, price = 0, startDate, endDate } = timeAndPricing || {};

    if (!prevVersion || !isPendingChanges(versionState)) {
      return null;
    }

    const changes: (ChangesReturn<any> & {
      title: string;
      valueParser?: (value: Value) => Value;
    })[] = [];

    const {
      title: prevTitle,
      description: prevDesc,
      timeAndPricing: prevTimeAndPricing,
    } = prevVersion;

    const contractInfoChanges = getContractInfoChanges(
      t,
      { title: prevTitle, description: prevDesc },
      {
        title,
        description,
      },
    );

    if (contractInfoChanges)
      changes.push({
        ...contractInfoChanges,
        title: t('General.contractInfo'),
        valueParser: (value) => {
          // Check if value is a Slate value (stringified json)
          if (isJSONString(value)) {
            return <SlatePreview>{value as string}</SlatePreview>;
          }

          return value;
        },
      });

    const skillsChanges = getSkillsChanges(
      t,
      {
        mainSkill: prevVersion.mainSkill?.name,
        skills: prevVersion.skills?.map((skill) => skill.name),
      },
      {
        mainSkill: mainSkill?.name,
        skills: skills?.map((skill) => skill.name),
      },
    );

    if (skillsChanges)
      changes.push({ ...skillsChanges, title: t('General.skills') });

    const priceChanges = getTimeAndPricingChanges(
      t,
      {
        ...prevTimeAndPricing,
        price: prevTimeAndPricing.price
          ? convertToMoney(
              prevTimeAndPricing.price,
              prevTimeAndPricing.currency,
            )
          : null,
        startDate: prevTimeAndPricing.startDate
          ? new Date(prevTimeAndPricing.startDate)
          : null,
        endDate: prevTimeAndPricing.endDate
          ? new Date(prevTimeAndPricing.endDate)
          : null,
      },
      {
        ...timeAndPricing,
        price: price ? convertToMoney(price, currency) : null,
        startDate: startDate ? new Date(startDate) : null,
        endDate: endDate ? new Date(endDate) : null,
      },
      prevVersion.isLockedTimeAndPricing,
      isLockedTimeAndPricing,
    );

    if (priceChanges)
      changes.push({ ...priceChanges, title: t('JobForm.timeAndPricing') });

    const typeOfServiceChanges = getTypeOfServiceChanges(
      t,
      // Do a double parse to remove invalid props
      parseFormTypeOfService(parseTypeOfService(prevVersion.typeOfService)),
      parseFormTypeOfService(parseTypeOfService(typeOfService)),
      prevVersion.isLockedTypeOfService,
      isLockedTypeOfService,
    );

    if (typeOfServiceChanges)
      changes.push({
        ...typeOfServiceChanges,
        title: t('JobForm.typeOfService'),
      });

    const cancelationChanges = getFreeCancellationChanges(
      t,
      {
        hasFreeCancelation: `${prevVersion.hasFreeCancelation}`,
        freeCancelation: prevVersion.freeCancelation,
      },
      {
        hasFreeCancelation: `${hasFreeCancelation}`,
        freeCancelation,
      },
      prevVersion.isLockedFreeCancelation,
      isLockedFreeCancelation,
      isOffer,
    );

    if (cancelationChanges)
      changes.push({
        ...cancelationChanges,
        title: t('JobForm.freeCancellation'),
      });

    const deadlineChanges = getDeadlinePolicyChanges(
      t,
      timeAndPricing?.endDate ? new Date(timeAndPricing.endDate) : null,
      {
        hasDeadlinePolicy: `${prevVersion.hasDeadlinePolicy}`,
        deadlines: prevVersion.deadlines?.map((d) => ({
          ...d,
          fromDate: d.fromDate ? new Date(d.fromDate) : null,
          toDate: d.toDate ? new Date(d.toDate) : null,
        })),
      },
      {
        deadlines: deadlines?.map((d) => ({
          ...d,
          fromDate: d.fromDate ? new Date(d.fromDate) : null,
          toDate: d.toDate ? new Date(d.toDate) : null,
        })),
        hasDeadlinePolicy: `${hasDeadlinePolicy}`,
      },
      prevVersion.isLockedDeadlinePolicy,
      isLockedDeadlinePolicy,
    );

    if (deadlineChanges)
      changes.push({ ...deadlineChanges, title: t('DeadlinePolicy.title') });

    const arbitrationChanges = getArbitrationChanges(
      t,
      skillOptions,
      {
        hasArbitration: !!prevVersion.arbitrationOptions?.length,
        arbitrationOptions: prevVersion.arbitrationOptions,
      },
      {
        hasArbitration: !!arbitrationOptions?.length,
        arbitrationOptions: arbitrationOptions,
      },
      prevVersion.isLockedArbitration,
      isLockedArbitration,
      isOffer,
    );

    if (arbitrationChanges)
      changes.push({ ...arbitrationChanges, title: t('General.arbitration') });

    const attachmentChanges = getAttachmentChanges(
      prevVersion.attachments?.map((attch) => ({
        name: attch.name,
        url: attch.url,
        originalFileId: attch.originalFileId,
      })),
      attachments?.map((attch) => ({
        name: attch.name,
        url: attch.url,
        originalFileId: attch.originalFileId,
      })),
    );

    if (attachmentChanges)
      changes.push({ ...attachmentChanges, title: t('General.attachments') });

    const isNegotiabilityChanged =
      `${prevVersion?.isNegotiable}` !== `${isNegotiable}`;

    if (isNegotiabilityChanged)
      changes.push({
        changedFrom: [
          prevVersion.isNegotiable ? t('General.yes') : t('General.no'),
        ],
        changedTo: [isNegotiable ? t('General.yes') : t('General.no')],
        title: t('JobForm.totalOfferNegotiability'),
      });

    if (!changes.length) return null;

    return changes;
  }, [isOffer, job, skillOptions, t]);

  const tooltip = allChanges ? (
    <ToolTip
      t={t}
      tooltipName="all-changes"
      icon={WarningIcon}
      className="anys-job-preview-price__changes"
    >
      {allChanges.map((change, i) => (
        <React.Fragment key={i}>
          <span className="anys-job-preview-price__changes__change-title">
            {change.title}
          </span>
          <JobChanges {...change} />
        </React.Fragment>
      ))}
    </ToolTip>
  ) : null;

  const hourLabelClasses = classNames(
    'anys-job-preview-price__label',
    'flex',
    'ac',
    'jb',
    { 'anys-job-preview-price__label--with-changes': !!allChanges },
  );

  return (
    <div className={classes}>
      {isPricingByProject ? (
        <>
          <div className="flex ac">
            {t('General.totalPrice')}
            {tooltip}
          </div>
          <GradientText gradientType="pink">{formattedMaxPrice}</GradientText>
        </>
      ) : isPricingHourlyStrict ? (
        <div className="flex ac">
          <div className={hourLabelClasses}>
            {`${totalHours} ${lowerCaseHours} • ${formattedPrice}/h`}
            {tooltip}
          </div>
          <GradientText gradientType="pink">{formattedMaxPrice}</GradientText>
        </div>
      ) : (
        <>
          <div className="flex ac">
            <div className={hourLabelClasses}>
              {`${minHours} ${lowerCaseHours} • ${formattedPrice}/h`}
              {tooltip}
            </div>
            <GradientText gradientType="pink">{formattedMinPrice}</GradientText>
          </div>
          <div className="flex ac jb">
            <div className="anys-job-preview-price__label">
              {`${maxHours} ${lowerCaseHours} • ${formattedPrice}/h`}
            </div>
            <GradientText gradientType="pink">{formattedMaxPrice}</GradientText>
          </div>
        </>
      )}
    </div>
  );
};

export default JobPreviewPrice;
