import React, { useContext } from 'react';
import ThemeContext from 'providers/Theme/Theme.context';
import ContentLoader from 'react-content-loader';
import { LoadingProps } from './LoadingProps';

const ImageLoading: React.FC<LoadingProps> = ({
  className,
  contentLoaderProps,
  length = 15,
}) => {
  const { theme } = useContext(ThemeContext);

  const backgroundColor = theme === 'dark' ? '#909194' : '#e7f1fc';
  const foregroundColor = theme === 'dark' ? '#333' : '#ffffff';

  return (
    <div className={className}>
      {Array.from({ length }).map((_, ind) => (
        <ContentLoader
          key={ind}
          viewBox="0 0 650 96"
          backgroundColor={backgroundColor}
          foregroundColor={foregroundColor}
          {...contentLoaderProps}
        >
          <rect x="0" y="0" rx="14" ry="14" width="100%" height="100%" />
        </ContentLoader>
      ))}
    </div>
  );
};

export default ImageLoading;
