import React, { useContext, useEffect } from 'react';
import { InboxItem, InboxUrlParams } from 'models/Inbox';
import showToast from 'modules/showToast';
import eventSocketService from 'services/socket/eventSocketService';
import { Button } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import { getEntityIdForItem } from 'router/subrouters/Inbox/utils';
import { User } from 'models/User';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import { SocketEventNames } from 'constants/socket';
import { useParams } from 'react-router-dom';
import { Arbitration } from 'models/Arbitrations';

type UpdatePayload = {
  sender?: Pick<User, 'id' | 'fullName'>;
} & (
  | {
      inboxItem: Partial<InboxItem>;
    }
  | { arbitration: Partial<Arbitration> }
);

const useEntityUpdateListener = (
  eventName: SocketEventNames = 'inbox-item-updated',
  propEntityId?: number,
) => {
  const { t } = useTranslation();

  const { currentUser } = useContext(CurrentUserContext);

  const params = useParams<InboxUrlParams>();

  // This will be either job-post/job/contract id
  const { id: paramId } = params;

  const currentUserId = currentUser?.id;

  const id = propEntityId || paramId;

  useEffect(() => {
    // We call this hook in a couple of pages,
    // so we don't want to duplicate the same listener.
    // (We expect to only have one listener for this event)
    if (eventSocketService.consumers[eventName]?.[0]) return;

    const handleInboxItemUpdated = debounce((payload: UpdatePayload) => {
      const { sender } = payload;

      // Don't show notification if current user made it
      if (sender?.id === currentUserId) return;

      const entityId =
        'inboxItem' in payload
          ? getEntityIdForItem(payload.inboxItem)
          : payload.arbitration?.id;

      // Update notification if we are not on the entity
      if (+id !== entityId) {
        return;
      }

      // Show toaster only if we are
      // looking at the entity that was updated
      showToast(
        'info',
        sender?.fullName
          ? t('General.newUpdateFromUser', { userName: sender.fullName })
          : t('General.newUpdate'),
        <>
          {t('General.refreshToSeeUpdate')}
          <Button
            variant="link"
            style={{ paddingLeft: 0, paddingBottom: 0 }}
            onClick={() => {
              window.location.reload();
            }}
          >
            <span>{t('General.refresh')}</span>
          </Button>
        </>,
        {
          autoClose: false,
          closeButton: true,
          closeOnClick: false,
        },
      );
    }, 2000);

    eventSocketService.addListener(eventName, handleInboxItemUpdated);

    return () => {
      eventSocketService.removeListener(eventName, handleInboxItemUpdated);
    };
  }, [currentUserId, eventName, id, t]);
};

export default useEntityUpdateListener;
