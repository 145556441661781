import { Contract, ContractRequest } from 'models/Contract';
import httpClient from '../httpClient';
import { AxiosResponse } from 'axios';
import utils from 'utils';
import { PaginatedResponse, PaginationParams } from 'models/Pagination';
import { Review } from 'models/Reviews';

type CreateReviewBody = {
  commonId: number;
  rating: number;
  content?: string;
};

type RequestPaymentType = {
  isCancellation: boolean;
  rating: number;
  content: string;
  price: number;
};

const getContract = (
  contractId: number,
): Promise<AxiosResponse<{ contract: any }>> =>
  httpClient.get(`contracts/${contractId}`);

const endContract = (contractId: number, explanation: string) =>
  httpClient.post(`contracts/${contractId}/request-end`, {
    explanation,
  });

const aproveEndContract = (contractId: number) =>
  httpClient.post(`contracts/${contractId}/approve-end`);

const refuseEndContract = (contractId: number) =>
  httpClient.post(`contracts/${contractId}/refuse-end`);

const cancellationFeeEnd = (commonId: number, amount: number) =>
  httpClient.post(`contracts/${commonId}/end`, { amount });

const createReview = (props: CreateReviewBody) =>
  httpClient.post(`reviews`, props);

const updateReview = ({ commonId, ...rest }: CreateReviewBody) =>
  httpClient.put(`reviews/${commonId}`, rest);

const getReviews = (params: PaginationParams) =>
  httpClient.get<PaginatedResponse<Review>>(`reviews`, {
    params,
  });

const requestPayment = (commonId: number, body: RequestPaymentType) =>
  httpClient.post(`contracts/${commonId}/request-payment`, body);

const counterPayment = (commonId: number, price: number) =>
  httpClient.post(`contracts/${commonId}/counter-payment`, { price });

const acceptPayment = (commonId: number, price: number) =>
  httpClient.post(`contracts/${commonId}/accept-payment`, { price });

const declinePayment = (commonId: number) =>
  httpClient.post(`contracts/${commonId}/decline-payment`);

const requestContractChange = (
  commonId: number,
  contractVersion: number,
  body: ContractRequest,
) => {
  return httpClient.post<{ contract: Contract }>(
    `contracts/${commonId}/request-change/${contractVersion}`,
    { ...body },
  );
};

const confirmContractChange = (commonId: number, contractVersion: number) => {
  return httpClient.post<{ contract: Contract }>(
    `contracts/${commonId}/confirm-change/${contractVersion}`,
  );
};

const rejectContractChange = (commonId: number, contractVersion: number) => {
  return httpClient.post<{ contract: Contract }>(
    `contracts/${commonId}/reject-change/${contractVersion}`,
  );
};

const uploadFiles = (commonId: number, version: number, files: File[]) => {
  const formData = utils.prepareFilesForUpload(files, 'job-attachments');

  return httpClient.post<{ contract: Contract }>(
    `contracts/${commonId}/files/${version}`,
    formData,
  );
};

const deleteFile = (commonId: number, version: number, removeId: number) => {
  return httpClient.delete<{ message: string }>(
    `contracts/${commonId}/files/${removeId}/${version}`,
  );
};

export default {
  getContract,
  endContract,
  aproveEndContract,
  refuseEndContract,
  cancellationFeeEnd,
  createReview,
  updateReview,
  getReviews,
  requestPayment,
  counterPayment,
  acceptPayment,
  declinePayment,
  requestContractChange,
  confirmContractChange,
  rejectContractChange,
  uploadFiles,
  deleteFile,
};
