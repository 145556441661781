import { Arbitration } from 'models/Arbitrations';
import { PaginatedResponse, PaginationParams } from 'models/Pagination';
import httpClient from 'api/httpClient';

const getArbitration = (id: number) =>
  httpClient.get<Arbitration>(`arbitrations/${id}`);

// MY CASES

const getArbitrationsAsDefendant = (params: PaginationParams) =>
  httpClient.get<PaginatedResponse<Arbitration>>(`/arbitrations/as-defendant`, {
    params,
  });

const addFunds = (id: number) =>
  httpClient.post<Arbitration>(`arbitrations/${id}/add-funds`);

const addProof = (id: number, formData: FormData) =>
  httpClient.post(`arbitrations/${id}/proof`, formData);

// ME AS ARBITRATOR

const getArbitrationsAsArbiter = (params: PaginationParams) =>
  httpClient.get<PaginatedResponse<Arbitration>>(`/arbitrations/as-arbiter`, {
    params,
  });

const acceptArbitration = (id: number) =>
  httpClient.post(`arbitrations/${id}/accept`);

const declineArbitration = (id: number) =>
  httpClient.post(`arbitrations/${id}/decline`);

const askQuestions = (
  id: number,
  questions: string[],
  questionsFor: 'client' | 'provider',
) => httpClient.post(`arbitrations/${id}/ask`, { questions, questionsFor });

const answerQuestions = (
  id: number,
  inquiries: { id: number; answer: string }[],
) => httpClient.post(`arbitrations/${id}/answer`, { inquiries });

const rejectArbitration = (id: number) =>
  httpClient.post(`arbitrations/${id}/reject`);

const voteInArbitration = (id: number, voteFor: 'client' | 'provider') =>
  httpClient.post(`arbitrations/${id}/vote`, { voteFor });

const decideArbitationChangePayment = (id: number, payoutAmount: number) =>
  httpClient.post(`arbitrations/${id}/change-payment`, { payoutAmount });

export default {
  getArbitrationsAsArbiter,
  getArbitrationsAsDefendant,
  getArbitration,
  addFunds,
  addProof,
  acceptArbitration,
  declineArbitration,
  askQuestions,
  rejectArbitration,
  voteInArbitration,
  decideArbitationChangePayment,
  answerQuestions,
};
