import React, { useCallback, useEffect, useId, useRef, useState } from 'react';
import classNames from 'classnames';
import {
  OptionValue,
  SelectProps,
} from 'ncoded-component-library/build/components/molecules/Select/Select.component';
import PhabletSearchableSelect from 'components/PhabletSearchableSelect';
import MultipleSelectTrigger from 'components/MultipleSelectTrigger';
import useMatchMedia from 'hooks/useMatchMedia';
import { Button, Dropdown, Input } from 'ncoded-component-library';
import { t } from 'i18next';
import CheckboxSelectOptions from 'components/CheckboxSelectOptions';
import useFilteredOptions from 'modules/select/hooks/useFilteredOptions';
import { DropdownRef } from 'ncoded-component-library/build/components/molecules/Dropdown/Dropdown.component';
import SelectOptions from 'components/SelectOptions';

import './FilterSelect.styles.scss';
import './FilterSelect.styles.responsive.scss';

export type FilterSelectProps<T extends string | number> = SelectProps<T> & {
  label: string;
  inputValue?: string;
  modalClassName?: string;
  inputRef?: (input: HTMLInputElement) => void;
};

function FilterSelect<T extends string | number>(props: FilterSelectProps<T>) {
  const {
    className,
    multiple,
    name,
    value,
    icon,
    async,
    placeholder,
    label,
    options,
    inputValue,
    onChange,
    inputRef,
    ...rest
  } = props;

  const inputId = useId();

  const dropdownRef = useRef<DropdownRef>();

  const [inputVal, setInputVal] = useState('');
  const isPhablet = useMatchMedia('isPhablet');
  const classes = classNames('anys-filter-select', className);

  const finalInputVal = inputValue !== undefined ? inputValue : inputVal;

  const [chosenOption, setChosenOption] = useState<OptionValue<T>>(
    value as OptionValue<T>,
  );
  const [selectedOptions, setSelectedOptions] = useState<T[]>(value as T[]);
  const filteredOptions = useFilteredOptions(options, inputVal, async);

  const trigger = <MultipleSelectTrigger<T> {...props} />;

  const apply = useCallback(
    (option?: OptionValue<T>) => {
      if (multiple === false) {
        onChange(option);
      } else {
        onChange(selectedOptions);
      }
      dropdownRef.current?.setIsOpen(false);
    },
    [multiple, onChange, selectedOptions],
  );

  const reset = useCallback(() => {
    if (multiple) setSelectedOptions([]);
    else setChosenOption(null);
  }, [multiple]);

  useEffect(() => {
    if (value) return;

    setChosenOption(null);
    setSelectedOptions(null);
  }, [value]);

  return isPhablet ? (
    <PhabletSearchableSelect
      {...rest}
      onClose={() => {
        if (multiple === false) {
          return;
        }

        setSelectedOptions(value as T[]);
      }}
      multiple={multiple as any}
      value={(multiple ? selectedOptions : chosenOption) as any}
      options={options}
      customTrigger={trigger}
      handleAdd={(option) => {
        if (multiple === false) {
          apply(option);
          return;
        }
        setSelectedOptions((old) => [...old, option.value]);
      }}
      handleRemove={(option) => {
        if (!multiple) setChosenOption(null);
        else setSelectedOptions((old) => old.filter((o) => o !== option.value));
      }}
      icon={icon}
      onApply={() => apply()}
      onReset={reset}
      label={label}
      inputValue={finalInputVal}
    />
  ) : (
    <Dropdown
      ref={dropdownRef}
      renderAsPortal
      portalClassName={classes}
      trigger={trigger}
      onOpen={() => {
        if (multiple === false) setChosenOption(value as OptionValue<T>);
        else setSelectedOptions(value as T[]);
      }}
      closeWithJS
    >
      {label && <label>{label}</label>}
      {rest.searchable && (
        <Input
          id={inputId}
          placeholder={placeholder}
          value={finalInputVal}
          onChange={(ev) => {
            setInputVal(ev.target.value);
            rest.onSearchChange?.(ev);
          }}
          className="anys-filter-select__input"
          ref={inputRef}
          {...rest.searchInputProps}
        />
      )}
      {multiple ? (
        <CheckboxSelectOptions
          className="anys-filter-select__results"
          options={filteredOptions}
          value={selectedOptions}
          multiple={multiple}
          onOptionCheck={(checked, option) =>
            setSelectedOptions((old = []) =>
              !checked
                ? old.filter((v) => v !== option.value)
                : [...old, option.value],
            )
          }
          noOptionsContent={
            <p>{t('General.noResultsFor', { term: inputVal })}</p>
          }
        />
      ) : (
        <SelectOptions
          className="anys-filter-select__results"
          options={options}
          value={chosenOption}
          onOptionCheck={(_, o) => setChosenOption(o)}
        />
      )}

      <hr />
      <div className="anys-filter-select__actions">
        <Button onClick={() => apply()}>{t('General.apply')}</Button>
        <Button variant="link" onClick={reset}>
          <span>{t('General.reset')}</span>
        </Button>
      </div>
    </Dropdown>
  );
}

export default FilterSelect;
