import { EnhancedRouteProps } from 'router/routes/Enhanced/Enhanced.route';
import AnyCoinList from './pages/AnyCoinList/AnyCoinList.page';
import WalletPage from './pages/Wallet/Wallet.page';

export default [
  {
    path: 'anycoins-list',
    element: AnyCoinList,
    authorized: true,
  },
  {
    path: '',
    element: WalletPage,
    authorized: true,
  },
] as Array<EnhancedRouteProps>;
