import httpClient from '../httpClient';
import { PaginatedResponse, PaginationParams } from 'models/Pagination';
import { InboxItem } from 'models/Inbox';

const inboxItemsPath = (routePath: TemplateStringsArray) =>
  `inbox-items/${routePath}`;

const getInboxItemsWithoutOffers = (params: PaginationParams) => {
  return httpClient.get<PaginatedResponse<InboxItem>>(
    inboxItemsPath`without-offers`,
    { params },
  );
};

const getInboxItems = (params: PaginationParams) => {
  return httpClient.get<PaginatedResponse<InboxItem>>(inboxItemsPath``, {
    params,
  });
};

const getInboxItem = (
  id: number,
  $relations: PaginationParams['$relations'],
) => {
  return httpClient.get<InboxItem>(inboxItemsPath`` + id, {
    params: { $relations },
  });
};

const updateInboxItems = (data: { isFavourite: boolean; ids: number[] }) => {
  return httpClient.patch<
    { id: number; isFavourite: boolean; updatedAt: string }[]
  >(inboxItemsPath``, data);
};

const updateInboxItem = (itemId: number, body: Partial<InboxItem>) => {
  return httpClient.patch<{
    id: number;
    isFavourite: boolean;
    updatedAt: string;
  }>(inboxItemsPath`` + itemId, body);
};

const deleteInboxItems = (ids: number[]) => {
  return httpClient.delete<{ message: string; deletedItems: InboxItem[] }>(
    inboxItemsPath``,
    { data: { ids } },
  );
};

const deleteInboxItem = (itemId: number) => {
  return httpClient.delete<{ deletedItem: InboxItem }>(
    inboxItemsPath`` + itemId,
  );
};

export default {
  getInboxItems,
  getInboxItem,
  updateInboxItems,
  updateInboxItem,
  deleteInboxItems,
  deleteInboxItem,
  getInboxItemsWithoutOffers,
};
