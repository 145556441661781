import React from 'react';
import { t } from 'i18next';
import ProfileCard, { CardProps } from 'components/Card/ProfileCardComponent';
import useCallbackRef from 'hooks/useCallbackRef';
import { ModalRef } from 'components/Modal';
import ProfilePreviewModal from '../ProfilePreviewModal';

type ProfileCardModalProps = CardProps & {
  cardTitle: string;
  modalTitle: string;
  modalName: string;
  itemList: JSX.Element[];
};

const ProfileCardModal: React.FC<ProfileCardModalProps> = (props) => {
  const { cardTitle, modalTitle, modalName, itemList, ...rest } = props;

  const [modal, modalRef] = useCallbackRef<ModalRef>(null);

  return (
    <ProfileCard
      cardTitle={cardTitle}
      {...(itemList.length > 2 && {
        buttonLabel: t('General.seeMore'),
        onClick: () => {
          modal.open();
        },
      })}
      {...rest}
    >
      <>
        {itemList?.slice(0, 2)}
        <ProfilePreviewModal
          modalName={modalName}
          title={modalTitle}
          modalRef={modalRef}
        >
          {itemList}
        </ProfilePreviewModal>
      </>
    </ProfileCard>
  );
};

export default ProfileCardModal;
