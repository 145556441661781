import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Job, JobPost } from 'models/Job';
import { useTranslation } from 'react-i18next';
import Timeline, { TimelineItem } from 'components/Timeline';
import dates from 'utils/dates';
import Chip from 'components/Chip';
import { getDeadlinePolicyChanges, isPendingChanges } from 'utils/job-changes';
import ToolTip from 'components/ToolTip';
import WarningIcon from 'icons/Warning.icon';
import JobChanges from 'components/JobChanges';

import './DeadlinePreview.styles.scss';

type DeadlinePreviewProps = {
  className?: string;
  jobEndDate: Date;
} & Pick<JobPost<'form'>, 'deadlines'> &
  Pick<Job, 'prevVersion'> &
  Required<Pick<Job, 'versionState' | 'isLockedDeadlinePolicy'>>;

const DeadlinePreview: React.FC<DeadlinePreviewProps> = (props) => {
  const {
    className,
    deadlines = [],
    prevVersion,
    versionState,
    isLockedDeadlinePolicy,
    jobEndDate,
  } = props;

  const { t } = useTranslation();

  const classes = classNames('anys-deadline-preview', className);

  const timelineItemClassName =
    'anys-deadline-preview__timeline-wrapper__timeline-step';

  const messages = useMemo(
    () => ({
      preview: t('General.preview'),
      deadline: t('General.deadline'),
      value: t('General.value'),
      endTime: t('General.endTime'),
    }),
    [t],
  );

  const deadlineChanges =
    prevVersion && isPendingChanges(versionState)
      ? getDeadlinePolicyChanges(
          t,
          jobEndDate,
          {
            hasDeadlinePolicy: `${prevVersion.hasDeadlinePolicy}`,
            deadlines: prevVersion.deadlines?.map((d) => ({
              ...d,
              fromDate: d.fromDate ? new Date(d.fromDate) : null,
              toDate: d.toDate ? new Date(d.toDate) : null,
            })),
          },
          {
            hasDeadlinePolicy: `${deadlines?.length > 0}`,
            deadlines,
          },
          prevVersion.isLockedDeadlinePolicy,
          isLockedDeadlinePolicy,
        )
      : null;

  const timelineItems = useMemo(() => {
    const timelines: TimelineItem[] = [
      { content: <Chip>100%</Chip>, className: timelineItemClassName },
    ];

    deadlines.forEach((d, i) => {
      // The first deadline date
      // is displayed as End time
      const title =
        i === 0
          ? jobEndDate
            ? dates.formatDeadlineDate(jobEndDate)
            : messages.endTime
          : d.fromDate
            ? dates.formatDeadlineDate(d.fromDate)
            : messages.deadline;

      timelines.push({
        title,
        content: (
          <Chip>
            {d.percentOfJobValue
              ? `${d.percentOfJobValue}%`
              : `${messages.value} %`}
          </Chip>
        ),
        isCheckpoint: i < deadlines.length,
        className: classNames(timelineItemClassName, {
          'anys-deadline-preview__timeline-wrapper__timeline-step--no-percent':
            !d.percentOfJobValue && !d.fromDate,
        }),
      });
    });

    return timelines;
  }, [deadlines, jobEndDate, messages]);

  const canShowPreview =
    Number.isFinite(deadlines?.[0]?.percentOfJobValue) || deadlines?.length > 1;

  return (
    canShowPreview && (
      <div className={classes}>
        <label>
          {deadlines.length > 1 ? messages.deadline : messages.preview}
          {deadlineChanges ? (
            <ToolTip t={t} icon={WarningIcon} tooltipName="deadline-changes">
              <JobChanges
                changedFrom={deadlineChanges.changedFrom}
                changedTo={deadlineChanges.changedTo}
              />
            </ToolTip>
          ) : null}
        </label>

        <div className="anys-deadline-preview__timeline-wrapper">
          <Timeline items={timelineItems} />
        </div>
      </div>
    )
  );
};

export default DeadlinePreview;
