import React, { useCallback, useEffect, useRef } from 'react';
import classNames from 'classnames';
import Input from 'components/Input/Input.component';
import { PlaceFormat } from 'models/User';
import useLocationAutocomplete from 'hooks/useLocationAutocomplete';
import DropdownOptions from 'components/DropdownOptions';
import { DropdownRef } from 'ncoded-component-library/build/components/molecules/Dropdown/Dropdown.component';
import { useTranslation } from 'react-i18next';

import './InputAutocompleteLocation.styles.scss';

export type InputAutocompleteLocationProps = {
  className?: string;
  name: string;
  value: PlaceFormat & { placeId?: string };
  useProfileLocation?: boolean;
  locationPromptInitially?: boolean;
  withBounds?: boolean;
  withPlaceId?: boolean;
  withFullAddress?: boolean;
  rawPlaceRef?: React.MutableRefObject<google.maps.places.PlaceResult>;
  onChange?: (value: PlaceFormat & { placeId?: string }) => void;
} & Omit<React.ComponentProps<typeof Input>, 'onChange'>;

const InputAutocompleteLocation: React.FC<
  React.PropsWithChildren<InputAutocompleteLocationProps>
> = (props) => {
  const {
    className,
    name,
    value,
    useProfileLocation,
    locationPromptInitially = false,
    withBounds,
    withPlaceId,
    withFullAddress,
    onChange,
    onBlur,
    onFocus,
    rawPlaceRef,
    ...rest
  } = props;

  const { t } = useTranslation();

  const dropdownRef = useRef<DropdownRef>();

  const classes = classNames(
    'anys-input-autocomplete-location',
    'predictions-dropdown',
    className,
  );

  const onPlaceSelect = useCallback(
    (place: PlaceFormat, placeId: string) => {
      onChange(
        place
          ? { ...place, ...(withPlaceId && placeId ? { placeId } : {}) }
          : null,
      );

      dropdownRef.current.setIsOpen(false);
    },
    [onChange, withPlaceId],
  );

  const {
    query,
    dropdownOptions,
    isInputFocused,
    onInputChange,
    onInputBlur,
    onInputFocus,
  } = useLocationAutocomplete({
    value,
    useProfileLocation,
    locationPromptInitially,
    withBounds,
    withFullAddress,
    rawPlaceRef,
    onPlaceSelect,
  });

  const noOptionsContent = query
    ? t('General.noLocationFound')
    : t('General.startLocationSearch');

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      onBlur?.(event);

      onInputBlur();
    },
    [onBlur, onInputBlur],
  );

  const handleFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      onFocus?.(event);

      onInputFocus();
    },
    [onFocus, onInputFocus],
  );

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onInputChange(event);

      dropdownRef.current?.setIsOpen(true);
    },
    [onInputChange],
  );

  useEffect(() => {
    if (!isInputFocused) return;

    dropdownRef.current?.setIsOpen(true);
  }, [isInputFocused]);

  return (
    <DropdownOptions
      options={dropdownOptions}
      className={classes}
      listClassName="predictions-dropdown__list"
      dropdownRef={dropdownRef}
      disabled
      closeWithJS={false}
      noOptionsContent={noOptionsContent}
      trigger={
        <Input
          {...rest}
          onFocus={handleFocus}
          onBlur={handleBlur}
          name={name}
          onChange={handleInputChange}
          autoComplete="off"
          value={query}
          className="anys-input-autocomplete-location__input"
        />
      }
    />
  );
};

export default InputAutocompleteLocation;
