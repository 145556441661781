import socketService, { SocketService } from './socketService';
import io from 'socket.io-client';
import credentialService from '../credentialsService';
import env from 'env';
import { SOCKET_EVENT_NAMES } from 'constants/socket';

const SERVER_ENDPOINT = env.SERVER_ENDPOINT;

function connect(this: SocketService) {
  return new Promise((resolve, reject) => {
    this.socket = io(SERVER_ENDPOINT + '/users');

    Object.values(SOCKET_EVENT_NAMES).forEach((event) => {
      this.socket.on(event, (data: any) => {
        (this.consumers[event] || []).forEach((cb) => cb(data));
      });
    });
    const token = credentialService.token;

    this.socket.on('connect', () => {
      if (token) {
        this.sendEvent(SOCKET_EVENT_NAMES.AUTH, token);
      }
    });

    this.socket.once(SOCKET_EVENT_NAMES.AUTH_SUCCESS, resolve);

    this.socket.on('disconnect', (message) => {
      reject({
        message,
      });
    });
  });
}

export default socketService(connect);
