import React from 'react';
import classNames from 'classnames';
import ResponsiveImage from 'components/ResponsiveImage';
import { Picture } from 'models/User';

import './PhotoContent.styles.scss';

type PhotoContentProps = {
  className?: string;
  photos: Picture[];
  openImagePreview: (currImgIndex: number, images: Picture[]) => void;
};

const PhotoContent: React.FC<PhotoContentProps> = (props) => {
  const { className, photos, openImagePreview } = props;

  const hasMultiPhotos = photos?.length > 1;

  const classes = classNames(
    'anys-photo-content',
    {
      'anys-photo-content--multi': hasMultiPhotos,
    },
    className,
  );

  return (
    photos?.length > 0 && (
      <div className={classes}>
        {photos.map(({ id, name, url, resizedUrls }, i) => {
          return (
            <button
              type="button"
              key={id}
              onClick={() => openImagePreview(i, photos)}
            >
              <ResponsiveImage
                alt={name}
                src={url}
                className={classNames('anys-photo-content__photo', {
                  'anys-photo-content__photo--multi': hasMultiPhotos,
                })}
                resizedVariants={resizedUrls}
                sizes="(max-width: 1280px) 564px,300px"
                onLoad={(e) => {
                  e.currentTarget.classList.add('loaded');
                }}
              />
            </button>
          );
        })}
      </div>
    )
  );
};

export default PhotoContent;
