import React from 'react';
import Offers from './Offers.page';
import ProfileProvider from '../ProfilePreview/providers/Profile';

const DefaultProfile = () => (
  <ProfileProvider>
    <Offers />
  </ProfileProvider>
);

export default DefaultProfile;
