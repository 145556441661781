import React, { useCallback, useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { JobPost } from 'models/Job';
import api from 'api';
import { parseJobPostFormValues } from './utils';
import OverlaySpinner from 'components/OverlaySpinner';
import { AxiosError } from 'axios';
import NavBar from 'components/NavBar';
import { parseJobPostFormValuesToJob } from 'router/subrouters/Job/pages/Job/utils';
import useJobActions from 'router/subrouters/Job/hooks/useJobActions';
import { getTranslationForJobState, isFile } from 'utils/job';
import MainTemplate from 'components/templates/MainTemplate';
import useHeaderStyles from 'hooks/useHeaderStyles';
import useInboxLink from 'router/subrouters/Inbox/hooks/useInboxLink';
import useHeaderContent from 'hooks/useHeaderContent';
import { getEntityInboxState } from 'router/subrouters/Inbox/utils';
import showToast from 'modules/showToast';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import { FormApi } from 'final-form';
import eventSocketService from 'services/socket/eventSocketService';
import useEntityUpdateListener from 'hooks/useEntityUpdateListener';
import UserScheduleProvider from 'components/JobPostPreview/components/UserScheduleModal/UserScheduleProvider';

import './JobPost.styles.scss';
import './JobPost.styles.responsive.scss';

type JobPostProps = {
  className?: string;
  asideLeft?: React.ReactNode;
  asideRight?: React.ReactNode;
};

export type JobPostContext = {
  isSavingJobPost: boolean;
  jobPost?: JobPost;
  fetchingJobPost: boolean;
  isOwnJob: boolean;
  createUpdateJobPost: (values: JobPost<'form'>) => Promise<JobPost>;
  updateJobPostAsOtherUser: (values: JobPost<'form'>) => Promise<void>;
  sendJobPost: (jobPost: JobPost, isSigned: boolean) => Promise<void>;
  sendAndUpdateJobPost: (
    values: JobPost<'form'>,
    shouldSend?: boolean,
  ) => Promise<JobPost | undefined>;
  setJobPost: React.Dispatch<React.SetStateAction<JobPost>>;
  onSubmitForm: (
    values: JobPost<'form'>,
    formApi?: FormApi<JobPost<'form'>>,
    isSubmitFromSign?: boolean,
  ) => Promise<void>;
};

const JobPostComponent: React.FC<JobPostProps> = (props) => {
  const { className, asideLeft, asideRight } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams<{ id: string }>();
  const [isSavingJobPost, setIsSavingJobPost] = useState(false);
  const [fetchingJobPost, setFetchingJobPost] = useState(false);
  const [jobPost, setJobPost] = useState<JobPost>(null);
  const { t } = useTranslation();

  const { currentUser } = useContext(CurrentUserContext);

  const { id: currentUserId } = currentUser || {};

  const { createEntityLink } = useInboxLink();

  useHeaderStyles(
    {
      showBackButton: true,
      hasBorder: true,
      isHeaderShown: true,
      isBackButtonTransparent: false,
      absolutePosition: false,
      className: null,
    },
    [location.pathname],
    true,
  );

  const isInbox = location.pathname.startsWith('/inbox');

  useHeaderContent(
    isInbox && !!jobPost && (
      <div className="mobile-header-content">
        {getTranslationForJobState(t, getEntityInboxState(jobPost, 'job-post'))}
      </div>
    ),
    [isInbox, !!jobPost],
    isInbox && !!jobPost ? 0 : 2,
  );

  const isEdit = location.pathname.includes('edit');

  const isCreate = location.pathname.includes('/create/');

  const isPreview = !isEdit && !location.pathname.includes('create');

  const isBoost = isEdit && +id && location.pathname.includes('/boost');

  const isFromMyOffers =
    (location.state as { for?: 'MyOffers' })?.for === 'MyOffers';

  const isDraft = isCreate || jobPost?.state === 'Draft';

  const isBoostCanceled = jobPost?.positionBoost?.state === 'canceled';

  const isOwnJob = isCreate || jobPost?.user?.id === currentUser?.id;

  const navBarClasses = classNames({
    'anys-job-post__navbar--hide': isPreview || isBoost,
  });
  const classes = classNames(
    'anys-job-post',
    {
      'anys-job-post--boost-view': isBoost,
    },
    className,
  );

  const { trackingData } = jobPost || {};

  const getJobPost = useCallback(
    async (jobId: number) => {
      OverlaySpinner.show('.anys-job-post');

      setFetchingJobPost(true);

      try {
        const {
          data: { jobPost },
        } = await api.jobPost.getJobPost(jobId, {
          $relations: 'boosts,positionBoost',
        });

        setJobPost(jobPost);
      } catch (error) {
        console.error(error);

        if ((error as AxiosError)?.response?.status === 404) {
          navigate('/not-found', { replace: true });
        } else {
          const err = error as AxiosError;

          showToast(
            'error',
            t('General.error'),
            err?.response?.data?.error?.message,
          );
        }
      } finally {
        OverlaySpinner.hide('.anys-job-post');
        setFetchingJobPost(false);
      }
    },
    [navigate, t],
  );

  const createUpdateJobPost = useCallback(
    async (values: JobPost<'form'>) => {
      setIsSavingJobPost(true);
      OverlaySpinner.show('.anys-job-post');

      try {
        const {
          attachments = [],
          attachmentIdsToDelete = [],
          ...restOfValues
        } = values;

        const reqBody: JobPost<'request'> =
          parseJobPostFormValues(restOfValues);

        const { type, boosts, id: formJobPostId, ...updateBody } = reqBody;

        const reqFn = id
          ? api.jobPost.updateJobPost
          : api.jobPost.createJobPost;

        const body = id ? updateBody : reqBody;

        const {
          data: { jobPost },
        } = await reqFn(body, +id);

        const onlyFiles = attachments.filter(isFile);

        if (onlyFiles.length) {
          const res = await api.jobPost.uploadJobPostFiles(
            jobPost.id,
            onlyFiles,
          );

          jobPost.attachments = res.data.jobPost.attachments;
        }

        if (attachmentIdsToDelete?.length) {
          await Promise.all(
            attachmentIdsToDelete.map((deleteId) =>
              api.jobPost.deleteJobPostFile(+id, deleteId),
            ),
          );
        }

        setJobPost({
          ...jobPost,
          attachments: jobPost.attachments?.filter(
            (file) => !attachmentIdsToDelete.includes(file.id),
          ),
        });

        return jobPost;
      } catch (error) {
        const err = error as AxiosError;

        showToast(
          'error',
          t('General.error'),
          err?.response?.data?.error?.message,
        );

        console.error(error);
      } finally {
        setIsSavingJobPost(false);
        OverlaySpinner.hide('.anys-job-post');
      }
    },
    [id, t],
  );

  const sendJobPost = useCallback(
    async (jobPost: JobPost, isSigned: boolean) => {
      const { id, sendToTimeline } = jobPost;

      try {
        await api.jobPost.sendJobPost(+jobPost.id, isFromMyOffers);

        if (isFromMyOffers) {
          return;
        }

        if (sendToTimeline) {
          setJobPost({
            ...jobPost,
            isSigned: isSigned,
            state: isSigned ? 'Offer' : 'Ad',
          });

          navigate(
            isInbox
              ? createEntityLink('view', 'job-post', id)
              : `/job-post/${id}`,
            { replace: true },
          );
        } else {
          navigate('/inbox', { replace: true });
        }
      } catch (error) {
        const err = error as AxiosError;

        showToast(
          'error',
          t('General.sendingError'),
          err?.response?.data?.error?.message,
        );
      }
    },
    [createEntityLink, isFromMyOffers, isInbox, navigate, t],
  );

  const sendAndUpdateJobPost = useCallback(
    async (values: JobPost<'form'>, shouldSend = true) => {
      const errorMsg = shouldSend
        ? t('General.sendingError')
        : t('General.error');

      try {
        const jobPost = await createUpdateJobPost(values);

        if (!jobPost?.id) {
          showToast('error', errorMsg);

          return;
        }

        OverlaySpinner.show('.anys-job-post');

        const { isSigned, sendToTimeline, sendToUsers } = jobPost;

        let isSignedFinal = isSigned;

        // If the user signed the post
        if (values.isSigned) {
          OverlaySpinner.hide('.anys-job-post');

          // TODO: check do we need this
          // const shouldKeepSigned = await confirm({
          //   title: t('General.actionConfirmKeepSign'),
          //   confirmContent: t('General.yes'),
          // });

          OverlaySpinner.show('.anys-job-post');

          // if (shouldKeepSigned) {
          const {
            data: { jobPost: signedJobPost },
          } = await api.jobPost.signJobPost(jobPost.id);

          isSignedFinal = signedJobPost.isSigned;
          // }
        }

        jobPost.isSigned = isSignedFinal;

        if (
          isFromMyOffers ||
          (shouldSend && (sendToTimeline || sendToUsers?.length > 0))
        ) {
          await sendJobPost(jobPost, isSignedFinal);
        }

        if (isFromMyOffers) {
          await api.user.addDisplayedOffer(currentUserId, {
            jobPostId: jobPost.id,
          });

          navigate('/profile', { replace: true });

          return;
        }

        setJobPost(jobPost);

        return jobPost;
      } catch (error) {
        const err = error as AxiosError;

        showToast('error', errorMsg, err?.response?.data?.error?.message);
        console.error(error);
      } finally {
        OverlaySpinner.hide('.anys-job-post');
      }
    },
    [
      createUpdateJobPost,
      currentUserId,
      isFromMyOffers,
      navigate,
      sendJobPost,
      t,
    ],
  );

  // We will send jobId and jobVersion in signJob
  const { signJob } = useJobActions({ jobCommonId: null, jobVersion: null });

  const handleTrackBuy = useCallback(() => {
    if (!trackingData) return;

    try {
      api.positionBoostMetrics.trackBuy(trackingData);
    } catch (error) {
      console.error(error);
    }
  }, [trackingData]);

  const updateJobPostAsOtherUser = useCallback(
    async (values: JobPost<'form'>) => {
      // If we are not the owner of the post,
      // we need to first create a job and then
      // do the update
      try {
        setIsSavingJobPost(true);

        const {
          data: { job },
        } = await api.jobPost.reserveJobPost(values.id, values.timeAndPricing);

        handleTrackBuy();

        let jobVersion = job.version;

        const { attachments, id, attachmentIdsToDelete, ...restOfValues } =
          values;

        const parsedValues = parseJobPostFormValuesToJob(restOfValues, false);

        const {
          data: { job: updatedJob },
        } = await api.job.updateJob(job.commonId, job.version, parsedValues);

        jobVersion = updatedJob.version;

        const onlyFiles = attachments.filter(isFile);

        if (onlyFiles.length) {
          await api.job.uploadFiles(job.commonId, jobVersion, onlyFiles);
        }

        if (attachmentIdsToDelete?.length) {
          await Promise.all(
            attachmentIdsToDelete.map((deleteId) =>
              api.job.deleteFile(job.commonId, jobVersion, deleteId),
            ),
          );
        }

        if (values.isSigned) {
          await signJob(job.commonId, jobVersion);
        } else {
          navigate(
            isInbox
              ? createEntityLink('view', 'job', job.commonId)
              : `/job/${job.commonId}`,
            { replace: true },
          );
        }
      } catch (error) {
        console.error(error);
        const err = error as AxiosError;

        showToast(
          'error',
          t('General.error'),
          err?.response?.data?.error?.message,
        );
      } finally {
        setIsSavingJobPost(false);
      }
    },
    [createEntityLink, handleTrackBuy, isInbox, navigate, signJob, t],
  );

  const onSubmitForm = useCallback(
    async (
      values: JobPost<'form'>,
      formApi: FormApi<JobPost<'form'>>,
      isSubmitFromSign: boolean,
    ) => {
      // If boost is canceled, we don't want
      // to redirect owner to boost page
      if (isOwnJob && isBoostCanceled) {
        sendAndUpdateJobPost(values, !isSubmitFromSign);

        return;
      }

      if (isDraft || isOwnJob) {
        const { id, sendToTimeline, sendToUsers, isSigned } =
          (await sendAndUpdateJobPost(
            values,
            !isSubmitFromSign && !isFromMyOffers,
          )) || {};

        if (id) {
          // This redirect is here so we can handle a case
          // if someone goes 'back' using the browser arrow,
          // so we can show a created job post.
          navigate(
            isInbox
              ? createEntityLink('edit', 'job-post', id)
              : `/job-post/edit/${id}`,
            { replace: true },
          );

          if (sendToTimeline && !sendToUsers?.length) {
            navigate(
              isInbox
                ? createEntityLink(
                    'edit',
                    'job-post',
                    id,
                    undefined,
                    undefined,
                    '/boost',
                  )
                : `/job-post/edit/${id}/boost`,
              { replace: true },
            );
          } else if (
            (isSigned && isSubmitFromSign) ||
            (!sendToTimeline && !sendToUsers?.length)
          ) {
            navigate(
              isInbox
                ? createEntityLink('view', 'job-post', id)
                : `/job-post/${id}`,
              { replace: true },
            );
          } else {
            navigate('/inbox', { replace: true });
          }
        }

        return;
      }

      await updateJobPostAsOtherUser(values);
    },
    [
      createEntityLink,
      isBoostCanceled,
      isDraft,
      isFromMyOffers,
      isInbox,
      isOwnJob,
      navigate,
      sendAndUpdateJobPost,
      updateJobPostAsOtherUser,
    ],
  );

  useEffect(() => {
    if (+id) {
      getJobPost(+id);
    } else {
      setJobPost(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEntityUpdateListener('job-post-updated');
  useEntityUpdateListener('job-post-suspended');

  useEffect(() => {
    if (!currentUser?.id || !+id) return;

    // socket will connect in CurrentUser context, so we wait a little
    setTimeout(() => {
      if (eventSocketService.socket)
        eventSocketService.sendEvent('subscribe-to-job-post');
    }, 1000);

    return () => {
      if (eventSocketService.socket)
        eventSocketService.sendEvent('unsubscribe-from-job-post');
    };
  }, [currentUser?.id, id]);

  const context: JobPostContext = {
    isSavingJobPost,
    jobPost,
    fetchingJobPost,
    isOwnJob,
    createUpdateJobPost,
    updateJobPostAsOtherUser,
    sendAndUpdateJobPost,
    setJobPost,
    sendJobPost,
    onSubmitForm,
  };

  return (
    <UserScheduleProvider>
      {isInbox ? (
        <Outlet context={context} />
      ) : (
        <>
          <NavBar className={navBarClasses} />
          <MainTemplate
            className={classes}
            asideLeft={asideLeft}
            asideRight={asideRight}
          >
            <Outlet context={context} />
          </MainTemplate>
        </>
      )}
    </UserScheduleProvider>
  );
};

export default JobPostComponent;

export const useJobPost = () => useOutletContext<JobPostContext>();
