import { EnhancedRouteProps } from 'router/routes/Enhanced/Enhanced.route';
import EditProfilePage from './pages/EditProfile';
import ProfilePreview from './pages/ProfilePreview';
import Offers from './pages/Offers';

export default [
  {
    path: 'edit',
    element: EditProfilePage,
    authorized: true,
  },
  {
    path: 'offers/:id',
    element: Offers,
  },
  {
    path: ':id',
    element: ProfilePreview,
  },
  {
    path: '',
    element: ProfilePreview,
    authorized: true,
  },
] as Array<EnhancedRouteProps>;
