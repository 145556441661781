import React, { useId } from 'react';

type IconProps = React.SVGProps<SVGSVGElement> & { showGradient?: boolean };

const InputSearchIcon = ({ showGradient, ...props }: IconProps) => {
  const gradientId = useId();
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.833344 8.74998C0.833344 4.37774 4.37777 0.833313 8.75001 0.833313C13.1222 0.833313 16.6667 4.37774 16.6667 8.74998C16.6667 10.6359 16.0072 12.3679 14.9063 13.7277L17.9672 16.7887C18.2927 17.1141 18.2927 17.6418 17.9672 17.9672C17.6418 18.2926 17.1141 18.2926 16.7887 17.9672L13.7278 14.9062C12.3679 16.0072 10.636 16.6666 8.75001 16.6666C4.37777 16.6666 0.833344 13.1222 0.833344 8.74998ZM8.75001 2.49998C5.29825 2.49998 2.50001 5.29822 2.50001 8.74998C2.50001 12.2017 5.29825 15 8.75001 15C12.2018 15 15 12.2017 15 8.74998C15 5.29822 12.2018 2.49998 8.75001 2.49998Z"
        fill={showGradient ? `url(#${gradientId})` : 'currentColor'}
      />
      <defs>
        <linearGradient
          id={gradientId}
          x1="0.833344"
          y1="9.52229"
          x2="18.2113"
          y2="9.52229"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF7BAC" />
          <stop offset="1" stopColor="#D142F4" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default InputSearchIcon;
