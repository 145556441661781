import React, { useId } from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

export default (props: IconProps) => {
  const gradientId = useId();

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10ZM9 14.5C9 13.9477 9.44771 13.5 10 13.5H10.0094C10.5617 13.5 11.0094 13.9477 11.0094 14.5C11.0094 15.0523 10.5617 15.5 10.0094 15.5H10C9.44771 15.5 9 15.0523 9 14.5ZM9.06653 6.73023C9.41265 6.52681 9.8196 6.45246 10.2153 6.52033C10.611 6.5882 10.9699 6.79392 11.2284 7.10106C11.487 7.40819 11.6285 7.79692 11.6279 8.19839L11.6279 8.19988C11.6279 8.56901 11.3378 8.9831 10.7232 9.39283C10.4463 9.57741 10.1623 9.72011 9.94362 9.81732C9.83569 9.86529 9.74714 9.90064 9.68791 9.9232C9.65838 9.93445 9.63638 9.94243 9.6232 9.94712L9.61034 9.95164C9.08724 10.1268 8.8047 10.6926 8.9792 11.2161C9.15385 11.7401 9.72017 12.0232 10.2441 11.8486L10.2454 11.8481L10.2468 11.8476L10.2506 11.8464L10.261 11.8428L10.2932 11.8315C10.3196 11.8222 10.3556 11.8091 10.3999 11.7922C10.4883 11.7585 10.6107 11.7095 10.7559 11.6449C11.0434 11.5171 11.4345 11.3224 11.8326 11.0569C12.5678 10.5668 13.6274 9.63115 13.6279 8.2008C13.6291 7.32721 13.3211 6.48138 12.7585 5.81305C12.1958 5.14458 11.4146 4.69684 10.5534 4.54911C9.69219 4.40139 8.80649 4.56323 8.05316 5.00597C7.29984 5.4487 6.72751 6.14376 6.43755 6.96804C6.25428 7.48903 6.52806 8.05994 7.04904 8.24321C7.57003 8.42649 8.14095 8.15271 8.32422 7.63172C8.45745 7.253 8.72041 6.93365 9.06653 6.73023Z"
        fill={`url(#${gradientId})`}
      />
      <defs>
        <linearGradient
          id={gradientId}
          x1="-3.32756e-08"
          y1="20"
          x2="20.3488"
          y2="19.6386"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A761F5" />
          <stop offset="1" stopColor="#50A8FA" />
        </linearGradient>
      </defs>
    </svg>
  );
};
