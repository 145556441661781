import { useTranslation } from 'react-i18next';

export default function useTranslations() {
  const { t } = useTranslation();

  return {
    name: t('SearchPage.name'),
    userId: t('SearchPage.userId'),
    skill: t('Filters.skills', { count: 1 }),
    addSkill: t('SearchPage.addSkill'),
    searchPlaceholder: t('SearchPage.searchUserPlaceholder'),
  };
}
