import AnyServiceLogoIcon from 'icons/AnyServiceLogo.icon';
import React, { useCallback, useContext, useMemo } from 'react';
import classNames from 'classnames';
import useCallbackRef from 'hooks/useCallbackRef';
import { ModalRef } from 'components/Modal';
import CreateAdOfferModal from 'components/CreateAdOfferModal';
import NavigationLink from '../NavigationLink';
import PlusCircleIcon from 'icons/PlusCircleStrokeGradient.icon';
import ProvideIcon from 'icons/Provide.icon';
import NeedIcon from 'icons/Need.icon';
import SearchIcon from 'icons/Search.icon';
import UserIcon from 'icons/User.icon';
import { useTranslation } from 'react-i18next';
import { NavItem } from 'models/Navigation';
import MenuDropdown from './MenuDropdown';
import { Link, useNavigate } from 'react-router-dom';

import './NavBar.styles.scss';
import './NavBar.responsive.styles.scss';
import CurrentUserContext from '../../providers/CurrentUser/CurrentUser.context';

type NavBarProps = {
  className?: string;
};

const NavBar: React.FC<NavBarProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();
  const { currentUser } = useContext(CurrentUserContext);
  const navigate = useNavigate();

  const [createAdOfferModal, createAdOfferModalRef] =
    useCallbackRef<ModalRef>(null);

  const messages = useMemo(
    () => ({
      provide: t('General.provide'),
      need: t('General.need'),
      add: t('General.add'),
      search: t('General.search'),
      profile: t('General.profile'),
    }),
    [t],
  );

  const navItems: NavItem[] = useMemo(
    () => [
      //TODO: add routes when pages are done
      { to: '/provide', icon: ProvideIcon, label: messages.provide },
      { to: '/need', icon: NeedIcon, label: messages.need },
      {
        to: '/job-post',
        icon: PlusCircleIcon,
        label: messages.add,
        onClick: (e: any) => {
          e.preventDefault();
          if (currentUser?.type === 'client') {
            navigate('/job-post/create/need');
            return;
          }
          createAdOfferModal.open();
        },
        className: 'anys-header__link--add-offer',
        end: true,
      },
      { to: '/search', icon: SearchIcon, label: messages.search },
      { to: '/profile', icon: UserIcon, label: messages.profile, end: true },
    ],
    [
      createAdOfferModal,
      currentUser?.type,
      messages.add,
      messages.need,
      messages.profile,
      messages.provide,
      messages.search,
      navigate,
    ],
  );

  const closeCreateAddOffer = useCallback(() => {
    createAdOfferModal.close();
  }, [createAdOfferModal]);

  return (
    <header className={classNames('anys-header', className)}>
      <Link to="/" className="anys-header__logo">
        <AnyServiceLogoIcon />
      </Link>

      <nav>
        <ul>
          {navItems.map(
            ({ to, icon: Icon, label, className, ...restOfItem }) => {
              const linkClasses = classNames('anys-header__link', className);

              return (
                <li key={to}>
                  <NavigationLink
                    to={to}
                    className={linkClasses}
                    {...restOfItem}
                  >
                    {({ isActive }) => (
                      <>
                        <span className="anys-header__link__icon-wrapper">
                          <Icon showGradient={isActive} />
                        </span>
                        <span className="anys-header__link__text">{label}</span>
                      </>
                    )}
                  </NavigationLink>
                </li>
              );
            },
          )}
        </ul>
      </nav>
      <div className="anys-header__actions">
        <MenuDropdown />
      </div>
      <CreateAdOfferModal
        createAdOfferModalRef={createAdOfferModalRef}
        onClose={closeCreateAddOffer}
      />
    </header>
  );
};

export default NavBar;
