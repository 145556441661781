import React from 'react';
import { t } from 'i18next';
import { useProfile } from '../../../Profile/Profile.page';
import ExperiencePreview from './components/ExperiencePreview';
import ProfileCardModal from '../ProfileCardModal';

const Experiences = () => {
  const {
    profileUser: { experiences },
  } = useProfile();

  return experiences?.length ? (
    <ProfileCardModal
      cardTitle={t('Profile.experience')}
      modalName="experiences-modal"
      modalTitle="Experience"
      itemList={experiences.map((experience, index) => (
        <ExperiencePreview key={index} experience={experience} />
      ))}
    />
  ) : null;
};

export default Experiences;
