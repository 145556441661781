import React, { useCallback, useContext, useState } from 'react';
import classNames from 'classnames';
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
} from 'react-router-dom';
import {
  ConfirmSocialSignupReqBody,
  EmailToVerify,
  ForgotPasswordReqBody,
  LoginReqBody,
  ResetPasswordFormValues,
  SignupReqBody,
  VerifyEmailReqBody,
} from 'models/Auth';
import api from 'api';
import { AxiosError } from 'axios';
import storageService, { STORAGE_KEYS } from 'services/storageService';
import credentialsService from 'services/credentialsService';
import { useTranslation } from 'react-i18next';
import showToast from 'modules/showToast';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';

import './Auth.styles.scss';
import './Auth.styles.responsive.scss';

const storeEmailToVerify = (email: string) => {
  const emailToStore: EmailToVerify = {
    email,
    storedAt: new Date().toString(),
  };

  storageService.setItem(STORAGE_KEYS.EMAIL_TO_VERIFY, emailToStore, true);
};

type AuthProps = {
  className?: string;
};

export type OutletContext = {
  login: (values: LoginReqBody) => Promise<void>;
  logout: () => void;
  signup: (values: SignupReqBody) => Promise<void>;
  verifyEmail: (values: VerifyEmailReqBody) => Promise<void>;
  resendVerifyCode: () => Promise<void>;
  forgotPassword: (values: ForgotPasswordReqBody) => Promise<void>;
  resetPassword: (values: ResetPasswordFormValues) => Promise<void>;
  checkIfEmailExists: (email: string) => Promise<boolean>;
  completeSignupWithSocial: (
    values: ConfirmSocialSignupReqBody,
  ) => Promise<void>;

  isAuthInProgress: boolean;
  isVerifyingEmail: boolean;
  isSendingVerificationCode: boolean;
  isForgotPasswordInProgress: boolean;
  isResettingPassword: boolean;
  isCheckingForExistingEmail: boolean;
};

const Auth: React.FC<AuthProps> = (props) => {
  const { className } = props;

  const { currentUser, setCurrentUser } = useContext(CurrentUserContext);

  const [isAuthInProgress, setIsAuthInProgress] = useState(false);
  const [isVerifyingEmail, setIsVerifyingEmail] = useState(false);
  const [isSendingVerificationCode, setIsSendingVerificationCode] =
    useState(false);
  const [isForgotPasswordInProgress, setIsForgotPasswordInProgress] =
    useState(false);
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [isCheckingForExistingEmail, setIsCheckingForExistingEmail] =
    useState(false);

  const navigate = useNavigate();
  const { state: locationState } = useLocation();

  const { t } = useTranslation();

  const classes = classNames('anys-auth', className);

  const handleLoginError = useCallback(
    (error: AxiosError) => {
      const { status } = error?.response || {};

      let errorMessage;

      switch (status) {
        case 404:
          errorMessage = t('Login.userNotFoundError');
          break;

        case 400:
          errorMessage = t('Login.wrongPasswordError');

          break;

        default:
          errorMessage = t('General.error');

          break;
      }

      showToast('error', errorMessage);
    },
    [t],
  );

  const applyReferralCodeMaybe = useCallback(async () => {
    const referralCode = storageService.getItem<string>('referralCode', true);

    if (!referralCode) return;

    try {
      await api.referral.recordReferral(referralCode);

      storageService.removeItem('referralCode', true);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const login = useCallback(
    async (values: LoginReqBody) => {
      setIsAuthInProgress(true);

      try {
        const {
          data: { token, refreshToken, user },
        } = await api.auth.login(values);

        setCurrentUser(user);
        credentialsService.saveAuthBody({ token, refreshToken });

        if (locationState && 'from' in (locationState as object)) {
          const { from } = locationState as { from: string };
          navigate(from);
        } else {
          navigate('/');
        }
      } catch (error) {
        const e = error as AxiosError;

        handleLoginError(e);
      } finally {
        setIsAuthInProgress(false);
      }
    },
    [handleLoginError, locationState, navigate, setCurrentUser],
  );

  const logout = useCallback(() => {
    // Remove auth data that we store in storage
    storageService.removeItem(STORAGE_KEYS.EMAIL_TO_VERIFY, true);
    credentialsService.removeAuthBody();

    // Do a hard reload
    window.location.href = '/';
  }, []);

  const checkIfEmailExists = useCallback(
    async (email: string) => {
      setIsCheckingForExistingEmail(true);

      try {
        const res = await api.auth.checkIfEmailExists(email);

        return res.data.existEmail;
      } catch (error) {
        showToast('error', t('General.error'));
      } finally {
        setIsCheckingForExistingEmail(false);
      }
    },
    [t],
  );

  const signup = useCallback(
    async (values: SignupReqBody) => {
      setIsAuthInProgress(true);

      const { termsAndConditions, ...rest } = values;

      try {
        await api.auth.signup(rest);

        storeEmailToVerify(values.email);

        navigate('verify-email');
      } catch (error) {
        const err = error as AxiosError;

        const msg =
          err.response.status === 409
            ? t('Signup.emailExistsError')
            : t('General.error');

        showToast('error', msg);
      } finally {
        setIsAuthInProgress(false);
      }
    },
    [navigate, t],
  );

  const verifyEmail = useCallback(
    async (values: VerifyEmailReqBody) => {
      setIsVerifyingEmail(true);

      try {
        const {
          data: { token, refreshToken, account },
        } = await api.auth.verifyEmail(values);

        setCurrentUser(account?.user);
        credentialsService.saveAuthBody({ token, refreshToken });
        storageService.removeItem(STORAGE_KEYS.EMAIL_TO_VERIFY, true);

        applyReferralCodeMaybe();

        navigate('/profile/edit');
      } catch (error) {
        showToast('error', t('General.error'));
      } finally {
        setIsVerifyingEmail(false);
      }
    },
    [applyReferralCodeMaybe, navigate, setCurrentUser, t],
  );

  const resendVerifyCode = useCallback(async () => {
    setIsSendingVerificationCode(true);

    try {
      const storedEmail = storageService.getItem<EmailToVerify>(
        STORAGE_KEYS.EMAIL_TO_VERIFY,
        true,
      );

      if (!storedEmail?.email) return;

      await api.auth.resendVerifyCode(storedEmail.email);

      storeEmailToVerify(storedEmail.email);
    } catch (error) {
      showToast('error', t('General.error'));
    } finally {
      setIsSendingVerificationCode(false);
    }
  }, [t]);

  const forgotPassword = useCallback(
    async (values: ForgotPasswordReqBody) => {
      setIsForgotPasswordInProgress(true);

      try {
        await api.auth.forgotPassword(values.email);
      } catch (error) {
        showToast('error', t('General.error'));

        throw error;
      } finally {
        setIsForgotPasswordInProgress(false);
      }
    },
    [t],
  );

  const resetPassword = useCallback(
    async (values: ResetPasswordFormValues) => {
      setIsResettingPassword(true);

      try {
        await api.auth.resetPassword(values.password, values.token);

        navigate('login');
      } catch (error) {
        showToast('error', t('General.error'));
      } finally {
        setIsResettingPassword(false);
      }
    },
    [navigate, t],
  );

  const completeSignupWithSocial = useCallback(
    async (values: ConfirmSocialSignupReqBody) => {
      setIsAuthInProgress(true);

      try {
        await api.auth.completeSignupWithSocial({ role: values.role });

        applyReferralCodeMaybe();

        navigate('/profile/edit');
      } catch (error) {
        const err = error as AxiosError;

        showToast(
          'error',
          t('General.error'),
          err?.response?.data?.error?.message,
        );
      } finally {
        setIsAuthInProgress(false);
      }
    },
    [applyReferralCodeMaybe, navigate, t],
  );

  if (currentUser?.id && currentUser?.role) return <Navigate to="/" replace />;

  return (
    <main className={classes}>
      <Outlet
        context={
          {
            login,
            logout,
            signup,
            verifyEmail,
            resendVerifyCode,
            forgotPassword,
            resetPassword,
            checkIfEmailExists,
            completeSignupWithSocial,

            isAuthInProgress,
            isVerifyingEmail,
            isSendingVerificationCode,
            isForgotPasswordInProgress,
            isResettingPassword,
            isCheckingForExistingEmail,
          } as OutletContext
        }
      />
    </main>
  );
};

export default Auth;

export const useAuth = () => useOutletContext<OutletContext>();
