import React from 'react';
import { LanguageType } from 'models/User';

type LanguagesPreviewProps = {
  language: LanguageType;
};

const LanguagesPreview: React.FC<LanguagesPreviewProps> = (props) => {
  const {
    language: { language, proficiency },
  } = props;

  return (
    <div className="anys-profile-preview__body__preview">
      <span className="anys-profile-preview__body__preview__name">
        {language}
      </span>
      <span className="anys-profile-preview__body__preview__value">
        {proficiency}
      </span>
    </div>
  );
};

export default LanguagesPreview;
