const CONTRACT_STATE = {
  ACTIVE: 'Active',
  DONE: 'Done',
} as const;

export type ContractState =
  (typeof CONTRACT_STATE)[keyof typeof CONTRACT_STATE];

export const PAYMENT_TYPE = {
  CONTRACT: 'contract',
  ARBITRATION: 'arbitration',
} as const;

export type PaymentType = (typeof PAYMENT_TYPE)[keyof typeof PAYMENT_TYPE];

export const PAYMENT_STATE = {
  CAPTURED: 'captured',
  RELEASED: 'released',
  PAID: 'paid',
  REFUNDED: 'refunded',
} as const;

export type PaymentState = (typeof PAYMENT_STATE)[keyof typeof PAYMENT_STATE];

export { CONTRACT_STATE };
