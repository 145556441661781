import React, { useMemo } from 'react';
import classNames from 'classnames';
import TimelineDot from '../TimelineDot';

import './TimelineLinePart.styles.scss';

type TimelineLinePartProps = {
  className?: string;
  dotClassName?: string;
  lineClassName?: string;
  hasFirstDot?: boolean;
  hasLastDot?: boolean;
  isFirstDotLarge?: boolean;
  isLastDotLarge?: boolean;
  firstDotContent?: React.ReactNode;
  lastDotContent?: React.ReactNode;
  isVertical?: boolean;
};

const TimelineLinePart: React.FC<TimelineLinePartProps> = (props) => {
  const {
    className,
    dotClassName,
    lineClassName,
    hasFirstDot = true,
    hasLastDot = true,
    isFirstDotLarge,
    isLastDotLarge,
    firstDotContent,
    lastDotContent,
    isVertical,
  } = props;

  const classes = classNames(
    'anys-timeline-line-part',
    { 'anys-timeline-line-part--vertical': isVertical },
    className,
  );
  const lineClasses = classNames(
    'anys-timeline-line-part__line',
    { 'anys-timeline-line-part__line--vertical': isVertical },

    lineClassName,
  );

  const line = useMemo(() => <div className={lineClasses} />, [lineClasses]);

  return (
    <div className={classes}>
      {hasFirstDot && (
        <div className="anys-timeline-line-part__wrapper">
          <TimelineDot isLarge={isFirstDotLarge} className={dotClassName} />
          {firstDotContent && (
            <span className="anys-timeline-line-part__wrapper__content">
              {firstDotContent}
            </span>
          )}
        </div>
      )}

      {line}

      {hasLastDot && (
        <div className="anys-timeline-line-part__wrapper">
          <TimelineDot isLarge={isLastDotLarge} className={dotClassName} />
          {lastDotContent && (
            <span className="anys-timeline-line-part__wrapper__content">
              {lastDotContent}
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default TimelineLinePart;
