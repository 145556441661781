import React from 'react';

type ExternalLinkProps = React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>;

const ExternalLink: React.FC<ExternalLinkProps> = (props) => {
  const { children, ...restOfProps } = props;

  return (
    <a {...restOfProps} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};

export default ExternalLink;
