import React from 'react';

type ItalicProps = React.SVGProps<SVGSVGElement>;

const Italic: React.FC<ItalicProps> = (props) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.81998 6.75H10.32L8.66998 14.25H7.16998L8.81998 6.75ZM10.08 3.75C9.93165 3.75 9.78664 3.79399 9.66331 3.8764C9.53997 3.95881 9.44384 4.07594 9.38707 4.21299C9.33031 4.35003 9.31546 4.50083 9.34439 4.64632C9.37333 4.7918 9.44476 4.92544 9.54965 5.03033C9.65454 5.13522 9.78818 5.20665 9.93367 5.23559C10.0792 5.26453 10.23 5.24968 10.367 5.19291C10.504 5.13614 10.6212 5.04001 10.7036 4.91668C10.786 4.79334 10.83 4.64834 10.83 4.5C10.83 4.30109 10.751 4.11032 10.6103 3.96967C10.4697 3.82902 10.2789 3.75 10.08 3.75Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Italic;
