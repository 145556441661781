import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import api from 'api';
import { Job } from 'models/Job';
import { useTranslation } from 'react-i18next';
import useInboxLink from 'router/subrouters/Inbox/hooks/useInboxLink';
import showToast from 'modules/showToast';
import { AxiosError } from 'axios';

export type JobVersionProps = {
  updateJob?: (job: Job) => void;
};

const useJobVersion = (props?: JobVersionProps) => {
  const { updateJob } = props || {};

  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const { createEntityLink } = useInboxLink();

  const [acceptingOrRejecting, setAcceptingOrRejecting] = useState(false);

  const isInbox = location.pathname?.startsWith('/inbox');

  const acceptVersion = useCallback(
    async (commonId: number, version: number) => {
      setAcceptingOrRejecting(true);

      try {
        const {
          data: { job },
        } = await api.job.acceptVersion(commonId, version);

        navigate(
          isInbox
            ? createEntityLink('view', 'job', job.commonId)
            : `/job/${job.commonId}`,
        );

        updateJob?.(job);
      } catch (error) {
        const err = error as AxiosError;

        showToast(
          'error',
          t('General.error'),
          err?.response?.data?.error?.message,
        );

        console.error(error);
      } finally {
        setAcceptingOrRejecting(false);
      }
    },
    [createEntityLink, isInbox, navigate, t, updateJob],
  );

  const rejectVersion = useCallback(
    async (commonId: number, version: number) => {
      setAcceptingOrRejecting(true);

      try {
        const {
          data: { job },
        } = await api.job.rejectVersion(commonId, version);

        navigate(
          isInbox
            ? createEntityLink('view', 'job', job.commonId)
            : `/job/${job.commonId}`,
        );

        updateJob?.(job);
      } catch (error) {
        const err = error as AxiosError;

        showToast(
          'error',
          t('General.error'),
          err?.response?.data?.error?.message,
        );
        console.error(error);
      } finally {
        setAcceptingOrRejecting(false);
      }
    },
    [createEntityLink, isInbox, navigate, t, updateJob],
  );

  return {
    acceptingOrRejecting,
    acceptVersion,
    rejectVersion,
  };
};

export default useJobVersion;
