import React, { useCallback, useContext, useMemo, useState } from 'react';
import classNames from 'classnames';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import Tabs from 'router/subrouters/Profile/pages/ProfilePreview/components/AvailabilityModal/components/Tabs';
import { Form } from 'react-final-form';
import InputField from 'components/InputField';
import arrayMutators from 'final-form-arrays';
import { Button } from 'ncoded-component-library';
import CountdownTimer from 'components/CountdownTimer';
import dayjs from 'dayjs';
import { AnyObject } from 'final-form';
import api from 'api';
import FilesPreview from 'components/FilesPreview';
import formValidators from 'utils/formValidators';
import { useArbitration } from 'providers/Arbitration/Arbitration.provider';
import Notice from 'components/Notice';
import { Trans, useTranslation } from 'react-i18next';

import './DecisioningAndQuestions.styles.scss';
import './DecisioningAndQuestions.styles.responsive.scss';

type DecisioningAndQuestionsProps = {
  className?: string;
  decisionMade?: boolean;
};

const DecisioningAndQuestions = (props: DecisioningAndQuestionsProps) => {
  const { className, decisionMade } = props;
  const classes = classNames('anys-decisioning-and-questions', className);

  const { selectedArbitration, setSelectedArbitration } = useArbitration();
  const { contract, proofs, actionTimers, id, votes } = selectedArbitration;
  const { currentUser } = useContext(CurrentUserContext);

  const [active, setActive] = useState(1);

  const { t } = useTranslation();

  const isCurrentUserClient = useMemo(
    () => currentUser?.id === contract?.client?.id,
    [contract?.client?.id, currentUser?.id],
  );

  const timeForAnswerQuestions = useMemo(() => {
    const date1 = dayjs(new Date());
    const date2 = dayjs(
      actionTimers.find(
        (actionTimer) => actionTimer.type === 'pending_proofinquiry_answer',
      )?.endsAt,
    );

    return date2.diff(date1, 'milliseconds');
  }, [actionTimers]);

  const handleSubmitAnswers = useCallback(
    async (data: any) => {
      const inquiries = Object.keys(data).map((key) => ({
        id: +key.split('_')[1],
        answer: data[`${key}`],
      }));

      try {
        const { data } = await api.arbitration.answerQuestions(id, inquiries);

        // const submitted = active === 1 ? 'provider' : 'client';

        if (data) {
          setSelectedArbitration((old) => ({
            ...old,
            proofs: old.proofs.map((proof) => {
              // if (proof.submittedBy === submitted) {
              return {
                ...proof,
                inquiries: proof.inquiries.map((inquirie) => ({
                  ...inquirie,
                  answer:
                    //TODO: check this 'ne radi' was written here
                    data.find((answer: any) => answer.id === inquirie.id)
                      .answer || '',
                })),
              };
              // } else return proof;
            }),
            actionTimers: [],
          }));
        }
      } catch (e) {
        console.error(e);
      }
    },
    [id, setSelectedArbitration],
  );

  const tabs = useMemo(() => {
    return [
      {
        name: t('Arbitration.myProof'),
        id: 1,
        handle: isCurrentUserClient ? 'client' : 'provider',
      },
      {
        name: isCurrentUserClient
          ? t('Arbitration.providerProof')
          : t('Arbitration.clientProof'),
        id: 2,
        disable: !decisionMade,
        handle: isCurrentUserClient ? 'provider' : 'client',
      },
    ];
  }, [t, isCurrentUserClient, decisionMade]);

  const selectedProofs = useMemo(() => {
    return decisionMade
      ? proofs.find((proof) => proof.submittedBy === tabs?.[active - 1]?.handle)
      : proofs[0];
  }, [active, decisionMade, proofs, tabs]);

  const arbitrationWinner = useMemo(() => {
    let votesForProvider = 0;
    let votesForClient = 0;

    votes?.forEach((vote) => {
      if (vote.votedFor === 'client') {
        votesForClient++;
      } else {
        votesForProvider++;
      }
    });

    return votesForProvider > votesForClient ? 'provider' : 'client';
  }, [votes]);

  //because form submit button is outside of form
  let submitAnswers: (
    event?: Partial<
      Pick<
        React.SyntheticEvent<Element, Event>,
        'preventDefault' | 'stopPropagation'
      >
    >,
  ) => Promise<AnyObject>;

  return (
    <section className={classes}>
      <h1>{t('Arbitration.arbitrationForContractId', { id: contract.id })}</h1>
      <Tabs
        labels={tabs}
        active={active}
        setActive={setActive}
        className="anys-add-proofs__tabs"
      />
      {selectedProofs?.text ? (
        <>
          <h2>{t('Arbitration.myProof')}</h2>
          <p>{selectedProofs?.text}</p>
        </>
      ) : (
        <p>{t('Arbitration.emptyProofs')}</p>
      )}
      <FilesPreview
        files={selectedProofs?.files as any}
        className="anys-provider-client-proofs__files"
      />
      {/* {selectedProofs?.files?.length > 0 && (
        <>
          <ul className="anys-add-proofs__files">
            {selectedProofs.files?.map((el: any, i: number) => (
              <li key={el.name + i} className="anys-add-proofs__file">
                <span className="anys-add-proofs__file__name">{el.name}</span>
                <div className="anys-add-proofs__file__size">
                  <span>{`${(+el.size / 1024 / 1024).toFixed(1)} MB`}</span>
                </div>
              </li>
            ))}
          </ul>
        </>
      )} */}
      {selectedProofs?.inquiries?.length !== 0 && (
        <>
          <Form
            onSubmit={handleSubmitAnswers}
            // initialValues={{
            //   questions: selectedProofs?.inquiries?.map(
            //     (inquirie) => inquirie.answer,
            //   ),
            // }}
            mutators={{
              ...arrayMutators,
            }}
            render={({
              handleSubmit,
              form: {
                mutators: { push, pop },
              },
            }) => {
              submitAnswers = handleSubmit;
              return (
                <form onSubmit={handleSubmit}>
                  <h2>{t('Arbitration.questions')}</h2>
                  {selectedProofs?.inquiries?.map((inquirie, index) => (
                    <section key={inquirie.id}>
                      <h3>{t('Arbitration.questionId', { id: index + 1 })}</h3>
                      <p className="anys-decisioning-and-questions__question-txt">
                        {inquirie.question}
                      </p>

                      {actionTimers.length !== 0 ? (
                        <InputField
                          key={index}
                          name={`answer_${inquirie.id}`}
                          label={t('Arbitration.answernId', { id: index + 1 })}
                          placeholder={t('Arbitration.enterYourAnswer')}
                          validate={formValidators.required('Required')}
                        />
                      ) : (
                        <>
                          <h3>
                            {t('Arbitration.answernId', { id: index + 1 })}
                          </h3>
                          <p className="anys-decisioning-and-questions__question-txt">
                            {inquirie.answer}
                          </p>
                        </>
                      )}
                    </section>
                  ))}
                </form>
              );
            }}
          />

          {actionTimers.length !== 0 && (
            <section className="anys-decisioning-and-questions__actions">
              <section>
                <Button type="submit" onClick={(e) => submitAnswers(e)}>
                  {t('Arbitration.submitAnswers')}
                </Button>
              </section>

              <CountdownTimer
                startTimeInMS={timeForAnswerQuestions}
                className="anys-arbitration-decisioning__timmer"
                iconGradient
              />
            </section>
          )}
        </>
      )}
      {decisionMade && (
        <Notice type="warn">
          <span>
            <Trans
              i18nKey="Arbitration.arbitrationFinishWithWinner"
              components={{
                b: <b />,
              }}
              values={{ arbitrationWinner: arbitrationWinner }}
            />
          </span>
        </Notice>
      )}
    </section>
  );
};

export default DecisioningAndQuestions;
