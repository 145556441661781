import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement> & { showGradient?: boolean };

export default (props: IconProps) => {
  const { showGradient = true, ...restOfProps } = props;

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restOfProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8333 0.833333C10.8333 0.373096 10.4602 0 10 0C9.53976 0 9.16667 0.373096 9.16667 0.833333V1.66667H8.33333C5.80201 1.66667 3.75 3.71868 3.75 6.25C3.75 8.78132 5.80201 10.8333 8.33333 10.8333H9.16667V16.6667H5.41671C4.95648 16.6667 4.58333 17.0398 4.58333 17.5C4.58333 17.9602 4.95662 18.3333 5.41686 18.3333H9.16667V19.1667C9.16667 19.6269 9.53976 20 10 20C10.4602 20 10.8333 19.6269 10.8333 19.1667V18.3333H11.6667C14.198 18.3333 16.25 16.2813 16.25 13.75C16.25 11.2187 14.198 9.16667 11.6667 9.16667H10.8333V3.33333H14.5833C15.0435 3.33333 15.4167 2.96024 15.4167 2.5C15.4167 2.03976 15.0434 1.66667 14.5831 1.66667H10.8333V0.833333ZM9.16667 9.16667H8.33333C6.72249 9.16667 5.41667 7.86085 5.41667 6.25C5.41667 4.63915 6.72249 3.33333 8.33333 3.33333H9.16667V9.16667ZM10.8333 10.8333V16.6667H11.6667C13.2775 16.6667 14.5833 15.3608 14.5833 13.75C14.5833 12.1392 13.2775 10.8333 11.6667 10.8333H10.8333Z"
        fill={showGradient ? 'url(#dollar-gradient)' : 'currentColor'}
      />
      {showGradient && (
        <defs>
          <linearGradient
            id="dollar-gradient"
            x1="3.75"
            y1="10"
            x2="16.25"
            y2="10"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FF7BAC" />
            <stop offset="1" stopColor="#D142F4" />
          </linearGradient>
        </defs>
      )}
    </svg>
  );
};
