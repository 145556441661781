import { PlaceFormat } from 'models/User';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { getCurrentLocationAsInputOption } from 'utils/location';
import useCurrentLocation from './useCurrentLocation';
import { useTranslation } from 'react-i18next';
import googleMaps from 'utils/googleMaps';
import { DropdownOption } from 'components/DropdownOptions/DropdownOptions.component';
import utils from 'utils';
import GradientText from 'components/GradientText';
import debounce from 'lodash/debounce';
import { OptionValue } from 'ncoded-component-library/build/components/molecules/Select/Select.component';
import showToast from 'modules/showToast';
import CurrentLocationIcon from 'icons/CurrentLocation.icon';

export const CURRENT_LOCATION_ID = 'current-location';

// When displaying data from the Google Places,
// attribution is required with the results.
// https://developers.google.com/places/web-service/policies#powered
const GoogleAttribution = () => {
  return <div className="google-attribution" />;
};

const formatPlace = (value: PlaceFormat) => {
  if (value?.fullAddress) return value.fullAddress;

  return value?.city && value?.country
    ? `${value.street ? `${value.street}, ` : ''}${value.city}, ${
        value.country
      }`
    : '';
};

type Prediction = {
  id: string;
  name: string;
  icon?: React.ReactNode;
  onClick?: (predictionId: string) => void;
};

type LocationAutocomplete = {
  value?: PlaceFormat & { placeId?: string };
  useProfileLocation?: boolean;
  queryRemovesValue?: boolean;
  locationPromptInitially?: boolean;
  withBounds?: boolean;
  withFullAddress?: boolean;
  rawPlaceRef?: React.MutableRefObject<google.maps.places.PlaceResult>;
  onPlaceSelect: (value: PlaceFormat, placeId: string) => void;
};

const useLocationAutocomplete = (props: LocationAutocomplete) => {
  const {
    value,
    useProfileLocation,
    queryRemovesValue = true,
    locationPromptInitially,
    withBounds,
    withFullAddress,
    rawPlaceRef,
    onPlaceSelect,
  } = props;

  const { t } = useTranslation();

  const [predictions, setPredictions] = useState<Prediction[]>([]);
  const [hasPredictionError, setHasPredictionError] = useState(false);
  const [query, setQuery] = useState<string>(formatPlace(value));
  const [isInputFocused, setIsInputFocused] = useState(false);

  const [resetCurrentLocation, setResetCurrentLocation] = useState(
    !locationPromptInitially,
  );

  const sessionToken = useRef<google.maps.places.AutocompleteSessionToken>();

  const { currentUser } = useContext(CurrentUserContext);

  const valueLatLng =
    value?.lat && value?.lng ? `${value.lat},${value.lng}` : null;

  const onCurrentLocationSuccess = useCallback(
    (place: PlaceFormat) => {
      if (value && !resetCurrentLocation) return;

      onPlaceSelect?.(
        getCurrentLocationAsInputOption(place, currentUser?.location),
        CURRENT_LOCATION_ID,
      );
    },
    [currentUser?.location, onPlaceSelect, resetCurrentLocation, value],
  );

  const { getCurrentLocation } = useCurrentLocation({
    callInitially: locationPromptInitially,
    onFinished: onCurrentLocationSuccess,
  });

  const getSessionToken = useCallback(() => {
    sessionToken.current =
      sessionToken.current ||
      new window.google.maps.places.AutocompleteSessionToken();

    return sessionToken.current;
  }, []);

  const getPlacePredictions = useMemo(
    () =>
      debounce(async (search: string) => {
        setHasPredictionError(false);

        if (!search) {
          setPredictions([]);

          return;
        }

        try {
          const { predictions } = await googleMaps.getPlacePredictions(
            search,
            getSessionToken(),
          );

          setPredictions(
            predictions.map(({ place_id, description }) => ({
              id: place_id,
              name: description,
            })),
          );
        } catch (error) {
          console.error(error);
          setHasPredictionError(true);
        }
      }, 500),
    [getSessionToken],
  );

  const getPlaceDetails = useCallback(
    async (placeId: string, predictionPlaceQuery: string) => {
      let details: PlaceFormat;

      if (placeId === CURRENT_LOCATION_ID) {
        setResetCurrentLocation(true);

        try {
          const currentLoc = (await getCurrentLocation(true)) as PlaceFormat;

          details = getCurrentLocationAsInputOption(
            currentLoc,
            currentUser?.location,
          );
        } catch (error) {
          showToast('error', t('General.locationNotAllowed'));
        }
      } else {
        try {
          details = await googleMaps.getPlaceDetails(
            { placeId, placeDesc: predictionPlaceQuery },
            getSessionToken(),
            { withFullAddress, rawPlaceRef },
          );

          sessionToken.current = null;
        } catch (error) {
          console.error(error);
        }
      }

      if (!withBounds && details) {
        delete details.bounds;
      }

      onPlaceSelect?.(details, placeId);
      setQuery(formatPlace(details));
      setResetCurrentLocation(false);

      return details;
    },
    [
      currentUser,
      getCurrentLocation,
      getSessionToken,
      onPlaceSelect,
      rawPlaceRef,
      t,
      withBounds,
      withFullAddress,
    ],
  );

  const predictionsFinal = useMemo((): Prediction[] => {
    if (!useProfileLocation) return predictions;

    return [
      {
        id: CURRENT_LOCATION_ID,
        name: t('General.useProfileLocation'),
        icon: <CurrentLocationIcon />,
      },
      ...predictions,
    ];
  }, [predictions, t, useProfileLocation]);

  const { dropdownOptions, selectOptions } = useMemo(() => {
    const dropdownOptions: DropdownOption[] = [];
    const selectOptions: OptionValue[] = [];

    for (let index = 0; index < predictionsFinal.length; index++) {
      const pred = predictionsFinal[index];

      dropdownOptions.push({
        title: (
          <>
            {pred.icon}
            {pred.id === CURRENT_LOCATION_ID ? (
              <GradientText gradientType="pink">{pred.name}</GradientText>
            ) : (
              pred.name
            )}
          </>
        ),
        key: pred.id,
        onClick: () =>
          getPlaceDetails(
            pred.id,
            pred.id === CURRENT_LOCATION_ID ? undefined : pred.name,
          ),
      });

      selectOptions.push({
        label: {
          icon: pred.icon,
          text: (pred.id === CURRENT_LOCATION_ID ? (
            <GradientText gradientType="pink">{pred.name}</GradientText>
          ) : (
            pred.name
          )) as any,
        },
        value: pred.id,
        searchValue: pred.name,
      });
    }

    if (predictions.length > 0) {
      dropdownOptions.push({
        key: 'google-attribution',
        title: <GoogleAttribution />,
        disabled: true,
        onClick: utils.noop,
      });
      selectOptions.push({
        label: { icon: <GoogleAttribution />, text: '' },
        value: 'google-attribution',
        searchValue: '',
        disabled: true,
      });
    }

    return { dropdownOptions, selectOptions };
  }, [getPlaceDetails, predictions, predictionsFinal]);

  const onInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const val = event?.target?.value || '';

      setQuery(val.trimStart());
    },
    [],
  );

  const onInputBlur = useCallback(() => {
    setIsInputFocused(false);
  }, []);

  const onInputFocus = useCallback(() => {
    setIsInputFocused(true);
  }, []);

  useEffect(() => {
    getPlacePredictions(query);

    if (!queryRemovesValue) return;

    // If the text inside the input changes from the selected
    // location, remove the location from state
    if (!query || !query.trim()) {
      onPlaceSelect?.(null, null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  // When selected value changes,
  // update query string
  useEffect(() => {
    if (typeof value === 'undefined') return;

    const val = formatPlace(value);

    setQuery(val);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueLatLng]);

  return {
    query,
    predictions: predictionsFinal,
    dropdownOptions,
    selectOptions,
    hasPredictionError,
    isInputFocused,
    getPlaceDetails,
    setPredictions,
    setQuery,
    onInputFocus,
    onInputBlur,
    onInputChange,
  };
};

export default useLocationAutocomplete;
