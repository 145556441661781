import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { Field, FieldRenderProps, UseFieldConfig } from 'react-final-form';
import { RadioGroupProps } from 'ncoded-component-library';
import formValidators from 'utils/formValidators';
import ValidationError from 'components/ValidationError';
import RadioButtonField from 'components/RadioButtonField';

import './RadioGroupField.styles.scss';

type CommonRadioGroupProps = {
  label?: string;
  isLargeLabel?: boolean;
  disabled?: boolean;
  customRadioComponent?: any;
} & RadioGroupProps;

type RadioGroupFieldProps = CommonRadioGroupProps &
  UseFieldConfig<string, string>;

type RadioGroupFieldComponentProps = CommonRadioGroupProps &
  FieldRenderProps<string, HTMLElement>;

const RadioGroupFieldComponent: React.FC<RadioGroupFieldComponentProps> = (
  props,
) => {
  const {
    className,
    label,
    name,
    input,
    meta,
    isLargeLabel,
    options,
    direction,
    onChange,
    disabled,
    ...restOfProps
  } = props;

  const classes = classNames(
    'anys-radio-group-field',

    className,
  );

  const legendClasses = classNames('anys-radio-group-field__legend', {
    'anys-radio-group-field__legend--large': isLargeLabel,
  });

  const optionsClasses = classNames('anys-radio-group-field__options', {
    'anys-radio-group-field__options--as-row': direction === 'row',
  });

  const {
    value,
    onChange: inputOnChange,
    ...restOfInput
  } = useMemo(() => input, [input]);

  const { hasError, error } = useMemo(
    () => formValidators.getErrorFromMeta(meta),
    [meta],
  );

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      inputOnChange(e);

      onChange?.(e);
    },
    [inputOnChange, onChange],
  );

  return (
    <div className={classes}>
      <fieldset name={name} disabled={disabled}>
        {label && <legend className={legendClasses}>{label}</legend>}

        <div className={optionsClasses}>
          {options.map((option) => (
            <RadioButtonField
              key={option.value}
              type="radio"
              label={option.label}
              value={option.value}
              checked={value === option.value}
              name={name}
              onChange={handleOnChange}
              disabled={disabled}
              {...restOfInput}
              {...restOfProps}
            />
          ))}
        </div>
      </fieldset>
      <ValidationError showError={hasError} error={error} />
    </div>
  );
};

const RadioGroupField: React.FC<RadioGroupFieldProps> = (props) => (
  <Field type="radio" component={RadioGroupFieldComponent} {...props} />
);

export default RadioGroupField;
