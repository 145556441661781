import React, { useMemo } from 'react';
import classNames from 'classnames';

import { SubmissionErrors } from 'final-form';
import { ConfirmSocialSignupReqBody } from 'models/Auth';
import { Button } from 'ncoded-component-library';
import { Form } from 'react-final-form';
import { Trans, useTranslation } from 'react-i18next';
import CheckboxField from 'components/CheckboxField';
import CustomLink from 'components/CustomLink';
import { Role } from 'models/User';
import RadioGroupField from 'components/RadioGroupField';

import './ConfirmSocialSignupForm.styles.scss';
import './ConfirmSocialSignupForm.styles.responsive.scss';

type AccountTypeOption = { label: React.ReactNode; value: Role };

type ConfirmSocialSignupFormProps = {
  className?: string;
  onSubmit: (
    values: ConfirmSocialSignupReqBody,
  ) => SubmissionErrors | Promise<SubmissionErrors> | void;
  initialValues: Partial<ConfirmSocialSignupReqBody>;
  inProgress: boolean;
};

const ConfirmSocialSignupForm: React.FC<ConfirmSocialSignupFormProps> = (
  props,
) => {
  const { className, initialValues, onSubmit, inProgress } = props;

  const { t } = useTranslation();

  const classes = classNames('anys-confirm-social-signup-form', className);

  const accountTypeOptions: AccountTypeOption[] = useMemo(
    () => [
      {
        label: (
          <span className="anys-confirm-social-signup-form__role">
            {t('General.individualAccount')}
            <span className="anys-confirm-social-signup-form__role__description">
              {t('Signup.individualAccountDescription')}
            </span>
          </span>
        ),
        value: 'Individual',
      },
      {
        label: (
          <span className="anys-confirm-social-signup-form__role">
            {t('General.businessAccount')}
            <span className="anys-confirm-social-signup-form__role__description">
              {t('Signup.businessAccountDescription')}
            </span>
          </span>
        ),
        value: 'Business',
      },
    ],
    [t],
  );

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={(formRenderProps) => {
        const { handleSubmit, invalid, values } = formRenderProps;

        const submitDisabled =
          invalid || inProgress || !values.termsAndConditions;

        return (
          <form className={classes} onSubmit={handleSubmit}>
            <h3 className="anys-confirm-social-signup-form__title">
              {t('General.confirmSignup')}
            </h3>
            <RadioGroupField
              name="role"
              label={t('General.accountType')}
              options={accountTypeOptions}
              isLargeLabel
              direction="row"
            />

            <CheckboxField
              name="termsAndConditions"
              className="anys-confirm-social-signup-form__terms"
              label={
                <Trans
                  i18nKey="Signup.termsAndConditionsLabel"
                  components={{
                    termsLink: (
                      <CustomLink
                        to="/static-pages/terms-and-conditions"
                        target="_blank"
                      />
                    ),
                  }}
                />
              }
            />

            <Button type="submit" disabled={submitDisabled}>
              {t('General.createAccount')}
            </Button>
          </form>
        );
      }}
    />
  );
};

export default ConfirmSocialSignupForm;
