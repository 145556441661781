import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import InputField from 'components/InputField';
import { SubmissionErrors } from 'final-form';
import { LoginReqBody } from 'models/Auth';
import { Button } from 'ncoded-component-library';
import { Form, FormProps } from 'react-final-form';
import formValidators from 'utils/formValidators';
import { useTranslation } from 'react-i18next';
import PasswordField from 'components/PasswordField';
import { useNavigate } from 'react-router-dom';

import './LoginForm.styles.scss';

const { getPasswordValidators, getEmailValidators } = formValidators;

type LoginFormProps = {
  className?: string;
  onSubmit: (
    values: LoginReqBody,
  ) => SubmissionErrors | Promise<SubmissionErrors> | void;
  inProgress: boolean;
} & FormProps<LoginReqBody>;

const LoginForm: React.FC<LoginFormProps> = (props) => {
  const { className, initialValues, onSubmit, inProgress } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const classes = classNames('anys-login-form', className);

  const messages = useMemo(
    () => ({
      email: t('General.email'),
      emailPlaceholder: t('General.emailPlaceholder'),

      password: t('General.password'),
      passwordPlaceholder: t('General.passwordPlaceholder'),

      login: t('General.login'),
      forgotPassword: `${t('General.forgotPassword')}?`,
    }),
    [t],
  );

  const validators = useMemo(
    () => ({
      email: getEmailValidators(t),
      password: getPasswordValidators(t),
    }),
    [t],
  );

  // TODO: change button to link
  const handleForgotPassword = useCallback(() => {
    navigate('../forgot-password');
  }, [navigate]);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={(formRenderProps) => {
        const { handleSubmit, invalid } = formRenderProps;

        const submitDisabled = invalid || inProgress;

        return (
          <form className={classes} onSubmit={handleSubmit}>
            <InputField
              type="email"
              name="email"
              label={messages.email}
              placeholder={messages.emailPlaceholder}
              validate={validators.email}
            />

            <PasswordField
              name="password"
              label={messages.password}
              placeholder={messages.passwordPlaceholder}
              validate={validators.password}
              hidePasswordStrength
            />
            <div className="anys-login-form__remember-me">
              <Button
                type="button"
                onClick={handleForgotPassword}
                variant="link"
                className="anys-login-form__forgot-password"
              >
                <span>{messages.forgotPassword} </span>
              </Button>
            </div>
            <Button type="submit" disabled={submitDisabled} variant="outline">
              {messages.login}
            </Button>
          </form>
        );
      }}
    />
  );
};

export default LoginForm;
