import React from 'react';
import { t } from 'i18next';
import { useProfile } from '../../../Profile/Profile.page';
import CertificatesPreview from './components/CertificatesPreview';
import ProfileCardModal from '../ProfileCardModal';

const Certificates = () => {
  const {
    profileUser: { certificates },
  } = useProfile();

  return certificates?.length ? (
    <ProfileCardModal
      className="anys-profile-preview__body__with-out-padding"
      cardTitle={t('Profile.certificates')}
      modalName="certificates-modal"
      modalTitle="Certificates"
      itemList={certificates.map((certificate, index) => (
        <CertificatesPreview key={index} certificate={certificate} />
      ))}
    />
  ) : null;
};

export default Certificates;
