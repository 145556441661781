import { CurrencyConfig } from 'constants/currency';
import { useCallback } from 'react';
import {
  ensureDotSeparator,
  getNumberFormatOptions,
  getPrice,
} from 'utils/currency';

export default function useFormatPrice(
  currencyConfig: CurrencyConfig = getNumberFormatOptions({
    style: 'decimal',
    currency: 'USD',
  }),
) {
  return useCallback(
    (unformattedValue: string) =>
      getPrice(ensureDotSeparator(unformattedValue), currencyConfig),
    [currencyConfig],
  );
}
