import React from 'react';
import classNames from 'classnames';
import RadioButtonChoice from 'components/RadioButtonChoice';
import RadioGroupField from 'components/RadioGroupField';
import { Form } from 'react-final-form';
import { Button } from 'ncoded-component-library';
import { useLocation, useNavigate } from 'react-router-dom';
import Notice from 'components/Notice';
import { RangeValueType } from 'ncoded-component-library/build/components/molecules/RangeSlider/RangeSlider.component';
import AmountSlider from '../AmountSlider';
import { Link } from 'react-router-dom';
import TimesIcon from 'icons/Times.icon';
import useInboxLink from 'router/subrouters/Inbox/hooks/useInboxLink';
import { useTranslation } from 'react-i18next';

import './ApproveDialog.styles.scss';

type ApproveDialogProps = {
  notice: string;
  explanation?: string;
  withAmountSlider?: boolean;
  maxAmountFee?: number;
  onConfirm: (data: {
    choice: 'approve' | 'refuse';
    priceRange?: RangeValueType;
    cancelationFee?: string;
  }) => void;
};

const ApproveDialog = (props: ApproveDialogProps) => {
  const {
    notice,
    explanation,
    withAmountSlider = false,
    maxAmountFee,
    onConfirm,
  } = props;

  const navigate = useNavigate();

  const { t } = useTranslation();

  const location = useLocation();
  const isInbox = location.pathname?.startsWith('/inbox');

  const { linkWihoutInboxItem } = useInboxLink();

  const classes = classNames('anys-contract-approve-dialog');

  return (
    <div className={classes}>
      <Form
        onSubmit={onConfirm}
        render={({ handleSubmit, values, form, submitError, errors }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="anys-contract-approve-dialog__title">
                <h1>{t('Contract.endContract')}</h1>
                <Link
                  to={isInbox ? linkWihoutInboxItem : '/provide'}
                  className="anys-payment-negotiation__title__close"
                >
                  <TimesIcon />
                </Link>
              </div>
              <Notice type="warn">{notice}</Notice>
              <p className="anys-contract-approve-dialog__explanation">
                {explanation}
              </p>

              <RadioGroupField
                name="choice"
                options={[
                  { value: 'approve', label: t('General.approve') },
                  { value: 'refuse', label: t('General.dontApprove') },
                ]}
                customRadioComponent={RadioButtonChoice}
                direction="row"
              />

              {withAmountSlider && (
                <AmountSlider
                  form={form}
                  maxAmountFee={+maxAmountFee?.toFixed(2)}
                  label={t('General.cancellationFee')}
                />
              )}

              <div className="anys-contract-approve-dialog__buttons">
                <Button
                  type="submit"
                  variant={'solid'}
                  disabled={
                    withAmountSlider
                      ? !values.choice || !values.cancelationFee
                      : !values.choice
                  }
                >
                  {withAmountSlider
                    ? t('General.collect')
                    : t('General.confirm')}
                </Button>
                {/* redirect back to preview all contract */}
                <Button
                  variant="link"
                  onClick={() =>
                    navigate(isInbox ? linkWihoutInboxItem : '/provide')
                  }
                >
                  {/* TODO: check do we need cancel action here */}
                  {/* <GradientText>{t('General.cancel')}</GradientText> */}
                </Button>
              </div>
            </form>
          );
        }}
      ></Form>
    </div>
  );
};

export default ApproveDialog;
