import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { SubmissionErrors } from 'final-form';
import { Button } from 'ncoded-component-library';
import { Form, FormProps } from 'react-final-form';
import formValidators from 'utils/formValidators';
import { useTranslation } from 'react-i18next';
import { ResetPasswordFormValues } from 'models/Auth';
import PasswordField from 'components/PasswordField';
import { useNavigate } from 'react-router-dom';

import './ResetPasswordForm.styles.scss';
import './ResetPasswordForm.styles.responsive.scss';

const { PASSWORD_MIN_LENGTH } = formValidators;

type ResetPasswordFormProps = {
  className?: string;
  onSubmit: (
    values: ResetPasswordFormValues,
  ) => SubmissionErrors | Promise<SubmissionErrors> | void;
  initialValues: Partial<ResetPasswordFormValues>;
  inProgress: boolean;
} & FormProps<ResetPasswordFormValues>;

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = (props) => {
  const { className, initialValues, onSubmit, inProgress } = props;

  const { t } = useTranslation();

  const classes = classNames('anys-reset-password-form', className);
  const navigate = useNavigate();

  const messages = useMemo(
    () => ({
      changePassword: t('General.changePassword'),
      subtitle: t('ChangePassword.changePasswordParagraph'),
      newPassword: t('General.newPassword'),
      confirmNewPassword: t('General.confirmNewPassword'),
      passwordMismatch: t('General.passwordMismatch'),
      backToLogin: t('General.backToLogin'),
      passwordDescription: t('Signup.passwordDescription', {
        minLength: PASSWORD_MIN_LENGTH,
      }),
    }),
    [t],
  );

  const passwordValidators = useMemo(
    () => formValidators.getPasswordValidators(t),
    [t],
  );

  const passwordMatchValidator = useCallback(
    (values: ResetPasswordFormValues) => {
      const errors: Partial<ResetPasswordFormValues> = {};

      if (values.password !== values.confirmPassword) {
        errors.confirmPassword = messages.passwordMismatch;
      }

      return errors;
    },
    [messages.passwordMismatch],
  );

  const handleBack = useCallback(() => {
    navigate('/auth/login', { replace: true });
  }, [navigate]);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={passwordMatchValidator}
      render={(formRenderProps) => {
        const { handleSubmit, invalid, values } = formRenderProps;

        const submitDisabled = invalid || inProgress || !values.token;

        return (
          <form className={classes} onSubmit={handleSubmit}>
            <h3>{messages.changePassword}</h3>
            <p className="anys-reset-password-form__subtitle">
              {messages.subtitle}
            </p>

            <PasswordField
              name="password"
              label={messages.newPassword}
              placeholder={messages.newPassword}
              validate={passwordValidators}
              hidePasswordStrength
              inputDescription={messages.passwordDescription}
            />
            <PasswordField
              name="confirmPassword"
              label={messages.confirmNewPassword}
              placeholder={messages.confirmNewPassword}
              validate={passwordValidators}
              hidePasswordStrength
            />

            <Button type="submit" disabled={submitDisabled} variant="outline">
              <span>{messages.changePassword}</span>
            </Button>

            <Button
              type="button"
              className="anys-reset-password-form__back-button"
              variant="link"
              onClick={handleBack}
            >
              <span>{messages.backToLogin}</span>
            </Button>
          </form>
        );
      }}
    />
  );
};

export default ResetPasswordForm;
