import React from 'react';

type IndentProps = React.SVGProps<SVGSVGElement>;

const Indent: React.FC<IndentProps> = (props) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.25 6.75V11.25L4.5 9L2.25 6.75ZM2.25 14.25H15.75V12.75H2.25V14.25ZM2.25 5.25H15.75V3.75H2.25V5.25ZM6.75 8.25H15.75V6.75H6.75V8.25ZM6.75 11.25H15.75V9.75H6.75V11.25Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Indent;
