import React, { useCallback, useState } from 'react';
import api from 'api';
import { InboxItem } from 'models/Inbox';
import { JobState } from 'models/Job';
import confirm from 'modules/confirm';
import showToast from 'modules/showToast';
import { useTranslation } from 'react-i18next';
import { getTranslationForJobState } from 'utils/job';

const useInboxItemDelete = (
  setItems: React.Dispatch<React.SetStateAction<InboxItem[]>>,
) => {
  const { t } = useTranslation();

  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = useCallback(
    async (idsToDelete: number[], state: JobState | 'items') => {
      const deletedItemsCount = idsToDelete.length;

      const stateMsg = getTranslationForJobState(t, state, deletedItemsCount);

      const lowerCaseEntityMsg = stateMsg.toLowerCase();

      const isMultiDelete = idsToDelete.length > 1;
      const thisOrThese = isMultiDelete
        ? t('General.these')
        : t('General.this');

      if (
        !(await confirm({
          title: t('General.removeEntity', { entity: lowerCaseEntityMsg }),
          description: (
            <div>
              {t('General.removeEntityQuestion', {
                entity: `${
                  isMultiDelete ? `${idsToDelete.length} ` : ''
                } ${lowerCaseEntityMsg}`,
              })}
              <div className="mt-16">
                {t('General.removeEntityIrreversible', {
                  thisOrThese: thisOrThese.toLowerCase(),
                  entity: lowerCaseEntityMsg,
                })}
              </div>
            </div>
          ),
          confirmContent: t('General.remove'),
        }))
      )
        return;

      setIsDeleting(true);

      try {
        if (isMultiDelete) {
          await api.inboxItems.deleteInboxItems(idsToDelete);
        } else {
          await api.inboxItems.deleteInboxItem(idsToDelete[0]);
        }

        showToast(
          'success',
          t('General.entityRemoved', { entity: stateMsg }),
          t('General.entityRemovedDesc', {
            count: deletedItemsCount,
            entity: lowerCaseEntityMsg,
          }),
        );

        setItems((oldItems) =>
          oldItems.filter((item) => !idsToDelete.includes(item.id)),
        );

        return true;
      } catch (error) {
        showToast('error', t('General.error'));
      } finally {
        setIsDeleting(false);
      }
    },
    [setItems, t],
  );

  return { isDeleting, handleDelete };
};

export default useInboxItemDelete;
