import React from 'react';
import { IconProps } from 'models/Icon';

const Camera: React.FC<IconProps> = (props) => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.75456 1.12716C6.89572 0.844839 7.18427 0.666504 7.49992 0.666504H12.4999C12.8156 0.666504 13.1041 0.844839 13.2453 1.12716L14.2649 3.1665H17.0833C18.2338 3.1665 19.1666 4.09924 19.1666 5.24984V15.2498C19.1666 16.4004 18.2338 17.3332 17.0833 17.3332H2.91658C1.76599 17.3332 0.833252 16.4004 0.833252 15.2498V5.24984C0.833252 4.09924 1.76599 3.1665 2.91658 3.1665H5.73489L6.75456 1.12716ZM7.59828 3.1665H12.4016L11.9849 2.33317H8.01495L7.59828 3.1665ZM2.91658 4.83317C2.68647 4.83317 2.49992 5.01972 2.49992 5.24984V15.2498C2.49992 15.48 2.68647 15.6665 2.91658 15.6665H17.0833C17.3134 15.6665 17.4999 15.48 17.4999 15.2498V5.24984C17.4999 5.01972 17.3134 4.83317 17.0833 4.83317H2.91658ZM5.83325 10.2498C5.83325 7.94864 7.69872 6.08317 9.99992 6.08317C12.3011 6.08317 14.1666 7.94864 14.1666 10.2498C14.1666 12.551 12.3011 14.4165 9.99992 14.4165C7.69872 14.4165 5.83325 12.551 5.83325 10.2498ZM9.99992 7.74984C8.6192 7.74984 7.49992 8.86912 7.49992 10.2498C7.49992 11.6306 8.6192 12.7498 9.99992 12.7498C11.3806 12.7498 12.4999 11.6306 12.4999 10.2498C12.4999 8.86912 11.3806 7.74984 9.99992 7.74984Z"
        fill="url(#paint0_linear_7424_10749)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_7424_10749"
          x1="0.833252"
          y1="17.3332"
          x2="19.4851"
          y2="16.9688"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A761F5" />
          <stop offset="1" stopColor="#50A8FA" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Camera;
