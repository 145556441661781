import { ChatMessage, ChatSession, SingleChatSession } from 'models/Chat';
import { PaginatedResponse, PaginationParams } from 'models/Pagination';
import utils from 'utils';
import httpClient from '../../httpClient';

const sessionsPath = (routePath: TemplateStringsArray) =>
  `chat/sessions/${routePath}`;

function getChatSessions(params: PaginationParams) {
  return httpClient.get<PaginatedResponse<ChatSession>>(sessionsPath``, {
    params,
  });
}

function getChatSession(chatSessionId: number) {
  return httpClient.get<SingleChatSession>(sessionsPath`` + chatSessionId);
}

function readSessions(chatSessionId: number) {
  return httpClient.post<ChatSession>(sessionsPath`` + chatSessionId + '/read');
}

function createChatSession(otherUserId: number) {
  return httpClient.post<SingleChatSession>(sessionsPath``, {
    userId: otherUserId,
  });
}

function removeChatSession(chatSessionId: number) {
  return httpClient.delete<string>(sessionsPath`` + chatSessionId);
}

function getChatMessages(chatSessionId: number, params: PaginationParams) {
  return httpClient.get<PaginatedResponse<ChatMessage>>(
    sessionsPath`` + `${chatSessionId}/messages`,
    {
      params,
    },
  );
}

function sendMessage(
  chatSessionId: number,
  { payload, files }: { payload: string; files?: File[] },
) {
  const formData = utils.prepareFilesForUpload(files, 'chatFiles');

  if (payload) formData.append('payload', payload);

  return httpClient.post<ChatMessage>(
    sessionsPath`` + `${chatSessionId}/messages`,
    formData,
  );
}

export default {
  getChatSessions,
  getChatSession,
  readSessions,
  createChatSession,
  removeChatSession,
  getChatMessages,
  sendMessage,
};
