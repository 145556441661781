import React, { useMemo } from 'react';
import classNames from 'classnames';
import ArbitrationInvite from './components/ArbitrationInvite/ArbitrationInvite.component';
import ArbitrationDecisioning from './components/ArbitrationDecisioning/ArbitrationDecisioning.component';
import { useArbitration } from 'providers/Arbitration/Arbitration.provider';

import './MeAsArbitrator.styles.scss';
import { useTranslation } from 'react-i18next';

type MeAsArbitratorProps = {
  className?: string;
};

const MeAsArbitrator = (props: MeAsArbitratorProps) => {
  const { className } = props;
  const classes = classNames('anys-me-as-arbitrator', className);
  const { t } = useTranslation();

  const { selectedArbitration } = useArbitration();

  const renderArbitrationState = useMemo(() => {
    // TODO if I accepted arbitration render ArbitrationDecisioning disable button
    const { state, arbitrationPrice, mainArbitrationOption } =
      selectedArbitration;

    switch (state) {
      case 'finding_arbiters':
        return <ArbitrationInvite />;
      case 'decisioning':
        return <ArbitrationDecisioning />;
      case 'finding_arbiters_failed':
        return <p>{t('Arbitration.decisionFailed')}</p>;
      case 'decisioning_failed':
        return <p>{t('Arbitration.decisionFailed')}</p>;
      case 'decision_made':
        return (
          <p>
            {t('Arbitration.decisionMadeAsArbitrator', {
              amount:
                arbitrationPrice /
                100 /
                mainArbitrationOption?.arbitratorsNumber,
            })}
          </p>
        );
      default:
        return <div>{state}</div>;
    }
  }, [selectedArbitration, t]);

  return <div className={classes}>{renderArbitrationState}</div>;
};

export default MeAsArbitrator;
