import classNames from 'classnames';
import GradientText from 'components/GradientText';
import TextAreaField from 'components/TextAreaField';
import { Button } from 'ncoded-component-library';
import React, { useCallback, useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import formValidators from 'utils/formValidators';
import AmountSlider from '../AmountSlider';
import ReviewEmojisGrade from './components/ReviewEmojisGrade';

import './RequestPaymentModal.styles.scss';

type RequestPaymentModalProps = {
  className?: string;
  explanation?: string;
  maxAmountFee?: number;
  title?: string;
  message?: string;
  onConfirm: (data: {
    cancelationFee?: string;
    mark?: number;
    explanation?: string;
  }) => void;
  hasReview?: boolean;
  initialValue?: number;
  onCancel?: () => void;
};

const RequestPaymentModal: React.FC<RequestPaymentModalProps> = (props) => {
  const {
    className,
    maxAmountFee,
    title,
    message,
    onConfirm,
    hasReview = true,
    initialValue,
    onCancel,
  } = props;

  const classes = classNames('anys-end-contract-modal', className);

  const { t } = useTranslation();

  const disableButton = useCallback((form: any) => {
    if (!form.cancelationFee || !form.mark) {
      return true;
    }
    if (form.mark < 5 && !form.explanation) {
      return true;
    }
  }, []);

  const [initial, setInitial] = useState(initialValue);

  return (
    <div className={classes}>
      <Form
        onSubmit={onConfirm}
        initialValues={
          initial && {
            cancelationFee: +initial.toFixed(2),
            priceRange: {
              min: initial,
              max: maxAmountFee,
              range: maxAmountFee - initial,
            },
          }
        }
        render={({ handleSubmit, form, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <h1>{title}</h1>
              {message && <span>{message}</span>}
              {hasReview && (
                <>
                  <h2>{t('General.reviews')}</h2>

                  <ReviewEmojisGrade
                    onChange={(value) => {
                      form.change('mark', value + 1);
                    }}
                  />
                  {values.mark < 5 && (
                    <TextAreaField
                      label={t('Arbitration.explanation')}
                      name="explanation"
                      placeholder={t(
                        'Arbitration.writeAnExplanationForEndingContract',
                      )}
                      className="anys-end-contract-modal__textarea"
                      maxLength={2000}
                      validate={formValidators.contractExplanation()}
                    />
                  )}
                </>
              )}

              <AmountSlider
                form={form}
                maxAmountFee={maxAmountFee}
                label={t('Contract.paymentRequest')}
                initialValue={+initial?.toFixed(2)}
              />

              <div className="anys-end-contract-modal__actions">
                {/* {values.mark < 5 && !values.explanation && (
                  <span className="anys-end-contract-modal__actions__error">
                    If you rate client with grade less then excellent, you must
                    add explanation
                  </span>
                )} */}

                <Button
                  type="button"
                  variant={'solid'}
                  onClick={(event) => handleSubmit(event)}
                  disabled={
                    initial && !hasReview ? false : disableButton(values)
                  }
                >
                  {!hasReview ? t('General.accept') : t('General.send')}
                </Button>

                <Button
                  variant="link"
                  onClick={() => {
                    onCancel && onCancel();
                    if (initial) {
                      setInitial(0);
                    }
                  }}
                  //TODO: check where should this navigate, this was Link previous
                  // to={`/contract`}
                >
                  <GradientText>
                    {t('General.cancel')}
                    {/* TODO: check do we need change action here */}
                    {/* {initial ? t('General.change') : t('General.cancel')} */}
                  </GradientText>
                </Button>
              </div>
            </form>
          );
        }}
      />
    </div>
  );
};

export default RequestPaymentModal;
