export const week: {
  day: string;
  label: string;
  // plural: string;
  abbreviation: string;
  id: number;
}[] = [
  {
    day: 'Sunday',
    label: 'General.sunday',
    // plural: 'Sundays',
    abbreviation: 'sun',
    id: 0,
  },
  {
    day: 'Monday',
    label: 'General.monday',
    // plural: 'Mondays',
    abbreviation: 'mon',
    id: 1,
  },
  {
    day: 'Tuesday',
    label: 'General.tuesday',
    // plural: 'Tuesdays',
    abbreviation: 'tue',
    id: 2,
  },
  {
    day: 'Wednesday',
    label: 'General.wednesday',
    // plural: 'Wednesdays',
    abbreviation: 'wed',
    id: 3,
  },
  {
    day: 'Thursday',
    label: 'General.thursday',
    // plural: 'Thursdays',
    abbreviation: 'thu',
    id: 4,
  },
  {
    day: 'Friday',
    label: 'General.friday',
    // plural: 'Fridays',
    abbreviation: 'fri',
    id: 5,
  },
  {
    day: 'Saturday',
    label: 'General.saturday',
    // plural: 'Saturdays',
    abbreviation: 'sat',
    id: 6,
  },
];
