import auth from './auth';
import user from './user';
import skill from './skill';
import skillGroup from './skillGroups';
import job from './job';
import jobPost from './jobPost';
import users from './user';
import favouriteFilters from './favouriteFilters';
import availability from './availability';
import contract from './contract';
import inboxItems from './inboxItems';
import arbitration from './arbitration';
import notification from './notification';
import arbitratorTerms from './arbitratorTerms';
import account from './account';
import wallet from './wallet';
import positionBoosts from './positionBoosts';
import frequentQuestions from './frequentQuestions';
import positionBoostMetrics from './positionBoostMetrics';
import referral from './referral';

export default {
  auth,
  user,
  users,
  skill,
  skillGroup,
  job,
  jobPost,
  favouriteFilters,
  availability,
  contract,
  inboxItems,
  arbitration,
  notification,
  arbitratorTerms,
  account,
  wallet,
  positionBoosts,
  positionBoostMetrics,
  frequentQuestions,
  referral,
};
