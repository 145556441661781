import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import DataPiecePreview from 'components/JobPostPreview/components/DataPiecePreview';
import { useTranslation } from 'react-i18next';
import CustomLink from 'components/CustomLink';
import { Boost, BoostType, PositionBoost } from 'models/Boosts';
import api from 'api';
import showToast from 'modules/showToast';
import { formatLocation } from 'utils/location';
import confirm from 'modules/confirm';
import GradientText from 'components/GradientText';

import './BoostPreview.styles.scss';
import './BoostPreview.styles.responsive.scss';

type BoostPreviewProps = {
  className?: string;
  positionBoost: PositionBoost;
  designBoost: Boost;
  editBoostLink: string;
};

const BoostPreview: React.FC<BoostPreviewProps> = (props) => {
  const { className, positionBoost, designBoost, editBoostLink } = props;

  const {
    id,
    skills,
    locations,
    numberOfReachViews,
    numberOfReachHotViews,
    numberOfClicks,
    numberOfBuys,
    currentBudget,
    initialBudget,
    state,
  } = positionBoost || {};

  const { t } = useTranslation();
  const [isChangingBoostState, setIsChangingBoostState] = useState(false);

  const classes = classNames('anys-boost-preview', className);

  const selectedSkills = useMemo(
    () =>
      skills?.length
        ? skills.map(({ name }) => name).join(', ')
        : t('Skills.noAddedSkills'),
    [skills, t],
  );

  const selectedLocations = useMemo(
    () =>
      locations?.length ? (
        <ul>
          {locations.map((location) => (
            <li key={location.id}>
              {formatLocation({
                locationType: 'One spot',
                locations: [location],
              })}
            </li>
          ))}
        </ul>
      ) : (
        t('General.noLocation')
      ),
    [locations, t],
  );

  const postDesignTitles: Record<BoostType, string> = useMemo(
    () => ({
      'Size-50': t('General.increasedPostSizeVal', { val: '50%' }),
      'Size-100': t('General.increasedPostSizeVal', { val: '100%' }),
      Photo: t('General.postWithImage'),
      Video: t('General.postWithVideo'),
    }),
    [t],
  );

  const isPaused = state === 'paused';

  const boostStateMsg = useMemo(() => {
    switch (state) {
      case 'canceled':
        return t('General.canceled');

      case 'paused':
        return t('General.stopped');

      case 'done':
        return t('General.done');

      case 'scheduled':
        return t('General.scheduled');

      default:
        break;
    }
  }, [state, t]);

  const onStopBoost = useCallback(async () => {
    const shouldStop = await confirm({
      title: t('General.actionConfirmQuestion', {
        action: t('General.stop').toLowerCase(),
      }),
      confirmContent: t('General.yes'),
    });

    if (!shouldStop) return;

    setIsChangingBoostState(true);

    try {
      const { data } = await api.positionBoosts.pauseBoost(id);

      positionBoost.state = data.state;
    } catch (error) {
      showToast('error', t('General.error'));
    } finally {
      setIsChangingBoostState(false);
    }
  }, [id, positionBoost, t]);

  const onContinueBoost = useCallback(async () => {
    const shouldContinue = await confirm({
      title: t('General.actionConfirmQuestion', {
        action: t('General.continue').toLowerCase(),
      }),
      confirmContent: t('General.yes'),
    });

    if (!shouldContinue) return;

    setIsChangingBoostState(true);

    try {
      const { data } = await api.positionBoosts.resumeBoost(id);

      positionBoost.state = data.state;
    } catch (error) {
      showToast('error', t('General.error'));
    } finally {
      setIsChangingBoostState(false);
    }
  }, [id, positionBoost, t]);

  return (
    <section className={classes}>
      <div className="anys-boost-preview__header">
        <h2>{t('General.boosting')}</h2>
        {boostStateMsg ? (
          <GradientText gradientType="pink">{boostStateMsg}</GradientText>
        ) : null}

        <div className="anys-boost-preview__header__actions">
          <CustomLink
            to={editBoostLink}
            className="anys-boost-preview__header__actions__edit"
          >
            {t('General.edit')}
          </CustomLink>
          {id && currentBudget > 0 && (
            <button
              type="button"
              onClick={isPaused ? onContinueBoost : onStopBoost}
              disabled={isChangingBoostState}
              className="anys-boost-preview__header__actions__stop"
            >
              {isPaused ? t('General.continue') : t('General.stop')}
            </button>
          )}
        </div>
      </div>
      {designBoost?.id ? (
        <DataPiecePreview
          label={t('General.postDesign')}
          value={postDesignTitles[designBoost.type]}
          className="mb-24"
        />
      ) : null}
      <div className="anys-boost-preview__content">
        {!id && !designBoost?.id ? (
          <div>{t('General.noBoostAdded')}</div>
        ) : (
          <>
            <div className="anys-boost-preview__content__skills-and-loc">
              <DataPiecePreview
                label={t('General.skills')}
                value={selectedSkills}
              />
              <DataPiecePreview
                label={t('General.locations')}
                value={selectedLocations}
                valueAs="div"
              />
            </div>
            <div className="anys-boost-preview__content__stats">
              <div>
                <DataPiecePreview
                  label={t('General.view')}
                  value={numberOfReachViews || 0}
                />
                <DataPiecePreview
                  label={t('General.hotView')}
                  value={numberOfReachHotViews || 0}
                />
                <DataPiecePreview
                  label={t('General.click')}
                  value={numberOfClicks || 0}
                />
                <DataPiecePreview
                  label={t('General.buy')}
                  value={numberOfBuys || 0}
                />
              </div>
              <div>
                <DataPiecePreview
                  label={t('General.moneySpent')}
                  value={
                    Math.abs((initialBudget || 0) - (currentBudget || 0)) / 100
                  }
                />
                <DataPiecePreview
                  label={t('General.remainingBudget')}
                  value={(currentBudget || 0) / 100}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default BoostPreview;
