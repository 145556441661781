import React from 'react';
import classNames from 'classnames';
import { ReactComponent as FileIcon } from 'icons/file.svg';
import { NonImageFile } from 'models/User';
import ExternalLink from 'components/ExternalLink';

import './FileItem.styles.scss';

type FileItemProps = {
  className?: string;
  file: NonImageFile;
};

const FileItem: React.FC<FileItemProps> = (props) => {
  const { className, file } = props;

  const classes = classNames('anys-file-item', className);

  const { name, url } = file || {};

  return (
    <ExternalLink href={url} className={classes}>
      <FileIcon />
      <span className="anys-file-item__name">{name}</span>
    </ExternalLink>
  );
};

export default FileItem;
