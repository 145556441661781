import { NavMenuItem } from 'models/Navigation';
import { NotificationEntity } from 'models/Notification';
import { createContext } from 'react';

type NotificationsContextProps = {
  notificationsByGroup: Partial<Record<NavMenuItem, number>>;
  arbitrationNotifications: Partial<Record<number, NotificationEntity[]>>;
  inboxItemNotifications: Partial<Record<number, NotificationEntity[]>>;
  notificationsCount: number;
  getInboxItemNotifCount: (inboxItemId: number) => number;
  getLastNotifForInboxItem: (inboxItemId: number) => NotificationEntity | null;
  getArbitrationNotifCount: (arbitrationId: number) => number;
  getLastNotifForArbitration: (
    arbitrationId: number,
  ) => NotificationEntity | null;
  readManyNotifications: (
    type: NavMenuItem,
    entityId: number,
    notifIds: number[],
  ) => Promise<boolean>;
};

export default createContext<Partial<NotificationsContextProps>>({});
