import React from 'react';
import classNames from 'classnames';
import { Link, LinkProps } from 'react-router-dom';
import Avatar from 'components/Avatar';
import DropdownOptions, {
  DropdownOption,
} from 'components/DropdownOptions/DropdownOptions.component';
import { ReactComponent as HorizontalDots } from 'icons/horizontal-dots.svg';

import './UserItem.styles.scss';
import './UserItem.styles.responsive.scss';

type WrapperProps = {
  className?: string;
  linkProps?: LinkProps;
};

const Wrapper: React.FC<React.PropsWithChildren<WrapperProps>> = (props) => {
  const { linkProps, children, ...rest } = props;

  if (linkProps)
    return (
      <Link {...linkProps} {...rest}>
        {children}
      </Link>
    );

  return <div {...rest}>{children}</div>;
};

type UserItemProps = {
  menuOptions?: DropdownOption[];
  isSelected?: boolean;
  topPartContent?: React.ReactNode;
  bottomPartContent?: React.ReactNode;
  imgUrl?: string;
  imgAlt?: string;
  cta?: React.ReactNode;
  prefixContent?: React.ReactNode;
} & WrapperProps;

const UserItem: React.FC<React.PropsWithChildren<UserItemProps>> = (props) => {
  const {
    className,
    linkProps,
    imgUrl,
    imgAlt,
    menuOptions,
    isSelected,
    topPartContent,
    bottomPartContent,
    children,
    cta,
    prefixContent,
  } = props;

  const classes = classNames(
    'anys-user-item',
    {
      'anys-user-item--selected': isSelected,
    },
    className,
  );

  return (
    <div className={classes}>
      {prefixContent}
      <Wrapper
        linkProps={linkProps}
        className={classNames('anys-user-item__item')}
      >
        {imgUrl ? (
          <Avatar
            img={imgUrl}
            alt={imgAlt}
            className="anys-user-item__item__avatar"
          />
        ) : null}

        {topPartContent || bottomPartContent ? (
          <span className="anys-user-item__item__info">
            {topPartContent && <span>{topPartContent}</span>}
            {bottomPartContent && <span>{bottomPartContent}</span>}
          </span>
        ) : null}

        {children}
      </Wrapper>

      {cta}

      {menuOptions && (
        <DropdownOptions
          className="anys-user-item__menu"
          contentClassName="anys-user-item__menu__content"
          trigger={
            <button type="button" className="anys-user-item__menu__trigger">
              <HorizontalDots />
            </button>
          }
          options={menuOptions}
          renderAsPortal
        />
      )}
    </div>
  );
};

export default UserItem;
