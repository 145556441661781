import React, { useMemo } from 'react';
import classNames from 'classnames';
import Card from 'components/Card';
import { t } from 'i18next';

import './JobSuccessCard.styles.scss';
import { useProfile } from 'router/subrouters/Profile/pages/Profile/Profile.page';

type JobSuccessCardProps = {
  className?: string;
};

const JobSuccessCard: React.FC<JobSuccessCardProps> = (props) => {
  const { className } = props;

  const {
    profileUser: { jobSuccess },
  } = useProfile();

  const classes = classNames('anys-job-success-card', className);

  const messages = useMemo(
    () => ({
      title: t('JobSuccess.jobSuccess'),
      totalAssignments: t('JobSuccess.totalAssignments', {
        assignments: jobSuccess?.numberOfSuccessfulJobs,
      }),
      noAssignmentsCompletedPlaceholder: t(
        'JobSuccess.noAssignmentsCompletedPlaceholder',
      ),
    }),
    [jobSuccess?.numberOfSuccessfulJobs],
  );

  return (
    <Card cardTitle={messages.title} className={classes}>
      {jobSuccess?.successPercentage ? (
        <div>
          <label className="anys-job-success-card__percentage">{`${jobSuccess?.successPercentage}%`}</label>
          <div className="anys-job-success-card__progress">
            <div
              style={{ width: `${jobSuccess?.successPercentage}%` }}
              className="anys-job-success-card__filler"
            />
          </div>
          <p className="anys-job-success-card__assignments">
            {messages.totalAssignments}
          </p>
        </div>
      ) : (
        <p className="anys-job-success-card__no-assignments">
          {messages.noAssignmentsCompletedPlaceholder}
        </p>
      )}
    </Card>
  );
};

export default JobSuccessCard;
