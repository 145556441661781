import React, { useContext, useMemo } from 'react';
import classNames from 'classnames';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import { Navigate } from 'react-router-dom';

import './Home.styles.scss';

type HomeProps = {
  className?: string;
};

const Home: React.FC<HomeProps> = (props) => {
  const { className } = props;

  const { shouldNavigateToAuth } = useContext(CurrentUserContext);

  const classes = classNames('anys-home', className);

  const shouldNavToAuth = useMemo(
    () => shouldNavigateToAuth(),
    [shouldNavigateToAuth],
  );

  if (shouldNavToAuth) {
    return <Navigate to="/auth" replace />;
  } else {
    return <Navigate to="/provide" replace />;
  }

  return <div className={classes}>hello from Home!</div>;
};

export default Home;
