import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import {
  Field,
  FieldRenderProps,
  UseFieldConfig,
  useFormState,
} from 'react-final-form';
import formValidators from 'utils/formValidators';
import { Checkbox } from 'ncoded-component-library';
import { CheckboxProps } from 'ncoded-component-library/build/components/molecules/Checkbox/Checkbox.component';

import './CheckboxField.styles.scss';

export type CheckboxValue = string | number | boolean;

type DefaultCheckboxProps = Omit<CheckboxProps, 'onChange'> & {
  onChange?: (value: CheckboxValue) => void;
  name: string;
};

type CheckboxFieldProps = DefaultCheckboxProps &
  UseFieldConfig<CheckboxValue, CheckboxValue>;

type CheckboxFieldComponentProps = DefaultCheckboxProps &
  FieldRenderProps<CheckboxValue, HTMLElement>;

const CheckboxFieldComponent: React.FC<CheckboxFieldComponentProps> = (
  props,
) => {
  const { className, input, meta, label, onChange, ...restOfProps } = props;

  const { values } = useFormState();

  const {
    onChange: inputOnChange,
    value: inputValue,
    checked,
    name,
    ...restOfInput
  } = useMemo(() => input, [input]);

  const checkboxValues = useMemo(
    () => (values[name] || []) as CheckboxValue[],
    [name, values],
  );

  const classes = classNames('anys-checkbox-field', className);

  const hasInputValue = typeof inputValue !== 'undefined';

  const { hasError } = useMemo(
    () => formValidators.getErrorFromMeta(meta),
    [meta],
  );

  const checkedFinal = hasInputValue
    ? checkboxValues.includes(inputValue)
    : checked;

  const handleOnChange = useCallback(
    (checked: boolean) => {
      onChange?.(hasInputValue ? inputValue : checked);

      inputOnChange(
        hasInputValue
          ? !checked
            ? checkboxValues.filter((val) => val !== inputValue)
            : [...checkboxValues, inputValue]
          : checked,
      );
    },
    [checkboxValues, hasInputValue, inputOnChange, inputValue, onChange],
  );

  return (
    <Checkbox
      {...restOfInput}
      {...restOfProps}
      name={name}
      checked={checkedFinal}
      value={inputValue as any}
      onChange={handleOnChange as any}
      label={label}
      hasError={hasError}
      className={classes}
    />
  );
};

const CheckboxField: React.FC<CheckboxFieldProps> = (props) => (
  <Field component={CheckboxFieldComponent} {...props} type="checkbox" />
);

export default CheckboxField;
