import React, { useContext, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import AnyServiceLogoIcon from 'icons/AnyServiceLogo.icon';
import useHeaderStyles from 'hooks/useHeaderStyles';
import { useNavigate, useSearchParams } from 'react-router-dom';
import credentialsService from 'services/credentialsService';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import ConfirmSocialSignupForm from './ConfirmSocialSignupForm';
import { useAuth } from '../Auth/Auth.page';
import { ConfirmSocialSignupReqBody } from 'models/Auth';
import { Link } from 'react-router-dom';
import { Loader } from 'ncoded-component-library';

import './ConfirmSocialSignup.styles.scss';
import './ConfirmSocialSignup.styles.responsive.scss';

type ConfirmSocialSignupProps = {
  className?: string;
};

const ConfirmSocialSignup: React.FC<ConfirmSocialSignupProps> = (props) => {
  const { className } = props;

  const [searchParams] = useSearchParams();
  const [hasCheckedForRole, setHasCheckedForRole] = useState(false);
  const navigate = useNavigate();

  const { getUser } = useContext(CurrentUserContext);

  const { completeSignupWithSocial, isAuthInProgress } = useAuth();

  const classes = classNames('anys-confirm-social-signup', className);

  const initialValues: Partial<ConfirmSocialSignupReqBody> = useMemo(
    () => ({
      role: 'Individual',
    }),
    [],
  );

  useHeaderStyles({
    hasBorder: false,
    showBackButton: true,
  });

  useEffect(() => {
    setHasCheckedForRole(false);

    const token = searchParams.get('access-token');
    const refreshToken = searchParams.get('refresh-token');
    const role = searchParams.get('role');

    if (token && refreshToken)
      credentialsService.saveAuthBody({ token, refreshToken });

    if (!role) {
      setHasCheckedForRole(true);

      return;
    }

    getUser().then(() => {
      setHasCheckedForRole(true);
    });

    navigate('/');
  }, [getUser, navigate, searchParams]);

  return hasCheckedForRole ? (
    <div className={classes}>
      <Link to="/">
        <AnyServiceLogoIcon className="anys-confirm-social-signup__big-logo" />
      </Link>

      <ConfirmSocialSignupForm
        initialValues={initialValues}
        onSubmit={completeSignupWithSocial}
        inProgress={isAuthInProgress}
      />
    </div>
  ) : (
    <Loader />
  );
};

export default ConfirmSocialSignup;
