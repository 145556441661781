import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

export default (props: IconProps) => (
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22 0C34.1503 0 44 9.84973 44 22C44 34.1503 34.1503 44 22 44C9.84973 44 0 34.1503 0 22C0 9.84973 9.84973 0 22 0ZM22 4C12.0589 4 4 12.0589 4 22C4 31.9411 12.0589 40 22 40C31.9411 40 40 31.9411 40 22C40 12.0589 31.9411 4 22 4ZM29 27C30.4868 27 31.4538 28.5646 30.7889 29.8944C30.4337 30.6047 29.7599 31.556 28.7037 32.4948C26.9482 34.0553 24.7045 35 22 35C19.2955 35 17.0518 34.0553 15.2963 32.4948C14.2401 31.556 13.5663 30.6047 13.2111 29.8944C12.5462 28.5646 13.5132 27 15 27H29ZM29 16C30.0257 16 30.871 16.7721 30.9865 17.7668L31 18V19C31 20.1046 30.1046 21 29 21C27.9743 21 27.129 20.2279 27.0135 19.2332L27 19V18C27 16.8954 27.8954 16 29 16ZM15 16C16.0257 16 16.871 16.7721 16.9865 17.7668L17 18V19C17 20.1046 16.1046 21 15 21C13.9743 21 13.129 20.2279 13.0135 19.2332L13 19V18C13 16.8954 13.8954 16 15 16Z"
      fill="url(#paint0_linear_9548_6692)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_9548_6692"
        x1="-7.32063e-08"
        y1="44"
        x2="44.7673"
        y2="43.2049"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A761F5" />
        <stop offset="1" stopColor="#50A8FA" />
      </linearGradient>
    </defs>
  </svg>
);
