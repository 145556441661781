import React from 'react';
import { t } from 'i18next';
import ProfileCard from 'components/Card/ProfileCardComponent';
import { useProfile } from '../../../Profile/Profile.page';
import useCallbackRef from 'hooks/useCallbackRef';
import { ModalRef } from 'components/Modal';
import ProfilePreviewModal from '../ProfilePreviewModal';
import classNames from 'classnames';

const PREVIEW_TEXT_LENGTH = 200;

const AboutMe = () => {
  const {
    profileUser: { about },
  } = useProfile();

  const [aboutMeModal, aboutMeModalRef] = useCallbackRef<ModalRef>(null);

  return about ? (
    <ProfileCard
      cardTitle={t('Profile.aboutMe')}
      {...(about.length > PREVIEW_TEXT_LENGTH && {
        buttonLabel: t('General.seeMore'),
        onClick: () => {
          aboutMeModal.open();
        },
      })}
    >
      <>
        <span
          className={classNames(
            'anys-profile-preview__body__preview__value',
            'anys-profile-preview__body__preview__value--about-me',
          )}
        >
          {`${about.substring(0, PREVIEW_TEXT_LENGTH)}${
            about.length > PREVIEW_TEXT_LENGTH && '...'
          }`}
        </span>
        <ProfilePreviewModal
          modalName="about-me-modal"
          title="About me"
          modalRef={aboutMeModalRef}
        >
          <span
            className={classNames(
              'anys-profile-preview__body__preview__value',
              'anys-profile-preview__body__preview__value--about-me',
            )}
          >
            {about}
          </span>
        </ProfilePreviewModal>
      </>
    </ProfileCard>
  ) : null;
};

export default AboutMe;
