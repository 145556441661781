import React, { useId } from 'react';

export type StarProps = {
  filled?: number;
  size?: number;
  className?: string;
};

const FilledStar = (props: StarProps) => {
  const { filled = 14, size = 20, className } = props;

  const id = useId();

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <mask
        id={`mask0_1359_6444_${id}`}
        maskUnits="userSpaceOnUse"
        x="1"
        y="1"
        width="14"
        height="13"
      >
        <path
          d="M7.41242 1.51994C7.48506 1.35809 7.71484 1.35809 7.78748 1.51994L9.36891 5.04348C9.45859 5.24328 9.64749 5.38053 9.86522 5.40407L13.705 5.81926C13.8814 5.83834 13.9524 6.05688 13.8209 6.17597L10.9585 8.76884C10.7962 8.91586 10.724 9.13793 10.7689 9.35228L11.5606 13.1324C11.597 13.3061 11.4111 13.4411 11.2572 13.3529L7.90669 11.4318L7.80444 11.6101L7.90668 11.4318C7.7167 11.3229 7.4832 11.3229 7.29322 11.4318L3.94273 13.3529C3.78883 13.4411 3.60293 13.3061 3.63929 13.1324L4.43097 9.35228C4.47586 9.13793 4.40371 8.91586 4.2414 8.76884L1.37901 6.17597C1.24752 6.05687 1.31853 5.83834 1.49491 5.81926L5.33468 5.40407C5.55241 5.38053 5.74132 5.24328 5.83099 5.04348L7.41242 1.51994Z"
          fill="#FBD653"
          stroke="#828282"
          strokeWidth="0.411111"
        />
      </mask>
      <g mask={`url(#mask0_1359_6444_${id})`}>
        <rect
          opacity="0.3"
          x="0"
          y="0.592896"
          width="15"
          height="12.3333"
          fill="#3A3F45"
          fillOpacity="0.8"
        />
        <rect
          x="0.999878"
          y="0.400024"
          width={filled}
          height="13"
          fill="#FFBF0B"
        />
      </g>
    </svg>
  );
};

export default FilledStar;
