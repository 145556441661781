import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

export default (props: IconProps) => (
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17 16C17 14.8954 16.1046 14 15 14C13.8954 14 13 14.8954 13 16V17C13 18.1046 13.8954 19 15 19C16.1046 19 17 18.1046 17 17V16Z"
      fill="url(#paint0_linear_9548_30168)"
    />
    <path
      d="M31 16C31 14.8954 30.1046 14 29 14C27.8954 14 27 14.8954 27 16V17C27 18.1046 27.8954 19 29 19C30.1046 19 31 18.1046 31 17V16Z"
      fill="url(#paint1_linear_9548_30168)"
    />
    <path
      d="M22 23C18.9144 23 16.7089 24.2495 15.2963 25.5052C14.4653 26.2439 13.7115 27.106 13.2112 28.1056C12.7172 29.0935 13.1176 30.2949 14.1056 30.7889C15.4329 31.4525 16.3282 30.5115 17.0227 29.531C17.219 29.2539 17.5266 28.8745 17.9537 28.4948C18.7912 27.7505 20.0856 27 22 27C23.9144 27 25.2089 27.7505 26.0463 28.4948C26.498 28.8963 26.9299 29.3738 27.2192 29.9104C27.7181 30.8867 28.9118 31.2802 29.8944 30.7889C30.8824 30.2949 31.2828 29.0935 30.7889 28.1056C30.2886 27.1062 29.5345 26.2437 28.7037 25.5052C27.2912 24.2495 25.0856 23 22 23Z"
      fill="url(#paint2_linear_9548_30168)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 0C9.84973 0 0 9.84973 0 22C0 34.1503 9.84973 44 22 44C34.1503 44 44 34.1503 44 22C44 9.84973 34.1503 0 22 0ZM4 22C4 12.0589 12.0589 4 22 4C31.9411 4 40 12.0589 40 22C40 31.9411 31.9411 40 22 40C12.0589 40 4 31.9411 4 22Z"
      fill="url(#paint3_linear_9548_30168)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_9548_30168"
        x1="-7.32063e-08"
        y1="44"
        x2="44.7673"
        y2="43.2049"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A761F5" />
        <stop offset="1" stopColor="#50A8FA" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_9548_30168"
        x1="-7.32063e-08"
        y1="44"
        x2="44.7673"
        y2="43.2049"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A761F5" />
        <stop offset="1" stopColor="#50A8FA" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_9548_30168"
        x1="-7.32063e-08"
        y1="44"
        x2="44.7673"
        y2="43.2049"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A761F5" />
        <stop offset="1" stopColor="#50A8FA" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_9548_30168"
        x1="-7.32063e-08"
        y1="44"
        x2="44.7673"
        y2="43.2049"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A761F5" />
        <stop offset="1" stopColor="#50A8FA" />
      </linearGradient>
    </defs>
  </svg>
);
