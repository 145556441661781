import React, { useCallback, useContext, useState } from 'react';
import classNames from 'classnames';
import SideWrapper from '../SideWrapper';
import MessageSquareIcon from 'icons/MessageSquare.icon';
import GoRightIcon from 'icons/GoRight.icon';
import Chat from 'components/Chat';
import { useNavigate, useParams } from 'react-router-dom';
import { InboxUrlParams } from 'models/Inbox';
import useInboxLink from '../../hooks/useInboxLink';
import ChatList from 'components/ChatList';
import { ChatSession } from 'models/Chat';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import { useTranslation } from 'react-i18next';
import ChatNotificationsContext from 'providers/ChatNotifications/ChatNotifications.context';
import NotificationBadge from 'components/NotificationBadge';
import { AsideOpenState } from 'components/Aside';
import useMatchMedia from 'hooks/useMatchMedia';
import { INBOX_CHAT_BREAKPOINT } from '../../utils';

import './SideChat.styles.scss';
import './SideChat.styles.responsive.scss';

type SideChatProps = {
  className?: string;
};

const SideChat: React.FC<SideChatProps> = (props) => {
  const { className } = props;

  const { currentUser } = useContext(CurrentUserContext);
  const { skipNotificationsForSession, totalUnreadNotifications } = useContext(
    ChatNotificationsContext,
  );

  const { chatUserId } = useParams<InboxUrlParams>();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isMobileView = useMatchMedia(INBOX_CHAT_BREAKPOINT[0]);
  const shouldCollapseSideChat = useMatchMedia(INBOX_CHAT_BREAKPOINT);

  const [chatOpen, setChatOpen] = useState(false);

  const { linkWihoutChat, createChatLink } = useInboxLink();

  const classes = classNames('anys-side-chat', className);

  const hasChatUserId = !!+chatUserId;

  // When chat is in collapsed mode, it's controlled by state;
  // When it's mobile view, chat is handled in /chat
  const isChatOpen =
    !isMobileView &&
    hasChatUserId &&
    (shouldCollapseSideChat ? chatOpen : true);

  const closeChatSession = useCallback(() => {
    navigate(linkWihoutChat, { replace: true });
  }, [linkWihoutChat, navigate]);

  const generateChatLink = useCallback(
    (chatSession: ChatSession) => {
      const chatParticipant = chatSession.participants?.find(
        ({ user }) => user?.id !== currentUser?.id,
      );
      const { id: chatUserId } = chatParticipant?.user || {};

      return createChatLink(chatUserId);
    },
    [createChatLink, currentUser?.id],
  );

  const handleOpenStateChange = useCallback(
    (newOpenState: AsideOpenState) => {
      if (newOpenState !== 'closed') return;

      skipNotificationsForSession(null);
    },
    [skipNotificationsForSession],
  );

  return (
    <div className={classes}>
      <SideWrapper
        open={chatOpen}
        toggleOpen={setChatOpen}
        openDirection="to-left"
        closeIcon={<GoRightIcon />}
        handleOpenStateChange={handleOpenStateChange}
        openIcon={
          <>
            <MessageSquareIcon />
            {totalUnreadNotifications ? (
              <NotificationBadge
                count={totalUnreadNotifications}
                onlyDotOnMobile={false}
                className="anys-side-chat__notif-badge"
              />
            ) : null}
          </>
        }
      >
        <div className="anys-side-chat__header">{t('General.chat')}</div>

        {isChatOpen ? (
          <Chat
            key={chatUserId}
            chatUserId={+chatUserId}
            handleCloseChat={closeChatSession}
          />
        ) : (
          <ChatList
            generateChatLink={generateChatLink}
            className="anys-side-chat__chat-list"
          />
        )}
      </SideWrapper>
    </div>
  );
};

export default SideChat;
