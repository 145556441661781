import React from 'react';
import classNames from 'classnames';
import TimesCircleIcon from 'icons/TimesCircle.icon';
import FileToImage from 'components/FileToImage';

import './ChatPhotoPreview.styles.scss';

type ChatPhotoPreviewProps = {
  className?: string;
  photos: File[];
  onRemoveFile: (file: File) => void;
};

const ChatPhotoPreview: React.FC<ChatPhotoPreviewProps> = (props) => {
  const { className, photos, onRemoveFile } = props;

  const classes = classNames('anys-chat-photo-preview', className);

  return (
    photos?.length > 0 && (
      <ul className={classes}>
        {photos.map((photo, i) => (
          <li key={i} className="anys-chat-photo-preview__wrapper">
            <FileToImage
              file={photo}
              className="anys-chat-photo-preview__wrapper__photo"
            />

            <button
              className="anys-chat-photo-preview__wrapper__remove"
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                onRemoveFile(photo);
              }}
            >
              <TimesCircleIcon gradient={false} />
            </button>
          </li>
        ))}
      </ul>
    )
  );
};

export default ChatPhotoPreview;
