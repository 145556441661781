import { UserAvailability } from 'models/Availability';
import httpClient from '../httpClient';

const createAvailability = (userId: number, data: any) =>
  httpClient.post(`users/${userId}/availability`, data);

const getAvailability = (userId: number) =>
  httpClient.get<UserAvailability>(`/users/${userId}/availability`);

const updateAvailabilityTimeoffs = (
  userId: number,
  availabilityId: number,
  data: any,
) =>
  httpClient.put(
    `users/${userId}/availability/${availabilityId}/timeoffs`,
    data,
  );

const updateAvailability = (
  userId: number,
  availabilityId: number,
  data: any,
) => httpClient.put(`users/${userId}/availability/${availabilityId}`, data);

export default {
  createAvailability,
  getAvailability,
  updateAvailabilityTimeoffs,
  updateAvailability,
};
