import React, { useCallback } from 'react';
import classNames from 'classnames';
import { ChangesReturn } from 'utils/job-changes';
import { useTranslation } from 'react-i18next';

import './JobChanges.styles.scss';

export type Value = string | string[] | JSX.Element | JSX.Element[];

type JobChangesProps = {
  className?: string;
  valueParser?: (value: Value) => Value;
} & (
  | ChangesReturn
  | ChangesReturn<string[] | string>
  | ChangesReturn<JSX.Element[] | string>
  | ChangesReturn<JSX.Element>
);

const JobChanges: React.FC<JobChangesProps> = (props) => {
  const { className, changedFrom, changedTo, valueParser } = props;

  const { t } = useTranslation();

  const classes = classNames('anys-job-changes', className);
  const fromMsg = t('General.from');
  const toMsg = t('General.changedTo');

  const parseMaybe = useCallback(
    <T extends Value>(value: T) => {
      if (!valueParser) return value;

      return valueParser(value);
    },
    [valueParser],
  );

  return (
    <ul className={classes}>
      <li className="anys-job-changes__label">{fromMsg}</li>
      {changedFrom?.map((change, i) => {
        const isString = typeof change === 'string';

        if (isString || !Array.isArray(change)) {
          return <li key={`change-from-${i}`}>{parseMaybe(change)}</li>;
        }

        return change.map((subChange, j) => (
          <li key={`change-from-sub-${j}`}>{parseMaybe(subChange)}</li>
        ));
      })}
      <li
        className={classNames(
          'anys-job-changes__label',
          'anys-job-changes__label--to',
        )}
      >
        {toMsg}
      </li>
      {changedTo?.map((change, i) => {
        const isString = typeof change === 'string';

        if (isString || !Array.isArray(change)) {
          return <li key={`change-to-${i}`}>{parseMaybe(change)}</li>;
        }

        return change.map((subChange, j) => (
          <li key={`change-to-sub-${j}`}>{parseMaybe(subChange)}</li>
        ));
      })}
    </ul>
  );
};

export default JobChanges;
