import React, { useId } from 'react';

type CurrentLocationIconProps = React.SVGProps<SVGSVGElement>;

const CurrentLocationIcon: React.FC<CurrentLocationIconProps> = (props) => {
  const gradient1 = useId();
  const gradient2 = useId();

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.6667 8.00008C10.6667 9.47284 9.47276 10.6667 8 10.6667C6.52724 10.6667 5.33333 9.47284 5.33333 8.00008C5.33333 6.52732 6.52724 5.33341 8 5.33341C9.47276 5.33341 10.6667 6.52732 10.6667 8.00008Z"
        fill={`url(#${gradient1})`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.66666 1.33341C8.66666 0.965225 8.36819 0.666748 8 0.666748C7.63181 0.666748 7.33333 0.965225 7.33333 1.33341V2.0367C4.55225 2.34413 2.34405 4.55234 2.03662 7.33341H1.33333C0.965141 7.33341 0.666664 7.63189 0.666664 8.00008C0.666664 8.36827 0.965141 8.66675 1.33333 8.66675H2.03662C2.34405 11.4478 4.55225 13.656 7.33333 13.9635V14.6667C7.33333 15.0349 7.63181 15.3334 8 15.3334C8.36819 15.3334 8.66666 15.0349 8.66666 14.6667V13.9635C11.4477 13.656 13.6559 11.4478 13.9634 8.66675H14.6667C15.0349 8.66675 15.3333 8.36827 15.3333 8.00008C15.3333 7.63189 15.0349 7.33341 14.6667 7.33341H13.9634C13.6559 4.55234 11.4477 2.34413 8.66666 2.0367V1.33341ZM12.6667 8.00008C12.6667 5.42275 10.5773 3.33341 8 3.33341C5.42267 3.33341 3.33333 5.42275 3.33333 8.00008C3.33333 10.5774 5.42267 12.6667 8 12.6667C10.5773 12.6667 12.6667 10.5774 12.6667 8.00008Z"
        fill={`url(#${gradient2})`}
      />
      <defs>
        <linearGradient
          id={gradient1}
          x1="0.666664"
          y1="8.00008"
          x2="15.3333"
          y2="8.00008"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF7BAC" />
          <stop offset="1" stopColor="#D142F4" />
        </linearGradient>
        <linearGradient
          id={gradient2}
          x1="0.666664"
          y1="8.00008"
          x2="15.3333"
          y2="8.00008"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF7BAC" />
          <stop offset="1" stopColor="#D142F4" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default CurrentLocationIcon;
