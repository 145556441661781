import React, { useContext, useMemo } from 'react';
import JobPostForm from './components/JobPostForm';
import { JobPost } from 'models/Job';
import { useJobPost } from '../JobPost/JobPost.page';
import { JOB_PRICING_TYPE } from 'constants/job';
import { parseJobPostToFormValues } from '../JobPost/utils';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type CreateEditJobPostProps = {
  type: 'create' | 'edit';
};

const CreateEditJobPost: React.FC<CreateEditJobPostProps> = (props) => {
  const { type } = props;

  const { t } = useTranslation();
  const location = useLocation();

  const { currentUser } = useContext(CurrentUserContext);

  const { fetchingJobPost, isOwnJob, jobPost, isSavingJobPost, onSubmitForm } =
    useJobPost();

  const isEdit = type === 'edit';
  const isCreate = type === 'create';

  const initialJobPost = useMemo(
    () =>
      ({
        type: location.pathname?.includes('/need') ? 'Need' : 'Provide',
        timeAndPricing: {
          type: JOB_PRICING_TYPE.BY_PROJECT,
          startDate: null,
          endDate: null,
          additionalExplanation: '',
          price: null,
          currency: 'USD',
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        typeOfService: {
          type: 'Online',
        },
        isNegotiable: 'true',
        hasFreeCancelation: 'true',
        isLockedTimeAndPricing: false,
        isLockedTypeOfService: false,
        isLockedFreeCancelation: false,
        isLockedDeadlinePolicy: false,
        isLockedArbitration: false,
        hasDeadlinePolicy: 'false',
        sendToTimeline: false,
        hasArbitration: 'true',
        arbitrationOptions: [
          {
            arbitratorsFee: 15,
            arbitratorsNumber: 3,
            optionPriority: 0,
            arbitratorsFilterQuery: null,
            filterQueryFormValues: undefined,
            arbitrators: null,
            arbitratorsType: 'Random',
          },
        ],
      }) as Partial<JobPost<'form'>>,
    [location.pathname],
  );

  const initialValues: Partial<JobPost<'form'>> = useMemo(
    () =>
      isEdit
        ? parseJobPostToFormValues(jobPost) || initialJobPost
        : initialJobPost,
    [initialJobPost, isEdit, jobPost],
  );

  const isProvideJob = initialValues.type === 'Provide';

  const { client, provider } = useMemo(() => {
    if (!jobPost) {
      return {
        client: isProvideJob ? null : currentUser,
        provider: isProvideJob ? currentUser : null,
      };
    }

    if (isProvideJob) {
      return {
        client: isOwnJob ? null : currentUser,
        provider: jobPost.user,
      };
    }

    return {
      client: jobPost.user,
      provider: isOwnJob ? null : currentUser,
    };
  }, [currentUser, isOwnJob, isProvideJob, jobPost]);

  const isDraft = isCreate || jobPost?.state === 'Draft';

  return (isEdit && jobPost) || isCreate ? (
    <JobPostForm
      initialValues={initialValues}
      onSubmit={onSubmitForm}
      inProgress={isSavingJobPost}
      isOwnJob={isOwnJob}
      client={client}
      provider={provider}
      isDraft={isDraft}
      versionState={null}
    />
  ) : fetchingJobPost ? (
    <div className="card">{t('General.loadingDetails')}</div>
  ) : null;
};

export default CreateEditJobPost;
