import useMatchMedia from 'hooks/useMatchMedia';
import React, { useEffect, useState } from 'react';
import ThemeContext, { ThemeType } from './Theme.context';

// const THEME_STORAGE_KEY = 'anyservice-theme';

type ThemeProps = {
  children: React.ReactNode;
};

const Theme: React.FC<ThemeProps> = (props) => {
  const { children } = props;

  const prefersDark = useMatchMedia('prefersDark');
  const [theme, setTheme] = useState<ThemeType>('dark');

  useEffect(() => {
    setTheme(prefersDark ? 'dark' : 'light');
  }, [prefersDark]);

  //uncoment this when dark theme is ready
  // useEffect(() => {
  //   const defaultDark = window.matchMedia(
  //     '(prefers-color-scheme: dark)',
  //   ).matches;

  //   const savedThemeLocal =
  //     storageService.getItem<ThemeType>(THEME_STORAGE_KEY);

  //   if (savedThemeLocal) {
  //     setTheme(savedThemeLocal);
  //   } else if (defaultDark) {
  //     setTheme('dark');
  //   }
  // }, []);

  // useEffect(() => {
  //   storageService.setItem(THEME_STORAGE_KEY, theme);

  //   // Because there are only two themes,
  //   // we will only handle the 'dark' theme,
  //   // and the default will be 'light'.
  //   if (theme === 'dark') {
  //     document.body.classList.add(theme);
  //   } else {
  //     document.body.classList.remove('dark');
  //   }
  // }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export default Theme;
