import React from 'react';

type SaveProps = React.SVGProps<SVGSVGElement>;

const Save: React.FC<SaveProps> = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 2.50016C2.5 1.57969 3.24619 0.833496 4.16667 0.833496H15.8333C16.7538 0.833496 17.5 1.5797 17.5 2.50016V18.3335C17.5 18.6243 17.3484 18.8941 17.1 19.0453C16.8515 19.1965 16.5423 19.2073 16.2839 19.0737L10 15.8247L3.71607 19.0737C3.45774 19.2073 3.14845 19.1965 2.90003 19.0453C2.65162 18.8941 2.5 18.6243 2.5 18.3335V2.50016ZM15.8333 2.50016H4.16667V16.9645L9.61726 14.1463C9.85733 14.0222 10.1427 14.0222 10.3827 14.1463L15.8333 16.9645V2.50016Z"
      />
    </svg>
  );
};

export default Save;
