import React from 'react';
import classNames from 'classnames';

import './DataPiecePreview.styles.scss';

type DataPiecePreviewProps = {
  className?: string;
  label?: React.ReactNode;
  value?: React.ReactNode;
  valueAs?: 'span' | 'div';
};

const DataPiecePreview: React.FC<DataPiecePreviewProps> = (props) => {
  const { className, label, value, valueAs = 'span' } = props;

  const classes = classNames('anys-data-piece-preview', className);

  const ValueEl = valueAs;

  return (
    <div className={classes}>
      {label && <div className="anys-data-piece-preview__label">{label}</div>}
      {typeof value !== 'undefined' ? (
        <ValueEl className="anys-data-piece-preview__value">{value}</ValueEl>
      ) : null}
    </div>
  );
};

export default DataPiecePreview;
