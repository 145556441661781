import classNames from 'classnames';
import React from 'react';
import { RenderLeafProps } from 'slate-react';

type EditorLeafProps = RenderLeafProps;

const EditorLeaf: React.FC<EditorLeafProps> = (props) => {
  const { children, attributes, leaf } = props;

  const classes = classNames({
    'anys-rich-text-editor__text--bold': leaf.bold,
    'anys-rich-text-editor__text--italic': leaf.italic,
    'anys-rich-text-editor__text--line-through': leaf['line-through'],
    'anys-rich-text-editor__text--underline': leaf.underline,
  });

  return (
    <span
      {...attributes}
      className={classes}
      style={{ color: leaf.color, backgroundColor: leaf.backgroundColor }}
    >
      {children}
    </span>
  );
};

export default EditorLeaf;
