import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import { t } from 'i18next';
import { Button } from 'ncoded-component-library';
import PlusCircleStrokeGradientIcon from 'icons/PlusCircleStrokeGradient.icon';
import TimesCircleIcon from 'icons/TimesCircle.icon';
import ResponsiveImage from 'components/ResponsiveImage';
import { Picture } from 'models/User';
import ImageInput from 'components/ImageInput';
import useCallbackRef from 'hooks/useCallbackRef';
import { ModalRef } from 'components/Modal';
import ImagesPreviewModal from 'components/ImagesPreviewModal';

import './Gallery.styles.scss';

export type PreviewGallery = {
  id: string;
  previewUrl: string;
  file: File;
};

type GalleryProps = {
  className?: string;
  edit?: boolean;
  images?: (Picture | PreviewGallery)[];
  onAddImage?: (files: File[]) => void;
  onRemoveImage?: (el: Picture | PreviewGallery) => void;
};

export const isPicture = (image: PreviewGallery | Picture): image is Picture =>
  !!(image as Picture).url;

const Gallery: React.FC<GalleryProps> = (props) => {
  const {
    className,
    edit = false,
    images = [],
    onAddImage,
    onRemoveImage,
  } = props;

  const [galleryModal, galleryModalRef] = useCallbackRef<ModalRef>(null);

  const [activeIndex, setActiveIndex] = useState<number>(0);

  const classes = classNames('anys-gallery', { 'edit-mode': edit }, className);

  const sizes = '(min-width: 768px) (min-width:289px), (min-width:191px)';

  const renderGallery = useMemo(
    () =>
      images &&
      images.slice(0, edit ? 7 : 8).map((el, index) => {
        const imgProps = isPicture(el)
          ? {
              key: el.id,
              src: el.url,
              alt: el.name,
              resizedVariants: el.resizedUrls,
            }
          : { key: el.id, src: el.previewUrl, alt: el.previewUrl };
        return (
          <div className="anys-gallery__pictures__picture" key={imgProps.key}>
            <ResponsiveImage
              {...imgProps}
              sizes={sizes}
              resizedVariants={imgProps.resizedVariants}
              onClick={() => {
                galleryModal.open();
                setActiveIndex(index);
              }}
            />
            {edit && (
              <Button
                type="button"
                variant="icon"
                className="anys-gallery__pictures__picture__button"
                onClick={() => onRemoveImage(el)}
              >
                <TimesCircleIcon />
              </Button>
            )}
          </div>
        );
      }),
    [edit, galleryModal, images, onRemoveImage],
  );

  const gallery = useMemo(
    () =>
      images.map((el) => {
        if (isPicture(el)) return el;

        return {
          id: +el.id,
          url: el.previewUrl,
          name: el.previewUrl,
          resizedUrls: null,
        };
      }),
    [images],
  );

  return (
    <div className={classes}>
      <div className="anys-gallery__pictures">
        {edit && (
          <ImageInput
            trigger={
              <Button
                type="button"
                variant="solid"
                className="anys-gallery__pictures__add"
              >
                <PlusCircleStrokeGradientIcon />
                <label>{t('General.addPhoto')}</label>
              </Button>
            }
            onUploadImage={(image, file) => {
              onAddImage([file]);
            }}
          />
        )}
        {renderGallery}
      </div>

      <ImagesPreviewModal
        modalRef={galleryModalRef}
        files={gallery}
        activeIndex={activeIndex}
      />
    </div>
  );
};

export default Gallery;
