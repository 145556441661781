import React, { useCallback } from 'react';
import classNames from 'classnames';
import LeftArrow from 'icons/LeftArrow.icon';
import { useNavigate } from 'react-router-dom';

import './BackButton.styles.scss';

export type BackButtonProps = {
  className?: string;
  withBorder?: boolean;
  prevPath?: string | -1;
  icon?: React.ReactNode;
  onBackClick?: (prevPath: string | -1) => void;
};

const BackButton: React.FC<BackButtonProps> = (props) => {
  const {
    className,
    withBorder,
    prevPath,
    icon = <LeftArrow />,
    onBackClick,
  } = props;

  const navigate = useNavigate();

  const classes = classNames(
    'anys-back-button',
    {
      'anys-back-button--with-border': withBorder,
    },
    className,
  );

  const handleBackClick = useCallback(() => {
    if (onBackClick) {
      onBackClick(prevPath);

      return;
    }

    if (
      window.opener?.origin === window.origin &&
      window.history.state?.idx === 0
    ) {
      window.close();
    } else {
      navigate(-1);
    }
  }, [navigate, onBackClick, prevPath]);

  return (
    <div className={classes}>
      <button type="button" onClick={handleBackClick}>
        {icon}
      </button>
    </div>
  );
};

export default BackButton;
