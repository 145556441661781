import Dropzone from 'components/Dropzone';
import Modal, { ModalRef } from 'components/Modal';
import { Button, Loader } from 'ncoded-component-library';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import './VerifyBusinessModal.styles.scss';

type UploadedFile = {
  id: number;
  name: string;
};

type VerifyBusinessModalProps = {
  modalRef: React.Ref<ModalRef>;
  closeModal: () => void;
};

const VerifyBusinessModal: React.FC<VerifyBusinessModalProps> = (props) => {
  const { modalRef, closeModal } = props;

  const { t } = useTranslation();
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const [removingFileId, setRemovingFileId] = useState(null);
  const [isUploadingFiles, setIsUploadingFiles] = useState(false);

  const {
    loading,
    currentUser,
    uploadUserFile,
    removeUserFile,
    setCurrentUser,
    getUser,
  } = useContext(CurrentUserContext);

  const { businessDocs } = currentUser || {};

  const shownFiles: (File | UploadedFile)[] = [
    ...filesToUpload,
    ...(businessDocs || []).map((doc) => ({ id: doc.id, name: doc.name })),
  ];

  const handleRemoveUploadedFile = useCallback(
    async (fileId: number) => {
      setRemovingFileId(fileId);

      await removeUserFile(fileId, false);

      setCurrentUser((old) => ({
        ...old,
        businessDocs: old.businessDocs.filter((doc) => doc.id !== fileId),
      }));

      setRemovingFileId(null);
    },
    [removeUserFile, setCurrentUser],
  );

  const onRemoveFile = useCallback(
    (file: File | UploadedFile, index: number) => {
      if ('id' in file) {
        handleRemoveUploadedFile(file.id);
      } else {
        setFilesToUpload((old) =>
          old.filter(
            (oldFile, oldIndex) =>
              oldIndex !== index && oldFile.name !== file.name,
          ),
        );
      }
    },
    [handleRemoveUploadedFile],
  );

  const onUpload = useCallback(async () => {
    if (!filesToUpload.length || isUploadingFiles) {
      closeModal();

      return;
    }

    setIsUploadingFiles(true);

    await uploadUserFile('business', filesToUpload, false);

    setIsUploadingFiles(false);

    closeModal();
  }, [closeModal, filesToUpload, isUploadingFiles, uploadUserFile]);

  return (
    <Modal
      type="action"
      modalName="verify-business"
      ref={modalRef}
      title={t('General.verifyBusiness')}
      isFullscreen
      primaryActionContent={
        isUploadingFiles ? <Loader inline overlay={false} /> : t('General.done')
      }
      cancelActionContent={t('General.cancel')}
      onPrimaryAction={onUpload}
      className={classNames('anys-verify-business-modal')}
      onClose={() => setFilesToUpload([])}
      onOpen={() => {
        getUser();
      }}
    >
      <p>
        {t('General.verifyBusinessNote1')}
        <br />
        {t('General.verifyBusinessNote2')}
      </p>

      {loading ? (
        <Loader />
      ) : (
        <>
          <Dropzone
            name="files"
            maxSizeInMB={20}
            handleFiles={setFilesToUpload}
            multiple
            accept={{
              'image/png': ['.png'],
              'image/jpg': ['.jpg'],
              'image/jpeg': ['.jpeg'],
              'application/pdf': ['.pdf'],
            }}
          />

          {shownFiles?.length ? (
            <ul className="anys-verify-business-modal__files">
              {shownFiles.map((file, i) => {
                const isRemoving = 'id' in file && removingFileId === file.id;

                return (
                  <li
                    key={file.name + i}
                    className="anys-verify-business-modal__files__file"
                  >
                    <span className="anys-verify-business-modal__files__file__name">
                      {file.name}
                    </span>
                    <div className="anys-verify-business-modal__files__file__remove">
                      {isRemoving ? (
                        <Loader inline overlay={false} />
                      ) : (
                        <Button
                          type="button"
                          variant="link"
                          styleType="secondary"
                          onClick={() => onRemoveFile(file, i)}
                        >
                          <span>{t('General.remove')}</span>
                        </Button>
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>
          ) : null}
          {businessDocs?.length ? (
            <p className="anys-verify-business-modal__documents-sent">
              {t('General.verifyBusinessAlreadyUploaded')}
            </p>
          ) : null}
        </>
      )}
    </Modal>
  );
};

export default VerifyBusinessModal;
