import React from 'react';
import classNames from 'classnames';

import './NotificationBadge.styles.scss';
import './NotificationBadge.styles.responsive.scss';

type NotificationBadgeProps = {
  className?: string;
  count: number;
  countLimit?: number;
  onlyDotOnMobile?: boolean;
};

const NotificationBadge: React.FC<NotificationBadgeProps> = (props) => {
  const { className, count, countLimit = 9, onlyDotOnMobile = true } = props;

  const classes = classNames(
    'anys-notification-badge',
    {
      'anys-notification-badge--dot-on-mobile': onlyDotOnMobile,
    },
    className,
  );

  return (
    <span className={classes}>
      {count > countLimit ? `${countLimit}+` : count}
    </span>
  );
};

export default NotificationBadge;
