import { ChatMessage } from 'models/Chat';
import { createContext } from 'react';

type ChatNotificationsContextProps = {
  newMessagesBySessions: Record<number, ChatMessage[]>;
  totalUnreadNotifications: number;
  markSessionAsRead: (
    chatSessionId: number,
    unreadMsgCount: number,
  ) => Promise<void>;
  skipNotificationsForSession: (chatSessionId: number) => void;
};

export default createContext<Partial<ChatNotificationsContextProps>>({});
