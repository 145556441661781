import classNames from 'classnames';
import React from 'react';

type LocationProps = React.SVGProps<SVGSVGElement>;

const Location: React.FC<LocationProps> = (props) => {
  const { className, ...restOfProps } = props;

  return (
    <svg
      width="10"
      height="14"
      viewBox="0 0 10 14"
      fill="none"
      className={classNames('location-icon', className)}
      {...restOfProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.041687 5.54159C0.041687 2.80317 2.2616 0.583252 5.00002 0.583252C7.73844 0.583252 9.95835 2.80317 9.95835 5.54159C9.95835 7.64414 8.75691 9.59994 7.64063 10.9767C7.07353 11.6761 6.50784 12.2511 6.08415 12.6512C5.87193 12.8516 5.6944 13.0091 5.56885 13.1173C5.50605 13.1714 5.45619 13.2133 5.42142 13.2421C5.40403 13.2565 5.39041 13.2677 5.38082 13.2755L5.3695 13.2847L5.36617 13.2874L5.3651 13.2882C5.3651 13.2882 5.36443 13.2888 5.00002 12.8333L4.63533 13.2885L4.63387 13.2874L4.63054 13.2847L4.61922 13.2755C4.60964 13.2677 4.59601 13.2565 4.57863 13.2421C4.54385 13.2133 4.49399 13.1714 4.43119 13.1173C4.30564 13.0091 4.12811 12.8516 3.9159 12.6512C3.4922 12.2511 2.92651 11.6761 2.35941 10.9767C1.24313 9.59994 0.041687 7.64414 0.041687 5.54159ZM2.66669 5.54159C2.66669 4.25292 3.71136 3.20825 5.00002 3.20825C6.28868 3.20825 7.33335 4.25292 7.33335 5.54159C7.33335 6.83025 6.28868 7.87492 5.00002 7.87492C3.71136 7.87492 2.66669 6.83025 2.66669 5.54159Z"
        fill="url(#paint0_linear_4657_53026)"
      />
      <path
        d="M4.63533 13.2885C4.84837 13.459 5.15138 13.4592 5.36443 13.2888L5.00002 12.8333C4.63562 13.2888 4.63533 13.2885 4.63533 13.2885Z"
        fill="url(#paint1_linear_4657_53026)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4657_53026"
          x1="0.041687"
          y1="6.99986"
          x2="9.95835"
          y2="6.99986"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF7BAC" />
          <stop offset="1" stopColor="#D142F4" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4657_53026"
          x1="0.041687"
          y1="6.99986"
          x2="9.95835"
          y2="6.99986"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF7BAC" />
          <stop offset="1" stopColor="#D142F4" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Location;
