import React, { useCallback, useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import api from 'api';
import OverlaySpinner from 'components/OverlaySpinner';
import { AxiosError } from 'axios';
import NavBar from 'components/NavBar';
import { Contract } from 'models/Contract';
import MainTemplate from 'components/templates/MainTemplate';
import useHeaderStyles from 'hooks/useHeaderStyles';
import useHeaderContent from 'hooks/useHeaderContent';
import { getTranslationForJobState, isFile } from 'utils/job';
import { getEntityInboxState } from 'router/subrouters/Inbox/utils';
import CustomLink from 'components/CustomLink';
import NotificationBadge from 'components/NotificationBadge';
import ChatNotificationsContext from 'providers/ChatNotifications/ChatNotifications.context';
import { JobPost } from 'models/Job';
import showToast from 'modules/showToast';
import { parseJobPostFormValuesToContractRequest } from '../../utils';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import useInboxLink from 'router/subrouters/Inbox/hooks/useInboxLink';
import { FormApi } from 'final-form';
import useEntityUpdateListener from 'hooks/useEntityUpdateListener';

import './Contract.styles.responsive.scss';
import UserScheduleProvider from 'components/JobPostPreview/components/UserScheduleModal/UserScheduleProvider';

type JobProps = {
  className?: string;
  asideLeft?: React.ReactNode;
  asideRight?: React.ReactNode;
  chatUserId?: number;
};

export type ContractContext = {
  contract?: Contract;
  fetchingContract: boolean;
  savingContract: boolean;
  confirmOrRejectInProgress: boolean;
  confirmChanges: () => Promise<void>;
  rejectChanges: () => Promise<void>;
  setContract: React.Dispatch<React.SetStateAction<Contract>>;
  getContract: (contractId: number) => Promise<void>;
  updateContract: (
    values: JobPost<'form'>,
    formApi: FormApi<JobPost<'form'>>,
  ) => Promise<void>;
};

const ContractComponent: React.FC<JobProps> = (props) => {
  const { className, asideLeft, asideRight, chatUserId } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const { id } = useParams<{ id: string }>();
  const [fetchingContract, setFetchingContract] = useState(false);
  const [savingContract, setSavingContract] = useState(false);
  const [confirmOrRejectInProgress, setConfirmOrRejectInProgress] =
    useState(false);
  const [contract, setContract] = useState<Contract>();
  const { t } = useTranslation();

  const { totalUnreadNotifications } = useContext(ChatNotificationsContext);
  const { currentUser } = useContext(CurrentUserContext);

  const { createEntityLink } = useInboxLink();

  const classes = classNames('anys-contract', className);

  const isInbox = location.pathname.startsWith('/inbox');

  const isOwnContract =
    contract?.type === 'Provide'
      ? currentUser?.id === contract?.provider?.id
      : currentUser?.id === contract?.client?.id;

  useEntityUpdateListener();

  useHeaderStyles({ showBackButton: true }, [location.pathname], true);

  useHeaderContent(
    <div className="mobile-header-content">
      {getTranslationForJobState(t, getEntityInboxState(contract, 'contract'))}

      <CustomLink
        to={`/chat/${chatUserId}`}
        variant="solid"
        className="mobile-header-content__cta"
      >
        {t('General.chat')}
        {totalUnreadNotifications ? (
          <NotificationBadge count={totalUnreadNotifications} />
        ) : null}
      </CustomLink>
    </div>,
    [!!contract, isInbox, totalUnreadNotifications],
    undefined,
    !!contract && isInbox,
  );

  const getContract = useCallback(
    async (contractId: number) => {
      OverlaySpinner.show('.anys-contract');

      setFetchingContract(true);

      try {
        const {
          data: { contract },
        } = await api.contract.getContract(contractId);

        setContract(contract);
      } catch (error) {
        console.error(error);

        if ((error as AxiosError)?.response?.status === 404) {
          navigate('/not-found', { replace: true });
        } else {
          showToast('error', t('General.error'));
        }
      } finally {
        OverlaySpinner.hide('.anys-contract');
        setFetchingContract(false);
      }
    },
    [navigate, t],
  );

  const updateContract = useCallback(
    async (values: JobPost<'form'>, formApi: FormApi<JobPost<'form'>>) => {
      setSavingContract(true);
      OverlaySpinner.show('.anys-contract');

      const { pristine } = formApi.getState();

      try {
        if (!pristine) {
          const { attachments, attachmentIdsToDelete, ...rest } = values;

          const {
            data: { contract: respContract },
          } = await api.contract.requestContractChange(
            contract.commonId,
            contract.version,
            parseJobPostFormValuesToContractRequest(rest, isOwnContract),
          );

          const onlyFiles = attachments.filter(isFile);

          if (onlyFiles.length) {
            const res = await api.contract.uploadFiles(
              respContract.commonId,
              respContract.version,
              onlyFiles,
            );

            respContract.attachments = res.data.contract.attachments;
          }

          if (attachmentIdsToDelete?.length) {
            await Promise.all(
              attachmentIdsToDelete.map((deleteId) =>
                api.contract.deleteFile(
                  respContract.commonId,
                  respContract.version,
                  deleteId,
                ),
              ),
            );
          }

          setContract({
            ...respContract,
            attachments: respContract.attachments?.filter(
              (file) => !attachmentIdsToDelete?.includes(file.id),
            ),
          });
        }

        navigate(
          isInbox
            ? createEntityLink('view', 'contract', contract.commonId)
            : `/contract/${contract.commonId}`,
        );
      } catch (error) {
        console.error(error);
        showToast('error', t('General.error'));
      } finally {
        setSavingContract(false);
        OverlaySpinner.hide('.anys-contract');
      }
    },
    [contract, createEntityLink, isInbox, isOwnContract, navigate, t],
  );

  const confirmChanges = useCallback(async () => {
    try {
      setConfirmOrRejectInProgress(true);

      const { data } = await api.contract.confirmContractChange(
        contract.commonId,
        contract.version,
      );

      setContract(data.contract);
    } catch (error) {
      showToast('error', t('General.error'));
    } finally {
      setConfirmOrRejectInProgress(false);
    }
  }, [contract, t]);

  const rejectChanges = useCallback(async () => {
    try {
      setConfirmOrRejectInProgress(true);

      const { data } = await api.contract.rejectContractChange(
        contract.commonId,
        contract.version,
      );

      setContract(data.contract);
    } catch (error) {
      showToast('error', t('General.error'));
    } finally {
      setConfirmOrRejectInProgress(false);
    }
  }, [contract, t]);

  useEffect(() => {
    if (+id) {
      getContract(+id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const context = {
    contract,
    fetchingContract,
    savingContract,
    confirmOrRejectInProgress,
    confirmChanges,
    rejectChanges,
    setContract,
    updateContract,
    getContract,
  };

  return (
    <UserScheduleProvider>
      {isInbox ? (
        <Outlet context={context} />
      ) : (
        <>
          <NavBar className="anys-contract__navbar" />
          <MainTemplate
            className={classes}
            asideLeft={asideLeft}
            asideRight={asideRight}
          >
            <Outlet context={context} />
          </MainTemplate>
        </>
      )}
    </UserScheduleProvider>
  );
};

export default ContractComponent;

export const useContract = () => useOutletContext<ContractContext>();
