import React from 'react';

const NoContractSelctedIcon = () => {
  return (
    <svg
      width="65"
      height="65"
      viewBox="0 0 65 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="14.5"
        y="10.5"
        width="36"
        height="45"
        rx="3"
        fill="#DEDEDE"
        stroke="#95989B"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <mask
        id="mask0_10617_150906"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="13"
        y="9"
        width="39"
        height="48"
      >
        <rect
          x="14.5"
          y="10.5"
          width="36"
          height="45"
          rx="3"
          fill="white"
          stroke="#95989B"
          strokeWidth="2"
          strokeLinejoin="round"
        />
      </mask>
      <g mask="url(#mask0_10617_150906)">
        <rect x="11.5" y="10.5" width="36" height="45" rx="3" fill="white" />
      </g>
      <rect
        x="14.5"
        y="10.5"
        width="36"
        height="45"
        rx="3"
        stroke="#95989B"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <line
        x1="20.5"
        y1="16.5"
        x2="44.5"
        y2="16.5"
        stroke="#95989B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="20.5"
        y1="22"
        x2="44.5"
        y2="22"
        stroke="#95989B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="20.5"
        y1="27.5"
        x2="44.5"
        y2="27.5"
        stroke="#95989B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="20.5"
        y1="33"
        x2="40.5"
        y2="33"
        stroke="#95989B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="20.5"
        y1="38.5"
        x2="44.5"
        y2="38.5"
        stroke="#95989B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="20.5"
        y1="44"
        x2="27.5"
        y2="44"
        stroke="#95989B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="35.5"
        y1="50.5"
        x2="44.5"
        y2="50.5"
        stroke="#95989B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="37.5"
        y="1.5"
        width="26"
        height="26"
        rx="13"
        fill="url(#paint0_linear_10617_150906)"
      />
      <path
        d="M47.8333 12.7084C47.8333 11.2357 49.0272 10.0417 50.5 10.0417C51.9727 10.0417 53.1667 11.2357 53.1667 12.7084C53.1667 13.951 52.3168 14.995 51.1667 15.2911V16.0417C51.1667 16.4099 50.8682 16.7084 50.5 16.7084C50.1318 16.7084 49.8333 16.4099 49.8333 16.0417V14.7084C49.8333 14.3402 50.1318 14.0417 50.5 14.0417C51.2364 14.0417 51.8333 13.4448 51.8333 12.7084C51.8333 11.972 51.2364 11.3751 50.5 11.3751C49.7636 11.3751 49.1667 11.972 49.1667 12.7084C49.1667 13.0766 48.8682 13.3751 48.5 13.3751C48.1318 13.3751 47.8333 13.0766 47.8333 12.7084Z"
        fill="white"
      />
      <path
        d="M51.3333 18.2084C51.3333 18.6686 50.9602 19.0417 50.5 19.0417C50.0398 19.0417 49.6667 18.6686 49.6667 18.2084C49.6667 17.7482 50.0398 17.3751 50.5 17.3751C50.9602 17.3751 51.3333 17.7482 51.3333 18.2084Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.3145 9.31463C46.6409 7.98829 48.4751 7.16675 50.5 7.16675C52.5248 7.16675 54.3591 7.98829 55.6854 9.31463C57.0118 10.641 57.8333 12.4752 57.8333 14.5001C57.8333 16.5249 57.0118 18.3592 55.6854 19.6855C54.3591 21.0119 52.5248 21.8334 50.5 21.8334C48.4751 21.8334 46.6409 21.0119 45.3145 19.6855C43.9882 18.3592 43.1667 16.5249 43.1667 14.5001C43.1667 12.4752 43.9882 10.641 45.3145 9.31463ZM50.5 8.50008C48.843 8.50008 47.3439 9.17092 46.2573 10.2574C45.1708 11.344 44.5 12.8431 44.5 14.5001C44.5 16.1571 45.1708 17.6562 46.2573 18.7427C47.3439 19.8292 48.843 20.5001 50.5 20.5001C52.157 20.5001 53.6561 19.8292 54.7426 18.7427C55.8291 17.6562 56.5 16.1571 56.5 14.5001C56.5 12.8431 55.8291 11.344 54.7426 10.2574C53.6561 9.17092 52.157 8.50008 50.5 8.50008Z"
        fill="white"
      />
      <rect
        x="37.5"
        y="1.5"
        width="26"
        height="26"
        rx="13"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M62.3521 29.8539C54.8232 31.8402 33.4206 46.6244 41.001 58.5006C54.9844 58.6869 50.8171 46.9409 62.3521 29.8539Z"
        fill="#D9D9D9"
        stroke="#95989B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.6905 62.9868C39.6905 62.9868 41.7555 54.7957 48.6479 45.1254C55.5402 35.455 61.4405 30.9393 61.4405 30.9393"
        stroke="#95989B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_10617_150906"
          x1="38.5"
          y1="26.5"
          x2="62.9185"
          y2="26.0663"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A761F5" />
          <stop offset="1" stopColor="#50A8FA" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default NoContractSelctedIcon;