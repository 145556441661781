import React from 'react';
import classNames from 'classnames';
import WarningIcon from 'icons/Warning.icon';

import './Notice.styles.scss';
import './Notice.styles.responsive.scss';

type NoticeProps = {
  className?: string;
  type?: 'error' | 'warn' | 'default';
};

const Notice: React.FC<React.PropsWithChildren<NoticeProps>> = (props) => {
  const { className, children, type = 'default' } = props;

  const classes = classNames(
    'anys-notice',
    {
      [`anys-notice--${type}`]: type,
    },
    className,
  );

  return (
    <div className={classes}>
      {type === 'warn' && <WarningIcon />}
      {children}
    </div>
  );
};

export default Notice;
