import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FilterContext } from 'router/subrouters/Search/pages/Search/providers/Filters/Filters.provider';
import utils from 'utils';
import filtersForm, { FilterReqValue } from 'utils/filtersForm';
import useQueryParams from './useQueryParams';

type FormValue = Record<string, any>;
type FiltersFormProps<T extends FormValue> = {
  defaultValues: T;
  parses?: Record<string, (value: string) => any>;
  transformations?: Record<string, (value: any) => string>;
  dbNames: Record<string, FilterReqValue>;
  onApply?: () => void;
};

export type UseFiltersFormReturn<T extends FormValue> = {
  initialValues: T;
  loading: boolean;
  onSubmit: (values: T) => Promise<void>;
};

const EMPTY_OBJECT = {};

export default function useFiltersForm<T>(
  props: FiltersFormProps<T>,
): UseFiltersFormReturn<T> {
  const {
    defaultValues,
    parses = EMPTY_OBJECT,
    transformations = EMPTY_OBJECT,
    dbNames,
    onApply,
  } = props;

  const { setMultipleQueryParams } = useQueryParams();
  const [loading, setLoading] = useState(true);

  const { filters, checkRegisterFilters } = useContext(FilterContext);

  const initialValues = useMemo(() => {
    const initValues = Object.keys(defaultValues).reduce((acc, key) => {
      const arrKey = `${key}[]`;

      const workingKey = filters[key] ? key : filters[arrKey] ? arrKey : '';

      const value = filters[workingKey]?.value;

      if (!workingKey) return acc;

      return {
        ...acc,
        [key]: filtersForm.transformParamValue(workingKey, value, parses),
      };
    }, {});
    return {
      ...defaultValues,
      ...initValues,
    };
  }, [defaultValues, filters, parses]);

  const onSubmit = useCallback(
    async (values: T) => {
      const finalFilters = filtersForm.transformFilterValues(
        values,
        false,
        transformations,
      );

      setMultipleQueryParams(finalFilters);

      await utils.wait(250);

      onApply?.();
    },
    [onApply, setMultipleQueryParams, transformations],
  );

  useEffect(() => {
    const preparedFilters = filtersForm.prepareFiltersForRegistration(
      defaultValues,
      dbNames,
    );

    checkRegisterFilters(preparedFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTimeout(() => setLoading(false), 500);
  }, [initialValues]);

  return { initialValues, loading, onSubmit };
}
