import React from 'react';

type LongArrowProps = React.SVGProps<SVGSVGElement> & {
  direction: 'left' | 'right';
};

const LongArrow: React.FC<LongArrowProps> = (props) => {
  const { direction, ...restOfProps } = props;

  switch (direction) {
    case 'left':
      return (
        <svg
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...restOfProps}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.00457 5.11712C8.33001 5.44256 8.33001 5.97019 8.00458 6.29563L4.4272 9.87305H17.4154C17.8756 9.87305 18.2487 10.2461 18.2487 10.7064C18.2487 11.1666 17.8756 11.5397 17.4154 11.5397H4.4272L8.00458 15.1171C8.33001 15.4426 8.33001 15.9702 8.00457 16.2956C7.67913 16.6211 7.15149 16.6211 6.82606 16.2956L1.82611 11.2956C1.50067 10.9702 1.50067 10.4426 1.82611 10.1171L6.82606 5.11713C7.15149 4.79169 7.67913 4.79169 8.00457 5.11712Z"
            fill="currentColor"
          />
        </svg>
      );

    default:
      return (
        <svg
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...restOfProps}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.9101 5.11712C12.2356 4.79169 12.7632 4.79169 13.0886 5.11713L18.0886 10.1171C18.414 10.4426 18.414 10.9702 18.0886 11.2956L13.0886 16.2956C12.7632 16.6211 12.2356 16.6211 11.9101 16.2956C11.5847 15.9702 11.5847 15.4426 11.9101 15.1171L15.4875 11.5397H2.49935C2.03911 11.5397 1.66602 11.1666 1.66602 10.7064C1.66602 10.2461 2.03911 9.87305 2.49935 9.87305H15.4875L11.9101 6.29563C11.5847 5.9702 11.5847 5.44256 11.9101 5.11712Z"
            fill="currentColor"
          />
        </svg>
      );
  }
};

export default LongArrow;
