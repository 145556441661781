import React from 'react';
import EmptyStar from '../EmptyStar.icon';
import FilledStar from '../FilledStar.icon';

import './Star.styles.scss';

type StarProps = {
  active?: boolean;
  index?: number;
  value?: number;
  isLast?: boolean;
  size?: number;
  className?: string;
  onMouseOver?: (index: number) => void;
  onClick?: (index?: number) => void;
};

const Star: React.FC<StarProps> = (props) => {
  const { active = false, value = 10, size, onClick, className } = props;

  return (
    <div
      onClick={() => {
        onClick?.();
      }}
      className="anys-star"
    >
      {active ? (
        <FilledStar
          filled={Number(value) * 1.4}
          size={size}
          className={className}
        />
      ) : (
        <EmptyStar size={size} className={className} />
      )}
    </div>
  );
};

export default Star;
