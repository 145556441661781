import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

export default (props: IconProps) => (
  <svg
    width="45"
    height="44"
    viewBox="0 0 45 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M31.4865 15.7668C31.371 14.7721 30.5257 14 29.5 14C28.3954 14 27.5 14.8954 27.5 16L27.5135 17.2332C27.629 18.2279 28.4743 19 29.5 19C30.6046 19 31.5 18.1046 31.5 17L31.4865 15.7668Z"
      fill="url(#paint0_linear_9548_12528)"
    />
    <path
      d="M15.5 14C16.5257 14 17.371 14.7721 17.4865 15.7668L17.5 17C17.5 18.1046 16.6046 19 15.5 19C14.4743 19 13.629 18.2279 13.5135 17.2332L13.5 16C13.5 14.8954 14.3954 14 15.5 14Z"
      fill="url(#paint1_linear_9548_12528)"
    />
    <path
      d="M30.3944 27.2111C29.4064 26.7171 28.2051 27.1176 27.7111 28.1055L27.6341 28.2448C27.4502 28.5523 27.0874 29.0241 26.5462 29.5051C25.4893 30.4447 24.1704 31 22.5 31C20.8295 31 19.5106 30.4447 18.4537 29.5051C17.8223 28.9439 17.4337 28.3952 17.2888 28.1055L17.1725 27.9029C16.6243 27.0649 15.5229 26.7524 14.6055 27.2111C13.6176 27.7051 13.2171 28.9064 13.7111 29.8944C14.0662 30.6047 14.7401 31.556 15.7962 32.4948C17.5518 34.0553 19.7954 35 22.5 35C25.2045 35 27.4481 34.0553 29.2037 32.4948C30.2598 31.556 30.9337 30.6047 31.2888 29.8944C31.7828 28.9064 31.3823 27.7051 30.3944 27.2111Z"
      fill="url(#paint2_linear_9548_12528)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.5 22C0.5 9.84973 10.3497 0 22.5 0C34.6503 0 44.5 9.84973 44.5 22C44.5 34.1503 34.6503 44 22.5 44C10.3497 44 0.5 34.1503 0.5 22ZM40.5 22C40.5 12.0589 32.4411 4 22.5 4C12.5589 4 4.5 12.0589 4.5 22C4.5 31.9411 12.5589 40 22.5 40C32.4411 40 40.5 31.9411 40.5 22Z"
      fill="url(#paint3_linear_9548_12528)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_9548_12528"
        x1="0.5"
        y1="44"
        x2="45.2673"
        y2="43.2049"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A761F5" />
        <stop offset="1" stopColor="#50A8FA" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_9548_12528"
        x1="0.5"
        y1="44"
        x2="45.2673"
        y2="43.2049"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A761F5" />
        <stop offset="1" stopColor="#50A8FA" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_9548_12528"
        x1="0.5"
        y1="44"
        x2="45.2673"
        y2="43.2049"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A761F5" />
        <stop offset="1" stopColor="#50A8FA" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_9548_12528"
        x1="0.5"
        y1="44"
        x2="45.2673"
        y2="43.2049"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A761F5" />
        <stop offset="1" stopColor="#50A8FA" />
      </linearGradient>
    </defs>
  </svg>
);
