import { PriceRangeType } from 'api/favouriteFilters';
import { AxiosResponse } from 'axios';
import { Job, JobPost, JobType } from 'models/Job';
import { BoostType } from 'models/Boosts';
import { PaginatedResponse, Pagination } from 'models/Pagination';
import { FormJobTimeAndPricing } from 'models/Job/TimeAndPricing';
import utils from 'utils';
import httpClient from '../httpClient';

type JobPostResponse = { jobPost: JobPost };

export type JobAllPostsResponse = {
  items: JobPost[];
  suggestion: PaginatedResponse<JobPost>;
} & Pagination;

const jobPostPath = (routePath: TemplateStringsArray) =>
  `job-posts/${routePath}`;

function getJobPosts(params?: Record<string, any>) {
  return httpClient.get<JobAllPostsResponse>(jobPostPath``, { params });
}

function getJobPost(id: number, params?: { $relations: string }) {
  return httpClient.get<JobPostResponse>(jobPostPath`` + id, { params });
}

function createJobPost(job: JobPost<'request'>) {
  return httpClient.post<JobPostResponse>(jobPostPath``, job);
}

function updateJobPost(job: JobPost<'request'>, id: number) {
  return httpClient.patch<JobPostResponse>(jobPostPath`` + id, job);
}

function uploadJobPostFiles(jobId: number, files: File[]) {
  const formData = utils.prepareFilesForUpload(files, 'job-attachments');

  return httpClient.post<JobPostResponse>(
    jobPostPath`` + `${jobId}/files`,
    formData,
  );
}

function deleteJobPostFile(jobId: number, fileId: number) {
  return httpClient.delete<{ message: string }>(
    jobPostPath`` + `${jobId}/files/${fileId}`,
  );
}

function signJobPost(id: number) {
  return httpClient.post<JobPostResponse>(jobPostPath`` + `${id}/sign`);
}

function unsignJobPost(id: number) {
  return httpClient.post<JobPostResponse>(jobPostPath`` + `${id}/unsign`);
}

function sendJobPost(id: number, sendToProfile?: boolean) {
  return httpClient.post<{ jobs: JobPost[] }>(jobPostPath`` + `${id}/send`, {
    sendToProfile,
  });
}

function reserveJobPost(id: number, timeAndPricing?: FormJobTimeAndPricing) {
  return httpClient.post<{ job: Job }>(jobPostPath`` + `${id}/reserve`, {
    timeAndPricing: timeAndPricing
      ? { ...timeAndPricing, price: timeAndPricing.price.amount }
      : undefined,
  });
}

function getClientJobPost(userId: number) {
  return httpClient.get(jobPostPath`user` + userId);
}

const getPriceRange = (
  type?: JobType,
): Promise<AxiosResponse<PriceRangeType>> => {
  const typeMaybe = type
    ? {
        params: {
          $where: {
            type: {
              EQUAL: type,
            },
          },
        },
      }
    : undefined;

  return httpClient.get('job-posts/price-range', typeMaybe);
};

const boostJobPost = (
  jobPostId: number,
  boostType: BoostType,
  file: File,
  existingFileId?: number,
) => {
  const formData = utils.prepareFilesForUpload(
    !existingFileId && file && [file],
    'job-boost',
  );

  formData.append('type', boostType);

  if (existingFileId) {
    formData.append('fileId', `${existingFileId}`);
  }

  return httpClient.post<JobPostResponse>(
    jobPostPath`` + `${jobPostId}/boost`,
    formData,
  );
};

const favouriteJobPost = (jobPostId: number) => {
  return httpClient.post<JobPostResponse>(
    jobPostPath`` + `${jobPostId}/favourite`,
  );
};

const unfavouriteJobPost = (jobPostId: number) => {
  return httpClient.post<JobPostResponse>(
    jobPostPath`` + `${jobPostId}/unfavourite`,
  );
};

/**
 * @returns object like { [jobPost.id]: boolean }
 */
const getFavouritedJobPosts = (jobPostIds: string) => {
  return httpClient.get<Record<string, boolean>>(jobPostPath`favourites`, {
    params: { jobPostIds },
  });
};

export default {
  getJobPosts,
  getJobPost,
  createJobPost,
  updateJobPost,
  uploadJobPostFiles,
  deleteJobPostFile,
  signJobPost,
  unsignJobPost,
  sendJobPost,
  reserveJobPost,
  getClientJobPost,
  getPriceRange,
  boostJobPost,
  favouriteJobPost,
  unfavouriteJobPost,
  getFavouritedJobPosts,
};
