import useBusListener from 'hooks/useBusListener';
import { useEffect, useState } from 'react';
import { isAndroid } from 'react-device-detect';

const useChatScrollContainer = () => {
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [scrollContainer, setScrollContainer] = useState<HTMLElement>(null);

  const handleInputFocused = ({ payload }: { payload: boolean }) => {
    setIsInputFocused(payload);
  };

  useBusListener('INPUT_FOCUS_CHANGE', handleInputFocused);

  useEffect(() => {
    // This is the whole chat component
    const chat = document.getElementById('anys-chat');
    // This is only the messages container
    const chatMain = document.getElementById('anys-chat-main');

    // On android we use the whole chat to scroll
    // when input is focused because of overscroll problem
    const container = isAndroid && isInputFocused ? chat : chatMain;

    setScrollContainer(container);
  }, [isInputFocused]);

  return { isInputFocused, scrollContainer };
};

export default useChatScrollContainer;
