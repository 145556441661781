import React from 'react';
import { Navigate } from 'react-router-dom';
import { EnhancedRouteProps } from 'router/routes/Enhanced/Enhanced.route';
import EditJob from './pages/EditJob';
import ViewJob from './pages/ViewJob';
import JobNotFound from './pages/JobNotFound';

export default [
  {
    path: 'not-found/:id',
    element: JobNotFound,
  },
  {
    path: 'edit/:id',
    element: () => <EditJob />,
    authorized: true,
  },
  {
    path: ':id',
    element: () => <ViewJob />,
  },
  {
    index: true,
    element: () => <Navigate to="/provide" replace />,
  },
  {
    path: '',
    element: () => <Navigate to="/" replace />,
  },
] as Array<EnhancedRouteProps>;
