import { BooleanString, JobPost } from 'models/Job';
import { removeInvalidProps } from 'utils/job';
import {
  parseDeadlines,
  parseFormArbitrationOptions,
  parseFormDeadlines,
  parseFormTimeAndPricing,
  parseFormTypeOfService,
  parseTimeAndPricing,
  parseTypeOfService,
} from 'utils/job-parses';

const parseJobPostFormValues = (
  values: Omit<JobPost<'form'>, 'attachments'>,
): JobPost<'request'> => {
  const {
    id,
    isNegotiable,
    hasFreeCancelation,
    hasDeadlinePolicy,
    mainSkill,
    skills,
    timeAndPricing,
    deadlines = [],
    hasArbitration,
    sendToUsers,
    arbitrationOptions = [],
    typeOfService,
    freeCancelation,
    isSigned,
    suspendedUntil,
    ...restOfValues
  } = values;

  const cleanTypeOfService = removeInvalidProps(
    parseFormTypeOfService(typeOfService),
  );
  const cleanFreeCancelation = freeCancelation
    ? removeInvalidProps(freeCancelation)
    : null;

  return {
    ...restOfValues,
    freeCancelation: cleanFreeCancelation,
    typeOfService: cleanTypeOfService,
    arbitrationOptions: parseFormArbitrationOptions(arbitrationOptions),
    deadlines: parseFormDeadlines(deadlines),
    timeAndPricing: parseFormTimeAndPricing(timeAndPricing),
    isNegotiable: isNegotiable === 'true',
    hasFreeCancelation: hasFreeCancelation === 'true',
    hasDeadlinePolicy: hasDeadlinePolicy === 'true',
    hasArbitration: hasArbitration === 'true',
    mainSkill,
    skills,
    sendToUsers: sendToUsers?.map(({ id }) => id),
  };
};

const parseJobPostToFormValues = (jobPost: JobPost): JobPost<'form'> => {
  if (!jobPost) return null;

  const {
    isNegotiable,
    hasFreeCancelation,
    hasDeadlinePolicy,
    timeAndPricing,
    user,
    signedAt,
    state,
    createdAt,
    updatedAt,
    deadlines = [],
    hasArbitration,
    mainSkill,
    skills,
    availability,
    typeOfService,
    positionBoost,
    boosts,
    suspendedUntil,
    ...restOfPost
  } = jobPost;

  const toReturn = {
    ...restOfPost,
    typeOfService: parseTypeOfService(typeOfService),
    mainSkill: mainSkill?.name,
    skills: skills?.map(({ name }) => name),
    isSigned: false,
    deadlines: parseDeadlines(deadlines),
    timeAndPricing: parseTimeAndPricing(timeAndPricing),
    isNegotiable: `${isNegotiable}` as BooleanString,
    hasFreeCancelation: `${hasFreeCancelation}` as BooleanString,
    hasDeadlinePolicy: `${hasDeadlinePolicy}` as BooleanString,
    hasArbitration: `${hasArbitration}` as BooleanString,
  };

  return toReturn;
};

export { parseJobPostFormValues, parseJobPostToFormValues };
