import { useEffect } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

export default function useResizeObserver(
  el: HTMLElement | undefined | null,
  callback: (entries: ResizeObserverEntry[]) => void,
): void {
  useEffect(() => {
    if (!el) return;

    const resizeObserver = new ResizeObserver(callback);

    resizeObserver.observe(el);

    return () => {
      resizeObserver.disconnect();
    };
  }, [el, callback]);
}
