import React from 'react';
import Card from './Card.component';
import { Button } from 'ncoded-component-library';
import classNames from 'classnames';

import './Card.styles.scss';
import './Card.styles.responsive.scss';

export type CardProps = {
  className?: string;
  cardTitle?: string;
  footer?: React.ReactNode;
  buttonLabel?: string;
  onClick?: () => void;
};

const ProfileCard: React.FC<React.PropsWithChildren<CardProps>> = (props) => {
  const { children, className, cardTitle, buttonLabel, onClick } = props;

  const classes = classNames('anys-profile-card-component', className);

  //TODO: change header title styles
  return (
    <Card
      cardTitle={
        <div className="anys-card__header">
          {cardTitle}
          <Button
            className="anys-card__title__btn"
            onClick={onClick}
            type="button"
            variant="link"
          >
            <span>{buttonLabel}</span>
          </Button>
        </div>
      }
      footer={
        buttonLabel && (
          <Button type="button" variant="link" onClick={onClick}>
            <span>{buttonLabel}</span>
          </Button>
        )
      }
      className={classes}
    >
      {children}
    </Card>
  );
};

export default ProfileCard;
