import React, { useContext, useMemo } from 'react';
import { useJob } from '../Job/Job.page';
import JobPostForm from 'router/subrouters/JobPost/pages/CreateEditJobPost/components/JobPostForm';
import { parseJobToJobPostFormValues } from '../Job/utils';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import { useTranslation } from 'react-i18next';
import UserScheduleContext from 'components/JobPostPreview/components/UserScheduleModal/UserScheduleProvider/UserSchedule.context';

const EditJob: React.FC = () => {
  const { t } = useTranslation();

  const { currentUser } = useContext(CurrentUserContext);
  const { selectedSchedule } = useContext(UserScheduleContext);

  const { job, savingJob, fetchingJob, updateJob } = useJob();

  const isOwnJob =
    job?.type === 'Provide'
      ? currentUser?.id === job?.provider?.id
      : currentUser?.id === job?.client?.id;

  const initialValues = useMemo(() => {
    const parsedJob = parseJobToJobPostFormValues(job);

    if (selectedSchedule) {
      parsedJob.timeAndPricing = {
        ...parsedJob.timeAndPricing,
        ...selectedSchedule,
        importAvailability: false,
      };
    }

    return parsedJob;
  }, [job, selectedSchedule]);

  return job ? (
    <JobPostForm
      initialValues={initialValues}
      onSubmit={updateJob}
      inProgress={savingJob}
      isOwnJob={isOwnJob}
      provider={job.provider}
      client={job.client}
      isSignedByClient={job.isSignedByClient}
      isSignedByProvider={job.isSignedByProvider}
      jobVersion={job.version}
      jobCommonId={job.commonId}
      prevVersion={job.prevVersion}
      isDraft={false}
      versionState={job.versionState}
    />
  ) : fetchingJob ? (
    <div className="card">{t('General.loadingDetails')}</div>
  ) : null;
};

export default EditJob;
