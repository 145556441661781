import React, { useContext, useRef } from 'react';
import classNames from 'classnames';
import { PaymentMethod } from 'models/Wallet';
import DropdownOptions from 'components/DropdownOptions';
import { DropdownRef } from 'ncoded-component-library/build/components/molecules/Dropdown/Dropdown.component';
import { Button } from 'ncoded-component-library';
import ElipsisIcon from 'icons/Elepsis.icon';
import ThemeContext from 'providers/Theme/Theme.context';

import './CardDetails.styles.scss';

type CardDetailsProps = {
  paymentMethod: PaymentMethod;
  handleDetachPaymentMethod: (
    stripePaymentMethodId: string,
    isDefault?: boolean,
  ) => void;
  handleSetDefault: (stripePaymentMethodId: string) => void;
  className?: string;
};

const CardDetails = (props: CardDetailsProps) => {
  const {
    paymentMethod,
    handleDetachPaymentMethod,
    handleSetDefault,
    className,
  } = props;

  const classes = classNames('anys-card-details', className);
  const { theme } = useContext(ThemeContext);

  const dropdownRef = useRef<DropdownRef>();

  return (
    <div className={classes}>
      <img
        alt="payment method brand"
        src={`/assets/images/payment-methods/${theme}-theme/${paymentMethod.brand}.png`}
        className="anys-card-details__brand-img"
      />

      <div className="anys-card-details__info">
        <div className="anys-card-details__info__main">
          <span className="anys-card-details__brand">
            {paymentMethod.brand}
          </span>

          <span className="anys-card-details__lastfour">
            <span className="anys-card-details__lastfour__asterisk">****</span>
            {paymentMethod.last4Digits}
          </span>
        </div>
        <div className="anys-card-details__expiration">
          <span>
            {`Expiration: ${paymentMethod.expirationMonth}/${paymentMethod.expirationYear}`}
          </span>
        </div>
      </div>
      {paymentMethod.isDefault && (
        <div className="anys-card-details__default">Default</div>
      )}
      <DropdownOptions
        dropdownRef={dropdownRef}
        lockScroll={false}
        trigger={
          <Button
            className="anys-card-details__more-actions"
            variant="link"
            icon={<ElipsisIcon />}
          />
        }
        options={
          paymentMethod.isDefault
            ? [
                {
                  title: 'Remove',
                  key: 'remove',
                  onClick: () => {
                    ((dropdownRef as any).current as any)?.setIsOpen(false);
                    handleDetachPaymentMethod(
                      paymentMethod.stripePaymentMethodId,
                      paymentMethod.isDefault,
                    );
                  },
                },
              ]
            : [
                {
                  title: 'Set default',
                  key: 'default',
                  onClick: () => {
                    ((dropdownRef as any).current as any)?.setIsOpen(false);
                    handleSetDefault(paymentMethod.stripePaymentMethodId);
                  },
                },
                {
                  title: 'Remove',
                  key: 'remove',
                  onClick: () => {
                    handleDetachPaymentMethod(
                      paymentMethod.stripePaymentMethodId,
                    );
                  },
                },
              ]
        }
      />
    </div>
  );
};

export default CardDetails;
