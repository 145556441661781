import React from 'react';

type PlusGradientProps = React.SVGProps<SVGSVGElement> & {
  gradientType?: 'purple' | 'pink';
};

const PlusGradient: React.FC<PlusGradientProps> = ({
  gradientType,
  ...rest
}) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.02508 0.333497C7.48532 0.3341 7.85792 0.707685 7.85732 1.16792L7.85077 6.16683H12.832C13.2923 6.16683 13.6654 6.53993 13.6654 7.00016C13.6654 7.4604 13.2923 7.8335 12.832 7.8335H7.84859L7.84203 12.8346C7.84143 13.2948 7.46784 13.6674 7.00761 13.6668C6.54737 13.6662 6.17476 13.2926 6.17537 12.8324L6.18192 7.8335H1.16536C0.705127 7.8335 0.332031 7.4604 0.332031 7.00016C0.332031 6.53993 0.705127 6.16683 1.16536 6.16683H6.1841L6.19066 1.16574C6.19126 0.7055 6.56484 0.332894 7.02508 0.333497Z"
        fill="url(#paint0_linear_4940_38867)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4940_38867"
          x1="0.332031"
          y1="7.00016"
          x2="13.6654"
          y2="7.00016"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={gradientType === 'purple' ? '#a761f5' : '#FF7BAC'} />
          <stop
            offset="1"
            stopColor={gradientType === 'purple' ? '#50a8fa' : '#D142F4'}
          />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default PlusGradient;
