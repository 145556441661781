import React from 'react';
import SlatePreview from 'components/SlatePreview';
import JobState from 'components/JobState';
import { JOB_STATE } from 'constants/job';
import classNames from 'classnames';
import { JobPost } from 'models/Job';
import { Link } from 'react-router-dom';
import TrashIcon from 'icons/Trash.icon';

import './OfferCard.styles.scss';
import './OfferCard.styles.responsive.scss';

type OfferCardProps = {
  className?: string;
  isOwnJob: boolean;
  jobPost: Partial<JobPost>;
  offerId: number;
  onDeleteOffer: (offerId: number) => void;
};

const OfferCard = (props: OfferCardProps) => {
  const { className, isOwnJob, jobPost, offerId, onDeleteOffer } = props;

  const {
    id: jobPostId,
    title,
    description,
    isSigned,
    timeAndPricing,
  } = jobPost || {};

  const classes = classNames('anys-offer-card', className);

  const state = isSigned ? JOB_STATE.OFFER : JOB_STATE.AD;

  return (
    <section className={classes}>
      <header className="anys-offer-card__header">
        <span className="anys-offer-card__header__title">{title}</span>
        <JobState state={state} />
        {isOwnJob && (
          <button
            type="button"
            onClick={() => onDeleteOffer(offerId)}
            className="anys-offer-card__header__delete"
          >
            <TrashIcon fill="currentColor" />
          </button>
        )}
      </header>

      <Link
        to={`/job-post/${jobPostId}`}
        target="_self"
        className="anys-offer-card__link"
      />

      <SlatePreview
        className="anys-offer-card__description"
        maxLength={250}
        key={offerId}
      >
        {description}
      </SlatePreview>

      <label className="anys-offer-card__price">
        {`$${(timeAndPricing?.price / 100)?.toFixed(2)}`}
        <span>{timeAndPricing?.type === 'Hourly' && '/hour'}</span>
      </label>
    </section>
  );
};

export default OfferCard;
