import { PositionBoostReq, BoostRes, PositionBoost } from 'models/Boosts';
import httpClient from '../httpClient';

const positionBoostsPath = (routePath: TemplateStringsArray) =>
  `position-boosts/${routePath}`;

const initBoost = (boosts: PositionBoostReq) => {
  return httpClient.post<PositionBoost>(positionBoostsPath``, boosts);
};

const updateBoost = (boostId: number, boosts: PositionBoostReq) => {
  return httpClient.put<Partial<BoostRes>>(
    positionBoostsPath`` + boostId,
    boosts,
  );
};

const getBoost = (boostId: number) => {
  return httpClient.get<PositionBoost>(positionBoostsPath`` + boostId);
};

const pauseBoost = (boostId: number) => {
  return httpClient.post<PositionBoost>(
    positionBoostsPath`` + `${boostId}/pause`,
  );
};

const resumeBoost = (boostId: number) => {
  return httpClient.post<PositionBoost>(
    positionBoostsPath`` + `${boostId}/resume`,
  );
};

const cancelBoost = (boostId: number) => {
  return httpClient.post<PositionBoost>(
    positionBoostsPath`` + `${boostId}/cancel`,
  );
};

export default {
  initBoost,
  updateBoost,
  getBoost,
  cancelBoost,
  pauseBoost,
  resumeBoost,
};
