import React from 'react';

type MailProps = React.SVGProps<SVGSVGElement>;

const Mail: React.FC<MailProps> = (props) => {
  const { className, ...restOfProps } = props;

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...restOfProps}
    >
      <path
        d="M16 0L0 9C0 11.281 0 28 0 28C0 30.209 1.79101 32 4 32H28C30.209 32 32 30.209 32 28C32 28 32 11.188 32 9L16 0ZM2 28V12.375L11.066 19.608L2.09501 28.579C2.03901 28.394 2 28.203 2 28ZM3.55498 29.946L12.742 20.76L16 23L19.258 20.76L28.445 29.946C28.301 29.979 3.69898 29.979 3.55498 29.946ZM30 28C30 28.203 29.961 28.394 29.905 28.579L20.9341 19.608L30 12.375V28ZM16 20.656L2 10L15.98 2.33103L30 10L16 20.656Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Mail;
