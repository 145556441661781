import React, { useCallback } from 'react';
import Modal, { ModalRef } from 'components/Modal';
import { useTranslation } from 'react-i18next';
import api from 'api';
import InputField from 'components/InputField';
import formValidators from 'utils/formValidators';
import { Field, Form } from 'react-final-form';
import FormButtons from 'components/FormButtons';
import MultipleSelectField from '../MultipleSelectField';
import { SkillGroup } from '../../models/Skills';
import useGetSkillGroups from '../../hooks/useGetSkillGroups';

type CreateNewSkillModalProps = {
  modalRef: (element: ModalRef) => void;
  onClose: () => void;
  handleNewSkill: (newSkill: string) => void;
};
const CreateNewSkillModal = (props: CreateNewSkillModalProps) => {
  const { modalRef, onClose, handleNewSkill } = props;

  const { t } = useTranslation();
  const { skillGroups } = useGetSkillGroups();

  const handleOnSubmit = useCallback(
    async (value: { newSkill: string; group: { value: number } }) => {
      try {
        await api.skill.createSkill(value.newSkill, value.group.value);
        handleNewSkill(value.newSkill);
        onClose();
      } catch {
        console.error();
      }
    },
    [handleNewSkill, onClose],
  );

  return (
    <Modal
      ref={modalRef}
      type="action"
      modalName="createNewSkillModal"
      title={t('Skills.createNewSkill')}
      overlayCloses
      isDrawer
      footer={null}
    >
      <Form
        onSubmit={handleOnSubmit}
        render={(formRenderProps) => {
          const { handleSubmit } = formRenderProps;

          return (
            <form onSubmit={handleSubmit}>
              <Field
                className="anys-skills-modal__select"
                component={MultipleSelectField}
                ignoreAsync
                options={skillGroups}
                mapFunction={(option: SkillGroup) => ({
                  value: option.id,
                  label: option.name,
                })}
                hideTags
                name="group"
                label={t('Skills.groupName')}
                placeholder={t('Skills.selectGroupName')}
                icon={<></>}
                searchable
                singleSelect
              />
              <InputField
                name="newSkill"
                label={t('Skills.createNewSkill')}
                placeholder={t('Skills.skillName')}
                validate={formValidators.required(t('General.required'))}
              />
              <FormButtons
                cancelTxt={t('General.cancel')}
                submitTxt={t('General.confirm')}
                onClear={onClose}
              />
            </form>
          );
        }}
      />
    </Modal>
  );
};

export default CreateNewSkillModal;
