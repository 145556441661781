import MobileHeaderContext from 'components/MobileHeader/providers/MobileHeader/MobileHeader.context';
import { DependencyList, useContext, useEffect } from 'react';

export default function useHeaderContent(
  content: JSX.Element,
  dependencies: DependencyList = [],
  priority?: number,
  condition = true,
) {
  const { setContent } = useContext(MobileHeaderContext);

  useEffect(() => {
    if (!condition) return;

    setContent(content, priority);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setContent, priority, condition, ...dependencies]);
}
