import { Job } from 'models/Job';
import utils from 'utils';
import httpClient from '../httpClient';

type JobResponse = { job: Job };

const jobPath = (routePath: TemplateStringsArray) => `jobs/${routePath}`;

function getJob(commonId: number) {
  return httpClient.get<JobResponse>(jobPath`` + commonId);
}

function updateJob(commonId: number, jobVersion: number, job: Job<'request'>) {
  return httpClient.patch<JobResponse>(
    jobPath`` + `${commonId}/${jobVersion}`,
    job,
  );
}

function signJob(commonId: number, version: number) {
  return httpClient.post<JobResponse & { contractCommonId?: number }>(
    jobPath`` + `${commonId}/sign/${version}`,
  );
}

function unsignJob(commonId: number, version: number) {
  return httpClient.post<JobResponse>(
    jobPath`` + `${commonId}/unsign/${version}`,
  );
}

function acceptVersion(commonId: number, jobVersion: number) {
  return httpClient.post<JobResponse>(
    jobPath`` + `${commonId}/accept-version/${jobVersion}`,
  );
}

function rejectVersion(commonId: number, jobVersion: number) {
  return httpClient.post<JobResponse>(
    jobPath`` + `${commonId}/reject-version/${jobVersion}`,
  );
}

function uploadFiles(commonId: number, jobVersion: number, files: File[]) {
  const formData = utils.prepareFilesForUpload(files, 'job-attachments');

  return httpClient.post<JobResponse>(
    jobPath`` + `${commonId}/files/${jobVersion}`,
    formData,
  );
}

function deleteFile(commonId: number, jobVersion: number, fileId: number) {
  return httpClient.delete<{ message: string }>(
    jobPath`` + `${commonId}/files/${fileId}/${jobVersion}`,
  );
}

export default {
  getJob,
  updateJob,
  signJob,
  unsignJob,
  acceptVersion,
  rejectVersion,
  uploadFiles,
  deleteFile,
};
