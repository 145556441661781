export const TEXT_COLORS = {
  RED: 'red',
  BLUE: 'blue',
  GREEN: 'green',
  BLACK: 'black',
  GRAY: 'gray',
  WHITE: 'white',
} as const;
export const TEXT_COLORS_ARRAY = Object.values(TEXT_COLORS);

export const TEXT_STYLES = {
  BOLD: 'bold',
  ITALIC: 'italic',
  UNDERLINE: 'underline',
  LINE_THROUGH: 'line-through',
  ...TEXT_COLORS,
} as const;
export const TEXT_STYLES_ARRAY = Object.values(TEXT_STYLES);

export const LIST_TYPES = {
  ORDERED_LIST: 'ordered-list',
  UNORDERED_LIST: 'unordered-list',
} as const;
export const LIST_TYPES_ARRAY = Object.values(LIST_TYPES);

export const TEXT_INDENT_TYPE = {
  OUTDENT: 'outdent',
  INDENT: 'indent',
} as const;
export const TEXT_INDENT_ARRAY = Object.values(TEXT_INDENT_TYPE);

export const ELEMENT_TYPES = {
  ...LIST_TYPES,
  ...TEXT_INDENT_TYPE,
  LINK: 'link',
  PARAGRAPH: 'paragraph',
  LIST_ITEM: 'list-item',
  DIV: 'div',
} as const;
export const ELEMENT_TYPES_ARRAY = Object.values(ELEMENT_TYPES);

export const EDITOR_ACTIONS = {
  ...TEXT_STYLES,
  TEXT_COLOR: 'text-color',
  BACKGROUND_COLOR: 'background-color',
  ...LIST_TYPES,
  ...TEXT_INDENT_TYPE,
  LINK: 'link',
} as const;
