import React from 'react';

type TrashProps = React.SVGProps<SVGSVGElement>;

const TrashIcon: React.FC<TrashProps> = ({ fill = '#61656A' }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path
        d="M6.91675 12.75C6.45651 12.75 6.08341 13.1231 6.08341 13.5833C6.08341 14.0436 6.45651 14.4167 6.91675 14.4167H11.0834C11.5437 14.4167 11.9167 14.0436 11.9167 13.5833C11.9167 13.1231 11.5437 12.75 11.0834 12.75H6.91675Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.75008 0.25C5.34417 0.25 4.99731 0.542458 4.92873 0.94253L4.54745 3.16667H1.50008C1.03984 3.16667 0.666748 3.53976 0.666748 4C0.666748 4.46024 1.03984 4.83333 1.50008 4.83333H2.80211L3.58514 16.9703C3.61343 17.4088 3.97734 17.75 4.41675 17.75H13.5834C14.0228 17.75 14.3867 17.4088 14.415 16.9703L15.1981 4.83333H16.5001C16.9603 4.83333 17.3334 4.46024 17.3334 4C17.3334 3.53976 16.9603 3.16667 16.5001 3.16667H13.4527L13.0714 0.94253C13.0028 0.542458 12.656 0.25 12.2501 0.25H5.75008ZM11.7617 3.16667H6.23843L6.45271 1.91667H11.5474L11.7617 3.16667ZM5.19805 16.0833L4.47224 4.83333H13.5279L12.8021 16.0833H5.19805Z"
        fill={fill}
      />
    </svg>
  );
};

export default TrashIcon;
