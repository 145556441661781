import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import classNames from 'classnames';
import { AnyObject, Form } from 'react-final-form';
import InputField from 'components/InputField';
import { Button } from 'ncoded-component-library';
import { useProfile } from '../Profile/Profile.page';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import TextAreaField from 'components/TextAreaField';
import SelectField from 'components/SelectField';
import api from 'api';
import CalendarDropdownField from 'components/CalendarDropdownField';
import MobileHeaderContext from 'components/MobileHeader/providers/MobileHeader/MobileHeader.context';
import TimesCircleIcon from 'icons/TimesCircle.icon';
import { Picture, Type, User } from 'models/User';
import { useNavigate } from 'react-router-dom';
import formValidators from 'utils/formValidators';
import OverlaySpinner from 'components/OverlaySpinner';
import Gallery from '../ProfilePreview/components/Gallery';
import {
  isPicture,
  PreviewGallery,
} from '../ProfilePreview/components/Gallery/Gallery.component';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import dayjs, { Dayjs } from 'dayjs';
import InputAutocompleteLocationField from 'components/InputAutocompleteLocation/InputAutocompleteLocationField.component';
import { LANGUAGE_OPTIONS, LANGUAGE_PROFICIENCY } from 'constants/language';
import { useTranslation } from 'react-i18next';
import AddSmallIcon from 'icons/AddSmall.icon';
import MainTemplate from 'components/templates/MainTemplate';
import CheckboxField from 'components/CheckboxField';
import BackButton from 'components/BackButton';
import RadioButtonField from 'components/RadioButtonField';
import AddSkillsField from 'router/subrouters/JobPost/pages/CreateEditJobPost/components/AddSkillsField';
import { getDegreeTypeOptions } from 'utils/user';
import { capitalizeFirstLetter } from 'utils';

import './EditProfile.styles.scss';
import './EditProfile.styles.responsive.scss';

const FIELDS_LIMIT_MAX = {
  EXPERIENCES: 15,
  EDUCATIONS: 6,
  CERTIFICATES: 6,
  LANGUAGES: 8,
};

export const DATE_FIELD_PROPS = {
  leftLimitDate: dayjs().subtract(100, 'year'),
  rightLimitDate: dayjs().subtract(1, 'day'),
  placeholder: 'DD/MM/YYYY',
  parse: (date: Dayjs) => (date ? date.format('YYYY-MM-DD') : date),
};

type EditProfileProps = {
  className?: string;
};

const EditProfile: React.FC<EditProfileProps> = (props) => {
  const { className } = props;
  const { profileUser, setProfileUser } = useProfile();

  const { uploadUserFile, removeUserFile, setCurrentUser } =
    useContext(CurrentUserContext);

  const [removeIds, setRemoveIds] = useState<number[]>([]);
  const [previewGallery, setPreviewGallery] = useState<PreviewGallery[]>([]);
  const { setStyleOptions, setContent } = useContext(MobileHeaderContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const classes = classNames('anys-edit-profile', 'anys-job-post', className);

  const onSubmit = async (
    formData: Omit<User, 'skills'> & { skills: string[] },
  ) => {
    const updatedUser: Omit<User, 'skills'> & { skills: string[] } = {
      type: formData.type,
      firstName: formData.firstName,
      lastName: formData.lastName,
      businessName: formData.businessName,
      profession: formData.profession || '',
      location: formData.location,
      skills: formData.skills,
      experiences: formData.experiences?.map(
        ({
          id,
          companyName,
          position,
          location,
          employmentType,
          startDate,
          endDate,
          isWorkingInRole,
          description,
        }) => ({
          id,
          companyName,
          position,
          location,
          employmentType,
          startDate,
          endDate,
          isWorkingInRole,
          description,
        }),
      ),
      about: formData.about || '',
      educations: formData.educations?.map(
        ({
          id,
          schoolName,
          degree,
          fieldOfStudy,
          startDate,
          endDate,
          description,
        }) => ({
          id,
          schoolName,
          degree,
          fieldOfStudy,
          startDate,
          endDate,
          description,
        }),
      ),
      certificates: formData.certificates?.map(
        ({ id, name, issuedBy, issueDate }) => ({
          id,
          name,
          issuedBy,
          issueDate,
        }),
      ),
      languages: formData.languages?.map(({ id, language, proficiency }) => ({
        id,
        language,
        proficiency,
      })),
    };

    try {
      OverlaySpinner.show('.anys-edit-profile__form');
      const { data } = await api.user.updateUser(updatedUser);

      let userResponse = data;

      const filesToUpload = previewGallery.map(({ file }) => file);

      if (removeIds.length) {
        const responses = await Promise.all(
          removeIds.map((id) => removeUserFile(id, false)),
        );

        userResponse = responses[removeIds.length - 1];
      }

      if (filesToUpload.length) {
        userResponse = await uploadUserFile('gallery', filesToUpload, false);
      }

      if (userResponse) {
        setProfileUser((old) => ({ ...old, ...userResponse }));
        setCurrentUser((old) => ({ ...old, ...userResponse }));

        navigate('/profile');
      }
    } catch (e) {
      console.error(e);
    } finally {
      OverlaySpinner.hide('.anys-edit-profile__form');
    }
  };

  const { required, dateFromCalendarIsAfter } = formValidators;

  const validators = useMemo(
    () => ({
      firstName: required<string>(t('General.required')),
      lastName: required<string>(t('General.required')),
      businessName: required<string>(t('General.required')),
      location: required<string>(t('General.required')),
    }),
    [required, t],
  );

  const userImages = useMemo(() => {
    if (profileUser?.gallery) {
      return [
        ...previewGallery,
        ...profileUser.gallery.filter((g) => !removeIds?.includes(g.id)),
      ];
    } else {
      return previewGallery;
    }
  }, [previewGallery, profileUser?.gallery, removeIds]);

  const languageProficiencyOptions = useMemo(
    () =>
      Object.values(LANGUAGE_PROFICIENCY).map((prof) => ({
        value: prof,
        label: t(`General.proficiency${prof}`),
      })),
    [t],
  );

  const isBusinessProfile = profileUser?.role === 'Business';

  const initialValues = useMemo(() => {
    return {
      ...profileUser,
      skills: profileUser?.skills?.map(({ skill }) => skill.name),
    };
  }, [profileUser]);

  const employmentTypeOptions = useMemo(
    () => [
      { label: t('Profile.fullTime'), value: 'Full-time' },
      { label: t('Profile.partTime'), value: 'Part-time' },
    ],
    [t],
  );

  const degreeOptions = useMemo(() => getDegreeTypeOptions(t), [t]);

  //because form submit button is outside of form
  let submit: (
    event?: Partial<
      Pick<
        React.SyntheticEvent<Element, Event>,
        'preventDefault' | 'stopPropagation'
      >
    >,
  ) => Promise<AnyObject>;

  const onAddImage = useCallback((files: File[]) => {
    const imgsToAdd: PreviewGallery[] = [];

    files.forEach((file) => {
      const url = URL.createObjectURL(file);

      imgsToAdd.push({ id: url, previewUrl: url, file });
    });

    setPreviewGallery((oldPreviewGallery) => [
      ...imgsToAdd,
      ...oldPreviewGallery,
    ]);
  }, []);

  const onRemoveImage = useCallback(
    (el: Picture | PreviewGallery) => {
      if (!isPicture(el)) {
        URL.revokeObjectURL(el.previewUrl);

        setPreviewGallery((oldPreviewGallery) =>
          oldPreviewGallery.filter((element) => element.id !== el.id),
        );
      } else {
        setRemoveIds((prev) => [...prev, el.id]);
      }
    },

    [],
  );

  useEffect(() => {
    setStyleOptions({ hasBorder: true, absolutePosition: false });
    setContent(
      <div className="anys-edit-profile__header">
        <BackButton
          withBorder
          onBackClick={() => navigate('/profile', { replace: true })}
        />
        <span className="anys-edit-profile__header__title">
          {t('Profile.editProfile')}
        </span>

        <Button
          className="anys-edit-profile__header__edit-button"
          type="submit"
          // disabled={invalid}
          onClick={(event) => submit(event)}
        >
          {t('General.save')}
        </Button>
      </div>,
    );
  }, [navigate, setContent, setStyleOptions, submit, t]);

  useEffect(() => {
    return () =>
      previewGallery.forEach((el) => URL.revokeObjectURL(el.previewUrl));
  }, [previewGallery]);

  return (
    <main className={classes}>
      <MainTemplate className="anys-edit-profile">
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          mutators={{
            ...arrayMutators,
          }}
          render={({
            handleSubmit,
            values,
            form,
            form: {
              mutators: { push, pop },
            },
            invalid,
          }) => {
            submit = handleSubmit;

            const {
              type,
              skills,
              experiences,
              educations,
              certificates,
              languages,
            } = values;

            return (
              <form onSubmit={handleSubmit}>
                <div className="anys-edit-profile__form">
                  <h1>{t('Profile.editProfile')}</h1>
                  <p className="anys-edit-profile__form__basic-info-desc">
                    {t('Profile.editProfileSubtitle')}
                  </p>
                  <div className="anys-edit-profile__form_type">
                    <RadioButtonField
                      name="type"
                      checked={type === Type.CLIENT}
                      value="client"
                      label={t('Profile.client')}
                    />
                    <RadioButtonField
                      name="type"
                      value="client_and_provider"
                      checked={type === Type.CLIENT_AND_PROVIDER}
                      label={t('Profile.clientAndProvider')}
                    />
                    <p className="anys-edit-profile__form__basic-info-desc">
                      {t('Profile.editProfileTypeDesc')}
                    </p>
                  </div>

                  {/* BASIC INFO */}
                  <div>
                    <h2 className="anys-edit-profile__form__basic-info-header">
                      {t('Profile.basicInfo')}
                    </h2>
                    <div className="anys-edit-profile__form__fields">
                      {isBusinessProfile ? (
                        <InputField
                          name="businessName"
                          label={t('General.businessName')}
                          required
                          placeholder={t('General.businessName')}
                          validate={validators.businessName}
                        />
                      ) : (
                        <>
                          <InputField
                            name="firstName"
                            label={t('Profile.firstName')}
                            required
                            placeholder={t('Profile.firstName')}
                            validate={validators.firstName}
                            format={capitalizeFirstLetter}
                          />
                          <InputField
                            name="lastName"
                            label={t('Profile.lastName')}
                            required
                            placeholder={t('Profile.lastName')}
                            validate={validators.lastName}
                            format={capitalizeFirstLetter}
                          />
                        </>
                      )}
                      <InputField
                        name="profession"
                        label={t('Profile.profession')}
                        placeholder={t('Profile.profession')}
                        format={capitalizeFirstLetter}
                      />
                      <InputAutocompleteLocationField
                        name="location"
                        label={t('General.location')}
                        placeholder={t('General.location')}
                        required
                        validate={validators.location}
                        withFullAddress
                      />
                    </div>
                  </div>

                  {type === Type.CLIENT_AND_PROVIDER ? (
                    <>
                      <AddSkillsField
                        formApi={form}
                        selectedSkills={skills}
                        hasMainSkillInput={false}
                        skills={[]}
                      />

                      {/* ABOUT ME */}
                      <div className="anys-edit-profile-card">
                        <h2 className="anys-card__title">
                          {t('Profile.aboutMe')}
                        </h2>
                        <TextAreaField
                          name="about"
                          placeholder={t('Profile.aboutYourself')}
                          maxLength={2000}
                          showLengthCount
                          format={capitalizeFirstLetter}
                        />
                      </div>

                      {!isBusinessProfile && (
                        <>
                          {/* EXPERIENCE */}
                          <div className="anys-edit-profile-card">
                            <h2 className="anys-card__title">
                              {t('Profile.experience')}
                            </h2>
                            <FieldArray name="experiences">
                              {({ fields }) =>
                                fields.map((name, index) => (
                                  <div
                                    key={name}
                                    className="anys-edit-profile__fields-form"
                                  >
                                    <div className="anys-edit-profile__form__field-header">
                                      <label>
                                        {t('Profile.experience')} #{index + 1}
                                      </label>
                                      <label
                                        onClick={async () => {
                                          fields.remove(index);
                                        }}
                                        className="anys-edit-profile__form__field-header__delete"
                                      >
                                        {t('Profile.deleteExperience')}
                                        <TimesCircleIcon />
                                      </label>
                                    </div>
                                    <InputField
                                      name={`${name}.companyName`}
                                      label={t('Profile.company')}
                                      placeholder={t(
                                        'Profile.companyPlaceholder',
                                      )}
                                      format={capitalizeFirstLetter}
                                    />

                                    <InputField
                                      name={`${name}.position`}
                                      label={t('Profile.position')}
                                      placeholder={t(
                                        'Profile.positionPlaceholder',
                                      )}
                                      format={capitalizeFirstLetter}
                                    />

                                    <InputField
                                      name={`${name}.location`}
                                      label={t('General.location')}
                                      placeholder={t(
                                        'Profile.locationPlaceholder',
                                      )}
                                      format={capitalizeFirstLetter}
                                    />

                                    <SelectField
                                      name={`${name}.employmentType`}
                                      label={t('Profile.employmentType')}
                                      placeholder={t(
                                        'Profile.employmentTypePlaceholder',
                                      )}
                                      options={employmentTypeOptions}
                                    />

                                    <CheckboxField
                                      label={t('Profile.currentlyWorking')}
                                      name={`${name}.isWorkingInRole`}
                                    />

                                    <div className="anys-edit-profile__fields-form__dates">
                                      <CalendarDropdownField
                                        name={`${name}.startDate`}
                                        label={t('General.startDate')}
                                        withoutDays
                                        {...DATE_FIELD_PROPS}
                                      />
                                      {/* Show end date field only if currently working is false */}
                                      {!experiences?.[index]
                                        ?.isWorkingInRole && (
                                        <CalendarDropdownField
                                          name={`${name}.endDate`}
                                          label={t('General.endDate')}
                                          withoutDays
                                          validate={dateFromCalendarIsAfter(
                                            t('Profile.endDateValidation'),
                                            new Date(
                                              values?.experiences?.[
                                                index
                                              ]?.startDate,
                                            ),
                                          )}
                                          {...DATE_FIELD_PROPS}
                                        />
                                      )}
                                    </div>

                                    <TextAreaField
                                      name={`${name}.description`}
                                      label={t('General.description')}
                                      placeholder={t(
                                        'Profile.descriptionPlaceholder',
                                      )}
                                      maxLength={1200}
                                      showLengthCount
                                      format={capitalizeFirstLetter}
                                    />
                                  </div>
                                ))
                              }
                            </FieldArray>
                            {(!experiences ||
                              experiences?.length <
                                FIELDS_LIMIT_MAX.EXPERIENCES) && (
                              <Button
                                type="button"
                                variant="link"
                                className="anys-edit-profile-card__add-field-btn"
                                onClick={() => {
                                  push('experiences', undefined);
                                }}
                              >
                                <AddSmallIcon />
                                <span>{t('Profile.addExperience')}</span>
                              </Button>
                            )}
                          </div>
                          {/* EDUCATION */}
                          <div className="anys-edit-profile-card">
                            <h2 className="anys-card__title">
                              {t('Profile.education')}
                            </h2>
                            <FieldArray name="educations">
                              {({ fields }) =>
                                fields.map((name, index) => (
                                  <div
                                    key={name}
                                    className="anys-edit-profile__fields-form"
                                  >
                                    <div className="anys-edit-profile__form__field-header">
                                      <label>
                                        {t('Profile.education')} #{index + 1}
                                      </label>
                                      <label
                                        onClick={async () => {
                                          fields.remove(index);
                                        }}
                                        className="anys-edit-profile__form__field-header__delete"
                                      >
                                        {t('Profile.deleteEducation')}
                                        <TimesCircleIcon />
                                      </label>
                                    </div>
                                    <InputField
                                      name={`${name}.schoolName`}
                                      label={t('Profile.schoolName')}
                                      placeholder={t(
                                        'Profile.schoolNamePlaceholder',
                                      )}
                                      format={capitalizeFirstLetter}
                                    />
                                    <SelectField
                                      name={`${name}.degree`}
                                      label={t('Profile.degree')}
                                      placeholder={t(
                                        'Profile.degreePlaceholder',
                                      )}
                                      options={degreeOptions}
                                    />

                                    <InputField
                                      name={`${name}.fieldOfStudy`}
                                      label={t('Profile.fieldOfStudy')}
                                      placeholder={t(
                                        'Profile.fieldOfStudyPlaceholder',
                                      )}
                                      format={capitalizeFirstLetter}
                                    />
                                    <div className="anys-edit-profile__fields-form__dates">
                                      <CalendarDropdownField
                                        name={`${name}.startDate`}
                                        label={t('General.startTime')}
                                        {...DATE_FIELD_PROPS}
                                      />
                                      <CalendarDropdownField
                                        name={`${name}.endDate`}
                                        label={t('General.endTime')}
                                        validate={dateFromCalendarIsAfter(
                                          t('Profile.endDateValidation'),
                                          new Date(
                                            values?.educations?.[
                                              index
                                            ]?.startDate,
                                          ),
                                        )}
                                        {...DATE_FIELD_PROPS}
                                      />
                                    </div>
                                    <TextAreaField
                                      name={`${name}.description`}
                                      placeholder={t(
                                        'Profile.moreAboutEducationPlaceholder',
                                      )}
                                      maxLength={1200}
                                      showLengthCount
                                      format={capitalizeFirstLetter}
                                    />
                                  </div>
                                ))
                              }
                            </FieldArray>
                            {(!educations ||
                              educations?.length <
                                FIELDS_LIMIT_MAX.EDUCATIONS) && (
                              <Button
                                type="button"
                                variant="link"
                                className="anys-edit-profile-card__add-field-btn"
                                onClick={() => {
                                  push('educations', undefined);
                                }}
                              >
                                <AddSmallIcon />
                                <span>{t('Profile.addEducation')}</span>
                              </Button>
                            )}
                          </div>
                          {/* CERTIFICATE */}
                          <div className="anys-edit-profile-card">
                            <h2 className="anys-card__title">
                              {t('Profile.certificates')}
                            </h2>
                            <FieldArray name="certificates">
                              {({ fields }) =>
                                fields.map((name, index) => (
                                  <div
                                    key={name}
                                    className="anys-edit-profile__fields-form"
                                  >
                                    <div className="anys-edit-profile__form__field-header">
                                      <label>
                                        {t('Profile.certificate')} #{index + 1}
                                      </label>
                                      <label
                                        onClick={async () => {
                                          fields.remove(index);
                                        }}
                                        className="anys-edit-profile__form__field-header__delete"
                                      >
                                        {t('Profile.deleteCertificate')}{' '}
                                        <TimesCircleIcon />
                                      </label>
                                    </div>
                                    <InputField
                                      name={`${name}.name`}
                                      label={t('Profile.certificateName')}
                                      placeholder={t(
                                        'Profile.certificateNamePlaceholder',
                                      )}
                                      format={capitalizeFirstLetter}
                                    />
                                    <InputField
                                      name={`${name}.issuedBy`}
                                      label={t('Profile.issuedBy')}
                                      placeholder={t(
                                        'Profile.issuedByPlaceholder',
                                      )}
                                      format={capitalizeFirstLetter}
                                    />
                                    <InputField
                                      name={`${name}.fieldOfStudy`}
                                      label={t('Profile.fieldOfStudy')}
                                      placeholder={t(
                                        'Profile.fieldOfStudyPlaceholder',
                                      )}
                                      format={capitalizeFirstLetter}
                                    />
                                    <CalendarDropdownField
                                      name={`${name}.issueDate`}
                                      label={t('Profile.issueDate')}
                                      {...DATE_FIELD_PROPS}
                                    />
                                  </div>
                                ))
                              }
                            </FieldArray>
                            {(!certificates ||
                              certificates?.length <
                                FIELDS_LIMIT_MAX.CERTIFICATES) && (
                              <Button
                                type="button"
                                variant="link"
                                className="anys-edit-profile-card__add-field-btn"
                                onClick={() => {
                                  push('certificates', undefined);
                                }}
                              >
                                <AddSmallIcon />
                                <span>{t('Profile.addCertificate')}</span>
                              </Button>
                            )}
                          </div>
                        </>
                      )}

                      {/* LANGUAGE */}
                      <div className="anys-edit-profile-card">
                        <h2 className="anys-card__title">
                          {t('General.language')}
                        </h2>
                        <FieldArray name="languages">
                          {({ fields }) =>
                            fields.map((name, index) => {
                              const currVal = fields.value[index];

                              // Remove already selected langauges
                              const languageOptions = LANGUAGE_OPTIONS.filter(
                                (lang) =>
                                  !fields.value.some(
                                    (selectedLang) =>
                                      lang.value === selectedLang?.language &&
                                      // Make sure we don't filter the language
                                      // if we are on the field that selected it
                                      selectedLang?.language !==
                                        currVal?.language,
                                  ),
                              );

                              return (
                                <div
                                  key={name}
                                  className="anys-edit-profile__fields-form"
                                >
                                  <div className="anys-edit-profile__form__field-header">
                                    <label>
                                      {t('General.language')} #{index + 1}
                                    </label>
                                    <label
                                      onClick={async () => {
                                        fields.remove(index);
                                      }}
                                      className="anys-edit-profile__form__field-header__delete"
                                    >
                                      {t('Profile.deleteLanguage')}
                                      <TimesCircleIcon />
                                    </label>
                                  </div>
                                  <SelectField
                                    name={`${name}.language`}
                                    label={t('General.language')}
                                    options={languageOptions}
                                  />

                                  <SelectField
                                    name={`${name}.proficiency`}
                                    label={t('Profile.proficiency')}
                                    options={languageProficiencyOptions}
                                  />
                                </div>
                              );
                            })
                          }
                        </FieldArray>
                        {(!languages ||
                          languages?.length < FIELDS_LIMIT_MAX.LANGUAGES) && (
                          <Button
                            type="button"
                            variant="link"
                            className="anys-edit-profile-card__add-field-btn"
                            onClick={() => {
                              push('languages', undefined);
                            }}
                          >
                            <AddSmallIcon />
                            <span>{t('Profile.addLanguage')}</span>
                          </Button>
                        )}
                      </div>
                    </>
                  ) : null}
                </div>

                <div>
                  <h2>{t('General.gallery')}</h2>
                  <Gallery
                    edit
                    images={userImages}
                    onRemoveImage={onRemoveImage}
                    onAddImage={onAddImage}
                  />
                </div>

                <div className="anys-edit-profile__submit-btns">
                  <Button
                    type="submit"
                    variant="solid"
                    onClick={(event) => submit(event)}
                  >
                    {t('General.save')}
                  </Button>
                  <Button
                    type="button"
                    variant="link"
                    onClick={() => {
                      navigate('/profile');
                    }}
                  >
                    <span>{t('General.cancel')}</span>
                  </Button>
                </div>
              </form>
            );
          }}
        />
      </MainTemplate>
    </main>
  );
};

export default EditProfile;
