import React from 'react';
import classNames from 'classnames';

import './TimelineDot.styles.scss';

type TimelineDotProps = {
  className?: string;
  isLarge?: boolean;
};

const TimelineDot: React.FC<TimelineDotProps> = (props) => {
  const { className, isLarge } = props;

  const classes = classNames(
    'anys-timeline-dot',
    { 'anys-timeline-dot--large': isLarge },
    className,
  );

  return <span className={classes} />;
};

export default TimelineDot;
