import React from 'react';
import FiltersProvider from './providers/Filters/Filters.provider';
import Search, { SearchProps } from './Search.page';

const WrapperSearch: React.FC<SearchProps> = (props) => {
  return (
    <FiltersProvider>
      <Search {...props} />
    </FiltersProvider>
  );
};
export default WrapperSearch;
