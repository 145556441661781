import React from 'react';

type StrikethroughProps = React.SVGProps<SVGSVGElement>;

const Strikethrough: React.FC<StrikethroughProps> = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.5 10.1691H17.5V11.4191H13.8618C14.0592 11.8335 14.1589 12.2876 14.153 12.7465C14.1647 13.182 14.0713 13.6138 13.8806 14.0054C13.69 14.397 13.4077 14.7369 13.0578 14.9963C12.2149 15.5894 11.1981 15.884 10.1688 15.8333C9.41663 15.8384 8.67183 15.6854 7.98263 15.3842C7.35323 15.1231 6.80764 14.6941 6.40543 14.144C6.0351 13.6189 5.83969 12.9904 5.84697 12.3478V12.2533H6.08632V12.2524H6.3709V12.2533H7.51667V12.3478C7.50361 12.6406 7.56151 12.9323 7.68544 13.1979C7.80937 13.4635 7.99566 13.6952 8.22844 13.8734C8.79295 14.2753 9.47667 14.4748 10.1687 14.4394C10.7797 14.4794 11.3871 14.3196 11.8993 13.9843C12.0892 13.8438 12.2418 13.659 12.3436 13.4459C12.4455 13.2329 12.4936 12.9981 12.4837 12.7621C12.497 12.5342 12.4549 12.3064 12.361 12.0982C12.2671 11.8901 12.1242 11.7077 11.9445 11.5668C11.8709 11.5134 11.7944 11.4641 11.7155 11.4191H2.5V10.1691ZM13.6206 5.88319C13.2693 5.33736 12.7726 4.9005 12.1863 4.62184C11.5328 4.31052 10.8161 4.15473 10.0923 4.16668C9.083 4.12336 8.09069 4.43671 7.28933 5.05179C6.94529 5.31749 6.66778 5.65958 6.47873 6.05101C6.28968 6.44244 6.19427 6.87248 6.20005 7.30714C6.19714 7.71905 6.28882 8.12613 6.46805 8.49702H8.63195C8.56247 8.45231 8.47747 8.40888 8.42137 8.36315C8.25182 8.23808 8.11418 8.07475 8.01965 7.88646C7.92512 7.69816 7.87636 7.49021 7.87735 7.27952C7.86478 7.03901 7.91035 6.79902 8.01022 6.57986C8.11008 6.3607 8.26129 6.16884 8.45103 6.02052C8.93111 5.68757 9.50907 5.52546 10.0923 5.56016C10.7264 5.51985 11.3526 5.71868 11.8473 6.11741C12.0542 6.31224 12.2162 6.54974 12.3221 6.81345C12.428 7.07716 12.4753 7.36076 12.4606 7.64456V7.73907H14.1299V7.64456C14.1327 7.02072 13.9559 6.40926 13.6206 5.88319Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Strikethrough;
