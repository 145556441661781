import { ObjectValues } from 'types';

const CURRENCY_CODES = {
  AUD: 'AUD',
  CAD: 'CAD',
  CHF: 'CHF',
  CNY: 'CNY',
  DKK: 'DKK',
  EUR: 'EUR',
  GBP: 'GBP',
  HKD: 'HKD',
  INR: 'INR',
  JPY: 'JPY',
  MXN: 'MXN',
  NOK: 'NOK',
  NZD: 'NZD',
  SEK: 'SEK',
  SGD: 'SGD',
  USD: 'USD',
} as const;

export type Currency = ObjectValues<typeof CURRENCY_CODES>;

export type Money = { amount: number; currency: Currency };

export type CurrencyConfig = Omit<Intl.NumberFormatOptions, 'currency'> & {
  currency?: Currency;
};

// See: https://en.wikipedia.org/wiki/ISO_4217
// See: https://stripe.com/docs/currencies
const SUB_UNIT_DIVISORS: Partial<Record<Currency, number>> = {
  AUD: 100,
  CAD: 100,
  CHF: 100,
  CNY: 100,
  DKK: 100,
  EUR: 100,
  GBP: 100,
  HKD: 100,
  INR: 100,
  JPY: 1,
  MXN: 100,
  NOK: 100,
  NZD: 100,
  SEK: 100,
  SGD: 100,
  USD: 100,
} as const;

const CURRENCY_SYMBOLS: Partial<Record<Currency, string>> = {
  USD: '$',
} as const;

export { SUB_UNIT_DIVISORS, CURRENCY_CODES, CURRENCY_SYMBOLS };
