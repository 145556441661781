import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

const JumpLeft: React.FC<IconProps> = (props) => (
  <svg
    width="10"
    height="12"
    viewBox="0 0 10 12"
    fill="currentColor"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.54759 1.58934C9.87302 1.2639 9.87302 0.736263 9.54759 0.410826C9.22215 0.0853888 8.69451 0.0853888 8.36908 0.410826L3.36908 5.41083C3.04364 5.73626 3.04364 6.2639 3.36908 6.58934L8.36908 11.5893C8.69451 11.9148 9.22215 11.9148 9.54759 11.5893C9.87302 11.2639 9.87302 10.7363 9.54759 10.4108L5.13684 6.00008L9.54759 1.58934ZM1.04159 0.16683C0.581348 0.16683 0.208252 0.539927 0.208252 1.00016V11.0002C0.208252 11.4604 0.581348 11.8335 1.04159 11.8335C1.50182 11.8335 1.87492 11.4604 1.87492 11.0002V1.00016C1.87492 0.539926 1.50182 0.16683 1.04159 0.16683Z"
    />
  </svg>
);

export default JumpLeft;
