import httpClient from 'api/httpClient';
import {
  ArbitratorTerms,
  PriceOptionArbitratorTerms,
  VisibilityTypeArbitratorTerms,
} from 'models/Arbitrations';
import { EducationType } from 'models/User';

type AddTermsParams = {
  priceOption: PriceOptionArbitratorTerms;
  percentage?: number;
  visibilityType: VisibilityTypeArbitratorTerms;
};

const getArbitratorTerms = () =>
  httpClient.get<ArbitratorTerms>(`arbitrator-terms`);

// const createArbitratorTerms = (terms: any) =>
//   httpClient.post(`arbitrator-terms`, { ...terms });

const addEducationTerms = (data: FormData) =>
  httpClient.post<EducationType>(
    `arbitrator-terms-verification/education`,
    data,
  );

const addCertificateTerms = (data: FormData) =>
  httpClient.post(`arbitrator-terms-verification/certificate`, data);

const addLangugageTerms = (data: FormData) =>
  httpClient.post(`arbitrator-terms-verification/language`, data);

const addAnyDocumentTerms = (data: FormData) =>
  httpClient.post(`arbitrator-terms-verification/document`, data);

const addTerms = (params: AddTermsParams) =>
  httpClient.post(`arbitrator-terms`, { ...params });

const updateTerms = (params: AddTermsParams) =>
  httpClient.put(`arbitrator-terms`, { ...params });

export default {
  getArbitratorTerms,
  // createArbitratorTerms,
  addEducationTerms,
  addCertificateTerms,
  addLangugageTerms,
  addAnyDocumentTerms,
  addTerms,
  updateTerms,
};
