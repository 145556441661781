import { ContractState } from 'constants/contract';
import { Contract } from './Contract';
import { Job, JobPost, JobState } from './Job';
import { User } from './User';
import { NotificationEntity } from './Notification';

export const INBOX_ITEM_TYPE = {
  JOB_POST: 'job-post',
  JOB: 'job',
  CONTRACT: 'contract',
} as const;

export type InboxItemType =
  (typeof INBOX_ITEM_TYPE)[keyof typeof INBOX_ITEM_TYPE];

export type InboxItem = {
  id: number;
  commonId?: number;
  type: InboxItemType;
  isFavourite: boolean;
  jobPostId?: number;
  jobId?: number;
  contractId?: number;
  jobPost?: JobPost;
  job?: Job;
  contract?: Contract;
  createdAt: string;
  updatedAt: string;
  numberOfSigns?: number;
  notifications?: NotificationEntity[];
  otherUser?: User;
  state: JobState | ContractState;
  isOwn: boolean;
} & Pick<
  JobPost,
  'mainSkill' | 'hasFreeCancelation' | 'hasDeadlinePolicy' | 'isNegotiable'
>;

export type InboxUrlParams = Partial<{
  inboxItemId: string;
  chatUserId: string;
  id: string;
}>;
