import React from 'react';

export default (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.51835 3.51834C5.1763 1.86043 7.4691 0.833496 10.0002 0.833496C12.5312 0.833496 14.824 1.86042 16.482 3.51835C18.1399 5.17629 19.1668 7.4691 19.1668 10.0002C19.1668 12.5312 18.1399 14.824 16.482 16.482C14.824 18.1399 12.5312 19.1668 10.0002 19.1668C7.4691 19.1668 5.1763 18.1399 3.51835 16.482C1.86043 14.824 0.833496 12.5312 0.833496 10.0002C0.833496 7.4691 1.86044 5.17629 3.51835 3.51834ZM14.7561 8.08942C15.0815 7.76398 15.0815 7.23634 14.7561 6.91091C14.4306 6.58547 13.903 6.58547 13.5776 6.91091L9.16683 11.3217L7.25608 9.41091C6.93065 9.08547 6.40301 9.08547 6.07757 9.41091C5.75214 9.73634 5.75214 10.264 6.07757 10.5894L8.57757 13.0894C8.90301 13.4149 9.43065 13.4149 9.75608 13.0894L14.7561 8.08942Z"
        fill="#1CAD2F"
      />
    </svg>
  );
};
