import React, { useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Collapsible } from 'ncoded-component-library';
import { CollapsibleRef } from 'ncoded-component-library/build/components/molecules/Collapsible/Collapsible.component';
import { VALUES_TO_QUERY_MAPPER } from '../AddArbitratorFilters';
import { ArbitratorFilters } from 'models/Job/Arbitration';
import ChevronIcon from 'icons/ChervonIcon.icon';
import useCallbackRef from 'hooks/useCallbackRef';
import { formatArbitrationFilterValue } from 'utils/arbitration';
import { getSkillsAsLabels } from 'utils/job';

import './ArbitrationSelectedFilters.styles.scss';
import useGetSkillGroups from '../../../../../../../../../hooks/useGetSkillGroups';

const ORDERED_FILTER_FIELDS = Object.keys(
  VALUES_TO_QUERY_MAPPER,
) as (keyof ArbitratorFilters)[];

type ArbitrationSelectedFiltersProps = {
  className?: string;
  title?: React.ReactNode;
  selectedFilters: Partial<ArbitratorFilters>;
};

const ArbitrationSelectedFilters: React.FC<ArbitrationSelectedFiltersProps> = (
  props,
) => {
  const { className, title, selectedFilters } = props;

  const { t } = useTranslation();

  const [collapsible, collapsibleRef] = useCallbackRef<CollapsibleRef>();

  const classes = classNames('anys-arbitration-selected-filters', className);

  const { skillOptions } = useGetSkillGroups();

  const noFiltersAddedMessage = t('General.noFiltersAdded');

  const titleMaybe = useMemo(
    () =>
      title && (
        <label className="anys-arbitration-selected-filters__title">
          {title}
        </label>
      ),
    [title],
  );

  return (
    <div className={classes}>
      {!selectedFilters ? (
        <>
          {titleMaybe}
          <span className="anys-arbitration-selected-filters__no-added">
            {noFiltersAddedMessage}
          </span>
        </>
      ) : (
        <Collapsible
          contentAs="div"
          trigger={
            <div
              className={classNames(
                'anys-arbitration-selected-filters__collapsible-trigger',
                {
                  'anys-arbitration-selected-filters__collapsible-trigger--open':
                    collapsible?.open,
                },
              )}
            >
              {titleMaybe}
              <ChevronIcon color="currentColor" />
            </div>
          }
          className="anys-arbitration-selected-filters__collapsible"
          ref={collapsibleRef}
          defaultOpen
        >
          <ul className="anys-arbitration-selected-filters__collapsible__filters">
            {ORDERED_FILTER_FIELDS.map((fieldName) => {
              const filterValue = formatArbitrationFilterValue(
                t,
                fieldName,
                selectedFilters,
                getSkillsAsLabels(skillOptions, selectedFilters.skills),
              );

              return (
                <li
                  key={fieldName}
                  className="anys-arbitration-selected-filters__collapsible__filters__filter"
                >
                  <span>{t(`General.${fieldName}`)}</span>
                  <span>{filterValue}</span>
                </li>
              );
            })}
          </ul>
        </Collapsible>
      )}
    </div>
  );
};

export default ArbitrationSelectedFilters;
