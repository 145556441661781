import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { Form } from 'react-final-form';
import Search from 'components/Search';
import useUsers from 'hooks/useUsers';
import useInfinitePagination from 'hooks/useInfinitePagination';
import { useTranslation } from 'react-i18next';
import UserList from 'components/UserList';
import { Button } from 'ncoded-component-library';
import { User } from 'models/User';
import { FormApi } from 'final-form';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import {
  DEFAULT_PAGINATION,
  PaginatedResponse,
  PaginationParams,
} from 'models/Pagination';
import { AxiosResponse } from 'axios';
import { Where } from 'router/subrouters/Search/pages/Search/providers/Filters/Filters.provider';
import useInfiniteContainer from 'hooks/useInfiniteContainer';

import './SearchUsersForm.styles.scss';

export type SearchUsersFormValues = {
  selectedUsers: User[];
  search: string;
  selectedUserIds: number[];
};

type SearchUsersFormProps = {
  className?: string;
  numberOfUsers?: number;
  initialValues: Partial<SearchUsersFormValues>;
  title: React.ReactNode;
  searchPlaceholder?: string;
  isArbitratorList?: boolean;
  manipulateWhere?: (where: Where[]) => Where[];
  customFetchReq?: (
    params: PaginationParams,
  ) => Promise<AxiosResponse<PaginatedResponse<User>>>;
  onSubmit: (selectedUsers: User[], selectedUserIds: number[]) => void;
  onCancel: () => void;
};

const SearchUsersForm: React.FC<SearchUsersFormProps> = (props) => {
  const {
    className,
    numberOfUsers,
    initialValues,
    title,
    searchPlaceholder,
    isArbitratorList,
    manipulateWhere,
    customFetchReq,
    onSubmit,
    onCancel,
  } = props;

  const { t } = useTranslation();
  const { getUsers } = useUsers(true);
  const { currentUser } = useContext(CurrentUserContext);
  const scrollContainerRef = useRef<HTMLUListElement>();

  const classes = classNames('anys-search-users-form', className);

  const messages = useMemo(
    () => ({
      cancel: t('General.cancel'),
      confirm: t('General.confirm'),
    }),
    [t],
  );

  const searchInputPlaceholder =
    searchPlaceholder || t('General.searchUsersPlaceholder');

  const [searchString, setSearchString] = useState('');

  const onSearchUsers = useCallback(
    async (currentPage: number, take: number) => {
      if (!currentUser?.id && currentUser?.id !== 0)
        return Promise.resolve({
          items: [] as User[],
          totalPages: 0,
          totalItems: 0,
        });

      const isIdSearch = !Number.isNaN(Number.parseFloat(searchString));

      const where = isIdSearch
        ? [
            {
              id: {
                NOT: currentUser.id,
              },
              anyServiceId: {
                EQUAL: Number.parseFloat(searchString),
              },
            },
          ]
        : [
            {
              id: { NOT: currentUser.id },
              displayName: { ILIKE: `%${searchString}%` },
            },
          ];

      const whereFinal = manipulateWhere ? manipulateWhere(where) : where;

      const queryParams: PaginationParams = {
        $page: currentPage,
        $where: JSON.stringify(whereFinal),
        $relations: 'profileImage,overallSkillScore',
        $take: take,
      };

      const fetchReq = customFetchReq || getUsers;

      const {
        data: { items, totalPages, totalItems },
      } = await fetchReq(queryParams);

      return { items, totalPages, totalItems };
    },
    [currentUser?.id, customFetchReq, getUsers, manipulateWhere, searchString],
  );

  const { items, loading, onContainerScrolled } = useInfinitePagination({
    take: DEFAULT_PAGINATION.take,
    searchString,
    makeRequest: onSearchUsers,
    resetDeps: [searchString],
    debounceTime: 500,
  });

  const { onScroll } = useInfiniteContainer({
    container: scrollContainerRef.current,
    onScroll: onContainerScrolled,
    loading,
  });

  const handleSubmit = useCallback(
    (values: SearchUsersFormValues) => {
      onSubmit(values.selectedUsers, values.selectedUserIds);
    },
    [onSubmit],
  );

  const selectUser = useCallback(
    (
      formApi: FormApi<SearchUsersFormValues>,
      user: User,
      selected: boolean,
      selectedUserIds: number[],
      selectedUsers: User[],
    ) => {
      formApi.batch(() => {
        formApi.change(
          'selectedUserIds',
          selected
            ? [...selectedUserIds, user.id]
            : selectedUserIds.filter((id) => id !== user.id),
        );

        formApi.change(
          'selectedUsers',
          selected
            ? [...selectedUsers, user]
            : selectedUsers.filter(({ id }) => id !== user.id),
        );
      });
    },
    [],
  );

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      render={(formRenderProps) => {
        const {
          handleSubmit,
          values: { selectedUserIds = [], selectedUsers = [] },
          invalid,
          form,
        } = formRenderProps;

        const submitDisabled = invalid || loading || !selectedUserIds?.length;

        return (
          <form onSubmit={handleSubmit} className={classes}>
            <Search
              name="search"
              type="search"
              placeholder={searchInputPlaceholder}
              value={searchString}
              onChange={(e) => setSearchString(e.target.value)}
            />
            <UserList
              title={title}
              users={items}
              isFetching={loading}
              onScroll={onScroll}
              scrollContainerRef={scrollContainerRef}
              onSelectUser={(user, selected) =>
                selectUser(form, user, selected, selectedUserIds, selectedUsers)
              }
              className="anys-search-users-form__arbitrators"
              selectedUserIds={selectedUserIds}
              maxSelectableUsers={numberOfUsers}
              isArbitratorList={isArbitratorList}
            />

            <div className="anys-search-users-form__buttons-wrapper">
              <Button type="submit" variant="solid" disabled={submitDisabled}>
                {messages.confirm}
              </Button>
              <Button type="button" variant="link" onClick={onCancel}>
                <span>{messages.cancel}</span>
              </Button>
            </div>
          </form>
        );
      }}
    />
  );
};

export default SearchUsersForm;
