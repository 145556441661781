import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as InboxChatBoxes } from 'icons/inbox-chat-boxes.svg';

import './EmptyMiddlePreview.styles.scss';
import './EmptyMiddlePreview.styles.responsive.scss';

const InboxComponent = () => {
  const { t } = useTranslation();

  return (
    <div className="anys-empty-middle-preview">
      <div className="anys-empty-middle-preview__content">
        <InboxChatBoxes />

        <span className="anys-empty-middle-preview__content__title">
          {t('General.noItemsSelected')}
        </span>
        <span className="anys-empty-middle-preview__content__desc">
          {t('General.noItemsSelectedDesc')}
        </span>
      </div>
    </div>
  );
};

export default InboxComponent;
