import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import JobState from 'components/JobState';
import SlatePreview from 'components/SlatePreview';
import SelectedSkills from 'components/SelectedSkills';
import { Job, JobPost } from 'models/Job';
import TimeAndPricingPreview from './components/TimeAndPricingPreview';
import TypeOfServicePreview from './components/TypeOfServicePreview';
import ClientCancellationPreview from './components/ClientCancelationPreview';
import DeadlinePolicyPreview from './components/DeadlinePolicyPreview';
import ArbitrationPreview from './components/ArbitrationPreview';
import { useTranslation } from 'react-i18next';
import AttachmentsPreview from './components/AttachmentsPreview';
import TotalNegotiabilityPreview from './components/TotalNegotiabilityPreview';
import JobSign from '../JobSign';
import DeadlinePreview from '../../router/subrouters/JobPost/pages/CreateEditJobPost/components/DeadlinePolicy/DeadlinePreview';
import SummarizedPreview from 'components/JobPostPreview/components/SummarizedPreview';
import { User } from 'models/User';
import { Link, useLocation } from 'react-router-dom';
import TimesIcon from 'icons/Times.icon';
import useInboxLink from 'router/subrouters/Inbox/hooks/useInboxLink';
import { Contract } from 'models/Contract';
import BoostPreview from 'components/BoostPreview';
import {
  getSkillsChanges,
  getTextChanges,
  isPendingChanges,
} from 'utils/job-changes';
import ToolTip from 'components/ToolTip';
import WarningIcon from 'icons/Warning.icon';
import JobChanges from 'components/JobChanges';
import { calcJobPrice } from 'utils/job';
import { convertToMoney } from 'utils/currency';
import { ModalRef } from 'components/Modal';
import UserScheduleModal from './components/UserScheduleModal';
import api from 'api';
import showToast from 'modules/showToast';
import { UserAvailability } from 'models/Availability';

import './JobPostPreview.styles.scss';
import './JobPostPreview.styles.responsive.scss';

type JobPostPreviewProps = {
  className?: string;
  job?: Job;
  jobPost?: JobPost;
  contract?: Contract;
  isFetching: boolean;
  hasClientSigned: boolean;
  hasProviderSigned: boolean;
  client: Partial<User>;
  provider: Partial<User>;
  isCurrentUserClient: boolean;
  actionInProgress: boolean;
  isOwnJob?: boolean;
  isInSummaryModal?: boolean;
  sendToForm?: React.ReactNode;
  handleSign:
    | ((
        hasSigned: boolean,
        commonId?: number,
        jobVersion?: number,
      ) => Promise<void>)
    | ((hasSigned: boolean) => Promise<void>);
};

const JobPostPreview: React.FC<React.PropsWithChildren<JobPostPreviewProps>> = (
  props,
) => {
  const {
    children,
    className,
    job,
    jobPost,
    isFetching,
    hasClientSigned,
    hasProviderSigned,
    client,
    provider,
    isCurrentUserClient,
    actionInProgress,
    contract,
    isOwnJob,
    isInSummaryModal = false,
    sendToForm,
    handleSign,
  } = props;

  const { t } = useTranslation();

  const location = useLocation();

  const [isFetchingAvailability, setIsFetchingAvailability] = useState(false);
  const [userAvailability, setUserAvailability] = useState<UserAvailability>();

  const isInbox = location.pathname?.startsWith('/inbox');

  const { createEntityLink, linkWihoutInboxItem } = useInboxLink();

  const userScheduleModal = useRef<ModalRef>();

  const { isSignedByClient, isSignedByProvider, prevVersion, versionState } =
    job ||
    (contract && {
      ...contract,
      isSignedByClient: true,
      isSignedByProvider: true,
    }) ||
    {};

  const getProperty = useCallback(
    <T extends keyof JobPost & keyof Job & keyof Contract>(name: T) => {
      if (jobPost) return jobPost[name];

      if (job) return job[name];

      if (contract) return contract[name];

      return null;
    },
    [contract, job, jobPost],
  );

  const isNegotiable = getProperty('isNegotiable');

  const id = getProperty('id');
  const type = getProperty('type');
  const title = getProperty('title');
  const description = getProperty('description');

  const isProvideJob = type === 'Provide';

  const isSigned =
    hasClientSigned ||
    hasProviderSigned ||
    isSignedByClient ||
    isSignedByProvider;

  // If we have both signs, it is a contract;
  // if only one, it's an offer
  const state =
    jobPost?.state === 'Draft'
      ? 'Draft'
      : isSignedByClient && isSignedByProvider
        ? 'Contract'
        : isSigned
          ? 'Offer'
          : 'Ad';

  const isUserScheduleDisabled =
    isFetchingAvailability || !userAvailability?.timeslots?.length;

  const otherUser = isProvideJob ? provider : client;

  const mainSkill = getProperty('mainSkill');

  const skills = getProperty('skills');

  const isLockedTimeAndPricing = getProperty('isLockedTimeAndPricing');

  const timeAndPricing = getProperty('timeAndPricing');

  const isLockedTypeOfService = getProperty('isLockedTypeOfService');

  const typeOfService = getProperty('typeOfService');

  const isLockedFreeCancelation = getProperty('isLockedFreeCancelation');

  const freeCancelation = getProperty('freeCancelation');

  const isLockedDeadlinePolicy = getProperty('isLockedDeadlinePolicy');

  const deadlines = getProperty('deadlines');

  const isLockedArbitration = getProperty('isLockedArbitration');

  const arbitrationOptions = getProperty('arbitrationOptions');

  const attachments = getProperty('attachments');

  const boosts = jobPost?.boosts || job?.boosts;

  const editBoostLink = isInbox
    ? `${createEntityLink(
        'edit',
        'job-post',
        id,
        undefined,
        undefined,
        '/boost',
      )}`
    : `/job-post/edit/${id}/boost`;

  const canShowBoostPreview = isOwnJob && !!jobPost;

  const classes = classNames(
    'anys-job-post-preview',
    { 'anys-job-post-preview--unsigned': !isSigned },
    { 'anys-job-post-preview--in-summary-modal': isInSummaryModal },
    className,
  );

  const loadingMsg = t('General.loadingDetails');

  const attachmentsPreview = (
    <AttachmentsPreview
      attachments={attachments}
      boosts={boosts}
      jobTitle={title}
      jobState={state}
      prevVersion={prevVersion}
      versionState={versionState}
      className={classNames({
        'anys-job-post-preview__attachments': isSigned,
      })}
    />
  );

  const deadlinesForPreview = useMemo(
    () =>
      deadlines?.map((d) => ({
        ...d,
        fromDate: d.fromDate ? new Date(d.fromDate) : null,
        toDate: d.toDate ? new Date(d.toDate) : null,
      })),
    [deadlines],
  );

  const titleChanges =
    prevVersion && isPendingChanges(versionState)
      ? getTextChanges(prevVersion.title, title)
      : null;

  const descChanges =
    prevVersion && isPendingChanges(versionState)
      ? getTextChanges(prevVersion.description, description)
      : null;

  const skillsChanges =
    prevVersion && isPendingChanges(versionState)
      ? getSkillsChanges(
          t,
          {
            mainSkill: prevVersion.mainSkill?.name,
            skills: prevVersion.skills?.map((skill) => skill.name),
          },
          {
            mainSkill: mainSkill?.name,
            skills: skills?.map((skill) => skill.name),
          },
        )
      : null;

  const skillChangesTooltip = skillsChanges ? (
    <ToolTip
      t={t}
      tooltipName="skills-changes"
      icon={WarningIcon}
      className="anys-job-post-preview__skills-changes"
    >
      <JobChanges
        changedFrom={[skillsChanges.changedFrom]}
        changedTo={[skillsChanges.changedTo]}
      />
    </ToolTip>
  ) : null;

  const selectedSkills = (
    <SelectedSkills
      mainSkillStyle="outline"
      skills={skills}
      mainSkill={mainSkill}
    />
  );

  const jobPrice = timeAndPricing
    ? calcJobPrice({
        ...timeAndPricing,
        startDate: timeAndPricing?.startDate
          ? new Date(timeAndPricing.startDate)
          : null,
        endDate: timeAndPricing?.endDate
          ? new Date(timeAndPricing.endDate)
          : null,
        price: convertToMoney(timeAndPricing.price, timeAndPricing.currency),
      })
    : 0;

  const onOpenUserSchedule = useCallback(() => {
    userScheduleModal.current.open();
  }, []);

  useEffect(() => {
    if (!otherUser?.id) return;

    const getUserAvailability = async () => {
      setIsFetchingAvailability(true);

      try {
        const { data } = await api.availability.getAvailability(otherUser.id);

        setUserAvailability(data);
      } catch (e) {
        showToast('error', t('General.error'));
      } finally {
        setIsFetchingAvailability(false);
      }
    };

    getUserAvailability();
  }, [otherUser?.id, t]);

  return (
    <div className={classes}>
      {(!jobPost || !job || !contract) && isFetching ? (
        <p className="anys-job-post-preview__loading">{loadingMsg}</p>
      ) : (
        <>
          <div className="anys-job-post-preview__title">
            <h1>{title}</h1>
            {titleChanges && (
              <ToolTip
                t={t}
                tooltipName="title-changes"
                icon={WarningIcon}
                className="anys-job-post-preview__title__tooltip"
              >
                <JobChanges
                  changedFrom={[titleChanges.from]}
                  changedTo={[titleChanges.to]}
                />
              </ToolTip>
            )}

            <div className="anys-job-post-preview__title__state-and-skill">
              <JobState state={state} />
              {isSigned && (
                <div>
                  {skillChangesTooltip}
                  {selectedSkills}
                </div>
              )}
            </div>
            {!isInSummaryModal && (
              <Link
                to={isInbox ? linkWihoutInboxItem : '/provide'}
                className="anys-job-post-preview__title__close"
              >
                <TimesIcon />
              </Link>
            )}
          </div>

          <div className="anys-job-post-preview__content">
            {canShowBoostPreview && (
              <BoostPreview
                positionBoost={jobPost?.positionBoost}
                editBoostLink={editBoostLink}
                designBoost={jobPost?.boosts?.[0]}
              />
            )}

            {description && (
              <section>
                {descChanges ? (
                  <ToolTip
                    t={t}
                    tooltipName="description-changes"
                    icon={WarningIcon}
                    className="anys-job-post-preview__desc-tooltip"
                  >
                    <JobChanges
                      changedFrom={[descChanges.from]}
                      changedTo={[descChanges.to]}
                      valueParser={(value) => {
                        if (typeof value !== 'string') return '';

                        return <SlatePreview>{value}</SlatePreview>;
                      }}
                    />
                  </ToolTip>
                ) : null}
                <SlatePreview>{description}</SlatePreview>
              </section>
            )}

            {isSigned ? (
              <>
                {attachmentsPreview}
                <SummarizedPreview
                  arbitrationOptions={arbitrationOptions}
                  typeOfService={typeOfService}
                  freeCancelation={freeCancelation}
                  timeAndPricing={timeAndPricing}
                  prevVersion={prevVersion}
                  versionState={versionState}
                  isLockedArbitration={isLockedArbitration}
                  isLockedFreeCancelation={isLockedFreeCancelation}
                  isLockedTimeAndPricing={isLockedTimeAndPricing}
                  isLockedTypeOfService={isLockedTypeOfService}
                  isOwnJob={isOwnJob}
                  onOpenSchedule={onOpenUserSchedule}
                  isUserScheduleDisabled={isUserScheduleDisabled}
                  isInSummaryModal={isInSummaryModal}
                />
                {deadlines?.length > 0 && (
                  <section>
                    <div className="fw-600">{t('DeadlinePolicy.title')}</div>
                    <DeadlinePreview
                      deadlines={deadlinesForPreview}
                      prevVersion={prevVersion}
                      className="anys-job-post-preview__deadlines"
                      versionState={versionState}
                      isLockedDeadlinePolicy={isLockedDeadlinePolicy}
                      jobEndDate={
                        timeAndPricing?.endDate
                          ? new Date(timeAndPricing.endDate)
                          : null
                      }
                    />
                  </section>
                )}
              </>
            ) : (
              <>
                <section>
                  {skillChangesTooltip}
                  {selectedSkills}
                </section>
                <TimeAndPricingPreview
                  timeAndPricing={timeAndPricing}
                  prevTimeAndPricing={prevVersion?.timeAndPricing}
                  versionState={versionState}
                  prevIsLockedTimeAndPricing={
                    prevVersion?.isLockedTimeAndPricing
                  }
                  isLockedTimeAndPricing={isLockedTimeAndPricing}
                  isOwnJob={isOwnJob}
                  onOpenSchedule={onOpenUserSchedule}
                  isUserScheduleDisabled={isUserScheduleDisabled}
                />
                <TypeOfServicePreview
                  typeOfService={typeOfService}
                  prevVersion={prevVersion}
                  versionState={versionState}
                  isLockedTypeOfService={isLockedTypeOfService}
                />
                <ClientCancellationPreview
                  freeCancelation={freeCancelation}
                  prevVersion={prevVersion}
                  versionState={versionState}
                  isLockedFreeCancelation={isLockedFreeCancelation}
                  jobPrice={jobPrice}
                  currency={timeAndPricing?.currency}
                />
                <DeadlinePolicyPreview
                  deadlines={deadlines}
                  prevVersion={prevVersion}
                  versionState={versionState}
                  isLockedDeadlinePolicy={isLockedDeadlinePolicy}
                  jobEndDate={
                    timeAndPricing?.endDate
                      ? new Date(timeAndPricing.endDate)
                      : null
                  }
                />
              </>
            )}

            {!isSigned && (
              <>
                <ArbitrationPreview
                  arbitrationOptions={arbitrationOptions}
                  timeAndPricing={timeAndPricing}
                  prevArbitrationOptions={prevVersion?.arbitrationOptions}
                  versionState={versionState}
                  isLockedArbitration={isLockedArbitration}
                  prevIsLockedArbitration={prevVersion?.isLockedArbitration}
                />
                {attachmentsPreview}
                <TotalNegotiabilityPreview
                  isNegotiable={isNegotiable}
                  prevVersion={prevVersion}
                  versionState={versionState}
                />
              </>
            )}

            {!isInSummaryModal && (
              <JobSign
                className={classNames('anys-job-post-preview__sign-section', {
                  'anys-job-post-preview__sign-section--signed': isSigned,
                })}
                firstAvatar="client"
                hasProviderSigned={hasProviderSigned}
                hasClientSigned={hasClientSigned}
                jobId={id}
                isClientSignDisabled={!provider}
                isProviderSignDisabled={false}
                client={client}
                provider={provider}
                handleSign={handleSign}
                isCurrentUserClient={isCurrentUserClient}
                inProgress={actionInProgress}
              />
            )}
            {sendToForm}
          </div>
          {children}
        </>
      )}
      <UserScheduleModal
        userScheduleModalRef={userScheduleModal}
        user={otherUser}
        closeModal={() => userScheduleModal.current?.close()}
        timeAndPricing={timeAndPricing}
      />
    </div>
  );
};

export default JobPostPreview;
