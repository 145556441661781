import { OverlayRef } from 'ncoded-component-library/build/components/atoms/Overlay/Overlay.component';
import { useEffect } from 'react';

//This is special case, bcs requestPaymentModal contain AmountSlider
//if drag value out of modal it will close modal
//better solution will be to add this in ncoded component library but not we dont have capacity
//and if we add this in Modal component here in project it will fail in loop bcs we remeber modal name in query params
const useModalCloseOverride = (modalRef: OverlayRef) => {
  useEffect(() => {
    const modalOverlay = document.getElementsByClassName('overlay__content')[0];
    const modalContent = document.getElementsByClassName(
      'ncoded-modal__content',
    )[0];

    const closeModal = (ev: any) => {
      !(modalContent as HTMLElement).contains(ev.target) && modalRef.close();
    };

    modalOverlay?.addEventListener('mousedown', closeModal);

    return () => {
      return modalOverlay?.addEventListener('mousedown', closeModal);
    };
  }, [modalRef]);
};

//IF YOU USE THIS HOOK YOU MUST OVERIDE onOverlayClose IN MODAL!
export default useModalCloseOverride;
