import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

export default (props: IconProps) => {
  const { ...restOfProps } = props;
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restOfProps}
    >
      <rect
        x="1"
        y="1"
        width="34"
        height="34"
        rx="17"
        fill="url(#paint0_linear_5487_60489)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0317 10C18.5839 10.0007 19.0311 10.449 19.0303 11.0013L19.0225 17H25C25.5523 17 26 17.4477 26 18C26 18.5523 25.5523 19 25 19H19.0199L19.012 25.0013C19.0113 25.5536 18.563 26.0007 18.0107 26C17.4584 25.9993 17.0113 25.551 17.012 24.9987L17.0199 19H11C10.4477 19 10 18.5523 10 18C10 17.4477 10.4477 17 11 17H17.0225L17.0304 10.9987C17.0311 10.4464 17.4794 9.99928 18.0317 10Z"
        fill="white"
      />
      <rect
        x="1"
        y="1"
        width="34"
        height="34"
        rx="17"
        stroke="white"
        strokeWidth="2"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5487_60489"
          x1="2"
          y1="34"
          x2="34.5581"
          y2="33.4218"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A761F5" />
          <stop offset="1" stopColor="#50A8FA" />
        </linearGradient>
      </defs>
    </svg>
  );
};
