import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

export default (props: IconProps) => (
  <svg
    width="19"
    height="15"
    viewBox="0 0 19 15"
    fill="currentColor"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.974854 1.97485C0.974854 1.42257 1.42257 0.974854 1.97485 0.974854H17.9749C18.5272 0.974854 18.9749 1.42257 18.9749 1.97485C18.9749 2.52714 18.5272 2.97485 17.9749 2.97485H1.97485C1.42257 2.97485 0.974854 2.52714 0.974854 1.97485ZM0.974854 7.97485C0.974854 7.42257 1.42257 6.97485 1.97485 6.97485H17.9749C18.5272 6.97485 18.9749 7.42257 18.9749 7.97485C18.9749 8.52714 18.5272 8.97485 17.9749 8.97485H1.97485C1.42257 8.97485 0.974854 8.52714 0.974854 7.97485ZM0.974854 13.9749C0.974854 13.4226 1.42257 12.9749 1.97485 12.9749H17.9749C18.5272 12.9749 18.9749 13.4226 18.9749 13.9749C18.9749 14.5271 18.5272 14.9749 17.9749 14.9749H1.97485C1.42257 14.9749 0.974854 14.5271 0.974854 13.9749Z"
    />
  </svg>
);
