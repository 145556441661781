import React, { useCallback, useMemo } from 'react';
import { Field, FieldRenderProps, UseFieldConfig } from 'react-final-form';
import { InputProps } from 'components/Input/Input.component';
import formValidators from 'utils/formValidators';
import InputAutocompleteLocation, {
  InputAutocompleteLocationProps,
} from './InputAutocompleteLocation.component';
import { PlaceFormat } from 'models/User';

type InputFieldProps = InputProps &
  Omit<InputAutocompleteLocationProps, 'value'> &
  UseFieldConfig<string, string>;

type InputFieldComponentProps = InputFieldProps & {
  value: PlaceFormat;
} & FieldRenderProps<string, HTMLElement>;

const InputFieldComponent: React.FC<InputFieldComponentProps> = (props) => {
  const { input, meta, onChange: propsOnChange, ...restOfProps } = props;

  const error = useMemo(() => formValidators.getErrorFromMeta(meta), [meta]);

  const onChange: InputAutocompleteLocationProps['onChange'] = useCallback(
    (place) => {
      input.onChange(place);

      propsOnChange?.(place);
    },
    [input, propsOnChange],
  );

  return (
    <InputAutocompleteLocation
      {...input}
      {...restOfProps}
      {...error}
      onChange={onChange}
    />
  );
};

const InputAutocompleteLocationField: React.FC<InputFieldProps> = (props) => (
  <Field component={InputFieldComponent} {...props} />
);

export default InputAutocompleteLocationField;
