import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Skill } from 'models/Skills';
import GradientText from 'components/GradientText';

import './SelectedSkills.styles.scss';

type SelectedSkillsProps = {
  className?: string;
  skills: Skill[];
  mainSkill?: Skill;
  mainSkillStyle?: 'outline' | 'fill';
  shownSkills?: number;
};

const SelectedSkills: React.FC<SelectedSkillsProps> = (props) => {
  const {
    className,
    skills = [],
    mainSkill,
    mainSkillStyle,
    shownSkills,
  } = props;

  const classes = classNames('anys-selected-skills', className);

  const isMainSkillOutline = mainSkillStyle === 'outline';

  const skillsWithoutMain = useMemo(() => {
    const skillsFinal = shownSkills ? skills?.slice(0, shownSkills) : skills;

    return mainSkill?.id
      ? skillsFinal?.filter((skillFinal) =>mainSkill?.id ? mainSkill?.id !== skillFinal?.id : mainSkill?.name !== skillFinal?.name)
      : skillsFinal?.filter(
          (skillFinal) => mainSkill?.name !== skillFinal?.name,
        );
  }, [mainSkill?.id, mainSkill?.name, shownSkills, skills]);

  return (
    <ul className={classes}>
      {mainSkill && (
        <li
          className={classNames(
            'anys-selected-skills__skill',
            'anys-selected-skills__skill--main',
            {
              'anys-selected-skills__skill--main--outline': isMainSkillOutline,
            },
          )}
        >
          {isMainSkillOutline ? (
            <GradientText gradientType="pink">{mainSkill.name}</GradientText>
          ) : (
            <span>{mainSkill.name}</span>
          )}
        </li>
      )}
      {skillsWithoutMain?.map(({ name, id }) => (
        <li key={id} className="anys-selected-skills__skill">
          <span>{name}</span>
        </li>
      ))}
    </ul>
  );
};

export default SelectedSkills;
