import React, { useId } from 'react';

type IconProps = React.SVGProps<SVGSVGElement> & {
  showGradient?: boolean;
  fillBox?: boolean;
};

export default ({
  showGradient = true,
  fillBox = false,
  ...props
}: IconProps) => {
  const lgId1 = useId();
  const lgId2 = useId();

  return (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" {...props}>
      <path
        d="M15.2222 9.09998H2.77778C1.79594 9.09998 1 9.90586 1 10.9V17.2C1 18.1941 1.79594 19 2.77778 19H15.2222C16.2041 19 17 18.1941 17 17.2V10.9C17 9.90586 16.2041 9.09998 15.2222 9.09998Z"
        stroke={showGradient ? `url(#${lgId1})` : 'currentColor'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={fillBox ? 'currentColor' : 'none'}
      />
      <path
        d="M4.55518 9.1V5.5C4.55518 4.30653 5.02343 3.16193 5.85692 2.31802C6.69042 1.47411 7.82088 1 8.99962 1C10.1784 1 11.3088 1.47411 12.1423 2.31802C12.9758 3.16193 13.4441 4.30653 13.4441 5.5V9.1"
        stroke={showGradient ? `url(#${lgId2})` : 'currentColor'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id={lgId1}
          x1="1"
          y1="19"
          x2="17.2708"
          y2="18.533"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A761F5" />
          <stop offset="1" stopColor="#50A8FA" />
        </linearGradient>
        <linearGradient
          id={lgId2}
          x1="4.55518"
          y1="9.1"
          x2="13.5985"
          y2="8.92375"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A761F5" />
          <stop offset="1" stopColor="#50A8FA" />
        </linearGradient>
      </defs>
    </svg>
  );
};
