import { SocketEventNames } from 'constants/socket';
import { Socket } from 'socket.io-client';

export type SocketService<T extends string = SocketEventNames> = {
  socket: Socket;
  consumers: Partial<Record<T, Array<(data: any) => void>>>;
  connect: () => Promise<unknown>;
  disconnect: () => void;
  addListener: <P = any>(event: T, callback: (data: P) => void) => void;
  removeListener: (event: T, callback: (data: any) => void) => void;
  removeAllListeners: () => void;
  sendEvent: <P = any>(event: T, payload?: P) => void;
};

export default <T extends string = SocketEventNames>(
  connect: () => Promise<unknown>,
): SocketService<T> => ({
  socket: null,
  consumers: {},
  connect,
  disconnect() {
    this.removeAllListeners();
    if (this.socket) {
      this.socket.disconnect();
    }
  },
  addListener(event, callback) {
    if (!this.consumers[event]) {
      this.consumers[event] = [callback];
    } else this.consumers[event].push(callback);
  },
  removeListener(event, callback) {
    const callbackArr = this.consumers[event];
    if (!callbackArr) return;
    this.consumers[event] = callbackArr.filter((cb) => cb !== callback);
  },
  removeAllListeners() {
    const consumers = Object.entries<((data: any) => void)[]>(this.consumers);

    consumers.forEach(([event, callbacks]) => {
      if (callbacks) {
        callbacks.forEach((callback) =>
          this.socket.removeListener(event, callback),
        );
      }
    });
    this.consumers = {};
  },
  sendEvent(eventName, eventPayload) {
    if (!this.socket) {
      throw new Error('Socket has to be initialized before events are sent');
    }
    this.socket.emit(eventName, eventPayload);
  },
});
