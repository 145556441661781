import React from 'react';
import { ExtractPropTypes } from 'types';
import { Input as InputNcLib } from 'ncoded-component-library';
import classNames from 'classnames';

import './RadioButtonChoice.styles.scss';

export type RadioButtonChoiceProps = {
  className?: string;
  label?: React.ReactNode;
} & ExtractPropTypes<typeof InputNcLib>;

const RadioButtonChoice: React.FC<RadioButtonChoiceProps> = (
  props: RadioButtonChoiceProps,
): JSX.Element => {
  const { className, label, checked, onChange, ...rest } = props;

  const classes = classNames('anys-radio-button', className);

  return (
    <label className={classes}>
      <input type="radio" checked={checked} onChange={onChange} {...rest} />
      <span className="anys-radio-button__button">{label}</span>
    </label>
  );
};

export default RadioButtonChoice;
