import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

const ArbitrationIcon = (props: IconProps) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.2172 7.55626C14.6021 7.16016 14.593 6.52706 14.1969 6.14219C13.8008 5.75731 13.1677 5.7664 12.7828 6.16249L8.46111 10.6101L6.17741 8.50707C5.77115 8.13295 5.13852 8.159 4.7644 8.56526C4.39027 8.97152 4.41632 9.60415 4.82259 9.97828L7.82244 12.7408C8.22102 13.1079 8.83943 13.0907 9.21704 12.7021L14.2172 7.55626Z"
        fill="currentColor"
        fillOpacity="0.85"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 3C0.5 1.61929 1.61929 0.5 3 0.5H16C17.3807 0.5 18.5 1.6193 18.5 3V16C18.5 17.3807 17.3807 18.5 16 18.5H3C1.6193 18.5 0.5 17.3807 0.5 16V3ZM3 2.5C2.72386 2.5 2.5 2.72386 2.5 3V16C2.5 16.2762 2.72385 16.5 3 16.5H16C16.2762 16.5 16.5 16.2762 16.5 16V3C16.5 2.72385 16.2762 2.5 16 2.5H3Z"
        fill="currentColor"
        fillOpacity="0.85"
      />
      <path
        d="M20.9999 4.50098C21.5522 4.50098 21.9999 4.94869 21.9999 5.50098V20C21.9999 21.1045 21.1045 22 19.9999 22H5.50171C4.94942 22 4.50171 21.5523 4.50171 21C4.50171 20.4477 4.94942 20 5.50171 20H19.9999V5.50098C19.9999 4.94869 20.4476 4.50098 20.9999 4.50098Z"
        fill="currentColor"
        fillOpacity="0.85"
      />
    </svg>
  );
};

export default ArbitrationIcon;
