import { IconProps } from 'models/Icon';
import React from 'react';

const QuestionMark: React.FC<IconProps> = (props) => {
  return (
    <svg
      width="21"
      height="36"
      viewBox="0 0 21 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.23028 24.9848H9.93032L9.98202 23.201C9.98202 20.8566 11.4813 20.245 13.601 19.5825C15.7274 18.9862 17.5989 17.7237 18.9328 15.9859C20.2666 14.248 20.9903 12.1293 20.9942 9.95008C21.0408 8.61955 20.8066 7.29398 20.3066 6.05727C19.8066 4.82056 19.0515 3.69965 18.0893 2.76547C17.1271 1.8313 15.9786 1.10421 14.7165 0.63023C13.4545 0.156249 12.1064 -0.0543028 10.7575 0.0119004C9.33203 -0.0187319 7.91509 0.23639 6.59262 0.761792C5.27015 1.2872 4.06973 2.07193 3.06416 3.0684C2.05858 4.06487 1.26882 5.25231 0.742759 6.55869C0.216701 7.86508 -0.0346854 9.26319 0.00384507 10.6682H4.86368C4.84523 9.89245 4.98404 9.12084 5.27199 8.39862C5.55995 7.67641 5.99124 7.01812 6.5406 6.46233C7.08996 5.90655 7.74633 5.46445 8.47124 5.16195C9.19616 4.85945 9.97504 4.70263 10.7622 4.70068C11.4721 4.66011 12.1825 4.77067 12.8452 5.02485C13.5079 5.27902 14.1073 5.67085 14.6027 6.1737C15.0981 6.67656 15.4779 7.27866 15.7162 7.93907C15.9546 8.59949 16.0459 9.30275 15.984 10.001C15.9527 11.1313 15.554 12.2217 14.8466 13.1118C14.1392 14.0018 13.1605 14.6443 12.0547 14.9447L10.3486 15.6118C8.67826 16.0086 7.21993 17.0092 6.26245 18.4156C5.30497 19.8219 4.91814 21.5315 5.17858 23.2056L5.23028 24.9848ZM7.5568 35.9979C8.01704 36.014 8.47587 35.939 8.9062 35.7773C9.33652 35.6156 9.72958 35.3705 10.0622 35.0564C10.3947 34.7424 10.6601 34.3659 10.8425 33.949C11.0249 33.5322 11.1206 33.0835 11.1241 32.6295C11.12 32.176 11.0238 31.7278 10.8411 31.3115C10.6584 30.8952 10.393 30.5192 10.0605 30.2056C9.72798 29.8921 9.33512 29.6474 8.90511 29.486C8.4751 29.3246 8.01664 29.2497 7.5568 29.2658C7.09696 29.2497 6.6385 29.3246 6.20849 29.486C5.77848 29.6474 5.38563 29.8921 5.05311 30.2056C4.7206 30.5192 4.45517 30.8952 4.27249 31.3115C4.08981 31.7278 3.99358 32.176 3.98947 32.6295C3.99295 33.0835 4.08873 33.5322 4.27113 33.949C4.45353 34.3659 4.71887 34.7424 5.05144 35.0564C5.38402 35.3705 5.77708 35.6156 6.20741 35.7773C6.63773 35.939 7.09657 36.014 7.5568 35.9979Z"
        fill="white"
      />
    </svg>
  );
};

export default QuestionMark;
