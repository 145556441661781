import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { Form, FormSpy } from 'react-final-form';
import { Button } from 'ncoded-component-library';
import SelectField from 'components/SelectField';
import RadioGroupField from 'components/RadioGroupField';
import InputField from 'components/InputField';
import Modal, { ModalRef } from 'components/Modal';
import FavouriteFilters from './components/FavouriteFilters';
import { Skill, SkillGroupWithSkills } from 'models/Skills';
import ChevronIcon from 'icons/ChervonIcon.icon';
import NeedProvideContext from 'providers/NeedProvide/NeedProvide.context';
import InputAutocompleteLocationField from 'components/InputAutocompleteLocation/InputAutocompleteLocationField.component';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import Aside from 'components/Aside';
import PriceRangeField from 'components/JobFilters/components/PriceRangeField';
import {
  convertSubUnitToUnit,
  convertToMoney,
  convertUnitToSubUnit,
  formatMoney,
  unitDivisor,
} from 'utils/currency';
import useCurrentLocation from 'hooks/useCurrentLocation';
import { FormApi, FormState } from 'final-form';
import JobFilters from 'components/JobFilters';
import SkillsModal from 'router/subrouters/Search/pages/Search/components/SkillsModal';
import {
  FormValue,
  jobFiltersTransformations,
} from 'components/JobFilters/JobFilters.component';
import { SkillsField } from 'router/subrouters/Search/pages/Search/components/FiltersModal/FiltersModal.component';
import useFiltersForm from 'hooks/useFiltersForm';
import filtersForm from 'utils/filtersForm';
import useSelectOptions from 'hooks/useSelectOptions';
import formValidators from 'utils/formValidators';
import { FilterContext } from 'router/subrouters/Search/pages/Search/providers/Filters/Filters.provider';
import useQueryParams from 'hooks/useQueryParams';
import { NeedProvideSearchParams } from 'providers/NeedProvide/hooks/useNeedProvideSearch';
import MobileFilters from './components/MobileFilters';
import {
  defaultDbNames,
  defaultParses,
  defaultTransformations,
  NeedProvideFiltersFormValues,
  NEED_PROVIDE_FILTERS,
  typeOfServiceInputParses,
} from '../../utils/filters';
import useMatchMedia from 'hooks/useMatchMedia';

import './NeedProvideFilters.styles.scss';
import './NeedProvideFilters.styles.responsive.scss';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';

type NeedProvideFiltersProps = {
  className?: string;
  skillGroups: SkillGroupWithSkills[];
  allSkills?: Skill[];
  activeFiltersCount: number;

  setIsReferralOpen: (isOpen: boolean) => void;
};

const NeedProvideFilters: React.FC<NeedProvideFiltersProps> = (props) => {
  const {
    className,
    skillGroups,
    allSkills,
    activeFiltersCount,
    setIsReferralOpen,
  } = props;

  const { t } = useTranslation();
  const isPhablet = useMatchMedia('isPhablet');

  const { favouriteFilters, priceRange, createFavouriteFilter } =
    useContext(NeedProvideContext);
  const { getParamsOfInterest, getWhere } = useContext(FilterContext);
  const { currentUser } = useContext(CurrentUserContext);
  const {
    params: { favouriteFilter, $currentLocation },
    setQueryParam,
  } = useQueryParams<NeedProvideSearchParams>();

  // responsive only
  const [filtersOpen, setFiltersOpen] = useState(false);

  const advancedFiltersFormApi = useRef<FormApi<FormValue>>();
  const advancedFiltersRef = useRef<ModalRef>();
  const jobFiltersAsQuery = useRef<string>(null);
  //
  const saveFilterModalRef = useRef<ModalRef>(null);
  const saveFilterFormApi = useRef<FormApi<{ filterName: string }>>();
  //
  const skillsModalRef = useRef<ModalRef>();

  const [skillsModalOpenFor, setSkillsModalOpenFor] = useState<{
    initialValues: number[];
    for: 'form' | 'filters';
  }>({ initialValues: [], for: undefined });

  const needProvideFormApi =
    useRef<FormApi<Partial<NeedProvideFiltersFormValues>>>();

  const favouriteFiltersModalRef = useRef<ModalRef>();

  const { location: currentLocation, state: currentLocationState } =
    useCurrentLocation({ callInitially: !$currentLocation });

  const {
    signOptions,
    userTypeOptions,
    overallRatingOptions,
    favouriteFiltersOptions,
  } = useSelectOptions(favouriteFilters);

  const defaultValues: Partial<NeedProvideFiltersFormValues> = useMemo(
    () => ({
      languages: null,
      skills: [],
      typeOfService: null,
      sign: 'see-all',
      rating: 'All',
      userType: 'see-all',
      price: null,
      numberOfJobs: null,
    }),
    [],
  );

  const parses = useMemo(
    () => ({
      ...defaultParses,
      ...(!isPhablet ? { typeOfService: typeOfServiceInputParses } : {}),
    }),
    [isPhablet],
  );

  const { initialValues, onSubmit } = useFiltersForm({
    dbNames: defaultDbNames,
    defaultValues,
    parses,
    transformations: defaultTransformations,
    // onApply,
  });

  const classes = classNames('anys-need-provide-filters', className);

  const messages = useMemo(
    () => ({
      title: t('NeedProvideFilters.filters'),
      edit: t('General.edit'),
      advancedFilters: t('NeedProvideFilters.advancedFilters'),
      favourites: t('General.favourites'),
      selectFavouriteFilters: t('NeedProvideFilters.selectFavouriteFilters'),
      offers: t('General.offers'),
      skill: t('General.skill'),
      selectSkill: t('NeedProvideFilters.selectSkill'),
      price: t('General.price'),
      location: t('General.location'),
      locationPlaceholder: t('General.locationPlaceholder'),
      sign: t('General.sign'),
      userType: t('General.userType'),
      users: t('General.users'),
      saveFilters: t('NeedProvideFilters.saveFilters'),
      filter: t('NeedProvideFilters.filter'),
      overallRating: t('NeedProvideFilters.overallRating'),
      minimumJobs: t('NeedProvideFilters.minimumJobs'),
      enterNumberOfJobs: t('NeedProvideFilters.enterNumberOfJobs'),
      saveFilter: t('NeedProvideFilters.saveFilter'),
      filterName: t('FavouriteFilters.filterName'),
      enterFilterName: t('NeedProvideFilters.enterFilterName'),
      done: t('General.done'),
      cancel: t('General.cancel'),
      save: t('General.save'),
      or: t('General.or'),
      browseFilters: t('Filters.browse'),
      valueRequired: t('General.valueRequired'),
    }),
    [t],
  );

  const initialFormValues: Partial<NeedProvideFiltersFormValues> = useMemo(
    () => ({
      ...initialValues,
      favouriteFilter,
    }),
    [favouriteFilter, initialValues],
  );

  const formatFilterPrice = useCallback(
    (val: number) => formatMoney(t, convertToMoney(val)),
    [t],
  );

  const openSaveFilterModal = useCallback(() => {
    const { values } = advancedFiltersFormApi.current?.getState() || {};

    if (!values) return;

    const transformedFilters = filtersForm.transformFilterValues(
      values,
      false,
      jobFiltersTransformations,
    );

    const search: Record<string, string> = {};

    Object.keys(transformedFilters).forEach((filterName) => {
      const filterValue =
        transformedFilters[filterName as keyof typeof transformedFilters];

      if (filterValue) search[filterName] = filterValue;
    });

    const poi = getParamsOfInterest(search);

    const where = getWhere(poi);

    jobFiltersAsQuery.current = '$where=' + JSON.stringify(where);

    saveFilterModalRef.current?.open();
  }, [getParamsOfInterest, getWhere]);

  const getFavouriteFilterValues = useCallback(
    (filterId: string) => {
      const selectedFavFilter = favouriteFilters?.find(
        ({ id }) => id === Number.parseInt(filterId),
      );

      if (!selectedFavFilter?.filterQueryFormValues) return;

      const { filterQueryFormValues } = selectedFavFilter;

      const transformedJobFilters = filtersForm.transformFilterValues(
        filterQueryFormValues,
        false,
        jobFiltersTransformations,
      );

      const jobFiltersToNeedProvide = Object.keys(transformedJobFilters).reduce(
        (acc, key) => {
          const value =
            transformedJobFilters[key as keyof typeof transformedJobFilters];

          if (!value || !(value as any)?.length) return acc;

          const isArray = key.includes('[]');

          const keyFinal = isArray ? key.replace('[]', '') : key;

          return {
            ...acc,
            [keyFinal]: filtersForm.transformParamValue(
              key,
              value,
              defaultParses,
            ),
          };
        },
        {},
      );

      return jobFiltersToNeedProvide;
    },
    [favouriteFilters],
  );

  const onChangeFilters = useMemo(
    () =>
      debounce((formState: FormState<NeedProvideFiltersFormValues>) => {
        if (
          (!formState.dirty && !formState.dirtySinceLastSubmit) ||
          !formState.valid
        )
          return;

        if (formState.dirtyFields.favouriteFilter) {
          const jobFiltersToNeedProvide = getFavouriteFilterValues(
            formState.values.favouriteFilter,
          );

          onSubmit({ ...formState.values, ...jobFiltersToNeedProvide });
        } else {
          onSubmit(formState.values);
        }
      }, 300),
    [onSubmit, getFavouriteFilterValues],
  );

  const onSkillsApply = useCallback(
    (skillIds: number[]) => {
      if (skillsModalOpenFor.for === 'filters') {
        advancedFiltersFormApi.current?.change('skills', skillIds);
      } else {
        needProvideFormApi.current?.change('skills', skillIds);
      }

      skillsModalRef.current?.close();
    },
    [skillsModalOpenFor],
  );

  const handleSaveFilter = useCallback(
    async (values: { filterName: string }) => {
      if (!jobFiltersAsQuery.current) return;

      const { filterName } = values;

      await createFavouriteFilter(
        filterName,
        jobFiltersAsQuery.current,
        advancedFiltersFormApi.current.getState().values,
      );

      jobFiltersAsQuery.current = null;

      saveFilterModalRef.current.close();
    },
    [createFavouriteFilter],
  );

  // Set favourite filter form values on phablet
  useEffect(() => {
    if (!favouriteFilter || !isPhablet) return;

    const jobFiltersToNeedProvide = getFavouriteFilterValues(favouriteFilter);

    onSubmit({
      ...needProvideFormApi.current.getState().values,
      ...jobFiltersToNeedProvide,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favouriteFilter]);

  useEffect(() => {
    if (currentLocationState !== 'partial' || $currentLocation) return;

    setQueryParam(
      '$currentLocation',
      `${currentLocation.lat},${currentLocation.lng}`,
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLocationState]);

  return (
    <div className={classes}>
      {currentUser?.id && (
        <Button
          type="button"
          onClick={() => setIsReferralOpen(true)}
          className="anys-need-provide-filters__invite"
          variant="link"
        >
          {t('Referral.title')}
        </Button>
      )}
      {isPhablet && (
        <MobileFilters
          allSkills={allSkills}
          setAdvancedFiltersOpen={() => advancedFiltersRef.current.open()}
          activeFiltersCount={activeFiltersCount}
          openFavouritesModal={() => favouriteFiltersModalRef.current?.open()}
        />
      )}

      <Aside
        open={filtersOpen}
        className="anys-need-provide-filters__aside"
        onClose={() => setFiltersOpen(false)}
      >
        <label className="anys-need-provide-filters__title">
          {messages.title}
        </label>
        <Button
          variant="link"
          styleType="secondary"
          type="button"
          className="anys-need-provide-filters__advanced"
          onClick={() => advancedFiltersRef.current.open()}
        >
          <span>{messages.advancedFilters}</span>
        </Button>
        <Form
          onSubmit={onSubmit}
          initialValues={initialFormValues}
          render={(formRenderProps) => {
            const { handleSubmit, values, form } = formRenderProps;

            const { price, skills } = values;

            needProvideFormApi.current = form;

            return (
              <form
                onSubmit={handleSubmit}
                className="anys-need-provide-filters__form"
              >
                <FormSpy
                  onChange={onChangeFilters}
                  subscription={{
                    values: true,
                    dirty: true,
                    dirtyFields: true,
                    dirtySinceLastSubmit: true,
                    valid: true,
                  }}
                />

                <SelectField
                  placeholder={messages.selectFavouriteFilters}
                  name={NEED_PROVIDE_FILTERS.favouriteFilter}
                  className="border-bottom"
                  label={
                    <div className="anys-need-provide-filters__form__edit-label">
                      {messages.favourites}
                      <Button
                        type="button"
                        variant="link"
                        styleType="secondary"
                        onClick={() => favouriteFiltersModalRef.current?.open()}
                        disabled={!favouriteFilters?.length}
                      >
                        <span>{messages.edit}</span>
                      </Button>
                    </div>
                  }
                  options={favouriteFiltersOptions}
                  icon={<ChevronIcon gradient />}
                />
                <div className="anys-need-provide-filters__form__section">
                  <label className="anys-need-provide-filters__title">
                    {messages.offers}
                  </label>
                  <div className="anys-need-provide-filters__form__section__skill border-bottom">
                    <SkillsField
                      skills={allSkills}
                      skillsValue={null}
                      onBrowse={() => {
                        setSkillsModalOpenFor({
                          initialValues: skills,
                          for: 'form',
                        });
                        skillsModalRef.current?.open();
                      }}
                      messages={messages}
                    />
                  </div>

                  {/* We put border on wrapper div so we can use field margin.
                      Dropdown doens't function well with field padding.
                      Render only on desktop so that the location filter parse
                      fns. don't override each other */}
                  {!isPhablet && (
                    <div className="border-bottom">
                      <InputAutocompleteLocationField
                        placeholder={messages.locationPlaceholder}
                        name={NEED_PROVIDE_FILTERS.typeOfService}
                        label={messages.location}
                        suffixNode={<ChevronIcon gradient />}
                        useProfileLocation
                        locationPromptInitially={false}
                        withBounds
                        withPlaceId
                      />
                    </div>
                  )}

                  {!!(priceRange.minTotalPrice && priceRange.maxTotalPrice) && (
                    <div className="anys-need-provide-filters__form__section__price border-bottom">
                      <label className="anys-need-provide-filters__form__section__price__title">
                        {messages.price}
                      </label>
                      <p>{`${formatFilterPrice(
                        priceRange.minTotalPrice,
                      )} - ${formatFilterPrice(priceRange.maxTotalPrice)}`}</p>

                      <PriceRangeField
                        functionType="logarithmic"
                        name={NEED_PROVIDE_FILTERS.price}
                        min={
                          priceRange.minTotalPrice
                            ? convertSubUnitToUnit(
                                priceRange.minTotalPrice,
                                unitDivisor('USD'),
                              )
                            : undefined
                        }
                        max={
                          priceRange.maxTotalPrice
                            ? convertSubUnitToUnit(
                                priceRange.maxTotalPrice,
                                unitDivisor('USD'),
                              )
                            : undefined
                        }
                      />

                      <div className="anys-need-provide-filters__form__section__price__range">
                        <label>
                          {price?.from
                            ? formatFilterPrice(
                                convertUnitToSubUnit(
                                  price.from,
                                  unitDivisor('USD'),
                                ),
                              )
                            : formatFilterPrice(priceRange.minTotalPrice)}
                        </label>
                        <label>
                          {price?.to
                            ? formatFilterPrice(
                                convertUnitToSubUnit(
                                  price.to,
                                  unitDivisor('USD'),
                                ),
                              )
                            : formatFilterPrice(priceRange.maxTotalPrice)}
                        </label>
                      </div>
                    </div>
                  )}
                  <div className="anys-need-provide-filters__form__section__field border-bottom">
                    <label>{messages.sign}</label>
                    <div className="anys-need-provide-filters__form__section__field__checkbox">
                      <RadioGroupField
                        name={NEED_PROVIDE_FILTERS.sign}
                        options={signOptions}
                      />
                    </div>
                  </div>
                  <div className="anys-need-provide-filters__form__section__field border-bottom">
                    <label>{messages.userType}</label>
                    <div className="anys-need-provide-filters__form__section__field__checkbox">
                      <RadioGroupField
                        name={NEED_PROVIDE_FILTERS.userType}
                        options={userTypeOptions}
                      />
                    </div>
                  </div>
                </div>
                <div className="anys-need-provide-filters__form__section">
                  <label className="anys-need-provide-filters__title">
                    {messages.users}
                  </label>
                  <div className="anys-need-provide-filters__form__section__field border-bottom">
                    <label>{messages.overallRating}</label>
                    <RadioGroupField
                      className="anys-need-provide-filters__form__section__field__radio"
                      direction="row"
                      name={NEED_PROVIDE_FILTERS.rating}
                      options={overallRatingOptions}
                    />
                  </div>
                  <div className="anys-need-provide-filters__form__section__field anys-need-provide-filters__form__section__field--last">
                    <InputField
                      label={messages.minimumJobs}
                      name={NEED_PROVIDE_FILTERS.numberOfJobs}
                      placeholder={messages.enterNumberOfJobs}
                      type="number"
                      min={0}
                      max={100}
                    />
                  </div>
                </div>
              </form>
            );
          }}
        />
      </Aside>

      <JobFilters
        modalName="provide-advanced-filters"
        ref={advancedFiltersRef}
        skills={allSkills}
        priceRange={priceRange}
        onBrowse={() => {
          setSkillsModalOpenFor({
            initialValues:
              advancedFiltersFormApi.current?.getState()?.values?.skills || [],
            for: 'filters',
          });

          skillsModalRef.current?.open();
        }}
        onApply={() => advancedFiltersRef.current.close()}
        formApi={advancedFiltersFormApi}
        closeModal={() => advancedFiltersRef.current.close()}
        dbNames={defaultDbNames}
        onSave={openSaveFilterModal}
        saveBtnProps={{ disabled: false }}
      />

      <SkillsModal
        skillGroups={skillGroups}
        initialValues={skillsModalOpenFor.initialValues}
        loading={false}
        ref={skillsModalRef}
        onSubmit={onSkillsApply}
      />

      <Modal
        type="no-action"
        modalName="new-filter-name"
        isFullscreen
        ref={saveFilterModalRef}
        title={messages.saveFilter}
        className="anys-need-provide-filters__modal-save-filter"
        footer={
          <div>
            <Button
              type="submit"
              onClick={() => saveFilterFormApi.current.submit()}
            >
              {messages.save}
            </Button>
            <Button
              type="button"
              onClick={() => saveFilterModalRef.current.close()}
              variant="link"
            >
              <span>{messages.cancel}</span>
            </Button>
          </div>
        }
      >
        <Form
          onSubmit={handleSaveFilter}
          render={(formRenderProps) => {
            const { handleSubmit, form } = formRenderProps;

            saveFilterFormApi.current = form;

            return (
              <form onSubmit={handleSubmit}>
                <InputField
                  name="filterName"
                  label={messages.filterName}
                  placeholder={messages.enterFilterName}
                  validate={formValidators.required(messages.valueRequired)}
                  autoComplete="off"
                />
              </form>
            );
          }}
        />
      </Modal>

      <FavouriteFilters
        skills={allSkills}
        priceRange={priceRange}
        closeModal={() => favouriteFiltersModalRef.current.close()}
        ref={favouriteFiltersModalRef}
      />
    </div>
  );
};

export default NeedProvideFilters;
