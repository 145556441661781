import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import api from 'api';
import { useWallet } from '../../WalletOutline.page';
import { DEFAULT_PAGINATION, PaginationParams } from 'models/Pagination';
import useInfinitePagination from 'hooks/useInfinitePagination';
import useInfiniteContainer from 'hooks/useInfiniteContainer';
import useMatchMedia from 'hooks/useMatchMedia';
import { Loader } from 'ncoded-component-library';
import useEvent from 'hooks/useEvent';
import {
  PaymentHistory,
  PaymentHistoryMetadata,
  PaymentHistoryType,
} from 'models/Wallet';
import { formatNumber } from 'utils/currency';
import { useTranslation } from 'react-i18next';
import OverlaySpinner from 'components/OverlaySpinner';

import './PaymentHistoryList.styles.scss';

const mapPaymentType: Record<
  PaymentHistoryType,
  { label: string; sign: string }
> = {
  payin: { label: 'Purchased service', sign: '-' },
  payout: { label: 'Service revenue', sign: '+' },
  deposit: { label: 'Deposit', sign: '+' },
  withdraw: { label: 'Withdraw', sign: '-' },
  trade_from: { label: 'AnyCredit purchase', sign: '-' },
  trade_to: { label: 'AnyCredit sale', sign: '+' },
};

type PaymentHistoryListProps = {
  className?: string;
  type?: PaymentHistoryType;
};

const PaymentHistoryList = (props: PaymentHistoryListProps) => {
  const { className, type } = props;
  const classes = classNames('anys-payment-history-list', className);

  const { wallet } = useWallet();

  const { t } = useTranslation();

  const getPaymentHistory = useCallback(
    async (currentPage: number, take: number) => {
      const queryParams: PaginationParams = {
        $page: currentPage,
        $take: take,
        ...(type
          ? {
              $where: JSON.stringify({
                type: { EQUAL: type },
              }),
            }
          : {}),
      };

      OverlaySpinner.show('.anys-payment-history-list');

      try {
        const usdFund = wallet.funds?.find((fund) => fund.currency === 'USD');

        if (usdFund) {
          const {
            data: { items, totalItems, totalPages },
          } = await api.wallet.getPaymentHistory(usdFund?.id, queryParams);

          return { items, totalItems, totalPages };
        } else {
          return { items: [], totalItems: 0, totalPages: 0 };
        }
      } catch (e) {
        console.error(e);
      } finally {
        OverlaySpinner.hide('.anys-payment-history-list');
      }
    },
    [type, wallet.funds],
  );

  const {
    items: payments,
    loading,
    onContainerScrolled,
  } = useInfinitePagination<PaymentHistory>({
    take: DEFAULT_PAGINATION.take,
    makeRequest: getPaymentHistory,
    debounceTime: 500,
  });

  const isPhablet = useMatchMedia('isPhablet');

  const { onScroll } = useInfiniteContainer({
    container: isPhablet
      ? window
      : (document.getElementsByClassName(
          'anys-payment-history-list__ul',
        )[0] as HTMLElement),
    onScroll: onContainerScrolled,
    loading: loading,
    loader: (
      <Loader
        className="anys-payment-history-list__ul"
        overlay={false}
        size="small"
      />
    ),
  });

  useEvent(window, 'scroll', onScroll, undefined, isPhablet);

  const redirectTo = useCallback((metadata: PaymentHistoryMetadata) => {
    if (metadata?.job) {
      return metadata.job.isSignedByClient && metadata.job.isSignedByProvider
        ? `/contract/${metadata?.job?.commonId}`
        : `/job/${metadata?.job?.commonId}`;
    }
    if (metadata?.jobPost) {
      return `/job-post/${metadata?.jobPostId}`;
    }

    if (metadata?.arbitration) {
      return `/arbitration?arbitrationId=${metadata?.arbitrationId}`;
    }
  }, []);

  return (
    <div className={classes}>
      <ul onScroll={onScroll} className="anys-payment-history-list__ul">
        {payments?.length !== 0 ? (
          payments.map((payment: PaymentHistory) => {
            return (
              <li key={payment.id}>
                {Object.keys(payment?.metadata)?.[0] ? (
                  <Link tabIndex={0} to={redirectTo(payment?.metadata)}>
                    <span>{mapPaymentType[payment.type]?.label}</span>
                    {payment.status === 'refunded' && (
                      <span>{` (Refunded)`}</span>
                    )}
                  </Link>
                ) : (
                  <span>{mapPaymentType[payment.type]?.label}</span>
                )}
                <span>
                  {payment.status === 'refunded'
                    ? '+'
                    : mapPaymentType[payment.type]?.sign}
                  {formatNumber(t, payment.amount / 100, { currency: 'USD' })} $
                </span>
              </li>
            );
          })
        ) : (
          <div>History is empty</div>
        )}
      </ul>
    </div>
  );
};

export default PaymentHistoryList;
