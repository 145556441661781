import React from 'react';
import { StarProps } from './FilledStar.icon';

const EmptyStar = (props: StarProps) => {
  const { size = 15 } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_156_3558"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="1"
        y="0"
        width="14"
        height="13"
      >
        <path
          d="M7.41242 0.919844C7.48506 0.757994 7.71484 0.757994 7.78748 0.919844L9.36891 4.44338C9.45859 4.64318 9.64749 4.78043 9.86522 4.80397L13.705 5.21917C13.8814 5.23824 13.9524 5.45678 13.8209 5.57588L10.9585 8.16874C10.7962 8.31576 10.724 8.53784 10.7689 8.75218L11.5606 12.5323C11.597 12.706 11.4111 12.841 11.2572 12.7528L7.90669 10.8317L7.80444 11.01L7.90668 10.8317C7.7167 10.7228 7.4832 10.7228 7.29322 10.8317L3.94273 12.7528C3.78883 12.841 3.60293 12.706 3.63929 12.5323L4.43097 8.75218C4.47586 8.53784 4.40371 8.31576 4.2414 8.16874L1.37901 5.57588C1.24752 5.45678 1.31853 5.23824 1.49491 5.21917L5.33468 4.80397C5.55241 4.78043 5.74132 4.64318 5.83099 4.44338L7.41242 0.919844Z"
          fill="#C4C4C4"
          stroke="#828282"
          strokeWidth="0.411111"
        />
      </mask>
      <g mask="url(#mask0_156_3558)">
        <rect
          opacity="0.5"
          x="-0.199951"
          y="0.400024"
          width="15"
          height="13"
          fill="#3A3F45"
          fillOpacity="0.8"
        />
      </g>
    </svg>
  );
};

export default EmptyStar;
