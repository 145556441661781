import React, { useEffect, useState } from 'react';
import ResponsiveImage from 'components/ResponsiveImage';

type FileToImageProps = {
  className?: string;
  file: File;
};

const FileToImage: React.FC<FileToImageProps> = (props) => {
  const { className, file } = props;

  const [fileUrl, setFileUrl] = useState('');

  useEffect(() => {
    if (file) setFileUrl(URL.createObjectURL(file));
  }, [file]);

  useEffect(() => {
    return () => {
      if (fileUrl) URL.revokeObjectURL(fileUrl);
    };
  }, [fileUrl]);

  return (
    <ResponsiveImage className={className} alt={file?.name} src={fileUrl} />
  );
};

export default FileToImage;
