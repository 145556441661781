import React from 'react';
import classNames from 'classnames';
import { JobState as JobStateModel } from 'models/Job';
import { useTranslation } from 'react-i18next';
import { getTranslationForJobState } from 'utils/job';
import { ContractState } from 'constants/contract';

import './JobState.styles.scss';

type JobStateProps = {
  className?: string;
  state: JobStateModel | ContractState;
};

const JobState: React.FC<JobStateProps> = (props) => {
  const { className, state } = props;

  const { t } = useTranslation();

  const classes = classNames(
    'anys-job-state',
    `anys-job-state--${state?.toLowerCase()}`,
    className,
  );

  const stateMessage = getTranslationForJobState(t, state);

  return <span className={classes}>{stateMessage}</span>;
};

export default JobState;
