import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import PlusCircleStrokeGradientIcon from 'icons/PlusCircleStrokeGradient.icon';
import Modal, { ModalRef } from 'components/Modal';
import { Button } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import { useProfile } from 'router/subrouters/Profile/pages/Profile/Profile.page';
import ProfileCard from 'components/Card/ProfileCardComponent';
import {
  convertSubUnitToUnit,
  formatNumber,
  getCurrencyFromIntl,
  unitDivisor,
} from 'utils/currency';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import UserItemLoading from 'components/LoadPlaceholders/UserItem.loading';
import useInfiniteContainer from 'hooks/useInfiniteContainer';
import {
  getEntityForItem,
  getEntityIdForItem,
  getEntityInboxState,
  getOtherPartyForEntity,
} from 'router/subrouters/Inbox/utils';
import { formatUserFirstLastName } from 'utils/user';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import UserItem from 'components/UserItem';
import { JobState as JobStateModel } from 'models/Job';
import { InboxItem } from 'models/Inbox';
import JobState from 'components/JobState';
import useInboxLink from 'router/subrouters/Inbox/hooks/useInboxLink';
import api from 'api';
import showToast from 'modules/showToast';
import CustomLink from 'components/CustomLink';
import useCallbackRef from 'hooks/useCallbackRef';
import useMyOffersSearch from './useMyOffersSearch';

import './MyOffers.styles.scss';
import './MyOffers.styles.responsive.scss';

const getStateFromSigns = (numberOfSigns: number): JobStateModel => {
  if (!numberOfSigns) return 'Ad';

  if (numberOfSigns === 1) return 'Offer';

  return 'Contract';
};

type MyOffersProps = {
  className?: string;
  isMine: boolean;
};

const MyOffers: React.FC<MyOffersProps> = (props) => {
  const { className, isMine } = props;
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { currentUser } = useContext(CurrentUserContext);

  const { id: currentUserId } = currentUser || {};

  const { profileUser, profileOffers, setProfileOffers } = useProfile();

  const { createEntityLink } = useInboxLink();

  const offerModalRef = useRef<ModalRef>(null);
  const addOfferModalRef = useRef<ModalRef>(null);

  const [inboxItemsContainer, inboxItemsRef] =
    useCallbackRef<HTMLUListElement>();

  const [selectedInboxItem, setSelectedInboxItem] = useState<InboxItem>(null);
  const [isAddingOffer, setIsAddingOffer] = useState(false);

  const classes = classNames(
    'anys-my-offers',
    {
      'anys-my-offers--empty': !profileOffers?.length,
    },
    className,
  );

  const messages = useMemo(
    () => ({
      title: t('General.offers'),
      myOffers: t('Profile.myOffers'),
      seeAll: t('General.seeAll'),
      addProvideOffer: t('MyOffers.addProvideOffer'),
      add: t('General.add'),
      addOffer: t('MyOffers.addOffer'),
      createNewOffer: t('MyOffers.createNewOffer'),
      addOfferModalTitle: t('MyOffers.addOfferModalTitle'),
      select: t('General.select'),
      cancel: t('General.cancel'),
    }),
    [t],
  );

  const { onContainerScrolled, loading, items, setItems } = useMyOffersSearch();

  const { onScroll, loaderEl } = useInfiniteContainer({
    container: inboxItemsContainer,
    onScroll: onContainerScrolled,
    loader: <UserItemLoading length={3} />,
    loading,
  });

  const onOpenAddOfferModal = useCallback(() => {
    addOfferModalRef.current.open();
    setSelectedInboxItem(null);
  }, []);

  const onAddOffer = useCallback(async () => {
    if (!currentUserId || !selectedInboxItem || isAddingOffer) return;

    try {
      setIsAddingOffer(true);

      // Only one of these will exist at a time
      const { jobPostId, jobId, contractId } = selectedInboxItem;

      const { data } = await api.user.addDisplayedOffer(currentUserId, {
        jobPostId,
        jobId,
        contractId,
      });

      setProfileOffers((old) => {
        // Only two offers are shown
        if (old.length >= 2) return old;

        return [data, ...old];
      });

      // Filter out the added item so that we don't add it again
      setItems((offers) =>
        offers.filter((offer) => offer.jobPostId !== data.jobPost.id),
      );

      addOfferModalRef.current.close();

      // Add a timeout so that the previous modal close
      // doesnt interfere because of search params clear
      setTimeout(() => {
        offerModalRef.current.close();
      }, 300);
    } catch (error) {
      showToast('error', t('General.error'));
    } finally {
      setIsAddingOffer(false);
    }
  }, [
    currentUserId,
    isAddingOffer,
    selectedInboxItem,
    setItems,
    setProfileOffers,
    t,
  ]);

  return !isMine && !profileOffers?.length ? null : (
    <>
      <ProfileCard
        buttonLabel={messages.seeAll}
        cardTitle={isMine ? messages.myOffers : messages.title}
        onClick={() => {
          navigate(`/profile/offers/${profileUser.id}`);
        }}
        className={classes}
      >
        <div className="anys-my-offers__content">
          <ul>
            {isMine && (
              <li>
                <Button
                  type="button"
                  variant="solid"
                  className="anys-my-offers__content__add"
                  onClick={() => offerModalRef.current.open()}
                >
                  <PlusCircleStrokeGradientIcon />
                  <label>{messages.addProvideOffer}</label>
                </Button>
              </li>
            )}
            {profileOffers.map(({ jobPost }) => {
              const { id, title, timeAndPricing } = jobPost || {};

              return (
                <li key={id}>
                  <Link to={`/job-post/${id}`} target="_self" rel="opener">
                    <label className="anys-my-offers__content__title">
                      {title}
                    </label>
                    <span>
                      <small>
                        {getCurrencyFromIntl(t, timeAndPricing?.currency)}
                      </small>
                      {timeAndPricing?.minTotalPrice
                        ? formatNumber(
                            t,
                            convertSubUnitToUnit(
                              timeAndPricing.minTotalPrice,
                              unitDivisor(timeAndPricing.currency),
                            ),
                            {
                              currency: timeAndPricing.currency,
                            },
                          )
                        : '-'}
                    </span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </ProfileCard>
      <Modal
        modalName="profileMyOffersModal"
        className="anys-my-offers__modal"
        type="no-action"
        isDrawer
        ref={offerModalRef}
        hideHeader
        isDark
      >
        <div className="anys-my-offers__modal__upper-buttons">
          <Button
            type="button"
            variant="solid"
            className="anys-my-offers__modal__add-offer-button"
            onClick={onOpenAddOfferModal}
          >
            {messages.addOffer}
          </Button>
          <CustomLink
            className="anys-my-offers__modal__create-new-offer-button"
            to="/job-post/create/provide"
            state={{
              for: 'MyOffers',
            }}
            styleType="pink"
          >
            {messages.createNewOffer}
          </CustomLink>
        </div>
        <hr></hr>
        <Button
          type="button"
          className="anys-my-offers__modal__cancel-button"
          onClick={() => offerModalRef.current.close()}
        >
          {messages.cancel}
        </Button>
      </Modal>

      <Modal
        modalName="add-offer-modal"
        className="anys-my-offers__modal--add-offer"
        ref={addOfferModalRef}
        type="action"
        title={messages.addOfferModalTitle}
        primaryActionContent={messages.add}
        onPrimaryAction={onAddOffer}
        secondaryActionContent={messages.cancel}
        onSecondaryAction={() => addOfferModalRef.current.close()}
      >
        <div className="anys-my-offers__modal--add-offer__item-list">
          {items?.length ? (
            <ul ref={inboxItemsRef} onScroll={onScroll}>
              {items.map((item) => {
                const {
                  id: inboxItemId,
                  mainSkill,
                  type,
                  state: itemState,
                  numberOfSigns,
                } = item;

                const entity = getEntityForItem(item);
                const entityId = getEntityIdForItem(item);

                const mainSkillName = mainSkill?.name;

                const otherUser = getOtherPartyForEntity(currentUser, item);
                const fullName = formatUserFirstLastName(otherUser);

                const entityState = getEntityInboxState(entity, type);

                const state =
                  itemState === 'Ad'
                    ? getStateFromSigns(numberOfSigns)
                    : itemState ||
                      entityState ||
                      getStateFromSigns(numberOfSigns);

                const isDraft = state === 'Draft';

                const link = createEntityLink(
                  isDraft ? 'edit' : 'view',
                  type,
                  entityId,
                  inboxItemId,
                  otherUser?.id === currentUserId ? null : otherUser?.id,
                );

                const linkWithoutSearch = link.split('?')[0];

                const imgUrl =
                  otherUser?.profileImage?.resizedUrls?.[80] ||
                  otherUser?.profileImage?.url;

                const isSelected = selectedInboxItem?.id === inboxItemId;

                return (
                  <li key={inboxItemId}>
                    <UserItem
                      linkProps={{
                        to: linkWithoutSearch,
                        target: '_blank',
                        rel: 'opener',
                      }}
                      imgUrl={!isDraft && imgUrl}
                      imgAlt={fullName}
                      topPartContent={fullName}
                      bottomPartContent={mainSkillName}
                      isSelected={isSelected}
                      cta={
                        !isSelected && (
                          <Button
                            type="button"
                            className="anys-my-offers__modal--add-offer__item-list__item-cta"
                            onClick={() => setSelectedInboxItem(item)}
                          >
                            <span>{messages.select}</span>
                          </Button>
                        )
                      }
                    >
                      {state && (
                        <JobState
                          state={state}
                          className={classNames(
                            'anys-my-offers__modal--add-offer__item-list__state',
                            {
                              'anys-my-offers__modal--add-offer__item-list__state--selected':
                                isSelected,
                            },
                          )}
                        />
                      )}
                    </UserItem>
                  </li>
                );
              })}
              {loading && <li>{loaderEl}</li>}
            </ul>
          ) : (
            loaderEl
          )}

          {!items?.length && !loading && t('General.noItems')}
        </div>
      </Modal>
    </>
  );
};

export default MyOffers;
