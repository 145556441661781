import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { ChatMessage as ChatMessageModel } from 'models/Chat';
import useInfiniteContainer from 'hooks/useInfiniteContainer';
import { PaginationParams } from 'models/Pagination';
import useInfinitePagination from 'hooks/useInfinitePagination';
import eventSocketService from 'services/socket/eventSocketService';
import chatSessions from 'api/chat/chatSessions';
import ChatMessage from '../ChatMessage';
import dayjs from 'dayjs';
import ChatMainLoading from './ChatMain.loading';
import { useTranslation } from 'react-i18next';
import useResizeObserver from 'hooks/useResizeObserver';
import { Loader } from 'ncoded-component-library';
import { Picture } from 'models/User';
import { isAndroid, isMobile } from 'react-device-detect';
import useChatScrollContainer from 'components/Chat/hooks/useChatScrollContainer';
import useEvent from 'hooks/useEvent';
import useBusListener from 'hooks/useBusListener';
import bus from 'modules/bus';
import debounce from 'lodash/debounce';
import ChatNotificationsContext from 'providers/ChatNotifications/ChatNotifications.context';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';

import './ChatMain.styles.scss';

const MESSAGES_TAKE = 15;

const sortMessages = (messages: ChatMessageModel[]) => {
  return messages.sort(
    (msg1, msg2) => +new Date(msg1.createdAt) - +new Date(msg2.createdAt),
  );
};

type ChatMainProps = {
  className?: string;
  chatSessionId?: number;
  fetchInProgress?: boolean;
  unreadMsgCount: number;
  openImagePreview: (currImgIndex: number, images: Picture[]) => void;
};

const ChatMain: React.FC<ChatMainProps> = (props) => {
  const {
    className,
    chatSessionId,
    fetchInProgress,
    unreadMsgCount,
    openImagePreview,
  } = props;

  const { t } = useTranslation();

  const lastSavedScrollHeight = useRef<number>();
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(true);

  const { currentUser } = useContext(CurrentUserContext);
  const { markSessionAsRead } = useContext(ChatNotificationsContext);

  const [msgContainerWidth, setMsgContainerWidth] = useState<number>();

  const classes = classNames('anys-chat-main', className);

  const { scrollContainer, isInputFocused } = useChatScrollContainer();

  // If there is a scrollbar
  const canScrollMessages =
    scrollContainer?.scrollHeight !== scrollContainer?.clientHeight;

  const fetchMessages = useCallback(
    async (currentPage: number, take: number) => {
      if (!chatSessionId) return { items: [], totalItems: 0, totalPages: 0 };

      const queryParams: PaginationParams = {
        $relations: 'sender',
        $take: take,
        $page: currentPage,
        $order: '-createdAt',
      };

      const {
        data: { items, totalItems, totalPages },
      } = await chatSessions.getChatMessages(chatSessionId, queryParams);

      return { items, totalItems, totalPages } as const;
    },
    [chatSessionId],
  );

  const {
    loading,
    onContainerScrolled,
    items: messages,
    setItems: setMessages,
    currentPage,
    totalPages,
  } = useInfinitePagination<ChatMessageModel>({
    take: MESSAGES_TAKE,
    makeRequest: fetchMessages,
    debounceTime: 300,
    prependItems: true,
    manipulateResponseItems: sortMessages,
  });

  const { onScroll, loaderEl } = useInfiniteContainer({
    container: scrollContainer,
    onScroll: onContainerScrolled,
    loading: loading || fetchInProgress,
    loader: (
      <Loader
        className="anys-chat-main__spinner"
        overlay={false}
        size="small"
      />
    ),
    threshold: 2,
    toTop: true,
  });

  const debouncedMarkAsRead = useMemo(
    () =>
      debounce(() => {
        markSessionAsRead(chatSessionId, unreadMsgCount);
      }, 1000),
    [chatSessionId, markSessionAsRead, unreadMsgCount],
  );

  const checkIfCanShowScrollDown = useMemo(
    () =>
      debounce(() => {
        // Caclulate how far we have scrolled from bottom
        const canShowDownArrow =
          scrollContainer.scrollHeight -
            scrollContainer.scrollTop -
            scrollContainer.clientHeight >
          200;

        bus.broadcastEvent('TOGGLE_SHOW_SCROLL_TO_BOTTOM', canShowDownArrow);

        if (!canShowDownArrow) {
          debouncedMarkAsRead();
        }
      }, 100),
    [scrollContainer, debouncedMarkAsRead],
  );

  const handleScroll = useCallback(
    (event: Event) => {
      onScroll(event);

      checkIfCanShowScrollDown();
    },
    [checkIfCanShowScrollDown, onScroll],
  );

  useEvent(scrollContainer, 'scroll', handleScroll);

  const hasMessages = messages?.length > 0;

  const isLastPageFetched = currentPage === totalPages;

  const scrollDown = useCallback(
    (elementToScroll?: Element) => {
      const scroller = elementToScroll || scrollContainer;

      scroller.scrollTop = scroller.scrollHeight;
      lastSavedScrollHeight.current = scroller.scrollHeight;

      setShouldScrollToBottom(false);
    },
    [scrollContainer],
  );

  const scrollDownWithArrow = useCallback(() => scrollDown(), [scrollDown]);

  // Scrolling on click with down arrow
  useBusListener('SCROLL_TO_BOTTOM', scrollDownWithArrow);

  const onInputFocusChange = useCallback(
    ({ payload }: { payload: boolean }) => {
      setShouldScrollToBottom(payload);
    },
    [],
  );

  useBusListener('INPUT_FOCUS_CHANGE', onInputFocusChange);

  const handleScrollChanges = useCallback(
    (isInputFocused: boolean, scrollContainer: Element) => {
      const isOverflowHidden =
        scrollContainer.className.includes('overflow-hidden') || loading;

      // Keep scroll position when loading new messages
      if (!shouldScrollToBottom && lastSavedScrollHeight.current) {
        const newScrollPosition =
          Math.abs(
            scrollContainer.scrollHeight - lastSavedScrollHeight.current,
          ) + 1;

        scrollContainer.scrollTop = newScrollPosition;

        return;
      }

      if (isOverflowHidden) return;

      // Scroll to bottom of scroll container
      // when we focus input on mobile
      if ((shouldScrollToBottom && hasMessages) || isInputFocused) {
        scrollDown(scrollContainer);
      }
    },
    [hasMessages, loading, scrollDown, shouldScrollToBottom],
  );

  // When mobile keyboard opens or when
  // overflow style changes (when fetching msgs), resize is called
  const onMsgContainerResize = useCallback(
    (entries: ResizeObserverEntry[]) => {
      if (!isMobile) return;

      const { borderBoxSize, target: scrollContainer } = entries?.[0] || {};

      const { inlineSize } = borderBoxSize?.[0] || {};

      // Save width to pass to content loader viewbox
      setMsgContainerWidth(inlineSize);

      handleScrollChanges(isInputFocused, scrollContainer);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasMessages, isInputFocused, loading],
  );

  useResizeObserver(scrollContainer, onMsgContainerResize);

  useEffect(() => {
    const handleMessageCreated = (message: ChatMessageModel) => {
      if (chatSessionId !== message?.session?.id) return;

      setMessages((oldMessages) => [...oldMessages, message]);

      setShouldScrollToBottom(true);
    };

    eventSocketService.addListener(
      'chat-message-created',
      handleMessageCreated,
    );

    return () => {
      eventSocketService.removeListener(
        'chat-message-created',
        handleMessageCreated,
      );
    };
  }, [chatSessionId, currentUser?.id, setMessages]);

  // Prevent overscroll on Android when
  // chat isn't scrollable (when we don't have enough messages)
  useEffect(() => {
    const shouldBlockTouchMove =
      isMobile && isAndroid && !canScrollMessages && !loading;

    if (!shouldBlockTouchMove) return;

    const tm = (e: TouchEvent) => e.preventDefault();

    window.addEventListener('touchmove', tm, {
      passive: false,
    });

    return () => window.removeEventListener('touchmove', tm);
  }, [canScrollMessages, loading]);

  useEffect(() => {
    if (!scrollContainer) return;

    // If we are loading messages, save scroll position
    if (loading) {
      lastSavedScrollHeight.current = scrollContainer.scrollHeight;
    }

    // If we are loading messages, forbid scroll
    scrollContainer.classList[loading ? 'add' : 'remove']('overflow-hidden');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (isMobile || !scrollContainer) return;

    handleScrollChanges(false, scrollContainer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages, loading]);

  useEffect(() => {
    if (!chatSessionId) return;

    markSessionAsRead(chatSessionId, unreadMsgCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatSessionId, markSessionAsRead]);

  return (
    <div className={classes} id="anys-chat-main">
      {isLastPageFetched && !loading && hasMessages && (
        <div className="anys-chat-main__messages-end">
          {t('General.noMoreMessages')}
        </div>
      )}

      {loading && !hasMessages && (
        <ChatMainLoading length={3} viewBoxWidth={msgContainerWidth} />
      )}

      {hasMessages && loaderEl}

      {hasMessages ? (
        messages.map((msg, i) => {
          const { id, sender, createdAt } = msg;

          const nextMsg = messages[i + 1];

          const isNextMsgSameSender = sender?.id === nextMsg?.sender?.id;
          const timeElapsed = dayjs(nextMsg?.createdAt).diff(
            createdAt,
            'minute',
          );

          const moreThanMinutePassed = timeElapsed >= 1;

          return (
            <ChatMessage
              key={id}
              message={msg}
              sendDataCollapsed={isNextMsgSameSender && !moreThanMinutePassed}
              className={classNames('anys-chat-main__message', {
                'anys-chat-main__message--group':
                  isNextMsgSameSender && !moreThanMinutePassed,
              })}
              openImagePreview={openImagePreview}
            />
          );
        })
      ) : !loading ? (
        <div> {t('General.noMessages')} </div>
      ) : null}
    </div>
  );
};

export default ChatMain;
