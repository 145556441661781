import type { OptionValue } from 'ncoded-component-library/build/components/molecules/Select/Select.component';
import { useMemo } from 'react';
import useTranslations from '../../../hooks/useTranslations';

export const SEARCH_BY = {
  NAME: 'name',
  SKILL: 'skill',
  USER_ID: 'userId',
} as const;

export type SearchBy = typeof SEARCH_BY[keyof typeof SEARCH_BY];

export default function useSearchOptions() {
  const copies = useTranslations();

  return useMemo(() => {
    const options: OptionValue<SearchBy>[] = [
      {
        label: copies.name,
        value: 'name',
      },
      {
        label: copies.skill,
        value: 'skill',
      },
      {
        label: copies.userId,
        value: 'userId',
      },
    ];

    return options;
  }, [copies.name, copies.skill, copies.userId]);
}
