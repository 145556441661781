import React, { useContext, useMemo } from 'react';
import classNames from 'classnames';
import { ChatMessage as ChatMessageModel } from 'models/Chat';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import { useTranslation } from 'react-i18next';
import { formatUserDisplayName } from 'utils/user';
import dates from 'utils/dates';
import dayjs from 'dayjs';
import PhotoContent from './PhotoContent';
import { Picture, NonImageFile } from 'models/User';
import FileContent from './FileContent';

import './ChatMessage.styles.scss';

const TODAY = dayjs();

const separateFiles = (
  files: (Picture | NonImageFile)[],
): {
  photos: Picture[];
  nonPhotos: (Picture | NonImageFile)[];
} => {
  if (!files?.length) return { photos: [], nonPhotos: [] };

  return files.reduce(
    (separated, file) => {
      if (file.type.startsWith('image/')) {
        separated.photos.push(file);
      } else {
        separated.nonPhotos.push(file);
      }

      return separated;
    },
    { photos: [], nonPhotos: [] },
  );
};

type ChatMessageProps = {
  className?: string;
  message: ChatMessageModel;
  sendDataCollapsed: boolean;
  openImagePreview: (currImgIndex: number, images: Picture[]) => void;
};

const ChatMessage: React.FC<ChatMessageProps> = (props) => {
  const { className, message, sendDataCollapsed, openImagePreview } = props;

  const { t } = useTranslation();

  const { currentUser } = useContext(CurrentUserContext);

  const { createdAt, sender, payload, files } = message || {};

  const { id: senderId } = sender || {};

  const isOwnMessage = senderId === currentUser?.id;

  const { photos, nonPhotos } = useMemo(() => separateFiles(files), [files]);

  const sentAt = TODAY.isSame(createdAt, 'day')
    ? dates.formatDate(createdAt, 'hh:mm A')
    : dates.formatDate(createdAt, 'MMM DD, hh:mm A');

  const senderDisplayName = isOwnMessage
    ? t('General.you')
    : formatUserDisplayName(sender);

  const classes = classNames(
    'anys-chat-message',
    {
      own: isOwnMessage,
      other: !isOwnMessage,
    },
    className,
  );

  return (
    <div className={classes}>
      <PhotoContent photos={photos} openImagePreview={openImagePreview} />
      <FileContent
        files={nonPhotos}
        className={classNames(
          'anys-chat-message__content',
          'anys-chat-message__content--file',
        )}
      />

      {payload && (
        <div className="anys-chat-message__content" tabIndex={0}>
          {payload}
        </div>
      )}
      <span
        className={classNames('anys-chat-message__send-info', {
          'anys-chat-message__send-info--collapsed':
            payload && sendDataCollapsed,
          'anys-chat-message__send-info--no-margin': !payload,
        })}
      >
        <span className="anys-chat-message__send-info__time">{sentAt}</span>
        <span>{senderDisplayName}</span>
      </span>
    </div>
  );
};

export default ChatMessage;
