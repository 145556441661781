import { ContractState } from 'constants/contract';
import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import { Job, JobPost, JobState } from 'models/Job';
import { Picture, NonImageFile } from 'models/User';
import { OptionValue } from 'ncoded-component-library/build/components/molecules/Select/Select.component';

const calcJobPrice = (
  timeAndPricing: Pick<JobPost<'form'>, 'timeAndPricing'>['timeAndPricing'],
) => {
  const isByProject = timeAndPricing.type === 'By project';
  const isHourlyStrict =
    !isByProject && timeAndPricing.pricingDurationType === 'Strict';

  const hours = isHourlyStrict
    ? timeAndPricing.totalHours
    : timeAndPricing.maxHours;

  return isByProject
    ? timeAndPricing?.price?.amount || 0
    : (timeAndPricing?.price?.amount || 0) * (hours || 0);
};

const getJobEndDate = (
  timeAndPricing: Pick<JobPost<'form'>, 'timeAndPricing'>['timeAndPricing'],
): Date | undefined => {
  const isByProject = timeAndPricing.type === 'By project';

  if (isByProject) return timeAndPricing.endDate;

  const { startDate, maxHours, minHours, totalHours } = timeAndPricing;

  if (startDate) {
    let date = dayjs(startDate);

    if (totalHours || maxHours || minHours) {
      date = date.add(totalHours || maxHours || minHours, 'hour');
    }

    return date.toDate();
  }

  return undefined;
};

const isJobPost = (obj: any): obj is JobPost => 'signedAt' in obj;

const isJob = (obj: any): obj is Job =>
  'signedByProviderAt' in obj && 'signedByClientAt' in obj;

const isFile = (obj: Picture | File | NonImageFile): obj is File =>
  !!(obj as File).lastModified;

const getSkillsAsLabels = (skillOptions: OptionValue[], skillIds: number[]) => {
  const skillLabels = skillOptions?.reduce((labels, { label, value }) => {
    if (skillIds?.includes(parseInt(value))) {
      labels.push(label);
    }

    return labels;
  }, []);

  return skillLabels.join(', ');
};

const getTranslationForJobState = (
  t: TFunction,
  state: JobState | ContractState | 'items',
  count = 1,
) => {
  switch (state) {
    case 'Contract':
      return t('General.contractCount', { count });

    case 'Active':
      return t('General.active');

    case 'Done':
      return t('General.done');

    case 'Offer':
      return t('General.offerCount', { count });

    case 'Ad':
      return t('General.preOfferCount', { count });

    case 'Draft':
      return t('General.draftCount', { count });

    default:
      return t('General.itemsCount', { count });
  }
};

/**
 *  Remove props that the BE adds because they are invalid when sending
 */
const removeInvalidProps = <T>(
  obj: T & { createdAt?: any; updatedAt?: any; deletedAt?: any; id?: any },
) => {
  const { createdAt, updatedAt, deletedAt, id, ...validProps } =
    obj || ({} as typeof obj);

  return validProps;
};

const removeId = <T>(obj: T & { id?: number }) => {
  if (!obj) return obj;

  const { id, ...validProps } = obj;

  return validProps;
};

const removeIdFromArray = <T>(array: (T & { id?: number })[]) => {
  if (!array) return array;

  const toReturn = array.map(({ id, ...rest }) => rest);

  return toReturn;
};

const getLockedData = <T>(
  lockedData: T,
  isLocked: boolean,
  isOwnJob: boolean,
) => {
  if (isOwnJob) return lockedData;

  if (isLocked) return undefined;

  return lockedData;
};

export {
  calcJobPrice,
  isJobPost,
  getJobEndDate,
  isFile,
  getSkillsAsLabels,
  getTranslationForJobState,
  removeInvalidProps,
  removeId,
  removeIdFromArray,
  getLockedData,
  isJob,
};
