import {
  Editor,
  Transforms,
  Element as SlateElement,
  Descendant,
  Range,
  Path,
  BaseRange,
} from 'slate';
import { ReactEditor } from 'slate-react';
import { LIST_TYPES_ARRAY } from '../constants';
import {
  ElementTypes,
  IndentElement,
  LinkElement,
  ListTypes,
  ParagraphElement,
  TextIndent,
} from '../models/EditorElements';
import { TextColor, TextStyle } from '../models/EditorText';
import { CustomSlateEditor } from '../models/RichTextEditor';

export default {
  toggleBlock(editor: CustomSlateEditor, elementType: ElementTypes) {
    const isActive = this.isBlockActive(editor, elementType);

    const isList = LIST_TYPES_ARRAY.includes(elementType as ListTypes);

    const isLink = elementType === 'link';

    // We will handle links in insertLink
    if (isLink) return;

    this.unwrapNodes(editor);

    const newProperties: Partial<SlateElement> = {
      type: isActive ? 'div' : isList ? 'list-item' : elementType,
    };

    Transforms.setNodes<SlateElement>(editor, newProperties);

    if (!isActive && isList) {
      const block: SlateElement = {
        type: elementType,
        children: [],
      };
      Transforms.wrapNodes(editor, block);
    }
  },

  toggleMark(editor: CustomSlateEditor, textStyle: TextStyle) {
    const isActive = this.isMarkActive(editor, textStyle);

    if (isActive) {
      Editor.removeMark(editor, textStyle);
    } else {
      Editor.addMark(editor, textStyle, true);
    }
  },

  toggleColor(
    editor: CustomSlateEditor,
    color: TextColor,
    isBackground = false,
  ) {
    const isActive = this.getActiveColor(editor, isBackground) === color;

    const mark = isBackground ? 'backgroundColor' : 'color';

    if (isActive) {
      Editor.removeMark(editor, mark);
    } else {
      Editor.addMark(editor, mark, color);
    }
  },

  isBlockActive(editor: CustomSlateEditor, elementType: ElementTypes) {
    const { selection } = editor;
    if (!selection) return false;

    const match = this.getActiveElement(editor, selection, elementType);

    return !!match;
  },

  isMarkActive(editor: CustomSlateEditor, textStyle: TextStyle) {
    const marks = Editor.marks(editor);

    return marks ? marks[textStyle as TextStyle] === true : false;
  },

  getActiveColor(editor: CustomSlateEditor, isBackground = false) {
    const marks = Editor.marks(editor);

    if (!marks) return null;

    if (isBackground) {
      return marks.backgroundColor;
    }

    return marks.color;
  },

  indentText(editor: CustomSlateEditor, textIndent: TextIndent) {
    const isIndent = textIndent === 'indent';

    const orderedListMatch = this.getActiveElement(
      editor,
      editor.selection,
      'ordered-list',
    );
    const unorderedListMatch = this.getActiveElement(
      editor,
      editor.selection,
      'unordered-list',
    );

    const hasList = orderedListMatch || unorderedListMatch;

    if (hasList) {
      this.unwrapNodes(editor);
    }

    const indent: IndentElement = {
      type: 'indent',
      children: [{ text: '' }],
    };

    try {
      // Throws an error if we can't find the previous path
      const at = isIndent
        ? Editor.previous(editor)[1]
        : Path.previous(Editor.path(editor, editor.selection));

      if (isIndent)
        Transforms.insertNodes(editor, indent, {
          at,
        });
      else
        Transforms.removeNodes(editor, {
          at,
          match: (n) => SlateElement.isElement(n) && n.type === 'indent',
        });
      // eslint-disable-next-line no-empty
    } catch (error) {}

    if (hasList) {
      const block: SlateElement = {
        type: orderedListMatch ? 'ordered-list' : 'unordered-list',
        children: [],
      };
      Transforms.wrapNodes(editor, block);
    }
  },

  insertLink(editor: CustomSlateEditor, url: string, text = url) {
    if (!url) return;

    const { selection } = editor;

    const displayText = text?.trim() || url;

    const link = this.createLinkNode(url, displayText);

    ReactEditor.focus(editor);

    if (selection) {
      const [parentNode, parentPath] = Editor.parent(
        editor,
        selection.focus?.path,
      );

      // Remove the Link node if we're inserting a new
      // link node inside of another link.
      if ((parentNode as SlateElement).type === 'link') {
        this.removeLink(editor);
      }

      if (editor.isVoid(parentNode as SlateElement)) {
        // Insert the new link after the void node
        Transforms.insertNodes(editor, this.createParagraphNode([link]), {
          at: Path.next(parentPath),
          select: true,
        });
      } else if (Range.isCollapsed(selection)) {
        // Insert the new link in our last known location
        Transforms.insertNodes(editor, link, { select: true });
      } else {
        // Wrap the currently selected range of text into a Link
        Transforms.wrapNodes(editor, link, { split: true, mode: 'all' });
        // Replace the selected text with the display text
        Transforms.insertText(editor, displayText);

        // Remove the highlight and move the cursor to the end of the highlight
        Transforms.collapse(editor, { edge: 'end' });
      }
    } else {
      // Insert the new link node at the bottom of the Editor when selection
      // is falsey
      Transforms.insertNodes(editor, this.createParagraphNode([link]));
    }
  },

  getSelectedLink(editor: CustomSlateEditor) {
    const { selection } = editor;
    if (!selection) return null;

    const match = this.getActiveElement(editor, selection, 'link');

    if (!match) return null;

    const linkElement = match[0] as LinkElement;

    return {
      link: linkElement.href,
      displayText: linkElement.children[0].text,
    };
  },

  removeLink(editor: CustomSlateEditor) {
    Transforms.unwrapNodes(editor, {
      match: (n) =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
    });
  },

  createLinkNode(href: string, text: string = href): LinkElement {
    return {
      type: 'link',
      href,
      children: [{ text }],
    };
  },

  createParagraphNode(
    children: Descendant[] = [{ text: '' }],
  ): ParagraphElement {
    return {
      type: 'paragraph',
      children,
    };
  },

  getActiveElement(
    editor: CustomSlateEditor,
    selection: BaseRange,
    elementType?: ElementTypes,
  ) {
    const [match] = Array.from(
      Editor.nodes(editor, {
        at: Editor.unhangRange(editor, selection),
        match: (n) =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          (!elementType || n.type === elementType),
      }),
    );

    return match;
  },

  unwrapNodes(editor: CustomSlateEditor) {
    Transforms.unwrapNodes(editor, {
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        LIST_TYPES_ARRAY.includes(n.type as ListTypes),
      split: true,
    });
  },

  getStringInSelection(editor: CustomSlateEditor) {
    if (!editor.selection) return null;

    return Editor.string(editor, editor.selection);
  },
};
