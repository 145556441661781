import React, { useCallback } from 'react';
import classNames from 'classnames';
import { DropzoneOptions, FileRejection, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import DropzoneTrigger from './DropzoneTrigger';
import showToast from 'modules/showToast';

import './Dropzone.styles.scss';

type DropzoneProps = {
  className?: string;
  customTrigger?: React.ReactNode;
  name: string;
  maxSizeInMB: number;
  uploadFileTypeMsg?: string;
  handleFiles: (files: File[]) => void;
} & Omit<DropzoneOptions, 'maxSize'>;

const Dropzone: React.FC<DropzoneProps> = (props) => {
  const {
    className,
    handleFiles,
    customTrigger,
    name,
    disabled,
    maxSizeInMB,
    accept,
    multiple,
    uploadFileTypeMsg,
    ...rest
  } = props;

  const { t } = useTranslation();

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (fileRejections.length > 0) {
        fileRejections.forEach((file) => {
          switch (file.errors[0].code) {
            case 'file-too-large':
              showToast('error', t('General.fileTooLarge'));
              break;
            case 'file-invalid-type':
              showToast('error', t('General.invalidFileType'));
              break;
            default:
              break;
          }
        });

        return;
      }
      handleFiles(acceptedFiles);
    },
    [handleFiles, t],
  );

  const { getRootProps, getInputProps, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
      disabled,
      maxSize: maxSizeInMB * 2 ** 20,
      accept,
      multiple,
      ...rest,
    });

  const classes = classNames('anys-dropzone', className, {
    'anys-dropzone--accept': isDragAccept,
    'anys-dropzone--reject': isDragReject,
    'anys-dropzone--disabled': disabled,
  });

  return (
    <div {...getRootProps()} className={classes}>
      <input {...getInputProps()} name={name} />

      <label className="anys-dropzone__text">
        {customTrigger || (
          <DropzoneTrigger
            formattedMaxSize={`${maxSizeInMB} MB`}
            uploadFileTypeMsg={
              uploadFileTypeMsg || t('General.files').toLowerCase()
            }
          />
        )}
      </label>
    </div>
  );
};

export default Dropzone;
