import React from 'react';
import classNames from 'classnames';
import utils from 'utils';
import SelectLabel from 'components/SelectLabel';
import type {
  SelectProps,
  OptionValue,
} from 'ncoded-component-library/build/components/molecules/Select/Select.component';

import './NativeSelect.styles.scss';
import './NativeSelect.styles.responsive.scss';

type NativeSelectProps = {
  className?: string;
} & SelectProps<string | number>;

const NativeSelect: React.FC<NativeSelectProps> = (props) => {
  const { className, options, value, placeholder, name, onChange, icon } =
    props;

  const classes = classNames(
    'anys-native-select',
    'ncoded-select__selected',
    { 'anys-native-select--no-val': !value },
    className,
  );

  return (
    <div className="anys-native-select-wrapper ncoded-select">
      <select
        defaultValue={utils.selectUtils.transformObjValue(value)}
        className={classes}
        name={name}
        placeholder={placeholder}
        onChange={(ev) => {
          if (onChange) {
            (onChange as (opt: OptionValue<string | number>) => void)({
              label: 'missing',
              value: utils.selectUtils.transformValueBack(ev.target.value),
            });
          }
        }}
      >
        {placeholder && (
          <option value="" disabled>
            {placeholder}
          </option>
        )}
        {options.map(({ label, value }, ind) => (
          <option key={ind} value={utils.selectUtils.transformObjValue(value)}>
            <SelectLabel label={label} />
          </option>
        ))}
      </select>
      <>{icon}</>
    </div>
  );
};

export default NativeSelect;
