import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import classNames from 'classnames';
import { SignupReqBody } from 'models/Auth';
import SignupForm from './components/SignupForm';
import { useAuth } from '../Auth/Auth.page';
import { FORM_ERROR } from 'final-form';
import { useTranslation } from 'react-i18next';
import MobileHeaderContext from 'components/MobileHeader/providers/MobileHeader/MobileHeader.context';
import AnyServiceLogoIcon from 'icons/AnyServiceLogo.icon';
import { Link } from 'react-router-dom';

import './Signup.styles.scss';
import './Signup.styles.responsive.scss';

type SignupProps = {
  className?: string;
};

const Signup: React.FC<SignupProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);

  const {
    signup,
    isAuthInProgress,
    checkIfEmailExists,
    isCheckingForExistingEmail,
  } = useAuth();

  const classes = classNames('anys-signup', className);

  const { setStyleOptions, setContent } = useContext(MobileHeaderContext);

  const isFirstStep = activeStep === 0;

  const inProgress = isAuthInProgress || isCheckingForExistingEmail;

  const initialValues: Partial<SignupReqBody> = useMemo(
    () => ({ role: 'Individual' }),
    [],
  );

  const handleSubmit = useCallback(
    async (values: SignupReqBody) => {
      if (!isFirstStep) {
        signup(values);

        return;
      }

      const emailExists = await checkIfEmailExists(values.email);

      if (emailExists) {
        return { [FORM_ERROR]: t('Signup.emailExistsError') };
      }

      setActiveStep((oldStep) => ++oldStep);
    },
    [checkIfEmailExists, isFirstStep, signup, t],
  );

  useEffect(() => {
    setStyleOptions({
      hasBorder: false,
      isBackButtonTransparent: true,
      showBackButton: true,
    });
    setContent(
      <h5 className="anys-signup__title">{t('General.createAccount')}</h5>,
    );
  }, [setContent, setStyleOptions, t]);

  return (
    <div className={classes}>
      <Link to=".." className="logo-icon">
        <AnyServiceLogoIcon className="anys-signup__big-logo" />
      </Link>

      <SignupForm
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onMobileSignup={handleSubmit}
        isFirstStep={isFirstStep}
        inProgress={inProgress}
        onDesktopSignup={signup}
      />
    </div>
  );
};

export default Signup;
