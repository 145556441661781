import React, { useCallback, useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Job, JobPost } from 'models/Job';
import api from 'api';
import OverlaySpinner from 'components/OverlaySpinner';
import { AxiosError } from 'axios';
import NavBar from 'components/NavBar';
import { parseJobPostFormValuesToJob } from './utils';
import useJobActions from '../../hooks/useJobActions';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import { getTranslationForJobState, isFile } from 'utils/job';
import MainTemplate from 'components/templates/MainTemplate';
import useHeaderStyles from 'hooks/useHeaderStyles';
import useInboxLink from 'router/subrouters/Inbox/hooks/useInboxLink';
import useHeaderContent from 'hooks/useHeaderContent';
import CustomLink from 'components/CustomLink';
import { getEntityInboxState } from 'router/subrouters/Inbox/utils';
import useHeaderBack from 'hooks/useHeaderBack';
import ChatNotificationsContext from 'providers/ChatNotifications/ChatNotifications.context';
import NotificationBadge from 'components/NotificationBadge';
import useEntityUpdateListener from 'hooks/useEntityUpdateListener';
import UserScheduleProvider from 'components/JobPostPreview/components/UserScheduleModal/UserScheduleProvider';
import showToast from 'modules/showToast';

import './Job.styles.scss';
import './Job.styles.responsive.scss';

type JobProps = {
  className?: string;
  asideLeft?: React.ReactNode;
  asideRight?: React.ReactNode;
  chatUserId?: number;
};

export type JobContext = {
  job?: Job;
  fetchingJob: boolean;
  savingJob: boolean;
  isOwnJob: boolean;
  setJob: React.Dispatch<React.SetStateAction<Job>>;
  updateJob: (values: JobPost<'form'>) => Promise<Job>;
};

const JobComponent: React.FC<JobProps> = (props) => {
  const { className, asideLeft, asideRight, chatUserId } = props;

  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [fetchingJob, setFetchingJob] = useState(false);
  const [savingJob, setSavingJob] = useState(false);
  const [job, setJob] = useState<Job>();
  const { t } = useTranslation();

  const location = useLocation();

  const { currentUser } = useContext(CurrentUserContext);

  const { totalUnreadNotifications } = useContext(ChatNotificationsContext);

  const { createEntityLink } = useInboxLink();

  const isInbox = location.pathname?.startsWith('/inbox');

  useEntityUpdateListener();

  useHeaderStyles({ showBackButton: true }, [], true);

  const onBackClick = useCallback(
    (prevPath: string | -1) => {
      if (typeof prevPath === 'string') navigate(prevPath, { replace: true });
      else navigate(prevPath);
    },
    [navigate],
  );

  useHeaderBack(onBackClick);

  useHeaderContent(
    <div className="mobile-header-content">
      {getTranslationForJobState(t, getEntityInboxState(job, 'job'))}

      <CustomLink
        to={`/chat/${chatUserId}`}
        variant="solid"
        className="mobile-header-content__cta"
      >
        {t('General.chat')}
        {totalUnreadNotifications ? (
          <NotificationBadge count={totalUnreadNotifications} />
        ) : null}
      </CustomLink>
    </div>,
    [!!job, isInbox, totalUnreadNotifications],
    undefined,
    !!job && isInbox,
  );

  const { signJob } = useJobActions();

  const isOwnJob =
    job?.type === 'Provide'
      ? currentUser?.id === job?.provider?.id
      : currentUser?.id === job?.client?.id;

  const classes = classNames('anys-job', className);

  const getJob = useCallback(
    async (commonId: number) => {
      OverlaySpinner.show('.anys-job');

      setFetchingJob(true);

      try {
        const {
          data: { job },
        } = await api.job.getJob(commonId);

        setJob(job);
      } catch (error) {
        console.error(error);

        if ((error as AxiosError)?.response?.status === 404) {
          navigate(`not-found/${commonId}`, { replace: true });
        } else {
          const err = error as AxiosError;

          showToast(
            'error',
            t('General.error'),
            err?.response?.data?.error?.message,
          );
        }
      } finally {
        OverlaySpinner.hide('.anys-job');
        setFetchingJob(false);
      }
    },
    [navigate, t],
  );

  const updateJob = useCallback(
    async (values: JobPost<'form'>) => {
      setSavingJob(true);
      OverlaySpinner.show('.anys-job');

      try {
        const {
          attachments = [],
          attachmentIdsToDelete = [],
          ...restOfValues
        } = values;

        const parsedValues = parseJobPostFormValuesToJob(
          restOfValues,
          isOwnJob,
        );

        let jobCommonId = job.commonId;
        let jobVersion = job.version;

        let newJob = job;

        const {
          data: { job: respJob },
        } = await api.job.updateJob(+id, job?.version, parsedValues);

        newJob = respJob;
        jobCommonId = respJob.commonId;
        jobVersion = respJob.version;

        const onlyFiles = attachments.filter(isFile);

        if (onlyFiles.length) {
          const res = await api.job.uploadFiles(
            respJob.commonId,
            respJob.version,
            onlyFiles,
          );

          respJob.attachments = res.data.job.attachments;
        }

        if (attachmentIdsToDelete?.length) {
          await Promise.all(
            attachmentIdsToDelete.map((deleteId) =>
              api.job.deleteFile(respJob.commonId, respJob.version, deleteId),
            ),
          );
        }

        let contractCommonIdMaybe: number = null;

        if (values.isSigned) {
          const { job, contractCommonId } = await signJob(
            jobCommonId,
            jobVersion,
          );

          newJob.isSignedByClient = job.isSignedByClient;
          newJob.isSignedByProvider = job.isSignedByProvider;
          contractCommonIdMaybe = contractCommonId;
        }

        setJob({
          ...newJob,
          attachments: newJob.attachments?.filter(
            (file) => !attachmentIdsToDelete?.includes(file.id),
          ),
        });

        const entityType = contractCommonIdMaybe ? 'contract' : 'job';
        const commonId = contractCommonIdMaybe || jobCommonId;

        navigate(
          isInbox
            ? createEntityLink('view', entityType, commonId)
            : `/${entityType}/${commonId}`,
        );

        return newJob;
      } catch (error) {
        const err = error as AxiosError;

        showToast(
          'error',
          t('General.error'),
          err?.response?.data?.error?.message,
        );

        console.error(error);
      } finally {
        setSavingJob(false);
        OverlaySpinner.hide('.anys-job');
      }
    },
    [createEntityLink, id, isInbox, isOwnJob, job, navigate, signJob, t],
  );

  useEffect(() => {
    if (+id) {
      getJob(+id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const context = {
    job,
    fetchingJob,
    savingJob,
    isOwnJob,
    setJob,
    updateJob,
  };

  return (
    <UserScheduleProvider>
      {isInbox ? (
        <Outlet context={context} />
      ) : (
        <>
          <NavBar className="anys-job__navbar" />
          <MainTemplate
            className={classes}
            asideLeft={asideLeft}
            asideRight={asideRight}
          >
            <Outlet context={context} />
          </MainTemplate>
        </>
      )}
    </UserScheduleProvider>
  );
};

export default JobComponent;

export const useJob = () => useOutletContext<JobContext>();
