import React from 'react';
import ProfilePreview from './ProfilePreview.page';
import ProfileProvider from './providers/Profile';

const DefaultProfile = () => (
  <ProfileProvider>
    <ProfilePreview />
  </ProfileProvider>
);

export default DefaultProfile;
