import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Modal, { ModalRef } from 'components/Modal';
import JobPostPreview from 'components/JobPostPreview';
import { convertToMoney, formatMoney } from 'utils/currency';
import { useTranslation } from 'react-i18next';
import { Fund, Wallet } from 'models/Wallet';
import { Button } from 'ncoded-component-library';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import ToolTip from 'components/ToolTip';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import AddDepositModal from 'components/AddDepositModal';
import useCallbackRef from 'hooks/useCallbackRef';
import api from 'api';
import env from 'env';
import { useParams } from 'react-router-dom';
import { InboxUrlParams } from 'models/Inbox';
import { useContract } from '../../../Contract/Contract.page';
import { calcJobPrice } from 'utils/job';
import { parseTimeAndPricing } from 'utils/job-parses';
import { Contract } from 'models/Contract';

import './ContractSummaryPayModal.styles.scss';

type SummaryPayModalProps = {
  summaryType: 'client' | 'provider';
  onClose: () => void;
  name?: string;
  isPaying: boolean;
  handlePay: () => Promise<void>;
  contractSummaryModalRef?: (element: ModalRef) => void;
};

const stripePromise = loadStripe(env.STRIPE_KEY);

const ContractSummaryPayModal = (props: SummaryPayModalProps) => {
  const {
    summaryType,
    onClose,
    name = 'contract-summary-pay-modal',
    isPaying,
    handlePay,
    contractSummaryModalRef,
  } = props;

  const { contract: baseContract, fetchingContract } = useContract();

  const { t } = useTranslation();

  const [wallet, setWallet] = useState<Wallet>();

  const { currentUser } = useContext(CurrentUserContext);

  const { inboxItemId } = useParams<InboxUrlParams>();

  const contract = useMemo(
    () =>
      baseContract && {
        ...baseContract,
        prevVersion:
          baseContract?.prevAcceptedVersion || baseContract?.prevVersion,
      },
    [baseContract],
  );

  const { client, provider, prevVersion, timeAndPricing } = useMemo(
    (): Partial<Contract> => contract || {},
    [contract],
  );

  const isCurrentUserClient = currentUser?.id === client?.id;

  const [balance, setBalance] = useState(0);

  const [addDepostiModal, addDepostiModalRef] = useCallbackRef<ModalRef>(null);

  const prevContractPrice = prevVersion
    ? calcJobPrice(parseTimeAndPricing(prevVersion.timeAndPricing))
    : 0;

  const currentContractPrice = timeAndPricing
    ? calcJobPrice(parseTimeAndPricing(timeAndPricing))
    : 0;

  const price = useMemo(() => {
    if (timeAndPricing?.type === 'Hourly')
      return timeAndPricing?.maxTotalPrice / 100;

    return timeAndPricing?.price / 100;
  }, [timeAndPricing]);

  // If the amount is negative, then the new
  // price is lower than the previous reserved funds
  // and we need to deposit more
  const priceDiffForReservedFunds =
    currentContractPrice - (prevContractPrice || 0);

  const priceDiffWithFee =
    priceDiffForReservedFunds + (priceDiffForReservedFunds / 100) * 0.97;

  const clientFeeAmount = (price / 100) * 0.97;
  const providerFeeAmount = (price / 100) * 3;

  const clientTotalWithFee = price + clientFeeAmount;

  const onSign = useCallback(async () => {
    handlePay();

    onClose();
  }, [handlePay, onClose]);

  useEffect(() => {
    const getWallet = async () => {
      try {
        const { data } = await api.wallet.getWallet();
        setWallet(data);
      } catch (e) {
        console.error(e);
      }
    };

    getWallet();
  }, []);

  useEffect(() => {
    const USDFunds = wallet?.funds.find(
      (wall: Fund) => wall.currency === 'USD',
    )?.balance;

    if (USDFunds) {
      setBalance(USDFunds / 100);
    }
  }, [wallet]);

  return (
    <Modal
      modalName={name}
      type="no-action"
      title="Summary"
      className="contract-summary-pay-modal"
      ref={contractSummaryModalRef}
    >
      <JobPostPreview
        contract={contract}
        isFetching={fetchingContract}
        hasClientSigned={summaryType === 'client'}
        hasProviderSigned={summaryType === 'provider'}
        client={client}
        provider={provider}
        isCurrentUserClient={isCurrentUserClient}
        actionInProgress={isPaying}
        handleSign={() => handlePay()}
        isOwnJob={false}
        isInSummaryModal
      />

      {summaryType === 'client' ? (
        <div className="job-summary-price">
          <div className="job-summary-price__price-part">
            {`Subtotal: $${price || 0}`}
          </div>

          <div className="job-summary-price__price-part">
            <span>
              {`Fees & Estimated Tax: $${+clientFeeAmount.toFixed(2)}`}
            </span>
            <ToolTip
              t={t}
              tooltipName="fees"
              className="job-summary-price__price-part__fee-tooltip"
            >
              Service fee is 0.97% help us operate AnyService
            </ToolTip>
          </div>

          <div className="job-summary-price__price-part">
            {`Total / Job value: ${formatMoney(t, convertToMoney(clientTotalWithFee * 100 || 0))}`}
          </div>

          {priceDiffForReservedFunds > 0 ? (
            <>
              <div className="job-summary-price__price-part">
                {`Amount in escrow: ${formatMoney(t, convertToMoney((prevContractPrice || 0) + (prevContractPrice / 100) * 0.97))}`}
              </div>

              <div className="job-summary-price__price-total">
                {`Amount to pay: ${formatMoney(t, convertToMoney(priceDiffWithFee))}`}
              </div>
            </>
          ) : null}

          <div className="job-summary-price__description">
            This amount will be in escrow until service is done and you are
            satisfied with service.
          </div>

          <div className="job-summary-price__actions">
            {+balance.toFixed(2) >= +(priceDiffWithFee / 100).toFixed(2) ? (
              <div>
                <Button type="button" variant="solid" onClick={onSign}>
                  Confirm and pay
                </Button>
                <Button
                  variant="link"
                  onClick={() => {
                    onClose();
                  }}
                >
                  <span>Cancel</span>
                </Button>
              </div>
            ) : (
              <div className="job-summary-price__no-enough-money">
                <span>{t('General.notMoneyInBalance')}</span>
                <Button
                  type="button"
                  variant="solid"
                  onClick={() => {
                    addDepostiModal.open();
                  }}
                >
                  Add deposit
                </Button>
              </div>
            )}
            <div className="job-summary-price__current-balance">
              Your current balance:
              <span>{formatMoney(t, convertToMoney(balance * 100 || 0))}</span>
            </div>
          </div>
          <Elements
            stripe={stripePromise}
            options={{
              mode: 'setup',
              currency: 'usd',
            }}
          >
            <AddDepositModal
              className="contract-summary-pay-modal__deposit-modal"
              addDepositModalRef={addDepostiModalRef}
              onDepositMake={(value) => {
                setBalance((old) => old + value);
              }}
              onClose={() => addDepostiModal.close()}
              initialValueForDeposit={
                +((priceDiffWithFee / 100 - balance) * 100).toFixed()
              }
              returnUrlOnConfirmSetup={
                inboxItemId
                  ? `${window.location.origin}/inbox/${inboxItemId}/0/contract/${contract?.commonId}?contract-summary-pay-modal=open&add-deposit=open`
                  : `${window.location.origin}/contract/${contract?.commonId}?contract-summary-pay-modal=open&add-deposit=open`
              }
            />
          </Elements>
        </div>
      ) : (
        <div className="job-summary-price">
          <div className="job-summary-price__price-part">
            {`Subtotal: ${price || 0}$`}
          </div>

          <div className="job-summary-price__price-part">
            {`Fees & Estimated Tax: $${+(providerFeeAmount < 3
              ? 3
              : +providerFeeAmount.toFixed(2))}`}
            <ToolTip
              t={t}
              tooltipName="fees"
              className="job-summary-price__price-part__fee-tooltip"
            >
              Service fee is 3% (minimum 3$) help us operate AnyService
            </ToolTip>
          </div>

          <div className="job-summary-price__price-total">
            Total / Job value:
            <span>
              {formatMoney(
                t,
                convertToMoney(
                  +(
                    (price - (providerFeeAmount < 3 ? 3 : providerFeeAmount)) *
                    100
                  ).toFixed(2) || 0,
                ),
              )}
            </span>
          </div>

          <div className="job-summary-price__actions">
            <div>
              <Button variant="solid" onClick={onSign}>
                Confirm and Take job
              </Button>
              <Button
                variant="link"
                onClick={() => {
                  onClose();
                }}
              >
                <span>Cancel</span>
              </Button>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ContractSummaryPayModal;
