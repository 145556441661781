import React, { useEffect } from 'react';
import classNames from 'classnames';
import { formatUserDisplayName } from 'utils/user';
import { ChatParticipant } from 'models/Chat';
import TimesIcon from 'icons/Times.icon';
import Avatar from 'components/Avatar';
import { useTranslation } from 'react-i18next';
import BackButton from 'components/BackButton';
import useChatOnlineStatus from 'hooks/useChatOnlineStatus';

import './ChatHeader.styles.scss';
import './ChatHeader.styles.responsive.scss';

type ChatHeaderProps = {
  className?: string;
  chatParticipant: ChatParticipant;
  onCloseChatClick: () => void;
};

const ChatHeader: React.FC<ChatHeaderProps> = (props) => {
  const { className, chatParticipant, onCloseChatClick } = props;

  const { t } = useTranslation();

  const { user, id: userId } = chatParticipant || {};

  const { onlineStatuses, checkOnlineStatuses } = useChatOnlineStatus();

  const { profileImage, status } = user || {};

  const { isOnline } = onlineStatuses?.[userId] || status || {};

  const classes = classNames('anys-chat-header', className);

  const displayName = formatUserDisplayName(user);

  useEffect(() => {
    if (userId) checkOnlineStatuses([userId]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return (
    <div className={classes}>
      <BackButton
        className="anys-chat-header__back"
        onBackClick={onCloseChatClick}
        withBorder
      />

      <Avatar
        alt={displayName}
        img={profileImage?.resizedUrls?.[80]}
        className="anys-chat-header__avatar"
      />

      <div className="anys-chat-header__user-info">
        <span className="anys-chat-header__user-info__name">{displayName}</span>
        {isOnline && (
          <span className="anys-chat-header__user-info__online">
            {t('General.online')}
          </span>
        )}
      </div>

      <button
        type="button"
        className="anys-chat-header__close-chat"
        onClick={onCloseChatClick}
      >
        <TimesIcon />
      </button>
    </div>
  );
};

export default ChatHeader;
