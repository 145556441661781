import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import Modal, { ModalRef } from 'components/Modal';
import showToast from 'modules/showToast';
import { useTranslation } from 'react-i18next';
import api from 'api';
import Availability from 'router/subrouters/Profile/pages/ProfilePreview/components/Availability';
import utils from 'utils';
import { UserAvailability } from 'models/Availability';
import { Button, Loader } from 'ncoded-component-library';
import { User } from 'models/User';
import { formatUserFirstLastName } from 'utils/user';
import UserScheduleContext, {
  UserSchedule,
} from './UserScheduleProvider/UserSchedule.context';
import dates from 'utils/dates';
import { Form } from 'react-final-form';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import isBetween from 'dayjs/plugin/isBetween';

import DateTimeField from 'components/DateTimeField';
import formValidators from 'utils/formValidators';
import { FormApi } from 'final-form';
import { Job } from 'models/Job';
import { getJobEndDate } from 'utils/job';
import { convertToMoney } from 'utils/currency';
import AvailableSlots from './AvailableSlots';

import './UserScheduleModal.styles.scss';
import './UserScheduleModal.styles.responsive.scss';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);

const TODAY = new Date();

// 1h = 3600000ms
const MIN_HOUR_BOUNDARY = 1;
const EARLIEST_START_DATE = new Date(+TODAY + MIN_HOUR_BOUNDARY * 3600000);

type UserScheduleModalProps = {
  className?: string;
  user: Partial<User>;
  userScheduleModalRef: React.Ref<ModalRef>;
  timeAndPricing: Pick<Job, 'timeAndPricing'>['timeAndPricing'];
  closeModal: () => void;
};

const UserScheduleModal: React.FC<UserScheduleModalProps> = (props) => {
  const { className, user, userScheduleModalRef, timeAndPricing, closeModal } =
    props;

  const { t } = useTranslation();

  const [isFetchingAvailability, setIsFetchingAvailability] = useState(false);
  const [userAvailability, setUserAvailability] = useState<UserAvailability>();

  const formApi = useRef<FormApi<UserSchedule>>();

  const { selectedSchedule, setSelectedSchedule } =
    useContext(UserScheduleContext);

  const classes = classNames('anys-user-schedule-modal', className);

  const { id: userId } = user || {};

  const { type } = timeAndPricing || {};

  const isHourlyPricing = type === 'Hourly';

  const userDisplayName = formatUserFirstLastName(user, t('General.user'));

  const { timeslots: rawTimelots, timezone } = userAvailability || {};

  const timeslots = useMemo(
    () =>
      rawTimelots?.length
        ? rawTimelots.sort(
            (slot1, slot2) => +new Date(slot1.from) - +new Date(slot2.from),
          )
        : null,
    [rawTimelots],
  );

  const validateScheduleForm = useCallback(
    (values: UserSchedule) => {
      const { startDate, endDate } = values;

      const errors: Partial<Record<keyof typeof values, string>> = {};

      if (!startDate || !endDate) return;

      if (dayjs(startDate).isAfter(endDate)) {
        errors.endDate = t('General.dateAfterError', {
          limit: dates.formatDeadlineDate(startDate),
        });
      }

      const isValidTimeSlot = timeslots?.some(
        (slot) =>
          dayjs(startDate).isBetween(slot.from, slot.to, 'minutes', '[)') &&
          dayjs(endDate).isBetween(slot.from, slot.to, 'minutes', '(]'),
      );

      if (!isValidTimeSlot) {
        errors.endDate = t('General.dateOutsideSchedule');
      }

      return errors;
    },
    [t, timeslots],
  );

  const onSubmit = useCallback(
    (values: UserSchedule) => {
      const { startDate, endDate } = values;

      setSelectedSchedule({
        startDate,
        endDate,
      });

      closeModal();
    },
    [closeModal, setSelectedSchedule],
  );

  useEffect(() => {
    if (!userId) return;

    const getUserAvailability = async () => {
      setIsFetchingAvailability(true);

      try {
        const { data } = await api.availability.getAvailability(userId);

        setUserAvailability(data);
      } catch (e) {
        showToast('error', t('General.error'));
      } finally {
        setIsFetchingAvailability(false);
      }
    };

    getUserAvailability();
  }, [t, userId]);

  return (
    <Modal
      type="no-action"
      modalName="user-schedule-modal"
      ref={userScheduleModalRef}
      className={classes}
      title={t('General.userSchedule', { userName: userDisplayName })}
    >
      {isFetchingAvailability ? (
        <Loader />
      ) : (
        <>
          <Availability
            renderInModal
            availability={userAvailability}
            isMine={false}
            // We don't need this fn. so we pass a dummy
            setShowAvailabilityModal={utils.noop}
          />

          <Form
            validate={validateScheduleForm}
            onSubmit={onSubmit}
            initialValues={selectedSchedule}
            render={(formRenderProps) => {
              const { handleSubmit, form, values, errors } = formRenderProps;

              const { startDate, endDate } = values;

              formApi.current = form;

              return (
                <form onSubmit={handleSubmit} className="flex column gap-8">
                  <AvailableSlots
                    startDate={errors.startDate ? startDate : null}
                    endDate={errors.endDate ? endDate : null}
                    timeslots={timeslots}
                    userDisplayName={userDisplayName}
                  />

                  <DateTimeField
                    name="startDate"
                    isSingleInput
                    label={t('General.startDate')}
                    validate={formValidators.composeValidators(
                      formValidators.requiredDate(t('General.requiredField')),
                      formValidators.dateIsAfter(
                        t('General.earliestDateStart', {
                          limit: t('General.hoursCount', {
                            count: MIN_HOUR_BOUNDARY,
                          }),
                        }),
                        EARLIEST_START_DATE,
                      ),
                    )}
                    renderAsPortal
                    timeslots={timeslots}
                    timezone={timezone}
                    onChange={(date) => {
                      if (!isHourlyPricing) return;

                      const endDateForHourly = getJobEndDate({
                        ...(timeAndPricing || {}),
                        type: timeAndPricing?.type,
                        currency: timeAndPricing?.currency,
                        price: timeAndPricing?.price
                          ? convertToMoney(
                              timeAndPricing.price,
                              timeAndPricing.currency,
                            )
                          : null,
                        startDate: date ? new Date(date as Date) : null,
                        endDate: timeAndPricing?.endDate
                          ? new Date(timeAndPricing.endDate)
                          : null,
                        timezone: timeAndPricing?.timezone,
                      });

                      form.change('endDate', endDateForHourly);
                    }}
                  />
                  {isHourlyPricing ? (
                    <p
                      className={classNames({
                        'anys-user-schedule-modal__empty-end-date':
                          errors?.endDate,
                      })}
                    >
                      <label>{t('General.endDate')} </label>
                      <br />
                      {endDate ? dates.formatDeadlineDate(endDate) + '.' : '-'}
                      <br />
                      {errors.endDate}
                    </p>
                  ) : (
                    <DateTimeField
                      name="endDate"
                      isSingleInput
                      label={t('General.endDate')}
                      leftLimit={startDate ? dayjs(startDate) : undefined}
                      validate={formValidators.requiredDate(
                        t('General.requiredField'),
                      )}
                      timeslots={timeslots}
                      timezone={timezone}
                      renderAsPortal
                    />
                  )}

                  <Button type="submit" className="mt-16">
                    {t('General.select')}
                  </Button>
                </form>
              );
            }}
          />
        </>
      )}
    </Modal>
  );
};

export default UserScheduleModal;
