import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

export default (props: IconProps) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="currentColor"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 2C7.68629 2 5 4.68629 5 8V17H17V8C17 4.68629 14.3137 2 11 2ZM19 17V8C19 3.58172 15.4183 0 11 0C6.58172 0 3 3.58172 3 8V17H1C0.447715 17 0 17.4477 0 18C0 18.5523 0.447715 19 1 19H7.53545C7.77806 20.6961 9.23678 22 11 22C12.7632 22 14.2219 20.6961 14.4646 19H21C21.5523 19 22 18.5523 22 18C22 17.4477 21.5523 17 21 17H19ZM12.4146 19H9.58536C9.79128 19.5826 10.3469 20 11 20C11.6531 20 12.2087 19.5826 12.4146 19Z"
      fillOpacity="0.85"
    />
  </svg>
);
