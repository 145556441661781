import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { RouteProps, Navigate, useLocation } from 'react-router-dom';
import authService from 'services/authService';
import credentialsService from 'services/credentialsService';
import { Role } from 'models/User';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';

export type EnhancedRouteProps = {
  authorized?: boolean;
  onlyPublic?: boolean;
  authorizedRoles?: Array<Role>;
} & Omit<RouteProps, 'element'> & {
    element: React.FC | React.ReactNode;
  };

const EnhancedRoute: React.FC<EnhancedRouteProps> = (props) => {
  const {
    authorized = false,
    onlyPublic = false,
    element,
    authorizedRoles,
  } = props;

  const { currentUser: user } = useContext(CurrentUserContext);
  const hasToken = !!credentialsService.token;
  const location = useLocation();
  const prevPathname = useRef(location.pathname);

  const finalRoute = useMemo(() => {
    if ((authorized || authorizedRoles) && !hasToken) {
      return (
        <Navigate
          to={{ pathname: '/auth' }}
          state={{ from: location.pathname }}
          replace
        />
      );
    }

    if (onlyPublic && hasToken) {
      return <Navigate to="/" />;
    }
    if (authorizedRoles) {
      const userAllowed = authService.checkRolesForUser(user, authorizedRoles);
      if (!userAllowed) credentialsService.removeAuthBody();
    }

    if (typeof element === 'function') {
      const Component = element;

      return <Component />;
    }
    return element as React.ReactElement;
  }, [
    authorized,
    authorizedRoles,
    hasToken,
    onlyPublic,
    location,
    user,
    element,
  ]);

  useEffect(() => {
    if (prevPathname.current !== location.pathname) {
      window.scroll({
        top: 0,
        left: 0,
      });

      prevPathname.current = location.pathname;
    }
  }, [location.pathname]);

  return finalRoute;
};

export default EnhancedRoute;
