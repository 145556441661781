import classNames from 'classnames';
import InputField from 'components/InputField';
import RangeSliderField from 'components/RangeSliderField';
import { FormApi } from 'final-form';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import formValidators from 'utils/formValidators';

import './AmountSlider.styles.scss';

type AmountSliderProps = {
  maxAmountFee: number;
  form: FormApi<any>;
  label?: string;
  initialValue?: any;
  minAmountFee?: number;
  errorMessages?: { minValueMsg: string; maxValueMsg: string };
  disabled?: boolean;
};

const AmountSlider = (props: AmountSliderProps) => {
  const {
    maxAmountFee,
    minAmountFee = 0,
    form,
    label,
    initialValue,
    errorMessages,
    disabled = false,
  } = props;

  const { composeValidators, required, maxValue, minValue } = formValidators;

  const { t } = useTranslation();

  const validators = useMemo(
    () => ({
      cancelationFee: composeValidators(
        required(t('General.required')),
        maxValue(
          errorMessages?.maxValueMsg ||
            t('Contract.youCanNotCollectMoreThenDefined'),
          maxAmountFee,
          true,
        ),
        minValue(
          errorMessages?.minValueMsg ||
            t('Contract.youCanNotCollectLessThenDefined'),
          minAmountFee,
          true,
        ),
      ),
    }),
    [
      composeValidators,
      errorMessages?.maxValueMsg,
      errorMessages?.minValueMsg,
      maxAmountFee,
      maxValue,
      minAmountFee,
      minValue,
      required,
      t,
    ],
  );

  return (
    <div
      className={classNames('anys-contract-approve-dialog__range-slider', {
        'anys-contract-approve-dialog__range-slider--disabled': disabled,
      })}
    >
      {label && <h2>{label}</h2>}

      <RangeSliderField
        variant="single"
        name="priceRange"
        min={minAmountFee}
        max={maxAmountFee}
        onChange={({ min }) => {
          if (disabled && initialValue) {
            form.change('priceRange', {
              min: initialValue,
              max: maxAmountFee,
              range: maxAmountFee - initialValue,
            });
          } else {
            form.change('cancelationFee', `${min.toFixed(0)}`);
          }
        }}
      />
      <div className="anys-contract-approve-dialog__range-slider__value-range">
        <span>{`$${minAmountFee}`}</span>
        <span>{`$${maxAmountFee}`}</span>
      </div>

      <InputField
        disabled={disabled}
        name="cancelationFee"
        prefixNode={
          <div className="anys-contract-approve-dialog__range-slider__cancelation-fee__prefix">
            $
          </div>
        }
        className="anys-contract-approve-dialog__range-slider__cancelation-fee"
        onChange={(value) => {
          if (value.currentTarget.value && maxAmountFee) {
            if (
              +value.currentTarget.value >= minAmountFee &&
              +value.currentTarget.value <= maxAmountFee
            ) {
              form.change('priceRange', {
                min: +value.currentTarget.value,
                max: maxAmountFee,
                range: maxAmountFee - +value.currentTarget.value,
              });
            }
          }
        }}
        type="number"
        isPureNumberInput
        min="0"
        validate={validators.cancelationFee}
      />
    </div>
  );
};

export default AmountSlider;
