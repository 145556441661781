import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

export default (props: IconProps) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 0.625C4.83063 0.625 0.625 4.83063 0.625 10C0.625 15.1694 4.83063 19.375 10 19.375C15.1694 19.375 19.375 15.1694 19.375 10C19.375 4.83063 15.1694 0.625 10 0.625ZM10 4.46875C10.241 4.46875 10.4767 4.54023 10.6771 4.67415C10.8775 4.80806 11.0337 4.99841 11.126 5.2211C11.2182 5.4438 11.2424 5.68885 11.1953 5.92527C11.1483 6.16168 11.0322 6.37884 10.8618 6.54929C10.6913 6.71973 10.4742 6.83581 10.2378 6.88283C10.0014 6.92986 9.7563 6.90572 9.5336 6.81348C9.31091 6.72123 9.12056 6.56502 8.98665 6.3646C8.85273 6.16418 8.78125 5.92855 8.78125 5.6875C8.78125 5.36427 8.90965 5.05427 9.13821 4.82571C9.36677 4.59715 9.67677 4.46875 10 4.46875ZM12.25 15.0625H8.125C7.92609 15.0625 7.73532 14.9835 7.59467 14.8428C7.45402 14.7022 7.375 14.5114 7.375 14.3125C7.375 14.1136 7.45402 13.9228 7.59467 13.7822C7.73532 13.6415 7.92609 13.5625 8.125 13.5625H9.4375V9.4375H8.6875C8.48859 9.4375 8.29782 9.35848 8.15717 9.21783C8.01652 9.07718 7.9375 8.88641 7.9375 8.6875C7.9375 8.48859 8.01652 8.29782 8.15717 8.15717C8.29782 8.01652 8.48859 7.9375 8.6875 7.9375H10.1875C10.3864 7.9375 10.5772 8.01652 10.7178 8.15717C10.8585 8.29782 10.9375 8.48859 10.9375 8.6875V13.5625H12.25C12.4489 13.5625 12.6397 13.6415 12.7803 13.7822C12.921 13.9228 13 14.1136 13 14.3125C13 14.5114 12.921 14.7022 12.7803 14.8428C12.6397 14.9835 12.4489 15.0625 12.25 15.0625Z"
        fill="#0080E5"
      />
    </svg>
  );
};
