import React from 'react';
import classNames from 'classnames';
import { PaginatedResponse } from 'models/Pagination';

import './SuggestedItems.styles.scss';

type SuggestedItemsProps<T> = {
  className?: string;
  paginatedRes: PaginatedResponse<T>;
  loaderEl: JSX.Element;
  title?: React.ReactNode;
  children: (item: T) => JSX.Element;
};

const SuggestedItems = <T,>(props: SuggestedItemsProps<T>) => {
  const { className, paginatedRes, loaderEl, title, children } = props;

  const { items: suggestedItems } = paginatedRes || {};

  const classes = classNames('anys-suggested-items', className);

  return (
    <div className={classes}>
      {title ? <h2>{title}</h2> : null}

      <ul className="anys-suggested-items__items">
        {suggestedItems.map((item) => children(item))}
      </ul>
      {loaderEl}
    </div>
  );
};

export default SuggestedItems;
