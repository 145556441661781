import { ObjectValues } from 'types';

export enum QueryOperator {
  NOT,
  LESSTHAN,
  LESSTHANOREQUAL,
  MORETHAN,
  MORETHANOREQUAL,
  EQUAL,
  LIKE,
  BETWEEN,
  IN,
  ANY,
  ISNULL,
}

export enum UserQueryParams {
  'languages.language',
  'skills.skillId',
  'user.role',
  'location',
  'dateOfBirth',
  'overallSkillScore.averageRating',
  'jobSuccess.numberOfJobs',
  'overallClientScore.averageRating',
}

type MapperReturnValue = Record<
  keyof typeof QueryOperator,
  number | string | number[] | string[]
>;

export type FormValueToQueryMapper<
  FormValues,
  QueryParamNames extends string = string,
> = {
  [P in keyof FormValues]: {
    name: QueryParamNames;
    mapperFn: (fieldValue: FormValues[P]) => Partial<MapperReturnValue>;
  };
};

export function convertFormValuesToQuery<
  FormValues,
  QueryParamNames extends string = string,
>(
  formValues: FormValues,
  valuesToQueryMapper: FormValueToQueryMapper<FormValues, QueryParamNames>,
): string | null {
  const parsedValues: Partial<
    Record<QueryParamNames, Partial<MapperReturnValue>>
  > = {};

  Object.entries(formValues).forEach((entry) => {
    const fieldName = entry[0] as keyof FormValues;
    const value = entry[1] as ObjectValues<FormValues>;

    const { name, mapperFn } = valuesToQueryMapper[fieldName];

    if (value) {
      parsedValues[name] = mapperFn(value);
    }
  });

  const hasValues = Object.values(parsedValues).length > 0;

  return hasValues ? JSON.stringify({ $where: parsedValues }) : null;
}

export const removeSeeAll = (val: string) => (val !== 'see-all' ? val : null);
