import {
  NotificationSetting,
  NotificationSettingRes,
} from 'models/Notification';
import httpClient from '../../httpClient';

const notificationSettingsPath = (routePath: TemplateStringsArray) =>
  `notification-settings/${routePath}`;

function getNotificationSettings() {
  return httpClient.get<NotificationSettingRes[]>(notificationSettingsPath``);
}

function updateNotificationSettings(
  notificationSettings: NotificationSetting[],
) {
  return httpClient.put<NotificationSetting[]>(notificationSettingsPath``, {
    notificationSettings,
  });
}

export default {
  getNotificationSettings,
  updateNotificationSettings,
};
