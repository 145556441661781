import api from 'api';
import { PaginationParams } from 'models/Pagination';
import React, { useEffect, useState } from 'react';
import { useProfile } from 'router/subrouters/Profile/pages/Profile/Profile.page';
import Modal, { ModalRef } from 'components/Modal';
import classNames from 'classnames';
import Review from 'router/subrouters/Profile/pages/ProfilePreview/components/SkillsRating/components/SkillsModal/components/Review';
import { useTranslation } from 'react-i18next';

import './ReviewCardModal.styles.scss';

type SkillsModalProps = {
  className?: string;
  skillsModalRef?: (element: ModalRef) => void;
};

const ReviewCardModal: React.FC<SkillsModalProps> = (props) => {
  const { className, skillsModalRef } = props;

  const { t } = useTranslation();

  const [reviews, setReviews] = useState([]);

  const {
    profileUser: { id },
  } = useProfile();

  const classes = classNames('anys-review-card-modal', className);

  useEffect(() => {
    if (id) {
      const getReviews = async () => {
        const queryParams: PaginationParams = {
          $relations: 'reviewee.profileImage',
          //TODO: 100 here why??, add infinitive pagination or page selector
          $take: 100,
          $skip: 0,
          $where: JSON.stringify({
            'user.id': { EQUAL: id },
          }),
        };

        const {
          data: { items },
        } = await api.contract.getReviews(queryParams);

        setReviews(items);
      };

      getReviews();
    }
  }, [id]);

  return (
    <Modal
      type="no-action"
      modalName="reviewed-as-a-client-modal"
      ref={skillsModalRef}
      title={t('General.reviewedAsAClient')}
      className={classes}
      isDrawer
    >
      <ul className="review-card-modal">
        {reviews?.map((review, index) => (
          <Review
            key={index}
            className="review-card-modal__review"
            review={review}
            isSkillReview={false}
          />
        ))}
      </ul>
    </Modal>
  );
};

export default ReviewCardModal;
