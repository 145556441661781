import { PriceRangeType } from 'api/favouriteFilters';
import { Filter } from 'models/Filters';
import { createContext } from 'react';

type NeedProvideContextProps = {
  favouriteFilters: Partial<Filter[]>;
  setFavouriteFilters: React.Dispatch<React.SetStateAction<Partial<Filter[]>>>;
  createFavouriteFilter: (
    name: string,
    query: string,
    formValues: object,
  ) => Promise<void>;
  removingFilter: boolean;
  removeFavouriteFilter: (id: number) => Promise<void>;
  priceRange: PriceRangeType;
  updateFavouriteFilter: (
    id: number,
    name: string,
    query: string,
    formValues: object,
  ) => Promise<void>;
};

export default createContext<Partial<NeedProvideContextProps>>({});
