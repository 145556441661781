import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import api from 'api';
import { AnyCoinTradeLimit, ListAnyCoin } from 'models/Wallet';
import { Button } from 'ncoded-component-library';
import { Form } from 'react-final-form';
import InputField from 'components/InputField';
import formValidators from 'utils/formValidators';
import { useTranslation } from 'react-i18next';
import SelectField from 'components/SelectField';
import OverlaySpinner from 'components/OverlaySpinner';
import { useWallet } from '../../WalletOutline.page';

import './AnyCoinList.styles.scss';

export const ANYCOIN_TRADE_PRICING_OPTIONS = {
  ONE_DIME: 10,
  TWO_DIME: 20,
  TREE_DIME: 30,
  FOUR_DIME: 40,
  FIVE_DIME: 50,
  SIX_DIME: 60,
  SEVEN_DIME: 70,
  EIGHT_DIME: 80,
  NINE_DIME: 90,
  DOLLAR: 100,
} as const;

const priceOptions = [
  { label: 'Select an option', value: 0 },
  { label: '0.1', value: 10 },
  { label: '0.2', value: 20 },
  { label: '0.3', value: 30 },
  { label: '0.4', value: 40 },
  { label: '0.5', value: 50 },
  { label: '0.6', value: 60 },
  { label: '0.7', value: 70 },
  { label: '0.8', value: 80 },
  { label: '0.9', value: 90 },
  { label: '1.0', value: 100 },
];

type AnyCoinListProps = {
  className?: string;
};
const AnyCoinList = (props: AnyCoinListProps) => {
  const { className } = props;
  const classes = classNames('anys-anycoin-list', className);

  const { t } = useTranslation();

  const { setWallet } = useWallet();

  const [anyCoinSellLimit, setAnyCoinSellLimit] = useState<AnyCoinTradeLimit>();

  const [anyCoinTrades, setAnyCoinTrades] = useState([]);

  const getAnyCoinTrades = useCallback(async () => {
    try {
      OverlaySpinner.show('.anys-anycoin-list');

      const { data } = await api.wallet.getAnyCoinTrades();

      if (data) {
        setAnyCoinTrades(data.items);
      }
    } catch (e) {
      console.error(e);
    } finally {
      OverlaySpinner.hide('.anys-anycoin-list');
    }
  }, []);

  const getAnyCoinTradeLimit = useCallback(async () => {
    try {
      const { data } = await api.wallet.getAnyCoinTradeLimit();
      setAnyCoinSellLimit(data);
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    getAnyCoinTradeLimit();
  }, [getAnyCoinTradeLimit]);

  useEffect(() => {
    getAnyCoinTrades();
  }, [getAnyCoinTrades]);

  const onListAnyCoin = useCallback(
    async (values: ListAnyCoin) => {
      const params: ListAnyCoin = {
        ...values,
        numberOfCoins: values.numberOfCoins,
      };

      try {
        OverlaySpinner.show('.anys-anycoin-list');
        const { data } = await api.wallet.sellAnyCoins(params);

        if (data) {
          setAnyCoinTrades((old) => [
            ...old,
            {
              id: data.id,
              price: data.price,
              numberOfCoins: data.numberOfCoins,
            },
          ]);

          setAnyCoinSellLimit((old) => ({
            tradeableAmount: old.tradeableAmount - values.numberOfCoins * 100,
            totalAmount: old.totalAmount - values.numberOfCoins * 100,
            untradeableAmount: old.untradeableAmount,
          }));

          setWallet((old) => ({
            ...old,
            funds: old.funds.map((fund) => {
              if (fund.currency === 'ANY') {
                return {
                  ...fund,
                  balance: fund.balance - values.numberOfCoins * 100,
                };
              } else return fund;
            }),
          }));
        }
      } catch (e) {
        console.error(e);
      } finally {
        OverlaySpinner.hide('.anys-anycoin-list');
      }
    },
    [setWallet],
  );

  const { composeValidators, required, maxValue } = formValidators;

  const validators = useMemo(
    () => ({
      list: composeValidators(
        required<string>(t('General.required')),
        maxValue(
          'You do not have this amount of tradeable anycredits',
          anyCoinSellLimit?.tradeableAmount,
          true,
        ),
      ),
      price: required<string>(t('General.required')),
    }),
    [
      anyCoinSellLimit?.tradeableAmount,
      composeValidators,
      maxValue,
      required,
      t,
    ],
  );

  const handleUnsellAnyCoin = useCallback(
    async (id: number, numberOfCoins: number) => {
      try {
        OverlaySpinner.show('.anys-anycoin-list');

        await api.wallet.unsellAnyCoins(id);

        //here update list
        //update total amount
        setAnyCoinTrades((old) => old.filter((trade) => trade.id !== id));

        setAnyCoinSellLimit((old) => ({
          tradeableAmount: old.tradeableAmount + numberOfCoins,
          totalAmount: old.totalAmount + numberOfCoins,
          untradeableAmount: old.untradeableAmount,
        }));

        setWallet((old) => ({
          ...old,
          funds: old.funds.map((fund) => {
            if (fund.currency === 'ANY') {
              return {
                ...fund,
                balance: fund.balance + numberOfCoins,
              };
            } else return fund;
          }),
        }));
      } catch (e) {
        console.error(e);
      } finally {
        OverlaySpinner.hide('.anys-anycoin-list');
      }
    },
    [setWallet],
  );

  return (
    <div className={classes}>
      <div className="anys-anycoin-list__amount">
        <p>{t('Wallet.listSellExplanation')}</p>
        <span>AnyCredits</span>
        <span>
          {` ${(anyCoinSellLimit?.tradeableAmount || 0) / 100} / ${
            (anyCoinSellLimit?.totalAmount || 0) / 100
          }`}
        </span>
      </div>

      <Form
        onSubmit={onListAnyCoin}
        render={({ handleSubmit, values, form }) => {
          return (
            <form
              onSubmit={(event) => {
                handleSubmit(event).then(() => {
                  form.restart();
                });
              }}
            >
              <div className="anys-anycoin-list__fields">
                <InputField
                  label="List"
                  name="numberOfCoins"
                  type="number"
                  isPureNumberInput
                  validate={validators.list}
                  parse={(value: string) => (value ? (+value as any) : value)}
                />

                <SelectField
                  name="price"
                  options={priceOptions}
                  label="Price"
                />

                <div className="anys-anycoin-list__total">
                  <span>Total</span>
                  <span>
                    {values?.numberOfCoins &&
                      values?.price &&
                      `${(values?.numberOfCoins * values?.price) / 100}$`}
                  </span>
                </div>
              </div>

              <Button type="submit">List</Button>
            </form>
          );
        }}
      />

      <ul>
        {anyCoinTrades.map(({ numberOfCoins, price, id }) => (
          <li key={id}>
            <span>
              {`${numberOfCoins} ${
                numberOfCoins === 1 ? 'AnyCredit' : 'AnyCredits'
              } x  ${price / 100}$ = ${(numberOfCoins * price) / 100}$`}
            </span>

            <Button
              variant="link"
              onClick={() => handleUnsellAnyCoin(id, numberOfCoins)}
            >
              <span>remove</span>
            </Button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AnyCoinList;
