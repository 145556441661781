import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Job, JobPost } from 'models/Job';
import { useTranslation } from 'react-i18next';
import DataPiecePreview from '../DataPiecePreview';
import {
  getFreeCancellationChanges,
  isPendingChanges,
} from 'utils/job-changes';
import ToolTip from 'components/ToolTip';
import WarningIcon from 'icons/Warning.icon';
import JobChanges from 'components/JobChanges';
import { formatMoney } from 'utils/currency';
import GradientText from 'components/GradientText';
import { Currency } from 'constants/currency';

import './ClientCancelationPreview.styles.scss';
import ReactPlayer from 'react-player';

type ClientCancelationPreviewProps = {
  className?: string;
  jobPrice: number;
  currency: Currency;
} & Pick<JobPost, 'freeCancelation'> &
  Pick<Job, 'prevVersion'> &
  Required<Pick<Job, 'versionState' | 'isLockedFreeCancelation'>>;

const ClientCancelationPreview: React.FC<ClientCancelationPreviewProps> = (
  props,
) => {
  const {
    className,
    freeCancelation,
    prevVersion,
    versionState,
    isLockedFreeCancelation,
    jobPrice,
    currency,
  } = props;

  const { t } = useTranslation();

  const classes = classNames('anys-client-cancelation-preview', className);

  const messages = useMemo(
    () => ({
      clientCancellation: t('JobForm.freeCancellationPolicy'),
      freeCancellation: t('JobForm.freeCancellation'),
      cancellationFee: t('General.cancellationFee'),
      yes: t('General.yes'),
      no: t('General.no'),
      strictCancelation: t('General.strictCancellation'),
      strictCancellationMessage: t('JobForm.strictCancellationMessage'),
      totalFee: t('General.totalFee'),
    }),
    [t],
  );

  const { isStrict, cancelationFee, cancelationHours } = freeCancelation || {};

  // If we have free cancellation,
  // the 'freeCancelation` prop is null
  const hasFreeCancelation = !freeCancelation;

  const displayedCancellationFee = isStrict
    ? messages.strictCancelation
    : `${cancelationFee}%`;

  const nonStrictCancellationMessage = t(
    'JobForm.nonStrictCancellationMessage',
    { untilHours: cancelationHours, feePercent: cancelationFee },
  );

  const cancellationChanges =
    prevVersion && isPendingChanges(versionState)
      ? getFreeCancellationChanges(
          t,
          {
            hasFreeCancelation: `${prevVersion.hasFreeCancelation}`,
            freeCancelation: prevVersion.freeCancelation,
          },
          {
            hasFreeCancelation: `${hasFreeCancelation}`,
            freeCancelation,
          },
          prevVersion.isLockedFreeCancelation,
          isLockedFreeCancelation,
          false,
        )
      : null;

  const cancellationAmount =
    jobPrice && cancelationFee ? jobPrice * (cancelationFee / 100) : null;

  const formattedCancellationAmount =
    cancellationAmount && currency
      ? formatMoney(t, {
          amount: cancellationAmount,
          currency: currency,
        })
      : null;

  return (
    <section className={classes}>
      <div className="anys-client-cancelation-preview__title">
        <h2>
          {messages.clientCancellation}
          {cancellationChanges ? (
            <ToolTip
              t={t}
              tooltipName="client-cancellation-changes"
              icon={WarningIcon}
            >
              <JobChanges
                changedFrom={cancellationChanges.changedFrom}
                changedTo={cancellationChanges.changedTo}
              />
            </ToolTip>
          ) : null}
        </h2>
        <ToolTip t={t} tooltipName="client-cancellation-tooltip">
          <ReactPlayer width="100%" url="https://youtu.be/BLHiia2NC0c" />
          <p style={{ whiteSpace: 'pre-line' }}>
            {t('Preview.clientCancellation')}
          </p>
        </ToolTip>
      </div>
      <div className="anys-client-cancelation-preview__values">
        <DataPiecePreview
          label={messages.freeCancellation}
          value={hasFreeCancelation ? messages.yes : messages.no}
        />
        {!hasFreeCancelation && (
          <DataPiecePreview
            label={messages.cancellationFee}
            value={displayedCancellationFee}
          />
        )}
        {!hasFreeCancelation && (
          <DataPiecePreview
            label={
              isStrict
                ? messages.strictCancellationMessage
                : nonStrictCancellationMessage
            }
          />
        )}

        {formattedCancellationAmount && (
          <div className="anys-client-cancelation-preview__values__total-fee">
            <span>{messages.totalFee}</span>

            <GradientText gradientType="pink">
              {formattedCancellationAmount}
            </GradientText>
          </div>
        )}
      </div>
    </section>
  );
};

export default ClientCancelationPreview;
