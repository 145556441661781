import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import ChatHeader from './components/ChatHeader';
import ChatMain from './components/ChatMain';
import ChatFooter from './components/ChatFooter';
import chatSessions from 'api/chat/chatSessions';
import showToast from 'modules/showToast';
import { useTranslation } from 'react-i18next';
import { SingleChatSession } from 'models/Chat';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import ImagesPreviewModal from 'components/ImagesPreviewModal';
import { ModalRef } from 'components/Modal';
import { Picture } from 'models/User';
import useViewportHeightSetter from 'hooks/useViewportHeightSetter';
import { isMobile, isMobileSafari } from 'react-device-detect';
import bus from 'modules/bus';
import ChatNotificationsContext from 'providers/ChatNotifications/ChatNotifications.context';

import './Chat.styles.scss';
import './Chat.styles.responsive.scss';

type ChatProps = {
  className?: string;
  chatUserId?: number;
  handleCloseChat: () => void;
};

const Chat: React.FC<ChatProps> = (props) => {
  const { className, chatUserId, handleCloseChat } = props;

  const { t } = useTranslation();

  const modalRef = useRef<ModalRef>();
  const chatRef = useRef<HTMLDivElement>();

  const [createInProgress, setCreateInProgress] = useState(false);
  const [chatSession, setChatSession] = useState<SingleChatSession>(null);

  const [viewingImages, setViewingImages] = useState<{
    imgIndex: number;
    images: Picture[];
  }>({ imgIndex: -1, images: [] });

  const { currentUser } = useContext(CurrentUserContext);
  const { newMessagesBySessions, skipNotificationsForSession } = useContext(
    ChatNotificationsContext,
  );

  const { id: chatSessionId, participants } = chatSession || {};

  const classes = classNames('anys-chat', className);

  const chatParticipant = useMemo(
    () => participants?.find(({ user }) => user?.id !== currentUser?.id),
    [currentUser?.id, participants],
  );

  const handleResize = useCallback(() => {
    // When keyboard is open on Mobile safari,
    // the content is pushed out of view
    // so we scroll back to it
    if (isMobileSafari) {
      window.scroll(0, 0);
    }
  }, []);

  useViewportHeightSetter(handleResize);

  const handleInputFocus = useCallback(() => {
    bus.broadcastEvent('INPUT_FOCUS_CHANGE', true);

    // On android mobile, when keyboard is open,
    // we want to handle style because the content is pushed
    if (chatRef.current && !isMobileSafari && isMobile) {
      chatRef.current.classList.add('anys-chat--input-focused-android');
    }
  }, []);

  const handleInputBlur = useCallback(() => {
    bus.broadcastEvent('INPUT_FOCUS_CHANGE', false);

    if (chatRef.current)
      chatRef.current.classList.remove('anys-chat--input-focused-android');
  }, []);

  const openImagePreview = useCallback(
    (currImgIndex: number, images: Picture[]) => {
      setViewingImages({ imgIndex: currImgIndex, images });

      modalRef.current.open();
    },
    [],
  );

  const closeChat = useCallback(() => {
    setChatSession(null);
    handleCloseChat();
  }, [handleCloseChat]);

  useEffect(() => {
    const createChatSession = async () => {
      setCreateInProgress(true);

      try {
        const { data } = await chatSessions.createChatSession(chatUserId);

        skipNotificationsForSession(data.id);

        setChatSession(data);
      } catch (error) {
        showToast('error', t('General.error'));
      } finally {
        setCreateInProgress(false);
      }
    };

    if (chatUserId) {
      createChatSession();
    }
  }, [chatUserId, skipNotificationsForSession, t]);

  useEffect(() => {
    return () => chatSessionId && skipNotificationsForSession(null);
  }, [chatSessionId, skipNotificationsForSession]);

  return chatSession ? (
    <div className={classes} ref={chatRef} id="anys-chat">
      <ChatHeader
        chatParticipant={chatParticipant}
        onCloseChatClick={closeChat}
      />
      <ChatMain
        fetchInProgress={createInProgress}
        chatSessionId={chatSessionId}
        unreadMsgCount={
          newMessagesBySessions?.[chatSessionId]?.length ||
          chatParticipant?.unreadMessages ||
          0
        }
        openImagePreview={openImagePreview}
      />
      <ChatFooter
        chatSessionId={chatSessionId}
        onInputFocus={handleInputFocus}
        onInputBlur={handleInputBlur}
      />
      <ImagesPreviewModal
        modalRef={modalRef}
        files={viewingImages.images}
        activeIndex={viewingImages.imgIndex}
        modalName="chat-image-preview-modal"
      />
    </div>
  ) : null;
};

export default Chat;
