import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import eventSocketService from 'services/socket/eventSocketService';
import ChatNotificationsContext from './ChatNotifications.context';
import chatSessions from 'api/chat/chatSessions';
import { ChatMessage } from 'models/Chat';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import chatParticipants from 'api/chat/chatParticipants';

type ChatNotificationsProps = { children?: React.ReactNode };

const ChatNotifications: React.FC<ChatNotificationsProps> = (props) => {
  const { children } = props;

  const { currentUser } = useContext(CurrentUserContext);

  const [totalUnreadMsgCount, setTotalUnreadMsgCount] = useState(0);
  const [skippedSessionId, setSkippedSessionId] = useState<number>();
  const [newMessagesBySessions, setNewMessagesBySessions] = useState<
    Record<number, ChatMessage[]>
  >({});

  const totalUnreadNotifications = useMemo(
    () =>
      Object.values(newMessagesBySessions).reduce(
        (totalUnread, messages) => (totalUnread += messages?.length || 0),
        totalUnreadMsgCount,
      ),
    [newMessagesBySessions, totalUnreadMsgCount],
  );

  const markSessionAsRead = useCallback(
    async (chatSessionId: number, unreadMsgCount: number) => {
      try {
        await chatSessions.readSessions(chatSessionId);

        setNewMessagesBySessions((oldMsgs) => ({
          ...oldMsgs,
          [chatSessionId]: undefined,
        }));

        setTotalUnreadMsgCount((oldTotal) =>
          Math.max(oldTotal - (unreadMsgCount || 0), 0),
        );
      } catch (e) {
        console.error(e);
      }
    },
    [],
  );

  const skipNotificationsForSession = useCallback((chatSessionId: number) => {
    setSkippedSessionId(chatSessionId);
  }, []);

  useEffect(() => {
    if (!currentUser?.id) return;

    const handleMessageCreated = (message: ChatMessage) => {
      const { session, sender } = message;

      const { id: sessionId } = session || {};

      if (sender?.id === currentUser.id || sessionId === skippedSessionId)
        return;

      setNewMessagesBySessions((oldMsgs) => ({
        ...oldMsgs,
        [sessionId]: [...(oldMsgs?.[sessionId] || []), message],
      }));
    };

    eventSocketService.addListener(
      'chat-message-created',
      handleMessageCreated,
    );

    return () => {
      eventSocketService.removeListener(
        'chat-message-created',
        handleMessageCreated,
      );
    };
  }, [currentUser?.id, skippedSessionId]);

  useEffect(() => {
    if (!currentUser?.id) return;

    const getUnreadMsgCount = async () => {
      try {
        const { data } = await chatParticipants.getUnreadMsgCount();

        setTotalUnreadMsgCount(data.count);
      } catch (error) {
        console.error(error);
      }
    };

    getUnreadMsgCount();
  }, [currentUser?.id]);

  return (
    <ChatNotificationsContext.Provider
      value={{
        newMessagesBySessions,
        totalUnreadNotifications,
        markSessionAsRead,
        skipNotificationsForSession,
      }}
    >
      {children}
    </ChatNotificationsContext.Provider>
  );
};

export default ChatNotifications;
