import React from 'react';

export default () => (
  <svg
    width="44"
    height="45"
    viewBox="0 0 44 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.5 12C24.5 10.6193 23.3807 9.5 22 9.5C20.6193 9.5 19.5 10.6193 19.5 12C19.5 13.3807 20.6193 14.5 22 14.5C23.3807 14.5 24.5 13.3807 24.5 12Z"
      fill="#50A8FA"
    />
    <path
      d="M18.5 18.5C18.5 17.3954 19.3954 16.5 20.5 16.5H22.5C23.6046 16.5 24.5 17.3954 24.5 18.5V30.5H26C27.1046 30.5 28 31.3954 28 32.5C28 33.6046 27.1046 34.5 26 34.5H19C17.8954 34.5 17 33.6046 17 32.5C17 31.3954 17.8954 30.5 19 30.5H20.5V20.5C19.3954 20.5 18.5 19.6046 18.5 18.5Z"
      fill="#50A8FA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 0.5C15.9255 0.5 10.4227 2.96463 6.44366 6.94363C2.46465 10.9227 0 16.4255 0 22.5C0 28.5745 2.46463 34.0773 6.44364 38.0563C10.4227 42.0353 15.9255 44.5 22 44.5C28.0745 44.5 33.5773 42.0353 37.5563 38.0563C41.5353 34.0773 44 28.5745 44 22.5C44 16.4255 41.5353 10.9227 37.5563 6.94365C33.5773 2.96464 28.0745 0.5 22 0.5ZM9.27206 9.77208C12.5317 6.51253 17.0289 4.5 22 4.5C26.9711 4.5 31.4683 6.51253 34.7279 9.77208C37.9875 13.0317 40 17.5289 40 22.5C40 27.4711 37.9874 31.9683 34.7279 35.2279C31.4683 38.4874 26.9711 40.5 22 40.5C17.0289 40.5 12.5317 38.4875 9.27208 35.2279C6.01253 31.9683 4 27.4711 4 22.5C4 17.5289 6.0125 13.0317 9.27206 9.77208Z"
      fill="#50A8FA"
    />
  </svg>
);
