import React, { useMemo } from 'react';
import { Field, FieldRenderProps, UseFieldConfig } from 'react-final-form';
import FileInput, {
  FileInputProps,
} from 'components/FileInput/FileInput.component';
import formValidators from 'utils/formValidators';
import Notice from 'components/Notice';

type FileInputFieldProps = FileInputProps & { name: string } & UseFieldConfig<
    File[],
    File[]
  >;

type FileInputFieldComponentProps = FileInputProps &
  FieldRenderProps<File[], HTMLElement>;

const FileInputFieldComponent: React.FC<FileInputFieldComponentProps> = (
  props,
) => {
  const { input, meta, ...restOfProps } = props;

  const { hasError, error } = useMemo(
    () => formValidators.getErrorFromMeta(meta),
    [meta],
  );

  return (
    <>
      <FileInput {...restOfProps} {...input} />
      {hasError && <Notice type="warn">{error}</Notice>}
    </>
  );
};

const FileInputField: React.FC<FileInputFieldProps> = (props) => (
  <Field component={FileInputFieldComponent} {...props} />
);

export default FileInputField;
