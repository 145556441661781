import React, { useCallback, useState } from 'react';
import Rating from 'components/Rating';
import MiniAvatar from 'components/MiniAvatar';
import utils from 'utils';
import useCallbackRef from 'hooks/useCallbackRef';
import { ModalRef } from 'components/Modal';
import ChangeReviewModal from '../ChangeReviewModal';
import classNames from 'classnames';
import { Review as ReviewType } from 'models/Reviews';

import './Review.styles.scss';
import './Review.styles.responsive.scss';
import { formatUserDisplayName } from 'utils/user';

type ReviewProps = {
  className?: string;
  review: ReviewType;
  isSkillReview: boolean;
};

const Review: React.FC<ReviewProps> = ({
  isSkillReview,
  className,
  review,
}) => {
  const { rating, createdAt, content, reviewee } = review || {};

  const { profileImage } = reviewee || {};

  // const {
  //   currentUser: { id: profileId },
  // } = useContext(CurrentUserContext);

  const [reviewContent, setReviewContent] = useState(content);

  const handleReviewChange = useCallback(
    (newContent: any) => setReviewContent(newContent),
    [],
  );

  const [changeReviewModal, changeReviewModalRef] =
    useCallbackRef<ModalRef>(null);

  const closeSkillsModal = useCallback(() => {
    changeReviewModal.close();
  }, [changeReviewModal]);

  const classes = classNames('skills-modal__review', className);

  return (
    <li className={classes}>
      <div className="skills-modal__review__info">
        <MiniAvatar
          className="skills-modal__review__info__avatar"
          userName={formatUserDisplayName(reviewee)}
          profileImage={profileImage}
          isSkillReview={isSkillReview}
        />
        {/* TODO: uncoment when add fun to change review */}
        {/* {profileId === revieweeId && (
          <Button
            onClick={() => {
              changeReviewModal.open();
            }}
            type="button"
            variant="link"
          >
            <span>Change</span>
          </Button>
        )} */}
        <div className="skills-modal__review__info__rating">
          <Rating
            withValue
            value={rating}
            valueFormat={(val) => val}
            reviewFormat={() => ''}
            maxReviewScore={isSkillReview ? 10 : 5}
            useSmily={!isSkillReview}
          />
          <div className="skills-modal__review__info__rating__date">
            {utils.humanizeIsoDate(createdAt)}
          </div>
        </div>
      </div>
      <p>{reviewContent}</p>
      <ChangeReviewModal
        review={{ ...review, content: reviewContent }}
        updateContent={handleReviewChange}
        changeReviewModalRef={changeReviewModalRef}
        onClose={closeSkillsModal}
      />
    </li>
  );
};

export default Review;
