import React, { useCallback, useEffect, useRef } from 'react';
import FilterSelect, {
  FilterSelectProps,
} from 'components/FilterSelect/FilterSelect.component';
import useLocationAutocomplete, {
  CURRENT_LOCATION_ID,
} from 'hooks/useLocationAutocomplete';
import { PlaceFormat, PlaceSearchParam } from 'models/User';
import { DropdownRef } from 'ncoded-component-library/build/components/molecules/Dropdown/Dropdown.component';
import Filter from 'components/Filter';
import { OptionValue } from 'ncoded-component-library/build/components/molecules/Select/Select.component';
import { useSearchParams } from 'react-router-dom';

import './LocationFilterSelect.styles.scss';

type LocationFilterSelectProps = {
  paramToValueParser: (paramValue: string) => PlaceSearchParam;
} & Omit<React.ComponentProps<typeof Filter>, 'component'>;

const LocationFilterSelect = (
  props: Omit<
    FilterSelectProps<string>,
    | 'options'
    | 'searchable'
    | 'async'
    | 'value'
    | 'onChange'
    | 'type'
    | 'multiple'
  > &
    LocationFilterSelectProps,
) => {
  const {
    className,
    valueMap: propValueMap,
    valueParse: propValueParse,
    name,
    paramToValueParser,
    ...rest
  } = props;
  const [params] = useSearchParams();

  const dropdownRef = useRef<DropdownRef>();

  const onPlaceSelect = useCallback((place: PlaceFormat) => {
    dropdownRef.current?.setIsOpen(false);
  }, []);

  const valueFromParam = params.get(name)
    ? paramToValueParser(params.get(name))
    : null;

  const {
    query,
    selectOptions,
    onInputChange,
    getPlaceDetails,
    setPredictions,
  } = useLocationAutocomplete({
    value: valueFromParam,
    useProfileLocation: true,
    queryRemovesValue: false,
    withBounds: true,
    onPlaceSelect,
  });

  const valueMap = useCallback(
    async (val: OptionValue) => {
      const locationText =
        typeof val.label === 'string' ? val.label : val.label?.text;

      const isCurrentLocation = val.value === CURRENT_LOCATION_ID;

      const searchValueMaybe = 'searchValue' in val ? val.searchValue : null;

      const locationTextFinal = locationText || searchValueMaybe;

      const details = await getPlaceDetails(
        val.value,
        isCurrentLocation ? undefined : locationTextFinal,
      );

      if (!details) return null;

      const value = { ...details, placeId: val.value };

      if (propValueMap) {
        return propValueMap(value);
      }

      return JSON.stringify(value);
    },
    [getPlaceDetails, propValueMap],
  );

  const valueParse = useCallback(
    (val: string) => {
      if (propValueParse) {
        return propValueParse(val);
      }

      const parsed = JSON.parse(val) as PlaceSearchParam;

      return parsed?.placeId;
    },
    [propValueParse],
  );

  useEffect(() => {
    if (query) return;

    setPredictions((old) => old.filter(({ id }) => id === CURRENT_LOCATION_ID));
  }, [query, setPredictions]);

  return (
    <Filter
      {...rest}
      name={name}
      component={FilterSelect}
      multiple={false}
      async
      options={selectOptions}
      valueMap={valueMap}
      valueParse={valueParse}
      searchable
      onSearchChange={onInputChange}
      inputValue={query}
      className="anys-location-filter-select"
      modalClassName="anys-location-filter-select__modal"
    />
  );
};

export default LocationFilterSelect;
