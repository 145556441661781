import { useCallback, useContext, useMemo, useState } from 'react';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import { useLocation, useNavigate } from 'react-router-dom';
import api from 'api';
import { JobPost, JobState, JobType } from 'models/Job';
import { useTranslation } from 'react-i18next';
import { User } from 'models/User';
import useInboxLink from 'router/subrouters/Inbox/hooks/useInboxLink';
import showToast from 'modules/showToast';
import { AxiosError } from 'axios';

export type JobPostSignProps = {
  jobId: number;
  jobType: JobType;
  jobState: JobState;
  jobCreator: User;
  trackingData: string;
  updateJob?: (job: JobPost) => void;
  onSignToggle?: (hasSigned: boolean) => void;
};

const useJobPostSign = (props: JobPostSignProps) => {
  const {
    jobId,
    jobType,
    jobState,
    jobCreator,
    trackingData,
    updateJob,
    onSignToggle,
  } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [signing, setSigning] = useState(false);
  const { currentUser } = useContext(CurrentUserContext);

  const { createEntityLink } = useInboxLink();

  const isInbox = location.pathname.startsWith('/inbox');

  const isJobCreated = !!jobId;
  const isProvideJob = jobType === 'Provide';
  const isJobDraft = jobState === 'Draft';

  const isCurrentUserJobCreator = currentUser?.id === jobCreator?.id;

  const { client, provider } = useMemo(() => {
    // This is the case where only one user can see it;
    // (the one who created the job).
    if (!isJobCreated || isJobDraft) {
      return {
        client: isProvideJob ? null : currentUser,
        provider: isProvideJob ? currentUser : null,
      };
    }

    if (isProvideJob) {
      return {
        client: isCurrentUserJobCreator ? null : currentUser,
        provider: jobCreator,
      };
    }

    return {
      client: jobCreator,
      provider: isCurrentUserJobCreator ? null : currentUser,
    };
  }, [
    currentUser,
    isCurrentUserJobCreator,
    isJobCreated,
    isJobDraft,
    isProvideJob,
    jobCreator,
  ]);

  const isCurrentUserClient = currentUser?.id === client?.id;

  const handleTrackBuy = useCallback(async () => {
    if (!trackingData) return;

    try {
      api.positionBoostMetrics.trackBuy(trackingData);
    } catch (error) {
      console.error(error);
    }
  }, [trackingData]);

  const reserveJobPost = useCallback(
    async (isEdit = false) => {
      const {
        data: { job },
      } = await api.jobPost.reserveJobPost(jobId);

      handleTrackBuy();

      // TODO: `isEdit` is never `true`, check why this was added
      if (isEdit) {
        navigate(
          isInbox
            ? createEntityLink('edit', 'job', job.commonId)
            : `/job/edit/${job.commonId}`,
        );
      } else {
        const {
          data: { job: signedJob, contractCommonId },
        } = await api.job.signJob(job.commonId, job.version);

        if (contractCommonId) {
          navigate(
            isInbox
              ? createEntityLink('view', 'contract', contractCommonId)
              : `/contract/${contractCommonId}`,
            { replace: true },
          );
        } else {
          navigate(
            isInbox
              ? createEntityLink('view', 'job', signedJob.commonId)
              : `/job/${signedJob.commonId}`,
            { replace: true },
          );
        }
      }
    },
    [createEntityLink, handleTrackBuy, isInbox, jobId, navigate],
  );

  const signJob = useCallback(
    async (jobIdFromForm?: number) => {
      setSigning(true);

      const shouldReserve = isProvideJob
        ? isCurrentUserClient
        : !isCurrentUserClient;

      try {
        if (shouldReserve) {
          await reserveJobPost();
        } else {
          const {
            data: { jobPost },
          } = await api.jobPost.signJobPost(jobIdFromForm || jobId);

          updateJob?.(jobPost);
        }
      } catch (error) {
        const err = error as AxiosError;

        showToast(
          'error',
          t('General.signError'),
          err?.response?.data?.error?.message,
        );

        console.error(error);
      } finally {
        setSigning(false);
      }
    },
    [isCurrentUserClient, isProvideJob, jobId, reserveJobPost, t, updateJob],
  );

  const unsignJob = useCallback(async () => {
    setSigning(true);

    try {
      const { data } = await api.jobPost.unsignJobPost(jobId);

      updateJob?.(data.jobPost);
    } catch (error) {
      const err = error as AxiosError;

      showToast(
        'error',
        t('General.unsignError'),
        err?.response?.data?.error?.message,
      );

      console.error(error);
    } finally {
      setSigning(false);
    }
  }, [jobId, t, updateJob]);

  const toggleSign = useCallback(
    async (hasSigned: boolean, callBack?: () => void, formSigning = false) => {
      if (!jobId && !formSigning) {
        onSignToggle?.(!hasSigned);
        return;
      }

      if (hasSigned) {
        await unsignJob();
      } else {
        await signJob();
      }

      callBack?.();
    },
    [jobId, onSignToggle, signJob, unsignJob],
  );

  return {
    client,
    provider,
    isCurrentUserClient,
    signing,
    reserveJobPost,
    toggleSign,
    signJob,
  };
};

export default useJobPostSign;
