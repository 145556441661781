import React, { ReactNode } from 'react';
import classNames from 'classnames';
import './MainTemplate.styles.scss';
import './MainTemplate.styles.responsive.scss';

type MainTemplateProps = {
  className?: string;
  asideLeft?: ReactNode;
  body?: ReactNode;
  asideRight?: ReactNode;
  as?: 'main' | 'div';
};

const MainTemplate: React.FC<React.PropsWithChildren<MainTemplateProps>> = (
  props,
) => {
  const {
    children,
    className,
    asideLeft,
    body,
    asideRight,
    as = 'main',
  } = props;

  const classes = classNames(
    'anys-main-template',
    {
      'no-left': !asideLeft,
      'no-right': !asideRight,
    },
    className,
  );

  const HTMLEl = as;

  return (
    <HTMLEl className={classes}>
      {asideLeft && (
        <aside className="anys-main-template__left">{asideLeft}</aside>
      )}
      {body}
      {children}
      {asideRight && (
        <aside className="anys-main-template__right">{asideRight}</aside>
      )}
    </HTMLEl>
  );
};

export default MainTemplate;
