import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import type { ExtractPropTypes, NumberRange } from 'types';
import { RangeSlider } from 'ncoded-component-library';
import { Field, FieldRenderProps, UseFieldConfig } from 'react-final-form';
import { RangeValueType } from 'ncoded-component-library/build/components/molecules/RangeSlider/RangeSlider.component';

import utils from 'utils';

import './PriceRangeField.styles.scss';

type PriceRangeProps = ExtractPropTypes<typeof RangeSlider>;

export type RangeFieldProps = PriceRangeProps &
  UseFieldConfig<NumberRange> & { name: string };

type PriceRangeFieldProps = PriceRangeProps &
  FieldRenderProps<NumberRange, HTMLElement> & {
    className?: string;
    label?: string;
    tooltip?: React.FC;
  };

const PriceRangeFieldComponent: React.FC<PriceRangeFieldProps> = (props) => {
  const {
    input,
    meta: { touched, error },
    className,
    min,
    max,
    label,
    tooltip,
    variant = 'double',
    ...rest
  } = props;

  const { value: pValue, onChange, ...restInput } = input;

  const hasError = useMemo(() => touched && error, [error, touched]);

  const classes = classNames(
    'anys-price-range-field',
    { error: hasError },
    className,
  );

  const value = useMemo(
    () =>
      ({
        min: pValue.from ? utils.getUnitValue(pValue.from) : min,
        max: pValue.to ? utils.getUnitValue(pValue.to) : max,
      } as RangeValueType),
    [max, min, pValue.from, pValue.to],
  );

  const handleOnChange = useCallback(
    (values: RangeValueType) => {
      const rangeValue = {
        from: values.min.toFixed(0),
        to: values.max.toFixed(0),
      } as NumberRange;

      onChange(variant === 'single' ? rangeValue.from : rangeValue);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [variant],
  );

  return (
    <div className={classes}>
      <RangeSlider
        {...rest}
        {...restInput}
        min={min}
        max={max}
        variant={variant}
        value={value}
        onChange={handleOnChange}
      />
    </div>
  );
};

const PriceRangeField: React.FC<RangeFieldProps> = (props) => {
  return <Field component={PriceRangeFieldComponent} {...props} />;
};

export default PriceRangeField;
