import { EnhancedRouteProps } from 'router/routes/Enhanced/Enhanced.route';
import ContractPreview from './pages/ContractPreview';
import EditContract from './pages/EditContract';

export default [
  {
    path: ':id',
    element: ContractPreview,
  },
  {
    path: 'edit/:id',
    element: EditContract,
  },
  //   {
  //     index: true,
  //     element: () => <Navigate to=":id" replace />,
  //   },
  //   {
  //     path: '',
  //     element: () => <Navigate to="/" replace />,
  //   },
] as Array<EnhancedRouteProps>;
