import Avatar from 'components/Avatar';
import AddWithBorderIcon from 'icons/AddWithBorder.icon';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import GradientText from 'components/GradientText';
import classNames from 'classnames';

import './DropzoneTrigger.styles.scss';

type DropzoneTriggerProps = {
  className?: string;
  formattedMaxSize: string;
  uploadFileTypeMsg: string;
};

const DropzoneTrigger: React.FC<DropzoneTriggerProps> = (props) => {
  const { className, formattedMaxSize, uploadFileTypeMsg } = props;

  const { t } = useTranslation();

  const classes = classNames('anys-dropzone-trigger', className);

  return (
    <div className={classes}>
      <div className="anys-dropzone-trigger__avatar-wrapper">
        <AddWithBorderIcon />
        <Avatar />
      </div>
      <div className="anys-dropzone-trigger__label">
        <div className="anys-dropzone-trigger__label__main">
          {t('General.dragDropEntity', {
            entity: uploadFileTypeMsg,
          })}
        </div>
        <div className="anys-dropzone-trigger__label__desc">
          <Trans
            i18nKey="General.dragDropAltText"
            values={{ text: t('General.here').toLowerCase() }}
            components={{
              wrapper: (
                <GradientText className="anys-dropzone-trigger__label__desc__gradient" />
              ),
            }}
          />
        </div>
      </div>
      <div className="anys-dropzone-trigger__max-size">
        {t('General.maxFileSize', { max: formattedMaxSize })}
      </div>
    </div>
  );
};

export default DropzoneTrigger;
