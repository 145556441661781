import React, { useCallback, useContext, useEffect, useMemo } from 'react';

import ApproveDialog from './components/ApproveDialog';
import { Contract } from 'models/Contract';
import ContractPreviewActions from './components/ContractPreviewActions';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import Notice from 'components/Notice';
import PaymentNegotiation from './components/PaymentNegotiation';
import RequestPaymentClientPreview from './components/RequestPaymentClientReview';
import RequestPaymentModal from './components/RequestPaymentModal';
import api from 'api';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { useContract } from '../Contract/Contract.page';
import { useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import JobPostPreview from 'components/JobPostPreview';
import utils from 'utils';
import StickyBottomContent from 'components/StickyBottomContent';
import UserScheduleContext from 'components/JobPostPreview/components/UserScheduleModal/UserScheduleProvider/UserSchedule.context';
import ContractSummaryPayModal from './components/ContractSummaryPayModal';
import useCallbackRef from 'hooks/useCallbackRef';
import { ModalRef } from 'components/Modal';
import { getPriceChanges } from 'utils/job-changes';
import { calcJobPrice } from 'utils/job';
import { parseTimeAndPricing } from 'utils/job-parses';
import { TFunction } from 'i18next';

import './ContractPreview.styles.scss';

// type ContractWaitingStatus = 'waitOnProvider' | 'waitOnClient';

const checkIfPriceChanged = (t: TFunction, contract: Contract) => {
  if (!contract) return false;

  const { timeAndPricing, prevVersion } = contract;

  if (!prevVersion) return false;

  const priceChanges = getPriceChanges(
    t,
    parseTimeAndPricing(prevVersion.timeAndPricing),
    parseTimeAndPricing(timeAndPricing),
    false,
    false,
  );

  if (
    !priceChanges ||
    (!priceChanges.changedFrom?.length && !priceChanges.changedTo?.length)
  )
    return false;

  const currentPrice = calcJobPrice(parseTimeAndPricing(timeAndPricing));

  const previousPrice = calcJobPrice(
    parseTimeAndPricing(prevVersion.timeAndPricing),
  );

  return currentPrice !== previousPrice;
};

type ContractDetailsWithNoticeProps = {
  contract: Contract;
  isOwnContract: boolean;
  notice: string;
};

const ContractDetailsWithNotice = (props: ContractDetailsWithNoticeProps) => {
  const { contract, isOwnContract, notice } = props;
  const { t } = useTranslation();

  return (
    <div>
      <Notice type="default" className="mt-16">
        {t(notice, {
          finalPrice: contract?.paymentProposal?.finalPrice / 100,
        })}
      </Notice>
      <ContractDetails contract={contract} isOwnContract={isOwnContract} />
    </div>
  );
};

export const ContractDetails: React.FC<
  React.PropsWithChildren<{
    contract: Contract;
    className?: string;
    status?: string;
    isOwnContract: boolean;
  }>
> = (props) => {
  const { className, status, contract, children, isOwnContract } = props;

  const { client, provider } = contract || {};

  const { currentUser } = useContext(CurrentUserContext);

  const isCurrentUserClient = currentUser?.id === client?.id;

  return (
    <JobPostPreview
      className={className}
      contract={contract}
      hasClientSigned
      hasProviderSigned
      isCurrentUserClient={isCurrentUserClient}
      isFetching={false}
      client={client}
      provider={provider}
      handleSign={utils.noop}
      actionInProgress={false}
      isOwnJob={isOwnContract}
    >
      {status && (
        <StickyBottomContent>
          <Notice
            type="default"
            className="anys-contract-preview__status-notice"
          >
            {status}
          </Notice>
        </StickyBottomContent>
      )}
      {children}
    </JobPostPreview>
  );
};

type ContractreviewProps = {
  className?: string;
};

const ContractPreview: React.FC<ContractreviewProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();
  const { currentUser } = useContext(CurrentUserContext);
  const { setSelectedSchedule } = useContext(UserScheduleContext);

  const {
    contract: baseContract,
    fetchingContract,
    confirmOrRejectInProgress,
    setContract,
    getContract,
    confirmChanges,
  } = useContract();

  const { id } = useParams<{ id: string }>();

  const [contractSummaryModal, contractSummaryModalRef] =
    useCallbackRef<ModalRef>(null);

  const contract = useMemo(
    () =>
      baseContract && {
        ...baseContract,
        prevVersion:
          baseContract?.prevAcceptedVersion || baseContract?.prevVersion,
      },
    [baseContract],
  );

  // TODO
  // 3. proveriti period 3 cancelation

  const {
    commonId,
    timeAndPricing,
    freeCancelation,
    client,
    endProcess,
    versionState,
    prevAcceptedVersion,
  } = useMemo((): Partial<Contract> => contract || {}, [contract]);

  const isOwnContract =
    contract?.type === 'Provide'
      ? currentUser?.id === contract?.provider?.id
      : currentUser?.id === contract?.client?.id;

  const classes = classNames('anys-contract-preview', className);

  const isCurrentUserClient = currentUser?.id === client?.id;

  const currentCancelationTime = useMemo(() => {
    const endProcessTime = dayjs(endProcess?.createdAt);

    const cancelationLeftLimit = dayjs(timeAndPricing?.startDate).subtract(
      freeCancelation?.cancelationHours,
      'hour',
    );

    return endProcessTime.diff(cancelationLeftLimit) <= 0
      ? 'Period1'
      : endProcessTime.diff(dayjs(timeAndPricing?.startDate)) < 0
        ? 'Period2'
        : 'Period3';
  }, [
    endProcess?.createdAt,
    freeCancelation?.cancelationHours,
    timeAndPricing?.startDate,
  ]);

  const isAwaitingPayment = versionState === 'Awaiting payment';

  const isClientPaymentPending = isCurrentUserClient && isAwaitingPayment;

  const hasPriceChanged = checkIfPriceChanged(t, baseContract);

  const maxCancelationFee = useMemo(() => {
    const percent = isAwaitingPayment
      ? prevAcceptedVersion?.freeCancelation?.cancelationFee
      : freeCancelation?.cancelationFee;
    const totalAmount = isAwaitingPayment
      ? prevAcceptedVersion?.timeAndPricing?.price
      : timeAndPricing?.price;

    return (totalAmount / 100) * (percent / 100 || 1);
  }, [
    freeCancelation?.cancelationFee,
    isAwaitingPayment,
    prevAcceptedVersion,
    timeAndPricing?.price,
  ]);

  const handleConfirmChanges = useCallback(() => {
    if (!hasPriceChanged) return confirmChanges();

    contractSummaryModal.open();
  }, [confirmChanges, contractSummaryModal, hasPriceChanged]);

  useEffect(() => {
    setSelectedSchedule(null);
  }, [setSelectedSchedule, commonId]);

  const a = useMemo(
    () => (
      // eslint-disable-next-line jsx-a11y/anchor-has-content
      <a
        href={`${window.location.origin}/arbitration?arbitrationId=${contract?.arbitration?.id}`}
      ></a>
    ),
    [contract?.arbitration?.id],
  );

  return (
    <div className={classes}>
      {contract?.arbitration ? (
        <div>
          <Notice type="default" className="mt-16">
            <p>
              <Trans
                i18nKey={`${
                  contract.state === 'Active'
                    ? 'Arbitration has already been initiate for this contract, please go to'
                    : 'Atbitration is finish for this contract, you can check status'
                } <b> <a> {{link}} </a> </b>`}
                t={t}
                values={{ link: 'Arbitration screen' }}
                components={{ a, b: <b /> }}
              />
            </p>
          </Notice>
          <ContractDetails contract={contract} isOwnContract={isOwnContract} />
        </div>
      ) : (
        <>
          {!contract && fetchingContract ? (
            <div className="card">{t('General.loadingDetails')}</div>
          ) : endProcess?.id ? (
            <div>
              {endProcess?.respondedBy ? (
                <div>
                  <Notice type="default" className="mt-16">
                    {t('Contract.contractIsEndedBy', {
                      previewType: endProcess?.respondedBy,
                    })}
                  </Notice>
                  <ContractDetails
                    contract={contract}
                    isOwnContract={isOwnContract}
                  />
                </div>
              ) : (
                <>
                  {isCurrentUserClient ? (
                    endProcess?.submittedBy === 'Provider' ? (
                      <ApproveDialog
                        notice={t('Contract.providerEndedContractExplanation')}
                        explanation={endProcess?.explanation}
                        onConfirm={async (data) => {
                          try {
                            if (data.choice === 'approve') {
                              await api.contract.aproveEndContract(commonId);
                            }
                            if (data.choice === 'refuse') {
                              await api.contract.refuseEndContract(commonId);
                            }
                            getContract(+id);

                            toast.success(
                              t('Contract.successfullyEndedContract'),
                            );
                          } catch (e) {
                            console.error(e);
                          }
                        }}
                      />
                    ) : endProcess?.submittedBy === 'Client' ? (
                      // Ide preview za clienta bez akcija
                      <ContractDetails
                        contract={contract}
                        status={t(
                          'Contract.clientEndedContractWaitForFeedback',
                        )}
                        isOwnContract={isOwnContract}
                      />
                    ) : (
                      <></>
                    )
                  ) : endProcess?.submittedBy === 'Provider' ? (
                    <ContractDetails
                      contract={contract}
                      status={t(
                        'Contract.providerEndedContractWaitForFeedback',
                      )}
                      isOwnContract={isOwnContract}
                    />
                  ) : endProcess?.submittedBy === 'Client' ? (
                    freeCancelation?.isStrict ? (
                      <ApproveDialog
                        notice={t('Contract.clientEndedContractExplanation')}
                        explanation={endProcess?.explanation}
                        withAmountSlider
                        maxAmountFee={timeAndPricing?.price / 100}
                        onConfirm={async (data) => {
                          try {
                            if (data.choice === 'approve') {
                              await api.contract.aproveEndContract(commonId);
                            }
                            if (data.choice === 'refuse') {
                              await api.contract.refuseEndContract(commonId);
                            }

                            if (data?.cancelationFee) {
                              await api.contract.cancellationFeeEnd(
                                commonId,
                                +data?.cancelationFee * 100,
                              );
                            }

                            getContract(+id);

                            toast.success(
                              t('Contract.successfullyEndedContract'),
                            );
                          } catch (e) {
                            console.error(e);
                          }
                        }}
                      />
                    ) : // Klient je zavrsio, provider moze 3 perioda ona
                    currentCancelationTime === 'Period1' ? (
                      <ApproveDialog
                        // maxCancelationFee={maxCancelationFee}
                        notice={t('Contract.clientEndedContractExplanation')}
                        explanation={endProcess?.explanation}
                        onConfirm={async (data) => {
                          try {
                            if (data.choice === 'approve') {
                              await api.contract.aproveEndContract(commonId);
                            }
                            if (data.choice === 'refuse') {
                              await api.contract.refuseEndContract(commonId);
                            }

                            getContract(+id);

                            toast.success(
                              t('Contract.successfullyEndedContract'),
                            );
                          } catch (e) {
                            console.error(e);
                          }
                        }}
                      />
                    ) : currentCancelationTime === 'Period2' ? (
                      <ApproveDialog
                        withAmountSlider
                        maxAmountFee={maxCancelationFee}
                        notice={t('Contract.clientEndedContract')}
                        explanation={endProcess?.explanation}
                        onConfirm={async (data) => {
                          try {
                            if (data.choice === 'approve') {
                              await api.contract.aproveEndContract(commonId);
                            }
                            if (data.choice === 'refuse') {
                              await api.contract.refuseEndContract(commonId);
                            }

                            if (data?.cancelationFee) {
                              await api.contract.cancellationFeeEnd(
                                commonId,
                                +data?.cancelationFee * 100,
                              );
                            }

                            getContract(+id);

                            toast.success(
                              t('Contract.successfullyEndedContract'),
                            );
                          } catch (e) {
                            console.error(e);
                          }
                        }}
                      />
                    ) : (
                      //check this period3
                      // <div>Period3 Full cancelation payment</div>
                      <RequestPaymentModal
                        // modal={endContractModalRef}
                        title={t('Contract.requestPayment')}
                        maxAmountFee={maxCancelationFee}
                        onConfirm={async (values) => {
                          // const { data } = await api.contract.createReview({
                          //   commonId: commonId,
                          //   reviewerRole: 'provider',
                          //   rating: values.mark,
                          //   content: values?.explanation,
                          // });

                          await api.contract.requestPayment(commonId, {
                            isCancellation: false,
                            rating: values.mark,
                            content: values?.explanation,
                            price: +values.cancelationFee,
                          });
                        }}
                      />
                    )
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
          ) : (
            <>
              {/* {contract?.paymentProposal && }
            kad se posalje payment proposal od provajdera */}
              {contract?.paymentProposal?.finalPrice ? (
                <>
                  <ContractDetailsWithNotice
                    contract={contract}
                    isOwnContract={isOwnContract}
                    notice={
                      contract?.paymentProposal?.state === 'Client accepted'
                        ? isCurrentUserClient
                          ? 'Contract.youAcceptedPaymentRequest'
                          : 'Contract.clientAcceptedPaymentRequest'
                        : isCurrentUserClient
                          ? 'Contract.providerAcceptedPaymentProposal'
                          : 'Contract.youAcceptedPaymentProposal'
                    }
                  />
                </>
              ) : contract?.paymentProposal ? (
                contract?.paymentProposal?.state === 'Provider proposed' ? (
                  isCurrentUserClient ? (
                    contract?.reviews?.some(
                      (review) => review.revieweeId === client.id,
                    ) || contract?.paymentProposal?.isCancellation ? (
                      <PaymentNegotiation
                        title={t('Contract.paymentRequest')}
                        message={t('Contract.providerSentYouPaymentRequest')}
                        maxAmountFee={
                          contract?.paymentProposal?.providerPrice / 100 ||
                          timeAndPricing?.price / 100
                        }
                        minAmountFee={
                          contract?.paymentProposal?.clientPrice / 100 || 0
                        }
                        initialValue={
                          contract?.paymentProposal?.providerPrice / 100
                        }
                        commonId={commonId}
                        from="client"
                        notice={t(
                          'Contract.ifClientDoesNotAcceptPaymentRequest',
                        )}
                        goToArbitration={
                          contract.arbitrationOptions &&
                          contract?.paymentProposal?.numberOfClientProposals ===
                            2
                        }
                        numberOfProposals={
                          contract?.paymentProposal?.numberOfClientProposals
                        }
                        arbitrationIsAllowed={
                          contract?.arbitrationOptions?.length !== 0
                        }
                      />
                    ) : (
                      <RequestPaymentClientPreview
                        onConfirm={async (values) => {
                          try {
                            const { data } = await api.contract.createReview({
                              commonId: commonId,
                              rating: values.mark,
                              content: values?.explanation,
                            });
                            setContract((old) => ({
                              ...old,
                              reviews: [
                                ...old.reviews,
                                {
                                  contractId: data.contractId,
                                  id: data.id,
                                  rating: data.rating,
                                  revieweeId: data.revieweeId,
                                  content: data.content,
                                },
                              ],
                            }));
                          } catch (e) {
                            console.error(e);
                          }
                        }}
                      />
                    )
                  ) : (
                    <ContractDetails
                      contract={contract}
                      status={t('Contract.nowWaitOnClientFeedback')}
                      isOwnContract={isOwnContract}
                    />
                  )
                ) : //Client proposed
                isCurrentUserClient ? (
                  <ContractDetails
                    contract={contract}
                    status={t(
                      'Contract.providerSentPaymentRequestWaitForFeedback',
                      { who: "provider's" },
                    )}
                    isOwnContract={isOwnContract}
                  />
                ) : (
                  <PaymentNegotiation
                    title={t('Contract.paymentRequest')}
                    message={t('Contract.clientSentYouPaymentRequest')}
                    maxAmountFee={
                      contract?.paymentProposal?.providerPrice / 100 ||
                      timeAndPricing?.price / 100
                    }
                    minAmountFee={
                      contract?.paymentProposal?.clientPrice / 100 || 0
                    }
                    initialValue={contract?.paymentProposal?.clientPrice / 100}
                    commonId={commonId}
                    from="provider"
                    notice={t('Contract.ifProviderDoesNotAcceptPaymentRequest')}
                    goToArbitration={
                      contract?.paymentProposal?.numberOfProviderProposals === 2
                    }
                    numberOfProposals={
                      contract?.paymentProposal?.numberOfProviderProposals
                    }
                    arbitrationIsAllowed={
                      contract?.arbitrationOptions?.length !== 0
                    }
                  />
                )
              ) : (
                <ContractDetails
                  contract={contract}
                  isOwnContract={isOwnContract}
                >
                  <ContractPreviewActions
                    contract={contract}
                    hasClientSigned
                    hasProviderSigned
                    isCurrentUserClient={isCurrentUserClient}
                    maxCancelationFee={maxCancelationFee}
                    isOwnContract={isOwnContract}
                    isClientPaymentPending={isClientPaymentPending}
                    onConfirmChanges={handleConfirmChanges}
                  />
                </ContractDetails>
              )}
            </>
          )}
        </>
      )}

      <ContractSummaryPayModal
        summaryType={isCurrentUserClient ? 'client' : 'provider'}
        onClose={() => contractSummaryModal.close()}
        handlePay={confirmChanges}
        isPaying={confirmOrRejectInProgress}
        contractSummaryModalRef={contractSummaryModalRef}
      />
    </div>
  );
};

export default ContractPreview;
