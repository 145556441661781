import React from 'react';
import { Navigate } from 'react-router-dom';
import { EnhancedRouteProps } from 'router/routes/Enhanced/Enhanced.route';
import CreateEditJobPost from './pages/CreateEditJobPost';
import ViewJobPost from './pages/ViewJobPost';
import EditBoost from 'pages/EditBoost';

export default [
  {
    path: 'create',
    element: () => <Navigate to="provide" replace />,
    authorized: true,
  },
  {
    path: 'create/provide',
    element: () => <CreateEditJobPost type="create" />,
    authorized: true,
  },
  {
    path: 'create/need',
    element: () => <CreateEditJobPost type="create" />,
    authorized: true,
  },
  {
    path: 'edit/:id',
    element: () => <CreateEditJobPost type="edit" />,
    authorized: true,
  },
  {
    path: 'edit/:id/boost',
    element: EditBoost,
    authorized: true,
  },
  {
    path: ':id',
    element: () => <ViewJobPost />,
  },
  {
    index: true,
    element: () => <Navigate to="create/provide" replace />,
  },
  {
    path: '',
    element: () => <Navigate to="/" replace />,
  },
] as Array<EnhancedRouteProps>;
