import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import ArbitrationsList from './components/ArbitrationsList/ArbitrationsList.component';
import { ContractDetails } from '../Contract/pages/ContractPreview/ContractPreview.page';
import MainTemplate from 'components/templates/MainTemplate';
import MyCasesActions from './components/MyCasesActions/MyCasesActions.component';
import NoArbitrationSelectedIcon from 'icons/NoArbitrationSelected.icon';
import NoContractSelctedIcon from 'icons/NoContractSelected.icon';
import MeAsArbitrator from './components/MeAsArbitrator/MeAsArbitrator.component';
import { useArbitration } from 'providers/Arbitration/Arbitration.provider';
import useQueryParams from 'hooks/useQueryParams';
import MyTermsArbitration from './components/MyTermsArbitration/MyTermsArbitration.page';
import classNames from 'classnames';
import useHeaderContent from 'hooks/useHeaderContent';
import useHeaderStyles from 'hooks/useHeaderStyles';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'ncoded-component-library';
import useHeaderBack from 'hooks/useHeaderBack';
import useCallbackRef from 'hooks/useCallbackRef';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import Modal from 'components/Modal';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import useEntityUpdateListener from 'hooks/useEntityUpdateListener';
import NotificationsContext from 'providers/Notifications/Notifications.context';
import { ARBITRATION_TYPE_TO_PANEL_VIEW } from 'models/Notification';

import './Arbitration.styles.scss';
import './Arbitration.styles.responsive.scss';

export type ArbitrationView = 'my_cases' | 'me_as_arbitor';

const ArbitrationPage = () => {
  const { setArbitrationView, selectedArbitration, arbitrationView } =
    useArbitration();

  const { currentUser } = useContext(CurrentUserContext);

  const { arbitrationNotifications } = useContext(NotificationsContext);

  const {
    params: { my_terms, arbitrationId },
  } = useQueryParams();

  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const classes = useMemo(
    () =>
      classNames('anys-arbitration-page', {
        'anys-arbitration-page--terms': my_terms,
        'anys-arbitration-page--selected-arbitration': arbitrationId,
      }),
    [my_terms, arbitrationId],
  );

  useHeaderStyles(
    {
      showBackButton: true,
      className: 'anys-inbox__mobile-header',
    },
    [location.pathname],
  );

  const onBackClick = useCallback(() => {
    navigate(
      {
        pathname: '/arbitration',
        search: `?arbitration_view=${arbitrationView}`,
      },
      { replace: true },
    );
  }, [navigate, arbitrationView]);

  const [contractPreviewModal, contractPreviewModalRef] =
    useCallbackRef<ModalRef>(null);

  const [myTerms, myTermsRef] = useCallbackRef<ModalRef>(null);

  const isOwnContract =
    selectedArbitration?.contract?.type === 'Provide'
      ? currentUser?.id === selectedArbitration?.contract?.provider?.id
      : currentUser?.id === selectedArbitration?.contract?.client?.id;

  useHeaderBack(onBackClick);

  useHeaderContent(
    <div className="mobile-header-content">
      {`${t('General.arbitration')}  ${arbitrationId ? arbitrationId : ''}`}

      {arbitrationId ? (
        <Button
          variant="link"
          onClick={() => {
            contractPreviewModal.open();
          }}
        >
          <span>{t('General.contract')}</span>
        </Button>
      ) : (
        <Button
          variant="link"
          onClick={() => {
            myTerms.open();
          }}
        >
          <span>{t('Arbitration.myTerms')}</span>
        </Button>
      )}
    </div>,
    [arbitrationId, contractPreviewModal],
  );

  useEntityUpdateListener('arbitration-updated', +arbitrationId);

  useEffect(() => {
    const firstNotification =
      arbitrationNotifications && Object.values(arbitrationNotifications)[0];

    if (!firstNotification?.length || selectedArbitration) return;

    const { id, type, arbitration } =
      firstNotification[firstNotification.length - 1];

    const idFinal = arbitration?.id || id;

    const arbType = type as keyof typeof ARBITRATION_TYPE_TO_PANEL_VIEW;

    const arbPanel = ARBITRATION_TYPE_TO_PANEL_VIEW[arbType];

    if (idFinal && arbPanel) {
      const isMeAsArbitor = arbPanel === 'arbiter';

      const panelType = isMeAsArbitor ? 'me_as_arbitor' : 'my_cases';

      setArbitrationView(panelType);

      navigate(
        {
          pathname: '/arbitration',
          search: `?arbitration_view=${panelType}`,
        },
        { replace: true },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arbitrationNotifications, navigate]);

  return (
    <>
      <MainTemplate
        className={classes}
        asideLeft={<ArbitrationsList />}
        asideRight={
          !my_terms ? (
            selectedArbitration ? (
              <ContractDetails
                key={selectedArbitration?.id}
                className="anys-arbitration-page__contract"
                contract={selectedArbitration?.contract}
                isOwnContract={isOwnContract}
              />
            ) : (
              <section className="anys-arbitration-page__nothing-selected">
                <NoContractSelctedIcon />
                <span className="anys-arbitration-page__nothing-selected--bold">
                  {t('Arbitration.noContractSelected')}
                </span>
                {t('Arbitration.selectItemToViewContract')}
              </section>
            )
          ) : undefined
        }
      >
        <div className="anys-arbitration-page__main">
          {my_terms ? (
            <MyTermsArbitration />
          ) : selectedArbitration ? (
            arbitrationView === 'my_cases' ? (
              <MyCasesActions />
            ) : (
              <MeAsArbitrator />
            )
          ) : (
            <section className="anys-arbitration-page__nothing-selected">
              <NoArbitrationSelectedIcon />
              <span className="anys-arbitration-page__nothing-selected--bold">
                {t('General.noItemsSelected')}
              </span>
              {t('Arbitration.selectItemFromList')}
            </section>
          )}
        </div>
      </MainTemplate>
      {/* MODALS ARE USED ONLY ON MOBILE RESOLUTION */}
      <Modal
        type="no-action"
        modalName="contract-preview"
        ref={contractPreviewModalRef}
        isDrawer
        hideHeader
      >
        <ContractDetails
          className="anys-arbitration-page__contract"
          contract={selectedArbitration?.contract}
          isOwnContract={isOwnContract}
        />
      </Modal>

      <Modal
        type="no-action"
        modalName="my-terms"
        ref={myTermsRef}
        isDrawer
        hideHeader
        renderAsPortal={false}
      >
        <MyTermsArbitration />
      </Modal>
    </>
  );
};

export default ArbitrationPage;
