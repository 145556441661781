import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import Card from 'components/Card';
import RadioGroupField from 'components/RadioGroupField';
import { JOB_PRICING_DURATION_TYPE, JOB_PRICING_TYPE } from 'constants/job';
import utils from 'utils';
import {
  FormJobTimeAndPricing,
  JobPricingDurationType,
  JobPricingType,
} from 'models/Job/TimeAndPricing';
import InputField from 'components/InputField';
import ToolTip from 'components/ToolTip';
import { Job, JobNegotiableSections, JobPost } from 'models/Job';
import SelectField from 'components/SelectField';
import { convertToMoney, formatMoney } from 'utils/currency';
import TextAreaField from 'components/TextAreaField';
import DateTimeField from 'components/DateTimeField';
import CurrencyInputField from 'components/CurrencyInputField';
import formValidators from 'utils/formValidators';
import dayjs from 'dayjs';
import { FormApi } from 'final-form';
import TimelineLinePart from 'components/Timeline/components/TimelineLinePart';
import CheckboxField from 'components/CheckboxField';
import GradientText from 'components/GradientText';
import { TFunction } from 'i18next';
import type { OptionValue } from 'ncoded-component-library/build/components/molecules/Select/Select.component';
import { getTimeAndPricingChanges, isPendingChanges } from 'utils/job-changes';
import WarningIcon from 'icons/Warning.icon';
import JobChanges from 'components/JobChanges';
import api from 'api';
import { UserAvailability } from 'models/Availability';
import showToast from 'modules/showToast';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import UserScheduleContext from 'components/JobPostPreview/components/UserScheduleModal/UserScheduleProvider/UserSchedule.context';
import { Button } from 'ncoded-component-library';
import dates from 'utils/dates';

import './TimeAndPricing.styles.scss';
import './TimeAndPricing.styles.responsive.scss';
import { Trans } from 'react-i18next';

const { dateIsAfter, minValue } = formValidators;

const TODAY = new Date();

// 1h = 3600000ms
const MIN_HOUR_BOUNDARY = 1;
const EARLIEST_START_DATE = new Date(+TODAY + MIN_HOUR_BOUNDARY * 3600000);

const parseToNumber = (val: string) => (val ? (+val as any) : val);

type TimeAndPricingProps = {
  className?: string;
  t: TFunction;
  valuesFromForm: FormJobTimeAndPricing;
  initialTimeAndPricing: FormJobTimeAndPricing;
  formApi: FormApi<JobPost<'form'>>;
  disabled?: boolean;
  isOwnJob: boolean;
  isContract: boolean;
  isProvideJob: boolean;
  openUserScheduleModal: () => void;
  getLockButton: (sectionName: JobNegotiableSections) => JSX.Element;
} & Pick<Job, 'prevVersion'> &
  Required<Pick<Job, 'versionState' | 'isLockedTimeAndPricing'>>;

const TimeAndPricing: React.FC<TimeAndPricingProps> = (props) => {
  const {
    t,
    className,
    valuesFromForm,
    formApi,
    disabled,
    isOwnJob,
    prevVersion,
    initialTimeAndPricing,
    versionState,
    isLockedTimeAndPricing,
    isContract,
    isProvideJob,
    getLockButton,
    openUserScheduleModal,
  } = props;

  const titleContainerRef = useRef<HTMLDivElement>();
  const { selectedSchedule } = useContext(UserScheduleContext);
  const { currentUser } = useContext(CurrentUserContext);

  const [isFetchingAvailability, setIsFetchingAvailability] = useState(false);
  const [userAvailability, setUserAvailability] = useState<UserAvailability>();

  const isScheduleDisabled =
    !isOwnJob &&
    (isFetchingAvailability || !userAvailability?.timeslots?.length);

  const classes = classNames('anys-time-and-pricing', className);

  const {
    type: pricingType,
    currency,
    price: formPrice,
    startDate,
    endDate,
    pricingDurationType,
    minHours = 0,
    maxHours = 0,
    totalHours = 0,
    importAvailability,
  } = valuesFromForm;

  const price = useMemo(
    () => formPrice || convertToMoney(0, currency),
    [currency, formPrice],
  );

  const isPricingByProject = pricingType === 'By project';

  const isHourlyFlexible =
    !isPricingByProject && pricingDurationType === 'Flexible';

  const messages = useMemo(
    () => ({
      title: t('JobForm.timeAndPricing'),
      price: t('General.price'),
      pricePlaceholder: t('General.pricePlaceholder'),
      explanation: t('JobForm.additionalExplanation'),
      explanationPlaceholder: t('JobForm.additionalExplanationPlaceholder'),
      duration: t('General.duration'),
      minHours: t('General.minHours'),
      maxHours: t('General.maxHours'),
      pricePerHour: t('General.pricePerHour'),
      totalHours: t('General.totalHours'),
      hours: t('General.hours'),
      minHourError: t('General.minHourError', { min: 0 }),
      totalPrice: t('General.totalPrice'),
      startDateAndTime: t('General.startDateAndTime'),
      startDate: t('General.startDate'),
      startTime: t('General.startTime'),
      endDateAndTime: t('General.endDateAndTime'),
      endDate: t('General.endDate'),
      endTime: t('General.endTime'),
      earliestStartDate: t('General.earliestDateStart', {
        limit: t('General.hoursCount', { count: MIN_HOUR_BOUNDARY }),
      }),
      earliestEndDate: t('General.dateAfterError', {
        limit: t('General.startDate'),
      }),
      dateAndTimePlaceholder: t('General.dateTimePlaceholder'),
      importFromAvailability: t('General.importFromAvailability'),
      schedule: t('General.schedule'),
    }),
    [t],
  );

  const hourValidators = useMemo(
    () => minValue<string>(messages.minHourError, 0),
    [messages],
  );

  const startDateValidators = useMemo(
    () => dateIsAfter(messages.earliestStartDate, EARLIEST_START_DATE),

    [messages],
  );
  const endDateValidators = useMemo(
    () => dateIsAfter(messages.earliestEndDate, startDate),

    [messages, startDate],
  );

  const pricingOptions = useMemo(
    () =>
      Object.values(JOB_PRICING_TYPE).map((type) => ({
        label: t(`General.${utils.camelize(type)}`),
        value: type,
      })),
    [t],
  );

  const hourlyPricingOptions = useMemo(
    () =>
      Object.values(JOB_PRICING_DURATION_TYPE).map((type) => ({
        label: t(`General.${utils.camelize(type)}`),
        value: type,
      })),
    [t],
  );

  const lockButton = useMemo(
    () => getLockButton('isLockedTimeAndPricing'),
    [getLockButton],
  );

  const priceFields = useMemo(
    () => (
      <div className="anys-time-and-pricing__row">
        <CurrencyInputField
          name="timeAndPricing.price"
          placeholder={messages.pricePlaceholder}
          label={isPricingByProject ? messages.price : messages.pricePerHour}
          className="anys-time-and-pricing__row__field"
          disabled={disabled}
        />
      </div>
    ),
    [disabled, isPricingByProject, messages],
  );

  const totalPrice = useMemo(() => {
    if (isPricingByProject) return null;

    const minTotal = convertToMoney(0, currency);
    const maxTotal = convertToMoney(0, currency);

    if (isHourlyFlexible) {
      minTotal.amount = price.amount * minHours;
      maxTotal.amount = price.amount * maxHours;
    } else {
      minTotal.amount = price.amount * totalHours;
    }

    return (
      <div className="anys-time-and-pricing__total">
        <span>{messages.totalPrice}</span>
        <GradientText gradientType="pink">
          {`${formatMoney(t, minTotal)}${
            maxTotal?.amount ? ` - ${formatMoney(t, maxTotal)}` : ''
          }`}
        </GradientText>
      </div>
    );
  }, [
    currency,
    isHourlyFlexible,
    isPricingByProject,
    maxHours,
    messages.totalPrice,
    minHours,
    price.amount,
    t,
    totalHours,
  ]);

  const scheduleCTA = (
    <Button
      variant="link"
      onClick={openUserScheduleModal}
      className="anys-time-and-pricing__schedule-cta"
      disabled={isScheduleDisabled}
    >
      <span>
        {selectedSchedule
          ? dates.formatDates(
              selectedSchedule.startDate,
              selectedSchedule.endDate,
              'DD/MM/YYYY • hh:mm a',
            )
          : messages.schedule}
      </span>
    </Button>
  );

  const startDateField = useMemo(
    () => (
      <DateTimeField
        name="timeAndPricing.startDate"
        dateLabel={messages.startDate}
        timeLabel={messages.startTime}
        label={messages.startDateAndTime}
        placeholder={messages.dateAndTimePlaceholder}
        validate={startDateValidators}
        rightLimit={endDate && isPricingByProject ? dayjs(endDate) : undefined}
        disabled={!!importAvailability || disabled}
        renderAsPortal
      />
    ),
    [
      disabled,
      endDate,
      importAvailability,
      isPricingByProject,
      messages,
      startDateValidators,
    ],
  );

  const resetDeadlines = useCallback(() => {
    formApi.change('deadlines', undefined);
    formApi.change('hasDeadlinePolicy', 'false');
  }, [formApi]);

  const importFromAvailabilityField = useMemo(() => {
    if (!isProvideJob) {
      return null;
    }

    return (
      <CheckboxField
        name="timeAndPricing.importAvailability"
        label={messages.importFromAvailability}
        className="anys-time-and-pricing__import-availability"
        onChange={(checked) => {
          if (checked) {
            formApi.batch(() => {
              formApi.change('timeAndPricing', {
                ...valuesFromForm,
                startDate: null,
                endDate: null,
              });

              formApi.change('freeCancelation', undefined);
              formApi.change('hasFreeCancelation', 'true');

              resetDeadlines();
            });
          }
        }}
        disabled={disabled || !isOwnJob || isContract}
      />
    );
  }, [
    disabled,
    isProvideJob,
    formApi,
    isContract,
    isOwnJob,
    messages,
    resetDeadlines,
    valuesFromForm,
  ]);

  const timeAndPricingChanges =
    prevVersion && isPendingChanges(versionState)
      ? getTimeAndPricingChanges(
          t,
          {
            ...prevVersion.timeAndPricing,
            price: convertToMoney(
              prevVersion.timeAndPricing.price || 0,
              prevVersion.timeAndPricing.currency,
            ),
            startDate: prevVersion.timeAndPricing.startDate
              ? new Date(prevVersion.timeAndPricing.startDate)
              : null,
            endDate: prevVersion.timeAndPricing.endDate
              ? new Date(prevVersion.timeAndPricing.endDate)
              : null,
          },
          valuesFromForm,
          prevVersion.isLockedTimeAndPricing,
          isLockedTimeAndPricing,
        )
      : null;

  const handlePricingTypeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const pricingType = event.target.value as JobPricingType;

      const { type: initialType } = initialTimeAndPricing || {};

      const isInitialTypeProject = initialType === 'By project';

      if (pricingType === 'By project') {
        const resets: Partial<FormJobTimeAndPricing> = {
          pricingDurationType: undefined,
          price: null,
          minHours: null,
          maxHours: null,
          startDate: null,
          endDate: null,
          minTotalPrice: null,
          maxTotalPrice: null,
          totalHours: null,
        };

        formApi.change('timeAndPricing', {
          ...valuesFromForm,
          ...resets,
          ...(isInitialTypeProject ? initialTimeAndPricing : {}),
          type: pricingType,
        });
      } else {
        const resets: Partial<FormJobTimeAndPricing> = {
          price: null,
          additionalExplanation: '',
          startDate: null,
          endDate: null,
        };

        formApi.change('timeAndPricing', {
          ...valuesFromForm,
          ...resets,
          ...(!isInitialTypeProject ? initialTimeAndPricing : {}),
          type: pricingType,
        });
      }

      resetDeadlines();
    },
    [formApi, initialTimeAndPricing, resetDeadlines, valuesFromForm],
  );

  const onChangePricingDurationType = useCallback(
    (value: string | OptionValue) => {
      const val = value as JobPricingDurationType;
      const { pricingDurationType: initialPricingDurationType } =
        initialTimeAndPricing || {};

      const isInitialFlexible = initialPricingDurationType === 'Flexible';

      if (val === 'Strict') {
        const resets: Partial<FormJobTimeAndPricing> = {
          minHours: null,
          maxHours: null,
          minTotalPrice: null,
          maxTotalPrice: null,
        };

        formApi.change('timeAndPricing', {
          ...valuesFromForm,
          ...resets,
          ...(initialPricingDurationType && !isInitialFlexible
            ? initialTimeAndPricing
            : {}),
          pricingDurationType: val,
        });
      } else {
        const resets: Partial<FormJobTimeAndPricing> = {
          totalHours: null,
          minTotalPrice: null,
          maxTotalPrice: null,
        };

        formApi.change('timeAndPricing', {
          ...valuesFromForm,
          ...resets,
          ...(initialPricingDurationType && isInitialFlexible
            ? initialTimeAndPricing
            : {}),
          pricingDurationType: val,
        });
      }
    },
    [formApi, initialTimeAndPricing, valuesFromForm],
  );

  useEffect(() => {
    if (!selectedSchedule) return;

    titleContainerRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [selectedSchedule]);

  useEffect(() => {
    if (!currentUser?.id || !isOwnJob) return;

    const getUserAvailability = async () => {
      try {
        setIsFetchingAvailability(true);

        const { data } = await api.availability.getAvailability(currentUser.id);

        setUserAvailability(data);
      } catch (e) {
        showToast('error', t('General.error'));
      } finally {
        setIsFetchingAvailability(false);
      }
    };

    getUserAvailability();
  }, [t, currentUser?.id, isOwnJob]);

  return (
    <Card
      className={classes}
      cardTitle={
        <div className="anys-time-and-pricing__title" ref={titleContainerRef}>
          {messages.title}
          {timeAndPricingChanges ? (
            <ToolTip
              t={t}
              tooltipName="time-and-pricing-changes"
              icon={WarningIcon}
              className="anys-time-and-pricing__title__changes"
            >
              <JobChanges
                changedFrom={timeAndPricingChanges.changedFrom}
                changedTo={timeAndPricingChanges.changedTo}
              />
            </ToolTip>
          ) : null}
          <div
            className={classNames(
              'anys-time-and-pricing__row',
              'anys-time-and-pricing__row--no-gap',
            )}
          >
            {lockButton}
            <ToolTip t={t} tooltipName="tap-tooltip">
              <p style={{ whiteSpace: 'pre-line' }}>
                <Trans
                  i18nKey="Preview.timeAndPricing"
                  components={{ b: <b /> }}
                />
              </p>
            </ToolTip>
          </div>
        </div>
      }
    >
      <RadioGroupField
        name="timeAndPricing.type"
        options={pricingOptions}
        direction="row"
        onChange={handlePricingTypeChange}
        className="anys-time-and-pricing__pricing-type"
        disabled={disabled}
      />

      {isPricingByProject ? (
        <>
          {priceFields}

          {importFromAvailabilityField}

          <div className="anys-time-and-pricing__time">
            {!isOwnJob && importAvailability ? (
              scheduleCTA
            ) : (
              <div className="anys-time-and-pricing__time__dates">
                <div className="anys-time-and-pricing__time__dates__date">
                  <TimelineLinePart
                    className={classNames(
                      'anys-time-and-pricing__time__line',
                      'anys-time-and-pricing__time__line--first',
                    )}
                    isVertical
                    hasLastDot={false}
                  />
                  {startDateField}
                </div>

                <div className="anys-time-and-pricing__time__dates__date">
                  <TimelineLinePart
                    hasFirstDot={false}
                    isLastDotLarge
                    className={classNames(
                      'anys-time-and-pricing__time__line',
                      'anys-time-and-pricing__time__line--last',
                    )}
                    isVertical
                  />
                  <DateTimeField
                    name="timeAndPricing.endDate"
                    label={messages.endDateAndTime}
                    dateLabel={messages.endDate}
                    timeLabel={messages.endTime}
                    placeholder={messages.dateAndTimePlaceholder}
                    validate={endDateValidators}
                    leftLimit={startDate ? dayjs(startDate) : undefined}
                    disabled={!!importAvailability || disabled}
                    renderAsPortal
                  />
                </div>
              </div>
            )}
          </div>
          <TextAreaField
            name="timeAndPricing.additionalExplanation"
            label={messages.explanation}
            placeholder={messages.explanationPlaceholder}
            showLengthCount
            maxLength={1200}
            resize={false}
            className="anys-time-and-pricing__additional-text"
            disabled={disabled}
          />
        </>
      ) : (
        <>
          <div
            className={classNames('anys-time-and-pricing__row', {
              'anys-time-and-pricing__row--wrap': isHourlyFlexible,
            })}
          >
            <SelectField
              className={classNames('anys-time-and-pricing__row__field', {
                'anys-time-and-pricing__row__field--medium': isHourlyFlexible,
              })}
              name="timeAndPricing.pricingDurationType"
              options={hourlyPricingOptions}
              label={messages.duration}
              defaultValue={hourlyPricingOptions[0].value}
              onChange={onChangePricingDurationType}
              disabled={disabled}
            />

            <div
              className={classNames('anys-time-and-pricing__row__hour-inputs', {
                'anys-time-and-pricing__row__hour-inputs--min-width':
                  isHourlyFlexible,
              })}
            >
              <InputField
                type="number"
                isPureNumberInput
                name={`timeAndPricing.${
                  isHourlyFlexible ? 'minHours' : 'totalHours'
                }`}
                className={classNames(
                  'anys-time-and-pricing__row__field',
                  'anys-time-and-pricing__row__field--small',
                )}
                label={
                  isHourlyFlexible ? messages.minHours : messages.totalHours
                }
                parse={parseToNumber}
                validate={hourValidators}
                min="0"
                placeholder={messages.hours}
                disabled={disabled}
              />
              {isHourlyFlexible && (
                <InputField
                  type="number"
                  isPureNumberInput
                  name="timeAndPricing.maxHours"
                  className={classNames(
                    'anys-time-and-pricing__row__field',
                    'anys-time-and-pricing__row__field--small',
                  )}
                  label={messages.maxHours}
                  parse={parseToNumber}
                  validate={hourValidators}
                  min="0"
                  placeholder={messages.hours}
                  disabled={disabled}
                />
              )}
            </div>
          </div>
          <div className="anys-time-and-pricing__row">
            {importFromAvailabilityField}
          </div>
          <div className="anys-time-and-pricing__row">
            {!isOwnJob && importAvailability ? (
              <div className="anys-time-and-pricing__schedule-cta--hourly">
                {scheduleCTA}
              </div>
            ) : (
              startDateField
            )}
          </div>
          {priceFields}
        </>
      )}

      {totalPrice}
    </Card>
  );
};

export default TimeAndPricing;
