import { Sign, YesNo } from 'components/JobFilters/JobFilters.component';
import { UserType } from 'constants/filters';
import { JobPricingType } from 'models/Job/TimeAndPricing';
import { FormJobTypeOfService } from 'models/Job/TypeOfService';
import { Skill } from 'models/Skills';
import { useTranslation } from 'react-i18next';
import { convertToMoney, formatMoney } from 'utils/currency';
import dates from 'utils/dates';
import { parseFormTypeOfService } from 'utils/job-parses';
import { formatLocation, formatSingleLocation } from 'utils/location';
import camelCase from 'lodash/camelCase';
import { PlaceSearchParam } from 'models/User';
import { LANGUAGE_IN_NATIVE } from 'constants/language';

type FilterTagDisplayProps = {
  skills: Skill[];
};

export default function useFilterTagDisplays(props: FilterTagDisplayProps) {
  const { skills } = props;

  const { t } = useTranslation();

  const displayFitlerTags: Record<string, (value: string) => string> = {
    price: (value: string) => {
      const [from, to, type] = value.split('-');

      const fromMoney = +from ? convertToMoney(+from) : null;
      const toMoney = +to ? convertToMoney(+to) : null;

      const typeFormatted =
        (type as JobPricingType) === 'By project'
          ? `${t('General.total')}: `
          : (type as JobPricingType) === 'Hourly'
          ? `${t('General.byHour')}: `
          : '';

      const formattedFrom = fromMoney ? formatMoney(t, fromMoney) : '';
      const formattedTo = toMoney ? formatMoney(t, toMoney) : '';

      if (fromMoney && toMoney)
        return `${typeFormatted}${formattedFrom} - ${formattedTo}`;

      if (fromMoney) return `${typeFormatted}${formattedFrom} <`;

      return `${typeFormatted}< ${formattedTo}`;
    },
    rating: (value: string) =>
      `${t('General.rating')}: ${Number.parseFloat(value)}+`,
    'skills[]': (value: string) => skills.find((s) => s.id === +value)?.name,
    'locations[]': (value: string) => value.replace(';', ', '),
    numberOfJobs: (value: string) =>
      `${t('General.numberOfJobs')}: ${Number.parseFloat(value)}+`,
    negotiable: (value) => {
      const val = value as YesNo;

      if (val === 'see-all')
        return `${t('General.negotiable')}: ${t('General.all')}`;

      if (val === 'yes') return t('General.negotiable');

      return t('General.nonNegotiable');
    },
    freeCancelation: (value) => {
      const val = value as YesNo;

      if (val === 'see-all')
        return `${t('JobForm.freeCancellation')}: ${t('General.all')}`;

      return val === 'yes'
        ? t('JobForm.freeCancellation')
        : t('General.noFreeCancellation');
    },
    sign: (value) => {
      const val = value as Sign;

      if (val === 'see-all') return `${t('General.sign')}: ${t('General.all')}`;

      return val === 'offer' ? t('General.offer') : t('General.preOffer');
    },
    typeOfService: (value) => {
      const typeOfService = JSON.parse(value) as FormJobTypeOfService;

      if (typeOfService.type === 'Online')
        return `${t('General.location')}: ${t('General.online')}`;

      const parsedLocation = parseFormTypeOfService(typeOfService, true);

      if (!parsedLocation.locations?.length)
        return `${t('General.location')}: ${t(
          `General.${camelCase(parsedLocation.locationType)}`,
        )}`;

      return formatLocation(parsedLocation);
    },
    userType: (value) => {
      const val = value as UserType | 'see-all';

      if (val === 'see-all')
        return `${t('General.userType')}: ${t('General.all')}`;

      return t(`General.${value.toLowerCase()}`);
    },
    location: (value) => {
      const parsed = JSON.parse(value) as PlaceSearchParam;

      return formatSingleLocation(parsed);
    },
    dates: (value) => {
      const [start, end] = value.split(',');

      const startDate = start
        ? dates.formatDate(start, 'DD/MM/YYYY, hh:mm a')
        : '';
      const endDate = end ? dates.formatDate(end, 'DD/MM/YYYY, hh:mm a') : '';

      if (start && end) return `${startDate} - ${endDate}`;

      if (startDate) return `${startDate} <`;

      return `< ${endDate}`;
    },
    'languages[]': (value) => {
      return (
        LANGUAGE_IN_NATIVE[value as keyof typeof LANGUAGE_IN_NATIVE] || value
      );
    },
  };

  return displayFitlerTags;
}
