import { AvatarImageVariants, ImageVariants } from 'constants/image';
import { Language, LanguageProficiency } from 'constants/language';
import { PaginatedResponse, Pagination } from './Pagination';
import { Skill } from './Skills';
import { SkillRaiting } from './Skills';
import { ArbitratorTerms } from './Arbitrations';

export type FileState = 'unverified' | 'pending-verification' | 'verified';

export type Role = 'Individual' | 'Business';

export const Type = {
  CLIENT: 'client',
  CLIENT_AND_PROVIDER: 'client_and_provider',
} as const;

export type UserType = (typeof Type)[keyof typeof Type];

export type AgeRange = {
  fromAge: number;
  toAge: number;
};

export type SkillRating = {
  averageRating: number;
  numberOfReviews: number;
  skill: Skill;
  skillId: number;
  userId: number;
};

export type LatLng = {
  lat: number;
  lng: number;
};

export type Bounds = {
  ne: LatLng;
  sw: LatLng;
};

export type LocationType = {
  id?: number;
  city: string;
  country: string;
  street?: string;
  area?: number;
  lat: number;
  lng: number;
  fullAddress?: string;
};

export type PlaceFormat = Omit<LocationType, 'id' | 'area'> & {
  bounds?: Bounds;
};

export type PlaceSearchParam = PlaceFormat & {
  placeId: string;
};

export type CurrentLocationState =
  | 'not-allowed'
  | 'partial'
  | 'full'
  | 'loading';

export const DEGREE_TYPE = {
  PROFESSIONAL: 'PROFESSIONAL',
  ASSOCIATE: 'ASSOCIATE',
  BACHELOR: 'BACHELOR',
  MASTER: 'MASTER',
  DOCTORATE: 'DOCTORATE',
} as const;

export type DegreeType = (typeof DEGREE_TYPE)[keyof typeof DEGREE_TYPE];

export type ExperienceType = {
  id?: number;
  companyName: string;
  position?: string;
  location?: string;
  employmentType?: string;
  isWorkingInRole?: boolean;
  startDate?: string;
  endDate?: string;
  description?: string;
};

export type EducationType = {
  id?: number;
  schoolName?: string;
  degree?: DegreeType;
  fieldOfStudy?: string;
  startDate?: string;
  endDate?: string;
  description?: string;
  //for arbitrator terms
  files?: File[];
  state?: FileState;
};

export type CertificateType = {
  id?: number;
  name: string;
  issuedBy?: string;
  issueDate?: string;
  //for arbitrator terms
  files?: File[];
  state?: FileState;
};

export type LanguageType = {
  id?: number;
  language: Language;
  proficiency: LanguageProficiency;
  //for arbitrator terms
  files?: File[];
  state?: FileState;
};

export type AnyDocumentType = {
  id?: number;
  description?: string;
  state?: FileState;
  files?: File[];
};

export type BadgeType = {
  id: number;
  name: string;
  description?: string;
};
export type OverallSkillScore = {
  numberOfReviews: number;
  averageRating: number;
};

export type OverallClientScore = {
  averageRating: string;
  numberOfReviews: number;
  rating: number;
};

export type Picture<
  Variant extends ImageVariants | AvatarImageVariants = ImageVariants,
> = {
  id: number;
  name: string;
  type: string;
  key: string;
  createdAt: string;
  updatedAt: string;
  url: string;
  size: string;
  resizedUrls: Partial<Record<Variant, string>>;
  originalFileId?: number;
};

export type NonImageFile = Omit<Picture, 'resizedUrls'>;

export type JobSuccess = {
  successPercentage: number;
  numberOfJobs: number;
  numberOfSuccessfulJobs: number;
};

export type User = {
  id?: number;
  anyServiceId?: number;
  email?: string;
  role?: Role;
  type?: UserType;
  hasVerifiedMail?: boolean;
  hasVerifiedIdentity?: boolean;
  firstName: string;
  lastName: string;
  businessName?: string;
  businessDocs?: (Picture | NonImageFile)[];
  hasVerifiedBusiness?: boolean;
  // our BE sets fullName
  fullName?: string;
  profession: string;
  about?: string;
  skills?: SkillRating[];
  location?: LocationType;
  experiences?: ExperienceType[];
  educations?: EducationType[];
  certificates?: CertificateType[];
  languages?: LanguageType[];
  badges?: BadgeType[];
  jobSuccess?: JobSuccess;
  profileImage?: Picture<AvatarImageVariants>;
  coverImage?: any;
  gallery?: Picture[];
  overallSkillScore?: OverallSkillScore;
  skillsRaitingList?: SkillRaiting[];
  overallClientScore?: OverallClientScore;
  status?: UserOnlineStatus;
  arbitratorTerms?: ArbitratorTerms;
  hasOffers?: boolean;
};

export type UserOnlineStatus = {
  id: number;
  isOnline: boolean;
  lastSeenAt: string;
};

export type GetUsersResponse = {
  items: User[];
  suggestion: PaginatedResponse<User>;
} & Pagination;
