import React, { ReactNode } from 'react';
import type {
  OptionValue,
  SelectProps,
} from 'ncoded-component-library/build/components/molecules/Select/Select.component';
import { Checkbox, SelectUtils } from 'ncoded-component-library';
import SelectLabel from 'components/SelectLabel';

import './CheckboxSelectOptions.styles.scss';
import './CheckboxSelectOptions.styles.responsive.scss';

type CheckboxSelectOptionsProps<T> = Pick<
  SelectProps<T>,
  'options' | 'value' | 'multiple' | 'className'
> & {
  noOptionsContent: ReactNode;
  onOptionCheck: (value: boolean, option: OptionValue<T>) => void;
};

function CheckboxSelectOptions<T>(props: CheckboxSelectOptionsProps<T>) {
  const {
    className,
    options,
    noOptionsContent,
    onOptionCheck,
    value,
    multiple,
  } = props;

  return (
    <ul className={className}>
      {options.map((o, ind) => {
        const liValue = o.value;

        let checked: boolean;

        if (!value) checked = false;
        else {
          checked =
            multiple === false || !value
              ? SelectUtils.getOptionValue(value).toString() === String(liValue)
              : (value as (T | OptionValue<T>)[])
                  ?.map(String)
                  .includes(String(liValue));
        }

        return (
          <li key={ind}>
            <Checkbox
              label={<SelectLabel label={o.label} />}
              checked={checked}
              name={liValue.toString()}
              onChange={(checked) =>
                onOptionCheck(
                  typeof checked === 'boolean'
                    ? checked
                    : checked.target.checked,
                  o,
                )
              }
            />
          </li>
        );
      })}
      {!options.length && noOptionsContent}
    </ul>
  );
}

export default CheckboxSelectOptions;
