import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default function useTranslationMessages() {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      or: t('General.or'),
      sign: t('General.sign'),
      time: t('General.time'),
      start: t('General.start'),
      language: t('General.language'),
      end: t('General.end'),
      negotiable: t('General.negotiable'),
      location: t('General.location'),
      skill: t('Filters.skills_one'),
      userType: t('General.userType'),
      overallRating: t('NeedProvideFilters.overallRating'),
      browseFilters: t('Filters.browse'),
      selectSkill: t('NeedProvideFilters.selectSkill'),
      numberOfJobs: t('Filters.numberOfJobs'),
      selectLanguage: t('Filters.selectLanguage'),
      freeCancellation: t('JobForm.freeCancellation'),
    }),
    [t],
  );
}
