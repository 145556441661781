import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { Button } from 'ncoded-component-library';
import api from 'api';
import { Field, Form } from 'react-final-form';
import InputField from 'components/InputField';
import PlusGradient from 'icons/PlusGradient.icon';
import Modal, { ModalRef } from 'components/Modal';
import { useTranslation } from 'react-i18next';
import CalendarDropdownField from 'components/CalendarDropdownField';
import TextAreaField from 'components/TextAreaField';
import { DATE_FIELD_PROPS } from 'router/subrouters/Profile/pages/EditProfile/EditProfile.page';
import formValidators from 'utils/formValidators';
import { AnyObject } from 'final-form';
import SelectField from 'components/SelectField';
import { LANGUAGE_OPTIONS, LANGUAGE_PROFICIENCY } from 'constants/language';
import FileInputField from 'components/FileInputField';
import { ArbitratorTerms } from 'models/Arbitrations';
import VerifiedIcon from 'icons/VerifiedProfile.icon';
import OverlaySpinner from 'components/OverlaySpinner';
import { getDegreeTypeOptions } from 'utils/user';

import './MyTermsArbitration.styles.scss';
import './MyTermsArbitration.styles.responsive.scss';
import MultipleSelectField from '../../../../../components/MultipleSelectField';
import { SkillGroup } from '../../../../../models/Skills';

type MyTermsArbitrationProps = {
  className?: string;
};
const MyTermsArbitration = (props: MyTermsArbitrationProps) => {
  const { className } = props;

  const classes = classNames('anys-my-terms-arbitration', className);

  const [arbitratorTerms, setArbitratorTerms] = useState<ArbitratorTerms>();
  const [skillGroups, setSkillGroups] = useState<SkillGroup[]>([]);

  const educationModalRef = useRef<ModalRef>();
  const certificateModalRef = useRef<ModalRef>();
  const languageModalRef = useRef<ModalRef>();
  const anyDocumentModalRef = useRef<ModalRef>();

  const { t } = useTranslation();

  const { required, requiredFile, dateFromCalendarIsAfter } = formValidators;

  const getSkillGroups = useCallback(async () => {
    try {
      const { data } = await api.skillGroup.getSkillGroups();

      if (data) {
        setSkillGroups(data.skillGroups);
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  const getArbitratorTerms = useCallback(async () => {
    try {
      const { data } = await api.arbitratorTerms.getArbitratorTerms();

      if (data) {
        setArbitratorTerms(data);
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  const languageProficiencyOptions = useMemo(
    () =>
      Object.values(LANGUAGE_PROFICIENCY).map((prof) => ({
        value: prof,
        label: t(`General.proficiency${prof}`),
      })),
    [t],
  );

  const degreeOptions = useMemo(() => getDegreeTypeOptions(t), [t]);

  const handleAddEducation = useCallback(async (values: any) => {
    const formData = new FormData();

    if (values.id !== undefined) formData.append('id', values.id);
    values.educationVerificationFiles?.forEach((file: any) =>
      formData.append('arbitratorTermsVerification', file),
    );

    // formData.append('educationVerificationFiles', values.educationVerificationFiles);
    formData.append('schoolName', values.schoolName);
    formData.append('degree', values.degree);
    formData.append('fieldOfStudy', values.fieldOfStudy);
    formData.append('startDate', values.startDate);
    formData.append('endDate', values.endDate);
    formData.append('description', values.description);
    formData.append('groupId', values.group.value);

    try {
      OverlaySpinner.show('.anys-arbitration-page__main');
      educationModalRef.current.close();
      const { data } = await api.arbitratorTerms.addEducationTerms(formData);

      if (data) {
        setArbitratorTerms((old) => ({
          ...old,
          educations: [...old.educations, data],
        }));
      }
    } catch (e) {
      console.error(e);
    } finally {
      OverlaySpinner.hide('.anys-arbitration-page__main');
    }
  }, []);

  const handleAddCertificate = useCallback(async (values: any) => {
    const formData = new FormData();

    if (values.id !== undefined) formData.append('id', values.id);
    values.certificateVerificationFiles?.forEach((file: any) =>
      formData.append('arbitratorTermsVerification', file),
    );

    formData.append('name', values.name);
    formData.append('issuedBy', values.issuedBy);
    formData.append('issueDate', values.issueDate);
    formData.append('fieldOfStudy', values.fieldOfStudy);
    formData.append('groupId', values.group.value);

    try {
      OverlaySpinner.show('.anys-arbitration-page__main');
      certificateModalRef.current.close();
      const { data } = await api.arbitratorTerms.addCertificateTerms(formData);

      if (data) {
        setArbitratorTerms((old) => ({
          ...old,
          certificates: [...old.certificates, data],
        }));
      }
    } catch (e) {
      console.error(e);
    } finally {
      OverlaySpinner.hide('.anys-arbitration-page__main');
    }
  }, []);

  const handleAddLanguage = useCallback(async (values: any) => {
    const formData = new FormData();

    if (values.id !== undefined) formData.append('id', values.id);
    values.laguageVerificationFiles?.forEach((file: any) =>
      formData.append('arbitratorTermsVerification', file),
    );

    formData.append('language', values.language);
    formData.append('proficiency', values.proficiency);
    formData.append('groupId', values.group.value);

    try {
      OverlaySpinner.show('.anys-arbitration-page__main');
      languageModalRef.current.close();

      const { data } = await api.arbitratorTerms.addLangugageTerms(formData);

      if (data) {
        setArbitratorTerms((old) => ({
          ...old,
          languages: [...old.languages, data],
        }));
      }
    } catch (e) {
      console.error(e);
    } finally {
      OverlaySpinner.hide('.anys-arbitration-page__main');
    }
  }, []);

  const handleAddAnyDocument = useCallback(async (values: any) => {
    const formData = new FormData();
    if (values.id !== undefined) formData.append('id', values.id);

    formData.append('description', values.description);
    formData.append('groupId', values.group.value);
    values.documentVerificationFiles?.forEach((file: any) => {
      formData.append('arbitratorTermsVerification', file);
    });

    try {
      OverlaySpinner.show('.anys-arbitration-page__main');
      anyDocumentModalRef.current.close();

      const { data } = await api.arbitratorTerms.addAnyDocumentTerms(formData);

      if (data) {
        setArbitratorTerms((old) => ({
          ...old,
          documents: [...old.documents, data],
        }));
      }
    } catch (e) {
      console.error(e);
    } finally {
      OverlaySpinner.hide('.anys-arbitration-page__main');
    }
  }, []);

  useEffect(() => {
    getArbitratorTerms();
  }, [getArbitratorTerms]);

  useEffect(() => {
    getSkillGroups();
  }, [getSkillGroups]);

  //because form submit button is outside of form
  let addEducation: (
    event?: Partial<
      Pick<
        React.SyntheticEvent<Element, Event>,
        'preventDefault' | 'stopPropagation'
      >
    >,
  ) => Promise<AnyObject>;

  //because form submit button is outside of form
  let addCertificate: (
    event?: Partial<
      Pick<
        React.SyntheticEvent<Element, Event>,
        'preventDefault' | 'stopPropagation'
      >
    >,
  ) => Promise<AnyObject>;

  //because form submit button is outside of form
  let addLanguage: (
    event?: Partial<
      Pick<
        React.SyntheticEvent<Element, Event>,
        'preventDefault' | 'stopPropagation'
      >
    >,
  ) => Promise<AnyObject>;

  let addAnyDocument: (
    event?: Partial<
      Pick<
        React.SyntheticEvent<Element, Event>,
        'preventDefault' | 'stopPropagation'
      >
    >,
  ) => Promise<AnyObject>;

  const educationInitialValues = useRef(null);
  const certificateInitialValues = useRef(null);
  const languageInitialValues = useRef(null);
  const anyDocumentsInitialValues = useRef(null);

  const emptyTerms = useMemo(
    () =>
      arbitratorTerms?.certificates?.length === 0 &&
      arbitratorTerms?.educations?.length === 0 &&
      arbitratorTerms?.languages?.length === 0 &&
      arbitratorTerms?.documents?.length === 0,

    [arbitratorTerms],
  );

  return (
    <section className={classes}>
      <section className="anys-my-terms-arbitration__header">
        <h1>{t('Arbitration.professionalArbitration')}</h1>
        <p className="anys-my-terms-arbitration__help-txt">
          {t('Arbitration.suggestedArbitratorsExplanation')}
        </p>
      </section>

      <section className="anys-my-terms-arbitration__verification">
        <h2>{t('Arbitration.arbitratorVerification')}</h2>
        <section className="anys-my-terms-arbitration__verification-settings">
          <section className="anys-my-terms-arbitration__verification-settings__info">
            {!emptyTerms ? (
              <>
                {arbitratorTerms?.educations?.map((education) => (
                  <section
                    key={education.id}
                    className="anys-my-terms-arbitration__verification-settings__data"
                  >
                    <label>{t('Profile.education')}</label>

                    <section>
                      <span>{education.schoolName}</span>
                      {education.state === 'verified' && <VerifiedIcon />}
                    </section>

                    {education.files.length === 0 && (
                      <Button
                        variant="link"
                        onClick={() => {
                          educationModalRef.current.open();
                          educationInitialValues.current = education;
                        }}
                      >
                        <span>{t('General.verify')}</span>
                      </Button>
                    )}
                  </section>
                ))}

                {arbitratorTerms?.certificates?.map((certificate) => (
                  <section
                    key={certificate.id}
                    className="anys-my-terms-arbitration__verification-settings__data"
                  >
                    <label>{t('Profile.certificate')}</label>

                    <section>
                      <span>{certificate.name}</span>
                      {certificate.state === 'verified' && <VerifiedIcon />}
                    </section>

                    {certificate.files.length === 0 && (
                      <Button
                        variant="link"
                        onClick={() => {
                          certificateModalRef.current.open();
                          certificateInitialValues.current = certificate;
                        }}
                      >
                        <span>{t('General.verify')}</span>
                      </Button>
                    )}
                  </section>
                ))}

                {arbitratorTerms?.languages?.map((language) => (
                  <section
                    key={language.id}
                    className="anys-my-terms-arbitration__verification-settings__data"
                  >
                    <label>{t('Filters.languages_one')}</label>

                    <section>
                      <span>{language.language}</span>
                      {language.state === 'verified' && <VerifiedIcon />}
                    </section>

                    {language.files.length === 0 && (
                      <Button
                        variant="link"
                        onClick={() => {
                          languageModalRef.current.open();
                          languageInitialValues.current = language;
                        }}
                      >
                        <span>{t('General.verify')}</span>
                      </Button>
                    )}
                  </section>
                ))}
                {arbitratorTerms?.documents?.map((doc) => (
                  <section
                    key={doc.id}
                    className="anys-my-terms-arbitration__verification-settings__data"
                  >
                    <label>{t('Profile.anyDocuments')}</label>

                    <section>
                      <span>{doc.description}</span>
                      {doc.state === 'verified' && <VerifiedIcon />}
                    </section>

                    {doc.files.length === 0 && (
                      <Button
                        variant="link"
                        onClick={() => {
                          anyDocumentModalRef.current.open();
                          anyDocumentsInitialValues.current = doc;
                        }}
                      >
                        <span>{t('General.verify')}</span>
                      </Button>
                    )}
                  </section>
                ))}
              </>
            ) : (
              <>{t('General.thereAreNoInformation')}</>
            )}
          </section>
          <section className="anys-my-terms-arbitration__verification-settings__actions">
            <Button
              variant="outline"
              icon={<PlusGradient />}
              onClick={() => {
                educationModalRef.current.open();
                educationInitialValues.current = null;
              }}
            >
              {t('Profile.addEducation')}
            </Button>
            <Button
              variant="outline"
              icon={<PlusGradient />}
              onClick={() => certificateModalRef.current.open()}
            >
              {t('Profile.addCertificate')}
            </Button>
            <Button
              variant="outline"
              icon={<PlusGradient />}
              onClick={() => languageModalRef.current.open()}
            >
              {t('Profile.addLanguage')}
            </Button>
            <Button
              variant="outline"
              icon={<PlusGradient />}
              onClick={() => anyDocumentModalRef.current.open()}
            >
              {t('Profile.addAnyDocument')}
            </Button>
          </section>
        </section>
      </section>

      {/* EDUCATION MODAL */}
      <Modal
        title={t('Profile.addEducation')}
        modalName="education-modal"
        type="action"
        ref={educationModalRef}
        renderAsPortal={false}
        className="anys-my-terms-arbitration__education-modal"
        footer={
          <section>
            <Button type="button" onClick={(e) => addEducation(e)}>
              {t('General.confirm')}
            </Button>
            <Button
              type="button"
              onClick={() => educationModalRef.current.close()}
              variant="link"
            >
              <span>{t('General.cancel')}</span>
            </Button>
          </section>
        }
      >
        <Form
          onSubmit={handleAddEducation}
          initialValues={educationInitialValues.current}
          render={({
            handleSubmit,
            form,
            values: { educationVerificationFiles, startDate },
          }) => {
            addEducation = handleSubmit;

            return (
              <>
                <InputField
                  name="schoolName"
                  label={t('Profile.schoolName')}
                  placeholder={t('Profile.schoolNamePlaceholder')}
                  validate={required(t('General.requiredField'))}
                  // disabled={educationInitialValues.current}
                />
                <SelectField
                  name="degree"
                  label={t('Profile.degree')}
                  placeholder={t('Profile.degreePlaceholder')}
                  validate={required(t('General.requiredField'))}
                  options={degreeOptions}
                  // disabled={educationInitialValues.current}
                />

                <InputField
                  name="fieldOfStudy"
                  label={t('Profile.fieldOfStudy')}
                  placeholder={t('Profile.fieldOfStudyPlaceholder')}
                  validate={required(t('General.requiredField'))}

                  // disabled={educationInitialValues.current}
                />
                <div className="anys-my-terms-arbitration__education-modal__dates">
                  <CalendarDropdownField
                    name="startDate"
                    label={t('General.startTime')}
                    validate={required(t('General.requiredField'))}
                    // disabled={educationInitialValues.current}
                    {...DATE_FIELD_PROPS}
                  />
                  <CalendarDropdownField
                    name="endDate"
                    label={t('General.endTime')}
                    // validate={formValidators.required(
                    //   t("General.requiredField"),
                    // )}
                    // disabled={educationInitialValues.current}
                    validate={formValidators.composeValidators(
                      required(t('General.requiredField')),
                      dateFromCalendarIsAfter(
                        t('Profile.endDateValidation'),
                        new Date(startDate),
                      ),
                    )}
                    {...DATE_FIELD_PROPS}
                  />
                </div>
                <TextAreaField
                  name="description"
                  label={t('General.description')}
                  placeholder={t('Profile.moreAboutEducationPlaceholder')}
                  className="anys-my-terms-arbitration__education-modal__description"
                  maxLength={1200}
                  rows={5}
                  resize={false}
                  showLengthCount
                  // disabled={educationInitialValues.current}
                />
                <Field
                  className="anys-skills-modal__select"
                  ignoreAsync={true}
                  component={MultipleSelectField}
                  options={skillGroups}
                  validate={required(t('General.requiredField'))}
                  mapFunction={(option: SkillGroup) => {
                    return {
                      value: option.id,
                      label: option.name,
                    };
                  }}
                  hideTags
                  name="group"
                  label={t('Arbitration.arbitrationField')}
                  placeholder={t('Arbitration.selectArbitrationField')}
                  icon={<></>}
                  searchable
                  singleSelect
                />
                <section className="anys-my-terms-arbitration__files">
                  <label className="anys-my-terms-arbitration__verif-label">
                    {t('Arbitration.verificationFiles')}
                  </label>

                  {educationVerificationFiles?.length > 0 && (
                    <>
                      <ul className="anys-add-proofs__files">
                        {educationVerificationFiles?.map(
                          (el: any, i: number) => (
                            <li
                              key={el.name + i}
                              className="anys-add-proofs__file"
                            >
                              <span className="anys-add-proofs__file__name">
                                {el.name}
                              </span>
                              <div className="anys-add-proofs__file__size">
                                <span>{`${(+el.size / 1024 / 1024).toFixed(
                                  1,
                                )} MB`}</span>

                                <Button
                                  type="button"
                                  variant="link"
                                  styleType="secondary"
                                  onClick={() => {
                                    form.change(
                                      'educationVerificationFiles',
                                      educationVerificationFiles.filter(
                                        (_: any, ind: number) => i !== ind,
                                      ),
                                    );
                                  }}
                                  // disabled={previewOnly}
                                >
                                  <span>{t('General.remove')}</span>
                                </Button>
                              </div>
                            </li>
                          ),
                        )}
                      </ul>
                    </>
                  )}
                  <FileInputField
                    name="educationVerificationFiles"
                    multiple
                    accept=".jpg,.png,.pdf,.docx,.doc"
                    // className="anys-add-attachments__file-input"
                    validate={requiredFile(t('Arbitration.pleaseAddFile'))}
                    maxFiles={5}
                    trigger={
                      <Button type="button" variant="outline">
                        <PlusGradient />
                        <label>{t('General.addFile')}</label>
                      </Button>
                    }
                  />
                </section>
              </>
            );
          }}
        />
      </Modal>

      {/* CERTIFICATE MODAL */}
      <Modal
        title={t('Profile.addCertificate')}
        modalName="certificate-modal"
        type="action"
        ref={certificateModalRef}
        renderAsPortal={false}
        className="anys-my-terms-arbitration__certificate-modal"
        footer={
          <section>
            <Button type="button" onClick={(e) => addCertificate(e)}>
              {t('General.confirm')}
            </Button>
            <Button
              type="button"
              onClick={() => certificateModalRef.current.close()}
              variant="link"
            >
              <span>{t('General.cancel')}</span>
            </Button>
          </section>
        }
      >
        <Form
          onSubmit={handleAddCertificate}
          initialValues={certificateInitialValues.current}
          render={({
            handleSubmit,
            values: { certificateVerificationFiles },
            form,
          }) => {
            addCertificate = handleSubmit;

            return (
              <>
                <InputField
                  name="name"
                  label={t('Profile.certificateName')}
                  placeholder={t('Profile.certificateNamePlaceholder')}
                  validate={required(t('General.requiredField'))}
                />
                <InputField
                  name="issuedBy"
                  label={t('Profile.issuedBy')}
                  placeholder={t('Profile.issuedByPlaceholder')}
                  validate={required(t('General.requiredField'))}
                />
                <InputField
                  name="fieldOfStudy"
                  label={t('Profile.fieldOfStudy')}
                  placeholder={t('Profile.fieldOfStudyPlaceholder')}
                  validate={required(t('General.requiredField'))}
                />
                <Field
                  className="anys-skills-modal__select"
                  ignoreAsync={true}
                  component={MultipleSelectField}
                  options={skillGroups}
                  validate={required(t('General.requiredField'))}
                  mapFunction={(option: SkillGroup) => {
                    return {
                      value: option.id,
                      label: option.name,
                    };
                  }}
                  hideTags
                  name="group"
                  label={t('Arbitration.arbitrationField')}
                  placeholder={t('Arbitration.selectArbitrationField')}
                  icon={<></>}
                  searchable
                  singleSelect
                />
                <CalendarDropdownField
                  name="issueDate"
                  label={t('Profile.issueDate')}
                  validate={required(t('General.requiredField'))}
                  {...DATE_FIELD_PROPS}
                />

                <section className="anys-my-terms-arbitration__files">
                  <label className="anys-my-terms-arbitration__verif-label">
                    {t('Arbitration.verificationFiles')}
                  </label>

                  {certificateVerificationFiles?.length > 0 && (
                    <>
                      <ul className="anys-add-proofs__files">
                        {certificateVerificationFiles?.map(
                          (el: any, i: number) => (
                            <li
                              key={el.name + i}
                              className="anys-add-proofs__file"
                            >
                              <span className="anys-add-proofs__file__name">
                                {el.name}
                              </span>
                              <div className="anys-add-proofs__file__size">
                                <span>{`${(+el.size / 1024 / 1024).toFixed(
                                  1,
                                )} MB`}</span>

                                <Button
                                  type="button"
                                  variant="link"
                                  styleType="secondary"
                                  onClick={() => {
                                    form.change(
                                      'certificateVerificationFiles',
                                      certificateVerificationFiles.filter(
                                        (_: any, ind: number) => i !== ind,
                                      ),
                                    );
                                  }}
                                  // disabled={previewOnly}
                                >
                                  <span>{t('General.remove')}</span>
                                </Button>
                              </div>
                            </li>
                          ),
                        )}
                      </ul>
                    </>
                  )}
                  <FileInputField
                    name="certificateVerificationFiles"
                    multiple
                    accept=".jpg,.png,.pdf,.docx,.doc"
                    // className="anys-add-attachments__file-input"
                    validate={requiredFile(t('Arbitration.pleaseAddFile'))}
                    maxFiles={5}
                    trigger={
                      <Button type="button" variant="outline">
                        <PlusGradient />
                        <label>{t('General.addFile')}</label>
                      </Button>
                    }
                  />
                </section>
              </>
            );
          }}
        />
      </Modal>

      {/* LANGUAGE MODAL */}
      <Modal
        title={t('Profile.addLanguage')}
        modalName="language-modal"
        type="action"
        ref={languageModalRef}
        renderAsPortal={false}
        className="anys-my-terms-arbitration__language-modal"
        footer={
          <section>
            <Button type="button" onClick={(e) => addLanguage(e)}>
              {t('General.confirm')}
            </Button>
            <Button
              type="button"
              onClick={() => languageModalRef.current.close()}
              variant="link"
            >
              <span>{t('General.cancel')}</span>
            </Button>
          </section>
        }
      >
        <Form
          onSubmit={handleAddLanguage}
          initialValues={languageInitialValues.current}
          render={({
            handleSubmit,
            values: { laguageVerificationFiles },
            form,
          }) => {
            addLanguage = handleSubmit;

            return (
              <>
                <SelectField
                  name="language"
                  label={t('General.language')}
                  options={LANGUAGE_OPTIONS}
                  validate={required(t('General.requiredField'))}
                />
                <SelectField
                  name="proficiency"
                  label={t('Profile.proficiency')}
                  options={languageProficiencyOptions}
                  validate={required(t('General.requiredField'))}
                />
                <Field
                  className="anys-skills-modal__select"
                  ignoreAsync={true}
                  component={MultipleSelectField}
                  validate={required(t('General.requiredField'))}
                  options={skillGroups}
                  mapFunction={(option: SkillGroup) => {
                    return {
                      value: option.id,
                      label: option.name,
                    };
                  }}
                  hideTags
                  name="group"
                  label={t('Arbitration.arbitrationField')}
                  placeholder={t('Arbitration.selectArbitrationField')}
                  icon={<></>}
                  searchable
                  singleSelect
                />
                <section className="anys-my-terms-arbitration__files">
                  <label className="anys-my-terms-arbitration__verif-label">
                    {t('Arbitration.verificationFiles')}
                  </label>

                  {laguageVerificationFiles?.length > 0 && (
                    <>
                      <ul className="anys-add-proofs__files">
                        {laguageVerificationFiles?.map((el: any, i: number) => (
                          <li
                            key={el.name + i}
                            className="anys-add-proofs__file"
                          >
                            <span className="anys-add-proofs__file__name">
                              {el.name}
                            </span>
                            <div className="anys-add-proofs__file__size">
                              <span>{`${(+el.size / 1024 / 1024).toFixed(
                                1,
                              )} MB`}</span>

                              <Button
                                type="button"
                                variant="link"
                                styleType="secondary"
                                onClick={() => {
                                  form.change(
                                    'laguageVerificationFiles',
                                    laguageVerificationFiles.filter(
                                      (_: any, ind: number) => i !== ind,
                                    ),
                                  );
                                }}
                                // disabled={previewOnly}
                              >
                                <span>{t('General.remove')}</span>
                              </Button>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                  <FileInputField
                    name="laguageVerificationFiles"
                    multiple
                    accept=".jpg,.png,.pdf,.docx,.doc"
                    // className="anys-add-attachments__file-input"
                    validate={requiredFile(t('Arbitration.pleaseAddFile'))}
                    maxFiles={5}
                    trigger={
                      <Button type="button" variant="outline">
                        <PlusGradient />
                        <label>{t('General.addFile')}</label>
                      </Button>
                    }
                  />
                </section>
              </>
            );
          }}
        />
      </Modal>

      {/* ANY DOCUMENT MODAL */}
      <Modal
        title={t('Profile.addAnyDocument')}
        modalName="any-document-modal"
        type="action"
        ref={anyDocumentModalRef}
        renderAsPortal={false}
        className="anys-my-terms-arbitration__any-document-modal"
        footer={
          <section>
            <Button type="button" onClick={(e) => addAnyDocument(e)}>
              {t('General.confirm')}
            </Button>
            <Button
              type="button"
              onClick={() => anyDocumentModalRef.current.close()}
              variant="link"
            >
              <span>{t('General.cancel')}</span>
            </Button>
          </section>
        }
      >
        <Form
          onSubmit={handleAddAnyDocument}
          initialValues={anyDocumentsInitialValues.current}
          render={({
            handleSubmit,
            values: { documentVerificationFiles },
            form,
          }) => {
            addAnyDocument = handleSubmit;

            return (
              <>
                <InputField
                  name="description"
                  label={t('Arbitration.title')}
                  validate={required(t('General.requiredField'))}
                />
                <Field
                  className="anys-skills-modal__select"
                  ignoreAsync={true}
                  component={MultipleSelectField}
                  validate={required(t('General.requiredField'))}
                  options={skillGroups}
                  mapFunction={(option: SkillGroup) => ({
                    value: option.id,
                    label: option.name,
                  })}
                  hideTags
                  name="group"
                  label={t('Arbitration.arbitrationField')}
                  placeholder={t('Arbitration.selectArbitrationField')}
                  icon={<></>}
                  searchable
                  singleSelect
                />
                <section className="anys-my-terms-arbitration__files">
                  <label className="anys-my-terms-arbitration__verif-label">
                    {t('Arbitration.verificationFiles')}
                  </label>

                  {documentVerificationFiles?.length > 0 && (
                    <>
                      <ul className="anys-add-proofs__files">
                        {documentVerificationFiles?.map(
                          (el: any, i: number) => (
                            <li
                              key={el.name + i}
                              className="anys-add-proofs__file"
                            >
                              <span className="anys-add-proofs__file__name">
                                {el.name}
                              </span>
                              <div className="anys-add-proofs__file__size">
                                <span>{`${(+el.size / 1024 / 1024).toFixed(
                                  1,
                                )} MB`}</span>

                                <Button
                                  type="button"
                                  variant="link"
                                  styleType="secondary"
                                  onClick={() => {
                                    form.change(
                                      'documentVerificationFiles',
                                      documentVerificationFiles.filter(
                                        (_: any, ind: number) => i !== ind,
                                      ),
                                    );
                                  }}
                                  // disabled={previewOnly}
                                >
                                  <span>{t('General.remove')}</span>
                                </Button>
                              </div>
                            </li>
                          ),
                        )}
                      </ul>
                    </>
                  )}
                  <FileInputField
                    name="documentVerificationFiles"
                    multiple
                    accept=".jpg,.png,.pdf,.docx,.doc"
                    // className="anys-add-attachments__file-input"
                    validate={requiredFile(t('Arbitration.pleaseAddFile'))}
                    maxFiles={5}
                    trigger={
                      <Button type="button" variant="outline">
                        <PlusGradient />
                        <label>{t('General.addFile')}</label>
                      </Button>
                    }
                  />
                </section>
              </>
            );
          }}
        />
      </Modal>
    </section>
  );
};

export default MyTermsArbitration;
