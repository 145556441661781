import React from 'react';
import { Navigate } from 'react-router-dom';
import { EnhancedRouteProps } from 'router/routes/Enhanced/Enhanced.route';
import AuthWelcome from './pages/AuthWelcome';
import ForgotPassword from './pages/ForgotPassword';
import Login from './pages/Login';
import ResetPassword from './pages/ResetPassword';
import Signup from './pages/Signup';
import VerifyEmail from './pages/VerifyEmail';
import ConfirmSocialSignup from './pages/ConfirmSocialSignup';

export default [
  {
    path: 'login',
    element: Login,
    onlyPublic: true,
  },
  {
    path: 'signup',
    element: Signup,
    onlyPublic: true,
  },
  {
    path: 'verify-email',
    element: VerifyEmail,
    onlyPublic: true,
  },
  {
    path: 'forgot-password',
    element: ForgotPassword,
    onlyPublic: true,
  },
  {
    path: 'reset-password',
    element: ResetPassword,
    onlyPublic: true,
  },
  {
    path: 'confirm',
    element: ConfirmSocialSignup,
  },
  {
    index: true,
    element: AuthWelcome,
    onlyPublic: true,
  },
  {
    path: '',
    element: () => <Navigate to="/" replace />,
  },
] as Array<EnhancedRouteProps>;
