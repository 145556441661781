import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { Button, Input } from 'ncoded-component-library';
import Modal from 'components/Modal';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import { useTranslation } from 'react-i18next';
import useCallbackRef from 'hooks/useCallbackRef';
import type {
  SelectProps,
  OptionValue,
} from 'ncoded-component-library/build/components/molecules/Select/Select.component';
import TimesIcon from 'icons/Times.icon';
import InputSearchIcon from 'icons/InputSearch.icon';
import CheckboxSelectOptions from 'components/CheckboxSelectOptions';

import './PhabletSearchableSelect.styles.scss';
import './PhabletSearchableSelect.styles.responsive.scss';
import useFilteredOptions from 'modules/select/hooks/useFilteredOptions';
import SelectOptions from 'components/SelectOptions';

// type PhabletSearchableSelectProps<T extends SelectValue> = Omit<
//   SelectProps<T, T | OptionValue<T>>,
//   'searchable'
// >;

function PhabletSearchableSelect<T extends { toString(): string }>(
  props: SelectProps<T> & {
    label?: ReactNode;
    customTrigger?: JSX.Element;
    modalClassName?: string;
    inputValue?: string;
    onApply?: () => void;
    onReset?: () => void;
    handleAdd: (o: OptionValue<T>) => void;
    handleRemove: (o: OptionValue<T>) => void;
  },
) {
  const {
    className,
    modalClassName,
    name,
    icon,
    label,
    options,
    searchInputProps,
    onReset,
    onApply,
    onSearchChange,
    async,
    customTrigger,
    placeholder,
    multiple,
    value,
    inputValue,
    searchable,
    handleAdd,
    handleRemove,
    onClose,
  } = props;

  const { t } = useTranslation();

  const [inputVal, setInputVal] = useState(inputValue || '');

  const [inputEl, inputRef] = useCallbackRef<HTMLInputElement>();
  const modalRef = useRef<ModalRef>();

  const [open, setOpen] = useState(false);
  const classes = classNames('anys-pss', { open }, className);

  const input = useMemo(
    () => (
      <Input
        ref={inputRef}
        key={name}
        className={classes}
        placeholder={open ? t('General.startTyping') : placeholder}
        onChange={(ev) => {
          setInputVal(ev.target.value);
          onSearchChange?.(ev);
        }}
        suffixNode={
          !open
            ? (icon as ReactNode)
            : inputVal && (
                <button
                  type="button"
                  className="clear"
                  onClick={() => {
                    setInputVal('');
                    onSearchChange?.(
                      new CustomEvent('change', { detail: inputEl }) as any,
                    );
                  }}
                >
                  <TimesIcon />
                </button>
              )
        }
        readOnly={!open}
        value={inputVal}
        prefixNode={<InputSearchIcon width={14} showGradient />}
        {...searchInputProps}
        onFocus={() => !open && setOpen(true)}
      />
    ),
    [
      inputRef,
      name,
      classes,
      open,
      t,
      placeholder,
      icon,
      inputVal,
      searchInputProps,
      onSearchChange,
      inputEl,
    ],
  );

  const trigger = useMemo(() => {
    if (!customTrigger) return input;

    return React.cloneElement(customTrigger, {
      onClick: () => !open && setOpen(true),
    });
  }, [customTrigger, input, open]);

  const filteredOptions = useFilteredOptions(options, inputVal, async);

  useEffect(() => {
    if (!inputValue || multiple) {
      return;
    }
    setInputVal(inputValue);
  }, [inputValue, multiple]);

  useEffect(() => {
    if (!open && !customTrigger) return;

    const timeout = setTimeout(() => {
      inputEl?.focus();
    }, 400);

    return () => clearTimeout(timeout);
  }, [open, customTrigger, inputEl]);

  if (!open) {
    return trigger;
  }

  return (
    <>
      {trigger}
      <Modal
        open
        ref={modalRef}
        isDrawer
        type="no-action"
        onClose={() => {
          setOpen(false);
        }}
        isFullscreen={searchable}
        addSearchParams={false}
        onBackClick={() => {
          setOpen(false);
          onClose?.();
        }}
        lockScroll={false}
        title={searchable ? input : label}
        footer={
          multiple ? (
            <>
              {!!value?.length && (
                <span className="n-of-selected">
                  {t('General.selectedN', { n: value.length })}
                </span>
              )}

              <div>
                <Button
                  variant="link"
                  onClick={() => {
                    setInputVal('');
                    onReset?.();
                  }}
                >
                  <span>{t('General.reset')}</span>
                </Button>
                <Button
                  onClick={() => {
                    setOpen(false);
                    setInputVal('');
                    onApply?.();
                  }}
                >
                  <span>{t('General.apply')}</span>
                </Button>
              </div>
            </>
          ) : null
        }
        modalName="add-skills"
        className={classNames('anys-pss-modal', { multiple }, modalClassName)}
      >
        {multiple ? (
          <CheckboxSelectOptions
            className="anys-pss__results bordered"
            options={filteredOptions}
            value={value}
            multiple={multiple}
            onOptionCheck={(checked, o) => {
              checked ? handleAdd(o) : handleRemove(o);
            }}
            noOptionsContent={
              <p>{t('General.noResultsFor', { term: inputVal })}</p>
            }
          />
        ) : (
          <SelectOptions
            className="anys-pss__results"
            options={options}
            multiple={multiple}
            value={value}
            onOptionCheck={(checked, o) => {
              if (checked) {
                handleAdd(o);
                setOpen(false);
                onClose?.();
              } else {
                handleRemove(o);
              }
            }}
          />
        )}
      </Modal>
    </>
  );
}

export default PhabletSearchableSelect;
