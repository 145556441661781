import { User } from 'models/User';
import { createContext } from 'react';

export type FileType = 'gallery' | 'profileImage' | 'coverImage' | 'business';

type UserContextProps = {
  currentUser: Partial<User> | null;
  loading: boolean;
  shouldNavigateToAuth: () => boolean;
  setCurrentUser: React.Dispatch<React.SetStateAction<Partial<User>>>;
  uploadUserFile: (
    fileName: FileType,
    file: File[],
    updateUserState?: boolean,
  ) => Promise<User>;
  removeUserFile: (id: number, updateUserState?: boolean) => Promise<User>;
  getUser: () => Promise<void>;
};

export default createContext<Partial<UserContextProps>>({});
