import React, { useCallback, useContext, useMemo } from 'react';
import classNames from 'classnames';
import { Job, JobPost } from 'models/Job';
import { Trans, useTranslation } from 'react-i18next';
import { convertToMoney, formatMoney } from 'utils/currency';
import dates from 'utils/dates';
import DataPiecePreview from '../DataPiecePreview';
import { getTimeAndPricingChanges, isPendingChanges } from 'utils/job-changes';
import ToolTip from 'components/ToolTip';
import WarningIcon from 'icons/Warning.icon';
import JobChanges from 'components/JobChanges';
import { Button } from 'ncoded-component-library';

import './TimeAndPricingPreview.styles.scss';
import UserScheduleContext from '../UserScheduleModal/UserScheduleProvider/UserSchedule.context';
import CurrentUserContext from '../../../../providers/CurrentUser/CurrentUser.context';
import { useNavigate } from 'react-router-dom';

type TimeAndPricingPreviewProps = {
  className?: string;
  isOwnJob: boolean;
  prevTimeAndPricing?: Pick<Job, 'timeAndPricing'>['timeAndPricing'];
  isUserScheduleDisabled: boolean;
  onOpenSchedule: () => void;
  prevIsLockedTimeAndPricing?: Pick<
    Job,
    'isLockedTimeAndPricing'
  >['isLockedTimeAndPricing'];
} & Pick<JobPost, 'timeAndPricing'> &
  Required<Pick<Job, 'versionState' | 'isLockedTimeAndPricing'>>;

const TimeAndPricingPreview: React.FC<TimeAndPricingPreviewProps> = (props) => {
  const {
    className,
    timeAndPricing,
    prevTimeAndPricing,
    versionState,
    prevIsLockedTimeAndPricing,
    isLockedTimeAndPricing,
    isOwnJob,
    isUserScheduleDisabled,
    onOpenSchedule,
  } = props;
  const navigate = useNavigate();
  const { currentUser } = useContext(CurrentUserContext);
  const { selectedSchedule } = useContext(UserScheduleContext);

  const { t } = useTranslation();

  const classes = classNames('anys-time-and-pricing-preview', className);

  const messages = useMemo(
    () => ({
      title: t('JobForm.timeAndPricing'),
      totalHours: t('General.totalHours'),
      hours: t('General.hours'),
      price: t('General.price'),
      starting: t('General.starting'),
      ending: t('General.ending'),
      explanation: t('JobForm.additionalExplanation'),
      time: t('General.time'),
      schedule: t('General.schedule'),
      timeFromAvailability: t('General.timeFromAvailability'),
    }),
    [t],
  );

  const {
    price,
    currency,
    type,
    startDate,
    endDate,
    minHours,
    maxHours,
    totalHours,
    minTotalPrice,
    maxTotalPrice,
    pricingDurationType,
    additionalExplanation,
    importAvailability,
  } = timeAndPricing || {};

  const isByProject = type === 'By project';

  const isHourlyStrict = !isByProject && pricingDurationType === 'Strict';

  const getScheduleCTA = useCallback(
    (
      nonImportContent: { label: string; value: string }[],
      scheduleLabel = messages.time,
    ) => {
      if (!importAvailability || (startDate && endDate))
        return nonImportContent;

      return [
        {
          label: scheduleLabel,
          value: isOwnJob ? (
            messages.timeFromAvailability
          ) : (
            <Button
              variant="link"
              onClick={() => {
                if (!currentUser?.id) {
                  return navigate('/auth');
                }
                onOpenSchedule();
              }}
              className="anys-time-and-pricing-preview__schedule-cta"
              disabled={isUserScheduleDisabled}
            >
              <span>
                {selectedSchedule
                  ? dates.formatDates(
                      selectedSchedule.startDate,
                      selectedSchedule.endDate,
                      'DD/MM/YYYY • hh:mm a',
                    )
                  : messages.schedule}
              </span>
            </Button>
          ),
        },
      ];
    },
    [
      currentUser?.id,
      endDate,
      importAvailability,
      isOwnJob,
      isUserScheduleDisabled,
      messages.schedule,
      messages.time,
      messages.timeFromAvailability,
      navigate,
      onOpenSchedule,
      selectedSchedule,
      startDate,
    ],
  );

  const displayedData = useMemo(() => {
    const data = [];

    if (isByProject) {
      data.push(
        {
          label: messages.price,
          value: price ? formatMoney(t, convertToMoney(price, currency)) : '-',
        },
        ...getScheduleCTA([
          {
            label: messages.starting,
            value: startDate
              ? dates.formatDeadlineDate(new Date(startDate))
              : '-',
          },
          {
            label: messages.ending,
            value: endDate ? dates.formatDeadlineDate(new Date(endDate)) : '-',
          },
        ]),
        {
          label: messages.explanation,
          value: additionalExplanation || '-',
        },
      );
    } else if (isHourlyStrict) {
      data.push(
        { label: messages.totalHours, value: totalHours },
        {
          label: messages.price,
          value: minTotalPrice
            ? `${formatMoney(t, convertToMoney(minTotalPrice, currency))}`
            : '-',
        },
        ...getScheduleCTA(
          [
            {
              label: messages.starting,
              value: startDate
                ? dates.formatDeadlineDate(new Date(startDate))
                : '-',
            },
          ],
          messages.starting,
        ),
      );
    } else {
      const minPrice = minTotalPrice
        ? formatMoney(t, convertToMoney(minTotalPrice, currency))
        : null;
      const maxPrice = maxTotalPrice
        ? formatMoney(t, convertToMoney(maxTotalPrice, currency))
        : null;

      data.push(
        {
          label: messages.totalHours,
          value: `${minHours} - ${maxHours}`,
        },
        {
          label: messages.price,
          value: minPrice && maxPrice ? `${minPrice} - ${maxPrice}` : '-',
        },
        ...getScheduleCTA(
          [
            {
              label: messages.starting,
              value: startDate
                ? dates.formatDeadlineDate(new Date(startDate))
                : '-',
            },
          ],
          messages.starting,
        ),
      );
    }

    return data;
  }, [
    additionalExplanation,
    currency,
    endDate,
    getScheduleCTA,
    isByProject,
    isHourlyStrict,
    maxHours,
    maxTotalPrice,
    messages,
    minHours,
    minTotalPrice,
    price,
    startDate,
    t,
    totalHours,
  ]);

  const timeAndPricingChanges =
    prevTimeAndPricing && isPendingChanges(versionState)
      ? getTimeAndPricingChanges(
          t,
          {
            ...prevTimeAndPricing,
            price: prevTimeAndPricing.price
              ? convertToMoney(
                  prevTimeAndPricing.price,
                  prevTimeAndPricing.currency,
                )
              : null,
            startDate: prevTimeAndPricing.startDate
              ? new Date(prevTimeAndPricing.startDate)
              : null,
            endDate: prevTimeAndPricing.endDate
              ? new Date(prevTimeAndPricing.endDate)
              : null,
          },
          {
            ...timeAndPricing,
            price: price ? convertToMoney(price, currency) : null,
            startDate: startDate ? new Date(startDate) : null,
            endDate: endDate ? new Date(endDate) : null,
          },
          prevIsLockedTimeAndPricing,
          isLockedTimeAndPricing,
        )
      : null;

  return (
    <section className={classes}>
      <div className="anys-time-and-pricing-preview__title">
        <h2>
          {messages.title}
          {timeAndPricingChanges ? (
            <ToolTip
              t={t}
              icon={WarningIcon}
              tooltipName="time-and-pricing-changes"
            >
              <JobChanges
                changedFrom={timeAndPricingChanges.changedFrom}
                changedTo={timeAndPricingChanges.changedTo}
              />
            </ToolTip>
          ) : null}
        </h2>
        <ToolTip t={t} tooltipName="time-pricing-tooltip">
          <p style={{ whiteSpace: 'pre-line' }}>
            <Trans i18nKey="Preview.timeAndPricing" components={{ b: <b /> }} />
          </p>
        </ToolTip>
      </div>

      <div className="anys-time-and-pricing-preview__values">
        {displayedData.map((data, i) => (
          <DataPiecePreview key={i} {...data} />
        ))}
      </div>
    </section>
  );
};

export default TimeAndPricingPreview;
