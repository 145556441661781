import React, { useCallback, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { Field, FieldRenderProps, UseFieldConfig } from 'react-final-form';
import formValidators from 'utils/formValidators';
import ValidationError from 'components/ValidationError';
import dayjs, { Dayjs } from 'dayjs';
import dates from 'utils/dates';
import CalendarIcon from 'icons/Calendar.icon';
import TimeInput from 'components/TimeInput';
import CalendarDropdown from 'components/CalendarDropdown';
import { Timeslot } from 'models/Availability';

import './DateTimeField.styles.scss';
import './DateTimeField.styles.responsive.scss';

const TODAY = dayjs();

type DateTimeProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label?: React.ReactNode;
  dateLabel?: React.ReactNode;
  timeLabel?: React.ReactNode;
  leftLimit?: dayjs.Dayjs;
  rightLimit?: dayjs.Dayjs;
  inputClassName?: string;
  isSingleInput?: boolean;
  renderAsPortal?: boolean;
  timeslots?: Timeslot[];
  timezone?: string;
  onChange?: (date: Date) => void;
};

type DateTimeFieldProps = DateTimeProps &
  UseFieldConfig<Date, Date> & { name: string };

type DateTimeFieldComponentProps = DateTimeProps &
  FieldRenderProps<Date, HTMLElement>;

const DateTimeFieldComponent: React.FC<DateTimeFieldComponentProps> = (
  props,
) => {
  const {
    className,
    inputClassName,
    input,
    meta,
    label,
    dateLabel,
    timeLabel,
    placeholder,
    leftLimit = TODAY,
    rightLimit = dates.MAX_DATEPICKER_LIMIT,
    onChange: propsOnChange,
    isSingleInput,
    disabled,
    renderAsPortal,
    timeslots,
    timezone,
  } = props;

  const mobileTimeInputRef = useRef<HTMLDivElement>();
  const desktopTimeInputRef = useRef<HTMLDivElement>();

  const {
    name,
    onChange: inputOnChange,
    value: inputValue,
    onFocus,
    onBlur,
  } = useMemo(() => input, [input]);

  const classes = classNames(
    'anys-date-time-field',
    {
      'anys-date-time-field--single-input': isSingleInput,
    },

    className,
  );

  const { hasError, error } = formValidators.getErrorFromMeta(meta);

  const selectedValue = useMemo(
    () => (inputValue ? dayjs(inputValue) : null),
    [inputValue],
  );

  const selectedTime =
    selectedValue instanceof dayjs ? selectedValue.format('HH:mm') : '';

  const datePlaceholder = useMemo(
    () =>
      isSingleInput ? (
        placeholder
      ) : (
        <>
          <span className="anys-date-time-field__inputs-wrapper__calendar-placeholder--desktop">
            dd/mm/yyyy
          </span>
          <span className="anys-date-time-field__inputs-wrapper__calendar-placeholder--mobile">
            {placeholder}
          </span>
        </>
      ),
    [isSingleInput, placeholder],
  );

  const onChange = useCallback(
    ({
      type,
      value,
    }: { type: 'date'; value: Dayjs } | { type: 'time'; value: string }) => {
      if (type === 'date') {
        const dateValue = value.toDate();

        inputOnChange(dateValue);

        propsOnChange?.(dateValue);
      } else {
        let timeAsDate = null;

        if (typeof value === 'string') {
          const [hours, minutes] = value.split(':');

          const h = hours ? +hours : 0;
          const m = minutes ? +minutes : 0;

          timeAsDate = dayjs(inputValue).hour(h).minute(m).toDate();
        }

        inputOnChange(timeAsDate);

        propsOnChange?.(timeAsDate);
      }
    },
    [inputOnChange, inputValue, propsOnChange],
  );

  const onChangeDate = useCallback(
    (date?: Dayjs) => {
      onChange({ type: 'date', value: date });
    },
    [onChange],
  );

  const onChangeTime = useCallback(
    (time: string) => {
      onChange({ type: 'time', value: time });
    },
    [onChange],
  );

  const touchField = useCallback(() => {
    onFocus();
    onBlur();
  }, [onBlur, onFocus]);

  return (
    <div className={classes}>
      {label && (
        <label
          htmlFor={name}
          className={classNames({
            'anys-date-time-field__combined-label': !isSingleInput,
          })}
        >
          {label}
        </label>
      )}

      <div className="anys-date-time-field__inputs-wrapper">
        <div className="anys-date-time-field__inputs-wrapper__date-time-wrapper">
          {dateLabel && <label> {dateLabel}</label>}

          <div
            className={classNames(
              'anys-date-time-field__inputs-wrapper__input',
              {
                'anys-date-time-field__inputs-wrapper__input--error': hasError,
              },
              inputClassName,
            )}
          >
            <CalendarDropdown
              leftLimitDate={leftLimit}
              rightLimitDate={rightLimit}
              value={selectedValue}
              onChange={onChangeDate}
              onClose={touchField}
              placeholder={datePlaceholder}
              onDone={() => {
                const isDesktopTimeInputHidden =
                  window.getComputedStyle(
                    desktopTimeInputRef.current?.parentElement,
                  ).display === 'none';

                if (isDesktopTimeInputHidden) {
                  mobileTimeInputRef.current.click();
                } else {
                  desktopTimeInputRef.current.click();
                }
              }}
              isPlainStyle
              hasIcon
              className={classNames(
                'anys-date-time-field__inputs-wrapper__input__calendar',
                {
                  'anys-date-time-field__inputs-wrapper__input__calendar--shrink':
                    isSingleInput,
                  'w-100': !selectedTime,
                },
              )}
              disabled={disabled}
              renderAsPortal={renderAsPortal}
              closeWithJS={renderAsPortal}
              timeslots={timeslots}
              timezone={timezone}
            />

            <TimeInput
              name={name}
              value={selectedTime}
              onChange={onChangeTime}
              customInputRef={(el) => (mobileTimeInputRef.current = el)}
              isPlainStyle
              hasIcon
              icon={<CalendarIcon />}
              className={classNames(
                'anys-date-time-field__time',
                'anys-date-time-field__time--mobile',
                {
                  'anys-date-time-field__time--no-date': !inputValue,
                },
              )}
              disabled={!inputValue || disabled}
              onBlur={touchField}
            />
          </div>
        </div>
        <TimeInput
          name={name}
          value={selectedTime}
          onChange={onChangeTime}
          customInputRef={(el) => (desktopTimeInputRef.current = el)}
          hasIcon
          className={classNames(
            'anys-date-time-field__time',
            'anys-date-time-field__time--desktop',
          )}
          hasError={hasError}
          label={timeLabel}
          disabled={!inputValue || disabled}
          onBlur={touchField}
        />
      </div>

      <ValidationError showError={hasError} error={error} />
    </div>
  );
};

const DateTimeField: React.FC<DateTimeFieldProps> = (props) => (
  <Field component={DateTimeFieldComponent} {...props} />
);

export default DateTimeField;
