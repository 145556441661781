import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Button } from 'ncoded-component-library';
import { Form } from 'react-final-form';
import Plus from 'icons/Plus.icon';
import Tabs from 'router/subrouters/Profile/pages/ProfilePreview/components/AvailabilityModal/components/Tabs';
import TextAreaField from 'components/TextAreaField';
import classNames from 'classnames';
import CountdownTimer from 'components/CountdownTimer';
import dayjs from 'dayjs';
import FileInputField from 'components/FileInputField';
import { useTranslation } from 'react-i18next';
import Notice from 'components/Notice';
import api from 'api';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import { useArbitration } from 'providers/Arbitration/Arbitration.provider';
import useMatchMedia from 'hooks/useMatchMedia';
import OverlaySpinner from 'components/OverlaySpinner';
import confirm from 'modules/confirm';
import { AnyObject } from 'final-form';

import './AddProofs.styles.scss';
import './AddProofs.styles.responsive.scss';
import showToast from '../../../../../../../modules/showToast';

const availabilityTabsProvider: {
  name: string;
  id: number;
  disable?: boolean;
}[] = [
  {
    name: 'My proof',
    id: 1,
  },
  {
    name: 'Client proof',
    id: 2,
    disable: true,
  },
];

const availabilityTabsClient: {
  name: string;
  id: number;
  disable?: boolean;
}[] = [
  {
    name: 'My proof',
    id: 1,
  },
  {
    name: 'Provider proof',
    id: 2,
    disable: true,
  },
];

type AddProofsProps = {
  className?: string;
  previewOnly?: boolean;
  stateMessage?: string;
};

const AddProofs = (props: AddProofsProps) => {
  const { className, previewOnly, stateMessage } = props;
  const classes = classNames('anys-add-proofs', className);

  const {
    selectedArbitration: { lastTransitionedAt, id, contract, proofs },
    setSelectedArbitration,
  } = useArbitration();

  const { currentUser } = useContext(CurrentUserContext);

  const [active, setActive] = useState(1);

  const { t } = useTranslation();

  const isPhablet = useMatchMedia('isPhablet');

  const timeLeftForVerification = useMemo(() => {
    const date1 = dayjs(new Date());
    const date2 = dayjs(lastTransitionedAt);

    //TODO: replace this hours
    const newLimitDate = date2.add(24, 'minutes');

    return newLimitDate.diff(date1, 'milliseconds');
  }, [lastTransitionedAt]);

  const isCurrentUserClient = useMemo(
    () => currentUser?.id === contract?.client?.id,
    [contract?.client?.id, currentUser?.id],
  );

  const onSubmit = useCallback(
    async (formValues: any) => {
      try {
        const formData = new FormData();
        formData.append('text', formValues.explanation);

        formValues.files?.forEach((file: any) =>
          formData.append('arbitrationProof', file),
        );

        OverlaySpinner.show('.anys-arbitration-page__main');
        const { data } = await api.arbitration.addProof(id, formData);
        if (data) {
          setSelectedArbitration((old) => ({
            ...old,
            state: data.state,
            actionTimers: [],
          }));
        }
      } catch (e) {
        console.error(e);
        showToast('error', 'Unable to confirm proofs, check your files!');
      } finally {
        OverlaySpinner.hide('.anys-arbitration-page__main');
      }
    },
    [id, setSelectedArbitration],
  );

  const initialData = useMemo(() => {
    return { explanation: proofs?.[0]?.text, files: proofs?.[0]?.files };
  }, [proofs]);

  //because form submit button is outside of form
  let confirmProofs: (
    event?: Partial<
      Pick<
        React.SyntheticEvent<Element, Event>,
        'preventDefault' | 'stopPropagation'
      >
    >,
  ) => Promise<AnyObject>;

  return (
    <div className={classes}>
      <Form
        onSubmit={onSubmit}
        initialValues={initialData}
        render={({ handleSubmit, form, values: { files } }) => {
          confirmProofs = handleSubmit;

          return (
            <form>
              <h1>
                {t('Arbitration.arbitrationForContractId', { id: contract.id })}{' '}
              </h1>
              <Tabs
                labels={
                  isCurrentUserClient
                    ? availabilityTabsClient
                    : availabilityTabsProvider
                }
                active={active}
                setActive={setActive}
                className="anys-add-proofs__tabs"
              />
              {isPhablet && (
                <CountdownTimer
                  startTimeInMS={timeLeftForVerification}
                  className="anys-add-proofs__actions__timer anys-add-proofs__actions__timer--mobile"
                  iconGradient
                />
              )}
              <TextAreaField
                label={t('Arbitration.explanation')}
                name="explanation"
                placeholder={t(
                  'Arbitration.writeAnExplanationForEndingContract',
                )}
                maxLength={4000}
                rows={10}
                // validate={formValidators.contractExplanation()}
                className="anys-add-proofs__explanation"
                resize={false}
                disabled={previewOnly}
              />
              <section className="anys-add-proofs__proofs-field">
                <label>{t('Arbitration.proofs')}</label>
                <span className="anys-add-proofs__proofs-field__explanation">
                  {t('Arbitration.pleaseUploadYourProof')}
                </span>

                <FileInputField
                  name="files"
                  multiple
                  // accept=".jpg,.png,.pdf,.docx,.doc"
                  disabled={previewOnly}
                  // className="anys-add-attachments__file-input"
                  maxFiles={10}
                  trigger={
                    <Button
                      type="button"
                      variant="outline"
                      disabled={previewOnly}
                    >
                      <Plus />
                      <label>{t('Arbitration.addProof')}</label>
                    </Button>
                  }
                />
                {files?.length > 0 && (
                  <>
                    <ul className="anys-add-proofs__files">
                      {files?.map((el: any, i: number) => (
                        <li key={el.name + i} className="anys-add-proofs__file">
                          <span className="anys-add-proofs__file__name">
                            {el.name}
                          </span>
                          <div className="anys-add-proofs__file__size">
                            <span>{`${(+el.size / 1024 / 1024).toFixed(
                              1,
                            )} MB`}</span>

                            <Button
                              type="button"
                              variant="link"
                              styleType="secondary"
                              onClick={() => {
                                form.change(
                                  'files',
                                  files.filter(
                                    (_: any, ind: number) => i !== ind,
                                  ),
                                );
                              }}
                              disabled={previewOnly}
                            >
                              <span>{t('General.remove')}</span>
                            </Button>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </section>

              <section className="anys-add-proofs__actions">
                {!previewOnly ? (
                  <>
                    <Button
                      disabled={previewOnly}
                      type="button"
                      onClick={async () => {
                        if (
                          !(await confirm({
                            title: t('Arbitration.confirmProofs'),
                            description: t('Arbitration.addProofSeqMsg'),
                            onConfirm: confirmProofs,
                          }))
                        ) {
                          return;
                        }
                      }}
                    >
                      {t('Arbitration.confirmProofs')}
                    </Button>

                    <CountdownTimer
                      startTimeInMS={timeLeftForVerification}
                      className="anys-add-proofs__actions__timer"
                      iconGradient
                    />
                  </>
                ) : (
                  <Notice type="default">
                    {stateMessage || t('Arbitration.nowWaitOnArbiters')}
                  </Notice>
                )}
              </section>
            </form>
          );
        }}
      />
    </div>
  );
};

export default AddProofs;
