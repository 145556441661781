import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import classNames from 'classnames';
import DropdownOptions, {
  DropdownOptionsProps,
  renderDropdownOptions,
} from 'components/DropdownOptions';
import Modal, { ModalRef } from 'components/Modal';
import { Button } from 'ncoded-component-library';
import useMatchMedia from 'hooks/useMatchMedia';

import './ResponsiveDropdown.styles.scss';
import './ResponsiveDropdown.styles.responsive.scss';

type ResponsiveDropdownProps = {
  className?: string;
  modalClassName?: string;
  triggerOptions?: React.ComponentProps<typeof Button>;
  triggerContent: React.ReactNode;
  modalName: string;
  renderDropdownAsPortal?: boolean;
} & Pick<DropdownOptionsProps, 'options' | 'listClassName'> &
  Partial<
    Pick<
      React.ComponentPropsWithoutRef<typeof Modal>,
      | 'type'
      | 'confirmActionContent'
      | 'primaryActionContent'
      | 'secondaryActionContent'
      | 'cancelActionContent'
      | 'onPrimaryAction'
      | 'onSecondaryAction'
      | 'title'
    >
  >;

const ResponsiveDropdown: React.ForwardRefRenderFunction<
  ModalRef,
  ResponsiveDropdownProps
> = (props, ref) => {
  const {
    className,
    modalClassName,
    triggerOptions,
    triggerContent,
    options,
    listClassName,
    renderDropdownAsPortal = true,
    ...modalProps
  } = props;

  const modalRef = useRef<ModalRef>();
  const isPhablet = useMatchMedia('isPhablet');

  const classes = classNames('anys-responsive-dropdown', className);

  const renderedOptions = useMemo(
    () => renderDropdownOptions(options, listClassName),
    [listClassName, options],
  );

  useImperativeHandle(ref, () => modalRef.current);

  // If modal is open above phablet,
  // close it because we use a dropdown
  useEffect(() => {
    if (!isPhablet && modalRef.current?.openState) {
      modalRef.current.close();
    }
  }, [isPhablet]);

  return (
    <div className={classes}>
      <div className="anys-responsive-dropdown__modal-wrapper">
        <Button
          {...triggerOptions}
          type="button"
          onClick={(ev) => {
            modalRef.current?.open();

            triggerOptions?.onClick?.(ev);
          }}
        >
          {triggerContent}
        </Button>

        <Modal
          type="confirm"
          {...modalProps}
          keepOpenOnRefresh={false}
          ref={modalRef}
          isDrawer
          lockScroll={false}
          className={classNames(
            'anys-responsive-dropdown__modal-wrapper__modal',
            {
              'anys-responsive-dropdown__modal-wrapper__modal--with-title':
                modalProps.title,
            },
            modalClassName,
          )}
        >
          {renderedOptions}
        </Modal>
      </div>

      <DropdownOptions
        triggerOptions={triggerOptions}
        triggerTitle={triggerContent}
        options={options}
        renderAsPortal={renderDropdownAsPortal}
        className="anys-responsive-dropdown__dropdown"
        listClassName={listClassName}
      />
    </div>
  );
};

export default forwardRef(ResponsiveDropdown);
