import React, { useCallback, useMemo } from 'react';
// import classNames from 'classnames';
import InputField from 'components/InputField';
import PasswordField from 'components/PasswordField';
import RadioGroupField from 'components/RadioGroupField';
import { SubmissionErrors } from 'final-form';
import { SignupReqBody } from 'models/Auth';
import { Role } from 'models/User';
import { Button } from 'ncoded-component-library';
import { Form, FormProps } from 'react-final-form';
import formValidators from 'utils/formValidators';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CheckboxField from 'components/CheckboxField';
import CustomLink from 'components/CustomLink';

import './SignupForm.styles.scss';
import './SignupForm.styles.responsive.scss';

const { PASSWORD_MIN_LENGTH, getEmailValidators, getPasswordValidators } =
  formValidators;

type AccountTypeOption = { label: React.ReactNode; value: Role };

type SignupFormProps = {
  className?: string;
  onMobileSignup: (
    values: SignupReqBody,
  ) => SubmissionErrors | Promise<SubmissionErrors> | void;
  onDesktopSignup: (
    values: SignupReqBody,
  ) => SubmissionErrors | Promise<SubmissionErrors> | void;

  initialValues: Partial<SignupReqBody>;
  isFirstStep: boolean;
  inProgress: boolean;
} & FormProps<SignupReqBody>;

const SignupForm: React.FC<SignupFormProps> = (props) => {
  const {
    className,
    isFirstStep,
    inProgress,
    onMobileSignup,
    onDesktopSignup,
    ...restOfProps
  } = props;

  const { t } = useTranslation();

  // const classes = classNames('anys-signup-form', className);
  const navigate = useNavigate();

  const messages = useMemo(
    () => ({
      title: t('General.createAccount'),
      subtitle: t('General.bestPlatform'),
      role: t('General.accountType'),
      individual: t('General.individualAccount'),
      individualDesc: t('Signup.individualAccountDescription'),
      business: t('General.businessAccount'),
      businessDesc: t('Signup.businessAccountDescription'),

      emailLabel: t('Signup.email'),
      emailPlaceholder: t('Signup.emailPlaceholder'),
      emailDescription: t('Signup.emailDescription'),

      passwordLabel: t('Signup.password'),
      passwordPlaceholder: t('General.passwordPlaceholder'),
      passwordDescription: t('Signup.passwordDescription', {
        minLength: PASSWORD_MIN_LENGTH,
      }),

      next: t('General.next'),
      backToLogin: t('General.backToLogin'),
      continue: t('General.continue'),
    }),
    [t],
  );

  const validators = useMemo(
    () => ({
      email: getEmailValidators(t),
      password: getPasswordValidators(t),
    }),
    [t],
  );

  const accountTypeOptions: AccountTypeOption[] = useMemo(
    () => [
      {
        label: (
          <span className="anys-signup-form__role">
            {messages.individual}
            <span className="anys-signup-form__role__description">
              {messages.individualDesc}
            </span>
          </span>
        ),
        value: 'Individual',
      },
      {
        label: (
          <span className="anys-signup-form__role">
            {messages.business}
            <span className="anys-signup-form__role__description">
              {messages.businessDesc}
            </span>
          </span>
        ),
        value: 'Business',
      },
    ],
    [messages],
  );
  const fields = useMemo(
    () => ({
      radioGroupField: (
        <RadioGroupField
          name="role"
          label={messages.role}
          options={accountTypeOptions}
          isLargeLabel
          direction="row"
        />
      ),
      emailField: (
        <InputField
          type="email"
          name="email"
          isLargeLabel
          label={messages.emailLabel}
          placeholder={messages.emailPlaceholder}
          inputDescription={messages.emailDescription}
          validate={validators.email}
        />
      ),

      passwordField: (
        <PasswordField
          name="password"
          label={messages.passwordLabel}
          placeholder={messages.passwordPlaceholder}
          isLargeLabel
          inputDescription={messages.passwordDescription}
          validate={validators.password}
          hidePasswordStrength
        />
      ),

      termsField: (
        <CheckboxField
          name="termsAndConditions"
          className="anys-signup-form__terms"
          label={
            <Trans
              i18nKey="Signup.termsAndConditionsLabel"
              components={{
                termsLink: (
                  <CustomLink
                    to="/static-pages/terms-and-conditions"
                    target="_blank"
                  />
                ),
              }}
            />
          }
        />
      ),
    }),
    [
      accountTypeOptions,
      messages.emailDescription,
      messages.emailLabel,
      messages.emailPlaceholder,
      messages.passwordDescription,
      messages.passwordLabel,
      messages.passwordPlaceholder,
      messages.role,
      validators.email,
      validators.password,
    ],
  );

  const handleBack = useCallback(() => {
    navigate('/auth/login', { replace: true });
  }, [navigate]);

  return (
    <>
      <Form
        {...restOfProps}
        onSubmit={onMobileSignup}
        render={(formRenderProps) => {
          const {
            handleSubmit,
            hasValidationErrors,
            submitError,
            dirtySinceLastSubmit,
            values,
          } = formRenderProps;
          const { termsAndConditions } = values;

          const nextDisabled =
            hasValidationErrors ||
            inProgress ||
            (isFirstStep && !termsAndConditions);

          return (
            <form
              className="anys-signup-form__mobile-form"
              onSubmit={handleSubmit}
            >
              {isFirstStep ? (
                <>
                  {fields.radioGroupField}
                  {fields.emailField}
                  {fields.termsField}
                </>
              ) : (
                fields.passwordField
              )}

              {!!submitError && !dirtySinceLastSubmit && (
                <p className="api-error">{submitError}</p>
              )}
              <Button type="submit" variant="outline" disabled={nextDisabled}>
                {messages.next}
              </Button>
            </form>
          );
        }}
      />
      <Form
        {...restOfProps}
        onSubmit={onDesktopSignup}
        render={(formRenderProps) => {
          const {
            handleSubmit,
            hasValidationErrors,
            submitError,
            dirtySinceLastSubmit,
            values,
          } = formRenderProps;

          const { termsAndConditions } = values;

          const continueDisabled = hasValidationErrors || !termsAndConditions;

          return (
            <form
              className="anys-signup-form__desktop-form"
              onSubmit={handleSubmit}
            >
              <h3 className="anys-signup-form__desktop-form__title">
                {messages.title}
              </h3>
              <p className="anys-signup-form__desktop-form__subtitle">
                {messages.subtitle}
              </p>
              {fields.radioGroupField}
              {fields.emailField}
              {fields.passwordField}
              {fields.termsField}
              {!!submitError && !dirtySinceLastSubmit && (
                <p className="api-error">{submitError}</p>
              )}
              <Button
                type="submit"
                variant="outline"
                disabled={continueDisabled}
              >
                <span>{messages.continue}</span>
              </Button>
              <Button
                type="button"
                className="anys-signup-form__desktop-form__back-button"
                variant="link"
                onClick={handleBack}
              >
                <span>{messages.backToLogin}</span>
              </Button>
            </form>
          );
        }}
      />
    </>
  );
};

export default SignupForm;
