import React, { ReactNode } from 'react';
import Modal, { ModalRef } from 'components/Modal';
import classNames from 'classnames';

import './ProfilePreviewModal.styles.scss';

type ExperiencesModalProps = {
  className?: string;
  title: string;
  modalName: string;
  modalRef: (element: ModalRef) => void;
  children: ReactNode;
};

const ProfilePreviewModal: React.FC<ExperiencesModalProps> = (props) => {
  const { className, title, modalName, modalRef, children } = props;

  const classes = classNames('anys-profile-preview-modal', className);

  return (
    <Modal
      className={classes}
      type="no-action"
      modalName={modalName}
      ref={modalRef}
      title={title}
      isDrawer
    >
      {children}
    </Modal>
  );
};

export default ProfilePreviewModal;
