import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import classNames from 'classnames';
import api from 'api';
import { useTranslation } from 'react-i18next';
import { Button, Loader } from 'ncoded-component-library';
import AddSmallIcon from 'icons/AddSmall.icon';
import { formatNumber } from 'utils/currency';
import { useWallet } from '../../WalletOutline.page';
import Modal from 'components/Modal';
import AnyCoinList from '../AnyCoinList/AnyCoinList.page';
import PaymentHistoryList from '../PaymentHistoryList/PaymentHistoryList.page';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { PaymentHistoryType, PaymentMethod } from 'models/Wallet';
import showToast from 'modules/showToast';
import CardDetails from './components/CardDetails/CardDetails.component';
import { Form } from 'react-final-form';
import formValidators from 'utils/formValidators';
import InputField from 'components/InputField';
import ThemeContext from 'providers/Theme/Theme.context';
import OverlaySpinner from 'components/OverlaySpinner';
import ToolTip from 'components/ToolTip';
import SelectField from 'components/SelectField';
import CurrencyInputField from 'components/CurrencyInputField';
import useHeaderStyles from 'hooks/useHeaderStyles';
import { useLocation } from 'react-router-dom';
import StripeConnectDetails from './components/StripeConnectDetails/StripeConnectDetails.component';
import { Currency } from 'constants/currency';

import './Wallet.styles.scss';
import './Wallet.styles.responsive.scss';

type WalletPageProps = {
  className?: string;
};
const WalletPage = (props: WalletPageProps) => {
  const { className } = props;
  const classes = classNames('anys-wallet-page', className);

  const { t } = useTranslation();

  const { wallet, setWallet } = useWallet();

  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);

  const [showPaymentMethodError, setShowPaymentMethodError] = useState(false);

  const { theme } = useContext(ThemeContext);

  const { required } = formValidators;

  const location = useLocation();

  useHeaderStyles(
    {
      showBackButton: true,
    },
    [location.pathname],
  );

  const getPaymentMethods = useCallback(async () => {
    try {
      const { data } = await api.wallet.getPaymentMethods();

      setPaymentMethods(data);
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    getPaymentMethods();
  }, [getPaymentMethods]);

  const defaultPaymentCard = useMemo(() => {
    if (paymentMethods.length !== 0) {
      return paymentMethods.find((paymentMethod) => paymentMethod.isDefault);
    }
  }, [paymentMethods]);

  const balanceUSD = useMemo(
    () =>
      wallet?.funds.find((fund) => fund.currency.toUpperCase() === 'USD')
        ?.balance,
    [wallet?.funds],
  );

  const balance = useMemo(() => {
    return balanceUSD
      ? formatNumber(t, balanceUSD / 100, { currency: 'USD' })
      : 0;
  }, [balanceUSD, t]);

  const inEscrow = useMemo(() => {
    return wallet?.funds.find((fund) => fund.currency === 'USD')?.reserved
      ? formatNumber(
          t,
          wallet?.funds.find((fund) => fund.currency === 'USD')?.reserved / 100,
          { currency: 'USD' },
        )
      : 0;
  }, [t, wallet?.funds]);

  const [anyCoinListModal, setAnyCoinListModal] = useState(false);
  const [paymentHistoryModal, setPaymentHistoryModal] = useState<{
    isOpen: boolean;
    type?: PaymentHistoryType;
  }>({ isOpen: false });

  const [cardDetailsModal, setCardDetailsModal] = useState(false);

  const [buyAnyCoinsModal, setBuyAnyCoinsModal] = useState(false);

  const [makeDepositModal, setMakeDepositModal] = useState(false);

  const [withdrawFundsModal, setWithdrawFundsModal] = useState(false);

  const [connectStripeInProgress, setConnectStripeInProfress] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = useCallback(
    async (e: any) => {
      e.preventDefault();

      try {
        OverlaySpinner.show('.anys-wallet-page__add-card-form');

        const { error: submitError } = await elements.submit();
        if (submitError) {
          console.error(submitError);
          return;
        }

        const { data } = await api.wallet.attachPaymentMethod();

        stripe
          .confirmSetup({
            elements,
            clientSecret: data.clientSecret,
            confirmParams: {
              return_url: `${window.location.origin}/wallet`,
            },
          })
          .then(function (result) {
            if (result.error) {
              // Inform the customer that there was an error.
              showToast('error', result.error.message);
            }
          });
      } catch (e) {
        showToast('error', 'Invalid payment method');
        setCardDetailsModal(false);
      } finally {
        OverlaySpinner.hide('.anys-wallet-page__add-card-form');
        getPaymentMethods();
      }
    },
    [elements, getPaymentMethods, stripe],
  );

  const handleDetachPaymentMethod = useCallback(
    async (stripePaymentMethodId: string) => {
      try {
        await api.wallet.detachPaymentMethod(stripePaymentMethodId);

        setPaymentMethods((old) =>
          old.filter(
            (paymentMethod) =>
              paymentMethod.stripePaymentMethodId !== stripePaymentMethodId,
          ),
        );
      } catch (e) {
        console.error(e);
      }
    },
    [],
  );

  const handleSetDefault = useCallback(
    async (stripePaymentMethodId: string) => {
      try {
        await api.wallet.setDefaultPaymentMethod(stripePaymentMethodId);

        setPaymentMethods((old) =>
          old.map((paymentMethod) => {
            if (paymentMethod.stripePaymentMethodId === stripePaymentMethodId) {
              return { ...paymentMethod, isDefault: true };
            } else return { ...paymentMethod, isDefault: false };
          }),
        );
      } catch (e) {
        console.error(e);
      }
    },
    [],
  );

  const handleBuyAnyCoins = useCallback(
    async (values: { numberOfCoins: number }) => {
      try {
        OverlaySpinner.show('.anys-wallet-page__wallet-value__anycoins');

        const { data } = await api.wallet.buyAnyCoins(values.numberOfCoins);

        if (data) {
          setBuyAnyCoinsModal(false);

          setWallet((old) => ({
            ...old,
            funds: old.funds.map((fund) => {
              if (fund.currency === 'USD') {
                return {
                  ...fund,
                  balance: fund.balance - values.numberOfCoins * 100,
                };
              }
              if (fund.currency === 'ANY') {
                return {
                  ...fund,
                  balance: data.balance,
                };
              } else return fund;
            }),
          }));
        }
      } catch (e) {
        console.error(e);
      } finally {
        OverlaySpinner.hide('.anys-wallet-page__wallet-value__anycoins');
      }
    },
    [setWallet],
  );

  const handleMakeDeposit = useCallback(
    async (values: {
      cardPaymentId: string;
      deposit: { amount: number; currency: string };
    }) => {
      try {
        OverlaySpinner.show('.anys-wallet-page__values');
        const {
          data: { clientSecret },
        } = await api.wallet.addDeposit(
          values.deposit?.amount,
          values.cardPaymentId,
        );

        if (clientSecret) {
          const { error } = await stripe.confirmCardPayment(clientSecret);

          if (error) {
            console.error(error);
            showToast('error', error.message);
          } else {
            setWallet((old) => ({
              ...old,
              funds: old.funds.map((fund) => {
                if (fund.currency.toUpperCase() === 'USD') {
                  return {
                    ...fund,
                    balance: fund.balance + values.deposit?.amount,
                  };
                } else return fund;
              }),
            }));
            setMakeDepositModal(false);
          }
        }
      } catch (e) {
        console.error(e);
      } finally {
        OverlaySpinner.hide('.anys-wallet-page__values');
      }
    },
    [setWallet, stripe],
  );

  const handleConnectStripe = useCallback(async () => {
    try {
      setConnectStripeInProfress(true);

      const {
        data: { onboardingLink },
      } = await api.wallet.connectStripe();

      window.location.href = onboardingLink.url;
    } catch (e) {
      console.error(e);
    } finally {
      setConnectStripeInProfress(false);
    }
  }, []);

  const handleWithdrawFunds = useCallback(
    async (values: { withdraw: { amount: number; currency: Currency } }) => {
      try {
        OverlaySpinner.show('.anys-wallet-page__values');

        await api.wallet.withdrawFunds(
          values.withdraw?.amount,
          values.withdraw?.currency,
        );

        setWallet((old) => ({
          ...old,
          funds: old.funds.map((fund) => {
            if (fund.currency.toUpperCase() === 'USD') {
              return {
                ...fund,
                balance: fund.balance - values.withdraw?.amount,
              };
            }
            return fund;
          }),
        }));

        setWithdrawFundsModal(false);
        setPaymentHistoryModal({ isOpen: true, type: 'withdraw' });
      } catch (e) {
        console.error(e);
      } finally {
        OverlaySpinner.hide('.anys-wallet-page__values');
      }
    },
    [setWallet],
  );

  useEffect(() => {
    if (paymentMethods.length !== 0) {
      setShowPaymentMethodError(false);
    }
  }, [paymentMethods]);

  const paymentMethodsOptions = useMemo(() => {
    return paymentMethods.map((paymentMethod) => ({
      label: {
        text: `**** ${paymentMethod.last4Digits}`,
        icon: (
          <img
            alt="payment method brand"
            src={`/assets/images/payment-methods/${theme}-theme/${paymentMethod.brand}.png`}
          />
        ),
      },
      value: paymentMethod.stripePaymentMethodId,
      searchValue: paymentMethod.last4Digits,
    }));
  }, [paymentMethods, theme]);

  return (
    <div className={classes}>
      <div className="anys-wallet-page__header">
        <h1>{t('Wallet.wallet')}</h1>
        <Button
          variant={theme === 'dark' ? 'outline' : 'solid'}
          onClick={() => {
            setPaymentHistoryModal({ isOpen: true });
          }}
        >
          {t('Wallet.paymentHistory')}
        </Button>
      </div>
      <div className="anys-wallet-page__values">
        <div className="anys-wallet-page__wallet-value">
          <span className="anys-wallet-page__wallet-value__label">Balance</span>
          <span className="anys-wallet-page__wallet-value__value">
            $ {balance}
          </span>
        </div>

        <div className="anys-wallet-page__wallet-value">
          <span className="anys-wallet-page__wallet-value__label">
            In escrow
          </span>
          <span className="anys-wallet-page__wallet-value__value">
            $ {inEscrow}
          </span>
        </div>

        <div className="anys-wallet-page__wallet-value anys-wallet-page__wallet-value__anycoins">
          <span className="anys-wallet-page__wallet-value__label">
            AnyCredits
          </span>
          <span className="anys-wallet-page__wallet-value__value">
            {wallet?.funds.find((fund) => fund.currency === 'ANY')?.balance /
              100}
          </span>
        </div>
      </div>
      <Button
        variant="outline"
        className="anys-wallet-page__deposit-btn"
        onClick={() => {
          if (paymentMethods.length !== 0) {
            setMakeDepositModal(true);
          } else {
            setShowPaymentMethodError(true);
          }
        }}
      >
        Add deposit
      </Button>

      {showPaymentMethodError && (
        <div className="anys-wallet-page__deposit-error">
          In order to add deposit you need to add payment card first
        </div>
      )}

      <div className="anys-wallet-page__payment-methodes">
        <h2>Payment methodes</h2>

        {paymentMethods.map((paymentMethod) => (
          <CardDetails
            key={paymentMethod.id}
            paymentMethod={paymentMethod}
            handleDetachPaymentMethod={handleDetachPaymentMethod}
            handleSetDefault={handleSetDefault}
          />
        ))}
        <Button
          type="button"
          variant="link"
          className="anys-wallet-page__payment-methodes__add-btn"
          onClick={() => {
            setCardDetailsModal(true);
          }}
        >
          <AddSmallIcon />
          <span>Add payment card</span>
        </Button>
      </div>
      <div className="anys-wallet-page__payouts">
        <h2>{t('Wallet.payouts')}</h2>

        <StripeConnectDetails
          key={wallet?.stripeAccountId}
          stripeAccountId={wallet?.stripeAccountId}
        />

        {!wallet?.stripeAccountId && (
          <Button
            variant="outline"
            onClick={handleConnectStripe}
            disabled={connectStripeInProgress}
          >
            {connectStripeInProgress && <Loader inline overlay />}
            {t('Wallet.connectWithStripe')}
          </Button>
        )}

        {wallet?.stripeAccountConnected && (
          <>
            <Button
              variant="outline"
              onClick={() => setWithdrawFundsModal(true)}
            >
              {t('Wallet.getPaid')}
            </Button>
            <Button
              variant="outline"
              onClick={() =>
                setPaymentHistoryModal({ isOpen: true, type: 'withdraw' })
              }
            >
              {t('Wallet.payoutHistory')}
            </Button>
          </>
        )}
      </div>
      <div className="anys-wallet-page__anycoins">
        <h2>AnyCredits</h2>
        <Button
          variant="outline"
          onClick={() => {
            setAnyCoinListModal(true);
          }}
        >
          {t('Wallet.listSellAnyCredit')}
        </Button>
        <Button variant="outline" onClick={() => setBuyAnyCoinsModal(true)}>
          {t('Buy AnyCredit')}
        </Button>
      </div>

      {/* BUY ANY COINS*/}
      <Modal
        type="no-action"
        modalName="buy-anyCoins"
        // ref={buyAnyCoinsModalRef}
        isDrawer
        title={t('Buy AnyCredits')}
        open={buyAnyCoinsModal}
        addSearchParams={false}
        onClose={() => setBuyAnyCoinsModal(false)}
        onBackClick={() => setBuyAnyCoinsModal(false)}
      >
        <Form
          onSubmit={handleBuyAnyCoins}
          render={({ handleSubmit, values, form }) => {
            return (
              <form
                onSubmit={(event) => {
                  handleSubmit(event).then(() => {
                    form.restart();
                  });
                }}
              >
                <div className="anys-anycoin-list__fields">
                  <span>1 AnyCredit = $1</span>
                  <InputField
                    label={t('Wallet.howMuchYouWant')}
                    name="numberOfCoins"
                    type="number"
                    isPureNumberInput
                    validate={required<string>(t('General.required'))}
                    parse={(value: string) => (value ? (+value as any) : value)}
                  />
                  <div className="anys-anycoin-list__total">
                    <span>{t('Wallet.total')}: &nbsp;</span>
                    <span>{`$${values?.numberOfCoins || 0}`}</span>
                  </div>
                </div>

                <Button type="submit" disabled={values.numberOfCoins > balance}>
                  Buy
                </Button>
              </form>
            );
          }}
        />
      </Modal>

      {/* ADD DEPOSIT */}
      <Modal
        type="no-action"
        modalName="make-deposit"
        isDrawer
        title="Make a deposit"
        open={makeDepositModal}
        addSearchParams={false}
        onClose={() => setMakeDepositModal(false)}
        onBackClick={() => setMakeDepositModal(false)}
      >
        <Form
          onSubmit={handleMakeDeposit}
          initialValues={{
            cardPaymentId: defaultPaymentCard?.stripePaymentMethodId,
          }}
          render={({ handleSubmit, values, form }) => {
            return (
              <form
                onSubmit={(event) => {
                  handleSubmit(event).then(() => {
                    form.restart();
                  });
                }}
              >
                <div className="anys-anycoin-list__fields">
                  <SelectField
                    name="cardPaymentId"
                    label="Credit card"
                    options={paymentMethodsOptions as any}
                    className="anys-wallet-page__default-cc"
                    validate={required(t('General.requiredField'))}
                  />

                  <CurrencyInputField
                    name="deposit"
                    label="Amount"
                    placeholder="Amount"
                    validate={required<string>(t('General.required'))}
                  />
                </div>
                {values.deposit && (
                  <div className="anys-wallet-page__total">
                    {/* SUBTOTAL */}
                    <span className="anys-wallet-page__sub-total">
                      Subtotal: {values.deposit.amount / 100}$
                    </span>

                    {/* FEES */}
                    <div className="anys-wallet-page__sub-total">
                      <span>{`Fees & Estimated Tax: ${
                        (values.deposit.amount / 10000) * 2.97 < 3
                          ? 3
                          : +((values.deposit.amount / 10000) * 2.97).toFixed(2)
                      }$`}</span>
                      <ToolTip t={t} tooltipName="fees">
                        Service fee is 2.97% (minimum 3$) help us operate
                        AnyService
                      </ToolTip>
                    </div>

                    {/* TOTAL */}
                    <span className="anys-wallet-page__total-lbl">
                      {`Total:
                      ${+(
                        values.deposit.amount / 100 +
                        ((values.deposit.amount / 10000) * 2.97 < 3
                          ? 3
                          : +((values.deposit.amount / 10000) * 2.97).toFixed(
                              2,
                            ))
                      )}$`}
                    </span>
                  </div>
                )}

                <Button
                  type="submit"
                  className="anys-wallet-page__total__buy-btn"
                >
                  Buy
                </Button>
              </form>
            );
          }}
        />
      </Modal>

      {/* WITHDRAW FUNDS - GET PAID */}
      <Modal
        type="no-action"
        modalName="get-paid"
        isDrawer
        title={t('Wallet.getPaid')}
        open={withdrawFundsModal}
        addSearchParams={false}
        onClose={() => setWithdrawFundsModal(false)}
        onBackClick={() => setWithdrawFundsModal(false)}
      >
        <Form
          onSubmit={handleWithdrawFunds}
          render={({ handleSubmit, values, form }) => {
            return (
              <form
                onSubmit={(event) => {
                  handleSubmit(event).then(() => {
                    form.restart();
                  });
                }}
              >
                <div className="anys-anycoin-list__fields">
                  <CurrencyInputField
                    name="withdraw"
                    label="Amount"
                    placeholder="Amount"
                    validate={formValidators.composeValidators(
                      required(t('General.required')),
                      formValidators.minMoneyValue(
                        t('General.atLeastValidation', { min: 10 }),
                        10,
                        true,
                      ),
                      formValidators.maxMoneyValue(
                        t('Wallet.insufficientBalance', {
                          currentBalance: balance,
                        }),
                        balanceUSD / 100,
                        true,
                      ),
                    )}
                  />
                </div>
                {values.withdraw && (
                  <div className="anys-wallet-page__total">
                    {/* SUBTOTAL */}
                    <span className="anys-wallet-page__sub-total">
                      Subtotal: {values.withdraw?.amount / 100}$
                    </span>

                    {/* FEES */}
                    <div className="anys-wallet-page__sub-total">
                      <span>{`Stripe fees: ${+(
                        (values.withdraw?.amount / 10000) * 0.25 +
                        25 / 100
                      ).toFixed(2)}$`}</span>
                      <ToolTip t={t} tooltipName="fees">
                        Stripe fee is 0.25% + 0.25$
                      </ToolTip>
                    </div>

                    {/* TOTAL */}
                    <span className="anys-wallet-page__total-lbl">
                      {`You will get:
                      ${+(
                        values.withdraw?.amount / 100 -
                        +(
                          (values.withdraw?.amount / 10000) * 0.25 +
                          25 / 100
                        ).toFixed(2)
                      )}$`}
                    </span>
                  </div>
                )}

                <Button
                  type="submit"
                  className="anys-wallet-page__total__buy-btn"
                >
                  {t('Wallet.getPaid')}
                </Button>
              </form>
            );
          }}
        />
      </Modal>

      {/* ADD PAYMENT CARD */}
      <Modal
        type="no-action"
        modalName="card-details"
        isDrawer
        title="Add cart details"
        open={cardDetailsModal}
        addSearchParams={false}
        onClose={() => setCardDetailsModal(false)}
        onBackClick={() => setCardDetailsModal(false)}
      >
        <form
          id="payment-form"
          onSubmit={handleSubmit}
          className="anys-wallet-page__add-card-form"
        >
          <PaymentElement
            options={{
              layout: {
                type: 'accordion',
                defaultCollapsed: false,
                radios: true,
                spacedAccordionItems: false,
              },
            }}
          />

          <Button type="submit">Add</Button>
        </form>
      </Modal>

      <Modal
        type="no-action"
        modalName="anycoin-list"
        isDrawer
        open={anyCoinListModal}
        title="List/Sell AnyCredit"
        addSearchParams={false}
        onClose={() => setAnyCoinListModal(false)}
        onBackClick={() => setAnyCoinListModal(false)}
      >
        <AnyCoinList />
      </Modal>

      <Modal
        type="no-action"
        modalName="payment-history"
        isDrawer
        title={
          paymentHistoryModal.type === 'withdraw'
            ? t('Wallet.payoutHistory')
            : t('Wallet.paymentHistory')
        }
        open={paymentHistoryModal.isOpen}
        addSearchParams={false}
        onClose={() => setPaymentHistoryModal({ isOpen: false })}
        onBackClick={() => setPaymentHistoryModal({ isOpen: false })}
      >
        <PaymentHistoryList type={paymentHistoryModal.type} />
      </Modal>
    </div>
  );
};

export default WalletPage;
