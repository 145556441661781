import React, { useCallback, useContext, useMemo } from 'react';
import classNames from 'classnames';
import { JobPost } from 'models/Job';
import { useTranslation } from 'react-i18next';
import { Button, Loader } from 'ncoded-component-library';
import { useLocation, useNavigate } from 'react-router-dom';
import JobPreviewPrice from 'components/JobPreviewPrice';
import StickyBottomContent from 'components/StickyBottomContent';
import useInboxLink from 'router/subrouters/Inbox/hooks/useInboxLink';
import confirm from 'modules/confirm';

import './JobPostPreviewActions.styles.scss';
import './JobPostPreviewActions.styles.responsive.scss';
import { User } from '../../../../../../models/User';
import CurrentUserContext from '../../../../../../providers/CurrentUser/CurrentUser.context';

type JobPostPreviewActionsProps = {
  className?: string;
  jobPost: JobPost;
  isCurrentUserClient: boolean;
  hasClientSigned: boolean;
  hasProviderSigned: boolean;
  provider: Partial<User>;
  inProgress?: boolean;
  isOwnJob: boolean;
  onSign: () => void;
  handleSend: () => void;
};

const JobPostPreviewActions: React.FC<JobPostPreviewActionsProps> = (props) => {
  const {
    className,
    jobPost,
    provider,
    isCurrentUserClient,
    hasClientSigned,
    hasProviderSigned,
    inProgress,
    isOwnJob,
    onSign,
    handleSend,
  } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const { currentUser } = useContext(CurrentUserContext);
  const { createEntityLink } = useInboxLink();

  const { id: jobId, isNegotiable } = jobPost || {};

  const classes = classNames('anys-job-post-preview-actions', className);

  const messages = useMemo(
    () => ({
      sign: t('General.sign'),
      change: t('General.change'),
      unsignAndChange: t('General.unsignAndChange'),
      reserve: t('General.reserve'),
      takeJob: t('General.takeJob'),
      send: t('General.send'),
    }),
    [t],
  );

  const isInbox = location.pathname.startsWith('/inbox');

  const hasSigned = isCurrentUserClient ? hasClientSigned : hasProviderSigned;
  const isClientSignPending = hasProviderSigned && !hasClientSigned;
  const isProviderSignPending = hasClientSigned && !hasProviderSigned;

  const jobPostLink = isInbox
    ? createEntityLink('edit', 'job-post', jobId)
    : `/job-post/edit/${jobId}`;

  const actionContent = hasSigned
    ? messages.unsignAndChange
    : isClientSignPending
      ? messages.reserve
      : isProviderSignPending
        ? messages.takeJob
        : messages.sign;

  const navigateToEdit = useCallback(async () => {
    if (isNegotiable || isOwnJob) {
      navigate(jobPostLink);

      return;
    }

    await confirm({
      title: t('General.entityNonNegotiable', {
        entity:
          hasClientSigned || hasProviderSigned
            ? t('General.offer')
            : t('General.preOffer'),
      }),
      cancelContent: null,
    });
  }, [
    hasClientSigned,
    hasProviderSigned,
    isNegotiable,
    isOwnJob,
    jobPostLink,
    navigate,
    t,
  ]);

  return (
    <StickyBottomContent className={classes}>
      <div className="anys-job-post-preview-actions__buttons">
        {isOwnJob && (hasClientSigned || hasProviderSigned) && (
          <Button type="button" onClick={handleSend}>
            {messages.send}
          </Button>
        )}

        <Button
          type="button"
          variant={hasSigned ? 'outline' : 'solid'}
          onClick={async () => {
            if (!currentUser?.id) {
              return navigate('/auth');
            }

            if (hasSigned) {
              navigateToEdit();
            } else {
              onSign();
            }
          }}
          disabled={inProgress || !provider}
        >
          {inProgress ? <Loader inline overlay={false} /> : actionContent}
        </Button>

        {!hasSigned && (
          <Button variant="link" onClick={navigateToEdit}>
            <span>{messages.change}</span>
          </Button>
        )}
      </div>

      <JobPreviewPrice job={jobPost} isOffer={jobPost?.isSigned} />
    </StickyBottomContent>
  );
};

export default JobPostPreviewActions;
