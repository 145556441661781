import useMatchMedia from 'hooks/useMatchMedia';
import ThemeContext from 'providers/Theme/Theme.context';
import React, { useContext } from 'react';
import ContentLoader from 'react-content-loader';

type SearchLoadingProps = { length?: number };

const SearchLoading: React.FC<SearchLoadingProps> = ({ length = 15 }) => {
  const { theme } = useContext(ThemeContext);
  const isPhablet = useMatchMedia('isPhablet');

  const backgroundColor = theme === 'dark' ? '#909194' : '#e7f1fc';
  const foregroundColor = theme === 'dark' ? '#333' : '#ffffff';

  if (isPhablet)
    return (
      <div>
        {Array.from({ length }).map((_, ind) => (
          <ContentLoader
            key={ind}
            height={94}
            width="100%"
            viewBox="0 0 358 94"
            backgroundColor={backgroundColor}
            foregroundColor={foregroundColor}
            className="anys-search__item"
          >
            <rect x="12" y="12" rx="14" ry="14" width="64" height="64" />
            <rect x="84" y="12" rx="4" ry="4" width="120" height="20" />
            <rect x="84" y="36" rx="4" ry="4" width="80" height="20" />
            <rect x="84" y="60" rx="4" ry="4" width="80" height="20" />
            <rect
              x={358 - 12 - 103}
              y="16"
              rx="10"
              ry="10"
              width="103"
              height="40"
            />
          </ContentLoader>
        ))}
      </div>
    );

  return (
    <div>
      {Array.from({ length }).map((_, ind) => (
        <ContentLoader
          key={ind}
          viewBox="0 0 650 96"
          backgroundColor={backgroundColor}
          foregroundColor={foregroundColor}
          className="anys-search__item"
        >
          <rect x="16" y="16" rx="14" ry="14" width="64" height="64" />
          <rect x="88" y="24" rx="4" ry="4" width="240" height="20" />
          <rect x="88" y="56" rx="4" ry="4" width="120" height="20" />
          <rect
            x={650 - 16 - 130}
            y="16"
            rx="10"
            ry="10"
            width="130"
            height="48"
          />
        </ContentLoader>
      ))}
    </div>
  );
};

export default SearchLoading;
