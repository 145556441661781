const USER_TYPE = {
  BUSINESS: 'Business',
  INDIVIDUAL: 'Individual',
} as const;

export type UserType = typeof USER_TYPE[keyof typeof USER_TYPE];

const SIGNED = {
  OFFER: 'Offer',
  PRE_OFFER: 'Pre-offer',
} as const;

const FREE_CANCELATION = {
  YES: 'Yes',
  NO: 'No',
} as const;

const DEADLINE = {
  YES: 'Yes',
  NO: 'No',
} as const;

const PRICE_RANGE_TYPE = {
  TOTAL: 'Total',
  BY_HOUR: 'By hour',
} as const;

export { USER_TYPE, SIGNED, FREE_CANCELATION, DEADLINE, PRICE_RANGE_TYPE };
