import { useCallback, useEffect, useState } from 'react';
import api from 'api';
import { useTranslation } from 'react-i18next';
import { User } from 'models/User';
import { Pagination } from 'models/Pagination';
import { AxiosError } from 'axios';
import showToast from 'modules/showToast';

const useUsers = (skipFirst?: boolean) => {
  const { t } = useTranslation();

  const [users, setUsers] = useState<User[]>([]);
  const [pagination, setPagination] = useState<Pagination>();
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);

  const getUsers = useCallback(
    async (params?: object) => {
      setIsLoadingUsers(true);

      try {
        const res = await api.users.getUsers(params);

        const {
          data: { items, currentPage, totalItems, totalPages },
        } = res;

        setUsers(items);
        setPagination({ currentPage, totalItems, totalPages });

        return res;
      } catch (e) {
        const err = e as AxiosError;

        showToast(
          'error',
          t('General.error'),
          err?.response?.data?.error?.message,
        );
        console.error(e);
      } finally {
        setIsLoadingUsers(false);
      }
    },
    [t],
  );

  useEffect(() => {
    if (!skipFirst) getUsers();
  }, [getUsers, skipFirst]);

  return { users, isLoadingUsers, pagination, getUsers };
};

export default useUsers;
