import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

export default (props: IconProps) => (
  <svg width="14" height="16" viewBox="0 0 14 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.76144 2.0136C8.1479 1.87503 9.40567 2.79862 9.68012 4.13584C9.75414 4.49651 10.1065 4.72889 10.4672 4.65487C10.8279 4.58084 11.0602 4.22846 10.9862 3.86778C10.5722 1.85046 8.68444 0.481433 6.62884 0.686874C4.57294 0.892341 2.99856 2.6079 2.99984 4.66717L2.99984 6.68238H1.99984C1.26346 6.68238 0.666504 7.27933 0.666504 8.01571V14.0157C0.666504 14.7521 1.26346 15.349 1.99984 15.349H11.9998C12.7362 15.349 13.3332 14.7521 13.3332 14.0157V8.01571C13.3332 7.27933 12.7362 6.68238 11.9998 6.68238H4.33317L4.33317 4.6674C4.33179 3.30439 5.37574 2.15208 6.76144 2.0136ZM1.99984 8.01571H11.9998V14.0157H1.99984V8.01571Z"
      fill="#1A0042"
    />
  </svg>
);
