import api from 'api';
import { UserAvailability } from 'models/Availability';
import { ProfileOffer } from 'models/Job';
import { User } from 'models/User';
import showToast from 'modules/showToast';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useOutletContext, useParams } from 'react-router-dom';
import utils from 'utils';

const Profile = () => {
  const { t } = useTranslation();

  const { currentUser } = useContext(CurrentUserContext);
  const [profileUser, setProfileUser] = useState<Partial<User>>({});
  const [profileOffers, setProfileOffers] = useState<ProfileOffer[]>([]);

  const [availability, setAvailability] = useState<UserAvailability>();

  const params = useParams();

  const getProfileUser = useCallback(async (id: string) => {
    try {
      const {
        data: { user },
      } = await api.user.getUser(id);
      setProfileUser(user);
    } catch (e) {
      utils.toastError(e);
    }
  }, []);

  const getUserAvailability = useCallback(async () => {
    if (profileUser.id) {
      try {
        const { data } = await api.availability.getAvailability(profileUser.id);
        if (data) {
          setAvailability(data);
        }
      } catch (e) {
        console.error(e);
      }
    }
  }, [profileUser.id]);

  useEffect(() => {
    if (!params.id) {
      if (currentUser)
        setProfileUser((oldProfileUser) => ({
          ...currentUser,
          ...(oldProfileUser?.id === currentUser?.id ? oldProfileUser : {}),
        }));
      return;
    }

    getProfileUser(params.id);
  }, [getProfileUser, params.id, currentUser]);

  useEffect(() => {
    getUserAvailability();
  }, [getUserAvailability]);

  useEffect(() => {
    if (!profileUser?.id) return;

    const getProfileOffers = async () => {
      try {
        const { data } = await api.user.getDisplayedOffers(profileUser.id, {
          $take: currentUser?.id === profileUser.id ? 2 : 3,
          $relations: 'jobPost.timeAndPricing',
          $order: '-id',
        });

        setProfileOffers(data.items);
      } catch (error) {
        console.error(error);
        showToast('error', t('General.error'));
      }
    };

    getProfileOffers();
  }, [currentUser?.id, profileUser?.id, t]);

  return (
    <Outlet
      context={{
        profileUser,
        setProfileUser,
        availability,
        setAvailability,
        profileOffers,
        setProfileOffers,
      }}
    />
  );
};
export default Profile;

type OutletContext = {
  profileUser: Partial<User>;
  setProfileUser: React.Dispatch<React.SetStateAction<Partial<User>>>;
  availability: UserAvailability;
  setAvailability: React.Dispatch<React.SetStateAction<UserAvailability>>;
  profileOffers: ProfileOffer[];
  setProfileOffers: React.Dispatch<React.SetStateAction<ProfileOffer[]>>;
};

export const useProfile = () => useOutletContext<OutletContext>();
