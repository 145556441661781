import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import './CopyToClipboard.styles.scss';
import showToast from 'modules/showToast';

type CopyToClipboardProps = {
  className?: string;
  contentToCopy: string;
  disabled?: boolean;
};

const CopyToClipboard: React.FC<
  React.PropsWithChildren<CopyToClipboardProps>
> = (props) => {
  const { children, className, contentToCopy, disabled } = props;

  const { t } = useTranslation();

  const [hasCopied, setHasCopied] = useState(false);

  const classes = classNames('anys-copy-to-clipboard', className);

  const copyToClipboard = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(contentToCopy);

      setHasCopied(true);
    } catch (error) {
      showToast('error', t('General.failedToCopy'));
    }
  }, [contentToCopy, t]);

  useEffect(() => {
    if (!hasCopied) return;

    const timeoutId = setTimeout(() => setHasCopied(false), 1500);

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [hasCopied]);

  return (
    <button
      type="button"
      disabled={disabled}
      className={classes}
      onClick={copyToClipboard}
    >
      {children}

      {hasCopied && (
        <span
          className="anys-copy-to-clipboard__copied-wrapper"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <span className="anys-copy-to-clipboard__copied-wrapper__copied">
            {t('General.copied')}
          </span>
        </span>
      )}
    </button>
  );
};

export default CopyToClipboard;
