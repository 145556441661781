import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Job, JobPost } from 'models/Job';
import DataPiecePreview from '../DataPiecePreview';
import { useTranslation } from 'react-i18next';
import PlainLocationDisplay from 'components/PlainLocationDisplay';

import './TypeOfServicePreview.styles.scss';
import { getTypeOfServiceChanges, isPendingChanges } from 'utils/job-changes';
import ToolTip from 'components/ToolTip';
import WarningIcon from 'icons/Warning.icon';
import JobChanges from 'components/JobChanges';
import { parseFormTypeOfService, parseTypeOfService } from 'utils/job-parses';

type TypeOfServicePreviewProps = {
  className?: string;
} & Pick<JobPost, 'typeOfService'> &
  Pick<Job, 'prevVersion'> &
  Required<Pick<Job, 'versionState' | 'isLockedTypeOfService'>>;

const TypeOfServicePreview: React.FC<TypeOfServicePreviewProps> = (props) => {
  const {
    className,
    typeOfService,
    prevVersion,
    versionState,
    isLockedTypeOfService,
  } = props;

  const { t } = useTranslation();

  const classes = classNames('anys-type-of-service-preview', className);

  const { type } = typeOfService || {};

  const isOnlineService = type === 'Online';

  const messages = useMemo(
    () => ({
      typeOfService: t('JobForm.typeOfService'),
      serviceType: t('General.serviceType'),
      location: t('General.location'),
    }),
    [t],
  );

  const serviceTypeValue = type ? t(`General.${type?.toLowerCase()}`) : '-';

  const typeOfServiceChanges =
    prevVersion && isPendingChanges(versionState)
      ? getTypeOfServiceChanges(
          t,
          // Do a double parse to remove invalid props
          parseFormTypeOfService(parseTypeOfService(prevVersion.typeOfService)),
          parseFormTypeOfService(parseTypeOfService(typeOfService)),
          prevVersion.isLockedTypeOfService,
          isLockedTypeOfService,
        )
      : null;

  return (
    <section className={classes}>
      <div className="anys-type-of-service-preview__title">
        <h2>
          {messages.typeOfService}
          {typeOfServiceChanges ? (
            <ToolTip
              t={t}
              tooltipName="type-of-service-changes"
              icon={WarningIcon}
            >
              <JobChanges
                changedFrom={typeOfServiceChanges.changedFrom}
                changedTo={typeOfServiceChanges.changedTo}
              />
            </ToolTip>
          ) : null}
        </h2>
        <ToolTip t={t} tooltipName="type-of-service-tooltip">
          <p>{t('Preview.typeOfService')}</p>
        </ToolTip>
      </div>
      <div className="anys-type-of-service-preview__values">
        <DataPiecePreview
          label={messages.serviceType}
          value={serviceTypeValue}
        />
        {!isOnlineService && (
          <DataPiecePreview
            label={messages.location}
            value={<PlainLocationDisplay {...typeOfService} />}
          />
        )}
      </div>
    </section>
  );
};

export default TypeOfServicePreview;
