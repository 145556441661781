import React, { useContext, useEffect } from 'react';
import MainTemplate from 'components/templates/MainTemplate';
import ProfileMain from 'router/subrouters/Profile/pages/ProfilePreview/components/ProfileMain';
import { Outlet } from 'react-router-dom';
import MyOffers from 'components/MyOffers';
import ReviewCard from 'components/molecules/Cards/ReviewCard';
import JobSuccessCard from 'components/molecules/Cards/JobSuccessCard';
import Gallery from './components/Gallery';
import SkillsRating from './components/SkillsRating';
import ProfileCard from 'components/Card/ProfileCardComponent';
import { useProfile } from '../Profile/Profile.page';
import LocationPreview from './components/LocationPreview';
import Availability from './components/Availability';
import { t } from 'i18next';
import ProfileContext from './providers/Profile/Profile.context';
import Experiences from './components/Experiences';
import Educations from './components/Educations';
import Languages from './components/Languages';
import Certificates from './components/Certificates';
import AboutMe from './components/AboutMe';
import { Type } from '../../../../../models/User';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import credentialsService from 'services/credentialsService';

import './ProfilePreview.styles.scss';
import './ProfilePreview.styles.responsive.scss';

type ProfilePreviewProps = {
  className?: string;
};

const ProfilePreview: React.FC<ProfilePreviewProps> = () => {
  const { getUser } = useContext(CurrentUserContext);

  const {
    profileUser: { type, gallery = [], location } = {
      experiences: [],
    },
    availability,
  } = useProfile();

  const { setShowAvailabilityModal, isMine } = useContext(ProfileContext);

  const canShowAvailability =
    type === Type.CLIENT_AND_PROVIDER &&
    (isMine || availability?.timeslots?.length > 0);

  // Get current user to
  // make sure we have up-to-date info
  useEffect(() => {
    if (!credentialsService.token) return;

    getUser();
  }, [getUser]);

  return (
    <MainTemplate
      className="anys-profile-preview"
      body={
        <main className="anys-profile-preview__body">
          <ProfileMain canShowAvailability={canShowAvailability} />

          <MyOffers isMine={isMine} />
          <SkillsRating />

          <div className="anys-profile-preview__job_accomplish">
            <ReviewCard />
            <JobSuccessCard />
          </div>

          <Experiences />

          <AboutMe />

          <Educations />

          <Certificates />

          <Languages />

          {location?.lat && location?.lng ? (
            <LocationPreview {...location} />
          ) : null}

          {gallery?.length !== 0 && (
            <ProfileCard cardTitle={t('General.gallery')}>
              <Gallery images={gallery} />
            </ProfileCard>
          )}
        </main>
      }
      asideLeft={
        canShowAvailability ? (
          <Availability
            availability={availability}
            isMine={isMine}
            setShowAvailabilityModal={setShowAvailabilityModal}
          />
        ) : null
      }
    >
      <Outlet />
    </MainTemplate>
  );
};

export default ProfilePreview;
