import httpClient from 'api/httpClient';
import { Account } from 'models/Account';

type AccountInfoParams = {
  email?: string;
  firstName?: string;
  lastName?: string;
  address?: string;
};

const getAccountInformation = () => httpClient.get<Account>(`/account`);

// kroz ovaj request se updatuju sve osnovne informacije na account settings stranici
// firstName, lastName, address, city, state, country, gender, phoneNumber
const updateAccountInformation = (params: AccountInfoParams) =>
  httpClient.put(`/account`, { ...params });

const changeEmail = (params: { email: string; currentPassword: string }) =>
  httpClient.post(`account/change-email`, params);

function resendVerifyCode(email: string) {
  return httpClient.post(`account/verify/email/resend`, { email });
}

// Account/Change password - promena passworda, to na dizajnu nema ali treba dodati
// Account/Change email - promena email-a, treba dodati i current password polje kog na dizajnu nema

export default {
  updateAccountInformation,
  getAccountInformation,
  changeEmail,
  resendVerifyCode,
};
