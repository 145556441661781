import { RefObject, useEffect } from 'react';

const useEvent = <TElement extends HTMLElement = HTMLElement>(
  el: RefObject<TElement> | TElement | Window,
  eventName: string,
  callback: EventListenerOrEventListenerObject,
  options?: AddEventListenerOptions,
  condition = true,
) => {
  const element = el ? ('current' in el ? el.current : el) : null;

  useEffect(() => {
    if (!condition) return;

    if (element && element.addEventListener) {
      element.addEventListener(eventName, callback, options);
    }

    return () => {
      if (element && element.addEventListener) {
        element.removeEventListener(eventName, callback, options);
      }
    };
  }, [eventName, options, element, callback, condition]);
};

export default useEvent;
