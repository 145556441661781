import classNames from 'classnames';
import { uniqueId } from 'lodash';
import React, { useRef } from 'react';

type LeftArrowProps = React.SVGProps<SVGSVGElement>;

const LeftArrow: React.FC<LeftArrowProps> = (props) => {
  const { className, ...restOfProps } = props;
  const id = useRef(uniqueId('lg-')).current;

  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames('left-arrow-icon', className)}
      {...restOfProps}
    >
      <path
        d="M20.3572 11.9221H8.25632L13.543 6.6354C13.9655 6.2129 13.9655 5.51957 13.543 5.09707C13.3406 4.89421 13.0658 4.78021 12.7792 4.78021C12.4927 4.78021 12.2179 4.89421 12.0155 5.09707L4.87632 12.2362C4.45382 12.6587 4.45382 13.3412 4.87632 13.7637L12.0155 20.9029C12.438 21.3254 13.1205 21.3254 13.543 20.9029C13.9655 20.4804 13.9655 19.7979 13.543 19.3754L8.25632 14.0887H20.3572C20.953 14.0887 21.4405 13.6012 21.4405 13.0054C21.4405 12.4096 20.953 11.9221 20.3572 11.9221Z"
        fill={`url(#${id})`}
      />
      <defs>
        <linearGradient
          id={id}
          x1="4.55945"
          y1="21.2198"
          x2="21.7346"
          y2="20.9066"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A761F5" />
          <stop offset="1" stopColor="#50A8FA" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default LeftArrow;
