import React, { useMemo } from 'react';
import TextArea from 'components/TextArea/TextArea.component';
import { Field, FieldRenderProps, UseFieldConfig } from 'react-final-form';
import formValidators from 'utils/formValidators';
import { ExtractPropTypes } from 'types';

type TextAreaProps = ExtractPropTypes<typeof TextArea>;

type TextAreaFieldProps = TextAreaProps & UseFieldConfig<string, string>;

type TextAreaFieldComponentProps = TextAreaProps &
  FieldRenderProps<string, HTMLElement>;

const TextAreaFieldComponent: React.FC<TextAreaFieldComponentProps> = (
  props,
) => {
  const { input, meta, ...restOfProps } = props;

  const error = useMemo(() => formValidators.getErrorFromMeta(meta), [meta]);

  return <TextArea {...input} {...restOfProps} error={error} />;
};

const TextAreaField: React.FC<TextAreaFieldProps> = (props) => (
  <Field component={TextAreaFieldComponent} {...props} />
);
export default TextAreaField;
