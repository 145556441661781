import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import MainTemplate from 'components/templates/MainTemplate';
import NavBar from 'components/NavBar';
import { useTranslation } from 'react-i18next';
import { Field, Form, FormSpy } from 'react-final-form';
import { FormState } from 'final-form';
import ToggleField from 'components/ToggleField';
import {
  NotificationSetting,
  NotificationSettingType,
} from 'models/Notification';
import showToast from 'modules/showToast';
import api from 'api';
import debounce from 'lodash/debounce';
import useHeaderStyles from 'hooks/useHeaderStyles';

import './NotificationCenter.styles.scss';
import './NotificationCenter.styles.responsive.scss';

type NotificationFormValues = {
  notificationSettings: NotificationSetting[];
};

type NotificationCenterProps = {
  className?: string;
};

const NotificationCenter: React.FC<NotificationCenterProps> = (props) => {
  const { className } = props;

  const [settings, setSettings] = useState<NotificationSetting[]>([]);

  const { t } = useTranslation();

  const classes = classNames('anys-notification-center', className);

  const initialValues: NotificationFormValues = useMemo(
    () => ({
      notificationSettings: settings,
    }),
    [settings],
  );

  const sections: {
    key: NotificationSettingType;
    title: string;
    subTitle?: string;
    groupTitle?: string;
    isGrouped?: boolean;
  }[] = useMemo(
    () => [
      {
        key: 'offer',
        title: `${t('General.preOffer')} / ${t('General.offer')}`,
      },
      {
        key: 'contract',
        title: t('General.contract'),
      },
      {
        key: 'any-message',
        title: t('General.anyMessage'),
        groupTitle: t('General.messageNotifications'),
      },
      {
        key: 'other-party',
        title: t('General.otherParty'),
        subTitle: t('NotificationCenter.otherPartyDesc'),
        isGrouped: true,
      },
      {
        key: 'reminders',
        title: t('General.reminders'),
      },
      {
        key: 'anyservice-updates',
        title: t('General.anyserviceUpdates'),
      },
      {
        key: 'promotional-offers',
        title: t('General.receivePromotionalOffers'),
        subTitle: t('General.forClients'),
      },
      {
        key: 'leads',
        title: t('General.leads'),
        subTitle: t('General.forProviders'),
      },
    ],

    [t],
  );

  useHeaderStyles({ showBackButton: true });

  const onSubmit = useCallback(
    async (values: NotificationFormValues) => {
      const { notificationSettings } = values || {};

      if (!settings?.length || !notificationSettings?.length) return;

      try {
        await api.notification.notificationSettings.updateNotificationSettings(
          notificationSettings.filter(Boolean),
        );
      } catch (error) {
        showToast('error', t('General.error'));
      }
    },
    [settings?.length, t],
  );

  const onChangeValues = useMemo(
    () =>
      debounce((formState: FormState<NotificationFormValues>) => {
        const { dirty, values } = formState;

        if (!dirty) return;

        onSubmit(values);
      }, 300),
    [onSubmit],
  );

  useEffect(() => {
    const getNotificationSettings = async () => {
      try {
        const { data } =
          await api.notification.notificationSettings.getNotificationSettings();

        setSettings(
          data.map(({ id, shouldReceiveEmail, shouldReceivePush }) => ({
            id,
            shouldReceiveEmail,
            shouldReceivePush,
          })),
        );
      } catch (error) {
        showToast('error', t('General.error'));
      }
    };

    getNotificationSettings();
  }, [t]);

  return (
    <>
      <NavBar />
      <MainTemplate className={classes}>
        <div
          className={classNames('anys-notification-center__content', 'card')}
        >
          <h1>{t('General.notificationCenter')}</h1>

          <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            render={(formRenderProps) => {
              const { handleSubmit } = formRenderProps;

              return (
                <form onSubmit={handleSubmit}>
                  <FormSpy
                    onChange={onChangeValues}
                    subscription={{ dirty: true, values: true }}
                  />

                  {sections.map(
                    (
                      { key, title, subTitle, groupTitle, isGrouped },
                      index,
                    ) => {
                      return (
                        <section
                          key={key}
                          className={classNames({ 'no-border': groupTitle })}
                        >
                          {groupTitle && (
                            <div
                              className={classNames(
                                'anys-notification-center__content__section-title',
                                'mb-24',
                              )}
                            >
                              {groupTitle}
                            </div>
                          )}
                          <h2
                            className={classNames(
                              'anys-notification-center__content__section-title',
                              {
                                'fw-400': groupTitle || isGrouped,
                              },
                            )}
                          >
                            {title}
                            {subTitle && (
                              <div className="anys-notification-center__content__section-title__desc">
                                {subTitle}
                              </div>
                            )}
                          </h2>

                          <Field
                            type="hidden"
                            name={`notificationSettings.${index}.id`}
                            component="input"
                            defaultValue={index}
                          />

                          <ToggleField
                            name={`notificationSettings.${index}.shouldReceiveEmail`}
                            label={t('General.email')}
                            className="anys-notification-center__content__toggle"
                          />
                        </section>
                      );
                    },
                  )}
                </form>
              );
            }}
          />
        </div>
      </MainTemplate>
    </>
  );
};

export default NotificationCenter;
