import React from 'react';
import classNames from 'classnames';
import UnlockedPadlockIcon from 'icons/UnlockedPadlock.icon';
import LockedPadlockIcon from 'icons/LockedPadlock.icon';
import { Field } from 'react-final-form';
import { Checkbox } from 'ncoded-component-library';
import './LockButton.styles.scss';

type LockButtonProps = {
  className?: string;
  disabled?: boolean;
  onLockToggle?: (newIsLocked: boolean) => void;
  name: string;
};

const LockButton: React.FC<LockButtonProps> = (props) => {
  const { className, name, disabled, onLockToggle } = props;

  const classes = classNames('anys-lock-button', className);

  return (
    <div className={classes}>
      <Field
        name={name}
        type="checkbox"
        disabled={disabled}
        render={({ input, meta, ...restOfProps }) => {
          const icon = input.checked ? (
            <LockedPadlockIcon />
          ) : (
            <UnlockedPadlockIcon />
          );

          const onChange = (value: boolean) => {
            onLockToggle?.(value);

            input.onChange(value);
          };

          return (
            <Checkbox
              {...input}
              {...restOfProps}
              disabled={disabled}
              label={icon}
              onChange={onChange as any}
            />
          );
        }}
      />
    </div>
  );
};

export default LockButton;
