import classNames from 'classnames';
import GradientText from 'components/GradientText';
import { ModalRef } from 'components/Modal';
import Notice from 'components/Notice';
import Star from 'components/Rating/Star/Star.component';
import TextAreaField from 'components/TextAreaField';
import TimesIcon from 'icons/Times.icon';
import { Button } from 'ncoded-component-library';
import React, { useCallback, useState } from 'react';
import { AnyObject, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useInboxLink from 'router/subrouters/Inbox/hooks/useInboxLink';
import formValidators from 'utils/formValidators';

import './RequestPaymentClientReview.styles.scss';

type RequestPaymentModalProps = {
  className?: string;
  modal?: React.Ref<ModalRef>;
  // notice: string;
  explanation?: string;
  onConfirm: (data: { mark?: number; explanation?: string }) => void;
};

const RequestPaymentClientPreview: React.FC<RequestPaymentModalProps> = (
  props,
) => {
  const { className, onConfirm } = props;

  const classes = classNames('anys-request-payment-client-preview', className);

  const [active, setActive] = useState<number>();

  const location = useLocation();
  const isInbox = location.pathname?.startsWith('/inbox');

  const { linkWihoutInboxItem } = useInboxLink();

  const navigate = useNavigate();

  const { t } = useTranslation();

  //because form submit button is outside of form
  let submit: (
    event?: Partial<
      Pick<
        React.SyntheticEvent<Element, Event>,
        'preventDefault' | 'stopPropagation'
      >
    >,
  ) => Promise<AnyObject>;

  const disableButton = useCallback((form: any) => {
    if (!form.mark) {
      return true;
    }
    if (form.mark < 10 && !form.explanation) {
      return true;
    }
  }, []);

  return (
    <div className={classes}>
      <Form
        onSubmit={onConfirm}
        render={({ handleSubmit, form, values }) => {
          submit = handleSubmit;

          return (
            <form onSubmit={handleSubmit}>
              <div className="anys-payment-negotiation__title">
                <h1>{t('Contract.requestPayment')}</h1>
                <Link
                  to={isInbox ? linkWihoutInboxItem : '/provide'}
                  className="anys-payment-negotiation__title__close"
                >
                  <TimesIcon />
                </Link>
              </div>

              <Notice type="warn">
                {t('Contract.requestPaymentMandatoryMessage')}
              </Notice>

              <h2>{`Review ${active + 1 || 0}/10`}</h2>

              <div className="anys-request-payment-client-preview__reviews">
                <div className="anys-request-payment-client-preview__reviews__stars">
                  {[...Array(10).keys()].map((el: number) => {
                    return (
                      <Star
                        key={el}
                        onClick={() => {
                          setActive(el);
                          form.change('mark', el + 1);
                        }}
                        active={el <= active}
                      />
                    );
                  })}
                </div>

                <div className="anys-request-payment-client-preview__reviews__value">{`${
                  active + 1 || 0
                }/10`}</div>
              </div>

              {values.mark < 10 && (
                <TextAreaField
                  label={t('Arbitration.explanation')}
                  name="explanation"
                  placeholder={t(
                    'Arbitration.writeAnExplanationForEndingContract',
                  )}
                  className="anys-end-contract-modal__textarea"
                  maxLength={2000}
                  validate={formValidators.contractExplanation()}
                />
              )}

              <div className="anys-end-contract-modal__actions">
                {/* {values.mark < 10 && (
                  <span className="anys-end-contract-modal__actions__error">
                    If you rate client with grade less then 10, you must
                    add explanation
                  </span>
                )} */}
                <Button
                  type="button"
                  variant={'solid'}
                  onClick={(event) => submit(event)}
                  disabled={disableButton(values)}
                >
                  {t('Contract.goToPaymentRequest')}
                </Button>
                <Button
                  variant="link"
                  onClick={() =>
                    navigate(isInbox ? linkWihoutInboxItem : '/provide')
                  }
                >
                  <GradientText>{t('General.cancel')}</GradientText>
                </Button>
              </div>
            </form>
          );
        }}
      />
    </div>
  );
};

export default RequestPaymentClientPreview;
