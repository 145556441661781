import React, { useCallback, useMemo } from 'react';
import { Field, FieldRenderProps, UseFieldConfig } from 'react-final-form';
import CurrencyInput, { CurrencyInputProps } from 'components/CurrencyInput';
import formValidators from 'utils/formValidators';
import { Money } from 'constants/currency';
import { getNumberFormatOptions } from 'utils/currency';

type CommonProps = Pick<
  CurrencyInputProps,
  'name' | 'className' | 'currencyConfig' | 'placeholder' | 'label' | 'disabled'
> &
  Pick<CurrencyInputProps['input'], 'onBlur' | 'onFocus' | 'onChange'> & {
    valueMap?: (money: Money, type?: 'change' | 'focus' | 'blur') => any;
  };

const defaultValueMap = (money: Money) => money;

type CurrencyInputFieldProps = CommonProps & UseFieldConfig<string, string>;

type CurrencyInputFieldComponentProps = CommonProps &
  FieldRenderProps<string, HTMLElement>;

const defaultCurrencyConfig = getNumberFormatOptions({
  style: 'decimal',
  currency: 'USD',
});

const CurrencyInputFieldComponent: React.FC<
  CurrencyInputFieldComponentProps
> = (props) => {
  const {
    className,
    id,
    input,
    meta,
    valueMap = defaultValueMap,
    currencyConfig = defaultCurrencyConfig,
    onFocus,
    onBlur,
    onChange,
    ...rest
  } = props;

  const error = useMemo(() => formValidators.getErrorFromMeta(meta), [meta]);

  const onInputBlur = useCallback(
    (money: Money) => {
      const val = valueMap(money, 'blur');

      input.onBlur(val);

      onBlur?.(val);
    },
    [input, onBlur, valueMap],
  );

  const onInputFocus = useCallback(
    (money: Money) => {
      const val = valueMap(money, 'focus');

      input.onFocus(val);

      onFocus?.(val);
    },
    [input, onFocus, valueMap],
  );

  const onInputChange = useCallback(
    (money: Money) => {
      const val = valueMap(money, 'change');

      input.onChange(val);

      onChange?.(val);
    },
    [input, onChange, valueMap],
  );

  const inputProps = useMemo(
    () => ({
      ...input,
      onChange: onInputChange,
      onBlur: onInputBlur,
      onFocus: onInputFocus,
    }),
    [input, onInputBlur, onInputChange, onInputFocus],
  );

  return (
    <CurrencyInput
      className={className}
      id={id}
      input={inputProps}
      meta={meta}
      currencyConfig={currencyConfig}
      {...error}
      {...rest}
    />
  );
};

const CurrencyInputField: React.FC<CurrencyInputFieldProps> = (props) => {
  return <Field component={CurrencyInputFieldComponent} {...props} />;
};

export default CurrencyInputField;
