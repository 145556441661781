import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

const JumpRight: React.FC<IconProps> = (props) => (
  <svg
    width="10"
    height="12"
    viewBox="0 0 10 12"
    fill="currentColor"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.452452 1.58934C0.127015 1.2639 0.127015 0.736263 0.452452 0.410826C0.777888 0.0853888 1.30553 0.0853888 1.63096 0.410826L6.63096 5.41083C6.9564 5.73626 6.9564 6.2639 6.63096 6.58934L1.63096 11.5893C1.30553 11.9148 0.777888 11.9148 0.452452 11.5893C0.127015 11.2639 0.127015 10.7363 0.452452 10.4108L4.8632 6.00008L0.452452 1.58934ZM8.12504 11.0001V1.00008C8.12504 0.539844 8.49814 0.166748 8.95837 0.166748C9.41861 0.166748 9.79171 0.539846 9.79171 1.00008V11.0001C9.79171 11.4603 9.41861 11.8334 8.95837 11.8334C8.49814 11.8334 8.12504 11.4603 8.12504 11.0001Z"
    />
  </svg>
);

export default JumpRight;
