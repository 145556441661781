import { BooleanString, Job, JobPost } from 'models/Job';
import {
  getLockedData,
  removeId,
  removeIdFromArray,
  removeInvalidProps,
} from 'utils/job';
import {
  parseDeadlines,
  parseFormArbitrationOptions,
  parseFormDeadlines,
  parseFormTimeAndPricing,
  parseFormTypeOfService,
  parseTimeAndPricing,
  parseTypeOfService,
} from 'utils/job-parses';

const parseJobPostFormValuesToJob = (
  values: Omit<JobPost<'form'>, 'attachments'>,
  isOwnJob = true,
): Job<'request'> => {
  const {
    id,
    type: jobType,
    isNegotiable,
    hasFreeCancelation,
    hasDeadlinePolicy,
    timeAndPricing,
    isLockedTimeAndPricing,
    deadlines = [],
    isLockedDeadlinePolicy,
    hasArbitration,
    sendToUsers,
    sendToTimeline,
    arbitrationOptions = [],
    isLockedArbitration,
    typeOfService,
    isLockedTypeOfService,
    freeCancelation,
    isLockedFreeCancelation,
    isSigned,
    attachmentIdsToDelete,
    boosts,
    suspendedUntil,
    ...restOfValues
  } = values;

  const editableCoreInfo = isOwnJob
    ? {
        isLockedTimeAndPricing,
        isLockedDeadlinePolicy,
        isLockedTypeOfService,
        isLockedFreeCancelation,
        isLockedArbitration,
      }
    : {};

  const timeAndPricingMaybe = getLockedData(
    parseFormTimeAndPricing(timeAndPricing),
    isLockedTimeAndPricing,
    isOwnJob,
  );

  const typeOfServiceMaybe = getLockedData(
    removeInvalidProps(parseFormTypeOfService(typeOfService)),
    isLockedTypeOfService,
    isOwnJob,
  );

  const freeCancellationMaybe = getLockedData(
    freeCancelation ? removeInvalidProps(freeCancelation) : null,
    isLockedFreeCancelation,
    isOwnJob,
  );

  const hasFreeCancellationMaybe =
    isOwnJob || !isLockedFreeCancelation
      ? {
          hasFreeCancelation: hasFreeCancelation === 'true',
        }
      : {};

  const deadlineMaybe = getLockedData(
    parseFormDeadlines(deadlines),
    isLockedDeadlinePolicy,
    isOwnJob,
  );

  const hasDeadlinePolicyMaybe =
    isOwnJob || !isLockedDeadlinePolicy
      ? {
          hasDeadlinePolicy: hasDeadlinePolicy === 'true',
        }
      : {};

  const arbitrationMaybe = getLockedData(
    parseFormArbitrationOptions(arbitrationOptions),
    isLockedArbitration,
    isOwnJob,
  );

  const hasArbitrationMaybe =
    isOwnJob || !isLockedArbitration
      ? {
          hasArbitration: hasArbitration === 'true',
        }
      : {};

  const isNegotiableMaybe = isOwnJob
    ? {
        isNegotiable: isNegotiable === 'true',
      }
    : {};

  const toReturn = {
    ...restOfValues,
    ...editableCoreInfo,
    timeAndPricing: isOwnJob
      ? timeAndPricingMaybe
      : removeId(timeAndPricingMaybe),
    typeOfService: isOwnJob ? typeOfServiceMaybe : removeId(typeOfServiceMaybe),
    freeCancelation: isOwnJob
      ? freeCancellationMaybe
      : removeId(freeCancellationMaybe),
    deadlines: isOwnJob ? deadlineMaybe : removeIdFromArray(deadlineMaybe),
    arbitrationOptions: isOwnJob
      ? arbitrationMaybe
      : removeIdFromArray(arbitrationMaybe),
    ...isNegotiableMaybe,
    ...hasFreeCancellationMaybe,
    ...hasDeadlinePolicyMaybe,
    ...hasArbitrationMaybe,
  };

  return toReturn;
};

const parseJobToJobPostFormValues = (job: Job): JobPost<'form'> => {
  if (!job) return null;

  const {
    isNegotiable,
    hasFreeCancelation,
    hasDeadlinePolicy,
    timeAndPricing,
    deadlines = [],
    hasArbitration,
    mainSkill,
    skills,
    typeOfService,
    commonId,
    createdAt,
    updatedAt,
    originalJobId,
    payment,
    boosts,
    version,
    versionSubmitedBy,
    versionState,
    versionVisible,
    prevVersion,
    prevVersionId,
    isSignedByClient,
    isSignedByProvider,
    signedByClientAt,
    signedByProviderAt,
    jobPost,
    provider,
    client,
    suspendedUntil,
    ...restOfPost
  } = job;

  const toReturn = {
    ...restOfPost,
    typeOfService: parseTypeOfService(typeOfService),
    mainSkill: mainSkill?.name,
    skills: skills?.map(({ name }) => name),
    sendToTimeline: null as boolean,
    isSigned: false,
    deadlines: parseDeadlines(deadlines),
    timeAndPricing: parseTimeAndPricing(timeAndPricing),
    isNegotiable: `${isNegotiable}` as BooleanString,
    hasFreeCancelation: `${hasFreeCancelation}` as BooleanString,
    hasDeadlinePolicy: `${hasDeadlinePolicy}` as BooleanString,
    hasArbitration: `${hasArbitration}` as BooleanString,
  };

  return toReturn;
};

export { parseJobPostFormValuesToJob, parseJobToJobPostFormValues };
