import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, useForm } from 'react-final-form';
import MultipleSelectField from 'components/MultipleSelectField';
import { Skill } from 'models/Skills';
import { Button } from 'ncoded-component-library';
import type { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import { InputAutocompleteLocationField } from 'components/InputAutocompleteLocation';
import ChevronIcon from 'icons/ChervonIcon.icon';
import RadioGroupField from 'components/RadioGroupField';
import useSelectOptions from '../../../../../../../hooks/useSelectOptions';
import InputField from 'components/InputField';
import Plus from 'icons/Plus.icon';
import Badge from 'components/Badge';
import useFilterTagDisplays from '../../hooks/useFilterTagDisplays';
import useFiltersForm from 'hooks/useFiltersForm';
import classNames from 'classnames';
import FiltersFormComponent from 'components/FiltersForm/FiltersForm.component';
import { FilterReqValue } from 'utils/filtersForm';
import { FormApi } from 'final-form';
import { PlaceSearchParam } from 'models/User';

import './FiltersModal.styles.scss';
import './FiltersModal.styles.responsive.scss';

export type FormValue = {
  skills: number[];
  languages: string[];
  location: PlaceSearchParam;
  rating: string;
  numberOfJobs: number;
  userType: string;
};

const parses: Record<string, (value: string) => any> = {
  'skills[]': Number,
  location: JSON.parse,
};

const transformations: Partial<
  Record<keyof FormValue, (value: any) => string>
> = {
  location: JSON.stringify,
};

const defaultValues: FormValue = {
  skills: [],
  languages: [],
  location: null,
  numberOfJobs: 0,
  rating: null,
  userType: null,
};

type FiltersModalProps = {
  className?: string;
  skills: Skill[];
  formApi?: React.MutableRefObject<FormApi<FormValue>>;
  closeModal: () => void;
  onApply?: () => void;
  onBrowse?: () => void;
};

const dbNames: Record<string, FilterReqValue> = {
  'skills[]': { dbName: 'skills.skill.id', type: 'array' },
  'languages[]': { dbName: 'languages.language', type: 'array' },
  location: { dbName: 'location', type: 'bounds-to-latlng' },
  rating: { dbName: 'overallSkillScore.averageRating', type: 'morethan' },
  numberOfJobs: { dbName: 'jobSuccess.numberOfJobs', type: 'morethan' },
  userType: { dbName: 'role', type: 'single' },
};

const FiltersModal: React.ForwardRefRenderFunction<
  ModalRef,
  FiltersModalProps
> = (props, ref) => {
  const { className, skills, formApi, onBrowse, onApply, closeModal } = props;
  const { t } = useTranslation();
  const classes = classNames('anys-filters-modal', className);

  const messages = useMemo(
    () => ({
      or: t('General.or'),
      apply: t('General.apply'),
      language: t('Filters.languages_one'),
      skill: t('Filters.skills_one'),
      browseFilters: t('Filters.browse'),
      location: t('General.location'),
      selectSkill: t('NeedProvideFilters.selectSkill'),
      userType: t('General.userType'),
      overallRating: t('NeedProvideFilters.overallRating'),
      numberOfJobs: t('Filters.numberOfJobs'),
      selectLanguage: t('Filters.selectLanguage'),
      clearFilters: t('Filters.clear'),
    }),
    [t],
  );

  const {
    loading: formLoading,
    initialValues,
    onSubmit,
  } = useFiltersForm({
    dbNames,
    defaultValues,
    parses,
    transformations,
    onApply,
  });

  const { userTypeOptions, ratingOptions, languageOptions, minNumberOfJobs } =
    useSelectOptions();

  return (
    <FiltersFormComponent
      className={classes}
      modalRef={ref}
      loading={formLoading}
      defaultValues={defaultValues}
      initialValues={initialValues}
      onSubmit={onSubmit}
      closeModal={closeModal}
      formApi={formApi}
      render={(formRenderProps) => {
        const {
          values: { skills: skillsValue },
        } = formRenderProps;

        return (
          <>
            <SkillsField
              skills={skills}
              skillsValue={skillsValue}
              messages={messages}
              onBrowse={onBrowse}
            />
            <hr />
            <InputAutocompleteLocationField
              // placeholder={userLocation}
              name="location"
              label={messages.location}
              suffixNode={<ChevronIcon gradient />}
              useProfileLocation
              locationPromptInitially
              withBounds
              withPlaceId
            />

            <hr />

            <RadioGroupField
              name="userType"
              label={messages.userType}
              direction="row"
              options={userTypeOptions}
            />

            <hr />

            <RadioGroupField
              className="radio-grid"
              label={messages.overallRating}
              name="rating"
              options={ratingOptions}
            />

            <hr />

            <div className="fields-row">
              <InputField
                className="number-of-jobs"
                label={messages.numberOfJobs}
                name="numberOfJobs"
                type="number"
                suffixNode={<Plus showGradient />}
              />
              <span className="or">{messages.or}</span>
              <RadioGroupField
                direction="row"
                className="radio-full-row"
                name="numberOfJobs"
                options={minNumberOfJobs}
              />
            </div>

            <hr />

            <Field
              component={MultipleSelectField}
              name="languages"
              label={messages.language}
              placeholder={messages.selectLanguage}
              options={languageOptions}
              icon={<></>}
              searchable
            />
          </>
        );
      }}
    />
  );
};

export const SkillsField: React.FC<{
  skills: Skill[];
  messages: Record<string, string>;
  skillsValue: number[];
  onBrowse?: () => void;
}> = ({ skills, messages, skillsValue, onBrowse }) => {
  const fitlerTagDisplays = useFilterTagDisplays({ skills });

  const { change } = useForm();

  return (
    <>
      <div className="anys-filters-modal__skills fields-row uneven">
        <Field
          component={MultipleSelectField}
          name="skills"
          ignoreAsync
          hideTags
          label={messages.skill}
          placeholder={messages.selectSkill}
          options={skills.map(({ name, id }) => ({
            label: name,
            value: id,
          }))}
          icon={<></>}
          searchable
        />
        <span className="or">{messages.or}</span>
        <Button onClick={onBrowse} variant="outline">
          {messages.browseFilters}
        </Button>
      </div>
      {!!skillsValue?.length && (
        <div className="flex gap-8 mt-16 wrap">
          {skillsValue.map((skill) => (
            <Badge
              key={skill}
              onClick={() =>
                change(
                  'skills',
                  skillsValue.filter((sk) => sk !== skill),
                )
              }
            >
              {fitlerTagDisplays['skills[]'](`${skill}`)}
            </Badge>
          ))}
        </div>
      )}
    </>
  );
};

export default React.forwardRef(FiltersModal);
