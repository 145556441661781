import React, { useContext, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { useAuth } from '../Auth/Auth.page';
import LoginForm from './components/LoginForm';
import AnyServiceLogo from 'icons/AnyServiceLogo.icon';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from 'ncoded-component-library';
import { useNavigate } from 'react-router-dom';
import MobileHeaderContext from 'components/MobileHeader/providers/MobileHeader/MobileHeader.context';
import { Link } from 'react-router-dom';

import './Login.styles.scss';
import './Login.styles.responsive.scss';

type LoginProps = {
  className?: string;
};

const Login: React.FC<LoginProps> = (props) => {
  const { className } = props;

  const { login, isAuthInProgress } = useAuth();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const classes = classNames('anys-login', className);

  const { setStyleOptions, setContent } = useContext(MobileHeaderContext);

  // TODO: change to link
  const signupLink = useMemo(
    () => (
      <Button
        type="button"
        onClick={() => navigate('../signup')}
        variant="link"
      />
    ),
    [navigate],
  );

  const messages = useMemo(
    () => ({
      title: t('Login.title'),
      subtitle: t('Login.subtitle'),
      signupText: t('General.signup'),
    }),
    [t],
  );

  useEffect(() => {
    setStyleOptions({
      hasBorder: false,
      isBackButtonTransparent: true,
      showBackButton: true,
    });
    setContent(null);
  }, [setContent, setStyleOptions]);

  return (
    <div className={classes}>
      <Link to=".." className="logo-link">
        <AnyServiceLogo className="anys-login__logo" />
      </Link>
      <h2 className="anys-login__title">{messages.title}</h2>
      <div className="anys-login__subtitle">{messages.subtitle}</div>

      <LoginForm onSubmit={login} inProgress={isAuthInProgress} />

      <div className="anys-login__signup">
        <Trans
          i18nKey="Login.noAccountHelp"
          t={t}
          values={{ signupText: messages.signupText }}
          components={{ signupLink, span: <span /> }}
        />
      </div>
    </div>
  );
};

export default Login;
