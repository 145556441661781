import { TypeWithDeleteUpdate } from 'types';
import { LocationType, NonImageFile, Picture, User } from './User';
import { Skill } from './Skills';

export const BOOST_VIEW_TYPE = {
  VIEW: 'VIEW',
  HOT_VIEW: 'HOT_VIEW',
} as const;

export type BoostViewType =
  (typeof BOOST_VIEW_TYPE)[keyof typeof BOOST_VIEW_TYPE];

export const POSITION_BOOST_STATE = {
  SCHEDULED: 'scheduled',
  ACTIVE: 'active',
  PAUSED: 'paused',
  CANCELED: 'canceled',
  DONE: 'done',
} as const;

export type PositionBoostState =
  (typeof POSITION_BOOST_STATE)[keyof typeof POSITION_BOOST_STATE];

export const NO_DESIGN_BOOST = '' as const;

export const JOB_POST_BOOST_TYPE = {
  SIZE_50: 'Size-50',
  SIZE_100: 'Size-100',
  PHOTO: 'Photo',
  VIDEO: 'Video',
} as const;

export type BoostType =
  (typeof JOB_POST_BOOST_TYPE)[keyof typeof JOB_POST_BOOST_TYPE];

type PictureBoost = {
  type: 'Photo';
  file: Picture;
};

type VideoBoost = {
  type: 'Video';
  file: NonImageFile;
};

type SizeBoost = {
  type: 'Size-50' | 'Size-100';
};

export type Boost = {
  createdAt: string;
  id: number;
  price: number;
  type: BoostType;
} & (PictureBoost | VideoBoost | SizeBoost);

export type PositionBoostReq = {
  jobPostId: number;
  skillIds?: number[];
  locations?: LocationType[];
  defineTime?: boolean;
  startDate?: string;
  endDate?: string;
  budget?: number;
};

export type BoostFormValues = Partial<
  Omit<PositionBoostReq, 'jobPostId' | 'budget' | 'startDate' | 'endDate'> & {
    locationInput: LocationType;
    type: BoostType | typeof NO_DESIGN_BOOST;
    budget: string;
    file: File;
    fileId: number;
    startDate: Date;
    endDate: Date;

    // Used for showing validation
    photoError: never;
    videoError: never;
    size50Error: never;
    size100Error: never;
  }
>;

export type BoostRes = TypeWithDeleteUpdate<{
  id: number;
  skills: Skill[];
  locations: TypeWithDeleteUpdate<LocationType>[];
  defineTime: boolean;
  startDate: string;
  endDate: string;
}>;

export type PositionBoost = {
  id: number;
  state: PositionBoostState;
  initialBudget: number;
  currentBudget: number;
  defineTime: boolean;
  startDate: string;
  endDate: string;
  score: number;
  numberOfReachViews: number;
  numberOfReachHotViews: number;
  numberOfImpressionViews: number;
  numberOfImpressionHotViews: number;
  numberOfClicks: number;
  numberOfBuys: number;
  skills: Skill[];
  locations: LocationType[];
  user: User;
  createdAt: string;
  updatedAt: string;
};
